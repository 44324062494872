export const wl_info = {
    "0xC1eAE831c306667Ea3a65B05f993A6A7048475A2": {
        "max": 1,
        "address": "0xC1eAE831c306667Ea3a65B05f993A6A7048475A2",
        "signature": "0x1ca5a3baccd2deb11bf4b7892d98648d343561e048dbd4ee5789d5aa4744ffa047aa2446f5dc12bf68f2055f78b3b128b680ad24f611cf9417256caf77c1bc331b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1637697E101063C5ba764306EfD4f82dc6112a8F": {
        "max": 1,
        "address": "0x1637697E101063C5ba764306EfD4f82dc6112a8F",
        "signature": "0x0348376dc5d412ab8fef5c1325b8544f578e9602c6ac75328de78fe976730b6c3feed23115c082bf8e09fca4413e589c7e460d5c00a15071301185f3ec602e181c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x311dD787dd528e4eCc0Db3E07897C7Cc6daEF3C8": {
        "max": 3,
        "address": "0x311dD787dd528e4eCc0Db3E07897C7Cc6daEF3C8",
        "signature": "0xdd1c63d58b8889c7f14bf367d3a6bda66e1ae311b82bf9bb0798e63f9c7054c66a577b323c8d9c0d86390bb2cc02f0864c9f7ca67e5ab3efe317cbc102165a971b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x19FDDaF6BE0c1394545d7715166E05618E7F20Ff": {
        "max": 1,
        "address": "0x19FDDaF6BE0c1394545d7715166E05618E7F20Ff",
        "signature": "0xe28f05d1a5a961c1d98d53491504b3a09a9bddfa33ce5496be9cf4aa83666305522ad04299022722a3e02b367306e9243628b6cba71fa7fbc8858ebfbf3262ac1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2fDF47B329286c52b2A6A87eDe7F0C33a12Eb438": {
        "max": 2,
        "address": "0x2fDF47B329286c52b2A6A87eDe7F0C33a12Eb438",
        "signature": "0x7ec9a4faee08fc24a3aad0e5ddc37621a20a488d196e2581c5d1b7b4e5608cd86ade21d7753c42332804beedf4d24f7712e6482ad2e7702818149b00627063ee1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9472346f14201111Bb308D62eDF9A8CE4eF85712": {
        "max": 3,
        "address": "0x9472346f14201111Bb308D62eDF9A8CE4eF85712",
        "signature": "0xe618c4758bfe0ddbd429c57eba48948b0498bf623fc4c9a58bec376da486fb904b5745c224b31b8ae1153b846ee9a37b527931689743bb12df4984e2b649a4031b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA4D12950Ed4494e4dF86e2ef319Cf99370451d92": {
        "max": 1,
        "address": "0xA4D12950Ed4494e4dF86e2ef319Cf99370451d92",
        "signature": "0xa7dfba60236a87f4c853e7494627bc512ed824603659c149997409958a095bdc316121e81669896228bfed52a49759fee85a1a21208e2521f564721514931c161c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEfc562f920742522Fe1c6a3B009A2f14D3612Ac8": {
        "max": 2,
        "address": "0xEfc562f920742522Fe1c6a3B009A2f14D3612Ac8",
        "signature": "0x47ea7383be942f68b881ca21b2b1de69a1cccd9172d37c88f3626e6294a22c2431d4ffef968477fc53209ec24ddb0dfb7bae940a13b344f53f5b4e409ca33fa81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x787F2C811998272c9b2E4d9c1708179904E61243": {
        "max": 3,
        "address": "0x787F2C811998272c9b2E4d9c1708179904E61243",
        "signature": "0xea3f5a290ed44abc1284d533a57d57e2702fc87989a68a07f069a787edcf96b626f5a802cbfcf15d35f1369a31c573257be24ea8df736dc7d352f9840a6d46461c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcF580566389a42f0eF843F7Eb3D8052008F9f6E1": {
        "max": 1,
        "address": "0xcF580566389a42f0eF843F7Eb3D8052008F9f6E1",
        "signature": "0xda5bc2883b2f0bc1715c717bfd089f9af53915fee782dee53e7a3e0cff21fd9e0a60a3dc68c80c08b1f9887d866ac31390495dab68197b32ecf6cf220f2d04af1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB9C1cd3fC666285D3aFFfff4ae4a721E69445DdD": {
        "max": 2,
        "address": "0xB9C1cd3fC666285D3aFFfff4ae4a721E69445DdD",
        "signature": "0x0a20d872b3432410e73a19f689865fdc5dff08e70338d3caa83e732689bc4f1a61b286dc9d7c47955e84e8c061ddbf9c20dc8626bcf692425c84b7e3d57b66711b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB435eFc15F408171AD6AF1A75C42a525F0974Ed2": {
        "max": 2,
        "address": "0xB435eFc15F408171AD6AF1A75C42a525F0974Ed2",
        "signature": "0x5ac9cc349be64539ef47d5bde2fbc028b9e6f8d11a87b5b421336e6ba3ce68b502bb55513151ccf72db4b275f909fb33756c7a3cdb604a2cb8aed1e8bfa968071b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf4e06c6941D36f6432332C052dF946FE05c3016B": {
        "max": 2,
        "address": "0xf4e06c6941D36f6432332C052dF946FE05c3016B",
        "signature": "0x5d0c7ece4aef929ad24a37f3f36d74874eeb2a8dee8eefaca4cb5ecbc76a8f9179944d548ef09dea9f5dcbb309e63bb4734241df6a0db665debc5b38725a70c61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC3274656D381a8bb3878A813584F63dc62d3216F": {
        "max": 2,
        "address": "0xC3274656D381a8bb3878A813584F63dc62d3216F",
        "signature": "0xf89d302aa704e7040cfa040087c46e1a873e98bf0d277478c6235ded7338ff6934223110e990a0aa651a53fc6fd74f1cbcef033528db897ad2aa0f7a6a2310e41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1B67b59E6f41a4BDD19785183B2770B86aE2B5bE": {
        "max": 3,
        "address": "0x1B67b59E6f41a4BDD19785183B2770B86aE2B5bE",
        "signature": "0xb228d5c048549b9c0104f2ab8ff39dc19ee2f9cf577d31b8873e7797b1987bdb300e054b125d16d4510fcf7b3daba28beb8b98ff441644f8a344a31520c019691c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x70b33FEb8fe545926D1735e44837691926cFC00c": {
        "max": 1,
        "address": "0x70b33FEb8fe545926D1735e44837691926cFC00c",
        "signature": "0x1766d322a720b0e64ce4f3e9b5eb68c68a96d1696deeea4d1723c1ec3443e12b435339b6f19db4a006a5eba084d92675631ac0bb62f7585db1b6c4cd1fb0b8af1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1CC944b061705A3d56207EB9dd1B17Dd8C8c1617": {
        "max": 2,
        "address": "0x1CC944b061705A3d56207EB9dd1B17Dd8C8c1617",
        "signature": "0x01c3451160369a2ea90eb93120b83d814d1da21312453e5c6c495fbd0daf4a332174d79cbd97d029515d19bf0c55c0cc618ddf550791192d97d3fb9d0f7eaed21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCACa8477e84fe1509420B2E24790c5e4047Bc605": {
        "max": 2,
        "address": "0xCACa8477e84fe1509420B2E24790c5e4047Bc605",
        "signature": "0x2e8f5753f93d7cedbdf5cd5a68bf3d560cb78ac8e24d01c78a7dcbe160924191496d292423afeeb4c74c69da6b0b83d3bc4565c6c68ea404bd8ef580529786881c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5e14C14B95D6f588D0C8FDcCf375ab9a892d75dB": {
        "max": 2,
        "address": "0x5e14C14B95D6f588D0C8FDcCf375ab9a892d75dB",
        "signature": "0xe90c6c51fc6c8c9c0d0aa100f28feafc9135eca4e44b179e88275fdcf47b07ad799205d43a88f36124327ff60cc9d1df8cc5ce633426893c2ec94bb4f95fbbd41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEBA8E6DE5B6bbFe68515BBbB9606304C5521EA24": {
        "max": 1,
        "address": "0xEBA8E6DE5B6bbFe68515BBbB9606304C5521EA24",
        "signature": "0x47abca7f0f943e90d531dc4a2b61735f69b750720c9fb3dd02e8783587ff609d7502774eb40f6aec3177bf18e3ea0d3bc23d47f3bd576170cfc47ac029b0ad031c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeCB9aD8D4FC49F28B799b40796F01977F7113E46": {
        "max": 2,
        "address": "0xeCB9aD8D4FC49F28B799b40796F01977F7113E46",
        "signature": "0xdb5fbe352362c53f228ff5c8f2cdac6c1ceff7d60e9314d4b54b9ac215720c6d5e5cc8f3cb1a7dce3100d7754f484007a575f7bd6213ebac11d3577e6399d7051b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf33565e538cbb6bC143E94a7037245De3167B708": {
        "max": 3,
        "address": "0xf33565e538cbb6bC143E94a7037245De3167B708",
        "signature": "0xa5860dcc03a2418b1977a02c38acd34377591dd767dd2f4fe5a544a3a796e41b6dfbf57db02cccc6fd1b3fab43ff3fe6368218e56afffe4b3932da9a52f617801b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf00401c81be186F5389103332000dC9461A01FcA": {
        "max": 1,
        "address": "0xf00401c81be186F5389103332000dC9461A01FcA",
        "signature": "0x72c9c0ea5c212fe3a02071e813dd545edf5becc309c2468eba101a039860f02f3a888e2343a8350f35ed3292d9245f3e9b2447104cb14c579c38ee6d3e9b9db61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3B8e8eFA0aF51a36eF296c8Be8BC2c9fD7956758": {
        "max": 1,
        "address": "0x3B8e8eFA0aF51a36eF296c8Be8BC2c9fD7956758",
        "signature": "0x15d106f28ccb7ddc9f8bb82927ee79d91ffdb3828a98cde7147bf157f93e116865c77e7784964055a258d80ca99a02be066087be7a9bfe7c8ac39daea3d2cf841b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x79C5146BD916944B4f4Aee4c2447644BE2B78e0f": {
        "max": 1,
        "address": "0x79C5146BD916944B4f4Aee4c2447644BE2B78e0f",
        "signature": "0xd4bcd4bb203a7605cea58483d2f9fff02b85d7e4eede5d641a6bfc0b1d927d5a125669cb9cc32e4f6576bd47ac0f65ea6894e488735ca47b4a0b5fb948d226dd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x63e5Ef37a5Bf2e71F6969a1988dad5f9890E6dcF": {
        "max": 2,
        "address": "0x63e5Ef37a5Bf2e71F6969a1988dad5f9890E6dcF",
        "signature": "0x563c5479ae4f056b1746b5665a3b3ae79ed241bbb99d06358e6386a2096306cd53838bd711c55efdc497879f9cd0f68c308e91f02a3bfdf7798127c80bad4c0f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2C0cE11e6569bEAB9F0235a743f8d3d4e45AeC37": {
        "max": 1,
        "address": "0x2C0cE11e6569bEAB9F0235a743f8d3d4e45AeC37",
        "signature": "0x7443b7c2beacc7989280bcfadd25b2f4c624f76164774f0f82dddd5f6cff70ed6ceb393ced175eb42175a66a674f5d7ca5138ffd62c1bf9afac0019417ce90211c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1E6CF79e51C8E8e260bf44808061A920AaEdB381": {
        "max": 2,
        "address": "0x1E6CF79e51C8E8e260bf44808061A920AaEdB381",
        "signature": "0x62bffba27d470e004475d81280dd11e2e1bb926480a095a74cf46cc715fb3fa97ae4a7bb78773c15e9c4512a445f35a2e4c88be943fb3e906e9b4296961f15fc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbD363599B9a7C532682AFfd88Fd4109765636860": {
        "max": 2,
        "address": "0xbD363599B9a7C532682AFfd88Fd4109765636860",
        "signature": "0x541ec86ce366e5c1c31bb5d825a453235c33895e3fa5fa09768e471ee573eaa40252b66d81189e7b0219e19ee348575619a19c78ef57603708f79565d3d8f61d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf5e2dEf2D9a9bF43d4307Fa6FD0f89787E3c76E3": {
        "max": 2,
        "address": "0xf5e2dEf2D9a9bF43d4307Fa6FD0f89787E3c76E3",
        "signature": "0x086e6b6899a8619ea57f9ce9216d3bd1e810bdd2850149abee261f3129ff05c9001d2e921d7bbe9fd6cf66041c4850e73bd469fa13650f8ed8212f89d4913e8f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf2960A9fD6AEC8316576a1820D80204677188E03": {
        "max": 2,
        "address": "0xf2960A9fD6AEC8316576a1820D80204677188E03",
        "signature": "0x7f680934ac032ee330a924c4761163488d4c020a5d329c9f066b9adaf0d30d78331e3d4278174bb92c8eae96c46b521f2e7b6830d7a191d25c0d7b7137f848eb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1368328A31025B433c756A464951C423e87B5547": {
        "max": 2,
        "address": "0x1368328A31025B433c756A464951C423e87B5547",
        "signature": "0x7b84401a84ba871b2febf68b9122e842283637c3c75f5d9b1882aea5c814472e7e3270ed9996cf95fe3588a09cbdb58e7aa90d19890a00c563a227362345d1791c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd9D57fAe874292033596618c0E1638F09f752743": {
        "max": 1,
        "address": "0xd9D57fAe874292033596618c0E1638F09f752743",
        "signature": "0xc13ee445d21faf4f6c0a34432536f6842afa4ccdd44b262f90bcb8ad5d06624a15f32e1528ac9e162e4d2739ae92267d55fbbadff0283afe5d4d10d7a465e20d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x871f304FE640c2df1545930573e2f303Ab7a1235": {
        "max": 1,
        "address": "0x871f304FE640c2df1545930573e2f303Ab7a1235",
        "signature": "0x189c8cf5fd9d782e409db93b23ea18e66dfeb449ab0ff78a5cb64dd2816f3c0d212fb7858a47a9ace50a343bcc6fd82409f73d3c6e0ad520a3939cdf4d17553b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x94D6692276D0160a1C073619e33077ae0D3e4aEF": {
        "max": 2,
        "address": "0x94D6692276D0160a1C073619e33077ae0D3e4aEF",
        "signature": "0x4c0af8188be711a020782248150b564728cf77445d63ea49b29bb26acc9c7ec435e628bbdb4222058524dceff2dcd617ea13212c441535fd0a6de151c235a4ae1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x394b0CA58672253287a2b4BB2EE8ae73D3bad4c2": {
        "max": 1,
        "address": "0x394b0CA58672253287a2b4BB2EE8ae73D3bad4c2",
        "signature": "0x27c9ff651add3def7758f8d43a87fea07d23a93b090f407a396a223fa205cdd075a10b82d99714ab1333b5111bf0bc0f8529ea3faba60bfa78f00e613e5cb5231b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc25E8323dFdc425aB465f49bD1e398326898B7DD": {
        "max": 1,
        "address": "0xc25E8323dFdc425aB465f49bD1e398326898B7DD",
        "signature": "0x0f6cc1b45efe9d608bf630cdf3dc8fd4eac3647f0827a5547b98b760e8c6b65f2573f0c2ae43d1c93bf04ad873a3c05552e9d9dc53325042a47a1485667ba6751b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3Ea458b5E4aF1fEa5f6fd0F37098d54E0931B98c": {
        "max": 1,
        "address": "0x3Ea458b5E4aF1fEa5f6fd0F37098d54E0931B98c",
        "signature": "0xc4a3845dfce1059256de40a5ee0acd9910362734ffea14059d7e229544b811cc1689de8ee3c60b2a21afcba0098691c50d117c9f2cf3fcd4b874f36240c342e01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa4d3aE6AE9c89cfBC7603baf0D171D891237b0Ac": {
        "max": 2,
        "address": "0xa4d3aE6AE9c89cfBC7603baf0D171D891237b0Ac",
        "signature": "0x9c53fde2b3476bce122776ccd50e1c7c1e54d6042e47019392e80422a147326b5be55006452c6864ab7ded55fc65cf05dd0a681aa7650da15061cbfb17a4f4c41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbC1eB4359ab755Af079F6EF77E3FaAc465e53EDA": {
        "max": 2,
        "address": "0xbC1eB4359ab755Af079F6EF77E3FaAc465e53EDA",
        "signature": "0x0fb74243df746c7620a8ee607fc384c6351e94c14d3d4c2a9e09797b18cf0def7e8efbbb434170415dd4edebe9c83ecab6eecc7a2df3a0af0ba213ae5f17420d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBe54b0Bcd94Ae051bDe8e537FF3f50eDC1380C24": {
        "max": 1,
        "address": "0xBe54b0Bcd94Ae051bDe8e537FF3f50eDC1380C24",
        "signature": "0xb13cdbd6aa89d8679af35cd5a93518fb79eac6bd308b06c9d051ce701398c1a67027a5c9e3e718ac1a28e0ab5c04b126e3639c7d3eb3e22aefefe815e6dd39c81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9F12665668e0Db6D95F216612a0D9689bF23688B": {
        "max": 1,
        "address": "0x9F12665668e0Db6D95F216612a0D9689bF23688B",
        "signature": "0x9db7cbdc6c0a7c0dd070eeb289f6608b0b82bc4f5d9f590541ab0a7e78d9fe3039661a05f4f9d6c6beefa1c4b62a6c96382877dea1561d56fbbc7a66a76e4a531c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0579eeC9235AFB8522753897eeC4793660bcf60e": {
        "max": 2,
        "address": "0x0579eeC9235AFB8522753897eeC4793660bcf60e",
        "signature": "0x5c5735cb34ee194c1afa0949fd5c80c890a8b422efae410b00a9d5b2606931c25838fe8a35defaffb1ac077de1b5526644d111aaad4285961e9605b70872a5091b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbE8e4763d1Bc9E1867434dDD78A7b7e1B2aBfE8A": {
        "max": 2,
        "address": "0xbE8e4763d1Bc9E1867434dDD78A7b7e1B2aBfE8A",
        "signature": "0xb0c35aa48f89b0338b87d0141262d67deb5c13f094d300231d7ef52987a9aa2a431f928a67dd31bba2b1a7b101eca642de0eb82a54229a67f8cb484e626b9e7c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2b11B98F18F161A6520E18768C945f884576044f": {
        "max": 2,
        "address": "0x2b11B98F18F161A6520E18768C945f884576044f",
        "signature": "0x77a373a5d83b3daa9181fcc458458513f077b4f936b794b75b11e010dbf18c84024bc72a76f34c76f92507dce481fa552cf24b280321268436707805eb631ded1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x97Ebb3bDDEe93502601D252e5550663E4542bc89": {
        "max": 3,
        "address": "0x97Ebb3bDDEe93502601D252e5550663E4542bc89",
        "signature": "0x0bc4221649f8ead71c46218cbe551989152221fe250d1078adc48c6c936387ee073a98ff71ed6962c6198ae34970abac3b541ec98e1c7eeb08a2309197354dc21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x044Ab09cFd9312695F390AE1689C4661454fB64B": {
        "max": 3,
        "address": "0x044Ab09cFd9312695F390AE1689C4661454fB64B",
        "signature": "0xc2608b3db8f7c1cf1bf35719973083628e113e128086559b1abb261983749b664fec37e896167f8738ef32e9e1eb6ab8422af4510a0e2b78eb5066c3c61e675b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCF3878A961131AA0DE3d48E41154047CB89e68F1": {
        "max": 3,
        "address": "0xCF3878A961131AA0DE3d48E41154047CB89e68F1",
        "signature": "0x750241e10bbc99931757b1ea1f253382bee43ebb263e4ea9188668ca4f97e94875617cf70402fecab589b04a2efb8334210f21893bd7aed384bef878c5db08f41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc9D7C09E32ae8B422047fa9c8E14ec4dba671692": {
        "max": 2,
        "address": "0xc9D7C09E32ae8B422047fa9c8E14ec4dba671692",
        "signature": "0x68391342b8ffa61179e19468dbfded5cf9dc5ef39c2183ce17309a144053c09c0d3937231a31a93915b5d8d5a14430f6f001224cf8636b524d35bf9d65a444a11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x91a138068DB5f11e131C698B02a0c852E3365342": {
        "max": 2,
        "address": "0x91a138068DB5f11e131C698B02a0c852E3365342",
        "signature": "0x4ae896af6aa6dabe7f41a978efea49763b142e1d3f0b0e714aa7d972c464c65634949f541eec2d1de4d98b36beb948b8d3937a0a7fff84605d1248f3a9281f1b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5790DFb4588759b5c2094CD170F1ec8D8c2af644": {
        "max": 2,
        "address": "0x5790DFb4588759b5c2094CD170F1ec8D8c2af644",
        "signature": "0xe92c81db29d719d9199b36d942d2a17af7ec76212a32b1095518a015dcf906a436f6d3537512221b9993cd050cebdbfcda25ba358707c6ba56214da7f34be3fb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0170fF73174f1F5c0Cc14CD84522e5480c271364": {
        "max": 1,
        "address": "0x0170fF73174f1F5c0Cc14CD84522e5480c271364",
        "signature": "0xf5e55b944a7434b47283b4d6d4176e96cf5b37cae2f6083951809790e10bdda97cf1b7edaf5112860adc205ccf022d58e59483b5974354bdd56386475f521c9f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x07Ad709EF37aea9049A1504Bc8580297F9E5584f": {
        "max": 3,
        "address": "0x07Ad709EF37aea9049A1504Bc8580297F9E5584f",
        "signature": "0xd4d2432a30f3f101894a6760155fb8c2739398d3dc7bf0b018ebefc6219abb1d324c50d58ba85137f099c3246c3146004267871f00defcc8b004f01fccf62cb31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf44fe30CC0E65b798cEeF16B40334768c52A5C9E": {
        "max": 1,
        "address": "0xf44fe30CC0E65b798cEeF16B40334768c52A5C9E",
        "signature": "0x70fcd700b4f382268e41db5c7a7ff8c11cd218897a266c45d310a4f74e7a118a00c1423cc2d1472087f4fd34eb5bf8cab6ffea0b00493d9ec63400be7090509f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9961BEb539fE8F3f392D675A3dFD148533B27313": {
        "max": 1,
        "address": "0x9961BEb539fE8F3f392D675A3dFD148533B27313",
        "signature": "0xdfca5280569715bf70bb0219a7972aa7d2bf2efbf4cc933128f8f8b3a2c885474d154a77e33a19b2afa060743280d4e20702cabacfb35d21f2839ad63dd7caf11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x321bdBaB5ba59B0591Ac40c3642EFfD2a8D94f97": {
        "max": 3,
        "address": "0x321bdBaB5ba59B0591Ac40c3642EFfD2a8D94f97",
        "signature": "0xaabdc47e7f3a20b6e5ce5fc8687be4e102fbea0596bcdbe741cadb885e9322217f7fb1539c2642308f116c5fff8e98645aa70bc60437762d396e035566bc71dd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3E0714C14F0423A6E0381659491EFE078595315C": {
        "max": 1,
        "address": "0x3E0714C14F0423A6E0381659491EFE078595315C",
        "signature": "0x5c281da073957a4a9f3bdb91da3d99a27a9c1ca4e54648ea9d91084083096b145a4c194294680dfce48b9c0cac35ea235e2b2db96d01bf60406f0962df3ef72d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x04d90Ba07801A57D935837E7F6e6522D4B6b7075": {
        "max": 2,
        "address": "0x04d90Ba07801A57D935837E7F6e6522D4B6b7075",
        "signature": "0xeefa5e5b9c9b9a777a7b4efcbb62af65103da8e597c31dafd34f2b18f6d4f3f376f3958abadd7b6f8773a54d3dd25ab0af843d233c218df100ab958e87c5f4871b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x16846fbA5ABDB9535dCDA7fAc27779fB95cfA2D5": {
        "max": 3,
        "address": "0x16846fbA5ABDB9535dCDA7fAc27779fB95cfA2D5",
        "signature": "0x58570746f0eb198546dff11fedb25388eed3c1e8331f59bf851ed83f8b62570b541a92af3fdec51646ac1c72cdb2a0d798412902a463689f509dcaefdd582f6b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x853A0AAACBe8B717215E15dfb25F6e1E177A6511": {
        "max": 2,
        "address": "0x853A0AAACBe8B717215E15dfb25F6e1E177A6511",
        "signature": "0xed4f221ebc3cf4dd4a506aa60bd8ecbd82d5d13165ee8fd6d8d983bdb773134b60117b64f90a99b924ead5081a9955b2bb8883276f11be6138446fd3b5e125f21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2e3EC09Fb80c35527377AeE04f35059f0DEFC209": {
        "max": 1,
        "address": "0x2e3EC09Fb80c35527377AeE04f35059f0DEFC209",
        "signature": "0xf905b1c7ab3f9ff41c41267017c3801acfc3a126159c4eba6f7c1b73d392c9846fbc2e5d20848b39e3e65959beabad5a6503296e91f1f4ef08874850b38492081c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEb0311C5d6E7157F254bBcbae44C408429B8c472": {
        "max": 2,
        "address": "0xEb0311C5d6E7157F254bBcbae44C408429B8c472",
        "signature": "0xf82cd550a4ee9ffd4daff71ab6da55e0da01ddf7f8e38413911e104668a428a81d3ee20bb11fb33c3183c9c68a6af7b7c3f5ac0c946c7d4fe3ca43f5e6d318751c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD486BF526740200FA87191DEC5f0cc622531dA21": {
        "max": 2,
        "address": "0xD486BF526740200FA87191DEC5f0cc622531dA21",
        "signature": "0x6e488680024819b598867b706c276463a4216706d794b191d6f630c80606791432573cee4138fc5dfe16e8e199679845195a82396c976560f0b4cc70d4f065ec1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x683d119bC9310DfeE6e6bC1E782C5F8Ff4bF8d52": {
        "max": 2,
        "address": "0x683d119bC9310DfeE6e6bC1E782C5F8Ff4bF8d52",
        "signature": "0xe619fe30a11f098545ae28ba1ccacbb48f39dd3e52667d1a6b0bbd5eb751ffc34ad1ad891b200f0d4d2d6e739f360bed1575de733665e283275030036c0bf8831c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf290D0b3aD096d3DdEa6218ac145cAB7290eDfdD": {
        "max": 1,
        "address": "0xf290D0b3aD096d3DdEa6218ac145cAB7290eDfdD",
        "signature": "0x85eda9cfb4b479de8fd52738e0a7061c4ccf76c5c6e8751b6301b819e7dd96ca4f197eab1501e840f1a877891cb6379ea431a61e0c3ce52debb05eac5bf209ed1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5A3F81D29B66849a7F986D39Df3D53FA6b6470Ae": {
        "max": 2,
        "address": "0x5A3F81D29B66849a7F986D39Df3D53FA6b6470Ae",
        "signature": "0x55c2aafe8cbaa4ad929a85d8c5a3af940b2f34d989a6f8febc128d3da2fa55b53b5dfe73a1716de1bd06a77bb16d1ea59941497078b5956fff91d156bd0291601c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03DF49D8A38649af6CbE6299F00Fe086EA0bAE8A": {
        "max": 1,
        "address": "0x03DF49D8A38649af6CbE6299F00Fe086EA0bAE8A",
        "signature": "0x86cba4d71449110fef73b88f6cde21099a093656b66d9a2898f77a34ce0bfc3f29343aab8d5614078476898291374d8919896c06721f9784ea8ef94639105ca81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCD22821a1589eF265D4c9308b3d6ab2f161082C6": {
        "max": 3,
        "address": "0xCD22821a1589eF265D4c9308b3d6ab2f161082C6",
        "signature": "0xff490a03f42f028463c30d56b0ce1c8c8e671b903a67c3a65c9666da2adab3786a9ef19cd7abde4f2bcb39175a68acdbd48603fdae50fcd1678819427ebd3b211c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x859902BC140e5D4ed3295C859D4cd4F616c7141F": {
        "max": 1,
        "address": "0x859902BC140e5D4ed3295C859D4cd4F616c7141F",
        "signature": "0x68b3bf045cb16ae28bfa34f1d4f88d0b02e1a5335fb687d62e61e5d2737350f75808d909f2a9e93234f54ad918f4f026260d98243358a5404209a806fbe8d3f01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7F41275857C248CA013E44e628b67011F99c4213": {
        "max": 3,
        "address": "0x7F41275857C248CA013E44e628b67011F99c4213",
        "signature": "0x316f2cfed4d034d9ba538d17433300df0ee6578137e5b8c8a4bb7e2f3669c46e139bede03093edbfbd1a4e7aeb827eef65341f1fd041ad9a5e5eda8934bdc7dd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x02708809F45059856BF012B73087CfDc10717EaD": {
        "max": 2,
        "address": "0x02708809F45059856BF012B73087CfDc10717EaD",
        "signature": "0xf286583024f21bd73064e9a708286d818368bb74b7b1dcde2ffbd986fe65bac75800f73fddbbc7750684763d95288151f57664989e581a2d35396a08f52f94de1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb6705e7074f9f4Cf93DD79C020d6014d760BA821": {
        "max": 3,
        "address": "0xb6705e7074f9f4Cf93DD79C020d6014d760BA821",
        "signature": "0xb71a1d57e3b38fc54ea4a104f99b71a6cabc77cdcac5ef48a9fdb1fd2428e80b4840d3fd2a8c02af6ddf059ce381ec455c6b4d6fc0284b8f812fd18314b49d231b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x640B362c1EA20716EF9A9CDF8B19EA371Ab18E20": {
        "max": 3,
        "address": "0x640B362c1EA20716EF9A9CDF8B19EA371Ab18E20",
        "signature": "0xb682513b0abc98d0cb696fd283eff8f0c2350232c7bc9a71a04b4425980db404569977fef723c92b3b8d66f4f64be70420c02fcc89280415ebdccd94568cf07b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x843A46D1405F22903Dd1cD1aD80863dCA236bACF": {
        "max": 3,
        "address": "0x843A46D1405F22903Dd1cD1aD80863dCA236bACF",
        "signature": "0x0907eff8d61396ce36c506306e1314fac5b7e55d073b6afbf251f515631be60d189cafc1076215d9ea214a8cfe2e1f019651ca18c93a9c83e27ca6f2158592bf1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcB360CD9ffF196C55a3c70A7d1aF2b57d01BaEe6": {
        "max": 1,
        "address": "0xcB360CD9ffF196C55a3c70A7d1aF2b57d01BaEe6",
        "signature": "0xe7f40483be1a48f59a579396b34fd9f9b927f930a5ba3a91d696a4e6a99d3d874feb5a555ecb59f4037beb03f2db6784033c9ad6eaf261dbc498f5dc0b94b33d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x84d25c65CB27af81F9f2AF0841e16390Ec0A7019": {
        "max": 1,
        "address": "0x84d25c65CB27af81F9f2AF0841e16390Ec0A7019",
        "signature": "0x55f411325232ae2f2132c1c2edaf850973855a8d691e136f042a9eb0d21cc7701f4b2799caf28ba0075feb4348e88383bde0c73c411b81a38ae75f9f3517431f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc21Fa011F5e802017C69eD5A0e4F6D94E404fAFE": {
        "max": 1,
        "address": "0xc21Fa011F5e802017C69eD5A0e4F6D94E404fAFE",
        "signature": "0xf7971e644507cb06393b5eb2d6a158877ad18013e2d9eb27ebe7ee1233fd25ad40f2ac658980cdeccea67a8ecfaa685c81b15b7df152438112963e01f0a3bc281b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC91C44BB5CF06AaeEA920f9A6a9736D4f533E647": {
        "max": 1,
        "address": "0xC91C44BB5CF06AaeEA920f9A6a9736D4f533E647",
        "signature": "0x3ead36a46c5ec986befe8d679e6294bce80a901d7547b96a87c75057541c9de143ca54e11449fa19ed3215eaf43606392006940f3b38ab7384be371bfc0d280e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCAb4161aE91900B9Cbd1A3C643a84dCb66F241BD": {
        "max": 3,
        "address": "0xCAb4161aE91900B9Cbd1A3C643a84dCb66F241BD",
        "signature": "0x3f833658fa7b53f18efd09a8ec83cdbabc2daa8ab2c75d694a340ba93c0d64076174b79c12d4c85be22e5d57ae6ab78d6a17a3f1b94c84d2990023c1b1dad1f61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb8EF5c01B9eEf0a166a6eb1708D9b17351d7cE0B": {
        "max": 3,
        "address": "0xb8EF5c01B9eEf0a166a6eb1708D9b17351d7cE0B",
        "signature": "0x34c7e4509167b19248564e09acc513ff783a83eed0d7e03c1b532ad54e6b2e21512d389c6585f2bcc21d1e5e44bf2187b5f22433c9a4fc77fad2c282e7a7ff161c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4bFde9c1Ab8887452A2a9fB80b6F60e013108eA2": {
        "max": 1,
        "address": "0x4bFde9c1Ab8887452A2a9fB80b6F60e013108eA2",
        "signature": "0x5fe59f97b0851f843b57305a296c47319f3c756032228af3de66b595040d6fad6e7bab39d2c4114de37d74538e2ad8b58de86a53f966c5e0521ba079285e01551b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5DC2F128110Da2c4255B67FF26df7fc92D8988AB": {
        "max": 1,
        "address": "0x5DC2F128110Da2c4255B67FF26df7fc92D8988AB",
        "signature": "0x2cfd83d6ede537883d6fa238c76cb29255a652cdceaff474dc98b787b53d3b3229db4be94d50d82f2be417f02509213ab6a326cd3877c37dcbf01c6bb297d1ff1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x608D24dB8C6798Ad1344e4b01e34c2731238718c": {
        "max": 3,
        "address": "0x608D24dB8C6798Ad1344e4b01e34c2731238718c",
        "signature": "0x5b6441f10236769f602c40eaae28ce550fc6497b88c9f865fd01dcb74301f4913a5fd18e4b84b32860a20fdf009a68aafadf9e5c455dffec709d6d0d9218fab11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xad39143e77468cD057Fbf37150840CCFCeE1F385": {
        "max": 3,
        "address": "0xad39143e77468cD057Fbf37150840CCFCeE1F385",
        "signature": "0x217bddec016b48408e02a5ba7ecc75e12d13cfdea0338273d20b7692d3b490f8001c4f823fa59cde9a12dffcfea6dcfb7a8f1c9a09151e651ed1315a4bf291fa1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3EF8A565Cea4F957f3921B73e290D9206dC7d027": {
        "max": 2,
        "address": "0x3EF8A565Cea4F957f3921B73e290D9206dC7d027",
        "signature": "0xb9fc315b88b4c8480b168d3bd3bddf6afc56e80c4de92e78d02ffca85e1c58fb65e8c162c2b7292e8ebd96efa674dcc0bcb75323643313616fb24adb81843f451c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x40010783B30A54046983A0bCB705049AbecA4bA0": {
        "max": 1,
        "address": "0x40010783B30A54046983A0bCB705049AbecA4bA0",
        "signature": "0x6bfde69fa07573e10af72a2c5532e3ba6cbeb9e5145a585c8f2d7bc28ea81a2a14044626f21f0384c5a1d9d08faa8d9ad00e79d50614d9a9615c5b3daaf875f71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6BeB4eeC23A17e35045abe2cDeA0e70D5a19aF67": {
        "max": 1,
        "address": "0x6BeB4eeC23A17e35045abe2cDeA0e70D5a19aF67",
        "signature": "0x7f514bbbe511a91d5413bcfdbed929f70ee032fda5ebb58c8c69e292c7c1ffcb17b3d128af4b34c8ec743f81412bdfaacc15e230dc584c4c73e14a9e5b5084901c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x187022f33B178d17d1369fB0b82115B6415cFdc4": {
        "max": 3,
        "address": "0x187022f33B178d17d1369fB0b82115B6415cFdc4",
        "signature": "0x7dc5306945eab4d9d93d15d0b5efa09e0f6a45d7f63e580b7f57bdc5f0f2e4136f4f12e94529b936f096c66f2c2a2b7a18610f61d6e5c13a60dfe1ee163ac8401c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2fB782b5B34B8E1CCc622966D57F33c6861d28FE": {
        "max": 1,
        "address": "0x2fB782b5B34B8E1CCc622966D57F33c6861d28FE",
        "signature": "0xcd9010a909188fb839124e2b012598a065f81adc974a2cc3e14bb0c46f747f022eeae8adb478408081dca1a58b50454bf9629a99af9df8c173023bffafc73bf61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x51E347643a2f96D97f014e7A95f7BAFB88Fa3A87": {
        "max": 3,
        "address": "0x51E347643a2f96D97f014e7A95f7BAFB88Fa3A87",
        "signature": "0x65b5da7edb789b400f1734541412936456b841ca5e18a477d16d47bc19872e22506cb05cc3c1e91b859a5b0362f2da70021450af777ce360dc4482b85e836dd91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4bdb8b565805B27b9D5C3c14104A706fd08ae373": {
        "max": 1,
        "address": "0x4bdb8b565805B27b9D5C3c14104A706fd08ae373",
        "signature": "0x197b5d0134a788a9e5027334d9319aafb0af22fed0ab7984b831a35820c2cec50e262ea62f9478e5c54e0b4356ade6022b2e6532ddcdf12b91543b4e04bb805a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2252390c7294e9CC7CbB3698E6F74DD07b5ca837": {
        "max": 2,
        "address": "0x2252390c7294e9CC7CbB3698E6F74DD07b5ca837",
        "signature": "0xdb85e776849c5e92c4c367ef18c1094b6c6255f5d4a7ec500713e2e75f8179943e456427b3eb7b3e9bf420580ee75160b0eaccae665c0fc4314f8f3ab66242c41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3E238207184db63B0883E8B63488173014a26165": {
        "max": 1,
        "address": "0x3E238207184db63B0883E8B63488173014a26165",
        "signature": "0x89f5d3c3744763bc98cd59c64dd9f36e3f99e125a569b41bc39ebdddbecd93dd4ec2c4762e4c1ca293376317b81de1fb5b132c8b1cfda2f9a389ae04fcb473731b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1F0512de1856662b3B621E910d6B102b117A53E5": {
        "max": 1,
        "address": "0x1F0512de1856662b3B621E910d6B102b117A53E5",
        "signature": "0x15a42fd00ccab79475beb8a08219bb3b74b24280f2bab0bfc521417b0f19cdde320752806767ebfcf31a5b8a3219d66ff0f190be3136d07ef6c3377ea1c700af1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x213b7a458A77385e0C12096CE7cD44a04f51c0D0": {
        "max": 1,
        "address": "0x213b7a458A77385e0C12096CE7cD44a04f51c0D0",
        "signature": "0xcce3b247d1c9b2e277d794904d79f85f2c7dae60ef9d1fea2545ef28fddef99e39e777de9707fe87146743f43873dc74f5129d228e90f741dbc8dd988842fa0d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb5e8a8Cefce0a0A8334bfEF214eeC14998D973Cb": {
        "max": 1,
        "address": "0xb5e8a8Cefce0a0A8334bfEF214eeC14998D973Cb",
        "signature": "0xd40708a8f480fba77534414ff6bcfa82b7fcd07b8afc4ab33db1ba166c54c65f1d1df5bd14be972919b3066463a5b51082f13454542fa93a17e8fa0b0efb4f921c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf210d9e74363C21137535c5Bbf41Be5526b11864": {
        "max": 1,
        "address": "0xf210d9e74363C21137535c5Bbf41Be5526b11864",
        "signature": "0xd18ef4bc13430d45877ab9e34e1b2f7fd8858e7486d9c04e1b20dba9276d7f2a6f7c8d3e106eafa86e28bea28a05f06b3a0aa6ca86aca1fe78bf77b4a2dd34601b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd6964A032f2F3498aC12cD859b0c18AcC03041eb": {
        "max": 1,
        "address": "0xd6964A032f2F3498aC12cD859b0c18AcC03041eb",
        "signature": "0xbef10f5bd497d67d5517cdb5f9d4f32b6b7ec142b7105300b3a1d2bee05e69b85b6578dbba6c9b7899b618336c7637b2dc94be6cbcccd941bd002e7e0fdb95ef1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x07C505FFec70870710686A545B813131d9218Da1": {
        "max": 2,
        "address": "0x07C505FFec70870710686A545B813131d9218Da1",
        "signature": "0x4a5ff011de7476e16a65f78404e126fd389750fb242b747b291d677b5683582e56bc187c3b7697578b68924dc768ab41852d17c1f727886a4107b613a8022f531c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x44a253BceF92AE0c2Ac8031D7Db1540d697Deb00": {
        "max": 1,
        "address": "0x44a253BceF92AE0c2Ac8031D7Db1540d697Deb00",
        "signature": "0x7e53aa9c540dcbbd1c858258d77fc5c1b68e212cae059f9758f4e7ca594ff328203f1b8776c51325f191e5227a37d18e557c59dd04cbd21e63e0b02a5175c7991b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x37C2a2850a4946Ac183C7b63D2865529ba4434A8": {
        "max": 1,
        "address": "0x37C2a2850a4946Ac183C7b63D2865529ba4434A8",
        "signature": "0xeeead17df04c0e36ba1192c796a9058e105f12363d3c7f44f57bc66da097ec3e3b40d700075cb1bbd106dad522de597fb5372d0d55e7ad65ca90d587743921e51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf616325282bAC107032F19A007FdaCc59D70Ad92": {
        "max": 3,
        "address": "0xf616325282bAC107032F19A007FdaCc59D70Ad92",
        "signature": "0xeb6f5eeaf57d46a2e1c447e21ae7ab5cc25d58fb84b93207fe243a7e3f41bab163c19a0208cb79bb31421d89e942433178b6b3fc8846c9570cd36235260a129a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcB9Cbed6Ae8d367891047D31318c5e379ebF3c13": {
        "max": 2,
        "address": "0xcB9Cbed6Ae8d367891047D31318c5e379ebF3c13",
        "signature": "0x471a81191490d73c83f38ef3938d07020c55b693b73c3c1413a1ccd8a255662b2533a9fc1fc301d0d15e1147cadbfe7116a7c2418815c20b767b2b004c8d26781b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x810d78229A935587A0B9aC71e534362407FC81b0": {
        "max": 2,
        "address": "0x810d78229A935587A0B9aC71e534362407FC81b0",
        "signature": "0xfe8f1c7314aec3842e957c88b9d5f864a9cbe585c5758202f78651f596bcbd692e8f9121e0555aa307ad27e01d3af255adde117bf11400646a9449a14fd51f281c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x86A050eB5eBE79a9d53da8ED4351bA59A3f4D81D": {
        "max": 1,
        "address": "0x86A050eB5eBE79a9d53da8ED4351bA59A3f4D81D",
        "signature": "0x0e655a37e4cf36d1cad60abb6c3a666405985f2c44b25ce130ce5b603797b3e63dcb8c8a6e8fe6a7946a33738dc596fe4f6977704d267383519e6b3a23e706aa1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x34f8559fD71df4b67F840e9E6180d073e0077c44": {
        "max": 3,
        "address": "0x34f8559fD71df4b67F840e9E6180d073e0077c44",
        "signature": "0x40e23c28cc7116bfc226a15d5c589ab25fd385b7f3f1a7ecb8e84ae258e424ea5b65c28a80c33c66a366b06806d094c858294b06cbbda0e91da62e76370bf14d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9fa03f422B5AAF9C74f0464E5AE7A3C9223d646D": {
        "max": 1,
        "address": "0x9fa03f422B5AAF9C74f0464E5AE7A3C9223d646D",
        "signature": "0xcf09aa36612fba903c56220377fb35625b462b57231e4e0744e96aaef9e0079c1eeebdb8edc8a59665b5353c539ac7eba35cbe05648791108495f17920ff94971c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7C1150D4ed20650e8299Ea6B2D9794E50d87c0bB": {
        "max": 1,
        "address": "0x7C1150D4ed20650e8299Ea6B2D9794E50d87c0bB",
        "signature": "0xe422524573742baf2ee96a8872087ccd300f0aa484789f5980b206df84ef59e3501e2a68d28edd676a037566e3fde47fe9fdf57f4a8fb618e70374356091582a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC9925FE939D1564FCBD64eA76950a1e22DB83fD6": {
        "max": 1,
        "address": "0xC9925FE939D1564FCBD64eA76950a1e22DB83fD6",
        "signature": "0x0e3fd80ea7d658a43bdb237f77ef15e331f549740d2bee9ee4c257fe3b966d2867e70e652ef1d419a09598237126dac306c8644506f6d5a3fa6c240a5eab84711b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x796d05F15b910Ca01f2A9E458E5634b7BE2a5bA1": {
        "max": 1,
        "address": "0x796d05F15b910Ca01f2A9E458E5634b7BE2a5bA1",
        "signature": "0x49ff222fb6216c340b5c915170407b7fb17f183e465e867c595a2a3cb21ffb7c7ab9f781f217bbb78287aa4fff09c9762029ed7d4890dcb33b56119d4969e77a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8Ab066B39118F42602Bb3B4FcA3E35Df0c445f3b": {
        "max": 1,
        "address": "0x8Ab066B39118F42602Bb3B4FcA3E35Df0c445f3b",
        "signature": "0xdf640a7510d121206c9ec193302dc84c7e73373673e7a39f71ea5ed9116886ac19a68090cf1e69f269c63a39cc14fae28a6e949a61ab23209102df695451bc5b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0b26f67b7E0deaB952B74388026c7C7eA13d5AA8": {
        "max": 1,
        "address": "0x0b26f67b7E0deaB952B74388026c7C7eA13d5AA8",
        "signature": "0xd954528417c521640ea363fe822fada66ccd2998873c0349e3daf10f9c4941964752f77ce083028e6de8bc4caa5ae1fae0625e67fa93d825db317f3ac9017e741b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd3B8B06422Dc624a11dcBF2e5589553EF2E9D444": {
        "max": 2,
        "address": "0xd3B8B06422Dc624a11dcBF2e5589553EF2E9D444",
        "signature": "0xed0c6a28e3909bf19145e909d1cfcab84b0c7632a32a37c47b903c929d1080d63e1db7bfab948ede4f347622e4347bec39ef917c95c9f87d40787bb7fe42f01b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbC1F926485956B1aDa816ce71F57Df66b232129B": {
        "max": 1,
        "address": "0xbC1F926485956B1aDa816ce71F57Df66b232129B",
        "signature": "0x900bc7a6c7d7e490447adcff0d759495163e7c7966e7f8302847e0538a0119d77d0b5f8fdde194a4daea1fa8ca3b1db82aa813c6b48d5d1b0e05521677becfd81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfE00AF7082d71Aa151518A1D695765AD760893Dd": {
        "max": 2,
        "address": "0xfE00AF7082d71Aa151518A1D695765AD760893Dd",
        "signature": "0x35bdaec318e0c2febff5697b2f7df5dad22471dbd170a358cd23a6c03848fa68597cf0a498a81e5cc8fad93726dc4d7e88e4417c6f047cc1e36d150d067f960e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE49e824F414d5927D36a3ae2140B2540A058522D": {
        "max": 1,
        "address": "0xE49e824F414d5927D36a3ae2140B2540A058522D",
        "signature": "0xfa91d7dccf201111585595692c9ca299f2f01bfde1903f5065989adfb3f3e69061c09775a45c4eed7a06aac6df7f31fb7c6d38fcf4e8ee0c00e7a70420f0eee71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf8bd27A32363CAE5867B13dc05334f237286C5af": {
        "max": 1,
        "address": "0xf8bd27A32363CAE5867B13dc05334f237286C5af",
        "signature": "0xa06699da2d5768e8174b991f25ebb295dcb11d9f7467c74a948e6216d949781e6bfe9a1c93dd64c605c352eb66b796dceb124e8d04aa653c20ec2b3b325819231b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd8969319492126F9Fcc2A7A8536A0E19554814fA": {
        "max": 2,
        "address": "0xd8969319492126F9Fcc2A7A8536A0E19554814fA",
        "signature": "0xd0cef56e9f0a1558ce374d9319855b20b444025c9ce0083b0711c342b446bc084658eb84beb93858493d8a07251817ee8241d7f0edb63c6658cd23c0f1f77c641b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeF4d2B4420BB04983E226C1FF90B83200a238724": {
        "max": 3,
        "address": "0xeF4d2B4420BB04983E226C1FF90B83200a238724",
        "signature": "0x21415e28a43e39c0b0c712730f113b02e4529b416e330307b4904e340b0ac0c13fa29be066473d14e4cec6c02034786b207a4453298be135dac4d64f41e268a41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9973ADF0AC4353984b85E57f4156cF57F6E69e0B": {
        "max": 1,
        "address": "0x9973ADF0AC4353984b85E57f4156cF57F6E69e0B",
        "signature": "0x13dcebad1cb494700a923abd41023db0d8c9e52d0ae2e2ee4f87ea09ee9eb7802578a4d35acf81316379bed25c5ca24a3834d40449aecdd1dd500c86d909ed531c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x720c3D83e1f540d2cE74713F5196bE640Def1ca9": {
        "max": 2,
        "address": "0x720c3D83e1f540d2cE74713F5196bE640Def1ca9",
        "signature": "0xac4abae9b12c950733ebe27830aa5bb12ff54051e80060ccd4b03652aa06eced0c73fafa45ecf556bfcaf83361c30a4b23696b1f66a07a556519cbd8c25dd9891c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8e1DE44b19979d95FdFC4DF691Df11DBB7FC3C54": {
        "max": 2,
        "address": "0x8e1DE44b19979d95FdFC4DF691Df11DBB7FC3C54",
        "signature": "0x4d7ddeab6461d5dee775c8c31263291fcc55cdd630bdb537e1534b2c0aec591d7ed73094bc29b21082b896bacbf36b7550920dbb66856f0eaefcd06d6e2d731e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x52fA49aB985A7bF769f382be2F80f0156afBd46D": {
        "max": 1,
        "address": "0x52fA49aB985A7bF769f382be2F80f0156afBd46D",
        "signature": "0x499eb78cc56ce8f136c4e377dc5fa7a3a656f8861febf26ef77f2cfaf28ce34e16e21f715dbf3ad41a23b5d258091e39a03f59eda59690e99eb41f8e71bb7b321c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x44c759aa1284F02988badaaCb7e650D7AF41Ec98": {
        "max": 3,
        "address": "0x44c759aa1284F02988badaaCb7e650D7AF41Ec98",
        "signature": "0xd60573726e9d7b9b9331a5e4de22eb920badd5f8375bffe69de3181a4e03deb47976f37a479dc6280842ad6ae55cab78a41d588b3c0734fb5c5a97986473e1001c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCE5AD9125642aF0b425a45cF14563453169A97AF": {
        "max": 2,
        "address": "0xCE5AD9125642aF0b425a45cF14563453169A97AF",
        "signature": "0x1a7043d46c75ed431713bac8b4b0c2a7b3ebe47e1612f44f75e2c68f08d56deb1c8d5495c6d6f414912ef4774266636e192fc89ea7a9273ed07d7fab45317c181b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd3bFc9394b536657460cEC4D28490F8a175E7827": {
        "max": 1,
        "address": "0xd3bFc9394b536657460cEC4D28490F8a175E7827",
        "signature": "0x0aa7f9435e8bfc9ca999c3313678878c510cb513ad4b80588e8f5c00aae34c4508949b9d38eea6adbb36014eeb583a0ee0ab893654ba754a15f1a62e99f029061c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc942237914981fad5815F79F8f1c1292b98913E7": {
        "max": 1,
        "address": "0xc942237914981fad5815F79F8f1c1292b98913E7",
        "signature": "0xfb5dd36356ec756286236c32ea1d161e3340f342b64a6be1391d3c45df9ab82656a935ea53e5a66ece39f702d6b175e8573cdca6bd21a1e3b23723ef56a3735a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x89890009EB4EcE248bce10dc942eD45930C415d7": {
        "max": 2,
        "address": "0x89890009EB4EcE248bce10dc942eD45930C415d7",
        "signature": "0x8f6cc0266eb16bd781e6a5e700bf27f02a029ff185a1f0457af29d06ee71b51215ee2fbc0ab1c99793ac05a50c34e073c0310c1bb7a76e021f89004fa74073ad1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF3399330A96f8Da28881f0eB0a0A7767ED505E99": {
        "max": 2,
        "address": "0xF3399330A96f8Da28881f0eB0a0A7767ED505E99",
        "signature": "0x283eab0ac5d6e7fc8c83d5465bc4be19eca040f3ea03c2d66fb961fa0628453678ef166312df4d0590e612ab0b5103aa15368a6c89200a5f0d977ca366cb31331c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x541856b218440ceD683759079556fd133D89643E": {
        "max": 3,
        "address": "0x541856b218440ceD683759079556fd133D89643E",
        "signature": "0x73a3d7a3b11480a8f6c4661b1826e80953aa4ead1fca0958be95e6fb918a1ae212b0e93bbec8f6115eb3d60bee2f1a3eb3d8aca4ecb9755237ecfad79448bfd31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0166e81f5Efbb836EdAbe1464DC6B2F2B44D5434": {
        "max": 1,
        "address": "0x0166e81f5Efbb836EdAbe1464DC6B2F2B44D5434",
        "signature": "0x58ecd66b617390a88034cc1d583eca9ffb07e448dfcbba5065886ca49309195d426551478370ed2dde39956aa6b6835d212fabc02a63d0ace2c6524dfd39c55c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3D9CE55E1B0fb685635D18C3c2C75A9d6cF1C939": {
        "max": 3,
        "address": "0x3D9CE55E1B0fb685635D18C3c2C75A9d6cF1C939",
        "signature": "0x5d0689b440b3c9e21429c7882f38f0cd78f0dffbde286024cee166045cf1bb7b729e22f763fdf57d7b35395cde5d50b1b86a0254c3a9c6278af95d55564a2b711c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE153E57E75BCfb28835622c0E9AD4Dd7bf0A4f13": {
        "max": 1,
        "address": "0xE153E57E75BCfb28835622c0E9AD4Dd7bf0A4f13",
        "signature": "0xac3d9be70c7c4c76696cef1a767eaa951f8ef52e1d62cca8909c17bfae53bdf17ad9ffee7d50386010422267c225e931f91ddf8142739180650dbbb4fbe7653f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x89e1A971D1f62Df8CcDaCFb11954D8f1c81897eD": {
        "max": 2,
        "address": "0x89e1A971D1f62Df8CcDaCFb11954D8f1c81897eD",
        "signature": "0x9348be492adcc488c4b4e1ffdee14f1229c45971d77fc3a03140be46ffebafc7719c7911cfa99d811585b8490922a5897b226e9d7b674e60819bfe7af8e1f1131b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB6825fe2feE68e8C0d2781d0D963fbFCf6da0487": {
        "max": 1,
        "address": "0xB6825fe2feE68e8C0d2781d0D963fbFCf6da0487",
        "signature": "0xda480b085dc2d7a13402320edffde974246587d8149d15d0e24069cc8ff6f8607164eec9e6705d1ba50e007c6f2cb67587da9f11cb3d56429feff4e42ec821a41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7287Ba6e4276985776E5A14b432A150Ca00688b3": {
        "max": 1,
        "address": "0x7287Ba6e4276985776E5A14b432A150Ca00688b3",
        "signature": "0xb1ea32a5bb85c2368e17da5386df23329c4713ffa96acc9d84f0fe536205f4bf573803c2f64dfa01cd79c38f1b787b437d4c250cfb1582f193b642bd2e46bf991b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7DF04Bd330559D6DAa99A19ADA3de6d3f64E3F99": {
        "max": 1,
        "address": "0x7DF04Bd330559D6DAa99A19ADA3de6d3f64E3F99",
        "signature": "0xc47ea6aa846e027eade33cca0aef88ab69e8f09d7c3e44b64369bc4a3433e17154d9b02f21d26fb110c1495a4665d8754a7a18cceec777f722028870381bd5ed1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb4B1d88127934c6c0531A7C6A4753F6A754d2790": {
        "max": 1,
        "address": "0xb4B1d88127934c6c0531A7C6A4753F6A754d2790",
        "signature": "0xba7157d60b4044bf7cc9d26ff4b71d46f981181405c2722c102bef4472f65a53454fdc26b81d2cf0d777fcbcbd3ade25d86ec9402f4e55fd9f047720f8df79bd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9d121161081B3c76804C3B9E934797D4dE2B4Fb8": {
        "max": 3,
        "address": "0x9d121161081B3c76804C3B9E934797D4dE2B4Fb8",
        "signature": "0x31aabc5c18a28ec0480c1b3ef2f0f49b0b4b7d1fbf84894ccd37a4763745912c040d5debec10bad09c86f5ec3a1cf266d0e9d717beccabb7abbe30e97ecac2551b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe54e6e8B431315Ea19fAfb9038153AFeBb60826C": {
        "max": 2,
        "address": "0xe54e6e8B431315Ea19fAfb9038153AFeBb60826C",
        "signature": "0xf924bb6c7fa1168055828f8134f90ddd425c3199defa88ef01dc48b9b43f1a291bc50fc5b3928e8e40d42d37ec36ca32a4e9d1de3beaf89ad6b9348e17f8699d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9aE5d2Ff5ac839A373C8Ea982e55f58AC57d8B80": {
        "max": 3,
        "address": "0x9aE5d2Ff5ac839A373C8Ea982e55f58AC57d8B80",
        "signature": "0x0439b667361dfc1d5b576187cee70a5e5502e7cd0a372915ab0b2804ea47e71e2f6a3010f68f367b9e954013c3a00dbfc6a608337cf13a77f9d30b672b7f8d621b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf9EEdDAA063cc799A80aDa98E17670Aef29E24c9": {
        "max": 1,
        "address": "0xf9EEdDAA063cc799A80aDa98E17670Aef29E24c9",
        "signature": "0x309addb0a96d4762ea0d56523e4fd0d8af7b80f2a5190c8fd08429405b6c3fd11173fed4ef25630ce43a4b786c00fd30a5422d65ca2e11a7d29d379b8972a3e11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x45692A7bBf52D7913d5a17397A91a8FB253c65A3": {
        "max": 3,
        "address": "0x45692A7bBf52D7913d5a17397A91a8FB253c65A3",
        "signature": "0x6cb8e823731a9cf19785ecd407b1972ed7625e5a1fcf693336178c4bd743417f53817ec0b0d461af10c2d8b89f0135b18fc532d8c9b7794258c91028ae400d071c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5DFb54E601865a0dFDab59fEa18d55F8DF497c24": {
        "max": 1,
        "address": "0x5DFb54E601865a0dFDab59fEa18d55F8DF497c24",
        "signature": "0x1ff338434483d4aa0fed27f7bdf91e45da0ceff6a7971f16db1f0d1dc1ba19050dcd518debda31e4815014776585970def79d6651bc9dfecbe35088a8f01ab2d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x54f1ed858Aa61b1e6355E8a212E8263904d55CF1": {
        "max": 2,
        "address": "0x54f1ed858Aa61b1e6355E8a212E8263904d55CF1",
        "signature": "0xbcc0af33d1004b7bff76ecda42a8b06cbe8b8145dbd8b9d274cf07661658c6463af3926d4b3596b8cee583836c58d62194ee53e128ffae98140bd86e4e789f0d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf5a3f9D6c55160c798A47Ed0F93d0F0Fb1F6EF2F": {
        "max": 1,
        "address": "0xf5a3f9D6c55160c798A47Ed0F93d0F0Fb1F6EF2F",
        "signature": "0x37035a1f969aee0c49c90be3c7132563a9834aab337e3d84fb3c4fb35fad3328700d22541b06c831acefbc5d7eaa366705e9bd5f57f49a6e71fa7b793f102c6f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6DC8542e1fa9089fa0586Df635f3512AD67F4006": {
        "max": 1,
        "address": "0x6DC8542e1fa9089fa0586Df635f3512AD67F4006",
        "signature": "0x1d3b1a70ce08e7f8cdca215ae1325bad51deb19c8f4ec1b1de31498bd12e0d892641d8a8cf35590dcdd5c8ea8c7033ef879b6bf3e907501e005559cc5aa5bb821c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8d65c7d59b6C9E9134e5b1Cd8b351cF2120DeB4a": {
        "max": 1,
        "address": "0x8d65c7d59b6C9E9134e5b1Cd8b351cF2120DeB4a",
        "signature": "0x398348b2ca788bd93bc7abf79d367cdf297e55d0cc0bf9a550dda59a9cffe4f27ebca20ccb0529d94728981e085a023bb2dbcce14b60f3abb57507c1131984b71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x488372bEF8504D1F9f8efc59B2e5F909Ec5097f9": {
        "max": 1,
        "address": "0x488372bEF8504D1F9f8efc59B2e5F909Ec5097f9",
        "signature": "0xe91169132fe92411202f10eec63b067487fe4d4c3477c9e568dd474c87ad7db152ba356ae02a5e82e8e342481244c02ef791c107f4ec820c1392cb26b79ed6fc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0B67169111a0b0bbB89ba8253aE50Aa3e47Df8B5": {
        "max": 1,
        "address": "0x0B67169111a0b0bbB89ba8253aE50Aa3e47Df8B5",
        "signature": "0xcbe47c255b28c90eca5e3720c68a39a8777558fdec620d7bb940f54af90460205a08c501eb19373fc055b5d43135fa6c5458e44979a16e40795ae1dce44193ea1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x32102D2eBB6799Cf7Ce1160EE0878063C4Ed572f": {
        "max": 1,
        "address": "0x32102D2eBB6799Cf7Ce1160EE0878063C4Ed572f",
        "signature": "0x3ab9d4aeff69bea78c39ab9baec34e2e967b4cd4ec98e57f49ad9195f03b9f192fd4b477a8e2f0a5f129db5ac62cd103ed62840058bf91a16f39f38a90eafeaa1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9dB7bbB19f5Cfe7E4a4240F6948B4A5D17beE22B": {
        "max": 1,
        "address": "0x9dB7bbB19f5Cfe7E4a4240F6948B4A5D17beE22B",
        "signature": "0xb5dcd551af1e5e606886d05adcf1237315cdb1dd40d161086b4404cabd121f9918e8fab041ff3acb84e83ec16b9c4372217e8a7778432c409f52f6a1010451cd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeDf1b949D670Dcd2c0B8a747c6b45eed75ea305E": {
        "max": 1,
        "address": "0xeDf1b949D670Dcd2c0B8a747c6b45eed75ea305E",
        "signature": "0x98cf28ff51a965c8760f040ab763517c94fc666029bfae94c324376e259bfc62381143561c995886f19e1c86326a9fe1f9fcd5b075093a7ed8fd4a91e47116c91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x92a9f884DDaD6acfE7C588D9b2293DAD5667E7E9": {
        "max": 1,
        "address": "0x92a9f884DDaD6acfE7C588D9b2293DAD5667E7E9",
        "signature": "0xb45065143b47321aea551d2e0c1557e9ac04486d647c835654913e0850dd26551162ede48a19f8ef70f7642df55a48164b228995c4760bd6d58e079ade3157841b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8D09246571fCE275442Acb76eD6832aF20a21D6C": {
        "max": 2,
        "address": "0x8D09246571fCE275442Acb76eD6832aF20a21D6C",
        "signature": "0xdee5f85559b4f435438950a3e61512b8471ff331327c21d0352e8da16855a8996ab78bb07935ba571e6b6a7e88a03e019e2e814fd5110b216d73e9702c25a8271b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDDe965F942292C807792D49f39B9B4cFe10Cf562": {
        "max": 1,
        "address": "0xDDe965F942292C807792D49f39B9B4cFe10Cf562",
        "signature": "0x6e0d9d204d0830f9c2d67eef0324af8d040a03416b9e170c0126005f2fb5442e474d30109e2dfb513880bd8adf8dea28d944823808d42dbd5e61c7c0347e62df1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFf1d198C69852eaa1A476761052B0E2fE1f5dd20": {
        "max": 1,
        "address": "0xFf1d198C69852eaa1A476761052B0E2fE1f5dd20",
        "signature": "0x9b6d4fde7cd9d40e0ce5be40bd48237f81f0f52de3dbaf769df571a8af1aed0327b7da50573bd6de5f62c44ad3d333eed26697bd56bc46bf27497fa28d19d42d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x780C391f91Ea57a60333D9d47d780c6d7617E3C0": {
        "max": 3,
        "address": "0x780C391f91Ea57a60333D9d47d780c6d7617E3C0",
        "signature": "0xc50690118356d3264b5a97ddcb49a9a5e84e8d52888727b347c0cdfff0cab9bd48967ea6b82313ce9fd90a1cec2a243fee72af523526816b1fc18fe61d2cca151b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x57A16A17Da3088b6CF97734Af92531dc77768D5e": {
        "max": 1,
        "address": "0x57A16A17Da3088b6CF97734Af92531dc77768D5e",
        "signature": "0x428fe6c3a6e78741b38707fa3b69e19b14384546fd4360a3584de4d067a4acfd73b292cd20933ae8d009808e913214aaa2643c7c2dde5257d80a8e1e2d4cf5941b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4288491f7e22814a737a3ACc46A314cB58A1E1fa": {
        "max": 3,
        "address": "0x4288491f7e22814a737a3ACc46A314cB58A1E1fa",
        "signature": "0xa9c39bc8486c73ebb3ec7316ccbaeb51f4ababe2b7a9681624c454d5680c0ff506f897c4e56f5ffa6a0c97bd381ce62cd0b80e1aa6fcd4f57d93ac16bee7efa91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC1Ba5d206EE1F07E54185dA06bfAfbF83367BFDd": {
        "max": 3,
        "address": "0xC1Ba5d206EE1F07E54185dA06bfAfbF83367BFDd",
        "signature": "0x49cde01700d7b74cea8eaaa5e1babc4d1f705fbd71904105a1710559619db2ab61c93b967c81023b251d449f39d006be3290391fa2be06228d5e2e36bd9543ae1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x98f4B5BFD059B1ea8c42A6f966380788dbfc6e9A": {
        "max": 1,
        "address": "0x98f4B5BFD059B1ea8c42A6f966380788dbfc6e9A",
        "signature": "0xf6586a34c6edbc3be3746d3e8f88c82adab659d840a3811fd183ddb42af7c4e653645c5743f7d0aca5a2800af50693185dea725e8a55dbb6d01412c3679d90391c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8C4138dCEad987bB50929ecfBeb39EF3e28B4C76": {
        "max": 1,
        "address": "0x8C4138dCEad987bB50929ecfBeb39EF3e28B4C76",
        "signature": "0x974dcbfdd299559a78d272cf1bd02dd9eac0c708619d928d986c6f1430e9c8cc11e9d38bc963400e3930dc3f3f99613284f68b06e91bb372ba74c3bda1619f091b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb63B528949767E3e4EbE5200e03Ad5776293f57F": {
        "max": 2,
        "address": "0xb63B528949767E3e4EbE5200e03Ad5776293f57F",
        "signature": "0x5ff8c0bb87efd847b60c45dfc874fd700d115ce944e9b2fa7aab68d369c9431a69e961484f15942d5f01cf36173585235f2dcc0b36509769d25bb904fad834e71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe5D79cdc83ed245833168a3CCEB08Ac7cB4bC802": {
        "max": 1,
        "address": "0xe5D79cdc83ed245833168a3CCEB08Ac7cB4bC802",
        "signature": "0x02c7690721e5612c1f793d6fcb979194d924d1c9d54034e5870371d3410144237df5225170f277e8fec3ba5c2b75484a12cdc79fbb46b241490a13a43e1c85731c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x78A5D964c26fEa26e4cF2d04E87759A0dbbfc154": {
        "max": 1,
        "address": "0x78A5D964c26fEa26e4cF2d04E87759A0dbbfc154",
        "signature": "0xb5e3a5dfdf1bf3aafa627220d9d32cbca4bb292fc188bcd58c9f9483bcea238e2de3c9c2c8eb311e7e3c3cd253d810a97353397a261b8eb5986ac3cd7bab11f51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4D797e596D002EBcb2AEa2dEe2e1d25f272B73AC": {
        "max": 3,
        "address": "0x4D797e596D002EBcb2AEa2dEe2e1d25f272B73AC",
        "signature": "0x6a2bed9eb8cfd1a146e3a4acc82aa5cb3f0683fd761ff5da0d98fd011241e6985bff8b7a6ae056e02d66b72a32d2be30b944ee7b1c72ee6e172890da7b23b4531c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7a755c96CD09D4882D11099e0279EE7bC4183A60": {
        "max": 1,
        "address": "0x7a755c96CD09D4882D11099e0279EE7bC4183A60",
        "signature": "0x23f9ca3c3f247e7cc608ab9de8b75f35b7126f2e6d92e45af422fa72a07c95733add3b04a42067e1e7d575f06a497a2924cc835082dab8fda1e2a003561c3fbd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5713F69Ac8D64049747274eF19F54f8DCce67184": {
        "max": 1,
        "address": "0x5713F69Ac8D64049747274eF19F54f8DCce67184",
        "signature": "0xe9ce43e046621edb9baf87f73d4d20d3db27d3e50bf1e76bb79976f4248c12093037d2b72fd7cbc6053cf261a242441b3a153566b337c4b06fe1ab2143d71dee1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFbccBfEceCb335474bC3ED231632e9c286746Aa4": {
        "max": 1,
        "address": "0xFbccBfEceCb335474bC3ED231632e9c286746Aa4",
        "signature": "0x882799dec50a63e58d740e4db8fa59f25ec6b678e080a4095c33c4d4e671f56138be5cddd9577db6cf123264bd3540c44ddc12ea1c00181fa5d3df016a22b82f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x969394f1d15BaA3d53aa72Cf5e7ec475dB096efA": {
        "max": 1,
        "address": "0x969394f1d15BaA3d53aa72Cf5e7ec475dB096efA",
        "signature": "0xa6e965985ceb8d80de9e0b603cda6333ba21b5c98ad99e81a720ab7cd509576d7ba3b4522a86e903c617535bf3fa973a83bdeb18362729ab05e54a5fc530c8041c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x91c321381042F642377c5A2ED0396A919Cd258FA": {
        "max": 1,
        "address": "0x91c321381042F642377c5A2ED0396A919Cd258FA",
        "signature": "0x5282f07b65a9172cacbaf3683b697b760dad8f9744d18ab33c0a1c39af8e7112677b6cc2f55357f72bae9c7e679da8e3f48067f3f1ea58d29b2715e4d6f3ba841c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe27CbC622F4e585147b9fb939adB2E13F9d4c290": {
        "max": 2,
        "address": "0xe27CbC622F4e585147b9fb939adB2E13F9d4c290",
        "signature": "0x392bd511fb1976708a6e18253fe3420fc0fb59f17fecd412fb7cdd82533bbbdf6e80010ad4392637cf8eb2c3ef2b8b27182f1aca60ef90b837561bbdc5d8d5ec1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1A86A329160695f10BB9b1510Eb7fc6c53b86103": {
        "max": 3,
        "address": "0x1A86A329160695f10BB9b1510Eb7fc6c53b86103",
        "signature": "0x4b3e74f0b3a6287b8212c5cabeb4765007c471557b43674b94ced9b894b8610d5a84ed3b9201858cf23a0b928778ec51bb942af53663d0c9112663187db8267f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6c4c873f108FBF9130c3aEa958350B5754C94a3C": {
        "max": 1,
        "address": "0x6c4c873f108FBF9130c3aEa958350B5754C94a3C",
        "signature": "0xadf38543acdf29d6ff95bf0e351dd3cb4b75c8feab2b724767a660da8617fa562908c4b6157d2575b0cdfd4a7985e016db69483b739da40c264d8fb921f3698f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC35BC69311A3aaB701b39662D51FFe1a014639B1": {
        "max": 2,
        "address": "0xC35BC69311A3aaB701b39662D51FFe1a014639B1",
        "signature": "0xda74a5d48621f94785e7e42404a6abfa0eb130110f2217a979fe63236018f679254d993949d6dced9011489e17197b5ea9468b13b61f2ba9fc88a1c2232d77371b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4653a3BBC69840c9bdFAc934a8acC48F8ebb92b0": {
        "max": 2,
        "address": "0x4653a3BBC69840c9bdFAc934a8acC48F8ebb92b0",
        "signature": "0x33cf9ce277079ca02d31d78670e830665ffa4f4b6e683b9fe5b6b44c3487adee7af1191d972e292ac50cc5436ab1fa4c004f7b4bddd449c6e6c1fda6e4c0940d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4c21BDdAE2e2A04a9f4578fC9aC2956d67702a47": {
        "max": 2,
        "address": "0x4c21BDdAE2e2A04a9f4578fC9aC2956d67702a47",
        "signature": "0x4114d911153d7cef38387e52bb9e324d346157cd048bdc94f5d0b58b6a4279671fad572825656e0fd53ecaec756992adb77401bdbe14c0e49be20ba8fe685c741b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA35F026178E07F26b19724d47c125CfF9a75D3eb": {
        "max": 1,
        "address": "0xA35F026178E07F26b19724d47c125CfF9a75D3eb",
        "signature": "0xc46a37195af3889520e8527cb993b6f58b58fbb190b1f09dc35a4095f10c0f0079deaba62b6596268441fa24526e2f708905a21b0ed3233220cbedd3280ef3ca1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa68d61cC389FC895AdcB1A70f12E7B91147451BF": {
        "max": 2,
        "address": "0xa68d61cC389FC895AdcB1A70f12E7B91147451BF",
        "signature": "0xc8f1f23933a7e7a5e390a5cfe02606d95cd92f78ac9563463262fe7fde2294397cccc0da25ed54a31e9e2ae0f8c1c7a8dcea087bdd073c6d120406363443e01b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE9825136Aaa4851f6Ddd21f6123197A8A290E6fb": {
        "max": 3,
        "address": "0xE9825136Aaa4851f6Ddd21f6123197A8A290E6fb",
        "signature": "0x826a7edfbda67957c23bdf896a312c436ffb10599ee245e80a274ff53f3b388406714ebc8311e8e04a98b76a9e9ab5707eceb0c123bbbb685bca14438925269f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x960c300B170e6f0460879Ea4aBf1B27CBe3c2E56": {
        "max": 1,
        "address": "0x960c300B170e6f0460879Ea4aBf1B27CBe3c2E56",
        "signature": "0x74f6aa0496809e364c2c3badc912acc9aa2dd3e6db93ca30e69a59c7f74c02fe51b4147ec086b336092ebc2ef860a2946ae3ecac2a367417f986b569f39db14e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA5Ab1d69B3519C02aC5bBB4F5958892568695Ea2": {
        "max": 3,
        "address": "0xA5Ab1d69B3519C02aC5bBB4F5958892568695Ea2",
        "signature": "0xb1be1cdf77afcd584f587d07b3740be70e4b88b2ff1eb6ad423c78f3b34f4222128a7dee5c7c88bec50dc959ce0f4640220c7de451bb04827423762c2f7a98d71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBCA5A2c56e1140dD618dD53cCdbF84B414A00adA": {
        "max": 1,
        "address": "0xBCA5A2c56e1140dD618dD53cCdbF84B414A00adA",
        "signature": "0xd471042f7c752b1b9f2688f40a190b2453402e071e17f8237ab7b69a24932a106508753d793ac056fcc73bc1d0109857e2b0a362abce006926883ccf43d8fcc71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x843d589eC8da308b675cC90577579c45C02E820b": {
        "max": 1,
        "address": "0x843d589eC8da308b675cC90577579c45C02E820b",
        "signature": "0xf2552aabd1ee9ca1c19809fbee46afc305a232c6d5ee5744aedd04d165ed1de0769db2dcdc2a0eac29facea4fda64ff1e055fa3e046846ccd29b4c881689cad01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x71D95390C9B7d24E92558D1cdA23eB6ac0e09032": {
        "max": 1,
        "address": "0x71D95390C9B7d24E92558D1cdA23eB6ac0e09032",
        "signature": "0x72cd770f5ea77a9362351bfa2275cc8dfb2092fe67a43b203e3bae2708c980bf240ba3125b7c503e47ce3a54ffc636b1eef652073277154b6b89115f878a26e51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4AF9B0Ced8271039a3F9f5047b86A18e7055bc88": {
        "max": 3,
        "address": "0x4AF9B0Ced8271039a3F9f5047b86A18e7055bc88",
        "signature": "0x277944e80585f92e0b76fa75dda6a7ac2b3f8f1d922fb6abca128cbee8b39a3321eaa776567d9247386e6798c67041cfd85ec4967e3d3ee6a0a568908680ae8b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x28e3D831cB9939A98FFC8f29F9B6d1afbB410aF6": {
        "max": 1,
        "address": "0x28e3D831cB9939A98FFC8f29F9B6d1afbB410aF6",
        "signature": "0x358472ab78d6841235c2a222e91aea4e8cc1c607eddfff44b32d3658aa441b8a6b2fd2e888746500089bafa62c5bd56f84d8df960e5b12ceea8f651773759f9d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8E16e67267e563f89CF06625062a0E946e2b70FB": {
        "max": 1,
        "address": "0x8E16e67267e563f89CF06625062a0E946e2b70FB",
        "signature": "0x33eda42cfa00152fec02d6bc0f8eab204fa57c2672adf7513e385d320492b83c1343db8e8edbf16a2afc9cb1c8271f56df51459e893be238408f645695c658ec1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x775dfFaE5d7Ea2D545cf407b24d512f015CF11D7": {
        "max": 1,
        "address": "0x775dfFaE5d7Ea2D545cf407b24d512f015CF11D7",
        "signature": "0x341df83b953ae5d6a7c15272ea0ac6717d1009f06b93376e9f20de2c777ac41a25ec497aaa40cee98128af29aea381dbfc5f75c3c7493c8d1998cdf7061b057a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6B9B00d4E54b9673DD96aD043A7A20C9481aF5e5": {
        "max": 2,
        "address": "0x6B9B00d4E54b9673DD96aD043A7A20C9481aF5e5",
        "signature": "0x08a9fd93a06162685440708aac79b0c5b3f04cd74bafe28b79020718d517a809521e72b6981e71714d563936ad5603d75520d58d20b0d8c23b4eec8cd486c1aa1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x65dC3a921613e4EaBBc42897c42B5B13EdfDDB2C": {
        "max": 1,
        "address": "0x65dC3a921613e4EaBBc42897c42B5B13EdfDDB2C",
        "signature": "0x5bf2bf18fd7fdccec6fbed4a8469069b679727c30642e11546fb8639b18161952afea488daed07b57d3db58d461929d112d43a3ca4603003502775c5706e1c7a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbaB7a83C3C3992167E53997ACAA5F771Af3354dB": {
        "max": 2,
        "address": "0xbaB7a83C3C3992167E53997ACAA5F771Af3354dB",
        "signature": "0xbd464f32004d3ca9df0ae5280de1f6cad3f5c357d3fb20a4d78ac40650501c680c8e6183c587b1da946bf774701474b1b5ec181b0763f180a299086f342f71f11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA882fB71aA1967A6a8aa11375F8abd5FeE5BDD6a": {
        "max": 1,
        "address": "0xA882fB71aA1967A6a8aa11375F8abd5FeE5BDD6a",
        "signature": "0x52d3c44f14b0d201bc518fdaec4890dec3b0e2eb23039a89fc1594d63e2da87f4107e5dbf3db959d88e969488428492081b58850c41dd6af9e1935c38298bc7f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7af89D0807328fc13a205FfF64AdF0caa0F259b6": {
        "max": 3,
        "address": "0x7af89D0807328fc13a205FfF64AdF0caa0F259b6",
        "signature": "0x019c46629080708324484f270cf8385b661a60a5324ea457bf30b97703d1df28738c175a070bb9b1ae7ef5af8134187192d34d10f71e1f88e12cb96c73eabb5e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0b3Bf97884738F8dac2Bf4CE201d7B1Ce77553Cf": {
        "max": 1,
        "address": "0x0b3Bf97884738F8dac2Bf4CE201d7B1Ce77553Cf",
        "signature": "0xc0919451c63429c3c7f1f58e162336bc434f1b850b8099a7e119b8657187ef96426af736a7667def8b92d6c77bd549b4db263613a91e0e14f3607da92da5e78b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5B822472a2a346606B638c8a544bC184AA900218": {
        "max": 1,
        "address": "0x5B822472a2a346606B638c8a544bC184AA900218",
        "signature": "0x8eab5eee8fbe4bb7edcc20934813c89e1aa32866539ae26e656ef6d2e81271c7466ab05ad7b79a4d611969891677a03ce75537260961612e7d99e21fe44ab8dd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x328a64849478c53DFa5f082045989b6d9C2856b1": {
        "max": 1,
        "address": "0x328a64849478c53DFa5f082045989b6d9C2856b1",
        "signature": "0xe64b9f606e307e2deb6df9a38bfeebb99a47d87e41034893b7ff681d2a482b896127b8ff03b5846c388542e50a2e066fd6723ae83eceb4b180034ac6893f13281b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDfE7B93661B552EAcE7885c446bb66bDDA1FC9DB": {
        "max": 2,
        "address": "0xDfE7B93661B552EAcE7885c446bb66bDDA1FC9DB",
        "signature": "0xd79201b93c9ed9a852be280fb772ac60835f1ad8e20e07f879f81efc6d0b9d2373a35f4619b3eb806cecbe1820167bdbe9c29327f21c72ef201f9c6b20e014651b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x39B1F669f48eAEdb2ceDCD69270333420437aC39": {
        "max": 1,
        "address": "0x39B1F669f48eAEdb2ceDCD69270333420437aC39",
        "signature": "0xe27923b98002eb173282c88e871e50ece4c2d0e4fe4f1157afadb76751ce8b23571186ee4130ce97a1894c520f1433af46f3a232be43a50d2ae8ec0a894f84631b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6AD59A5255232d74E9b594E1EB8F341D3fC2357b": {
        "max": 2,
        "address": "0x6AD59A5255232d74E9b594E1EB8F341D3fC2357b",
        "signature": "0x83aa4b21f56a0f1b479fbe8112c0a9c3422ca9e81b55897e3bdf263829f471060530bc647621a8d2c6add5d86fe9fed484b0fbcf733a12d2ab2b97d8765fa0381b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4FBf92b5BE67d7048Ab0b1cF6f474e6c3c4A98d3": {
        "max": 2,
        "address": "0x4FBf92b5BE67d7048Ab0b1cF6f474e6c3c4A98d3",
        "signature": "0xffef0a38a21b9be61b0e8afd0d81b68efef8d3d80c8e0c7cdca5e1a733fa30e118b3aa4f1eab8f5ee5517906b6576e73d7fd4f9a796253b680fdebd04c9c9fdb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7999F382b81846dCb67c757bcA1C09878d060057": {
        "max": 3,
        "address": "0x7999F382b81846dCb67c757bcA1C09878d060057",
        "signature": "0x000fabc6bcdff0964d4520babe3675dfe5f37467a7f6732d010604a1248dabb1771597d82cc009634b1dc11e89e782171f79196375099f6fcdea94aefb49d5f31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0C905f85cd389B1295202574c5763D7395B04788": {
        "max": 3,
        "address": "0x0C905f85cd389B1295202574c5763D7395B04788",
        "signature": "0xae8d5831d9805125c3ded56b2c6094403b138c80df6c3239d980e4a93c16ac415202c4d634d36ed079ae3219a15a74210d247a092af960546064cff8d13700511c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x697c164D772d0F02A0244026936c8b715f6eaa54": {
        "max": 1,
        "address": "0x697c164D772d0F02A0244026936c8b715f6eaa54",
        "signature": "0xbcc237985be0ba5a91a8b6a0910a263267ac2f6e189d16391da73666b6857f126595adab4cf63e90481ccf2f53f3895f34b198b93c712ee48dbd6bb7c96f8b9b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3f11F0D67b9073318707EF2a665Fc5C0fdAdAe27": {
        "max": 1,
        "address": "0x3f11F0D67b9073318707EF2a665Fc5C0fdAdAe27",
        "signature": "0x5c00d9920999c9bc95a4a8f3683d2e015dd76a9fd7e6581df7de16fda40e0c2674b7f2f83030a6015c3e07297c720bf201e71f0e2c6ea4b7998c51229c4067f41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCb167dCCEA3eab6C9b08215e23a459D3160bD87B": {
        "max": 1,
        "address": "0xCb167dCCEA3eab6C9b08215e23a459D3160bD87B",
        "signature": "0x05a2de11b02457ca3e42d6cb952a4f36f8897a6550eb749c20ed474d1341e8a7512a19a0d8a9cf6692145dfeb7b54fc9fe34cd2f830b0ca92fafde4738aec9471b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4b071d08C91358C55a5Df0ad0927fF900e005a56": {
        "max": 1,
        "address": "0x4b071d08C91358C55a5Df0ad0927fF900e005a56",
        "signature": "0x976d4663499c44505488876c66218ca980569bc486b2a9fbb1de9af6c87d4ddb466b23b446e4c9c0be40251a8d2876b9304fd8a10e3fc0fa68a49b6980fdd9e41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x584918c2ed491fFFBC324f9d78B31d199C9Ca22d": {
        "max": 3,
        "address": "0x584918c2ed491fFFBC324f9d78B31d199C9Ca22d",
        "signature": "0xe0291cf3852b012eabd32c28dc105411bd2f9e27b42fa9eb1ff1f3886e59b97f3fae2911a50fbc8ea9b51cc56a4b85a4192b3a49ace464ddefea7edfefb2903f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC47455993D7Cff6248DC594BAA8c3b6FbF9F0f93": {
        "max": 1,
        "address": "0xC47455993D7Cff6248DC594BAA8c3b6FbF9F0f93",
        "signature": "0x334edfb30ed5a32dd693357420a8ca8bd7aad1374c7b3ccccb7cd3b95db99873744ba0b7521c4af7a8eac0362cdce5382b977eabc63fb96d5fa4bf2cd85597a81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x51f80c08743715477e525f4d2add0660AabbacBe": {
        "max": 3,
        "address": "0x51f80c08743715477e525f4d2add0660AabbacBe",
        "signature": "0x4777bd90ecf5bbb082730f3164240fe365430ccb4a6b356a3dba6ddb59c5c82b29c3364d491a24adce157c330b20dd69d3d6a2fc8ac800edc9e1508af9d847e31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCbA511fd29A509d478d3323533D164c7dffeB91E": {
        "max": 1,
        "address": "0xCbA511fd29A509d478d3323533D164c7dffeB91E",
        "signature": "0xa0e3f2fe13bc1fd7b76f770bca68e1abe6f9f873e587e35d369e89a1485c0d3b763110572d4a02de230fcd913c94c541800d3ca5d449adc2acf72faa495808721b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF080548Cbf31F62201E1662A7DcB8728A8e3BCee": {
        "max": 2,
        "address": "0xF080548Cbf31F62201E1662A7DcB8728A8e3BCee",
        "signature": "0x0af9c8791b7fdf3b19f846a2856de2d744e56ecf122d258e3549de3bfa6a8e4b629471a8a5527e5a50363c709c1af0b4ee9b3c3863a486af89e034c6321905c11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x095606b9fc58E2A4D068Eb668b313eAB0f7092C3": {
        "max": 1,
        "address": "0x095606b9fc58E2A4D068Eb668b313eAB0f7092C3",
        "signature": "0xaa80010cd5919ee7501c6b01d998ff4f47cfbafd6a625bea4c7d67cf21a3338370929daa39f4947a52b615aac3abd6e005c3a64b2f0ff2897c9d49c3d6f049101b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5E13aA079523F31e603b9D9ab9cffC569214328c": {
        "max": 2,
        "address": "0x5E13aA079523F31e603b9D9ab9cffC569214328c",
        "signature": "0x696d3acd1fce341a5adaedafc829b9ff6df78d06875e0b373be5e0e285c6da2918cccebc861c9498fd46ff779c240fed3a02bc61eafb8eb0a926d4f917af7ee51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8ad5ABa6216afaC5cf0E6f5a0eB01A23dC022f03": {
        "max": 1,
        "address": "0x8ad5ABa6216afaC5cf0E6f5a0eB01A23dC022f03",
        "signature": "0xed78c1baab74679308f3d31ed1e1a495b1c868b171906cc8708e89f08e7f45654cfb8d510980392c68e6da04dcc0007e60be3575300bbd87dc433b0d68bd8fb31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x295dD8b8E255773442F2c9010351a95E2A129368": {
        "max": 2,
        "address": "0x295dD8b8E255773442F2c9010351a95E2A129368",
        "signature": "0x060431396ebb3f79469c0341185260d43fc8561614ebae519fb46e7bbe94d04d4ac606457eb78db94b0e2c19893a026e1fce10e4fdc20b3eabe57720499ffd821b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa344c162a8d1eB43f984D03E7970C1882eaAec29": {
        "max": 1,
        "address": "0xa344c162a8d1eB43f984D03E7970C1882eaAec29",
        "signature": "0xdd7d3a8cfe909691272b120d4a4f14900cd7e1154ced05a103bffad2484ec93b747311bb0a2fe1ef83eb8079af03e72d2c7190d8d13408e2dd8735867105e0191b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3703D1ce1d5789C04eED0544B8BA4B0D56c882b5": {
        "max": 1,
        "address": "0x3703D1ce1d5789C04eED0544B8BA4B0D56c882b5",
        "signature": "0xeab9387930f9557ddb750407a8ab2d17f1dc4caf1c2619f1bf4c594bcfd00a8a0532db933067d8b7fc044c59aa0b3ce2ee4b3dadd9a49a9436d661fde2da2fb51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9D0e8cEc8D0c76e8DD8547a04E74F6790f0F09A5": {
        "max": 1,
        "address": "0x9D0e8cEc8D0c76e8DD8547a04E74F6790f0F09A5",
        "signature": "0x12e36b0c3ae35106463c002f0f65859e81334de975510a7c39b5f77e7d740b5b5a5b1bde9eb63769f9cad62d83ee673cca4373af0e9d9f7bb58e3854737f6df11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x29A2e030b8b91A2D30bC13c3a4c2258c0a750542": {
        "max": 1,
        "address": "0x29A2e030b8b91A2D30bC13c3a4c2258c0a750542",
        "signature": "0xd319a279317843af54b5e4598cbf27b42ab896c73dd5fbb4eb6a66e464ddf76a7e399c5fd24beac96e75ea3ea11d7c21c236a47b2b8fc58d73388fc44d8689821b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3116C70ef259d90ECa1F78F8eb14521Ce9c898B0": {
        "max": 2,
        "address": "0x3116C70ef259d90ECa1F78F8eb14521Ce9c898B0",
        "signature": "0xeb582b1bd9944e4f699dab2a1ce705be5b35aa919b45e4e6e03adb6dab77972c29d50329d945e97ffbae1e78245fa3a6f30fa8f8efecaad3a1ff6fbf762709c21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd2C67515708B5C096132D00131055282F9bA4b8B": {
        "max": 3,
        "address": "0xd2C67515708B5C096132D00131055282F9bA4b8B",
        "signature": "0x03c85ae0bc26096a1280a070cf5f5a93c8cfe22c4e548bdb5073820f0e8bedf16827b858766e8340eb2d89546aaa0fa5f36e05d22b9773e2ac29408e2fe8cf801b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfE767CD9FC72E8277e6Cb0c1b271FEDAd12825f3": {
        "max": 1,
        "address": "0xfE767CD9FC72E8277e6Cb0c1b271FEDAd12825f3",
        "signature": "0xa7884bdd0966f33f1a2d7c2f2895d8a88b62249d3e67d6f3f76ab1841e01cda2124c295421811945d1b63648911045254d46a05dd95fb62fd924a85ce922e1ab1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc9323C9Acf94D5762778dcc2487C3391D3622ef0": {
        "max": 1,
        "address": "0xc9323C9Acf94D5762778dcc2487C3391D3622ef0",
        "signature": "0x06d1790b95b86a28b8efd15be70b9a066d66fb9c64d0bc5daf2edccf517332215eb16e070c04e8b3d89276d351b626af0c5f9ea4b06a79dabaf7409f5eff947e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2353F8B06F065bB64301766C943F181441F56Dd0": {
        "max": 1,
        "address": "0x2353F8B06F065bB64301766C943F181441F56Dd0",
        "signature": "0x862960ab93ff26b07a4b2d04a5dbabc8b76288a406305b6599a19481555ef4a64a6f0b01b901d4c77dbbeb58ff0e118397d7986e38af3b69a1c5374710d803ea1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc2D57f7309941bC87A38d72fe89E06125C77E81e": {
        "max": 2,
        "address": "0xc2D57f7309941bC87A38d72fe89E06125C77E81e",
        "signature": "0x2bfe0bf1a95097c7c2b35285cd5c0864194689de8c7f7ad785f5651debe087875819ad864680218c30898de1e9cdb273ea187a6a9c3f32ef6ef87ee0ad670ae31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD9cCFa6b704025F5559ED7c1A548A7b7E9A3bdBC": {
        "max": 1,
        "address": "0xD9cCFa6b704025F5559ED7c1A548A7b7E9A3bdBC",
        "signature": "0x5aff03da70f928a84e740537e92336c41899c6d2fa4c2bda101c14c80f7ac80874e68e875288de78d2f31deacbf629110fe1399a99af0f81c2fbf8635279afd81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfDDE2625Df444B889330Fb57e46923E12d436585": {
        "max": 3,
        "address": "0xfDDE2625Df444B889330Fb57e46923E12d436585",
        "signature": "0x11851a4e69a942cddf592ef269b1602327e6488b796766e147fc3229b0b83c7a4e40400687a93e9590ed31136341420e04c137d8bc1f36e222920d54f940879e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC5FF8c387eB3E83064c1F0bB3101AAEe6e8c8332": {
        "max": 1,
        "address": "0xC5FF8c387eB3E83064c1F0bB3101AAEe6e8c8332",
        "signature": "0xe566ab9043f53247ce659d2d077abb5032bc909af122b9fafbb14ae09548f1f8083888e482dba82acc19b56946d1941033a130c6be5dc3d2d76e46969d91b1121c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6aB7B619E11dD533a2b01c51D5D6Db37829b2706": {
        "max": 1,
        "address": "0x6aB7B619E11dD533a2b01c51D5D6Db37829b2706",
        "signature": "0x74a12dc84c319bbb47f97b02427757dad230afeb78ff0caffee2cd2e9e84c0774dc0d21f172730e612cfeb00f2bfd90d259cb518b943863bcd6601e721f92e7b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAa2d7a884A34FddEe7918CAAe453182C1BDA8886": {
        "max": 2,
        "address": "0xAa2d7a884A34FddEe7918CAAe453182C1BDA8886",
        "signature": "0xaf68d7b63eb65cebcc2e22a6f7f087233bd7939872a000bfbedfee23c126df470d2cd3320837cb5651089caacd70b06c47d8f7bfd9a93bf6a6cfc87cff71aa891c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9986da4443f5C8A9055C0adfca8d7a4a001B0311": {
        "max": 3,
        "address": "0x9986da4443f5C8A9055C0adfca8d7a4a001B0311",
        "signature": "0xe51f6d2981fb5fc4299fbdea08c37cc36c41a4ba30f0df99520e4ffbd9cfab896ed865116c1ad2d78f21df8f9e7ba555370b9bdab2f9656a0a3fbb1f708779cb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe0CD0fE2223aC163cDad2A599c8158557d47eADa": {
        "max": 1,
        "address": "0xe0CD0fE2223aC163cDad2A599c8158557d47eADa",
        "signature": "0xd18d95f1ff10057bd6b17342c74f50f4e5a5c86926fe3795fb6f12d928ac67425ad7e0ef9e4a0051a1792b68d23a1173ed29dcca0bf679a017c527ea741d96d71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8EE9B3e39ec4125C3D6dB42d7dfa1104739d8147": {
        "max": 1,
        "address": "0x8EE9B3e39ec4125C3D6dB42d7dfa1104739d8147",
        "signature": "0x694b0bb6b8038f085137ba45f2ff9f32cafdffaf4827a0892cc81442a44dfff163a426cfc5a309a8e4a59aebafa6c0840638162e3e23c949c0e60eea9c586c2d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd217394dc6502C1541541f988eE5c39A1E074c82": {
        "max": 1,
        "address": "0xd217394dc6502C1541541f988eE5c39A1E074c82",
        "signature": "0xd883ffab74f14a7808854d2399eedae7eab922f38e52fc1665548cdbc4898bdd4d8cc2b92cd877f92304d029d3fbb81a926131de753075931a18d4a378c3b9751c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa2ffb28F4B86aB41c5eebFf9fA502C87C7aa2cAB": {
        "max": 3,
        "address": "0xa2ffb28F4B86aB41c5eebFf9fA502C87C7aa2cAB",
        "signature": "0xa569bb0a09e0b728d089c3e7553422211e0cadbb0a8faa3ca960650f906e7ac9003169729abeaf226de72326a844ec03be9237820d2084c889ebefcfe5d580361b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x695BbB82804A1c22616Bdb012beD9eE316085EAC": {
        "max": 1,
        "address": "0x695BbB82804A1c22616Bdb012beD9eE316085EAC",
        "signature": "0x5689861d4ba019f8009572bc6dbc986064a0675cabdf70f82a6b05d0029ea6a8423b49d5ff91656634276b23cfd64e26a7b580e7d10d3d06bd0aa9aaf8616da81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x866E3eAa5e23d7e6bad3192AA5D5bdd118d66783": {
        "max": 1,
        "address": "0x866E3eAa5e23d7e6bad3192AA5D5bdd118d66783",
        "signature": "0x32634143ad131812225deef411d42dafe4bc9ba221a5b8d9eaa3582c1e222c04496fb3eaaec84f2b80a8ba5a4662361816135889839f82a2d5c24892026e70d01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x01CCA4DdBeC60AE224c072B262cd3b60Ee464636": {
        "max": 3,
        "address": "0x01CCA4DdBeC60AE224c072B262cd3b60Ee464636",
        "signature": "0xfaa4e8aefb62d0b5bdbc73e2141be6704a07a7d6d7d33fedf9fb2c735681edd6344e356e655803812b86fd8dffd6b7e62493f3427885fc08732f170b0535f19a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1F7fd08219956d9e06286c6AD296742121D2fEDf": {
        "max": 2,
        "address": "0x1F7fd08219956d9e06286c6AD296742121D2fEDf",
        "signature": "0x38d69ace0f429ad3e844a1ff93b02c472d80ccad9d7b5fd5952ff54b4ef0b19440ac395bbbd18099e0a197a61ba1c14f753b55ba9c2eff4ca33cdd75c0f739f91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3501dF5fF979d6E1Cd8b898e8bfb00a02e752f0b": {
        "max": 2,
        "address": "0x3501dF5fF979d6E1Cd8b898e8bfb00a02e752f0b",
        "signature": "0x5bab99013fc7c1dc5cdd530949f6e93878838109af95ef4013c985c432fd1c8c1eb57de038bbdd2f82e7cc97e7d37ee68a736f5fd984473e295ede9936b2704d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8a6e1860a3f7BFCC688ccA694Ec5882BB2176062": {
        "max": 1,
        "address": "0x8a6e1860a3f7BFCC688ccA694Ec5882BB2176062",
        "signature": "0x4a2ee49abf1dedb735157dd3be9c71c9115c18048aae8812f1d8fdb0576f389845265080df420f0f078e161afc7313be858834e4e467468db4351c7399294f6b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2408E3aCCd020A11a8Ceb0802C8060FE9983fb15": {
        "max": 1,
        "address": "0x2408E3aCCd020A11a8Ceb0802C8060FE9983fb15",
        "signature": "0xb282556f5e78cc89cab24c315ca4c8eb31a121df945550cdb943be606af7521f3bef0948e8ef84de84c8e4c5242c591fbfa90ff514fd23c705d881a4349920e11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7fa8109Ef9e12194dFF36fb02d564a129f883a96": {
        "max": 1,
        "address": "0x7fa8109Ef9e12194dFF36fb02d564a129f883a96",
        "signature": "0x44ea81a6addff2a34ebbeb433f77634fd2f307c0fe6cf6c862b804e40df586ab0f5ad107ab5d7d129a56d460cf416cac4bdd8f76279a656e1ec83b763549b40c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB9d1F34B20542f19f1465Fa6278a8003D04479d6": {
        "max": 1,
        "address": "0xB9d1F34B20542f19f1465Fa6278a8003D04479d6",
        "signature": "0x5cb3d5b49aa2abb7a21c703b0bb1289445182301b744a1898854ff677aeb346c2334727e3390402dd2e5ab820346b6890bf54b3d1cc03cda0aab70a4ad824aeb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x54e2489034C8dD804762033313955a1e27959306": {
        "max": 1,
        "address": "0x54e2489034C8dD804762033313955a1e27959306",
        "signature": "0xf6259808ef26b987076a9e41db79e9be3a4167830f0816565031b7faf4149fe34f8e4d16b497241914abb283c6fbb08ab3336a32422da467ab4da07c36d0c3e61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb1CDcCd36A0138Fd9Ad6b5eE1038d49A26ADAAe4": {
        "max": 2,
        "address": "0xb1CDcCd36A0138Fd9Ad6b5eE1038d49A26ADAAe4",
        "signature": "0x451ee140830d6c5b7299d9289a8443b436f7bc21272551be442246114c1f680c3079fe9c6a05571e36d840b75e03052c8f7c64b3bc28dc4b2ab474e212d3aefa1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7a22BFbE0ABcEffF1A5eA5041FE2A4AAf1f47798": {
        "max": 1,
        "address": "0x7a22BFbE0ABcEffF1A5eA5041FE2A4AAf1f47798",
        "signature": "0xbf936da8735780663d04541841b603fd41dd696c18a2123876550389bff7496261b79806917197024edf33b8c272950e2be8b5da6b1ee1ac4b0480f4c5e8fcae1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x77DF49f0B1469a871a0e2d875967200aa396d08f": {
        "max": 2,
        "address": "0x77DF49f0B1469a871a0e2d875967200aa396d08f",
        "signature": "0x18db560170ddd19d1829ba4729734613e7f301019469af7b4973fde44c71651a6c4dadc699652f7c1934b34f9b1874052017ef8f478d84528a242c1510be78101c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3292f5028Da5117f171A42a0E015c164AbB9E87F": {
        "max": 1,
        "address": "0x3292f5028Da5117f171A42a0E015c164AbB9E87F",
        "signature": "0x1782a85701421210408e92ac9d5624718453490cc5930e49debdf9685c786feb7a6b9cad3fdf0da5dc26e78e62184758f21b1ae7aceeabbd70bd4001f697dd651b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x414cE028041F44eD40A06E6a6d8985fE2bFEC042": {
        "max": 1,
        "address": "0x414cE028041F44eD40A06E6a6d8985fE2bFEC042",
        "signature": "0x4316c29d987fddc1301fb04f514ca0ba3759e696a44b0668a38fadae319346412bdaf87809c8000cde6926a3755c26709a7b713a1d4a914225cf043d357ac5721c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3b0F4de2435ace7856D04583b1Fc0b3492D519Da": {
        "max": 3,
        "address": "0x3b0F4de2435ace7856D04583b1Fc0b3492D519Da",
        "signature": "0x628390788b675ff0327a133427b6874ad36b2b0421a3c1217aeed01cbe40dc9952dc535e225a74c9e8d14f429c039c5a4572804b4854a3926d2040913a430a061c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1Dd9bEc3FBb7Df0dA93eF89214198503EC5C123f": {
        "max": 1,
        "address": "0x1Dd9bEc3FBb7Df0dA93eF89214198503EC5C123f",
        "signature": "0x1529a8c14a39faf580f0cdc806ea9a1ba72ebbacd96c1d58b750fa9faddd2838703e9edef98d713577de77a9da073f688acc08e8c7e8910f6ee3038d0a1dccb61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDbC0C6EeB935ea19987ddc77D69a76923B9bE291": {
        "max": 1,
        "address": "0xDbC0C6EeB935ea19987ddc77D69a76923B9bE291",
        "signature": "0xb765f863e54c8309a54f7445d3a864eba5043f3d2f8f65961be798d22d64dd593bf3b05dddfd450d12ca9646e957b63b2262c5d6d4d38a205b38f67727050d511c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2dA3aEB9f831D99e957Dfcc3B15E9344063A04C2": {
        "max": 1,
        "address": "0x2dA3aEB9f831D99e957Dfcc3B15E9344063A04C2",
        "signature": "0xf091958c762866e32427690e64ba9734c1a15ec16089b1a278af0a0e73101fb80bcef2d6147ce46c7a310180daa55aa395d44b3cc9fc5975a1b11890e201fad41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0e2F90c6bE9e291D0fcbFf841ae0eae827c47aF9": {
        "max": 1,
        "address": "0x0e2F90c6bE9e291D0fcbFf841ae0eae827c47aF9",
        "signature": "0xf2e966f06630a7c4ca566cf35e4f4d897d6400dfcd9d1adfdc24bb0c7cf0902d2d75754a4a428e29d248edaa3da72d854c487694e4208490be2eec0f007b2e891c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3FD7507eE8255A0492869108039Aaa43E07CD0b9": {
        "max": 1,
        "address": "0x3FD7507eE8255A0492869108039Aaa43E07CD0b9",
        "signature": "0xf492a5c7bad0e69be4a0da63b035e79fef402aa960a3e93aa037c95900559fb24df7090c3515564f1fa90a98f1f374a0b7af2984590f3d385467f8d8145512041b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB75AB356e82FD26B284e693B15A0580df00a52Ae": {
        "max": 1,
        "address": "0xB75AB356e82FD26B284e693B15A0580df00a52Ae",
        "signature": "0x874d9b85d7e84d6a3f8f747aed3f08c2d3d142420bb6f41f48ac73eb3883fb4169a5d074272359b4640682361b83b9f4e079d73982c9f1cc0eaab4f589fa18c01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x786Ae7491701100BdFa36Dd386E6728Df5eECbAb": {
        "max": 2,
        "address": "0x786Ae7491701100BdFa36Dd386E6728Df5eECbAb",
        "signature": "0xd72d36b52060c8eb68b8af3b41665fc6d417c0d66fea88a84d5df38d132743831cd85f0418efad47d05a1874862cd9db5b807516f73d63305b56e627746f82081b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5d9F578f4626E132248ABC3cCD88C7E59Fb644D9": {
        "max": 1,
        "address": "0x5d9F578f4626E132248ABC3cCD88C7E59Fb644D9",
        "signature": "0x80a99727545f248b4b346600a906e4b803461fd4fe496398889863f049a6bd375885c82eac2f85c61d350b4fa26d069b456882bd05d9fc52f165ac5a6df250f71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAa13c3291AAB9b413AC762CEC85eE0c5e639307a": {
        "max": 1,
        "address": "0xAa13c3291AAB9b413AC762CEC85eE0c5e639307a",
        "signature": "0x703f1ca9dc90b083cbfcfd2ebd1e2dc0020b46fd5931542ff41ebdeef1f7ab0a513670a99f34c59ff18d59e9bcf56a4e3c67d33a92d6c88711a95c4447294ad11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcDEBd74beaba2d76b5059CCf4C7FE17e0B5f1747": {
        "max": 2,
        "address": "0xcDEBd74beaba2d76b5059CCf4C7FE17e0B5f1747",
        "signature": "0x83d74bab507c1af7a158d0f8ca43771748cc7b83de96142a9e3a31746bd1b3461f57548801f4993ba0a751b1899685e8d1b894a979a7d1786dc77248c81646e41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0E1F051204F64dB4ff698e8948e9e06B7F8eb619": {
        "max": 1,
        "address": "0x0E1F051204F64dB4ff698e8948e9e06B7F8eb619",
        "signature": "0xbeca324f631b514add4e5965152853d16d4930b6676e0de6eddfd75b11f8c2db3c05f28bae9b2243e01dcf271d3df133587aa251635b2576a71750d95495d7051c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF5EaE34CD07892C8C672Cd6d1DEaca29583E8A71": {
        "max": 2,
        "address": "0xF5EaE34CD07892C8C672Cd6d1DEaca29583E8A71",
        "signature": "0x9b8fd2210484b4521c1691542547b13ebe1fb46a6140a34d5a645281d7896a9a094eac6710e6fec45ed145385547261445904623fcb65ead9775a04f8a3a2f1b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x765544Df572B7eb6BD29D74cd015d2f1F495F384": {
        "max": 1,
        "address": "0x765544Df572B7eb6BD29D74cd015d2f1F495F384",
        "signature": "0x77df10ed8ab0572e7ddd9953790797fe159faf0efadf640fa5ec19553ef5cdd61d0c29c737232b316bce273e28494fbde04f0f59c68593cd1d4955c467bc621b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEEAe6c14bD4120e6cAA179B3E0C56b6baf3d1bb3": {
        "max": 1,
        "address": "0xEEAe6c14bD4120e6cAA179B3E0C56b6baf3d1bb3",
        "signature": "0x633de4e4b29d581008d9701123c181e182c459966681488348a9a08498397c3101f6e13d4f26afda9c8988ce65b1b7e6179bfd4d2c2bf76d3c2af876a0aeb7a01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe9629F66127322eb41F9f58C06E58cdf5FceCEDC": {
        "max": 2,
        "address": "0xe9629F66127322eb41F9f58C06E58cdf5FceCEDC",
        "signature": "0xa634752e4cf7b04fef61ec2639e9e623d0c1173cf1d6c459730142d7840776007ffd5af4c5430f591f80c5bd34b4b684aa13cb351252b296d31b3cd080b698811b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4D16A9C63c88d7A5d64E4e0c0ca4eA8eaaEaF183": {
        "max": 1,
        "address": "0x4D16A9C63c88d7A5d64E4e0c0ca4eA8eaaEaF183",
        "signature": "0x93ba99e18f12729aae86cd46417cea2defbac49d741a5c8b7918989689b5784e5f92fe3552de9a6109fef099526ce12b883a5fc09e964d77a5b2a985b04597651c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xad5a16CFe893CDAc1A84e30c4c2EE017Ef3dc9d3": {
        "max": 1,
        "address": "0xad5a16CFe893CDAc1A84e30c4c2EE017Ef3dc9d3",
        "signature": "0x4d5d27da75e193484f8f7b103f6fd0aa430ee513fb8ef9495202b92c99ac545b62cf0090144155f6c2eccb058674eaa9648a8bb785e9a0513687ce5cf288a4de1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE1b08F07A20a2369A38F7E8Eda07E634a50552DF": {
        "max": 1,
        "address": "0xE1b08F07A20a2369A38F7E8Eda07E634a50552DF",
        "signature": "0xb210b898d238ef3e6923fe5893a46a70aa9e78607de9bd34af99987ae6eee10216fb8ec2eb4d1fa2de48e4cc69580ebcce2ec5cd4b4b60008194f59296da1ea41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7a36Dd2378246Fa3860b2474102Db37853BC8D92": {
        "max": 1,
        "address": "0x7a36Dd2378246Fa3860b2474102Db37853BC8D92",
        "signature": "0xc0689dd6391c484030c9a1eb58d0ef6a6270ff23ccd776c70fe5cb986bd2894630864f9d4133b294aa768bf79e4c626bc13b48cca6729b565a3ebe62f5e0e63e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x660a75F8Ca6b16753B74486a9aC76e41A7813bfe": {
        "max": 1,
        "address": "0x660a75F8Ca6b16753B74486a9aC76e41A7813bfe",
        "signature": "0xa559a7b76ae6d2c33da5124e7faccae5a6880526dbf00563fc7d171862aa8bf748ee034d473813d0275059a54d17f253d73fe94097413658b0e490865b4961e71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2b62038F4Ef48621aD391EEAAfCf0143079eb9A1": {
        "max": 2,
        "address": "0x2b62038F4Ef48621aD391EEAAfCf0143079eb9A1",
        "signature": "0x34be519e3d4420e2b42053e2dd1131a1bc7ad5ef65f87159d9086f23714ed2890b0b4cd024da2792649729ea2a42d77de5f2746ed679b8cbc66f74ca8c5602511b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEdD3D8DF1A885eB42E6fA8f9dBf6168c4860438B": {
        "max": 2,
        "address": "0xEdD3D8DF1A885eB42E6fA8f9dBf6168c4860438B",
        "signature": "0x3a08db833989d19ec6ccd18f491ff09a1dcb0e04738f79c8cbeba520f96574dc556133982bdd0d8853fd3802840f1068a054dabcedf5f66fa4836e73dde3d4c41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x63Fce0A82Ae7Ec1d8a2716cEd9439A667D42DAa6": {
        "max": 1,
        "address": "0x63Fce0A82Ae7Ec1d8a2716cEd9439A667D42DAa6",
        "signature": "0x8f2ea1c414a30110286b09e03a80df3d8d4ac0c87dbfa30277a00afa5bfe011e796511c8011467a03be7dacacd34ada77dfe964d9da872b6276aca96058edab01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFCa40c4541859818a12c60E043191C345323E98F": {
        "max": 1,
        "address": "0xFCa40c4541859818a12c60E043191C345323E98F",
        "signature": "0x52a5f74b11b5ac20ab562325c9c6cc8e0900db57dd283689c213c7e70eb783ff61846839cff217e00d9f409c4107f9c4d7b44cc885b7ca0fd2ca3865b65871691b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf813E8C9398B58F4a946842c7c41Fe69afAF9BCD": {
        "max": 1,
        "address": "0xf813E8C9398B58F4a946842c7c41Fe69afAF9BCD",
        "signature": "0xe5fd6ae4c4d774cc74e0a06cfce283dc0d0ece18546af1936b3cf1757dbf4ece3d667ff595dfea98c673aa8f5a43b0cd6c80c4acda5b4006e311abc233b9ab5e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9c628E0FFCc384836A76F341DA4E4F645113f5d7": {
        "max": 3,
        "address": "0x9c628E0FFCc384836A76F341DA4E4F645113f5d7",
        "signature": "0x20f1464c30d0689eb5e49535ccff32e2a9d7dc2eab980d18cbe3509b7273abd951d327d1b12f68a00b1752b8baed4289aaa2030d6cdf3ed3e998e74cede3a0021b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe0f3a766073DF15eF94F513B1239092f12621911": {
        "max": 1,
        "address": "0xe0f3a766073DF15eF94F513B1239092f12621911",
        "signature": "0x5b64d75a45fe9c9d696ad26456b92a7c112a2882290717f2aad6535959cc5be01966d4b6842b4c86bc104ab733ea065d248a94d991c40fda90bc4e0b01f493681b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1aE94Af0b6a7C33718D006091B7be2dC3B7186bE": {
        "max": 3,
        "address": "0x1aE94Af0b6a7C33718D006091B7be2dC3B7186bE",
        "signature": "0x614da190cde8d1a8da623c301adfdc3e8be3c866955a42b71501843be0cb49bb2df73a2d59c4f22652676c27006dfa2c5d4f9d3e7e7d15991ec523d3bfb278831c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8F1CDe0eE946014d6329de465606F2a938D62bf1": {
        "max": 2,
        "address": "0x8F1CDe0eE946014d6329de465606F2a938D62bf1",
        "signature": "0xa7b13905c1e018b8cb5a4435caf5e1a340cc690c5fb867a84bd3b320a88eb0983c97cc70f7730da7a7f8dda56354f3005b9624228ecbdb9e85a47b0590d743421b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x87E94aD527d642a8789759BaC292E285bab2F71A": {
        "max": 1,
        "address": "0x87E94aD527d642a8789759BaC292E285bab2F71A",
        "signature": "0x0c1d02bdc9ddd31d6d6a213a4be21f03625b334e720347b3afc2b3cc7d522aeb4095c26f769645e07ef19f30eef5eeb0466ae5754364d3e01292e17eda4a7c7b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6aAdC0776420640c9b80850Bb9ba558c829d7126": {
        "max": 1,
        "address": "0x6aAdC0776420640c9b80850Bb9ba558c829d7126",
        "signature": "0x1b25633d6a6c0e84be501cc4e4cb47145013830a47dfe4a7ff4a43bfe6d3a59f60a8f8033100ba9c486c6eeb0f4b5e223526c8c7de0b14af1ef7309b0c2639bd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0969eb9F5671B8bBBebF24463c8aB716A65c7E4C": {
        "max": 1,
        "address": "0x0969eb9F5671B8bBBebF24463c8aB716A65c7E4C",
        "signature": "0xa538430594458171ffeb272c6fd820debe4a2d89db9c4c8988406680f1ef99dc4fe95ef6cdd411d5949d2fac83137195df997ef3e0a8dca6ecada67f707031cc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5AFc967a56ad39948Ea07Fc6Ba21Bb0940FFdE5A": {
        "max": 2,
        "address": "0x5AFc967a56ad39948Ea07Fc6Ba21Bb0940FFdE5A",
        "signature": "0x6eb1bec71f47288b2433ee5f0e2aa18c5faf8d6a2552664a18b791a413beca3840c0a6507a64341431547754b15af81358885686b6803f589d8f2395e30d240a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaCB995252df36DD4c44ad5152176D58Cc526BD4d": {
        "max": 1,
        "address": "0xaCB995252df36DD4c44ad5152176D58Cc526BD4d",
        "signature": "0x38998a0c09462b5630264a7b6c213a46123c8ee4a55bcb8a3f0a5194378661f112833ad8d068ce04380a5bc40aeeae49f30e0416b05cd05f6de448691de971211b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x28d6E4F34E47158da2fC84a2B99e44A2aA4C1D10": {
        "max": 2,
        "address": "0x28d6E4F34E47158da2fC84a2B99e44A2aA4C1D10",
        "signature": "0x9b86742b3d659d90449dc3d469b8bdaa05ffd1147621c07b4b4e63682e9e829955d3f244ee659f190faa83b5f0d02d7a029f6325cf21759114c9c91020a03ce21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x532E4Ee74aF0B765283353F4C6f37492ed14c98E": {
        "max": 1,
        "address": "0x532E4Ee74aF0B765283353F4C6f37492ed14c98E",
        "signature": "0xa198aa83ab1c5e85629b47e2b5bc7b7769ee4d01956d3d9ef97007447b6bb481144b99510ded40fdc6a1ca4812e21498afa5fa8400d1496c18464370ab5923fd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD35C797e759305d7D9615Cdc49d0A38370d39135": {
        "max": 2,
        "address": "0xD35C797e759305d7D9615Cdc49d0A38370d39135",
        "signature": "0xa755293dbbddd52d3c766faf996d28ed21c285124e1276aa4d848e7c7bc49d947046bc0b6ad6e68668beaf0a955c03b9cab871655e0f145741c5d4e832b6660b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x28e214d2823749F3Cd1aC3e4d50d0bD7151dDd5f": {
        "max": 1,
        "address": "0x28e214d2823749F3Cd1aC3e4d50d0bD7151dDd5f",
        "signature": "0xfde5d94ab7ffb686693024e3aa01214d583e163df20c994fa061bf432a3582b249a49c262905909fd33461ab0641498c4bfae4a2da5bf8452000cfa86e3aa8e21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4485B7cE73115582e4D37F772d5C08726Af83814": {
        "max": 1,
        "address": "0x4485B7cE73115582e4D37F772d5C08726Af83814",
        "signature": "0xefa2a97e49da81f21b305df74bfff9053bcf2acf70bc41f37f2767ece7ed4f5363d21fbc03a160b9d314a19c819bb0b798256660dcb01f599cabdb4ee29f98f91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x79729c6e05Ad719b66008aEB2BA93BC54f37FE3B": {
        "max": 2,
        "address": "0x79729c6e05Ad719b66008aEB2BA93BC54f37FE3B",
        "signature": "0x4ced9c70fcf142a85940ed5ca7c1ccbfb1fa19fa8f66df31baa3a608732b927b535848d28f975d7b13a53cb28d338ec87d7817468b67fbddd62abd9735ccc9b11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaAB2ca8330CF0903bB166fd44fea99bDf607436a": {
        "max": 3,
        "address": "0xaAB2ca8330CF0903bB166fd44fea99bDf607436a",
        "signature": "0xa6a5856b21214384133892e37aad54a8a1aa731155defce43a588d0e78ad0f6373af544a632d307fee51bf7ac7314e9f2a3ebce7099a26eebf859530ac1167081c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x52385f14104e0E0Cb4Bf51978A99a5621eA12387": {
        "max": 2,
        "address": "0x52385f14104e0E0Cb4Bf51978A99a5621eA12387",
        "signature": "0x0d38168f49b557ccbe1d634eb757584af607e8ec7ff3a11edb2e9be457bb4af71df8e2718df17feb2ff15811eaa3fd732e11f7e94d7465a3e120c3fa3cc6dce51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x125869f4d89E70C8882334C710BA330ABEe9Ec0e": {
        "max": 3,
        "address": "0x125869f4d89E70C8882334C710BA330ABEe9Ec0e",
        "signature": "0xd81929d58fd48f581b4b6d34806cdd7d1671013f73b4e2e7eeee38c2a9bb5cd9525a2c3e1efe6838f704e69142d6a23bd37a9559dd415f095ac4c66ffb4806bf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0f71b5afB389AF61Be54529898eEc917175353dB": {
        "max": 1,
        "address": "0x0f71b5afB389AF61Be54529898eEc917175353dB",
        "signature": "0x84521ea1b84fb57106c9ae3a918a31815d01a4d6e13d2a1ee948a8babd8ab1bd7981a21953602a9de63643cfa4aeab49aa50ad6640a0348a82740129e277e2a71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x63B1B55d056D84c2f252822C1646765C513BEda7": {
        "max": 1,
        "address": "0x63B1B55d056D84c2f252822C1646765C513BEda7",
        "signature": "0xf334de2b3a2563e6ddedadff2ac51ef9e484f70f14e0531797a19ad4a015964e68de80bffddf7a9a23de5a8282d31524fd0585e4d1499d88ec09b6d952a6a9c21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x11aFf4f1aB8Bbe4aaa32AF2d2BB68D6C211fc040": {
        "max": 1,
        "address": "0x11aFf4f1aB8Bbe4aaa32AF2d2BB68D6C211fc040",
        "signature": "0x66756fb1e6e2beff17b92d3b57c3d9cb9f8275e4d51759da68f235fb7eb6ad74168fc0686fc9bf958c0554bc45e7b2ba8ef56cc27304c7ea0b13ec1c176625751c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb88437f8a872f878afF31f80Ad9292B0d910aEF2": {
        "max": 3,
        "address": "0xb88437f8a872f878afF31f80Ad9292B0d910aEF2",
        "signature": "0xabacce9c92e719e161ac372fbb18bbd8d839a98ddcdf44a68569742138fd3856174a6a9e62f3d12d79559af23de2d18aaf6da9ccf15dbdee326732f05744fb1a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x099858cb34C7cb8D1F4a5f2d536484164993EB93": {
        "max": 1,
        "address": "0x099858cb34C7cb8D1F4a5f2d536484164993EB93",
        "signature": "0x6b1b8ccf4a87c7d6d20d07665d1219aaad98a5e88917a30c5ae71ed7b6f23eb7340b5ca6ce31f69b05a20455d64653f55bc8e7422bada922d65c2a66af1408791b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x575007599901971456EEb3fe0Bf564B394004529": {
        "max": 1,
        "address": "0x575007599901971456EEb3fe0Bf564B394004529",
        "signature": "0x433826889041469a87a9e7eecf8300b3408906a60f91013f16d3faf59047220842df065e70145e8979635b4485ef9fe961460c0070e75145347a2f3cd50fea901b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaAb505cA8A36f674cDB0013B84595f69A132CF68": {
        "max": 1,
        "address": "0xaAb505cA8A36f674cDB0013B84595f69A132CF68",
        "signature": "0x9778b2b0620f405447cbabb73e3e4c101f8f98514da4d50b308face45a7154175db1c60cb5283d0eaeb6dbe33d18abf0110747a9417fde2a13b92e2ad5ad1f1b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfdDeC27F8C310c0159C4f171A3fD46Fe35a9441F": {
        "max": 2,
        "address": "0xfdDeC27F8C310c0159C4f171A3fD46Fe35a9441F",
        "signature": "0x9d7af6f16721a3cdd24ebab4ac71cb8f3ae4dd150c6768364153bdfb08e8dc3c6fb70c6449eab1586dd129bdcc336261de1180c0e15db55afc4fa75e3865067e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7dF6211fcBFd2c3688da84960085c6dd57D3658b": {
        "max": 1,
        "address": "0x7dF6211fcBFd2c3688da84960085c6dd57D3658b",
        "signature": "0x5ab6e5be31208a0e4577bcc3992897cecbbdc4e784f048c432e844ed8a8b173361979fb26f880dab2a5968f30f6542173b4fa6f60aa654218346f776f49d1b6a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB10dF21D5762381982BDB522957b798b9F5e8d98": {
        "max": 1,
        "address": "0xB10dF21D5762381982BDB522957b798b9F5e8d98",
        "signature": "0xe0c21320db6611db7a803ee4452f253a381e2c07a50080d5e7413768731351301d7dd54b31bd309a4e3a7beaf6c215d6ee9eeff4a36488ffcc96f3371189a1d51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3A870e8BddAF12cb1451c9FA67BbDEc703e59fF3": {
        "max": 2,
        "address": "0x3A870e8BddAF12cb1451c9FA67BbDEc703e59fF3",
        "signature": "0x54945267cef3e713fe0b263f430d83b64bd970a59f2639ac3ac1ab03ef23552f4072f0e191ef81f0a443836a63b73464466d535cc66cb9d54d21e7c9ad7b6b721b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC42159149B1715435690FB4089633F1377b93eA4": {
        "max": 3,
        "address": "0xC42159149B1715435690FB4089633F1377b93eA4",
        "signature": "0xa2d31c9de1fb65c9f831e058cd2bd53308300ac6dba1f69be3f8fa4996fd18482d9f3787ba5c21b293aeff788de0deb01bb98f99a448bdb58a35b9c66ee63cd51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x18DD833F5Cb10CDA775766e95d01f1e9dc0A38CB": {
        "max": 1,
        "address": "0x18DD833F5Cb10CDA775766e95d01f1e9dc0A38CB",
        "signature": "0x2bbea000b64428cf90d1e1bbaf9c19f6948560133fcc72b4591de864f0f04c443693673a9ac56d8ed799e4c3162ced11f60a1a30cd73af4ba2e671cd8878b6c91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfb9f48CD3A11594b1a6cB61ba3A84Ea594C171EC": {
        "max": 2,
        "address": "0xfb9f48CD3A11594b1a6cB61ba3A84Ea594C171EC",
        "signature": "0x75c0449c0956808888f30a04eaecc468e2bcf8d6c07008bbf3c6384a5e9b4931720c38c2ee8ee428af24d5a19ffe2f1a1b3ea6c39766a168087f62de189982a21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9220491DeB4A4E5339eDa37659C9D66d713a7EA2": {
        "max": 1,
        "address": "0x9220491DeB4A4E5339eDa37659C9D66d713a7EA2",
        "signature": "0xa0b91ddb22994410e36d58da51411da7ff3d944bdde2fd1a32460dcb0bcc106e02d5a79dc60642c1f03faad3fe558a22971b8c93606b97936a5bd8492d8270d11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAD79d347D42b9709E58972Bd0E4A790157227492": {
        "max": 3,
        "address": "0xAD79d347D42b9709E58972Bd0E4A790157227492",
        "signature": "0x0e82c0194d9cd0a318c25f5654e98d29b1d44d8e3ab22aca52b59742b63f8527060121d2f60572a44378ab29987dc06f18a494874789a46d9e648bfb874e8cb51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4F8004513f0067cA5eD5720747ecbb795b911838": {
        "max": 2,
        "address": "0x4F8004513f0067cA5eD5720747ecbb795b911838",
        "signature": "0xfa11359525b2a7c8e811f34701504714a8e8e799c0cd4c7b95377659831cc0ad2bbf820120f1e85430d831b7056d68dff1685f29427d86d9a9122b4b7792cb371c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA2904b7e48F8E428521FAF34E3D4c6072A6E6d05": {
        "max": 3,
        "address": "0xA2904b7e48F8E428521FAF34E3D4c6072A6E6d05",
        "signature": "0x4284a366f67102a335d0e66c3ffbb0497d88d71c5ea06b4b3be86d6423c9d2833011f90562ab82c71d59a7c430d2ccad7faa16dd4190cfddb3e15cc2bc8bac491c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEFd9ba2E337c7046297284f4a9358a2AEE5990bd": {
        "max": 1,
        "address": "0xEFd9ba2E337c7046297284f4a9358a2AEE5990bd",
        "signature": "0xab76ee617938b0008b244867fbe4f8fdc6e674a38e78d706a2c7fdcf0f437f126dacb54e2728db1610d020ff34a1f6ba5c5e392185628cc9db34cf3c7dec68501b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE188b0Ba723260B14D5C61DcF8e7a69Ab4B9cF41": {
        "max": 3,
        "address": "0xE188b0Ba723260B14D5C61DcF8e7a69Ab4B9cF41",
        "signature": "0xf46643909d58baf9473e79514252d387a2d3c35f5fcb72ab3eef8dab3b557dce2e213807a6951ebcfc7fb8eeb50dcd5af157705c3b8dce769d6b144f1c0fc4bb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7869ABAe1A95a215C0a9dA5371C21BF903716dcA": {
        "max": 3,
        "address": "0x7869ABAe1A95a215C0a9dA5371C21BF903716dcA",
        "signature": "0xe5a76c2caa6ace68714f1d360129bded9968ac375654b7dba2bdb25d4116849654d619c046aab31b0c26fb19aa586562af5a429be0d2fbdda8eb90c7632959301c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xab873Db0fB910AeeeaE62085ff172ca385c0eBeE": {
        "max": 1,
        "address": "0xab873Db0fB910AeeeaE62085ff172ca385c0eBeE",
        "signature": "0x0a17a98d168860fe6cbf5f955510ae1954fefe9cb0e6bf29a554149ff41bd0020483598e016edd7a6323f60cc3e41aa86cc907c54d2ad1b2d354f473a44484c91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeD43554dF2b4378103Cf4062075cB9C1fF31E481": {
        "max": 1,
        "address": "0xeD43554dF2b4378103Cf4062075cB9C1fF31E481",
        "signature": "0x8afe9b8a1b9cfd2010ab3b7106ae2268793bdb1e9f27901406b99672815f15b46e2fe52d3eba125c94ff7a5b1b17a316bf089a7ff1a08fa5594351e8b5ee3a451c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa6A7b9470ad83c6250fb922507454B8Ba5da0ec0": {
        "max": 1,
        "address": "0xa6A7b9470ad83c6250fb922507454B8Ba5da0ec0",
        "signature": "0x266b1f583b931bb113ab8c4579602d4ccf2183653cd38e9c1f31e60b3df88bb56674bb3c5f46f8e9e10f31f988119856596230bb29b95bd1596da0e59ef0ca6d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x62E725f096666Ef2f05fF3AAF4d0b042b3Eef5B8": {
        "max": 1,
        "address": "0x62E725f096666Ef2f05fF3AAF4d0b042b3Eef5B8",
        "signature": "0xd0acea4988c9fc3be3f85836582aae40b63d368caaf4a0f97246da0f34d3cd9236e18bc685ebd4fc14bf86d052d1e5957878039f2a4eaa940195fe1444f27a4a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0318d38Dcb790E4014935d708874833560Bb0d40": {
        "max": 1,
        "address": "0x0318d38Dcb790E4014935d708874833560Bb0d40",
        "signature": "0xa5db8ab86bbb9dfde076ce283d64f82168a5ab9adff38d2f90f9b1f15a5d329265ba887179435951e318db31a0f504b9fdb321873015fbd2c6dc2750e34ac9021c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1c5A3484db36794b28D9A3E2ae2D05ddaDDffc18": {
        "max": 1,
        "address": "0x1c5A3484db36794b28D9A3E2ae2D05ddaDDffc18",
        "signature": "0xed6f89da25583c84855f2b33707ddae16b7b8468b082c29291452f63013e778e38e419ea8a5929cff354353306c234c47958d902c3722f847f5d070f719074281b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeB72A1A8B1B566C5EA953daB95b593D23b347787": {
        "max": 2,
        "address": "0xeB72A1A8B1B566C5EA953daB95b593D23b347787",
        "signature": "0x166c8f6acca6543e99efa9bc991637456508c7614367ad358776a4d864e34f64372a06fe94f2317f8be500f576be35b76fa3a9988931b5b51e878fe416f6a6eb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x20D75f0973532F9F9F5295f4d97F00F719335884": {
        "max": 1,
        "address": "0x20D75f0973532F9F9F5295f4d97F00F719335884",
        "signature": "0x26755707a66b454196f0b3040cb8c713ddcda6641dde98b083cb2a86c27c563b0c438fd8ca90d0ffeb137be9959412d6628fa843944bf5ef490748f28c9bd62d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3EA06201F8F7dc29BA776E6Ffcdf1eF77528e8B2": {
        "max": 1,
        "address": "0x3EA06201F8F7dc29BA776E6Ffcdf1eF77528e8B2",
        "signature": "0xf6d3844722deedc331d8f5f9cd3be96128c99f8fb889c89d3ed01a66af14fd3b683955cffe9873de18ff808f040fee0552ba7fde3261fa418a0930d4a2fcfce11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaEFC57fD6956ed1Ea902d0DA3C351DA2F036A8Dd": {
        "max": 1,
        "address": "0xaEFC57fD6956ed1Ea902d0DA3C351DA2F036A8Dd",
        "signature": "0xcd2ecbd15b76aaebb04f0ce83e76d4c44f6c8898a0a69d72cda2cf12f92b76b83d86aa2a00e2d79470768bcb6e9292ae4a8618720dfd3faf0ec00275798a683b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x88E2B3dDb429BBfe7e8eFc392e3dBAd010Ca7948": {
        "max": 2,
        "address": "0x88E2B3dDb429BBfe7e8eFc392e3dBAd010Ca7948",
        "signature": "0x4d3d360e1936049b0cb44ac547686203a8b4e6f8220b999b8c7d2a858ea3213d7bccef453ef99c1a3ab2a2ac231f9b0494cd9ca9111e3ca356ee541c8bea01391b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc62272fb44E26C567e91B553059239Dda0c47Ff4": {
        "max": 1,
        "address": "0xc62272fb44E26C567e91B553059239Dda0c47Ff4",
        "signature": "0xcb77e9bdcc0422a62d102298721cf5cd7258966d063123f039a1b6a172e5e81c6f3228f760a9d20c43a95fe02be49b0d1307f3fb3624ec84afe337ca0c0171e81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9876449DE01A64a87F765aA72651CC795E44ef29": {
        "max": 2,
        "address": "0x9876449DE01A64a87F765aA72651CC795E44ef29",
        "signature": "0x97b6c7be6366965f03a9f78991c9c21e619c21540f1c995ee7f15def343f301745677773b582e5f51d366dcdd839e43396616f4bb3715dfcf538f64fe0041dbe1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0474EF727CECA0Ff3f260AE836a91071B56260fD": {
        "max": 3,
        "address": "0x0474EF727CECA0Ff3f260AE836a91071B56260fD",
        "signature": "0x2e49e2413d3c6862e4f967cdaf331636e892ff3e1ccc2baff4ebc66a04a76f98439a8308d0b401a38c874b6eee04e4c34ecb23395e44c941c18460b40b627c931b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdE375d321E98caDddc4eD082d73E5B65fDD43E1c": {
        "max": 1,
        "address": "0xdE375d321E98caDddc4eD082d73E5B65fDD43E1c",
        "signature": "0xb43ad4f120865481f583702c44ca16e1f4b5323175380f31467235ce6888b63f551ce98424f697e9228e8413a32329daf2e47b883dd9ea89f2711b313b3e918b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1b86677DcD14aAe39cA26a344702587b5BD91186": {
        "max": 1,
        "address": "0x1b86677DcD14aAe39cA26a344702587b5BD91186",
        "signature": "0x48b093593e332afc523e60a075d2abd839e0c3821fd367fcc99cd7cd4622a73705caaf36750db35bd8faa26d4c851ba02f63965e01c9cf77395607ee0e9f79671b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x02D4FD0ec9980037d2D704F4fAA720CEb82f9256": {
        "max": 1,
        "address": "0x02D4FD0ec9980037d2D704F4fAA720CEb82f9256",
        "signature": "0x31cce66f3e66c97ef9f56c3cded55f6c0b2fc501845da62b19f576addcb92b9116332794e356c59a3ec06cf4b5c4c6a8564ea050d5a6c1fd3fc802ae9a14e7b41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0FA6ff986FB6F7E28E96a3555D59f8eC75C38268": {
        "max": 3,
        "address": "0x0FA6ff986FB6F7E28E96a3555D59f8eC75C38268",
        "signature": "0x17b1d8d72dc1e19445ba2f000b7194dacb64d50ee584bd34fbd1d562d33948080ceaea7df9b8fca8be461a42abe7afb58b78d5ea5828071885ce3cd466eb96df1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x79B33876135EA1Cc4ab3D4c7018D36410B074fAa": {
        "max": 1,
        "address": "0x79B33876135EA1Cc4ab3D4c7018D36410B074fAa",
        "signature": "0xd5e8582ad293e4ba454c0de85e273840dd3ca165a6213439e00ec4a3682809e82b625c02cb8dad476a241cb2abd228861109346fa8c42cc6bca1c5086bf2307a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC443727503ff057e36d12f45DAd99a8aF851C883": {
        "max": 3,
        "address": "0xC443727503ff057e36d12f45DAd99a8aF851C883",
        "signature": "0x02afe7f80ae191d3c8acc0c508eb97b2da6146960a09b6606ad4276b7160acd707d9066b1efb3ced223b4fecd2f1388bd6a87d7cd75b674d147dec2886fbdae61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3D06b57b60f9a54fAc1522F7cEd4C4E1c7cDfF27": {
        "max": 2,
        "address": "0x3D06b57b60f9a54fAc1522F7cEd4C4E1c7cDfF27",
        "signature": "0x42db16ea3888d0fbd2d0beccc427f8e017931bbc894f2ae4cef6c35aaf404d6f75416965045d1391f41d0fc271c42df4d9d1b151356c86c1b4129ad400583c151c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x165c5D86381a9cB403d33118D4E5A81Dd12A3Fb1": {
        "max": 1,
        "address": "0x165c5D86381a9cB403d33118D4E5A81Dd12A3Fb1",
        "signature": "0xdb36524f2f72365705c63e2b31c94e6da54294e78093eb20e4b0ce0f9457ead5432552bf9edee0f315068c5831efd5e25f044983e2a207d502f0d480613fad721b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5Dcf82B9a030a8d5BD464De59D11622Ea66540b1": {
        "max": 1,
        "address": "0x5Dcf82B9a030a8d5BD464De59D11622Ea66540b1",
        "signature": "0xfa755057a4018ab71f5bbcfe792827f77ccb7e97226bce27d458ce1c34f7b40a09728af1ec584c43e6080b1356c51621b419c4f6249b33ae19be032775a766131b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdb03092e08d1e913E2294f597C24e5C31B30cD46": {
        "max": 1,
        "address": "0xdb03092e08d1e913E2294f597C24e5C31B30cD46",
        "signature": "0x44a6906f604a049bacfc5539982d752229d7d7c9ad4ef7871d9994a1101002e85dd6dc778d6d4a6e25558bdcfe485fbb74a912535907ed241b1cc78dae59cc3c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE2a323110f58eE47Cd6ccDFE303Ac75AA1748Cb4": {
        "max": 1,
        "address": "0xE2a323110f58eE47Cd6ccDFE303Ac75AA1748Cb4",
        "signature": "0x8a38d4277f4ab84300a62cf2784a380e5a0860d95706ccf71d4ca270c4ae2ca72f160756b5b789d642ce416f2224155ab0e69dbd7f0213afea6d5b6a53224ed31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7614C69077dE016b165605559b0954984B09e813": {
        "max": 1,
        "address": "0x7614C69077dE016b165605559b0954984B09e813",
        "signature": "0x8f2037c17c3f08a564a864079188a3f5879380e4f8b873399579e0f196d621053f1da8100fd3e09b753fe0156687372263691e5f2c22c9c6f126a610ccf540c51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6eDfc7b8eaC86Baf00056Dabe3086e700Cd06523": {
        "max": 2,
        "address": "0x6eDfc7b8eaC86Baf00056Dabe3086e700Cd06523",
        "signature": "0x7eb8e3a322e8938e0cf6101ed81b7610457be8e5956a5f3dd4b8c9d9dfcfdb234a962bee012b8eafa71094deabd046eea1e031ebc194bf5f676d2cb010da13fb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x60deb3E50183E102334c1710b8F05040267dfD62": {
        "max": 1,
        "address": "0x60deb3E50183E102334c1710b8F05040267dfD62",
        "signature": "0xb7d9aa799a1fb24b3aebcff18d8e48a772d327d4d5d0ae0919514267e909d0ec40f66249aa6d475f935587e1055ba666ef62d38fc88a834bfe6dfd27b9dc417a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6EeB195A2E36D8daF27478E59f85868290292951": {
        "max": 1,
        "address": "0x6EeB195A2E36D8daF27478E59f85868290292951",
        "signature": "0x6768abf49c0c4871e1bab2dbe3f0d17dabe668651200ce12dd845bb2eac635311775fa272fadde525b5883304f0f28f5437bc3c07d880b5903127b09168b60c41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB4e5B893CFbc64b587F2bAd248F84705Ea8333Ec": {
        "max": 3,
        "address": "0xB4e5B893CFbc64b587F2bAd248F84705Ea8333Ec",
        "signature": "0x97524bfd92fefc646b9f00f81961e85bc7b258414ff09ffbe0ff572a670f66cc67fa5c9e9da59e984ca9f98440deabb124eee32e755f21c8f5a96fb50427c2f11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6f4f5FCa4b3A5De5EF0817f2834E7774B620CcCb": {
        "max": 1,
        "address": "0x6f4f5FCa4b3A5De5EF0817f2834E7774B620CcCb",
        "signature": "0x90d4bc4812fa91928d4f9e012012787de1afe8b4277b97a4a49c99dff8ca0af0502a803d38ea6310975eed4fc02ad2a3f34bbb6acc0f86f01a51cb78de8a69be1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9f25E62422D4C93D066A12cC1EC197D8d6dBbcfe": {
        "max": 2,
        "address": "0x9f25E62422D4C93D066A12cC1EC197D8d6dBbcfe",
        "signature": "0x6324275678c6504e5a12fb8577ecd214a11d5b95056ca4ac1578bd9fe0ee088a5f74bd8e8a8960a89f23bb7e846c7a7a58338eb0c08a80eb804e9998e344332a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5dC8e1651B15D895828c73dC96cc00dBe577752B": {
        "max": 1,
        "address": "0x5dC8e1651B15D895828c73dC96cc00dBe577752B",
        "signature": "0xf17ea53b076b3e3771e10efda2331d3dbfdfef0e3b97ce65e8c9c2d0b3ef339341ef2fe07c9d2a9d21bc4263d7e5b6ce8b91135cc6af609cab2df71e4b4656371c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1E094ed9Dfe65b02fD4dd14c046ac6b413d383a2": {
        "max": 1,
        "address": "0x1E094ed9Dfe65b02fD4dd14c046ac6b413d383a2",
        "signature": "0x8a89f5d344ac0d5a0c7149989c120c014ae8dd3a68df973755719bd209c19be37f9d6290aa198837050d536b19ff3f1538d4b26b847a1d6cf23f50d7a00f417e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6b9588e1ab955b8e63Ceb1D439f603Eb678e9FF1": {
        "max": 3,
        "address": "0x6b9588e1ab955b8e63Ceb1D439f603Eb678e9FF1",
        "signature": "0x4a0e1e8b55d60b6228a9a47bb5feb53b6ffb40842840e99f0aed3eeeed3dd73f19a2cbbeddd387401ee4e498de33c7df1c6abc3bf29efba3681dcfe4f0e175471c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4775a0A6E36CD3a1d3D48738FC68C77339D44d49": {
        "max": 1,
        "address": "0x4775a0A6E36CD3a1d3D48738FC68C77339D44d49",
        "signature": "0x2a00d0f9dfd3d3a979ca056122fdfc030e64824c000bc9d8506d547ee3a51d9540ffa490f6b906f4975983421dc5d3eeb45befe4442ac75de18bd994910135061c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd4Fd0B1e7d2cA17B72813F406e3f211188326A9C": {
        "max": 1,
        "address": "0xd4Fd0B1e7d2cA17B72813F406e3f211188326A9C",
        "signature": "0x34ffb9eb848281a83c1dd323d8b8032398681be8497535be7d28b244694899c5044d78a65c0d73441c070a65c2eb20c902d0ec16ed8552a22f0294a16cf382e01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9126F676fb35fC0fd24893a5437920d5d6d657bA": {
        "max": 1,
        "address": "0x9126F676fb35fC0fd24893a5437920d5d6d657bA",
        "signature": "0x14a56f3f1da16fbf7e0addfef67e9fcd42d2d7a3b5c4cded2c2acd222eeac4b506e51e6f56a888b346c6791a73c7c1f5c9c76d637a0e0eaa9a1f8fffa5fbbd4e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x29f889fAc52A05386073bBa0e047F4873c6e0e0b": {
        "max": 1,
        "address": "0x29f889fAc52A05386073bBa0e047F4873c6e0e0b",
        "signature": "0xadfcbe0b66e1a3bca0c16a3c83c01a32d4d53513e6675d62281c7de7836c1bcb74c3a6f06625be688ab41c54cdc1f933b46647532fa6a4138f68c584517903c91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf8B60d5c78f86c3B94580F3Ee9b472bF5F8b673d": {
        "max": 1,
        "address": "0xf8B60d5c78f86c3B94580F3Ee9b472bF5F8b673d",
        "signature": "0x00f8a85e768c347dacde840b87fe8e48c75d40023ff79fadcf303fca44ad8eb66f4d429a9f930566f841e58f890dec69acbace716c0b506a7edb04f1274b723d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8efC6BeF8b5cd38ebD5Da4706F8FB88DcfC57A59": {
        "max": 1,
        "address": "0x8efC6BeF8b5cd38ebD5Da4706F8FB88DcfC57A59",
        "signature": "0x9b93d232621512dbb8881377ccc59c27c4ec781afe2f03f26e15b73f2872541d081a5c0c97aa689b176848976f94382cdedfeb2dd4e5b99a5ada6cec316f811f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x74D5EDf85139c6289f2Ee1ff49DD8E1864B0104C": {
        "max": 1,
        "address": "0x74D5EDf85139c6289f2Ee1ff49DD8E1864B0104C",
        "signature": "0x069a19278eb52d8d2d9410d4d51adcfc68760f87d942ffd4e425305f30f56b5814081e8d8b09a1c9fb0a563975f5b2e80c3d257bab80a78da0ee0124c68c7d981b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc15618d2CF67AF2E639F6db12a156853F7E17da4": {
        "max": 2,
        "address": "0xc15618d2CF67AF2E639F6db12a156853F7E17da4",
        "signature": "0x78aae93c02fc5a6585d60e741371fb24391362c37e3f4666bef5fe2d46fc047a194b8fd52ea4054036935891619e143360cca738b3a5ec24895fc7949b3588411b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD929Cf6e95153f98a8be0aF7eEa8C377eeeb415A": {
        "max": 2,
        "address": "0xD929Cf6e95153f98a8be0aF7eEa8C377eeeb415A",
        "signature": "0x8c5a8c9131e9d868054b027ba397ccaa6bc7d504f2a1e10fb22c930d663fc3db1273163119a76d9676cfdba3c23961ac6ee291c67066e45dcc10cd41efc2e3531b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6ACD7B0fbe174Cf92F3A27a4FC2d23b992691A81": {
        "max": 2,
        "address": "0x6ACD7B0fbe174Cf92F3A27a4FC2d23b992691A81",
        "signature": "0xc1d50219a85daa40523eef2207593babf8c8fdf6332de4eb602f5b94b30d23ce483f638491c0459a31785e0b650541f5f84e525f48e1bba071e996d7588836db1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1591B6f0c7dc56b24B3527e44a9D5f3FAC0F3dC5": {
        "max": 2,
        "address": "0x1591B6f0c7dc56b24B3527e44a9D5f3FAC0F3dC5",
        "signature": "0x2183048df50667fbdc9d2e4f9fd11b1832ce43c0fc1a9e009bff1835c1a60b2c5955374994ddd6c1a16eef2ee29024a1769741674c163d7d479f37138e0801761b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8F66c0c359B4546512BC8dca379B89Ac93008d97": {
        "max": 1,
        "address": "0x8F66c0c359B4546512BC8dca379B89Ac93008d97",
        "signature": "0x62d5bbc2d45a7aec2a755552c907574d8803adac6f1e9c6d19121d6128c1d2d762536077b673306f858f433b563e3be2e322398a4cc3a12b19c5f1217598e23c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3CcB86a565DAa86D1613A634DD16663779bBD63f": {
        "max": 2,
        "address": "0x3CcB86a565DAa86D1613A634DD16663779bBD63f",
        "signature": "0x2f175b13a0d685e61d7b1375a71b3ae0abbdfdb409ad2c4338da0b4585df0c0a68480218218fbd9b3139a44c8e87d22a27f362b0d8e4d3fddfeb8e61a66a92431b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbe09ee4DBEFC5eE1dC61E426AAF7536013b21CcA": {
        "max": 1,
        "address": "0xbe09ee4DBEFC5eE1dC61E426AAF7536013b21CcA",
        "signature": "0x02fd5820a055d96b17f1de202d0d095f1b611826c128c98f9512eb48fb92d6ee597cd1f244d0a12a36cc8306be1ab077af10b224a885be6779089897c19820331c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeB4E5869a1Fb974c443A9107d038866e6d437F1E": {
        "max": 2,
        "address": "0xeB4E5869a1Fb974c443A9107d038866e6d437F1E",
        "signature": "0x0d031e1671642aaf1467c9e83788289eb71c0e9951204c1fc2245607a2efc6e21344a543348b816afdd73ad2da1e0b9db8a782e5104ea8b7935308ea9e1c10321c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC6b37e3715261f096c81aC60507f30E1f70757C7": {
        "max": 3,
        "address": "0xC6b37e3715261f096c81aC60507f30E1f70757C7",
        "signature": "0xf532b8571267cd8c5cd58c8ff892580cee59abbeb6db9b0fae63c24bab859dff50cc8766df34b159c4228dea876dab70e46a7e945ea9ab0d20acba501580adb91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x59E800C4dEbdD336213b882f1035472a044Fcb2D": {
        "max": 2,
        "address": "0x59E800C4dEbdD336213b882f1035472a044Fcb2D",
        "signature": "0xfa0c98c5abda81866384d826708d770e64ff3d98a2a470c654cf6f45db83446c1f5f2e3ba4d44357c78b021851324c6958259e1b2f87eee7eb1978074f897d101c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2F40bbB492B94098E06445CF2f5a19aF559bC59A": {
        "max": 2,
        "address": "0x2F40bbB492B94098E06445CF2f5a19aF559bC59A",
        "signature": "0x1323ebe88a23b26661d3df435f68dde6f08d979bb1cbd429b24cb6fbf81afe4256b2a392fae6e4b0f8b2430eb3404bd65e6753820654b4a713f9cd50fe53ebd01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9620Da3d1Cece0ac2B43d5012270B4036894DE4a": {
        "max": 1,
        "address": "0x9620Da3d1Cece0ac2B43d5012270B4036894DE4a",
        "signature": "0xfc8241a2dba9ec21e93cbfe34ad676548262a055f66ca38d2b178ef44cb5834a7f58403d94f651a3f34a2ecbea294c587e88f0288d9c9625279f47efdf6017961b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2D8f11b3e4010C067Ad964D5d8558e2b61E21f07": {
        "max": 3,
        "address": "0x2D8f11b3e4010C067Ad964D5d8558e2b61E21f07",
        "signature": "0x7d4f31333984238f0d09158817d63f13bf236dc26baebdf1951cae343f629b5c25a98bac90e254895485db5ef44b7cd4b1aaa5244ad6163fa6ff959258f5e7101c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5319cff4F5B3BeAB5DD5d2eE9a067D7507eda4f6": {
        "max": 3,
        "address": "0x5319cff4F5B3BeAB5DD5d2eE9a067D7507eda4f6",
        "signature": "0xd03d615b0e26da0f9eb17c7e74009ba28a6a805fff61ea54e6acb6263423ca2d5c5b697bcc0620f57fc1a1b56cef4fe676521697d931387cbc8ef427b8c5b7661b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9C3196f8C884c2F7Cb2BB3c769FfF8D7E479CD82": {
        "max": 3,
        "address": "0x9C3196f8C884c2F7Cb2BB3c769FfF8D7E479CD82",
        "signature": "0x1c7f49e1f9b453c15daf1203235371ea6f5d50a143e32ea8d9bf6fccef51bc976bf46cbbd50c55f391b73ad3dd353d286580ab4ee9a97d1da5e24c07aff6b8d11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5Ec2F1616b0C4E4f0E911716c8C0b02cc80f27bb": {
        "max": 3,
        "address": "0x5Ec2F1616b0C4E4f0E911716c8C0b02cc80f27bb",
        "signature": "0xe40683e2a85a4f628728b7008145abd83fce5ec4c2ee94b5b1b46f77375fd4c7047ebeb04a74b5cd413a2cd23e5ce0e84c09066079848e4f9e319261b595e7601b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd68e2193a56057BAD8635140a7Fe1Ee5d6aC63D3": {
        "max": 1,
        "address": "0xd68e2193a56057BAD8635140a7Fe1Ee5d6aC63D3",
        "signature": "0x5444091de3819ae73df42f4f7003763b444f21eb9c756e609236e03a91e24ba95b413a3946041767821036b2782155883318028bc8cb7e3ac478a1bd14055f021c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x732Bb12525961f5853154DeB9d0a4Aacd2eB240f": {
        "max": 2,
        "address": "0x732Bb12525961f5853154DeB9d0a4Aacd2eB240f",
        "signature": "0xd25497292c2f05da82e47084e258437f89ef8ff526aa130a3ba3c5cd7450defd01c2fa406f8f5e76b01923be92365a41d3b4286bb06e4bc9ca0b4acf01e103fa1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA8C70533331be88189DF54E3fD30ADBD3072DD60": {
        "max": 1,
        "address": "0xA8C70533331be88189DF54E3fD30ADBD3072DD60",
        "signature": "0x742b099e107d5b45d6f7dfccc9cee12ca2a740fcb21f54ede3a403c1798cc02c0b6136771a406a15a0946e50806bc2bc25f069f823441d9a227833ba5baf49f01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdD704Cc4AC9A043c8Ec709751d568d78E2dCb571": {
        "max": 1,
        "address": "0xdD704Cc4AC9A043c8Ec709751d568d78E2dCb571",
        "signature": "0x543349db28cfc7adfd91406700e4c47159a730d989997aa0e879c973ee2fef8b093af94bd03225702f4929780ae3bdaf2e2ffbbb4448ea1c53679dde5f5d85111c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDeFA5675CDf2c7482AcEC6A16626D14941F0ACbE": {
        "max": 1,
        "address": "0xDeFA5675CDf2c7482AcEC6A16626D14941F0ACbE",
        "signature": "0xf1e2dce943d042f34777b6f3731c638e8161cc57f8b495f443e26712e6ed1ed730636def6a5154b621c605dd3743de94f677c1a419b2538cc508f4a95d0a41021c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5668D454a0594a0A18B720080eC3052C5Ecf871E": {
        "max": 1,
        "address": "0x5668D454a0594a0A18B720080eC3052C5Ecf871E",
        "signature": "0xc2995f942afe344b73c25fdfe4dc059c45ee6601b9b30317699111800e89e4c40c0959dcabb968bdec73ec74c3fb1fc3ca5d5675425a0dadc269ac7a7b8cb4c91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9D9bB1a52915074c651848Ef2cF245624A135eD1": {
        "max": 1,
        "address": "0x9D9bB1a52915074c651848Ef2cF245624A135eD1",
        "signature": "0x9874d5278dd2f3c2534ee366c5c59235c87ded4f2b683307fbc8ecc5cbecd41c56cac9c50e568ec7f223db181990483048fdd86bd875cbcd9ed2bf66aea88d5f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0b259cdE8d712931F1422A4487Ac204E697D04Ee": {
        "max": 2,
        "address": "0x0b259cdE8d712931F1422A4487Ac204E697D04Ee",
        "signature": "0xbbaf372ae0f5596d47c070601edbf75f11ae71984c32d7f9a990e4d33832d9e62939be128761383fce572a164691e576a56a95c8e172ffbc8054f101983969901b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6b1B673E56f90e5848B022440115BC882cc40e86": {
        "max": 3,
        "address": "0x6b1B673E56f90e5848B022440115BC882cc40e86",
        "signature": "0xb8f62e6ee9b2cff2f881a71c62109c5a25fb6e780ef750874d14bd69160bfe2f7d40585d455699fc6706e1f76aca67ad90b73d2c77085f1c478771965c9dcda21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE704A46dFDbFE1F5224df23ca1D8FA69374b1A7f": {
        "max": 2,
        "address": "0xE704A46dFDbFE1F5224df23ca1D8FA69374b1A7f",
        "signature": "0x4ff5f7df6f6de3568770e4c9ab4c2ae8314e3f943023609916ee5417c420e60123dd301b7529ecd5cc1dbdfa8fad2b1c4582d5545280756b9f19f787a4b50aed1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0904796616619742F6953166Bbc5Bc96392De502": {
        "max": 2,
        "address": "0x0904796616619742F6953166Bbc5Bc96392De502",
        "signature": "0x5cb4007100e37800f065d9b1434b3390f1fe487fb5c2a97388674e4a3cdc1efa699f1290ad8588b6074c61b77f704f2bee5b37036c590936eb14968a373186c91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA47EFA8Ea6d770c280B9074282e7cFe63446ee35": {
        "max": 1,
        "address": "0xA47EFA8Ea6d770c280B9074282e7cFe63446ee35",
        "signature": "0xa27bef8c2473d9edee8fe3f39c1888f39fe8ed95a05f6816dc616a92e707590e7c61ff8c7b74336e4f216d32e9c1bca1ff3b98d7c28f0dcbf6b957f62318310b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8feda00F185b1018D404347cD82A00D1DaBf65B3": {
        "max": 1,
        "address": "0x8feda00F185b1018D404347cD82A00D1DaBf65B3",
        "signature": "0xb3e6b4756eadb7c7123d2b4a5673f033354781752e9fc62eb6c236e57b4e98f221f21a0bd5abfb4ae36e638f58fd1e047ce17be0f25dfbce87ef556db91691161c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x384e0f613dFCC82D1C1A53b396e9Fd28976462EF": {
        "max": 3,
        "address": "0x384e0f613dFCC82D1C1A53b396e9Fd28976462EF",
        "signature": "0x84342e0b0d8dd30e4afdbd05f5ae7de8f82af1e3807b42355042971698078aef1430962cabff46090955aba3458555f74013cceea1e7bf1993acc51f44d68e3b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x198C17D18B1655f260E8652301BB629aC5402198": {
        "max": 1,
        "address": "0x198C17D18B1655f260E8652301BB629aC5402198",
        "signature": "0x8db6718e40ff743498d132ef764d4e42b3bf25a1ae7068a58588568a9c80c10a6c09c44a187e9a1c1c074a8c83492afa77747fe95110441e2d8c2701a7dae0bf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x86D25cE7aE1599ab1EB1c277C8d1BD80aBBB15b8": {
        "max": 2,
        "address": "0x86D25cE7aE1599ab1EB1c277C8d1BD80aBBB15b8",
        "signature": "0x48a792dd436af29c6394f60eda1711e6f135aa0748981e3546c8e7c1b72d06c97653d28bb7f5836dfa8f99c369018b28f6ecb18a102b78e045a8e7adf7620b061b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd166aAb6c72a85D5Aa3Df0D737c26e8C545E1154": {
        "max": 1,
        "address": "0xd166aAb6c72a85D5Aa3Df0D737c26e8C545E1154",
        "signature": "0xfc9463351670b57883c819ee5254b715430b3743ec5713a5d18763022f1584b235a5893afe0ba5b51c26c5548fb4263f772f7e1f557e98c4a1f60766de8f50811b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc42db05E4Cf51dB102a31Cf71a4AF0BEFE092903": {
        "max": 1,
        "address": "0xc42db05E4Cf51dB102a31Cf71a4AF0BEFE092903",
        "signature": "0x7071a1d8e6ff10ffe3ae9ac7b1be341d90d58f7c9790bacd6200b8cc1284363635b5f38618c09979f6e067a69ad04852d17b20c7efb6187da6776732ae611d961c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD9625edb8326E0eFd0b84986Ce377a551206A71b": {
        "max": 2,
        "address": "0xD9625edb8326E0eFd0b84986Ce377a551206A71b",
        "signature": "0x919cb730217e540899f2ea78a0c534403ffa001676bc16470a99acb9d2c53abd7bab7033bc946319648f63d49b5b85a871d1e7ad100e31712bd426bb133d82681c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x408803645D88f109796e78d0B9c8C0c262cE9324": {
        "max": 1,
        "address": "0x408803645D88f109796e78d0B9c8C0c262cE9324",
        "signature": "0x7200008dc69042b8483abcfaa036a1cfdc10baa5a15dcd64f1ce707df74a4f4072cb9604fa1b152ac8335afec473bf1c038117bc61beda1dcfe077adf3555f001b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x57cb8Bd60663B98aB9e84b44195F50A86E4480B9": {
        "max": 1,
        "address": "0x57cb8Bd60663B98aB9e84b44195F50A86E4480B9",
        "signature": "0xf8e273428499930e608e9b1312f31d9c1b874f3b7d60144141fe02a2702074b6111a2f65ad4377e9bbe4094f94204034efab9d824f81ba8c180fc578aa09ce3c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x512F826E7AbF25086427F4233b769a34023D2805": {
        "max": 2,
        "address": "0x512F826E7AbF25086427F4233b769a34023D2805",
        "signature": "0x9c7a449ddca02e2c845b002f81cfa7e3295ec42155bb24ddfaba037185d2808c5e493d80cb86497ac9aa58a80a55255d057bbc86d26338858b8197fb7dfccc4c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6D274cA4bA123769985d21a60D8ae14ecab1a43B": {
        "max": 1,
        "address": "0x6D274cA4bA123769985d21a60D8ae14ecab1a43B",
        "signature": "0x774ac8125511d9dc1d0e223407404a53ace336eb85795ec8a381000a34c4b77955c572edf7306f6219cdb83340c2a4f54cabb153c97107d20a42c09fea7800231c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2a0D11204383088511667312E91F30252f5600F6": {
        "max": 1,
        "address": "0x2a0D11204383088511667312E91F30252f5600F6",
        "signature": "0x96d54a98bb1e84afa3aa5bdb35899021552e02609511409d26a3a6d484d3f2e937513f986a4c31aaf57a828ffc9e4203544ff3732146906a44701a7711b18dbd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x14787F64b7d09cD0824b27636d4d81035dF15888": {
        "max": 1,
        "address": "0x14787F64b7d09cD0824b27636d4d81035dF15888",
        "signature": "0xd3dae3dcab6007a8b4e9a3630c28cb2ca84806a30fa8d6f428f3340ace9150a61da8ad336a3953f7d06b0d657ae9978da82f854cc9a6e5e50d5497cdd1e1a28c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x79905Ba011ff69423c65ef49D51ea64CAb49579F": {
        "max": 1,
        "address": "0x79905Ba011ff69423c65ef49D51ea64CAb49579F",
        "signature": "0x85acdbff7f7b7397f6ab898b6d3f78ad4beaa19b19b395468eab0b65f5606a5019833d52a4495cf85d553a94bdb2bf495b84c9fed0698d5eaa4ac9e589a83c871b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEeeE61D1A5df13e4a0B0aFBc65B0D91A6B7015E6": {
        "max": 3,
        "address": "0xEeeE61D1A5df13e4a0B0aFBc65B0D91A6B7015E6",
        "signature": "0xcf36ffbc1b8e11c26b642bb3ecad2652ac5fe95affc5c101f16990e3067993bb1235e657539d23a614172c19ff827193683e8d580dd79931db949d6977d2e1a31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xefD991b74E21bf71189c95593Bd1fcf665ba2c26": {
        "max": 1,
        "address": "0xefD991b74E21bf71189c95593Bd1fcf665ba2c26",
        "signature": "0xcf1c7f53d7e789b8bd48945d3fd26d85bef0a99304ff346dedbce80da49b7c781f9cb900b6cc3fc82e245e8fddf9641a2f9adb1dfcaa7f6a3dd0ced38535bdc71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x78688EA05E514848C0cf86707AB4D6fCb344b04A": {
        "max": 3,
        "address": "0x78688EA05E514848C0cf86707AB4D6fCb344b04A",
        "signature": "0xcfd8d0bc3f759669ffe9ac28b7fd1f7371a5d33e4f336305527103b3c8edabdf4ae131dea936c1fe8aa0c3ebe79f4032e0fb4878d17c4cc3a702a7bba74aab761b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA0Fd1BAdDF6BAF3b858B40f606E490DC0262E848": {
        "max": 1,
        "address": "0xA0Fd1BAdDF6BAF3b858B40f606E490DC0262E848",
        "signature": "0x4ab6a3635e75af9efeb8a957e51f754ebd664497a410474b53b6649eee248d4601c96f98c61e04a97662e8b596aec29cc613e0036261c8c178eb67d7b7b2a6241b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCADc7FCFd1c12da24be866d30BE91B44cbfF3914": {
        "max": 1,
        "address": "0xCADc7FCFd1c12da24be866d30BE91B44cbfF3914",
        "signature": "0x7ee27fe7ef8181e68b50c71e3184e28c4cd78bc22dfeed7cb828c75fc6cb8af565a7aa3400db5dc58f68760b36cbac84af6c7ecd665ad356acaaa71aaf284d141b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x53feE4B0d4f2F60e1A27acCb6F933907eA7C25b4": {
        "max": 1,
        "address": "0x53feE4B0d4f2F60e1A27acCb6F933907eA7C25b4",
        "signature": "0xff40ad77688fab014990245432de794c6202b99be6d975572680e8bff1b034be0fc2ed9efb83f7d04f7dd1409be03b35dcef9bc94056efd859313640b94f06881c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x427d9B95D9618756e78670d7e5F7CB600A6C2573": {
        "max": 1,
        "address": "0x427d9B95D9618756e78670d7e5F7CB600A6C2573",
        "signature": "0xeceda799ca749d1bbc0bbe5a2d169681e142f2bc5d58cfe85c52175b9272502c2230471f576354cab2a2b784d384c05e46e4f65000db743758d3c04c317844991b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3c31D629c92783021727dB299b70794ed7f93E8f": {
        "max": 2,
        "address": "0x3c31D629c92783021727dB299b70794ed7f93E8f",
        "signature": "0xce10d9011e836075e5d7e51b4e1d869dd3d4eb3422cfa9cfd385d88ea60d090c72a79e918920afb46ff6b34c740cd08fef7ec3e3bb9dfe0c735e93c8ed331f541b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x025b7BC2B7Df820aBb9a6468fac3a7Bc2d8EC9f3": {
        "max": 1,
        "address": "0x025b7BC2B7Df820aBb9a6468fac3a7Bc2d8EC9f3",
        "signature": "0x4c8f052178eb59ee53238eb8e1ef95729881a331ff70613ea81f5a62a84690150dee9b6bd1fb1a0119ce1640f8aec30af098bb397399d74708da612f6a6ceccd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6d3B8DED98d79190A4eaF1d4abE20DD3DFB173A9": {
        "max": 1,
        "address": "0x6d3B8DED98d79190A4eaF1d4abE20DD3DFB173A9",
        "signature": "0x688762d4a98b9cc58143475faac305627eb68a5d856a6f07aae51167b24af6837011b1fecbfc42876badaa40ec0e0a9d8ea5869890fe2c0927e88a598d8516ab1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4682207b69575b9b03C71236E7c5659A73A70363": {
        "max": 1,
        "address": "0x4682207b69575b9b03C71236E7c5659A73A70363",
        "signature": "0x7a24a9de070fd4fbe5c6205f7ac81ea13c1595af2d38a35c0f3b40aabfaa04ae4308f0904d476afb2e4025ae6440df093479bf0f25ad7ab96fa53e5539666b971c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3d0b91eb5BE39084f657e3AD66491B9Ac3F0595a": {
        "max": 1,
        "address": "0x3d0b91eb5BE39084f657e3AD66491B9Ac3F0595a",
        "signature": "0x4239449e953d30bc3b746806d5b31e8c34bef886b116e1f269f307f66bc37bed77a54f24cbb8e0a38d154714f91b8e52e2482658027127ccae05f4c138b99b461b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4B58FEB67dD91492Aa77f20dE915a6F502e92A56": {
        "max": 2,
        "address": "0x4B58FEB67dD91492Aa77f20dE915a6F502e92A56",
        "signature": "0x49ca871966a27fa24fd0a5f63ee6249d2c6a9b72213ff8accc0e38a68b130501154472a1cbac9583d087c2c0e408a73f7dd64602257e30ca8d1fa8bcaf6b3f661c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7A3A6FE2bDe4ae601804bBF951912E51C82060c4": {
        "max": 2,
        "address": "0x7A3A6FE2bDe4ae601804bBF951912E51C82060c4",
        "signature": "0x52e1fe35e59b28be98541c5cb7e40782f24ba4d1e2fd26d1ae9aadd6c68f950262d62a0f17cfebfaf69a67fd9d6baf334fc718be1e5bd5f5332df62c24707d681b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfD6f67B779d9373Cefe11a7F1fCBa40f53080051": {
        "max": 2,
        "address": "0xfD6f67B779d9373Cefe11a7F1fCBa40f53080051",
        "signature": "0xbf0372142fee213f73301f752eb91f540263c0e57b6f3fb4f40394d100272be4706b54be4761a2f14b83bd57b7e083665ee8f3f95a797e7b18f23f95d1ef06fe1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x82cb41Cd1660C97Fd64a21b7Bd938fd73ADe57b9": {
        "max": 2,
        "address": "0x82cb41Cd1660C97Fd64a21b7Bd938fd73ADe57b9",
        "signature": "0x011248357c707afc5f2297dafbd0ee2ae41871a97defc7f75ce19970c88bf4b520511453296f018f3b61feafb031239960fb71bb25ef5ff4da22a12aa99f4a1b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x39bC48ca7dC849a98f6D068f2dc6061D2806b9dd": {
        "max": 3,
        "address": "0x39bC48ca7dC849a98f6D068f2dc6061D2806b9dd",
        "signature": "0x8a11d6347dad34cef2f7befa7e68de6b75232a9af3bc836981a348199513557d75e9728494de4e71c4fde061df97bc6caaeef456a0ae81798e3dcb052ef0f0291c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBf7a5A96D488f5044abd0cE9D7C0952ecCd22d85": {
        "max": 1,
        "address": "0xBf7a5A96D488f5044abd0cE9D7C0952ecCd22d85",
        "signature": "0xcacee906a97c144a2cb118b20c87b7cdbd9fcfbe4037a5c2677a1028420fe4203d4074192ff89b724f76388f64a70f8a49a252bf545957fb9e266b302b48dd281c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5bd10b5D593f2AD88d28eC16EBBba2DBAd7d15Ed": {
        "max": 1,
        "address": "0x5bd10b5D593f2AD88d28eC16EBBba2DBAd7d15Ed",
        "signature": "0x8a78900c1e75b835714629ff96b9a609c21e003990c96b1bb4ba2c2b6092f615537e3bbb72935f2aa5b4f876c78b6965974f151034e6e2715b45f8b8e99d9c231b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA3c731882BBb5C2f19abcbbab06c22F20745Ef2b": {
        "max": 2,
        "address": "0xA3c731882BBb5C2f19abcbbab06c22F20745Ef2b",
        "signature": "0x0c42d632b1e095e810b97a26175322cbe71b1c789a2e5645e0cd823fab8d0e2d7485bace1fcd144c70ace85d9d47d9b97d3574f1564f01018a56e411a4f407921c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8b514a8fa1A913e63db69b879675cD04c4B9daF5": {
        "max": 1,
        "address": "0x8b514a8fa1A913e63db69b879675cD04c4B9daF5",
        "signature": "0x8b4b31bf9cbd59e59d7446090b8f8d0a95030e185013d3bd6f1c14ebf4fce45d1ac6a909691cb0d7d28867c7481b674c945ec200f90903ad2464d207894896691b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x36B3717714ac4f771115D21d482AFe690AA259D6": {
        "max": 1,
        "address": "0x36B3717714ac4f771115D21d482AFe690AA259D6",
        "signature": "0x3978a6e87679a4e1ace837012c51e768d8ce7eadcfd0d3b419afae57a75eaff6779f3ef7fe480fe83ff6050ddf7a2274504b80c268e2e85706d67da1828b7bce1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD537bd1351DA10F92Cc0C9a7EE83831BCF7117D7": {
        "max": 2,
        "address": "0xD537bd1351DA10F92Cc0C9a7EE83831BCF7117D7",
        "signature": "0x8753dd435a76fbe6262623c45a37cbfbc88f36dd4270a0336003b5be22f33b6735c9da93bf6f11543dde2e10ac607a5d9491b958b43945dcc7fa2d9d8b7b57221c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD1C101668a0984a22Eeb21B9366095843E0bEc1A": {
        "max": 3,
        "address": "0xD1C101668a0984a22Eeb21B9366095843E0bEc1A",
        "signature": "0x6f3f5fbe7e9cfd51d99d7a52196c3753e07de03333275d590d7dba834700466330b25b960aa14c799bca707fc95045946b95c912a2e04c8cab42e5b7741889461c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3FA339534c165BA902a5e05B30088016c227AA61": {
        "max": 2,
        "address": "0x3FA339534c165BA902a5e05B30088016c227AA61",
        "signature": "0x0a04efa904308c7ef8e28a553f7cf8f08b1949dd848d1645564df048687a9c1a2824786568b5e0fba9718751fa1750f9d7a5a75712f812466ac992649da49bed1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc690fF44F4874E81b9E0A862b9a2e24945FdaAa2": {
        "max": 1,
        "address": "0xc690fF44F4874E81b9E0A862b9a2e24945FdaAa2",
        "signature": "0x16695baaa108a2140c5d3dcbeb25a1f56c1859c617f7b5da0cf39ccfc268b2d21ec75b87d176889f10f69102fd5d130b0715f7b43fe894919fcee43e2ff589821b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0962e7319C4415A0EFea81EE9040De44C07C7D82": {
        "max": 3,
        "address": "0x0962e7319C4415A0EFea81EE9040De44C07C7D82",
        "signature": "0xa465f725c2398263e3488b2bad0d33b1fbad84995aa0a9ba040cd3e1850c3e9456cf165fc2f83cb0b008d98e6423775fbcefaffabe4fa4f4fbb860b4e6f820501c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd7672DC497285cb6B05ED3A60aAaa55e195Df507": {
        "max": 2,
        "address": "0xd7672DC497285cb6B05ED3A60aAaa55e195Df507",
        "signature": "0xd4ff92b6f1f5f077bd85e218c0f824d8c4bac55d66745a9b6cd2a473653ac2e1411ed443fe4ff73d06d1861bb2ffb853d4d9c65e8a0448d4427c1908382af4f41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8268730cBC1D0862cd26a08A86a696e63022B9a7": {
        "max": 1,
        "address": "0x8268730cBC1D0862cd26a08A86a696e63022B9a7",
        "signature": "0xf3764d007b32f70fc1314cf13bcffadbd30f6dda4fc1c0e865a1928b3d8d01360b3bbe713f3330cc626108790f90c06287d9ea88acd216cdceb457a1bdc367b71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x41338D84A3e14d506499aBEb455d749339E3FF5c": {
        "max": 1,
        "address": "0x41338D84A3e14d506499aBEb455d749339E3FF5c",
        "signature": "0xd41a950136c5d11fa550c6150d9b14f665dbe28ec960ba5ba785241e923ae83e2ed0b06c5964f0b2498b7bd3efacdf7220e6c507d72442ea059a01c8c4824f701b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbB67ed923b19fA1624d51F5d2CAAe03F8Ffc885C": {
        "max": 1,
        "address": "0xbB67ed923b19fA1624d51F5d2CAAe03F8Ffc885C",
        "signature": "0xcc7d95d33cfc3a8836baa25bb69b1e61d4fa72e459623116336b0883e20e30791bb59051272be6cb7c8994d8dd1b07f27990befcec29713cfc5e82caee29ba1f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAf23eFF4Ab08F768889F50f4099B10e45609B032": {
        "max": 3,
        "address": "0xAf23eFF4Ab08F768889F50f4099B10e45609B032",
        "signature": "0x9ad2f1bedc525d69bb59fd722e9671803d1f49b5403838f2949ca85eb446d0b1327080bc80744f837404389f52f77026fe2b3fddfa0f9c79228d3f05c9b6bf691b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x032B379d3BE8e347252A991457CAF182657DdA99": {
        "max": 1,
        "address": "0x032B379d3BE8e347252A991457CAF182657DdA99",
        "signature": "0x3231bb55daf09cc3359c0e78fed3354104d13c7d529a5a1303c2603beaea944b27e9820d0f86ab1707d957448dda43abc2ae5bc3714073cd21eca0281f40b2df1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa93CF0e0682BEE6626a2A34e7e1D284a8b8E1E85": {
        "max": 2,
        "address": "0xa93CF0e0682BEE6626a2A34e7e1D284a8b8E1E85",
        "signature": "0xfabfda9e6d5b7871aa3a2776e57bbf55366ed7bb4fe148c811acaafee0790b5e356177cb84e63ef48db203c452be6e3555ae6ca991d6e236b543feb839d793d51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1a41ec571EC8Fc815FEbF81FCBaf9D7F7eC98849": {
        "max": 1,
        "address": "0x1a41ec571EC8Fc815FEbF81FCBaf9D7F7eC98849",
        "signature": "0x17984d91207566a6b8a2c29f52e4dd02d663a2e7a01a9431a26111e1316f9da056260d7846e885b94cec2f92e6065545150e375bd2fb49a53b9773f25e9f67bf1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x04b66B7249097AEB308bc25353d126B21E9CbFD4": {
        "max": 3,
        "address": "0x04b66B7249097AEB308bc25353d126B21E9CbFD4",
        "signature": "0x0f61b52bc3ae10ebf3b619fb4577bea498307551b6fee67659113a9b484337f04f2694f91d32ebb3f2697e6a2b2e76e5fab4d1ecf56712d05d7b757aed8f054d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x00aA85FE54fc51f47039F3045D632dD0Cd8f5660": {
        "max": 3,
        "address": "0x00aA85FE54fc51f47039F3045D632dD0Cd8f5660",
        "signature": "0x0bb529d98df9b18bbbbf62b39aaf9a0200179ce77de084400fbd262ba04fcfea4ee7c2d8124918a59469b53b76e204a3f6f844d219d1754429c6b949d02040991b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2d9924C0eb49D083aA8c652AB639934b1ED7b4d8": {
        "max": 2,
        "address": "0x2d9924C0eb49D083aA8c652AB639934b1ED7b4d8",
        "signature": "0x6b82d784ab7adceab6399d278ad40d800d4aceea8ca48d9355947339a1ccc6f9497a54b22e0a96c48f7d6ff03ffbabab11a9fecd9b16fba936799b15d63cf4b11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc45814A96514efA536Aed86cfb37D0161CefC030": {
        "max": 1,
        "address": "0xc45814A96514efA536Aed86cfb37D0161CefC030",
        "signature": "0x63fe810b5f5a61122e7cb80374fe6c50784d98758098e75b5bee4d238cdd55f45698ab5b48c7c55747fcd86590d70e04b812040a51471a92919161a38dd72c1a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4ffC88958E0a5Ced57E373D32BcA3Da24f3DB8D7": {
        "max": 1,
        "address": "0x4ffC88958E0a5Ced57E373D32BcA3Da24f3DB8D7",
        "signature": "0xde8e372979366d702161b0a9f040abd7bdaa1feebf6654725292f8171782b1c433b12f30b0cd7de2570596780481e018c744cd3bd13224d6cee5b90ae2f521261b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x74eec282f2bF0C9A8c132623dD1F2715C6693A1b": {
        "max": 1,
        "address": "0x74eec282f2bF0C9A8c132623dD1F2715C6693A1b",
        "signature": "0x7a65f493b7c3f2253bd86d527e94d100d53df10f5c99457071d04391dd6db3e234beab6b42377d920e845d5a355e1ed0461a1978f83d8d288a479f163a9e2a741c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeA1126e70185400A2cF0DE2A6b35428Fb8AfFD29": {
        "max": 1,
        "address": "0xeA1126e70185400A2cF0DE2A6b35428Fb8AfFD29",
        "signature": "0xe9b0e8fdcf86f69bf0f0c1276c484ac1900d8595a97b5533c5c7f9b794a577280dfb1dccf70a7a496788083fe76ac47d93127891a0a1aba68f2f61d06b9115f61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03a89814B2BFD5DF55D74aA169Beae659F876324": {
        "max": 1,
        "address": "0x03a89814B2BFD5DF55D74aA169Beae659F876324",
        "signature": "0x532e5514123b70dc93cf2a75d9e876c17cea316846f5de9e73a9477d8a0198ce63230af8d455df92e192ac34ca82a7d8dba63ce9232921b622a5525d8fcb343f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA655870ED0f8cca5535b3E28c6D9AC0abb66f1a2": {
        "max": 1,
        "address": "0xA655870ED0f8cca5535b3E28c6D9AC0abb66f1a2",
        "signature": "0xb1be74eb20fcfd8f15031cf0887ea52d40a11a8a9a2b337e98dbe3b18b7c512b7275b7bac3df5e8802880c8c648b8dd008cf08b61bad308442506a5a5bfebb4e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd15C806dA47EA485e476c52c8feAbb66a155931c": {
        "max": 2,
        "address": "0xd15C806dA47EA485e476c52c8feAbb66a155931c",
        "signature": "0x1df192049ff306cf37754fbe626d600c186624676363835630390e96311a3cbe226509776d5cec81cbe6acfd5443dd0c6eeec1287a80fac4ddee85f6a67dd04f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x523aE77e313a519aEd5c8cc4218E8Bd74790f4d6": {
        "max": 3,
        "address": "0x523aE77e313a519aEd5c8cc4218E8Bd74790f4d6",
        "signature": "0xbbad441977288109d523e5c00bf6fdd6b1d399701d4a4fbf27e707646a478a856442b798e7c3e07f3611762818b61a811aad52b89db17f0c82dada5c9b1425fd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9036BcebE81Bd0900Ed5fE3A1cC87FccaC317C79": {
        "max": 2,
        "address": "0x9036BcebE81Bd0900Ed5fE3A1cC87FccaC317C79",
        "signature": "0x0cbfe3cd2aa13a7927f8d66eb71a5faff1aa3bc894679e71f41545cf79c6c90f3c2c437ff0c5c4a96d8eccbccb1b83a627408152f65c71328843137ceda0330e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x489d20b135c7e7325f22dE88FEaB1b4B0BD61A8F": {
        "max": 1,
        "address": "0x489d20b135c7e7325f22dE88FEaB1b4B0BD61A8F",
        "signature": "0x31405ed519cc6d15acb3a33246f31bb5bdaf05b4fd739e4a4e00d7124ca100ec097d3abf32b3142bf6f1bf94abe0f6ea117c81aa08197338a6f153ba8a8c37351c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x108A340208dF535017F917cD8F712813B59e73CF": {
        "max": 2,
        "address": "0x108A340208dF535017F917cD8F712813B59e73CF",
        "signature": "0x9f368a4ae5c1ff288bdcf367af1675eb900bdccfe3b3935d377d51af0be9c7a45736c6b1ca18b295a679a6860b720a1f65cf2c794b7ec6d7b9e866ceb51327f91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDB5c0e04271Fd1f3CbCC484EC584Fd83Ff956721": {
        "max": 2,
        "address": "0xDB5c0e04271Fd1f3CbCC484EC584Fd83Ff956721",
        "signature": "0x742a38d6f8559f471809a500c06b1347d76c49ea59d6a790e24ce948eed0c2d072ba80fe59873515ae376137b4df6bedbefc7bc7a535ac1a86d6af8c9014332b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2c0415d296DE341B3ee88A561c6224886266CD2D": {
        "max": 3,
        "address": "0x2c0415d296DE341B3ee88A561c6224886266CD2D",
        "signature": "0xef11348a1c983373e4a1a4aa6c7f05b308ac417b7ba86e6a0435848349fadc3b6c01e6574e82c96230b92937e5d7c65995bd9f8928ec33e6228620592fa69f801b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4A37C4E2D808Ff3bb680eD655e888546e5428a26": {
        "max": 1,
        "address": "0x4A37C4E2D808Ff3bb680eD655e888546e5428a26",
        "signature": "0x32643d8511f2fb31ac256e7feb28898c1fc5b72fe1a2dfb6ab4cfe876d6816bb39a791baf98370c75e5f0ab406f21dafc21a15933e6a44bb13194edfc31f82a71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb70B35791B17e5C999313e104fC46C1600eAE0ED": {
        "max": 1,
        "address": "0xb70B35791B17e5C999313e104fC46C1600eAE0ED",
        "signature": "0xd06445e3f80bab934772283aacc85d0f4fd6b711a14486eed2ca1578168c2ff006f5c31d12019e96615887bb06921a13c145166e7c2c3275e1f42a8b2da3ddba1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9eFfb48A52b06B80C8fccEB550Cb81b9Ed9341DF": {
        "max": 2,
        "address": "0x9eFfb48A52b06B80C8fccEB550Cb81b9Ed9341DF",
        "signature": "0xc6874f7b6660ad6097d4e845ed201c230fc9a25226b55cd6623a903ddb34f41922387dfff45ae7838977749c9c371aa61c9e61365f3b49e1595183a1ae761b281c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8c8020774F23Eec650773f5dF539A892e97B17D5": {
        "max": 1,
        "address": "0x8c8020774F23Eec650773f5dF539A892e97B17D5",
        "signature": "0xc0a54e36149758b87c84188e804994e63a20aef0f93de5621667b8868f2ad2195ac7c31aafaeaae6af2baad50343e8b3d88cb59dace61485a723e8ae64f4efa61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x85735eb7c13fFD9401Da899A0a993802eEc78B2c": {
        "max": 1,
        "address": "0x85735eb7c13fFD9401Da899A0a993802eEc78B2c",
        "signature": "0xb9056867dcce3773e4571d8ff55b15046f7b4a191637e8a046966b2c01d0de645bb787f0f8046e135ac7b1a372cbbbed3bbbf72f2c9a86cb10561ad8bfccdb341b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1740d7a8a797C77316E8eEC30b71352065074aF1": {
        "max": 1,
        "address": "0x1740d7a8a797C77316E8eEC30b71352065074aF1",
        "signature": "0xeaa2f6a9cb6d630b68edc839e0b65ab8d931d43bf4b1c8543baba81dcd9636f060ed481ed5696f1b1585667d105c7d3dded614780e9ae8daff14bdc7ffcbddf11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x19668a65e6C8A02369428B8AF2e0783EE4552CAF": {
        "max": 2,
        "address": "0x19668a65e6C8A02369428B8AF2e0783EE4552CAF",
        "signature": "0xab304b159d1d82b93a932563c8531b8cfd87b59b59341ec130a3e247ef095cca579adcc86a9e10680a91afb59dfc7a123d1c357786ba7a72555f7677c549b39f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x61EA54E7a31B5C1BC163ea97BC67436cb02606ad": {
        "max": 2,
        "address": "0x61EA54E7a31B5C1BC163ea97BC67436cb02606ad",
        "signature": "0x30c7d4b02af827a6741e2792b3c5322ba4f31875f9ea7643f590b629dc54fb4b5de330079b636837d6d4e90f082708e752fbcccaa7d727c0e6e841c5db4015bc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcac105C1A605A1Ad24Bcdd5Af175651d08f701bd": {
        "max": 2,
        "address": "0xcac105C1A605A1Ad24Bcdd5Af175651d08f701bd",
        "signature": "0x4ebe2ab27e37a4ad4f7e430444b6ea60bfb23c24f1a5a4e8b0edff50e041d2b343339f444b681cfd07aa1f22d3db5b7a0709b7f427d5540aac4a86c13a2adab41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeFB764EbE58aCb1b8Ce831bF47a1886D2DF42942": {
        "max": 1,
        "address": "0xeFB764EbE58aCb1b8Ce831bF47a1886D2DF42942",
        "signature": "0x4db37674c978c279f7e7c8528ac0243c5dd69dee46ffb9cdcea96b4cef3756497f111b39b51192e663f3a009b3d75266599de4925ab0d48ef2210a3620e2412c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd528e916242E1c8E3Ca28319622932D56a719B07": {
        "max": 2,
        "address": "0xd528e916242E1c8E3Ca28319622932D56a719B07",
        "signature": "0x509bce012e886f01378d6021af51f363c6d5a0efbd08f88361415b9daaa0e0c67a085b3156aaf82e878d4fed47c5d5d55fa0821beb02cf0166ad110cb67f74061b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA7BDd9807A60Ca7c4F4A7428aF27047942039b22": {
        "max": 2,
        "address": "0xA7BDd9807A60Ca7c4F4A7428aF27047942039b22",
        "signature": "0x1a64ebbdf1c9f9ce1399f127ec38e7170e30f3b004d0b3d31a69124f98e6b86c2d77291e4caaa3f2b5b666fcf66bc9e14e3e6adfc4c605ad573fb366a810e5bf1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0667640Ab57CB909B343157d718651eA49141A75": {
        "max": 1,
        "address": "0x0667640Ab57CB909B343157d718651eA49141A75",
        "signature": "0xeba3ae6ca2ee027a424679ce4eaf8787981ccbe3a0193a9aebaa11c7663bfb835ef03c92dd1fe45472fa975ea16c93a5286dc4d02afa15c07632b71501fe53531b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa14e18092bEAa7196E794c339B81a54c8042FC1f": {
        "max": 1,
        "address": "0xa14e18092bEAa7196E794c339B81a54c8042FC1f",
        "signature": "0x91e1b2abe80475939c8f91c010c41d89ffeb266f8584e0303ce96ffe199d680507f2fe15ee6fcc85a7dd8b72544a8233bbd79bb6b64679df1eb4c96e8bb8dd371b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5dcc5C0463D27C117601c7617bBae883A55E6079": {
        "max": 1,
        "address": "0x5dcc5C0463D27C117601c7617bBae883A55E6079",
        "signature": "0xd341f079f128fd49b9fb7aae42c7f03f0fae195446fa22cc87b7256a94d2cdb67af8f5b0922cff17c06fe00ed3763f229333cdbd385cbc41f5a4def9b3a6583d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc004AdE9865Dfc4F15F0c1eF18235b1D1ec05A48": {
        "max": 3,
        "address": "0xc004AdE9865Dfc4F15F0c1eF18235b1D1ec05A48",
        "signature": "0x7554a87478e9820b20f7a0adfbd40ee4d696fae1f484ada6cc7e26099a06d8b05114839970356020998d11520dde589ad3f8821ab2db139f903a20961ede143d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xacF1D9F05213dC0682f611d62c4De9798A7fC686": {
        "max": 1,
        "address": "0xacF1D9F05213dC0682f611d62c4De9798A7fC686",
        "signature": "0x4ad8c5c5ce294100f9fe34df8351d9a43d7e41e3289252784dbd8943c1abdb661b563448b847044e7c7ea919e393a1c38d5430e2cf312685d4fc7356bfd71ce91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfcf2a04A3fC9A939c7E911d780Bb3D0B212c6be1": {
        "max": 3,
        "address": "0xfcf2a04A3fC9A939c7E911d780Bb3D0B212c6be1",
        "signature": "0x6336919d1f8b07afb38d8e4cb2163417d3604c9aa60975a4b982069bc88708087f2584c2bb1bba95645dd2c737d3f9cfbb32bc239c685e7db74fb397ab05f7021c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3BeD89b5C0b6c31011a47a578EE3Df6bD3a6f745": {
        "max": 2,
        "address": "0x3BeD89b5C0b6c31011a47a578EE3Df6bD3a6f745",
        "signature": "0xeeb5711e60b6aa5a0908bb8ed6309e7ddb1913d3acd1e3574065345bd76e47a175b31160e7e0588d68248111139f54200d6eddf0b47eca85f0102906672f4bb11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5022cF478c7124d508Ab4A304946Cb4Fa3d9C39e": {
        "max": 2,
        "address": "0x5022cF478c7124d508Ab4A304946Cb4Fa3d9C39e",
        "signature": "0x64e97a47a8847f5ff143afb23adfc0ceab11464a6e34b7aea9227900e8fe0e91780962cc36544040d8bcd1c0138f3f15605e6b05c4f289430725652aef9c60391b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x76f4e8C81bf2bbaEB4b6121db1A8873C036929db": {
        "max": 1,
        "address": "0x76f4e8C81bf2bbaEB4b6121db1A8873C036929db",
        "signature": "0x766ca5544105f81d0730021c968904e79b0749ceb459fff8d827d7edd3a875f433ffb4caafc18f933a91844d50d4a34cc8747803aa48d1fb920463642528adcf1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB56215eE11B4076B7eAe31599256375677002C53": {
        "max": 3,
        "address": "0xB56215eE11B4076B7eAe31599256375677002C53",
        "signature": "0xb612fa317452c4f4b3bc2175ea755b5e57067dedce86b7c6a5f1f586c87df821386831e6bc150c3f489a992508ea8e84f71531a3ad167601a5bd370d12a7cac61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3e716A0E4dC07999Bf688d43c05dD85CB445168d": {
        "max": 1,
        "address": "0x3e716A0E4dC07999Bf688d43c05dD85CB445168d",
        "signature": "0x82a189bb03e032d226dc1425af3c7fe70e0fd801d5855393bd460c29006109782b98713a510acbe510d070e6a158d651896fd6eb34671d116934d9a3a0c8d8f31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9D53263D30d904dE3a7Bb990183e803f69772eF9": {
        "max": 3,
        "address": "0x9D53263D30d904dE3a7Bb990183e803f69772eF9",
        "signature": "0x937f73a3278c4659831cc6234775bd840362414bcdb700f0b27415379a569b7b2f283b7cefe2ad40b6e4e68cb82a432d355a9d10e12b54610b0a8f6c667b30cc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x070E33dd7374214E13DaAdfc3cA527C8478A6161": {
        "max": 3,
        "address": "0x070E33dd7374214E13DaAdfc3cA527C8478A6161",
        "signature": "0xe8d9290f2c8d8ab00666924453dc8e66d03939ee8905c5c0bc9847aa50cd56e65dc621d7a0d1dc9443f184cef1cdb7feee018105c96d931504c9294a25685fff1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc30923B6999db5ed7991edf9c48e34B74a98D87b": {
        "max": 1,
        "address": "0xc30923B6999db5ed7991edf9c48e34B74a98D87b",
        "signature": "0x29260e259bc780ec39c6bb6d4dc575ddb341904d2b0f7d9af1902df87a609a8742048fd116fc567ec9795fa054ed28228cfd39b585fd0fa0e74a85add5485f621c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x85b793fb9A45F8c3fD9487ed22a298dB7f44beE2": {
        "max": 2,
        "address": "0x85b793fb9A45F8c3fD9487ed22a298dB7f44beE2",
        "signature": "0xd0d7591b4d071f16929f906d209cdc7d8a9745a02f100c82b4fd77b897e55cbe03768b8c295c4a7ab8b2ef588b35812342ad8b9ec26ed57fe7bc3fae09ed90651b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x77f82685c4901a1C8A59013E9d37699C097c97f1": {
        "max": 3,
        "address": "0x77f82685c4901a1C8A59013E9d37699C097c97f1",
        "signature": "0xc159ec62d444e4ea96813ab5520feee7d4e0536f2ef89c7d84bc0866f029ac9b349dd1ae45e1d91764623f69ddec0eff396dab313eac3f6e105384ce3d1263381b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2d6aEFFa8703909224673AF51cD3A8b2DFd1A9a7": {
        "max": 1,
        "address": "0x2d6aEFFa8703909224673AF51cD3A8b2DFd1A9a7",
        "signature": "0x8353607c97ebca08ed69e3f7b1c96b7633ed24721e8dfb28f269cac55282c0a8021571def4cc5d4c3176e81cf410e1780e0371490065ef8767841d22725c8b091b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeb45879635364C83caF07d6F0Dc2E39411D55328": {
        "max": 2,
        "address": "0xeb45879635364C83caF07d6F0Dc2E39411D55328",
        "signature": "0xcc9ad6ba077ce9a17063b2db0862b70a9fa91eea138c35bb1b0d2b91da2a75cc5bcf156b8e1f4fa77943fb1f02c26e6f6e25b19dd8f4427ed753cf4f9fc107fc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe6bb26d4038f320fd3Fb7874D61C170251d91d0b": {
        "max": 1,
        "address": "0xe6bb26d4038f320fd3Fb7874D61C170251d91d0b",
        "signature": "0x905926deb6619859f8ea25856551f1607c7fe57d692cf743f7fb0007fb0c8eae2688636c4c26ea4f3cdc340969d046c6d9d8c96b0b8ed4a5c405a178bf08cabd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x35E3a5f9A59A441f5b87416eD68eb99753e59171": {
        "max": 1,
        "address": "0x35E3a5f9A59A441f5b87416eD68eb99753e59171",
        "signature": "0x2ce604947e8a18d622f80b55ccecf5dbff46d609890b003b101eeda674cba07b6b25880f5d3b1f052233d4c71c246fc73e715376b9dbb463bfafea36926af6701c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1EE03A59F4C44ABc44892839486Aaa91C12dd1aD": {
        "max": 2,
        "address": "0x1EE03A59F4C44ABc44892839486Aaa91C12dd1aD",
        "signature": "0x84aea418ad4515e0f49219a6ee589ea9a62b9a3f9d7e765ba9bd184560598dcd2b11c0a01ae211e06a55e12c58545c629c270d442d02f7f62c5d92731810854a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5c344BA4ED12b0Eb0632199D4cBE91DeA12f7e4E": {
        "max": 1,
        "address": "0x5c344BA4ED12b0Eb0632199D4cBE91DeA12f7e4E",
        "signature": "0xd0138dba3ffcdabb25103762ba880ddb36d51aaad16cabfff6c030dc3d9bbbd74c2472975b4c6d14d1dd2b7fa3b3d5d63e3be832654cc0bfe9cf5e9bfa1ac5f81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF80af3919e09f7ea98B2101193bd5890d88d2dC3": {
        "max": 1,
        "address": "0xF80af3919e09f7ea98B2101193bd5890d88d2dC3",
        "signature": "0xda6eefab70e0c64eaa5b734de910cf021788a5acc668a41a2e5e41ff137caa0364087a9b092ee7b299c554f81d58d41ccc804f611df80839dbab2c9636fc8b321b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x56487dE67A5bE8fD5a6Ae906c04A22Df4b98a2d3": {
        "max": 1,
        "address": "0x56487dE67A5bE8fD5a6Ae906c04A22Df4b98a2d3",
        "signature": "0x23a84feb681ca1406045020acaebd613ed9452fb2c4623d6c4c8835be7eeafc2256b893e5c5d7fb889cc27faecfa6646c22e0c578093ac9ffdf96e78cefea8e11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6F36746E04649902eD7F6BC4CEB7088ae7C6810A": {
        "max": 1,
        "address": "0x6F36746E04649902eD7F6BC4CEB7088ae7C6810A",
        "signature": "0xec08502278574d023a9d6302ab47b41cdc45680beb2c74783538ca969e16c78c53f0a0b23765fd22f87978d9facbf0bf68be22a5d50893f7e3de9b1dbb67788f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0C9fe37e13E792A8feB5195Da1Ff9e8a694AE043": {
        "max": 2,
        "address": "0x0C9fe37e13E792A8feB5195Da1Ff9e8a694AE043",
        "signature": "0x7323960bcb61ddf910f46f9e3c96c2720c127aae95c3eaf42ba200dec024fd6a06609dba86591f47a6236527d26ca5eb5788f99ad8658af470950fccb6a334f61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2A50862A9618369eBe85856c12Fd3a90109Fc0F1": {
        "max": 1,
        "address": "0x2A50862A9618369eBe85856c12Fd3a90109Fc0F1",
        "signature": "0x6fec078d69ff3ca23c4705bd987e867835ed16e2f48b92e90d432f4f2a40227368b9f7ce7a511558b04ae5bbd42acf1275c585d1dc02466128c9a7b1ee7591831c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x462E6A076cEd2716B481871d5373B5025b22FeDE": {
        "max": 1,
        "address": "0x462E6A076cEd2716B481871d5373B5025b22FeDE",
        "signature": "0xba811e3187fd7345a8be541355e9522f2c38a62cdb726d88cdec493ac83d0c7331c40bf3b92ea3f2cf05276402cb480902214e2380b167cf825fb4ed511e26201b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8d6E3408C7f6599DE23178D8C8e163b46dF6F6bF": {
        "max": 1,
        "address": "0x8d6E3408C7f6599DE23178D8C8e163b46dF6F6bF",
        "signature": "0xf0c61a919c830910a418c53533bb08f46e8e90f0961441d17411dd87181a6141410c3f1bf7a3e0991ba089732f4b0e040f9106574998bf22a349924ca27cdaa11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb76e940Bf40620F410c15b3cB777B72e34Ad25c5": {
        "max": 1,
        "address": "0xb76e940Bf40620F410c15b3cB777B72e34Ad25c5",
        "signature": "0x51ae07cefa27123de2161a368a21c059e7a5a38c0a98c7955811e11351ed4ca21480dfa6e9a1679c245007658b4ae511450e8e23c09bad908e79f8896056ddec1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFdFFFce31a1aC3e124CA26C7545b996b66486F94": {
        "max": 1,
        "address": "0xFdFFFce31a1aC3e124CA26C7545b996b66486F94",
        "signature": "0x926f49c0c1e8ec5a9165ac370ac266a6f1390f300dfde909d83b623986f1249a5ebebc1cda8d5f9fb200437f73fa2d571f1432c2cfcde6a7ef28f02bef10fe9a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xda5c8cfF0174738E62fE05f0f4ef5F91126E1530": {
        "max": 3,
        "address": "0xda5c8cfF0174738E62fE05f0f4ef5F91126E1530",
        "signature": "0x8be5d0825db9241a4cec9d8b65731681c93c3d6800df946513899bedceba42661a4cdad8cf230773d9302277e232a0340f8c392fdebc9e75cab24969cbfe17ec1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfCA0C85790216AC1c7aB76a773267054Ba111624": {
        "max": 2,
        "address": "0xfCA0C85790216AC1c7aB76a773267054Ba111624",
        "signature": "0x1c3c342664c8f021132ac404e0d0535fb5065d6075b6dfa3a56684ca38437ccf445ca7a8817947c2a5ec2765d593a4aa9be00a847a1a775def4aea7bc3bb2b7e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x94B4D1a7cF4D46e5F52C7f5B6E7e63926fBE6d73": {
        "max": 2,
        "address": "0x94B4D1a7cF4D46e5F52C7f5B6E7e63926fBE6d73",
        "signature": "0xe09cc04b59a1c2beb431edf9100ff33339728858c8769984860247ee02948c4869677a5807b9f6092536e14e9ba52cc54eddcf44f3e9bb4fe060bc40515379471c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBaB74606440C4240D1dF81D8584Bcf1d2710ddB6": {
        "max": 3,
        "address": "0xBaB74606440C4240D1dF81D8584Bcf1d2710ddB6",
        "signature": "0x9a800d844b5f29b1aac387cca0704554dd99b29c76cec988d8aa59f2de0404e9083c587c9aae39d6b2c321db2097e36da4e360f804c327d457ca9c93e10a091a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x10097434c44E56314DaA896826caD75404a00C87": {
        "max": 1,
        "address": "0x10097434c44E56314DaA896826caD75404a00C87",
        "signature": "0x88d0ab7add4a3b30c400f43f3a366824b87a0c9c312b3333425a74e0fe8d85b03b28bf6921be5d31b87085192217cb6988bf7b3cd8fdb21cda9544d95789353b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x36265A8dF985845d633eDCeE6652419cd31C1f32": {
        "max": 3,
        "address": "0x36265A8dF985845d633eDCeE6652419cd31C1f32",
        "signature": "0x791249c47b1cf51d61c5282c9f64dc8a9940287324322e0405681f97dfb5161f583f04226b9d722e19145dab633247cdd0ed813d3c6bc2ea227bd87e4e17f7491b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA92e2A17834010b75902e7e23f4d12284eca1496": {
        "max": 1,
        "address": "0xA92e2A17834010b75902e7e23f4d12284eca1496",
        "signature": "0x72afec3b040488631f07b3d2860efdddf37d2c100dc5eeb4608bd680241c0120623a034da8693a83a8968eba871c183b6c1bbc99de813b525e3b5541070503b81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x60A4d8E6E7a850B3b23CA1443Ce38F2ddc26c74b": {
        "max": 1,
        "address": "0x60A4d8E6E7a850B3b23CA1443Ce38F2ddc26c74b",
        "signature": "0x8fd41ada1ad26586a3f50b2bc2536b782edf78c73793133cc43b3b1ae504ac6f52e05f34ece79b62ff9d8b721b36d78bc73d80da92430f85b1aaaab867991d241c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbcB816A10b83c87D1d5b362f3d52cA4d0830C637": {
        "max": 1,
        "address": "0xbcB816A10b83c87D1d5b362f3d52cA4d0830C637",
        "signature": "0xba613bf9f1622974471cfb1b6309b8049acf6f691d957b0c8fae4cc37d7c15cb2f3785cc8e28a40f25202517b91933918fa860e5916439c4da251c443c101cd21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD1d8d3cDf606Ef80CC61688CCAc4611815DDDf15": {
        "max": 2,
        "address": "0xD1d8d3cDf606Ef80CC61688CCAc4611815DDDf15",
        "signature": "0x7146937d59f768f506f4130b477af6f0f2fc2dee5a0e517462531c54f234b73e151d78e07bda63ce27caf0158b99aff49dabc9be210c1434f6452cf4a78296831b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8C017Eb61f340F8c1cAbfFDb69d26953594EA370": {
        "max": 1,
        "address": "0x8C017Eb61f340F8c1cAbfFDb69d26953594EA370",
        "signature": "0x02cd83d31bd71c96f54e902163e2cd41f99e3ea7bb3a9036180158e1fac8d3d4022305a56dab891c1852685433a1644ffebf6c7293e234d3fa740cd7b6200b5b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBB82b4D89961FFaCf36DcB687445C7D25af2ae39": {
        "max": 1,
        "address": "0xBB82b4D89961FFaCf36DcB687445C7D25af2ae39",
        "signature": "0x71531902e5065a21e8e4e22366417894a381b37a7fc0395a876f721ced320e686b9eb9dd7c66db59d8e7fec5db86f969314049fa288082a4d62e814f501c0ade1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa523cBF4e899B476938629c124603eFD6F6d3BC4": {
        "max": 1,
        "address": "0xa523cBF4e899B476938629c124603eFD6F6d3BC4",
        "signature": "0x038777a89950131b8ca70baad1c049223229238a0f82ab1547c65144cbaf4afd4d86407c773f95f694e6105cb452bd4dbe786ca2f213fe7e3842b2538b4a66871c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDaBDc4aBdB061B7257E2DCAEB27E6637310A018a": {
        "max": 3,
        "address": "0xDaBDc4aBdB061B7257E2DCAEB27E6637310A018a",
        "signature": "0x9b4dd61b0a0eeed6bab319452003814b63ffcd65250d0b00e144a00cc9022c683341f2cd79812aa5564c1c95140fd7064797a8f6ed84a98c587c83998b88f0021b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9875805F43ab65C77eBCF91F8d4c53042B4e1999": {
        "max": 1,
        "address": "0x9875805F43ab65C77eBCF91F8d4c53042B4e1999",
        "signature": "0xfeef722a95f549961503d03635ebb7cfbb67bbf5d6c919c3abf8dc8818f8f97d224ba75872c4aca83d10d95623a876989213af98eed2baacdac451a84dfb8d271b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x23C45C4ab53D8B4eDAd1A77e200ff12D5DdF13F4": {
        "max": 2,
        "address": "0x23C45C4ab53D8B4eDAd1A77e200ff12D5DdF13F4",
        "signature": "0x2378773887c4ae99ed0dca91f35c0f8df0baca2f8c00a48b31f256346e70dced14e95281f9e5ab302bedc9546cee7f63212c8a7cc14b54ffff32b19f1cfc719d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5699A61411ff07Bd75DD28DA0Ce3C8d2eBD4E6c7": {
        "max": 1,
        "address": "0x5699A61411ff07Bd75DD28DA0Ce3C8d2eBD4E6c7",
        "signature": "0x8579918a8964b6cede5effb361ed3d59204f828f1e45190dab3d0d49e71cc10268c891f3ebf4cf31f99558c15523fa94d1968437f0e037962be436ac3cdb4d781b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x17B4579A8E4E3eF61A3437931f6550F12B032CB5": {
        "max": 2,
        "address": "0x17B4579A8E4E3eF61A3437931f6550F12B032CB5",
        "signature": "0x42ac094197c70c5f3b2b551bae6ba440d52233dee8e5b3612cdec253af0d1c824459e2001dd1155798870132cc784253bda922bfe27daf64377a0709f1b252371b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc2218C1F3AFd8D0a92025a6243993b6024DB46eB": {
        "max": 2,
        "address": "0xc2218C1F3AFd8D0a92025a6243993b6024DB46eB",
        "signature": "0xd43fb6dce485147c5009f68d67618e6f8c90468ef855acf12c01df175a78d98516a1768b8efa18c9f0e4577479f8fe97b322a16c7075f6657ae7f2ce517a70a91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6b016B9d8E773e50C2f27B9559Ef8aCa5f31DaC5": {
        "max": 3,
        "address": "0x6b016B9d8E773e50C2f27B9559Ef8aCa5f31DaC5",
        "signature": "0x5edb32024f5df4dfff1cccc2551dbb53c023fbff6e4496f9e1ce4ab2805dcf490ff72a7ba4a9514eaed63d922d4bd841af316d1ddb3142dfbe0122f8f8db23681c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBA087a8f9c2e3c9E2693a4710c502983350acCCa": {
        "max": 3,
        "address": "0xBA087a8f9c2e3c9E2693a4710c502983350acCCa",
        "signature": "0x348af87d67e6cf3708c32f56512ccdd3c1f59d36e7e4065520e865b69c61c1b72dbf4c42f399c716369e9616d07ca8b1fd9741bfd3ca88777079d9d707fdf9e01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4C3C19B113062Cbd6b6611703C82843C8ba42159": {
        "max": 1,
        "address": "0x4C3C19B113062Cbd6b6611703C82843C8ba42159",
        "signature": "0x967015f2c5fab95a499c790c8531eb95c8d756f532e8e2f151a951db60920585324b6fdc6b105f6062dbb0cd5eeb85b088ec2966b2e1aa937b69a47c332eaeae1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb66F94B7Fe8d24c7e904fC1956c0F4210b474487": {
        "max": 2,
        "address": "0xb66F94B7Fe8d24c7e904fC1956c0F4210b474487",
        "signature": "0x2d1ec15bc83266c1fac7449df2fa632adfbaf0c34ca4adc4297623f825a4b08f6fca9f986e416780d1379a80bd7f79916e700af9b6f1dfedcec96767c99f760d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEc8E08dA68e0d9f1769a45896c4fCB2bBf5B8756": {
        "max": 1,
        "address": "0xEc8E08dA68e0d9f1769a45896c4fCB2bBf5B8756",
        "signature": "0x1c65bec322abebe2599c033c814a3fb1efbcc5c29b6c6ec0ec48e362a0103cc42b8980cfea48015a9bfd1372719cd46f3981d799814da5816cfdab24b0d3ae8d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8019397478e456011566C34741B31292dCd4F26c": {
        "max": 1,
        "address": "0x8019397478e456011566C34741B31292dCd4F26c",
        "signature": "0x82175637e5d1c615849ba88ba7ca1616b5bf7519787bab29a74722ed9957d943145c21d00c1ca1711731ec776cde7104fac81acc642485a856fd53ed8cfeb1311c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc15E8Df9ECcd93a81CF216573e3cF1B2B30151a1": {
        "max": 2,
        "address": "0xc15E8Df9ECcd93a81CF216573e3cF1B2B30151a1",
        "signature": "0x4699dda4ae900af2cecb675ac5e7baa46868d5ab5f451c0c49f12480f70cebe34badd546ebdcbc973aa9a3229b1123c4e5d2596308909f74be5a3c61a30506511c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc445f390FDD32ea10E5da308584d41d53C4107bA": {
        "max": 1,
        "address": "0xc445f390FDD32ea10E5da308584d41d53C4107bA",
        "signature": "0x25ae92b59a114639548a4833be2e656b81f4b851a54dcc50f31f02d9ded25ace78d22eceff7dd22a9d2b3abca8656b2dbbc7f99e74a7494883152c05c56df15a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB187cB2AB6afc2329577B93FBBef95cEe3681FA2": {
        "max": 3,
        "address": "0xB187cB2AB6afc2329577B93FBBef95cEe3681FA2",
        "signature": "0x97ee20fb096517d543857253fa180b7715856fe2ab07eabe70b940a411a4c0676a30807b4b6bdab16b4043e0392d5887832ca9db92df953ea07f330e17652cfb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x727d9c220a77c7bF6C0bfC3a5DCbb3bd66D8249c": {
        "max": 2,
        "address": "0x727d9c220a77c7bF6C0bfC3a5DCbb3bd66D8249c",
        "signature": "0x7c2458b30103514137720fc41af4a8f77fb9880bfdb5e32c5110959614d2050a3f17082f8dd2d1d6a446180f85f6f05b0ba58eb802fa733641592613f5f48ee31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0AA1BE4a5DEF69bA678E2083444b94a0737F74EC": {
        "max": 1,
        "address": "0x0AA1BE4a5DEF69bA678E2083444b94a0737F74EC",
        "signature": "0xd28678043fa9a931ee04df13098b98def9285f6fc94e165bd534cc4863ee47485f3a7ac8322b689041838abe2a9cb91bec203d0196b490733a96ef64364d4ce31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8d1DB0c2fcE84a914c7d9Bc291980D79d0D8e8C3": {
        "max": 3,
        "address": "0x8d1DB0c2fcE84a914c7d9Bc291980D79d0D8e8C3",
        "signature": "0x9765bf6736d5f0fba8a88f14efffaf680cd72c17c27ec20547c532705c87834c143838767e594cd3d237ed97d61eaae23d01ef098efe8e5502911f6501e9e22d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x29A68b7Fe85c4F4c1C4c12Cd7a40162aa3368D5F": {
        "max": 1,
        "address": "0x29A68b7Fe85c4F4c1C4c12Cd7a40162aa3368D5F",
        "signature": "0xaa4a73510dfdacb5af62410b414a35b030013570334e65dfabf3301c55a49e70060f14145196bb59e33d928dbcd2f99df6a35ceab5192eee4ee890f17be57aeb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x544cF6A16a06938EF30DD17c0718a0412bdb00aA": {
        "max": 2,
        "address": "0x544cF6A16a06938EF30DD17c0718a0412bdb00aA",
        "signature": "0x497c545cdd9b2a64505f200e03ba9c51daa105714b2e81bd86cfd0fa0fac51a4706f883afae08bc08b43df4721f20d8ae1ed72ba5c1b7bff176c063b29811cf21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF43402754dD3E2F6a3ac792dbD53fe3Ff4dA189f": {
        "max": 2,
        "address": "0xF43402754dD3E2F6a3ac792dbD53fe3Ff4dA189f",
        "signature": "0x69468d1b4bb7dea1537cf7ca95d94b89739e8097c82a49b225a69931ae4d81856d593ebccbea61463289ef5c9e7cac7fe0dccb705529c01a3b5ea6de9690abcc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5c969718c73eaCce045055Afa70C0E37bE02fB3D": {
        "max": 1,
        "address": "0x5c969718c73eaCce045055Afa70C0E37bE02fB3D",
        "signature": "0xd4b845e1d8ca7132200ba1fee1219f9744c26f8cdbdd6c364339a24039f38f64676df124746d4eaf9f79bc66b3b8c03c75ec318ffa6025cd66811d78d6137d1a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x34fCCdC5E781B724B9640D7AB5C742A00E06bAA5": {
        "max": 2,
        "address": "0x34fCCdC5E781B724B9640D7AB5C742A00E06bAA5",
        "signature": "0x3141bf7a48fd39eb7752f89ce33b63955ad206d2e8acdd04ffb3ee8623da7c374948b05a60ab01e61bc119c0295f8e93e6f969eaef897739dc17ede95d9b55e31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6aee82fb31CFcEDF695b0cB02f0611144fA40502": {
        "max": 2,
        "address": "0x6aee82fb31CFcEDF695b0cB02f0611144fA40502",
        "signature": "0xd05c60e414ea4691af27dc087c110bea5fa4273dfae289b854342b5cd1bd0b7358c1a5bd993529fa3df2f04f83b1cc5c09e322103dbddf790b6a6acf40a663721c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2D0bED75Dc6B712C51493eABd00DA387552a9026": {
        "max": 1,
        "address": "0x2D0bED75Dc6B712C51493eABd00DA387552a9026",
        "signature": "0xe365f66dd5c88b78cd46dd0c28f6c275304be283db91ad07adefa28320447896023d169229de8cc6a2ac103ac96280154d15af5203af4a80dde7fde4c9ceb10b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0a6362541867a7f342F6576D5Df3FC291866C19F": {
        "max": 1,
        "address": "0x0a6362541867a7f342F6576D5Df3FC291866C19F",
        "signature": "0xe1669740f6265e5dde9259e4500ae782bd9714f6d565aec9d55ddd83603bbe1e7fa09856a5d47bb1518384d5e80794643ff4ddedcf6651916cb67124d523111c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD869B8241C95EaE1C43fa26FF34F2b495afd9486": {
        "max": 3,
        "address": "0xD869B8241C95EaE1C43fa26FF34F2b495afd9486",
        "signature": "0xdaae8c581b0d39884313b647bd34908b86a1db9a811eaf60a97a775e29f0464f041db476ec6984f232d6a54102a0d1c28b2efd51c311e9b0972c53ba0d37929d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf186f88143604df324348e41d2cCBA2be0939eB3": {
        "max": 1,
        "address": "0xf186f88143604df324348e41d2cCBA2be0939eB3",
        "signature": "0x36a9a024cf60b583ebede36cdbc814295d0f1ceec3cd5c770eb30df7b1674d932c9b93b2f64e4abb2b102ddb103770a18daab399a251ef2854d9ba600722ecb91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbDd5D94E6463cF1683083A60774B38E7f3BC42B2": {
        "max": 1,
        "address": "0xbDd5D94E6463cF1683083A60774B38E7f3BC42B2",
        "signature": "0xa22fdbe71897f0b58f0139b7698ae0f17a34271ba21fbd0e535bf09be1f8bcad16e9caff4a2f59d7872b65cbbbaf2f6ee745e9a7c515a180032cbd4af5783be91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7e0e555BE335E5e50aFB58d31Dd406d6E977fb57": {
        "max": 1,
        "address": "0x7e0e555BE335E5e50aFB58d31Dd406d6E977fb57",
        "signature": "0x009e042d97c0a844da407c67ab7acd7588c7ebcfcfdc1e57cfdfb05bb85d36673184bba63e37e530467fba4b816db4363cb66c692dfad2db8eee299bc3ecc5fa1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x70286f06f48e3a0299Bf71df0b4cD9b077fb4276": {
        "max": 3,
        "address": "0x70286f06f48e3a0299Bf71df0b4cD9b077fb4276",
        "signature": "0xfe6ab6c37b86eaf65e1494f3e545f2bae4e42f31e24f10a32f82cfa3847f6feb602d7b7bf99f3808914e928bb7cffa80c34c83249f8059c9a0538fa0fd488b681c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x657B1e1bd617d28d77B77B2fd78b6240D3e077cB": {
        "max": 3,
        "address": "0x657B1e1bd617d28d77B77B2fd78b6240D3e077cB",
        "signature": "0xc30026de53b65647211e4fa6dfe28f5bcd46c1453233282ead552ed6a89ddb6c2a9bf5b7a8a0377466b5918734e986a5f870b046d47750010e90340fe9378f1a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE110805D72946B533996bCE9e39ebff54Aa242c7": {
        "max": 3,
        "address": "0xE110805D72946B533996bCE9e39ebff54Aa242c7",
        "signature": "0x8c448b27611a2f96ef9d0e76106a9dfff572b0f5fe6dcdfb0342dea636775c4917fba030f63481f643c2017d347fed5c5b3b5b3b0062200895d5bde4379df7351b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDd85dC3780F70B2B3e577ce343C3b84E8a36D1Ca": {
        "max": 2,
        "address": "0xDd85dC3780F70B2B3e577ce343C3b84E8a36D1Ca",
        "signature": "0x342b1ac082d5cd986c8519fa1b18c8aff0b81808cf3c8fe507bdadc3ac2e07d77f7f27fd662dd48ace2aa1d5d91deb1baf9c32ba06727addf106be6ccc58a85c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5C0b5fc1375d36EFCbc0fE844E6ab40a7D690BaD": {
        "max": 1,
        "address": "0x5C0b5fc1375d36EFCbc0fE844E6ab40a7D690BaD",
        "signature": "0xb491e2a90022d3b9b9e08c06889755af32b9b6d442e0c1e1ffe20c64d43576be6aa689b24c24dde4b2b2db52bd85bccbea5d2ad07e17f733b00cf0331bdbec6a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA9E1B0b83bf53d7D5601F6d8d9fbB52146eFb59d": {
        "max": 3,
        "address": "0xA9E1B0b83bf53d7D5601F6d8d9fbB52146eFb59d",
        "signature": "0xf33ad829f744435969931350f7b5695413dc5aa8e579d7ca5629494174865b027c05b9a187f3c76f6914cb7b1208132f4a0223cc81c0d2e5006726f72d0ef65c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9399816dBE0588975ccdc6fd4c63b6C09CFec88c": {
        "max": 1,
        "address": "0x9399816dBE0588975ccdc6fd4c63b6C09CFec88c",
        "signature": "0x2eb7ce2f89a665446a8931ff6545b2738db687e7e1c550bb151b849b95465425500b43f39ca0134fdf7069f414dd79bbab118153a6b6ee21caf1c70c05362cfc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe3d270EeCE1356977147B5e3a41b0F3adbC1Fd21": {
        "max": 2,
        "address": "0xe3d270EeCE1356977147B5e3a41b0F3adbC1Fd21",
        "signature": "0x484e6f4fc989782699b04cd6e9d877d095897545c6a9262988ef7828489e091c593ce5e663e992b5a4af1227380adaafe3118c6b813589c607e3374253bc0a1e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB4d7c86f7B74f85Abf32E72Ebfa40D3B0082Bc68": {
        "max": 2,
        "address": "0xB4d7c86f7B74f85Abf32E72Ebfa40D3B0082Bc68",
        "signature": "0x23c038ae502cb23f64cfce4b9525827d02655765efeacda61c95fab6ba16242e65203ed6e86700709f0b1130d200ddb91e99cf9a888b8b2b4e56964c2daa98aa1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x550176C0Abb1A22C257A43307D5138b98aA738f7": {
        "max": 2,
        "address": "0x550176C0Abb1A22C257A43307D5138b98aA738f7",
        "signature": "0x584ef242a975da733a866f1c0266136b0a6906dc70e4a109e8778ee6e0e69a726f58a9c11016209f1da8f0d97a3d5d315c92ab0b5cfd522982fb0816936064dd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9C202Cf5fF2dB0ed1A1f520CaF1C18244FA4988a": {
        "max": 2,
        "address": "0x9C202Cf5fF2dB0ed1A1f520CaF1C18244FA4988a",
        "signature": "0x11be0b98b14f2e96f3807abd9510a4b9f2797345b8c0bfa59452d5067a12ce1877617e1e056ac034bef39d013a362db995ce505d1d5153e27e10104f496b726b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x31e5FdD1ec2F14Fe80006F9f3f0c4A1Ac42A018D": {
        "max": 3,
        "address": "0x31e5FdD1ec2F14Fe80006F9f3f0c4A1Ac42A018D",
        "signature": "0x1d4dcae0cb1ac9426c8f4c2420479b4dcee7699c316b5c9b641338ff23e386d935e1304fd5b7bdb0b261ae1d94313fce3662f8c2e80d43cdf91a97fe8e243d951c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x52B24BecaE3fa1036cA0e956cd987D48A8f0e165": {
        "max": 2,
        "address": "0x52B24BecaE3fa1036cA0e956cd987D48A8f0e165",
        "signature": "0xc58c0b471b4feb7b7200ee64d137133bba27be5f819cf842636f366c99cc9c6f27ab740a7c506b763baeec41251cb456cee46b49e9a149128180a4b185c575a11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEDBfb42D53e6c3A99f770b50288dA0E4702F6524": {
        "max": 1,
        "address": "0xEDBfb42D53e6c3A99f770b50288dA0E4702F6524",
        "signature": "0x13cb40e5d99f3ff25e31908f83c59b193a651fa19a9f09aee140a2190e554e151c0cc422e5de35a4b396ea2b24e2e00f0f78c12929da4549d246f5f5ff9eac521c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE5442E714EBC4639dbaC03Ed9f9b6C395FE6b5fA": {
        "max": 3,
        "address": "0xE5442E714EBC4639dbaC03Ed9f9b6C395FE6b5fA",
        "signature": "0x99c2a9c3bf4a1fc9a8a2d55d225d06a9aaf26b90121661df25e454508911c9dd241a6190fe2775313950aa44681c34942998820d6a0ca0bfb4b42983560e0c231c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x74b232d3A4E8CE1CC3345D1046c3dfE8581DB06e": {
        "max": 3,
        "address": "0x74b232d3A4E8CE1CC3345D1046c3dfE8581DB06e",
        "signature": "0xc4d511c0d9a2418e305977b33acaa2c2d7b75afbc7ffc0e288dd0fff27b6e95738670510030c141308a926eaa77b30d55854b93dccbba3a02910247b3ec8856c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7f2E75065c273A8F368E125f367c5c45D53c38ab": {
        "max": 3,
        "address": "0x7f2E75065c273A8F368E125f367c5c45D53c38ab",
        "signature": "0x9bafd3b01c551409fd60ab894a4183cd74be5129b1c91a18fc53c650d06dcdd813a6948492c81e33a646bd64e36ee36aa169d3e5120ff14ce75acd3a4ab4b1841b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5f3fEa69BfC3fe51E9E43e3BE05dD5794AC50AB6": {
        "max": 1,
        "address": "0x5f3fEa69BfC3fe51E9E43e3BE05dD5794AC50AB6",
        "signature": "0x2cfdd8b3a0aab8953d905638ac60516078e580a2e311faa2b66530a2cea11f06405c0cdb91f1ff0703766f6f7203f2c0c106f31c39a8a73864f7d77ae42ed2111b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x503117707AbebCeFc8d6970DAB48Bdc7e9aBf17a": {
        "max": 2,
        "address": "0x503117707AbebCeFc8d6970DAB48Bdc7e9aBf17a",
        "signature": "0x8473286b5234c058557a94b006f0fab22df0cb4167888a356ccca3bc2f04fe0d516e4ceb085e76002030b3587f59c5ad5aacd6406e82fbfa8d8398bc73de6c901c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8eA425Ff3a2ec77D889CFBdc3d096E94c313234C": {
        "max": 1,
        "address": "0x8eA425Ff3a2ec77D889CFBdc3d096E94c313234C",
        "signature": "0x8457d014240c4ad53edf12b298b36a4b10dc5f52f674c5cddf7458f2003db4630b57421fa3955b9063e951bea50d4978282103cbe3420cb7f7d083a42f73c2d91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaEabe7513BB61325E22c0D7Fd7B2804b3e2C9C28": {
        "max": 1,
        "address": "0xaEabe7513BB61325E22c0D7Fd7B2804b3e2C9C28",
        "signature": "0xad20f077a5379d09a2353a29b0808cbdbed9d43e5bb7af7d9af29858b6415c6d68832be690008e7460996951385fe47a3b48b47ac3e2a4a5298fecbb1e3b03321c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF35bd6dFDB3103Dd7F2A05352489B714563c9fa1": {
        "max": 1,
        "address": "0xF35bd6dFDB3103Dd7F2A05352489B714563c9fa1",
        "signature": "0x03502e375fb9ffb165a46195b5d2b8aee5edc84ba6c7697b4120e18631beec6c626cb8b9901f94b7b27a234e1c73b46de4731f2c8fbde40b9e6a080f61d9feec1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5034A9a16cA49DfA07885C52a803b3a4cD0779e9": {
        "max": 1,
        "address": "0x5034A9a16cA49DfA07885C52a803b3a4cD0779e9",
        "signature": "0xdc66f48d9283f06f96d9ecb07a7e09460d84e65a2f06f36b1e45c50ba48c3dcc31c522462b6a67f67a106443293512bad227c264989988696df331aff25e51781c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5a45b05f405D2b9A3b8aC4Ebf461Fb455cc5eaf0": {
        "max": 2,
        "address": "0x5a45b05f405D2b9A3b8aC4Ebf461Fb455cc5eaf0",
        "signature": "0x675c3ec14fb408734d6c37b289b12c5b15f671a0dd98f8fe21a1aad726bbe0c90c3ecd5c95d9824a7c0eea4e025134e8159b1697a617bb4791b41df9fb61e4e21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf87dF23b73177236663e262bFc43F54B72C90595": {
        "max": 3,
        "address": "0xf87dF23b73177236663e262bFc43F54B72C90595",
        "signature": "0x3f08b38083194eca03dc1936dc73279d0d5a28e51a57af03897bacada3d52c240a3e9b422f4ef2d7f58f02881dd8204f7fde84a0a7b61f9f4f07c6024c8290a61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x28C0d64F4EDa4fCb7e3BCBaEa37Be0C999f0844E": {
        "max": 1,
        "address": "0x28C0d64F4EDa4fCb7e3BCBaEa37Be0C999f0844E",
        "signature": "0x6b1b501bc7b041e522a222f28ae85e89c9be2fc81a4c1ffe4541e59201a19f564cf689bf22e8cf9161fc93bc63da19acb290216a897bafeaeb7390db6468ea7f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x78B8d4B8e513d134DBA5a94125Fa3A2F68d855f8": {
        "max": 1,
        "address": "0x78B8d4B8e513d134DBA5a94125Fa3A2F68d855f8",
        "signature": "0x6f624a70c41c5ba60ab415d9d60025775b66a10674536ce6cb2277900fbdd9d804c297bf074bc92b73f15814d4e21b49e794035c2674fefa02343873ca7f06881c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x47be3b7808b5f22a4ddb6eae5C42FD70cA62CfFA": {
        "max": 1,
        "address": "0x47be3b7808b5f22a4ddb6eae5C42FD70cA62CfFA",
        "signature": "0x44f7cbcb49e74352e62aa83c02a6b752f38752682ca9829794e5b8af701ea17e661bb842c17c96157dc60d7a118d6fd7e0cbb18707fb9a0e91f9a28bb658dce31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x74B1C088F4Af8Cee1a623028228252469F9F6fD9": {
        "max": 2,
        "address": "0x74B1C088F4Af8Cee1a623028228252469F9F6fD9",
        "signature": "0x9f050877cbbd9819de8872d26ec0b811824de5f221dcfb57f86caeb0f73c5e4175c26e24b977377259243ae98cdda1051bc30486d9044b521d60185b0e1798fd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2C78A83F0949EDbf8B0d5c4b1cD116194b56ac05": {
        "max": 1,
        "address": "0x2C78A83F0949EDbf8B0d5c4b1cD116194b56ac05",
        "signature": "0x613d97c3a61835c663f1a05903c6823bcefe534ca72190331d60dec27779ba9c59245d3a8fdf77cfaf559410208dc3875da5df3601d14222fc55273412b289151b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xec3Eb5c74955F0ee151Aae4bC6e89E8AFfa3ECa1": {
        "max": 1,
        "address": "0xec3Eb5c74955F0ee151Aae4bC6e89E8AFfa3ECa1",
        "signature": "0x665abfbfa59c7fe238100501389a545ff003746637591de19c8928084c89286553b69bdba2fc539ff7a14945e8778df567613bb9f514de35f549653c7022bc7e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1ee398a066487C7500388b85907e7611C7bC3A30": {
        "max": 2,
        "address": "0x1ee398a066487C7500388b85907e7611C7bC3A30",
        "signature": "0x393edd0a1848ca0f6d8744060db926ef8ec19ee7f59f35e3e54e15ec79fa7edd18105e1d65b0c057dd238a0b39e8dee4c2be77cc53f84e698e5c1e708c0f72771c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8Cd5140d719f6A6a14bAe8510e297fCdA48e7065": {
        "max": 1,
        "address": "0x8Cd5140d719f6A6a14bAe8510e297fCdA48e7065",
        "signature": "0x3a09bfa86b67fcfd4d27685100a2ca2240bb6579424af48c2b350e9fe1952ff4776d711d83c2d06d2e2fc8f7133f7c67b31ebbad943252eefdfea39240e932991c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x881Dd23b82aada23f8BE0C12A33DBeE1f469A47f": {
        "max": 2,
        "address": "0x881Dd23b82aada23f8BE0C12A33DBeE1f469A47f",
        "signature": "0xfe7f9c83c45925039d813eccb41a71e624085a898f2f1d6552694503b08ef458521c13fcc6d6fe1a3734731ea42872840b053747e0fe8683553e07d75cf76d6a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x014B4EbaA25Cf06fE71C7e80bE5237c279E331B1": {
        "max": 2,
        "address": "0x014B4EbaA25Cf06fE71C7e80bE5237c279E331B1",
        "signature": "0x55954eb95c2d794b02f7da61d1af81698cba1f7d810930dbc26d8627aed31df76242d4685fe3f4501210dd3a6faa0940256329819667e8e23b1fd48cb12ef15a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDFc01838f90C7E2D9a13003dd375F4ab705a7bD0": {
        "max": 1,
        "address": "0xDFc01838f90C7E2D9a13003dd375F4ab705a7bD0",
        "signature": "0xa4f264b90d30e72d890f59fbcbe1d164eb61198168148bc26ae35e3ac76183db524858e705750a131088d0f54461b89f0e6fcdc16c7c586584754579ffa8e19f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9934608ac3d88DB9de7EA0CD565927a4F0F1d47a": {
        "max": 3,
        "address": "0x9934608ac3d88DB9de7EA0CD565927a4F0F1d47a",
        "signature": "0xfa38ab9927a1f569b8ddb6e0d00b538ab4944e68a6d7aec0932ac98f56a7f8a52ea418366f454bca91f0ac736044e2e6b20ba42c4759db23ef7232f3f2fa199c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0809F379F141B3F735acdB7dc7A7b57085eb44De": {
        "max": 3,
        "address": "0x0809F379F141B3F735acdB7dc7A7b57085eb44De",
        "signature": "0xa71d2916b91656ea9d61cb98c175f4ff647220c58806114397ccdc4f0aab7c74029b7d7330ae0b15ac3d90aef02ed75dc33df0e4a81885b0a7605996e3cd41781c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4dda190e0a66B1905cb982Ff8609F6f6Ae441Fc8": {
        "max": 1,
        "address": "0x4dda190e0a66B1905cb982Ff8609F6f6Ae441Fc8",
        "signature": "0xe71f46c98a26a2d17ccdf04c265922e94b77a66ec02ffeb2d459cc5ac8e3f25a326ad0015ade00741936f02e7ee743f8db088e59e25ccb009cb4ca3e21a7dc6e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6675a4c0c70CC6e180cE7522c38118aCcEA49F3c": {
        "max": 1,
        "address": "0x6675a4c0c70CC6e180cE7522c38118aCcEA49F3c",
        "signature": "0x75c042353543aba9d620d0a09fa27c91085a86bab77163e796231facb2ae17c535261557c9151aba67ef1213953f2d6dfdd7e9d9e77455995ce20ed97fa19e8b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9075acA029d407815F175725cE9d9007b5e0433f": {
        "max": 1,
        "address": "0x9075acA029d407815F175725cE9d9007b5e0433f",
        "signature": "0x900da1278b98a82302a0626456f1743344da22a8bb7899269534511d546545b95dfde1e52287b30e6c98be372f0aaaffbaf76250e6881181d00d99e2ace48e201b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9ecED984DEf50A17043031a2941Ac4aBd83FE49f": {
        "max": 3,
        "address": "0x9ecED984DEf50A17043031a2941Ac4aBd83FE49f",
        "signature": "0xb88997369a07c970bd124dd38143df516a354305f49d70b3374366527196344f16c03a2e729510245a1fbb20ad88e426f28344986b9d24b65b58b1be7c8a62211b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf421763E3467acBe0f0073F5Dc8b67dbCFe28FF6": {
        "max": 3,
        "address": "0xf421763E3467acBe0f0073F5Dc8b67dbCFe28FF6",
        "signature": "0x76dd388f6b133ff552975080ea21bace6ac46da1eb4804a787c3251db7d539f76b20b0b7724cb3bbc83686cf2eaa804cb43ecf1e7678236b610c8a5b0db8d2bd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x86dd3BF40AAC60AB6c1FF30dd7B7A6bd77606092": {
        "max": 2,
        "address": "0x86dd3BF40AAC60AB6c1FF30dd7B7A6bd77606092",
        "signature": "0xe5c1d4c45c0f97ed100dcec8e0759f560f647fe1581dcbc600a464c958d2413f5a0f2d886150a326677f52f5f412605e4e6468ec1d885cf73f2b4be05f619dce1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x14e1A2FE8FF05dCa590B5290AE106C99fE63a479": {
        "max": 1,
        "address": "0x14e1A2FE8FF05dCa590B5290AE106C99fE63a479",
        "signature": "0x60541b59d65d2a030270e8911c4177fa0a43b29de9df65bf2c0c7e0766cf1de42fc4afbdf797573341c9a80a8ed3a1f94582d3d38b3a2344e079d00bf2ec70921b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x18982Cb2926340dc5dcbA36E9C9c3cE3CD0F40CC": {
        "max": 1,
        "address": "0x18982Cb2926340dc5dcbA36E9C9c3cE3CD0F40CC",
        "signature": "0x9b8dd0b36cd16c8ce8136a94f6b85111021d701009abadce5be94c6cfe3e99722eeef2b0d3f5e9de8d67d24df2ac24d7ff7cde98ec9daee45623072b92e8c53e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAf6070518868F96B0b9955b25EF0954C666e6A52": {
        "max": 1,
        "address": "0xAf6070518868F96B0b9955b25EF0954C666e6A52",
        "signature": "0xa0cf449af2d39922134ea38a6eb4c4367841245106111d3151bd41e24046345a360881f4217c4d3ed0639c782811eead45f111f126b796300a0a1a61b64fd6d51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5ea8a3D4D7B34EdFfB12f9B558662f2926991Be9": {
        "max": 3,
        "address": "0x5ea8a3D4D7B34EdFfB12f9B558662f2926991Be9",
        "signature": "0xade8f13f65fa12db339f256aee02a323100132dc0b4604d5e54fd1011c3c2a475e9e8e65fb6beaa8dccad14a28ae49abc275cb97f3e60cfbe273c2fc912038c81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC4A8897692eb59e39FF8d5296cC8bcDCC9B86912": {
        "max": 1,
        "address": "0xC4A8897692eb59e39FF8d5296cC8bcDCC9B86912",
        "signature": "0xf1187cf4465869b4cb4830c2b198ae0d477ff47c7e55d5aedafaca5c0b0474b52c4590fc415ea50e2bee27fc68d507a35b59ac9966f29d6cc2fde2437cdfae991b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8724D3B85E5dE2b544D8423FF7979dc2F157D292": {
        "max": 2,
        "address": "0x8724D3B85E5dE2b544D8423FF7979dc2F157D292",
        "signature": "0x56cfb3bb38b4094bc468646d93a9caa751a7145fe7f813072456b3cb5a9edea173602f9dae2ec71123a0ba2cf7ac291705b9353f6c600c4c6f50e4ef965c4a0a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa33827442e4E69eF9F26eB48aF01d540BB8663d2": {
        "max": 1,
        "address": "0xa33827442e4E69eF9F26eB48aF01d540BB8663d2",
        "signature": "0x9e5e6f204015c8f85b993a585966b91d8cc464da748b0c35ed87b631ef146bc0074931e71ab673582445983ebe0f6029e90fa3cb43df7641c0f4e702737fcb491c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf407Fcf4b09E9D4093777e1Dcf33383DCd44Af45": {
        "max": 1,
        "address": "0xf407Fcf4b09E9D4093777e1Dcf33383DCd44Af45",
        "signature": "0xb0486487b23c4272bbdd414eed4c07cbe53ab75a8057ad7a4f762386fd56ab3d62ede6771c49c99c33f3d8d36ee861900fc2e104cccdb8c3c5221b2a0f81259d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1FAbFd128d498fEF7da0190ef416a502375bf59A": {
        "max": 2,
        "address": "0x1FAbFd128d498fEF7da0190ef416a502375bf59A",
        "signature": "0x3ff64554e0ffd7727e0634eee25028eca30aeaaa804a92b4d1383906cd4fc9e84b28aa0750ff630019faf751fc217dc451a402bacb77001f8266238241fa1ea21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9f802ccAd997aF4ECd57720130A991Fd60227965": {
        "max": 1,
        "address": "0x9f802ccAd997aF4ECd57720130A991Fd60227965",
        "signature": "0xdd15b024f4dd161d8984c74aa879559d05e917f4387c622b6a240ced0c6a4bca39e772a3b6aa2350f9b4e6ce40d4285ef0c22d1a2684a25210c2ea59c859da881c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x313470CF1dd9E341298F0958593fBf7AeEC3bf15": {
        "max": 1,
        "address": "0x313470CF1dd9E341298F0958593fBf7AeEC3bf15",
        "signature": "0xd25cca812fdc9da133b93749a74fe7a36651fcfbd3978a0b38f40185920413da5b5eb369def5936868f2b742a71d68c718f68ca5119997233997d86a2e3a685f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0F4e22289B4bF3a1817209625814E80632f94E66": {
        "max": 1,
        "address": "0x0F4e22289B4bF3a1817209625814E80632f94E66",
        "signature": "0xcb4363217cf7b203e2be77a338592ae83372e741ab05459c1a0c89c3a5da773a29c942743d23ff97c383748f8eb3576573020f9ddbdd8aba21b917400e5dacbf1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe8EC34e14d25aBAd13719BedEb4A22B578573094": {
        "max": 3,
        "address": "0xe8EC34e14d25aBAd13719BedEb4A22B578573094",
        "signature": "0x68c562b1213fc0e38f23f9c70d3f291f73b3b51f061c77c3da27d1bc878a0bf10cab7006a9c82dba87883b50d9db34b9931825c36b95f3122668465740ec0d401c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x532502912f2eD5cDf033e280D94C05F1b99Ca15f": {
        "max": 3,
        "address": "0x532502912f2eD5cDf033e280D94C05F1b99Ca15f",
        "signature": "0xca24b3e0be3b59aa26786c82db416f29680150cecaf8d9ccc84ca458d5f415ba2c93ffe5b285ce331a6961a3f09c1bb36efd7a9ff78d5864e86773d93f5092751b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x66CAD93ff07E68BbF1D81C348CA8D10fca20441F": {
        "max": 1,
        "address": "0x66CAD93ff07E68BbF1D81C348CA8D10fca20441F",
        "signature": "0xe91a911a30362f92a715b79f6495ece422876a7e3d1e5c757dbd86df546d7a0c79b82d7ce9ce8c61848707acfc64e69d8247fc5cd3e55b629529352f2464f1661c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcc47F370D7b0892E5be50e3642d7815aBb13c449": {
        "max": 1,
        "address": "0xcc47F370D7b0892E5be50e3642d7815aBb13c449",
        "signature": "0xbc9ca3c99e57b0be0ef800e011f03054e97fd57255c63ff21674ed907270c2301ea0b821913db030e05eae98fae2963625876c00c3288058bb9d953b6f1094131b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5e39F37B10FD3d55F27aA21f4b82a03bd0AB34A2": {
        "max": 2,
        "address": "0x5e39F37B10FD3d55F27aA21f4b82a03bd0AB34A2",
        "signature": "0x53f939b4dfa888c616ad9f94d01a2c6dc5bb66701487fdfd991546658c7143d4292522e13a0f693ece2a528ece20a91fcb05278d7eedc2f428063a42ddac60fe1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1D7C08AdC571aC4D1d11578C0b154215B68f0cb3": {
        "max": 1,
        "address": "0x1D7C08AdC571aC4D1d11578C0b154215B68f0cb3",
        "signature": "0x96e7266daf147215fe885c102ab026893a479bf93b4591bda6175fd084c42dff03789955079fcdaf7abefee4dc8b8e4fd68482f6fc5f5706965a61c7452a85831b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAe150Fe9af090eCEdEd52dbADeDA6236f3acB865": {
        "max": 1,
        "address": "0xAe150Fe9af090eCEdEd52dbADeDA6236f3acB865",
        "signature": "0x6928760a154b4fbefadad3b9ce2f27d0bb97854a33120712e87f2b597cdc81bc3503f6ff1f2b1f77f92a7f710f86f61b5ca89e0f16d098526ce561c485cbaad81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa31Df4e639970ee62CB20bdAf79B272810722f1A": {
        "max": 1,
        "address": "0xa31Df4e639970ee62CB20bdAf79B272810722f1A",
        "signature": "0x597451dc6c84ef30f10201cb5e3169f4dca95eb6d7f9aafbfc4b5f24b9e99421016bd08f07148562c88e6840258dba70383517db7db3e3c2f70f4f11822095371b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC7ab53a35cD827600408b84ab7915b679299bD4f": {
        "max": 1,
        "address": "0xC7ab53a35cD827600408b84ab7915b679299bD4f",
        "signature": "0xb99cffc096aa7c0bbd65020bcb641f36cbcbe8559a966c7958def3d60faa8bd66917c0518e5b4ffd2a95f38ec4ad7dafaedb180db598e0bd4ec20ab4fe8e1bbc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x587D714453eCcD6F49818da4C17121c092506cCe": {
        "max": 3,
        "address": "0x587D714453eCcD6F49818da4C17121c092506cCe",
        "signature": "0xe46248e935b84ead724d3b360f7c85b258f1f83193fe7500bb1dad7a61a0e5c156ab953689b068985bec6cc59fb93207b985909ea6e891b733c9bb74b7036cfd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd80d923a92d7f5Fe96C184Da3aD04955Bb099961": {
        "max": 2,
        "address": "0xd80d923a92d7f5Fe96C184Da3aD04955Bb099961",
        "signature": "0x0c0d902ad881843acf224505373ee5cdadb1b6be5239bcabe9d9b55c2bea164b1cdb6e7dd1cede74d655cfaabeca9ed7dbc0f392ae087178316e3adad6dffd951b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB5BEebBFB568be3d5d7AFc7C35CAC5bC517a1fA4": {
        "max": 2,
        "address": "0xB5BEebBFB568be3d5d7AFc7C35CAC5bC517a1fA4",
        "signature": "0xa189978bf0248b9a65007a9df1e15f5faf1508976ad8934b93122d368566120a7302eada5e8dc8f61523cfbe8dae76a3a217fe365959383b8593793f4fff6c201c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x80D1060d9CFf5e935B815F8cd125EfD3F03640E0": {
        "max": 1,
        "address": "0x80D1060d9CFf5e935B815F8cd125EfD3F03640E0",
        "signature": "0x1f7157432a15bc44ec6ca54c7c8feb1c1e4577df41073ad1a2f8cb1fba68e99567c75dbabe679a116a3fd5442fbeccee5f129c0132a29899bbd5f65d3aa61e571c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x471005Dd757858681982fA8673e1b9fD3f5349aD": {
        "max": 1,
        "address": "0x471005Dd757858681982fA8673e1b9fD3f5349aD",
        "signature": "0xd122462dc372db4f289e2f948f0abbe28fef7ba34c17baf3e98e7c6feee3f5f94f9e0ab91d21f5b8693f4ece0bcb470e3b4788a0e274ec58d0383570461284661b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xffA43dD43C85B680894BE63a906c5a4207C9FCf0": {
        "max": 1,
        "address": "0xffA43dD43C85B680894BE63a906c5a4207C9FCf0",
        "signature": "0xe9fec0bc21a3dd36c4b9b82e21afb189f390511b1bf425c7c1b14b46e46990b04923e258b7871482c03ea65ee3e4cad960d1f7ef0feaf8cfcefe7416bda886ef1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xef8890C29628dD9E4C076C07C0459566662c6489": {
        "max": 1,
        "address": "0xef8890C29628dD9E4C076C07C0459566662c6489",
        "signature": "0x2b337b23126d312fb1d5412992618e6d5bd5aa030cf1c5522216f97b270d8b00786556dcdec5a69c5c55bcb959a5101bc8e31121f379b112c9d2f06e7d7809251c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3A13646cf7Fac5a566d76B048deD85BAB2F1E075": {
        "max": 1,
        "address": "0x3A13646cf7Fac5a566d76B048deD85BAB2F1E075",
        "signature": "0xf8142c18d6812709204bfb0155c1560f53d641a4e9c4bbe125b4a7fe561999c7318879c752fcde300120434d1f92ea3fee4de1b265a0f7095e086ce35740842c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xba4e78a72dE4f9d2046B8101eb88c68E474F9bbD": {
        "max": 1,
        "address": "0xba4e78a72dE4f9d2046B8101eb88c68E474F9bbD",
        "signature": "0x55fc8d5b0c593bbc6d206b2e4a0f74ac6c6d939dc36646b726c4d0cffbb2811f5843833f7d8f468a9f82c81b58a4d65f124c06df688c60c5394fa760eda0e1241b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x71e6805A8fBE81359eB26617f9b6f4C1863814fE": {
        "max": 3,
        "address": "0x71e6805A8fBE81359eB26617f9b6f4C1863814fE",
        "signature": "0x7cc871b1a1bb1c664c980c8ba487a4816f56263161520408048f8a9279c4c6567985b1b9f47f7c101f1cf39c6716fe1fa00174fd0f8d4f44f6b90e08e0e7a2d71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd6bc72D7f58455aA9B9F5F8f03eB7eB7142847d2": {
        "max": 1,
        "address": "0xd6bc72D7f58455aA9B9F5F8f03eB7eB7142847d2",
        "signature": "0xca7aaa460587102bfd8791ccbd03fbaa66b90567127c70bfd3e7573d3e84b65357972f2185983c640392b80e28ca5f2f272b4e4893df4e41ceeec26a2ac414931b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9C5E8414A779EE17b2F54eCC87a981FB77868be8": {
        "max": 1,
        "address": "0x9C5E8414A779EE17b2F54eCC87a981FB77868be8",
        "signature": "0x56df99f2ee7448a3baedbd643dca167906d5115c059f3f9db4a7a734217c8ad536f899f966601a02340e62c6eea5aadadd91115220bc783deff9c3d9905566cb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE1e99292C4745fB8a1a65f3A1Bb440BcdC0F9656": {
        "max": 3,
        "address": "0xE1e99292C4745fB8a1a65f3A1Bb440BcdC0F9656",
        "signature": "0xc94c22179bb10136b34b2896860ec1ea5b92295d0788a64c287be3402a2742fa5b782c260fd82b3447861f1ee94a35976c40ea261d3821c9d5e5f1a32c02f8f61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0076A4b95e6898c4A6a81BDE5EeB1aD4b02574Ae": {
        "max": 2,
        "address": "0x0076A4b95e6898c4A6a81BDE5EeB1aD4b02574Ae",
        "signature": "0x1e5eafc94f816929166e7f990a260acf3142902410b9af610679853cd71a1c844dd34d90ce9e440d8b0d3975cf50c539a54092eced832e04f88f562a67936edf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7C96a82929A9133DEf4F513aB8fEC8382e1003F4": {
        "max": 2,
        "address": "0x7C96a82929A9133DEf4F513aB8fEC8382e1003F4",
        "signature": "0xc94faf478641a11b0ed9966b90132d47c2427480dc02cbee83623b8152a87bb907ccb7968832ee37f2f207aadab475f9f59df6fa538ba553afd4e4a6c184835f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCE095083B2f480AEB8D111f8B19b7d008b39B529": {
        "max": 3,
        "address": "0xCE095083B2f480AEB8D111f8B19b7d008b39B529",
        "signature": "0xae194a0ab46e8d4442cc61b8e99544eae0a5c40cd1eaca50f70d995ac69d51ce01947cfecd1acc71f828f88848d945af99155a401a8ae1d43ac822d21c6b4df31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x56BdF4b2f8D854aE62b151C52dc9915036d4c7fc": {
        "max": 3,
        "address": "0x56BdF4b2f8D854aE62b151C52dc9915036d4c7fc",
        "signature": "0x303bd9d847ca8ad87747b756e516b957e2fcf8d627f763d74122c8718a96b0a1692b71981a652a732f65f094f094fd9ed33ac04f8831f8f52f7f19239fa4b2231c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9D00FdE7983aE34e918A6D0AEd97956ca4A7cc0d": {
        "max": 1,
        "address": "0x9D00FdE7983aE34e918A6D0AEd97956ca4A7cc0d",
        "signature": "0x8c2500bd713334fd682616b3aa43be41b90400c2c5cf3ccd566e5b03f1eea60509b2a94c5a9a0a276add97ab9637500f9e9ee87a9fa198bcef8a76b61df9677d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAF77E6ce8FEf4b096E909Ebe6c475Cb991c27675": {
        "max": 3,
        "address": "0xAF77E6ce8FEf4b096E909Ebe6c475Cb991c27675",
        "signature": "0x1869aff704d1626b9f73ca0c706b6e46a33341fd2bf97a7237064f838251aeec24c037c51f1c16c9a5916b6af3588f707c2842098f0d03244c726c3c595fb0271c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7A12BE3a92E0d662C37b76A26dAD5B442Be0dF7C": {
        "max": 3,
        "address": "0x7A12BE3a92E0d662C37b76A26dAD5B442Be0dF7C",
        "signature": "0x05c4507753409ef9995d9e86486946ad321ed39624846a03a5245b14c12865f715c759c0e0e2e0b6a783d06866918f6901f31c72149e8535b4f9cc6f8c476f281b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x140cb4A0CF6B65421cdb7eB8c7a51c839c7C359d": {
        "max": 3,
        "address": "0x140cb4A0CF6B65421cdb7eB8c7a51c839c7C359d",
        "signature": "0x6de303736c5b0882218dc5f1d0d48df885bc27611a614eebfb95d88231779dc86f86c32a414a5afaeaa22ee12733a821740ea76845aa625abd2284a8e59c8f741b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFF7F6FE7A3CB814d52bFA25d3990fc7148eF91A9": {
        "max": 1,
        "address": "0xFF7F6FE7A3CB814d52bFA25d3990fc7148eF91A9",
        "signature": "0xb84d5b04a5bd610d7bd781d2e1aba4ad951637df97de72f1799b0644a533c05d65c0bb53a91297d9465cfd9e053dc3af4fc1e3984f9627c090ce70572111e9a11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x383fc519Cc6163d336cb839EDc89AaE3CF82D61c": {
        "max": 2,
        "address": "0x383fc519Cc6163d336cb839EDc89AaE3CF82D61c",
        "signature": "0xa655fb4966660e729bf728f959d501d08a550f22faef40b6e7f2b329e8e4a4216e4f162ebe9655376b69ac9ce22ba34d4d2b0c82172798c121a58b4511f275171c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCfdE83e7dF386c5F693dA72a9540EdC26Bf37Ca0": {
        "max": 2,
        "address": "0xCfdE83e7dF386c5F693dA72a9540EdC26Bf37Ca0",
        "signature": "0xfa4a438f3998db44647a82e548da5850ffaec1831e4a5237f400a90327edb1db3e0c5b2b271394ad46bc7f193c4dfafd38b58a1377e8e92a5839dc05e8b92fe11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaE3C5338f61BEC485D3047f1472A5f4BF9934664": {
        "max": 1,
        "address": "0xaE3C5338f61BEC485D3047f1472A5f4BF9934664",
        "signature": "0xd93914cf201c08c05f58c66f3853c62e844b9fd334fc053f584d7772569067bb729e96950543f5ccc9bf17724d622db402bd5dec471f24400c32754cda51f7c11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0Fb4EB373512564Af0cDb95530b4Ba551eFCa6C2": {
        "max": 2,
        "address": "0x0Fb4EB373512564Af0cDb95530b4Ba551eFCa6C2",
        "signature": "0x179af8d85b9f1f4956e48c5b648bf38ac1588a96d9434b2c073627426c2ba2675e6b4e75b499c37cd9933873e5b8820439cb4378b89ad38b75e2ca69ce3ba1db1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7226DD153a0972F1279BE4120e55bd79Def8a247": {
        "max": 2,
        "address": "0x7226DD153a0972F1279BE4120e55bd79Def8a247",
        "signature": "0x1a02b08df4b05bf478230119407c6f0c10ce0a2e619e29743e712f20c8e6ec81310a3663ac864c26dd88daf627b5d953e19e422d4af9042052643190d23b04021b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa6DB9d5977De9bad6D8a8Edd0309ea883deD14A7": {
        "max": 1,
        "address": "0xa6DB9d5977De9bad6D8a8Edd0309ea883deD14A7",
        "signature": "0xa58a30a4bc63963a58bc0d5bebcdd0879a1e031aaf8d392d94c23e4bb96194382577d99abe2cd76304b311d0a9f1f9af9a50a0707cbd449e90b4560c5fd2788e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x77E8001a83E5175e36B0d80c11FE8b5a65AB5CA3": {
        "max": 1,
        "address": "0x77E8001a83E5175e36B0d80c11FE8b5a65AB5CA3",
        "signature": "0xd30a00b329742220f2093935b2d77a87e5988cc3ee1f0d0c8a68e9999694cc2a371866905fd9ed8e6d98c3d47abfa3e33076cbd4128a9612a0e37aa6afc35f291b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x978380159a8f2C615BB49B520fd844F854898070": {
        "max": 2,
        "address": "0x978380159a8f2C615BB49B520fd844F854898070",
        "signature": "0x5e0605fd9a5f3eeb5e4f240c77061e5d76e8e42a176f489798a021117191f4fb54b755b3d7a7812e68a6a5fca97db51848898766fe36693a810b3e6ef1db9b761b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7A45271D7B3C044144FAfA089ecd4A3b72D18534": {
        "max": 1,
        "address": "0x7A45271D7B3C044144FAfA089ecd4A3b72D18534",
        "signature": "0xe47f67f5479fed301142e2688e0e12ba874690522ff31252ab3c3b07b83dc5883fe199cc4f9bca8e24fb9e59976909fe20efc4fdf56cf3278b534b14e275553b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x060dB2D4dfd2619D825c9Dcabd5a3Df4930d589d": {
        "max": 1,
        "address": "0x060dB2D4dfd2619D825c9Dcabd5a3Df4930d589d",
        "signature": "0xc2afe0ff17797d023fd33348227f54317481775cf8535b6fd8dba8f70f878e544968353261e2447b6dcea0446812b6a181c8861afa7cbe3a4fd99bdb672d84ab1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF648b671F29371913219ee5e71a18f55C3DB0aE6": {
        "max": 2,
        "address": "0xF648b671F29371913219ee5e71a18f55C3DB0aE6",
        "signature": "0xaa2603ba1ddee7bf872733ba2899b88ffae2a1aaf0fec544de9d62b2849a4de945616124cbd6b1df498b5cbc2f77b57d9a53f376adc6800f66f7b396806e161f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB83d1Cd67EF4d54a02B6202Cd534E8EFE12A2738": {
        "max": 2,
        "address": "0xB83d1Cd67EF4d54a02B6202Cd534E8EFE12A2738",
        "signature": "0x6b9b0dbe8762759a08e125396691d04cfc7eb37d5dbb61d00f61eb2feb7796b2753ec8b6b912f94d902672e3962caade2ad4cbf83bda6111e1512349773cc9f11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBc189BA38441c465F748746b12f8C87Bb8ECa61b": {
        "max": 3,
        "address": "0xBc189BA38441c465F748746b12f8C87Bb8ECa61b",
        "signature": "0x9d51cac60d8c3a56f4dbe60211b7595de7bccb9ed1f282f16c6bb0d814e80e4562a022536b72b5b83749c21216cc5758c1103f7236102d111d5dae2fbad398061c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xABF0991032CfF614881e075f6Cf0f561a7B1e8B0": {
        "max": 2,
        "address": "0xABF0991032CfF614881e075f6Cf0f561a7B1e8B0",
        "signature": "0x6e8eced54142085c63851843faf39fe089452a64669b3a80e2eefb7a9dc9c947256fcd5fd874a313916421e812c531a88f71bf290a192be330ebd1a928cb08601c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe1bBcec334a963f27e71B8553259e5146EAC4ac9": {
        "max": 1,
        "address": "0xe1bBcec334a963f27e71B8553259e5146EAC4ac9",
        "signature": "0xc0e4f52d6b6a95d62f2be739d1e789a4bdf5e2bdd654c0630b7be7910f65b59606d5f291ffb1a3610e66705a70b5c1034b43178f4e477e8b17aa46346328c5561b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaBDe62569603BA7a8d03563Dc64551f0e4141137": {
        "max": 2,
        "address": "0xaBDe62569603BA7a8d03563Dc64551f0e4141137",
        "signature": "0xfd8303a756ae89b5ff4777a443de569e6f48629f03d9c6b547aa19f50b5bf4195409152b6ab675e4ddc94f87d99953e1f29c33aa7a3b166b943e35a91f2a02db1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x49a59B4701e7BCC1F1C32575b19c1A6BDa556c87": {
        "max": 1,
        "address": "0x49a59B4701e7BCC1F1C32575b19c1A6BDa556c87",
        "signature": "0xf9a5d55aa0b142ef164a5109859fc411cd11cdc91f21b3f94f060c3268daa3123a1e344a23cb002241bfdb23576229a078a3616588bac752b2feb3229be85c191c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3072e3aF03ec1b36B8714b983fB902ECb1aD55cB": {
        "max": 3,
        "address": "0x3072e3aF03ec1b36B8714b983fB902ECb1aD55cB",
        "signature": "0x73e65c463dcc4cc034c0046926df46b77afbfc7c3e9668d680c4643d5f6b104d352c0c2cef3a15ef3d6deefd250587b84717edc165f9c8a288331d548b50b1c21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8447c40144342627C4614F5cba96fE6ac0dE962b": {
        "max": 2,
        "address": "0x8447c40144342627C4614F5cba96fE6ac0dE962b",
        "signature": "0xf8b3d1a8e94254d2af51b03e9ab83d76a6166d5cb0c6bc6940bf1bbfb06b1f685253fcc30cf69c4ad8e7739320f141fabbb1c4f5aad06f9ed913fd79455291941c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE56A6bca9FdC1afF667f44f7f92e1A90d4258359": {
        "max": 1,
        "address": "0xE56A6bca9FdC1afF667f44f7f92e1A90d4258359",
        "signature": "0xa71c3f284c32a92806ba9c3837253c1171664174a1574440cd4be774bb1a3dc907cf967d22650462fd80b881aca27ad0b0183574fe6d2dc968b01d5e1ee04a271c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD242BDc4B29a28469AcF956A9d2Aa3A3A372c88D": {
        "max": 1,
        "address": "0xD242BDc4B29a28469AcF956A9d2Aa3A3A372c88D",
        "signature": "0x5a855f4b46d4a73b66d052faa5d711bcbc1d7dcc1c47b4cccf679e5d66929b407979c3e33f0c65748edb0b495fadc0d3cad4b5e67881db084c2bcea18bd878b41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9BBE5D008C0CA960D1ecca0221dc65a8734F3689": {
        "max": 1,
        "address": "0x9BBE5D008C0CA960D1ecca0221dc65a8734F3689",
        "signature": "0x42478fdcefed21c85a4db22896a7c81ef13ae067307cc99959c9f46327e48a6365e3c496ffeb309229b533048bd8e56d030cd7681562a3d2e83f62f92cfab1871b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe87935A6b318776eA03A1fdafc51994683911454": {
        "max": 2,
        "address": "0xe87935A6b318776eA03A1fdafc51994683911454",
        "signature": "0x186c6182854346f6539bd7ab7227e6ca509bdbe59f36795a8955b3d08853d8f23331536f41bdfb56437a1ca12bbf2d5152a0a2cde4a5be27d2c116c366b02ac11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5337376f90D4663a26134aea61D7B54E4BE261b5": {
        "max": 2,
        "address": "0x5337376f90D4663a26134aea61D7B54E4BE261b5",
        "signature": "0x989fcb61b9dca13c714d3f1756560a5bf195092b5d487d87c337fb6e954c44cf181711bbd210e8d7e6b70f27878d648f8428f98efc2c05dad441db6129f3222e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03Da39d3EA57E830F370C09B12e9E7352643468E": {
        "max": 1,
        "address": "0x03Da39d3EA57E830F370C09B12e9E7352643468E",
        "signature": "0x996a7900e966d7349dccfd691d81b8827ef90c6b1721235d40cf532efd744ce55828524dccfe5a3b490f2140f963beb07e252db2b0a47a7ee95a4130942f10501c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa25136CE328CD92A69B64028689ac6DF746d3ff9": {
        "max": 3,
        "address": "0xa25136CE328CD92A69B64028689ac6DF746d3ff9",
        "signature": "0x1b072bac90888689491122799204ae995771309a57f4659249890d603ba2fad01ae2f84c5754ff21ee09e7d8141beb9a86946d23f41b71714cc361e91be9ef041b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc0580E669eA7E191c2542bF8cdf7C7A07673B638": {
        "max": 1,
        "address": "0xc0580E669eA7E191c2542bF8cdf7C7A07673B638",
        "signature": "0xfc3440ca1cb80cde6ab75959e8ec24a4b9eaeeae25a81f10077e9fb4740c17183e8800baae6f5cfcb72f5c7ed4105f5e8a53b4bbe8db89b9c57b81825ffc6fd01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD003e6b0f2789ff1C826180FeEf909209577A1Cd": {
        "max": 3,
        "address": "0xD003e6b0f2789ff1C826180FeEf909209577A1Cd",
        "signature": "0x873d2ad54e2f6aef5772746461f01408ec6c9ea6462b1ac6d750aa841fa90ef75ab15af979afcf15211f718d718993883e79ef0b124abee6f4d3d449f681a1af1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x02Ab5a7e87756034A99Fa2cdB5C678808BECAa1f": {
        "max": 2,
        "address": "0x02Ab5a7e87756034A99Fa2cdB5C678808BECAa1f",
        "signature": "0xf22ea4eec1c5d6aaf31d885435f76211d9067e899aed91b739fe8028ee47da1503b8d2e6353e688f89d965171a24290e66124aafa1bc653c49ce145ae9b267fe1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x73aFD0D663D29dB1d9122E1e87d21Ff60ddF313E": {
        "max": 3,
        "address": "0x73aFD0D663D29dB1d9122E1e87d21Ff60ddF313E",
        "signature": "0x392d1e78c0b2ebf19995aead23674695e34767188222c14193f9ec53d580929c51704ac62ea001fdfc8d0a40ad3b508a93b6d4f201548f4c59c53ea6d6da83871c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x692f04B913C7c88208acE123bAF36752C86Da64b": {
        "max": 1,
        "address": "0x692f04B913C7c88208acE123bAF36752C86Da64b",
        "signature": "0x5704f9028eb7e2766352b077cab4701a86a282d1bde0dc47250860e320bb40d56d748f726d34e5339836c04e8d6d4a260273e3a05b0e921cfa4c97c3551cb2471c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfd45BA41CFB3FC218565a197405f427c8c65de4a": {
        "max": 1,
        "address": "0xfd45BA41CFB3FC218565a197405f427c8c65de4a",
        "signature": "0x71ab2075ccadf5dc3115bf16d715810a6016e9cbf1bbd11eeb1919726e82ab8543a5f53f7409dcb46381040cae5a724083438920c1975f204fa03e727d37020c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x546D121478eF0B55343c15110d3D12ecA9ae0722": {
        "max": 1,
        "address": "0x546D121478eF0B55343c15110d3D12ecA9ae0722",
        "signature": "0x7c7c750986d75fb76ae5b148255715913e464cf143787ffd05d95a66e8c5f6684b9519b4fa31ae5b032a17626fa12d2afb6bb6b3fa7bbcff76a8c25c84e066e61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x54466c1fb3e1b7aBB2E79B49f0367b0c933DBC33": {
        "max": 3,
        "address": "0x54466c1fb3e1b7aBB2E79B49f0367b0c933DBC33",
        "signature": "0x2b535effa964e22d8e579de413e14ea409d8ef1ac5e7955cc451a3e2356f0f59278df3fac930bfaaae619b52f9180f27814661b498b864ace05bbe8285d04f861b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x49CdD74Cd29aeE4773B21E1cbB75a0C9FF113B96": {
        "max": 2,
        "address": "0x49CdD74Cd29aeE4773B21E1cbB75a0C9FF113B96",
        "signature": "0x2cf5b5e5188f59d83c9bb53ef8c22aa2d6e5d5d4ac812befa2d5a538561d7c850c30e9171844cfd364f37d4bae9700a89719565513be2900a10d20604b117eed1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2dFD86bc2eEf0BC1c894a153f90aa2A04c045B0D": {
        "max": 1,
        "address": "0x2dFD86bc2eEf0BC1c894a153f90aa2A04c045B0D",
        "signature": "0x9389ac40ddcf512fd154867cf55f641d2e88d104ca3a88b3a6d5670c3646428e6e61a3d3bc1bad2f4d8a8fc0ec1c74bd2f8c71e96f983aa655619340ae5ea5941c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x27187f1020664aC6d1545C092B5926bc72501697": {
        "max": 1,
        "address": "0x27187f1020664aC6d1545C092B5926bc72501697",
        "signature": "0xb22e642cf80fc1e047059a6b62c802f0cae0231c09cf0f928fcd475fbd56667a55fec66ed7ff8dad786fd99b82d37efc9a8a2b0ff6732b429cdf72eb9292c67a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf00319DD114E0567e789def536C436fC0EDB9cAE": {
        "max": 1,
        "address": "0xf00319DD114E0567e789def536C436fC0EDB9cAE",
        "signature": "0x2c240ca3b7a89ace5a9692b1f62212d746a0b3372ccbc836f0c0bb5225aa7abb692c6d313ea27b7e85ef83d4e695521ea6d6164ecec6f7383a2da1a8a9217f411c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdbc4896dDeEd975Fc63c75EE82605B7878611B8a": {
        "max": 2,
        "address": "0xdbc4896dDeEd975Fc63c75EE82605B7878611B8a",
        "signature": "0xdd7a79c4f13a3ecce5f40f6a273922790ff6c14a3bc227c4d9e4276d724f698c7a8a15d3e46ac58e7702e3056dfc31f5721ab34fea7184b0db520405b3b18fb91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeB173EDeB07Edb7b3797Ed25B503667bD25B0827": {
        "max": 3,
        "address": "0xeB173EDeB07Edb7b3797Ed25B503667bD25B0827",
        "signature": "0xb4003835fb7398087ddab3f4ab4a55124a7ca94018499aca81f28238797bfcf349787b27f146e1c61d482854c699b239624e46fa39d21cdfe7afe935a96a252b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x61a002c7F723B8702Dc910D14e1d95a523a8FCCC": {
        "max": 1,
        "address": "0x61a002c7F723B8702Dc910D14e1d95a523a8FCCC",
        "signature": "0x527bdbd87264a6a3cff6d6d237c568a26fbb4ec9f09040c3de4f813bf42e09a644a647c260def3a9ac691736695fefb2a60ceb77b3f9a197d8278cf831ac8e7d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1ea34655Dcea330c799E9382e97dD834F9bF2dbD": {
        "max": 1,
        "address": "0x1ea34655Dcea330c799E9382e97dD834F9bF2dbD",
        "signature": "0x408953e59b2d0f3107a6f042c340dc9275b5aaf0e8fdb989b637398ebfbc8f1e1a928cb44a2fea4b76087e3f7327d079f391c0d1772071997296f3619d91a3da1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5489a663225FeE3aA5BB8426F388bafA3ff3482C": {
        "max": 1,
        "address": "0x5489a663225FeE3aA5BB8426F388bafA3ff3482C",
        "signature": "0x8b710a0d41693e47ba406c6a17842d06ab0301f34532cc75b274c84521720d16429d812a84a277366d8be6ae0d5f894378f0181c7cf453262ee3bf3ea2c565a31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7396d1c010Cb8c42be45Ea6313Ef72cb128d8F4E": {
        "max": 3,
        "address": "0x7396d1c010Cb8c42be45Ea6313Ef72cb128d8F4E",
        "signature": "0x51f02f4663738bc5b467e6cb3d487835ab055503ee492655301b450570fe09667e0314f9ee9117044fc808ea409886d0d5087acba298a2c09ebf809c08946c891b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4F6cD9b6682640d562b74f5132395e25DD00FC3D": {
        "max": 2,
        "address": "0x4F6cD9b6682640d562b74f5132395e25DD00FC3D",
        "signature": "0x8341920735eb964b8f6713e348070aab06e92b9908bc097bef16e12ace65ed864b293ffefe54f12dff9826e39d64365bc095a57ec59f2d9cdc6e538ba65675bd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1167f28a1C4e20156E6cB9BF0499F93040F6625F": {
        "max": 1,
        "address": "0x1167f28a1C4e20156E6cB9BF0499F93040F6625F",
        "signature": "0x152cd42058dc13face70b7f5156fa6dd45293373c470284076b421bc10e4261303f82b4d2238064ec11195a6cd69af0c3288808f53f47cb1562f321a53a2d8391b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb07DCE4a1E8784Ba5c575D1EF9284bf115A3204f": {
        "max": 3,
        "address": "0xb07DCE4a1E8784Ba5c575D1EF9284bf115A3204f",
        "signature": "0x29deb50de7d17aec0c06b7ab807edbc7ff137204f69c6e717d07269308053be843b6e9c82e6059ab2d18f17073cb4e95a3b41f74674bfec1b6a76bbefaba51201c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x77857e5938cAa3A233E3477Dbee1b4f36218Faed": {
        "max": 1,
        "address": "0x77857e5938cAa3A233E3477Dbee1b4f36218Faed",
        "signature": "0x41cb6fadcb13062b13948f90efd2c3bd0a44d7f44e1a2f6b3599250b4bfa039778756014fb411791db44c7a9d7e221a490efa5debe1529b62f11ce3c9cbaae371c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x998B611d05f3E2DFEa54b599838A88C118841e1E": {
        "max": 1,
        "address": "0x998B611d05f3E2DFEa54b599838A88C118841e1E",
        "signature": "0x7ba5117fe98e4999a7f8c3e4ef51c0591d4dc9afc0f27b4d9b7210321ed3c0f928b097c8423cfca12d4ed3f5b0082d011f1d51ce68b11d48ff7b23021bb72bfc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7eE1d1d9D959Bb516Cb6D22BF38847Bb1FEfC33E": {
        "max": 3,
        "address": "0x7eE1d1d9D959Bb516Cb6D22BF38847Bb1FEfC33E",
        "signature": "0xcb6e983deedd867ad0cc819fad4a15feb9072ce933585daa7ab8e8d4cbff5f5b2b9851babcadc690e6f06c27c5938d6fa93890a0df66ac8ca19a125ae5834f471b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2cf8f6648d2CE39fBb12a17c9eaD04bC92d678a4": {
        "max": 1,
        "address": "0x2cf8f6648d2CE39fBb12a17c9eaD04bC92d678a4",
        "signature": "0xfa42a4944223ada779d2a4324fb0ba4ff60ed78b1cda40eede66e56eb8d5a1722bd29376206c4568cbdd10558af61bcbd3a5947c105c100efbfb9a7ac007f2c91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x70070d4Ff9487755709e8ddC895820B456AF9d9A": {
        "max": 1,
        "address": "0x70070d4Ff9487755709e8ddC895820B456AF9d9A",
        "signature": "0x2c15a9f06c4f84cc42bd96fd4f25cce4e2e04a1049d6dcf428230ba4f9f9e9837728217852a915882b73d57e483c7bdc26044a18a953fc23eb6d5cb322fd55521b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1c457a373281Fe5fBFE5ec3BefDBa0d09f1857e6": {
        "max": 1,
        "address": "0x1c457a373281Fe5fBFE5ec3BefDBa0d09f1857e6",
        "signature": "0x6cc7c9c41fff5cd32b9066f88fa03b6488ab20d86078c4ab273ac8084ff2c5a4188b9e47a6c438232de791a209dddd95c0b377fef5db27ab7c5cdeade70198ac1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE395257d64BCE9e1795cC59Dd2A625930F15D28B": {
        "max": 1,
        "address": "0xE395257d64BCE9e1795cC59Dd2A625930F15D28B",
        "signature": "0x931f786b3b4092972973891aebe4637c49291e59337d8742f6ef7d07e2b6200e6e90b676737e8c162e1e463d64010ae1f5becf04fa92f9a82d99f7224f90cb441b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAF560180D08972Fd21c1de8A774AefBF5155df44": {
        "max": 2,
        "address": "0xAF560180D08972Fd21c1de8A774AefBF5155df44",
        "signature": "0xa23cf193ecd15510c35d7d74b9ba3ea3429f015a32f539f5a8261aaa3fad67b42f6d17237e25b1627c469527da8173efebca06ce50c507706bb2e9f8106562881c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x76a96f96255A7984dA77Cf36544F9206dF730fb2": {
        "max": 1,
        "address": "0x76a96f96255A7984dA77Cf36544F9206dF730fb2",
        "signature": "0xb01c8360fab26f006e518bb698172d79e3d293c268d3059161c1c3776095505d63a8f459f2b3c3f6e90a75b03d63b31d120681e85f446a5579d4d6da527211721c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa17fdD55B83694a0182C8A352Dd76Fc28e56618D": {
        "max": 1,
        "address": "0xa17fdD55B83694a0182C8A352Dd76Fc28e56618D",
        "signature": "0xd8bf12f6c642ef5bf3663a1f62160d6c2a535ac1eb863e2a890d03a8af1a6f9b6469a11d0f575d8980b1d48dc3656248f2127b93fbf46cab3196abad7d5773c41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0c3cE78970ebb2b8A8e8FCD99e0e5Fe4d2A9d56f": {
        "max": 2,
        "address": "0x0c3cE78970ebb2b8A8e8FCD99e0e5Fe4d2A9d56f",
        "signature": "0xff05ecf9e9fe4be47ff5ee552e610d0481734a6ebcb4818713174a312419968a3e0896d9fdc321021ede8af839a476a5b0bb8a8ee3476422730dbd8c192fe9d71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9C9E22B6d527a68279f8BdA25FdE4536341C3B8F": {
        "max": 3,
        "address": "0x9C9E22B6d527a68279f8BdA25FdE4536341C3B8F",
        "signature": "0xb7cd9499c0474b5d8af51ec79a6e6fd318b3be8585f6f487325cde88b7f213c05bf04227659a5242d6649af1883b5011a3d74641abb83261453b9d2f45d42e531b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x61E5980A0FF394C4946ce147cAEbdE772aeFBbC2": {
        "max": 1,
        "address": "0x61E5980A0FF394C4946ce147cAEbdE772aeFBbC2",
        "signature": "0xb9526afb28e48f1bac5cf21d3a11146f202d617557d6e94def3d623b4a5c16a65b26013e5877cefdb76d150fbe0b4278a81ccc2d72576dc4c9270c3860760cd21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x273974ccC85F0A650E1B5099868E6Fd63889BAC6": {
        "max": 1,
        "address": "0x273974ccC85F0A650E1B5099868E6Fd63889BAC6",
        "signature": "0xb99d5f6045163be5c811d266b3c139d0312e67806f334ff8475663b5692f1faa4aad4bb64f13e7ca0a17c8687bf018535e6061777bd02e903917f0bf2655d42b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8e5B4921dE175eCFf5FD8C73A4c8B1CA3666b0d5": {
        "max": 1,
        "address": "0x8e5B4921dE175eCFf5FD8C73A4c8B1CA3666b0d5",
        "signature": "0x572bcd0f41ee8aa62410e9641c43218ded3a8e48933e509f957638b90240f7d84980d5463933cdbc3793fd6975e8a1dfbabc1f2fe98a59b2469703e2f0a7b4621c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03b653e4850Cdf6F79e1E5A9361D3Eb16D18d437": {
        "max": 3,
        "address": "0x03b653e4850Cdf6F79e1E5A9361D3Eb16D18d437",
        "signature": "0x302838560b5dc304e249a9664329726b987c5154f869dd3d8bb10fa79a5d82026547b51315b1f3eff6737aaaa7c22d0cedf9c2ff678bcdacc9caf07e87d9073a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x48a11db8CfCCB0F14e08AeC935191f6BC203F3Ab": {
        "max": 1,
        "address": "0x48a11db8CfCCB0F14e08AeC935191f6BC203F3Ab",
        "signature": "0xb98a3e98203e756645081e0939fc7ea826c34f26eeaea03e857fd416bb1267114254a452faf35dc8e07db366cc590b8cb2d7e2cec5f3d2aad85b091c4fa41ccc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x83579a32cf3f4121D76E6fF48FFDDD4501112720": {
        "max": 2,
        "address": "0x83579a32cf3f4121D76E6fF48FFDDD4501112720",
        "signature": "0x5d1e784e7a038f6ff5bed1e62d29ebaded1405144c56144beedfdd6b6dc324f825ddec680e09202d717cd106efb019ba687681da88cf1ba4da99f67a1e93201a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7B8BBe991Cde89179Ad5398B95B4ed450F8295e9": {
        "max": 1,
        "address": "0x7B8BBe991Cde89179Ad5398B95B4ed450F8295e9",
        "signature": "0x7a72593ebb23655acdf1a11261add151e4446fe1f50033691ec41c2001e073a6536a6b8fc034f8d03770d55837b01b54d16959279e84ced036ac2c0f41ccd5d01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF55406D78dD136374C249C5A15c5442b1B384A4b": {
        "max": 1,
        "address": "0xF55406D78dD136374C249C5A15c5442b1B384A4b",
        "signature": "0x2653b76dc6e7e4b936b6dfa96b1985875986570eda8ff9abb0899522a4f50b4c129f783a1f8625bd32a8666bb7b62f84671544552aa2049540da3ffd158fe2741c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xab5dc2Fc69236e4De5568cd91259568af71a2de1": {
        "max": 1,
        "address": "0xab5dc2Fc69236e4De5568cd91259568af71a2de1",
        "signature": "0xefcbfec1ea5ff459f3661ae0b3364291e739281da76b3161344c8e2201ead4dd75f2b11cced8c96a89cd2d16c81f56818536370a97810fe08d215c91f23290561b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x19Be73De77eaddAFDE6a80edb57bB8B7eAc2F275": {
        "max": 3,
        "address": "0x19Be73De77eaddAFDE6a80edb57bB8B7eAc2F275",
        "signature": "0x44be3651d29c27b345933d25b957bd0aea3f6050a1e2ca19b8c8f2fcc4cce27a48d20f905b71ea4717557f70038519198f97d0fc20f4742628f4c33c796f48a31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2d6F93bD6A51fc86A56B75EB98f42f62D7f24902": {
        "max": 3,
        "address": "0x2d6F93bD6A51fc86A56B75EB98f42f62D7f24902",
        "signature": "0xfaa09b2e90df67f3be84b6def2532157d4f1ef257dcb39fec7a076a1c525e8144e4cb2c5fa350f7fed428b4df5c115fc2db1716362078ffbef580343f7e700ca1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf600aa98db82F5C27BFB7B871f861010b6B54bf8": {
        "max": 3,
        "address": "0xf600aa98db82F5C27BFB7B871f861010b6B54bf8",
        "signature": "0x234d348b170a018f3c3adf2796730dfda52791224457d282e99a813c719b4fbb2cead48aabbc22c4483d30d38619b0d04af8427aa98c395ea2d13473b4d91c1d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE0dB63339041b88D27C1c1c2c263CBAda1fC8Bda": {
        "max": 1,
        "address": "0xE0dB63339041b88D27C1c1c2c263CBAda1fC8Bda",
        "signature": "0x00bfd0412ed531644eb5607bd0e411a74c0b2a8d15d2dd54df6313da6643d57571259b17eb857a3631b97a09a5cd4801be4dd1e514a33e13b921e9d6f7cf79931b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD58CB9378b9587D745eAE62d1926219ac3767Dda": {
        "max": 2,
        "address": "0xD58CB9378b9587D745eAE62d1926219ac3767Dda",
        "signature": "0xb4bcaa936b76269a5f2739f513d07543fa949f2a2ae2c9f2f1bdea40b642847f5a42cc9663c6c555691cb860dc396eee905877588f92b991d7b721db85ab64fb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa28FEd1c717ba029Da21574aea041b378203bE26": {
        "max": 1,
        "address": "0xa28FEd1c717ba029Da21574aea041b378203bE26",
        "signature": "0x89feea85fc07803a50e4bf5e2b624b773379187613980f92c2d8c88d86a12aae2b7f78fc74ba78d8eb718008bd8d0dd0e9fb09c6fbbc91e100ae630f9c05c8371b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x896956b0cA7a8ec7aBE049c33e7996509bd3C498": {
        "max": 2,
        "address": "0x896956b0cA7a8ec7aBE049c33e7996509bd3C498",
        "signature": "0x987fd7679bf7389b3ddaaefde94ce04afa8def9792275f06ca52b46d960823032d3a4f1d88dbde62706b156431d7055780b89e957055421b2fb4417cf41f68791c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x642C7d19B7b2C28346B92e6C0ef4bdC85ad2d29e": {
        "max": 1,
        "address": "0x642C7d19B7b2C28346B92e6C0ef4bdC85ad2d29e",
        "signature": "0x68a3df18428234470b6cf6f449e03117453c102b967ec2cc231b30643731eedb7fbe35efddfa9a3e54bda12dd2eb9080061bdfc87ab4a9a89fb091a6fcd214e81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCBe6e0dacACC4CaB79eE2cC60E3C92B94812F211": {
        "max": 3,
        "address": "0xCBe6e0dacACC4CaB79eE2cC60E3C92B94812F211",
        "signature": "0x049de0cc3b00e861285301e57facacf35dc35b73170273be5b2de2d6846250746e9f967c33f427220dcaee9e50d3218268dcc89b67147e9745ed96a5b90739d91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd91906FF32aF7Ed780A1AB4fb2c8ECd5C8d08020": {
        "max": 3,
        "address": "0xd91906FF32aF7Ed780A1AB4fb2c8ECd5C8d08020",
        "signature": "0x2c61465f6cd0317bad0c047d7630e9fa9fd075e2a724daa27e76e81a552886f35ba8237d649a638ae3eba0d045f4acc61b270e4cca9c1d91379408a3c66f0a3f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD930E58c82141764d5649b649EF8E53Fbf49ca3A": {
        "max": 3,
        "address": "0xD930E58c82141764d5649b649EF8E53Fbf49ca3A",
        "signature": "0x64707eef93097cdd8fa68522b33e79a2ffa135643c9b01ce8a63516df607719350f853a7efbd4ac83a93b4b4fdd396e5e693e4351c6ec42459d193c7b8540f321c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe73424160AE3001555AcC7ce2E6ec738CC65E6E3": {
        "max": 2,
        "address": "0xe73424160AE3001555AcC7ce2E6ec738CC65E6E3",
        "signature": "0xb94771f9301ebbc08dc74f47ecc332883988cee0649d4656e54846c70596fc2a1be9ff34ec0cf023e1b7b0ce282a9544b0602fc0ce9411b21fc21bcb66d85fd31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBcCDA08E6bf490Aefa1d357aB2975151e75b0127": {
        "max": 3,
        "address": "0xBcCDA08E6bf490Aefa1d357aB2975151e75b0127",
        "signature": "0xc89390dc1434d8dbacd7744baae091ef802fe25ea5804de7ba712981b406691431e2e8c26221e93a4a9f5ce86c532ce34e23d0c1270c42082d5a8245b6ffec7c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6D5Aa13AA12006016B825E138271b464370fB4e5": {
        "max": 3,
        "address": "0x6D5Aa13AA12006016B825E138271b464370fB4e5",
        "signature": "0xb85f4fe789fb8aaa82990c83276ef78369730fc2dc5b23aa8351ed72c15dbf9e636568f2e0f538ed623d8925f30b72ed896b045faa576358bfc9045b83caeea01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x574E2C54Eb4542ad63d6238f672d7d476422372D": {
        "max": 2,
        "address": "0x574E2C54Eb4542ad63d6238f672d7d476422372D",
        "signature": "0x99f2828debbfaa968b49d2a5bd056f10d37e52e3d84fedbf421b92f64ff1aacf0b01433b5ea5fdbbc077f639c9d953ea491a47a646174b8994b1e6858b85b3ea1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1B9160b5d0059eCBA0Fcf4D63865063e468A0a2F": {
        "max": 1,
        "address": "0x1B9160b5d0059eCBA0Fcf4D63865063e468A0a2F",
        "signature": "0xe8e21b1b3186a957af8320ffd6c2ea5d2fa70262e02e21ccb883802eea6c35126b7a6a17d85cb784ddf7bb51d6e0bdc0504911131f9c900d5af7d5ee8241bb111c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x83d8fe37105f254AE782285545dED843d417272D": {
        "max": 2,
        "address": "0x83d8fe37105f254AE782285545dED843d417272D",
        "signature": "0x60150cb6d036807dc471d1d8f066ea6ec5bfa008d5e72e815164b31bbed990e729b5e13a1a1d6c19caf10d12a2a8e181f30b043f522eacfd775df57cfaadb68e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x10E28f6c91184d75FD2773dCD00959cB6C08AB31": {
        "max": 3,
        "address": "0x10E28f6c91184d75FD2773dCD00959cB6C08AB31",
        "signature": "0xe99633ddcbfedf87227f60731f4ea3797a0c53f78e9cbcf30b2428f59bfa2fd8483777d6e07ef04100925f9dccb0f448b4dc5c570a9692f4fc43e7f816784a3f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x79611EEd03B52b7A1B89b28a6ff7c40aF3e58B0c": {
        "max": 1,
        "address": "0x79611EEd03B52b7A1B89b28a6ff7c40aF3e58B0c",
        "signature": "0x52c87df54925f60c63f52ee321657c8eec2365a104db3397d3d97ccec05e9f9464eb439f86a7c16611768b5b42f2f79553c6ddb569c56b3b83846354e31d6bf61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3Be3f923d6F20E1CCDF3d517Ed22960269E8C703": {
        "max": 2,
        "address": "0x3Be3f923d6F20E1CCDF3d517Ed22960269E8C703",
        "signature": "0xcf489125982ab74b052c8ced82d8973a490145603fa65728168bb604214786e155fb8343977098009471b4f278d770ba4199ccadc88cea9a51d5a02d4d05c74a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0B90449f7423b45FE61815C733bB2FF9F8EE8C8A": {
        "max": 2,
        "address": "0x0B90449f7423b45FE61815C733bB2FF9F8EE8C8A",
        "signature": "0x8e559e9037cbe702e6cf90d6b5e9610213b7d4fde3c48d9e72174945912a8bc81c36a5479712ec0ce382685d2d9cd077efbf91ec04fce1efe41f828632f3eb051b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1bD1DbC6EfC1eD4a587296a6042DF51D2a3dd193": {
        "max": 3,
        "address": "0x1bD1DbC6EfC1eD4a587296a6042DF51D2a3dd193",
        "signature": "0x0f9b14e797300c0bea31aac854307e19060a1cc5e55fe667e0d058081a3b988f128f8fe3784cd56bc91e0a5fc525b9afa046c42733f2de2c80c622cdf6fbdf871c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6DC1D6F33bf91254146f3d8C71Fbcf50aeC9B715": {
        "max": 1,
        "address": "0x6DC1D6F33bf91254146f3d8C71Fbcf50aeC9B715",
        "signature": "0x9a27013891e1c829fc44865cd6213d25a286f1e4ca2f6b94b2d4fa3252085b017ee1a2ba0c5c80e4ac799000bd962f836d239eb3651d0cc86019ec26ef4a53441b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8e68EC6F345a429D3d3deB003e19109E726B8dED": {
        "max": 2,
        "address": "0x8e68EC6F345a429D3d3deB003e19109E726B8dED",
        "signature": "0x81f64df6f11572b3655b62f74ed0bb0770184fedb8eacd3d7f84fd1f8ab48b004161a271fbed29804e9a1e1884b5e9c44d2fa637b72178969a864dd631b8d2431c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC717063F994e7F9a73dC7d292a7E28e30Bb8913C": {
        "max": 1,
        "address": "0xC717063F994e7F9a73dC7d292a7E28e30Bb8913C",
        "signature": "0xf9408dcfb7872c969a0fd6aa1c35ec0f14242d39becd6ab189f934c588f898707f755bf68b53273afdad8630dda9d65383a720df7afb641224e50a4a1c978baa1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa4606441f58029aaCE9B51D770742dDd8516134b": {
        "max": 1,
        "address": "0xa4606441f58029aaCE9B51D770742dDd8516134b",
        "signature": "0x4a2fc10dcde83c93d89a2d794f92812a825dc724373264fd8b684ed1bf3f25b8694ce89cc67c7843b1de7cad30d6efe4bd0f53898c83c72f2eb53e6b16f0477e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa400E4B45e5aaf09CCd220199cc6E76B2F5DAE0E": {
        "max": 2,
        "address": "0xa400E4B45e5aaf09CCd220199cc6E76B2F5DAE0E",
        "signature": "0x0275f2874e2b792cd6827f8f4b5ad3519d3b36827b8f0effab7b0c7e474d58193e7588e7348dcd31df4d46e2c9c2e10252d20df338ee812560eef59f320c193e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x584Eb8Aa2D2b30726790F52A2F6500801Baa0aB4": {
        "max": 2,
        "address": "0x584Eb8Aa2D2b30726790F52A2F6500801Baa0aB4",
        "signature": "0xe91c9a4ceaddc7a173cb4276c7c0946a276f586fb517f8725f55eb85948bf99737369a9e5cb79cf71d6325f0010bbbdaa31c3384dca039b5fa9d33bf5fb7db251b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfB08006E2C8066463F1C2552cEb1E0BF5Bc594Ed": {
        "max": 1,
        "address": "0xfB08006E2C8066463F1C2552cEb1E0BF5Bc594Ed",
        "signature": "0x763c77d85372bdd135ca072f7fd980c2e74f68577334c5a6920ee061984e313b4b180da30c3039e10d7c1c5e53f8591c11fb21384cf1d5b148c173e576dac5ac1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9d295A8B75c006129e4771f419Cfd2702B885D84": {
        "max": 1,
        "address": "0x9d295A8B75c006129e4771f419Cfd2702B885D84",
        "signature": "0x781862f94c59fef6994e5152c0ce13d809d8172baf6538d9ac2af247001b92347a89ba21569c7857dd172695df2afafc48ac43461472f0b43e5a9970e57bb9411b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1c48A215f17d18178a567d2F299f39bB3FEBc88C": {
        "max": 2,
        "address": "0x1c48A215f17d18178a567d2F299f39bB3FEBc88C",
        "signature": "0xd2067151410d2e7f12d6e9530848fb7a3987cc4b5df0cf74eb2995a772d96602562f4747aa0bdcbca7c0bcb0e3acc8cfb569b170be4d0c2c26b2262c7b93f36c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCf5f7e67b92ce18c75551b19383527B271470f30": {
        "max": 3,
        "address": "0xCf5f7e67b92ce18c75551b19383527B271470f30",
        "signature": "0x5b28787c2b5f9cd50930c5a2108ef3191cd2c560dd2e32f47287cf7383b81ea70fd3e1dd3ad56f9a5aa7e28cc55a94f13ede5026dd19ebce3bc7c068bbc6db561c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3354D76A74927c8359c81026768003b0EdE99303": {
        "max": 1,
        "address": "0x3354D76A74927c8359c81026768003b0EdE99303",
        "signature": "0x5621ae977f84167258c92a9fd1bf8cad30750fbcfd24c3dd69c9483d7cb98a7409e460600e4c87c04ec3602f6356c9a16f59ab608f24798a6bd2aad2a1a9959f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAF10940Aae545Ad55a26dC5C61F29bef2AbCB6C0": {
        "max": 3,
        "address": "0xAF10940Aae545Ad55a26dC5C61F29bef2AbCB6C0",
        "signature": "0xe532cd0b19aaa7a189df3df5d8be3004381457ac16160f7ef4fb2d9cde40c58960a42b4b63261c363261698dc13f30e62ebfe90049798612629ed490a2636f411b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb4303328790BD1daA50adD887060FDDF6B4f3Dc9": {
        "max": 1,
        "address": "0xb4303328790BD1daA50adD887060FDDF6B4f3Dc9",
        "signature": "0xcc09dd8cff1fcee543a90cb76cdbeb8b3f7bc6f97962bb9b5558aea15a03a61c510ca5664882faf89206480b8ee797fcef0df33e913914c433f1ada7662584f61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1C57DdCcB31Fa0CF7b7fFDB12CbEbf80721B91f9": {
        "max": 1,
        "address": "0x1C57DdCcB31Fa0CF7b7fFDB12CbEbf80721B91f9",
        "signature": "0xa79f4e5b085729919a21ef545ab3000c4d3ed91c728b7ca69f8582e8c8178bb37ee2655f4811a6c1d417a9c4afa0b5aef2b03206b609febbbdde0d1505009cb21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x53FDa6d3ffABC8388B4c24e980F83FCDb1496e51": {
        "max": 1,
        "address": "0x53FDa6d3ffABC8388B4c24e980F83FCDb1496e51",
        "signature": "0x736fcd992c55b31c08d286b0d9d40998ebd7b1c1bb41c2575119b2e62ecf85784bb8a922a7f7d83929fd11e5c532b57a38774dc11ac19f3e4a795adc9fc16a1f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x090DEb24A178AD520f4C7575906f55d6Ed4597Bc": {
        "max": 3,
        "address": "0x090DEb24A178AD520f4C7575906f55d6Ed4597Bc",
        "signature": "0xbf5ffe06471f9a6c539c573b020fc512ac06f88ae3a916f29a008eba103be67b4e69190bef260312245111b4c6ac751c902e293f0ec6fec9caf8f6ed82f2166a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x91ae5443D036370E1c8fC6Edeb8E04F15C388665": {
        "max": 1,
        "address": "0x91ae5443D036370E1c8fC6Edeb8E04F15C388665",
        "signature": "0x476de447828af1369a35ef1875422925b6fa8b8f79cc19733cad54c28126482359bc42ce5b8995b92965c3bbde28767d4c717798412ed9c2a3ee9d5ac04dccdd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8F249AB3287c9E2cB0aC19A393c8af00DdFcA913": {
        "max": 1,
        "address": "0x8F249AB3287c9E2cB0aC19A393c8af00DdFcA913",
        "signature": "0x184e912a2750bc969eb34fc656613898e22e175b6a20ce4316111a8b1c2637420fc020c8190c169a87c2fd899aba2e494790228ba5102365e0f4c19534b771fc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa03AbEb0Be7A5cC44F5768ab525283341D35D212": {
        "max": 3,
        "address": "0xa03AbEb0Be7A5cC44F5768ab525283341D35D212",
        "signature": "0xa98a5c3d0401c9dbf4abe45111d319cfbdc3885fda50ec73833833c10330111829e1058a0934118bd5037942b1f834a3e6d9e92246aace624c8bd05e9281f4571c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbc5abc87881f3DD95C027204b08e100Bd1353911": {
        "max": 2,
        "address": "0xbc5abc87881f3DD95C027204b08e100Bd1353911",
        "signature": "0x59455dcf3b6bf0c21a5d14d032fed8f46f1f762034d38ff363c305be8ed6fc1835595da7a57014095a118d1bd12ba1f3d35d9896f4fb33112126c4cdd30a5bc31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEB4bf79853Df25bB1Da14ED67aBC618DEADD98A4": {
        "max": 1,
        "address": "0xEB4bf79853Df25bB1Da14ED67aBC618DEADD98A4",
        "signature": "0x6eb3ec001b50e8f7c82652fafa2afe546768eb9e2cd948c415ecb4294c24cdfd6b46bbd8bb40a9718b64445cf5dd74af3344f260828b6b36904180184859d23b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x395E7c7FD7c1A6B61a2FDE8F8bE6D51855Bb9983": {
        "max": 2,
        "address": "0x395E7c7FD7c1A6B61a2FDE8F8bE6D51855Bb9983",
        "signature": "0xb93049bdc5ddea025cb18698b077b02913500157925d8893bd45eacdf24f4621292a37419bd1dd62b5091c288bcd58d3966b0d55c2ee642ce76cea4cae85e0041b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb812dA58fD04867E7A6E88b494337e16D6ACD8a7": {
        "max": 1,
        "address": "0xb812dA58fD04867E7A6E88b494337e16D6ACD8a7",
        "signature": "0xc0abbb9d422abc870d1b03cdbdc9e0961c86730f97f66e28319acd1e415114be0806b4733ced19465f16e4eb9c9d1c0eb87daf884fcd5c127524ba0bccaa807d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x02593A69f967c02B3cceA9E80cf5EfE169FE470e": {
        "max": 2,
        "address": "0x02593A69f967c02B3cceA9E80cf5EfE169FE470e",
        "signature": "0xa6ca5a7d7dca174ab6d3c017b377b1bd774feb0afc2d691a4eda3ca53bcf5b0a7b6b736cb757a936932ea27f37af53ca6e2ce6263fa14b53ecd5fe8b35cf7b501c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x508D41B2fdB351B5BD74FEf575e18B9aA33cB2C9": {
        "max": 3,
        "address": "0x508D41B2fdB351B5BD74FEf575e18B9aA33cB2C9",
        "signature": "0x3ed8635ab1882570fa7325cdf98da8d04eb07cf2deb2575ea2c57d77685aa35508034a40511a47caed44e0312e5b24391560b9c07c742d53b96a91ffaf4ac8511c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x339399B830383C94f76343CBA6d2465BBdEFf4e4": {
        "max": 1,
        "address": "0x339399B830383C94f76343CBA6d2465BBdEFf4e4",
        "signature": "0xa0a3ba229e2da1b527a6ec0b100d22760af8aca6078ee110f4069cc8d16f7e235756ac9ce4f98dacf4a5e3dde1bca987008dfddb0b940967e377b8b4f373027a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc79050916C212e9DE6766D6aef09C2b4e523575B": {
        "max": 2,
        "address": "0xc79050916C212e9DE6766D6aef09C2b4e523575B",
        "signature": "0x278aa555f7d62a1d99f592b75867027b89dc62b1dbb6c9310ef194467a3d6c974d0c34a4e622969cb5c46cd6b82b25bbef7cbe6692a8bf2b143c6c05004111551b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x54E957506dB4340c100B5E95C9698DBF399E3F19": {
        "max": 3,
        "address": "0x54E957506dB4340c100B5E95C9698DBF399E3F19",
        "signature": "0x552f6ea226ff437a15c58cfd5cd01cb0be5fd03013bbcc509c839efa2a5ce96b05ebdc31b4e6c1908f3c85d47ebd040ead6e02e23b40cda0e46e0642230c01fb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1A7321b7bb00CBF284eb0A14eF8879105b73C4de": {
        "max": 3,
        "address": "0x1A7321b7bb00CBF284eb0A14eF8879105b73C4de",
        "signature": "0x7f39b901db9d380ea7c2e7e64a08af7bb1de9f544719651d1659060565ad911d4ead9db686ff02e35e2e62d379aeaf4ae703ac8dcf38cf78550ff52b02ebb2921b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc375f90Fa74737513b5874920958aeE7170F6B17": {
        "max": 2,
        "address": "0xc375f90Fa74737513b5874920958aeE7170F6B17",
        "signature": "0xe7ca2b8834cb207ef02f64295e0426bed2d245d322d0a860bfe07fc4dccc8dd1208687328e47be7d914c581c911d0e48af5a5665b56eaf732f25f958a197e8091c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5923659ddE20a78eE18Ae4ce539654cB6d32cd97": {
        "max": 1,
        "address": "0x5923659ddE20a78eE18Ae4ce539654cB6d32cd97",
        "signature": "0x092885b110f2cf422e6c11b51c586f2b5c14aca2afe251a6b285a6d1e8ecd73e12fdf79319fdd38d9b71001bd3a4d90bba71138ca7620faad1dac3009bcd59401b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcE2a6D6c3cc6d038F955f64673E1922756DAE4DD": {
        "max": 3,
        "address": "0xcE2a6D6c3cc6d038F955f64673E1922756DAE4DD",
        "signature": "0xcfa4f6d8fb4ab9bcfd35638180abc2941400d9c5e057bd27abd46eeafb4728d62d5d8e2b9aafe254598a4f76d9e0ba3eddc20e928a4fe82586c56c86494394c91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd8906d1afCa3bc473d9b91BB5209489636d4aB8d": {
        "max": 1,
        "address": "0xd8906d1afCa3bc473d9b91BB5209489636d4aB8d",
        "signature": "0xc129df59b2a9968862e9560785d4b8c147f3d7e2bad65885ab2739cc125b9be329634b7f03c3df50db15e602a5ce55cfdbabcc97a9df861c48919eccc98e63481c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6A7A5c9CC4780B12E1a2ab422202276A630DFB43": {
        "max": 1,
        "address": "0x6A7A5c9CC4780B12E1a2ab422202276A630DFB43",
        "signature": "0xcafee9800c3340f0a2fb6badbedbb7c74161af8d942d6a64262786eb7551d09a1323e00eb375d5475d4301cad266e46b781a25350b965991d6ac3a844a9b2bfd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0cF50c5C7233B84aC8644e87A27c1965419d6941": {
        "max": 1,
        "address": "0x0cF50c5C7233B84aC8644e87A27c1965419d6941",
        "signature": "0xb77e751a17a4db7af50b78590ba41bea19cd926086aca4b7e72dc7ba6e7ee9a02a35ea92644326bcee06f33b2a0d4734981aa3060fce1ed297cbfbab262dab6c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x28C20FbDF021fa19Ccea3f99327492dA8B6EfbC1": {
        "max": 2,
        "address": "0x28C20FbDF021fa19Ccea3f99327492dA8B6EfbC1",
        "signature": "0x357afacbcaa0627ba5e3e9ad0e348a729e5afcf433ee6d73c5992e6440fac25605470233897c8bc756811dc84ef77c48fbf9cf01008b811ac65939e1cfca24b21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5e580e1ABA383bAF78895783f9Ef1587C63DFA24": {
        "max": 2,
        "address": "0x5e580e1ABA383bAF78895783f9Ef1587C63DFA24",
        "signature": "0xf5cb7fa1be8b90e792067af40860a738e7d2def3f24aac854e146f7d4b3ee6185e155afb6b3c82e2b936e29d21bc2a4203222716d843a83cca138cb19037ad291b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCa1cB03E588094C7143D7A7270D8789E99aED44C": {
        "max": 3,
        "address": "0xCa1cB03E588094C7143D7A7270D8789E99aED44C",
        "signature": "0x861631272c204571c12cc244e5cb4fcb5008af1e6af950e2e2665096af5cce9242d44ad153b26036a3c4270613e1e0dd509ea132389224c697d41e672a502f481c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x745B8E12698B852f067a5eDC661AdCAB0a049428": {
        "max": 3,
        "address": "0x745B8E12698B852f067a5eDC661AdCAB0a049428",
        "signature": "0x7c2a6955265166b3bd139e6218de13a9ce9be82d12ab76142820605e591d07b243141493a9d31e62d62c27c1c05b69ac8b6f00f918eb9b320cebedb7f1734a951b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x22a6C7EAE21e6197d2F180673B8f939e0B2fAe8B": {
        "max": 3,
        "address": "0x22a6C7EAE21e6197d2F180673B8f939e0B2fAe8B",
        "signature": "0xe46fb9e7b1f53ab61246557d5d62afcf546747ba20007eea50922b85db6aabf519e7da04fc86d8fa2ba75ed3a1cd3105c0cea87a4f847e5b7d721de87d71f7ed1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4149cb9c4dcAd4137Ad8f06B008CCe5B9130BAaf": {
        "max": 1,
        "address": "0x4149cb9c4dcAd4137Ad8f06B008CCe5B9130BAaf",
        "signature": "0x1d993aa6ef637564f078320c4ff266ed3283f7ebb91bc78c75b4944e9fab34424c5058bbaf01124f91bc52d44a8e56a7811ac7bb23ca8e51285bce04f08784bd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb0171487185C5b78fFB6F8AFc83Aa05E767f9551": {
        "max": 1,
        "address": "0xb0171487185C5b78fFB6F8AFc83Aa05E767f9551",
        "signature": "0x349857c52e781ac35e63e17f42d4541a4301b65a8aa0cf38cc3a7b26653dcb6b77d0bcec9cfd436cd0645905590a7a7112508c86e248c704b0231536fa4ffd7c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb8012545AD5F04f597cC324cf126ba70e4882C13": {
        "max": 3,
        "address": "0xb8012545AD5F04f597cC324cf126ba70e4882C13",
        "signature": "0x0a0bfaa6bf5d5d680d36ae3c33228b94bf07560870986732ecd2e5b04242d2a724db718bb40e4ce84f886154517846eecb6d0c8c62c8884463aa4c46cc1ff6dc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x98fbFc01AA43Bf786EB1641fFc350988bec681F0": {
        "max": 2,
        "address": "0x98fbFc01AA43Bf786EB1641fFc350988bec681F0",
        "signature": "0x99125c0e90fec7475014ed4611b884bbeab8c3e4b626566c04395a3c665443cf7ca3b083a88e403d698a358e5983be6b9ac3d915a3cbdd20f532155ea92c1ac21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x957445d064162Fc7710D62489D1BEa02bd352F62": {
        "max": 1,
        "address": "0x957445d064162Fc7710D62489D1BEa02bd352F62",
        "signature": "0x695c465effbe7aa7fe4ff09af6a7127e75e96b0d41fbee874a6f38ee2d5fe5e05ca0df548d40a0046e5d58cd9adf95c1c7e8db176e73160c5b530ee23e7184191b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9b40f867c982F342C72041718cEf48D62Aa56E5e": {
        "max": 3,
        "address": "0x9b40f867c982F342C72041718cEf48D62Aa56E5e",
        "signature": "0x0c6e6d42f9fbaf7e6c626824efb05e6751454802cfc611fbb7213d7a6151e192607626f238db54cbd90a1fc0678f82115b2755aea141dfc3caaac1789313462c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x06443760b9FC204429963949e6F01645ccF81cAf": {
        "max": 3,
        "address": "0x06443760b9FC204429963949e6F01645ccF81cAf",
        "signature": "0x1a69833528905d48212d7ba8e4b88711d483f571f7a5df7b9ccb79db6942ec8b7147b9c16339c4fa332eca6970375791c0bc084d6269ce2a672f6bade2dd69e01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5103C0C72541034BBbF2518842cC8001c2cee383": {
        "max": 1,
        "address": "0x5103C0C72541034BBbF2518842cC8001c2cee383",
        "signature": "0x480b32030789ea9231d181c6cd9ac849642e08884e1c72ffceceed79b2b02c2c4c2476e005893d6f788bacb2888362021cb8cf1b17a123ffcc9ba3f1b02c250f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC36603788f3a558217347537271720816369DD9e": {
        "max": 2,
        "address": "0xC36603788f3a558217347537271720816369DD9e",
        "signature": "0x4ca426d4d9ba5b3db568d9b42874bf71cb63dbcbd6ce06af3cacc25302317d276947c2e6575f4c7efbe038d134d9caaf34081619dad1390c90e0f590b3e401931b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb5816179A6814B2291A1f078c67B104Da2F58E3E": {
        "max": 1,
        "address": "0xb5816179A6814B2291A1f078c67B104Da2F58E3E",
        "signature": "0x896537e92dc5f45a1b6533d4ded746afd3816cd5eb8eb62de87e1b9c9f8cc792691660d0bd647afd9e4be299a653e7ee1c408ec1cad572e0fd654b21ded6bab11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x63adB58E2f47F5E511d43A7FC8315174C530a78b": {
        "max": 2,
        "address": "0x63adB58E2f47F5E511d43A7FC8315174C530a78b",
        "signature": "0xf2eddc34e67e24e1895b68b6907775b4648af280ae336d6032edf07d005b65e35df3e7d5a85808ab6f73d10284be8c433f5ec2e76a0af41df3f786a1eef6afb21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa000cE1D36BCc3d18A7aDAaCF8381f8A99B5d937": {
        "max": 3,
        "address": "0xa000cE1D36BCc3d18A7aDAaCF8381f8A99B5d937",
        "signature": "0xaa02239f91d8395db2f53dbfaa0a97c2dd20bb9967d135543a74d0ff9edb7753570c3bba1bd7219cdfa9f9bf7207b354ce37086f9458c3fbaefc08ede419b85f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbAEe2C4A69dFe928523a9a3E7293D09c0699F002": {
        "max": 1,
        "address": "0xbAEe2C4A69dFe928523a9a3E7293D09c0699F002",
        "signature": "0x5d232d7082a539447c4080905e60f9c8d82ae1418dacede01adc639f97c5e59e5b30f70eb6dc3a126f751881df86420b87856415484f778f4aa4f4501a4dc0081c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbeB31411F94701D4D090E0B79c7CcE1EEf8c189D": {
        "max": 3,
        "address": "0xbeB31411F94701D4D090E0B79c7CcE1EEf8c189D",
        "signature": "0x05433f19781af358d865f9b299efb3a58960816fa7feba93d76f208b044ae9f72fe8f639ddf23fd94ceb87e698f6c9047f1b2eaca56f378105705e428c1f1d5d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xffC4C7896526105338742f74f91667D94199C1E8": {
        "max": 1,
        "address": "0xffC4C7896526105338742f74f91667D94199C1E8",
        "signature": "0x53602b754161e39e3df03a2eeeefe7ef136215142b756beecfe3944acdde23685983d7984b54046d4a97c15d5f08eb493f527b939d7b3379c88f5f23c8d007cc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7Fc732a92D826421517E97C1a5a17b010Ba44091": {
        "max": 3,
        "address": "0x7Fc732a92D826421517E97C1a5a17b010Ba44091",
        "signature": "0xe18bb0acfe6ee3202090d6d50f55603123ff81297d4afc22d56930cae6103783647145140d7c5e9d52ed722f14cd601433771cc68698f7560385e57413668ae61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDE31150f0485651A77552479B464659d8C274d7b": {
        "max": 2,
        "address": "0xDE31150f0485651A77552479B464659d8C274d7b",
        "signature": "0x8155a4d86bccf659d7e38220fd0bf2b36489dd956e816c350a2f30352d47a79614886babdc188553bf012e7d09cc00411dc5a0b1306fcc4fe26d22db73c2c8ea1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD01D69de11e35076d620c4D9aA59AB361D9D0137": {
        "max": 1,
        "address": "0xD01D69de11e35076d620c4D9aA59AB361D9D0137",
        "signature": "0x972aeb106ce3b057b40cdc1e11090210eb163d795a4a9fbcd88281111f833235036eb2f5645e6b55d6e1830f1aa1c7c3121c91b63bb62e8a56af2bb6b8d382711c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x804CE4E6277B2967394d1aD48D5E798b9271BB3d": {
        "max": 2,
        "address": "0x804CE4E6277B2967394d1aD48D5E798b9271BB3d",
        "signature": "0xc03af857666b89bc126bc67926016b9b5197fdda8ee5577ae0452ec8761d77a16792283218492ebb28d5bea6645b7dc4aabd5963e105e970769faa1afc208b291b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8Ec2b1f37331137F870412bb0660a1BD3B38C2D4": {
        "max": 3,
        "address": "0x8Ec2b1f37331137F870412bb0660a1BD3B38C2D4",
        "signature": "0x4dcd2f91bf1f92d69abbb85192fc16094b34b6160e641df7c28d7e124ad415e73656c5536017e4f27f15c97239985e370a0cb124243e4c8577448df1f483212a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe38EEE4d2edc6303930F51bd79cd5545f81b945b": {
        "max": 1,
        "address": "0xe38EEE4d2edc6303930F51bd79cd5545f81b945b",
        "signature": "0x91e1048d9d694572958a20a948b9f86f8bb27c916e4177925d3dfccef1d5cd2f7ff52e634fe892a17b6b14bac8790d9b8d167d5e745a27759e72edf3ac3160d81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x02D4BEf35A186396E7238d5Baa30518E0623B120": {
        "max": 1,
        "address": "0x02D4BEf35A186396E7238d5Baa30518E0623B120",
        "signature": "0xba35f31e6f105672a3ae21f741e9b6fba89c28e80972cf64a1e2e80f1ec205b670898346a750d8a89fe2bb275c947eaf78bd041010ca395f96f3a68dcae258c71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd1eD8082eC5fA2E6479AE2c7Dc3ad73EA1DF04bc": {
        "max": 3,
        "address": "0xd1eD8082eC5fA2E6479AE2c7Dc3ad73EA1DF04bc",
        "signature": "0xd3280c9197f2f250b6532df7fe352dd72bf800bbe5ecdaa452fac2cf129ed6ca6bc5d322354ad01e45d7c52c44e86639e101be033f722add7c3f1bdec6d891561c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1cc0E56C41c1232322BC661A800Cf361235690ab": {
        "max": 2,
        "address": "0x1cc0E56C41c1232322BC661A800Cf361235690ab",
        "signature": "0x82d35189f3455d63a31fe16b15483f7c2507e7b1e66e649716e82b0fa698c6f754962b7e26a8d041488d3fef37b85294d4ab0a5bd255ead6c1c5db95627c184b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0C80a1162A061d1c63743FEF56204c3FcA45074f": {
        "max": 2,
        "address": "0x0C80a1162A061d1c63743FEF56204c3FcA45074f",
        "signature": "0xc6445768aeeb15b6b5b9b804c70964e00163326eb122777112d3abd2371db7dd0acfb4b2c09e3446c30c2585250c8121cdbebc121e0b5cf6a25a4c5163330dce1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x49Fc31AE1274716B76f697888667BB5b793B6167": {
        "max": 1,
        "address": "0x49Fc31AE1274716B76f697888667BB5b793B6167",
        "signature": "0xdb3a550990edf337d4de4461d0850f9cc2d48750c09a238a62eea8c6e3915e5a0d3c8e2677c3120bf2f0c6dd27c38deb65b202de3ab321f6eb55dfdad56e19d61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x90694141d1184b42e4C8e316ca7e431631EF0c49": {
        "max": 2,
        "address": "0x90694141d1184b42e4C8e316ca7e431631EF0c49",
        "signature": "0xf8f8269698fdd148f32055b9b9f18ae021a1acaf34fd17c9aeb553f73c3f34286cc000db9e8546bc9a6dd57eff412f2833eb36f8939fb9b6d826fe134bd268371c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x21d5996BBC760627D71F857f321249B61DA96351": {
        "max": 1,
        "address": "0x21d5996BBC760627D71F857f321249B61DA96351",
        "signature": "0x75dfdd09c22b7978dafd5fa13ef455f06d2744f72b58cceabe2b97d5af8edde248b5098cd1dbea8b3d6984360699ba9d7472528daea004f4b244dd3c096d2d2a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6Ea45893e1C503B1bd8e7B575d5A1c87A7D5e367": {
        "max": 2,
        "address": "0x6Ea45893e1C503B1bd8e7B575d5A1c87A7D5e367",
        "signature": "0x3f24e10c7ad3559eaa17a04c9f379528957bb02334577e3d8b631d3799817f7770f670360b432c1fa2b559c27291856cd25e613ad0bb2ac0eb44fabbefeb403d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb1E3d17a180507B7488A3B7BD107a91Ad993CE61": {
        "max": 2,
        "address": "0xb1E3d17a180507B7488A3B7BD107a91Ad993CE61",
        "signature": "0x991782778ff70bdf1c15dfd557559dcb0df703ba166ffb2045111d051fc7a0635eadea7549c53fe1aa15f4abea7620b4c100df0bd597936996956fbb77856a451c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcD7ea49c3033f80A9D453d04c8Cb78e4D9936954": {
        "max": 1,
        "address": "0xcD7ea49c3033f80A9D453d04c8Cb78e4D9936954",
        "signature": "0x2f973084d038100fe6c8ec643fb21de8e3fbecb3cd6cc1f5cc83ebb1d2c9666053b0f7d9e9fabb49be6679108383f775b50a1a0bb059e4e47427a9c9b93a7a1e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x58F0bEF3a7a29a45312e3727CbE2DD01001A2124": {
        "max": 1,
        "address": "0x58F0bEF3a7a29a45312e3727CbE2DD01001A2124",
        "signature": "0x35417a403f13042f8a6e1d68e24d02660f2be7cdf46c13dbd7e3a06e1872f0f7386efed71dcd551a52343524514e4c0f1495dd53e3b68e28535f1facd818c2a21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe88F7D56556C03E6dC4EC7134972C27cE1c367e1": {
        "max": 1,
        "address": "0xe88F7D56556C03E6dC4EC7134972C27cE1c367e1",
        "signature": "0x9f65f5464f42dcf46dff2b3f21db665cc08fea2369db0435a70d7163d03d122a65a803c8193dcbc1e01ee8429af75f21008d000325788aa048b0240ff9413e501c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf95051Eb5D58e461803315C4e5A298611970E3b1": {
        "max": 2,
        "address": "0xf95051Eb5D58e461803315C4e5A298611970E3b1",
        "signature": "0xa0768fe2e44b7fcdede106a8a43f4c2635e908357bf1f0a34111b5b50d9f479d483a23139a992059eab493ada107e712e45af24e65c8ba105f1bcbde8b0a7c101b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x98Ec10Ad6d59Ad1BAD41f976358B7B5e82E400A1": {
        "max": 1,
        "address": "0x98Ec10Ad6d59Ad1BAD41f976358B7B5e82E400A1",
        "signature": "0xc5f5e737d95a8588770ed50ce6d405faa3069de1475c013cb3b5e0a6786f70055dc2e14ef45a8e6269a07b69fbf546dd94c0dbd318443578438da293873e6a981c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa7dB1F06f5107e852285C2cB77461aE4f858b97E": {
        "max": 1,
        "address": "0xa7dB1F06f5107e852285C2cB77461aE4f858b97E",
        "signature": "0xef1388d1e2cca2597b48b2bca9e605dd433b8b61c7607545b53cb293aba9cf5c46f19d0534c9c041fec9664a044530a0de560982874aa39b30830b482b258c2e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD6A400631e27C04f1E3353B71e921b518Ed7bbdD": {
        "max": 1,
        "address": "0xD6A400631e27C04f1E3353B71e921b518Ed7bbdD",
        "signature": "0x6cf7a7a199afd64827b4d6e02424c8e16cefac93d8105772cff4ff099157640a213aa1a6e9ade974a4bd0867feb2537e336e216cad1f45534300e6090704b1ed1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfFf9F1F85FB17d3C4b5cF376f6299cB63c757242": {
        "max": 1,
        "address": "0xfFf9F1F85FB17d3C4b5cF376f6299cB63c757242",
        "signature": "0xd60b613da7fca97f932da552f02817668d3f05a2c57b35c423b367022efec957505ae6d772a236d9d81e92e83b7c454e5e98355d59e182e834b8159062055a2c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCd429666E2ea15DAbd25A316Dc0f2E8026E98EAC": {
        "max": 2,
        "address": "0xCd429666E2ea15DAbd25A316Dc0f2E8026E98EAC",
        "signature": "0xd709d7e015cb6a580ce47f68a509220e21008f1a4dcfb5dec7d64598211c601c759d3753c6879c46c7def102db729ac4387fbf1070651b749ed369f0377b83a51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x852AceD2318de3b30b619fbEAb2fe67639772712": {
        "max": 1,
        "address": "0x852AceD2318de3b30b619fbEAb2fe67639772712",
        "signature": "0xcabbe40fe79aacd76e5c946d14e53d1b0c2382f4d1c490e2400ffcf049aa79bb03f35b52a91aee6500a5e26cb9be3e27f828fb304c1e15e701d7d1dde05a96791b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x23f28AE3E9756BA982a6290F9081b6A84900b758": {
        "max": 2,
        "address": "0x23f28AE3E9756BA982a6290F9081b6A84900b758",
        "signature": "0x5af80c268f495b84fd2cdbf17fb4ebd9efdd08bad7d168e873bb5954148c2c7003e27e9585e1f999d2e988ffa4138190fd5850f5c2ba16742caedf385cf740341b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3C40a3A82D059f58505fC0f3Ee75371EFC86306B": {
        "max": 1,
        "address": "0x3C40a3A82D059f58505fC0f3Ee75371EFC86306B",
        "signature": "0xc059ad16ea9a600e2d79e08070b5f7daa1fa2617fe80607ed372f8e550913b953b773c30acb9d07bff1c98782546891ee4bd4276810c25b012b46763f9ed51491c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x386F8AC6941C7dF1da475E68f2862A8AA035AF89": {
        "max": 3,
        "address": "0x386F8AC6941C7dF1da475E68f2862A8AA035AF89",
        "signature": "0x09f4546e6f993bd31f2390e596d2c6a46561571f268abb27a2df9e6a9b25d89f14e49664a1bd28ee43ac1e7de2d6f1a90df108fcd58df8cf1208769b396ab9c31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFCb49314d478fb81caCe751A73272F1bA1a15b01": {
        "max": 3,
        "address": "0xFCb49314d478fb81caCe751A73272F1bA1a15b01",
        "signature": "0xa7c242cfee83ba9ce7c8c0560c389f2d3ea1cf911a67470845185c6db73e5efa1bccff14345b86ffde3387b641bedadba5b5019ccff9f12d08de14f66d6375341c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x83EaA3797DA6D83f98B5386b2DF35b412954bb4E": {
        "max": 2,
        "address": "0x83EaA3797DA6D83f98B5386b2DF35b412954bb4E",
        "signature": "0xcc7f221ec00ca2f1d6e42736af50ca1167ac33c8e8d614fdaf1b8c5a2e981f4118229595c254271488b694b0e9204b2262bdeb535ba262392bbefd09463fa6881b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA671eee10663ebb4f238e4011Bbcd1BACacD4eB2": {
        "max": 1,
        "address": "0xA671eee10663ebb4f238e4011Bbcd1BACacD4eB2",
        "signature": "0xc6deb16890ddeacb2aa63a362adc7c8a6d6285649e40f1c26631e6de5fc8dca5492a7de2451a92976d56817923ce401c852b612de0a51bc397591108d12995971b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdBD85D3373cE7c3954bDDeE710ED1A682b064b07": {
        "max": 1,
        "address": "0xdBD85D3373cE7c3954bDDeE710ED1A682b064b07",
        "signature": "0xbf20b04e298fca84c019b7cc5de8c6625a68f6417f4e7db958b947a5dda8b95020414c4ab059651401f685d61d11583c35df5f111dc3e1683cbd61b47da7aaf71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB2285bC9703b4B20A13D9960ad93E10cfF9C3e4c": {
        "max": 1,
        "address": "0xB2285bC9703b4B20A13D9960ad93E10cfF9C3e4c",
        "signature": "0x5fe45085a8ec3a2043e721e519535d1c63b5b4b531e387d1c6932051e7f069e2447076a96390de8e0858eb9490a8542a461f57d51a2288513516a4973b9cb11c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB298dDf9da891D6d1De16301f09DE8945f223484": {
        "max": 1,
        "address": "0xB298dDf9da891D6d1De16301f09DE8945f223484",
        "signature": "0xd8dd6f82d48016d594833b46fe2136daf7a4caf96829eaa33c37c9defc4a120662fd6f759bc3a0676ddb85d226950a98774b78ecadaaad249ed91962e50b53ce1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x80894C9C28918626DD9A2cAb6387cc032cC38d8D": {
        "max": 2,
        "address": "0x80894C9C28918626DD9A2cAb6387cc032cC38d8D",
        "signature": "0xdd10d4fc8d46ade58142b48faddd541f5551fe6fb263396ac17dd2a02af64c2964d9d30b482ace368d5f3672733ecfabc055665bde5ca27f68606ee718be764c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x40a56411D9613bA6150feDd32Fa78239FcCB2620": {
        "max": 1,
        "address": "0x40a56411D9613bA6150feDd32Fa78239FcCB2620",
        "signature": "0xc9950dc213546e60e6ed9280981e8e0357c8e59e242dbd821b2264330c156fa07adbcbd59164dc58b48e5bd8df91f5074b0eade5c94d3af411e87b544a36760f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x71D3D0011c3Ebb3EA75e42Ed3F78E0E7d5c21520": {
        "max": 3,
        "address": "0x71D3D0011c3Ebb3EA75e42Ed3F78E0E7d5c21520",
        "signature": "0xc12b1f16d02aea1070975065a216af78de97eb67c0ef9a7b545e0b808eb8ebcd05998edd8704aab6ea70788311e34f49c0725b9b4f0f8220fdd0e18073f01f031b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb2E0bAd98d6e66a4838101af1E5a895E5FbB2aC3": {
        "max": 1,
        "address": "0xb2E0bAd98d6e66a4838101af1E5a895E5FbB2aC3",
        "signature": "0xf8dabc78cab5b326c34ea633554b2ae496b5c2d735981a19b6b402a86e433a4507eb68d2d0b2092c545c8666c3792c437ea5a2ad8bd959a1a1fccfb11cd9c34a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x21E0327eb942DD0d740949fF09A7A3727b6b53B0": {
        "max": 1,
        "address": "0x21E0327eb942DD0d740949fF09A7A3727b6b53B0",
        "signature": "0xfd99a985b0e505d6f269e699bfcdacc3e7f22919f2991e1716f08afc40c895761bab34b3c3f0df218d85a9f82e245b01c2ef2178db99ff413a45db61010e53511b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x817c4e605992530b358F7D858D26C15898b0C696": {
        "max": 1,
        "address": "0x817c4e605992530b358F7D858D26C15898b0C696",
        "signature": "0xcbd69d8d65a9c47ab62a769ec7af7034f116a46f071a4e7598dd10541e9872f42bdd3cb8ad96e2e03c1b4ea506d67cbe946c0f6e61555bc26293e89c207342231c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x08ee7296449EA937934072490Ec8681817320218": {
        "max": 2,
        "address": "0x08ee7296449EA937934072490Ec8681817320218",
        "signature": "0xb060c0edf052720db30427482cf34db057c354b59563a2a6514e8d175becc56375ad43603a1ea121d153395e1f63be547355761db1e7d78b1153c7a97bfd5dfa1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0fdFB102E8d081b8C0870bF2dFFff61f1834dA14": {
        "max": 1,
        "address": "0x0fdFB102E8d081b8C0870bF2dFFff61f1834dA14",
        "signature": "0x995081d1fd5ab6b0a226af3153e941b5baa2aa8d7c2c6912ee96d4ab9bf187f07c32a3bbce8f9012f538d67dfa7105967f161ac0e32ecd81097e1526ad5ed6031b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC7Fc8fA03ff49BF378951cCc92b76385a9B7e291": {
        "max": 1,
        "address": "0xC7Fc8fA03ff49BF378951cCc92b76385a9B7e291",
        "signature": "0xeaf85127f3c92117d3cb9e342a90e0d71ca25013f8ec9d4b9a15b5d0ae6d829f425de4dbda7769228108fe6c44b63f8da277607d3a4101a0d2d6180519d10f951c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdc295e925e84e155e860bCA1D36a68b88A604642": {
        "max": 1,
        "address": "0xdc295e925e84e155e860bCA1D36a68b88A604642",
        "signature": "0xec3b1c3298b4daccea81cc52db89c084c1dadda44726814daf65def6d3aa0f367c6281aaa1df140b08a9f0a24d7b60b2a71beb74e2e49fcf3336ecfd868910bc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4ce01528a41735658783911Ab954FEdBA1461586": {
        "max": 3,
        "address": "0x4ce01528a41735658783911Ab954FEdBA1461586",
        "signature": "0x57cc54dfc2ebf5d0d964ae388dfc6829890febd301256fa0983caf36ce5970350c1b56d6ce4d5d49c189db10bdb9128dfccdc768f536e4913199c8c88c3a2d631b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDD079cC159B66c3A4C64eBBE1555226913c4A1c4": {
        "max": 2,
        "address": "0xDD079cC159B66c3A4C64eBBE1555226913c4A1c4",
        "signature": "0xafeb44ca37f40b0402d6f7f4dff8c4c07d81ce0214d24fc437b8cc0b5e80677529b9e39ad976f4b09226a581de96f995fd54f774d01ca2cd360b6b4380cd2b651c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x25569ecB5a421bD57F5CFA11f665b7795C9854Bb": {
        "max": 2,
        "address": "0x25569ecB5a421bD57F5CFA11f665b7795C9854Bb",
        "signature": "0x7bfa5ff5af03ee89d8ce3c01480dc53d2d55ad48e8f8680dd5c828d3681ce6650361294a02b36a30b8ab4401ef793aad0ca0395d4ff578ad60ec33dd2cbde4b61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAaa42E5faa464BBA9AA79d8499BC6abEe139f818": {
        "max": 1,
        "address": "0xAaa42E5faa464BBA9AA79d8499BC6abEe139f818",
        "signature": "0x74ce247fd92fc68b886759895255d16c41d99f22602bee31c268cbdbb31fc11a566f48052d9b84259c7ce2897e36110e766cfef4110ecb13c111242e3e5353f91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x75Fd540272760d97cfD858b2C811140812Ae67E6": {
        "max": 3,
        "address": "0x75Fd540272760d97cfD858b2C811140812Ae67E6",
        "signature": "0x055894cd795bff4f23c98dfe5c3a92605629e619a0ac0e41b1d047be515b7bfa4f24e27c27bea6db15177ebed3e2e39081c83054db6df3bb03a9d999f50c77651b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x25bcEe39100Ea2E0d69E9DDDf65384EF9c1825CF": {
        "max": 1,
        "address": "0x25bcEe39100Ea2E0d69E9DDDf65384EF9c1825CF",
        "signature": "0x0e3e4e3b3209dd5fb2e208b14b2346566326416bcb61fa91b0d4698148fed13c63d53b3c2591092c7c4ce8acaee3c77593ad7fb42413e125481c1cae83da83951c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x30E6D54793CA55f03487f3f098843965e541EE73": {
        "max": 1,
        "address": "0x30E6D54793CA55f03487f3f098843965e541EE73",
        "signature": "0x22005ec1d3e3ee403c4a1f894c16061d1a287e5394b52cd608df034b22c5951a4e6e06343c46384c405b8b5ba21d47314097334ff4430939a3e55ac98d9b29ca1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9b7E156E8D243d53B076Ca659a623dC4f8b5F913": {
        "max": 1,
        "address": "0x9b7E156E8D243d53B076Ca659a623dC4f8b5F913",
        "signature": "0xcaef0756e52afc1b169379e88929f90d3eb12ba6c35bf437e96498885455cdac797750b4c8c3c74a1e7cb355134d70f3c835e971fe7efa1452ac8328b6b1ced21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x461e282D97e4ff3ADd54ea2b3Fa83051c60159bA": {
        "max": 2,
        "address": "0x461e282D97e4ff3ADd54ea2b3Fa83051c60159bA",
        "signature": "0x95853cfe1c271a57576cfbc3a7e890ef335f169cc9974e0701429e3696d8962331bbeb8f3120593f02bf6ccc4c957bcaea4c8e039c535808d4dc94a14fbfd5931b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb2D03B55ab729f21B8268F83b9d7cF913Aa15A65": {
        "max": 2,
        "address": "0xb2D03B55ab729f21B8268F83b9d7cF913Aa15A65",
        "signature": "0x217227dddd814aba27dba53ba89889f17dcb83863e6b49c736b4cde1c9f341b6721f4daee70ef942774898b8a3d09d437a3b513a4807fa38cfbcf5820f194e671b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6D4b80ACCA7AbCd6d7241cB4CbE57aeC0CEdA931": {
        "max": 1,
        "address": "0x6D4b80ACCA7AbCd6d7241cB4CbE57aeC0CEdA931",
        "signature": "0x5a7efbcbc2f12db2f8757c6c0d8a7de8f5b5ef014b4adef0d70ab036809ad955208b9dd4dd69e7686ee84b9773922f3fe908220a14e85ccc2f5cce74d6f95c551c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF608b9D96c924d97851f7314d6219be8799289C1": {
        "max": 1,
        "address": "0xF608b9D96c924d97851f7314d6219be8799289C1",
        "signature": "0x71de9a35f04f4da4b1feb8434f935c9135823ddfde87d3ff3122c215e82777a36b1e9fa695061e8d6a666cf924c3561dbacc0e5049f6c88868669597f07e08651b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xab69AEf1433a9054E8c9394095f4FCCEEd768EAf": {
        "max": 1,
        "address": "0xab69AEf1433a9054E8c9394095f4FCCEEd768EAf",
        "signature": "0xad7122d1d573fcc109dfe6465ad42570c5cc770b98c5c1e91fadbf323bdee5b908e9d40285a8bd535ab37d4031de810697dd10060e80f3d304059314c3d1c2be1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7E3509E75ba15C240E9E60E02C180a93Aa5e0c6f": {
        "max": 3,
        "address": "0x7E3509E75ba15C240E9E60E02C180a93Aa5e0c6f",
        "signature": "0xbcd5d5431d0e472d9ae853af0df76b4573596628f5ce2cda06a1189ff0dd638d32ac56f2dca302c43c8cb92edec2f2f5c5f36f64381b97ca53abf61f9a101c601c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAC9723a0Fe3194b9CEA56a7c72E94f1097B331b5": {
        "max": 1,
        "address": "0xAC9723a0Fe3194b9CEA56a7c72E94f1097B331b5",
        "signature": "0x2fbcaeae0f61c154c7aaade81a08c5a1c6b0fd5d3133718409a4169e0167bb1c4f0b890fb032f28cb1e408c31765d83f52e466eba0ea045b9b34406866f92c291c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x41135aA6845D2104b8eDB75d06a087aA82DFd8ae": {
        "max": 1,
        "address": "0x41135aA6845D2104b8eDB75d06a087aA82DFd8ae",
        "signature": "0xa6e6c27e7539dc264471baddb66794d2a256bbdc531fca357c35672ce0fb3f4d4dca4f05a17f7944863c9e7cf79f3339e1e9a9fb1b133af212a32c083d4355c81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBD4C340FBdA90CdbdFc0Bf51Ab970080662Fc1B2": {
        "max": 3,
        "address": "0xBD4C340FBdA90CdbdFc0Bf51Ab970080662Fc1B2",
        "signature": "0xa9754681b2e2efcb90b78327f0cd33f23d7250beb1f5932f4e6f9f6804cf99e624202cc560e9795586dadee8d090fa8e0919ef675c98bedeb599e7ecc331fc6e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeD0F45a8Dd340143912CC4D0a530c209073D1d3a": {
        "max": 3,
        "address": "0xeD0F45a8Dd340143912CC4D0a530c209073D1d3a",
        "signature": "0x0f597adc0917473064c568679f78fb07c9ef5cf033ee59cde3f01bab5941178c3944647afb35f95bfdf0203e1346d757f4c931683d2f8707641b2e32e899a0461b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA8B2305554A1b473Ff260c3052AdEE307E651873": {
        "max": 1,
        "address": "0xA8B2305554A1b473Ff260c3052AdEE307E651873",
        "signature": "0x510bd7efc7223333f4789a3708b9fba1e32ddff65852b981d4d57071393fa3502beb33d0df4f814ec6f656bc74d1227d902dd74adc012c5d8537a90fa2aa17371c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa8E50B40A7358d54e32f33fCCf293dB430C893a3": {
        "max": 1,
        "address": "0xa8E50B40A7358d54e32f33fCCf293dB430C893a3",
        "signature": "0x256fe1303f95a0e0c3d60eb4a12835cce825361c3a8633368fbcea3f1bd9e29419463afb435f1b45ff9c9308e7a70c42b729eb9ce94a000a35aea95ffbe5c8991c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x58097649bf57E5E40eB863a6f57c15729066c9Ab": {
        "max": 2,
        "address": "0x58097649bf57E5E40eB863a6f57c15729066c9Ab",
        "signature": "0x99daa404bc60624af94f305232f3af170e75d6c3460ff2f472d5c610359f9ce002988dd265e17f8703c32180db4f646a208f3c8025d41f90f01fde830f36ce391b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8c1FFd11b9cD30fa2237A9bc33d3Ad17157ACb6e": {
        "max": 1,
        "address": "0x8c1FFd11b9cD30fa2237A9bc33d3Ad17157ACb6e",
        "signature": "0xdae14a71db635e6e4df67192d5f5b893fb0664c779d5ed60415d982aefc9908a3d81abd1c5911bbe3c3314573869f64bf53268b7bcadbb6a85272fec66beeeb71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x99f6C14A7e16bebAf6fD75D19A161A9eC56e668f": {
        "max": 1,
        "address": "0x99f6C14A7e16bebAf6fD75D19A161A9eC56e668f",
        "signature": "0x4985aab4ae3c5e3bd203c4c9d1f857099890c233dbb90c2b980c7faf1169d8942b9aaf7a5e54924dfd13719df1f04a3f52c2c134188975d99b1d5abe513944f41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0Fe3D85F0a7F55049a251c0D6c27730A2466ca62": {
        "max": 3,
        "address": "0x0Fe3D85F0a7F55049a251c0D6c27730A2466ca62",
        "signature": "0x41d40c3ee2f1202f6bd026a066480f671ccab0b8029b4439d38d51df2a0776e812f22bf1b5873420531d0617e054871c1d23b9be9973e613f93fcd560509fd0b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4Be41113256eD707FA0e1D5e9f456f8044B6d1aF": {
        "max": 1,
        "address": "0x4Be41113256eD707FA0e1D5e9f456f8044B6d1aF",
        "signature": "0x326e42e2862bd47395822467e08639507ea6c164dd4aa867d4cfbb12e8163de9013619698c164544ac167ac0cc5fb4ca79ea19258e0c8354d8b2cd9331a356561b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x40Ecc780Ef74143dD23e48CA830929d081362C1a": {
        "max": 2,
        "address": "0x40Ecc780Ef74143dD23e48CA830929d081362C1a",
        "signature": "0x5706eb892f649b1b874b16355f3b0ba0b75f1f90432abad3b88a80da8e092d7e712c3a6072e23e248c6348480c2c8e1126ee2d012ba804108300ba6aee9e03c81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x13f4a5805B4EC58A3940EFE6Ac43f3B6ca28f1D9": {
        "max": 2,
        "address": "0x13f4a5805B4EC58A3940EFE6Ac43f3B6ca28f1D9",
        "signature": "0xeeff7330513ee54b0aa6d414dbb40c66451ce1c4eb9166883ae4f6919c5080af795fe73cb32c83237e6503b1c8bca45e6fcb26582caa8c771b1998ad399d5a4d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3A6f8f6d136B30b2af9FFfbe66D35aD503a58825": {
        "max": 1,
        "address": "0x3A6f8f6d136B30b2af9FFfbe66D35aD503a58825",
        "signature": "0xa871568333bfe3f6dafafb2e2c635b98d1e980f033723a155a97a1e3e471ac58299edeb1955b93e235f633d94cdb62606d6bda4ea55c42b417722e3ed487b50d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2f92340C9Eb337a6Fc94D9CFA28ad005386A1C72": {
        "max": 3,
        "address": "0x2f92340C9Eb337a6Fc94D9CFA28ad005386A1C72",
        "signature": "0xc5470368f6c82cfdb3c0d19a9a2658ee53b1c1fac8205da79c34c5e01b49e69e6a86f649b52d432dbbb7343b5960ca504404ec73a75ff5a9a9fbf83f193bcdec1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE82d2fB02B40886F25A299873b73cB2A4670786B": {
        "max": 1,
        "address": "0xE82d2fB02B40886F25A299873b73cB2A4670786B",
        "signature": "0x3eb4590c8ff9014883ddc0b3fdb2cc0368518d1d6588811e2c79038c3a8014ad1df76093b5cacd5b42c26e81691a77bf8bdac8a93cfb1afbd0efb7e89f6979bb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc906d78dE5612Ae7430b64c7914A0d825775fd08": {
        "max": 1,
        "address": "0xc906d78dE5612Ae7430b64c7914A0d825775fd08",
        "signature": "0xe9900cc87b757be17c035eae27d8fe586a9d0bebc41b57ae50432992481fdb2f3222f0f5806598598979dbf96731d290bc7ca6a7c9673d8380c18d641fd7296b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFd954269c62B3E0614B604CfE6Fb8aB97Ae9374A": {
        "max": 2,
        "address": "0xFd954269c62B3E0614B604CfE6Fb8aB97Ae9374A",
        "signature": "0x31e397f4c5a3dfaa9f66059883e27734348f1cde9771365c8f0e7a044e756a503995e336e90fb16d02fc8e456e5e0bf3fe9c0a5af28b2fe9a541c9c916e94ee21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7EadDF262e3299CDcf29ACefCa588D57f75eF564": {
        "max": 3,
        "address": "0x7EadDF262e3299CDcf29ACefCa588D57f75eF564",
        "signature": "0x1608f5e49918d71366dcdd31557193530288437f867d47cab330870c88f641021022f06acbfef3277164fcd2c167324d1e8a52a773adb3229db5dde9b15032eb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAF6AeF8a20a7115f479211EE0e3013126E6e8975": {
        "max": 3,
        "address": "0xAF6AeF8a20a7115f479211EE0e3013126E6e8975",
        "signature": "0xfd440c373c883cee984191d3ec9a71c9c924a94a444a601db00df7d712c16547360c0f8c3e41471268853f43486979264b3c9bf91ed9ee66fd36fad55f0eb1351b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xae8e14F82Fc2B0a8904e1F0a2b645deE90EfcE09": {
        "max": 1,
        "address": "0xae8e14F82Fc2B0a8904e1F0a2b645deE90EfcE09",
        "signature": "0x8252be084c449291a5d498e2ed51ded26004c0943aaec1bc891aa531bd349be8402d9f95b6e710b5137488069af3c6edfb834e0f8623146f7e470c5d83044cc11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf1b25c692f572d4E9290Ba9dBC6130DabAe76F6b": {
        "max": 1,
        "address": "0xf1b25c692f572d4E9290Ba9dBC6130DabAe76F6b",
        "signature": "0x2da8e74755da5cb8c7eecf754645a2f1774a626e066a584e4da6127f10bbdd6b3823228c5977fe24707d2bf854d52cf355ea311cc93e1fe2b1daccfe2cc1bdd81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe5227356aAeA9544f9cfA0233efFAd861F9670E3": {
        "max": 2,
        "address": "0xe5227356aAeA9544f9cfA0233efFAd861F9670E3",
        "signature": "0x7e2096593b54907c460be6fdb440eae499211b6beb7574bf91e12727b8ad90d7122682d47083c8f6a55f35f9cb3a8a31fe314569062b9d28890383c8fafbfece1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xABc5c08b084f021a31d99830a3264CB65df42Aa7": {
        "max": 1,
        "address": "0xABc5c08b084f021a31d99830a3264CB65df42Aa7",
        "signature": "0xb06d92d168b68e10fcff5b59d7f7f474c7dfd686e95ef99aef00342fb68b9a51765f7085d8363db54c1a6f1532bf22e6d8b4e54af7f68a52e5cd26ae7c05d43d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x329d57a7Bc333a57636225997114bC87eFcfC8E0": {
        "max": 2,
        "address": "0x329d57a7Bc333a57636225997114bC87eFcfC8E0",
        "signature": "0xec44604944b5e0a609e2ea782e62ce79406e8471ad056e2851012f78e732af162c05663f62d10fd1a56182a04548b7fe44b89000c9d3e1b9efe2ab16a532c6ee1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x213BD10026afb69E4B4FF204BAD94e07E90BADB2": {
        "max": 1,
        "address": "0x213BD10026afb69E4B4FF204BAD94e07E90BADB2",
        "signature": "0x522a777d7d2a4a93dffb1dab2ae680c4af31582054712f8b2388bd9a78ca0ed52b14cdc2fac775fec7bc180c6f0a3a16fbfebb749274a49a93119ef8599022a71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7FDC3aD1C76860D3684f855DE2BD9E2F41371173": {
        "max": 1,
        "address": "0x7FDC3aD1C76860D3684f855DE2BD9E2F41371173",
        "signature": "0xd281b71a9edffade855f0fdd2ee74c668d081cc9a551a7d27d77d1ad0838627737d731dfcd728110e5747f1b5f1b1f73757ac5b75275678fbe3faec901ed21031c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd3747ABF8729bC1731081bb12030F502A0D14c7f": {
        "max": 2,
        "address": "0xd3747ABF8729bC1731081bb12030F502A0D14c7f",
        "signature": "0xd3dc5051df7625e335e7d1484c9e8806dbf39e81f40eb40d6e1aed70e3a6dfe879dddf67e88160761fcc72b81a8ac7d6daf07e90fb0530ba1a77f9598058e6631b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x159d605A94DF6B64e0A4332d6AAEeA08a06cc995": {
        "max": 1,
        "address": "0x159d605A94DF6B64e0A4332d6AAEeA08a06cc995",
        "signature": "0xe878d3be13acd05c9813b33ef2a39fa82934a848d329a60bcdbc76d3cc2db2d443bf5b491606ae55971fe73b48ce3c2f42717adad0e634ecff4a879b2154e96e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd386c406356B231c7A34F37E6E9E24a59E592a8b": {
        "max": 2,
        "address": "0xd386c406356B231c7A34F37E6E9E24a59E592a8b",
        "signature": "0xc1203032c2a1f7e73a92149247c51259a5a953dcf348db5671b70e8919b4fc2b6888a4763090d5dc2b4e7eaba7ee11dc075a10e335e3ec788dec414f2f5050341b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x30e764166986C811ffFBE21407982314fe31B690": {
        "max": 1,
        "address": "0x30e764166986C811ffFBE21407982314fe31B690",
        "signature": "0x4d61e4a54b10ecd471a7d418d20eb7c9d78210f4d61321e2b02d3fe5d99cd6a20123f083e2762c90a99e0fdb4027f40bda702ba4851a9bdce5dd3fc8ce3782631b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0Ba85e868277F6113de68e179bb7aEcb5efC2E45": {
        "max": 3,
        "address": "0x0Ba85e868277F6113de68e179bb7aEcb5efC2E45",
        "signature": "0xd611962fb0b370e0d312b1041ad94962509db7f329fcdde8c1db212acd93a9fe312519769d08914b444f0399d6413de3be8180a768c11c5ebfd7fef5830ba4c31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0e8DFf089deB65e592e6B7Bc6FE2Dd9aB255205F": {
        "max": 1,
        "address": "0x0e8DFf089deB65e592e6B7Bc6FE2Dd9aB255205F",
        "signature": "0x6b5b1881a6c1983cfe00e360c26f5a655cb7d063fc60152e0c3dc3ede763bf0f5e30013d5d98f9f9a5e5c2c4c0b8222fa79e1ed7412e1ec48c6470a941d56fc71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x48539e6b46d6F12B463E4d636b6Db913Edb533D5": {
        "max": 1,
        "address": "0x48539e6b46d6F12B463E4d636b6Db913Edb533D5",
        "signature": "0x0df440a4b6cf2835df690948f99e377b9bea68dae7ec7fa82bf1a94ea27c52863f809aa80d916a3d340ef60f516e398940fe77ac9c880208ad971609e8c1436c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfCB0637C8011F1fd55DE40FaFDB7C26c40533083": {
        "max": 2,
        "address": "0xfCB0637C8011F1fd55DE40FaFDB7C26c40533083",
        "signature": "0x6677fdd0c6f272c19f1fd6821a6780055b0e6b4015fcf3614a872f3a40c4fa306433c56f9f7a3940c9bd0dae437c35293bb6c702671987d17a0eda2f50deb0601b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4C8aE1b32901977bB61CD106AB3baA319EFdc055": {
        "max": 2,
        "address": "0x4C8aE1b32901977bB61CD106AB3baA319EFdc055",
        "signature": "0x96d031a40985d3b8cb6b10b626ca07a702a7e9d26c6117fdf54df1e33319183c4e6768ca89c04fb178d914bf2667a578efd9afdfd59da5c489818cdd69a32fa81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1D836fE7A2cD3A1d6eCAd12c6D92A05e375772be": {
        "max": 1,
        "address": "0x1D836fE7A2cD3A1d6eCAd12c6D92A05e375772be",
        "signature": "0x029890e5a83963d0dd14c590703dae5e1a21625a4f20d46f210b9f336ddeecd9035e6451952a5e1d5e3f0182e9df024f4334c84c759992f96cb4d54f67b605141c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x94d012Bcc71878805D232Cc8cCdb924cFDD3Fbf5": {
        "max": 1,
        "address": "0x94d012Bcc71878805D232Cc8cCdb924cFDD3Fbf5",
        "signature": "0x1e569b77fd29a3873adbcf00e5daada6c46cf565e558e05afd8386bd53e05e4e7b1e6a0bf71d0ee3db8c9fdcb10ce3c31cb4b83672d106fcfd5f37a8e48200751c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAe34De3C0F93073990f1A820a75931A3650527CA": {
        "max": 1,
        "address": "0xAe34De3C0F93073990f1A820a75931A3650527CA",
        "signature": "0xeaef2522041d37fe6c0688794909201ce4b807c3d9735c14f0d4c3cb0f149afd36ff3d9bcba3c72459726cda888326ef3994ae4cb5efde5c19a4ca21cbba504f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB7a85286A59F2b2aBdCAf4F3acfF39303Ad8579c": {
        "max": 1,
        "address": "0xB7a85286A59F2b2aBdCAf4F3acfF39303Ad8579c",
        "signature": "0xff69361b66cbde26f0af0b82a2cc5a6006d08f03bb5415373f516945724331756744ed372dcd89b7d04fec8fb03b07fdb144fcc5698b8693ef490ab89ea2efd41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xde32FED26EaEedE2F568f4fEcF9C12d0D1B747C2": {
        "max": 1,
        "address": "0xde32FED26EaEedE2F568f4fEcF9C12d0D1B747C2",
        "signature": "0x8830cb75d790146457323a5f00ffd1e5ed7a2643661969ff5cf0cdd804870ef73c4c63803bd1e644e8d92d360ca9024e370a21881d273f2287c01a62f9f20bd51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB682EF20dE74af004cC2C418DCDc5cecEAF06D1B": {
        "max": 3,
        "address": "0xB682EF20dE74af004cC2C418DCDc5cecEAF06D1B",
        "signature": "0x0695ee351eaa830be351c52d3a9018e39a1e44ac586e5bf27fc744a0665a15f43bf8e5abb18c2b2325fecc360d8b352e09e5dfbeef6edfe4a71f272e31c4a1871b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xca8bB66dAe4c8452F35AeE563d2b8436634a08a6": {
        "max": 3,
        "address": "0xca8bB66dAe4c8452F35AeE563d2b8436634a08a6",
        "signature": "0xe0840f1be6a23e12d9ca0a56cd6561ca4846ce827824baf8761980fccec866a602c2c981d39c91019b11999ff37a4da21e1b91bc89d19624e1d7f7c1ec3e70ec1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x83C1BAF7DE0f9846F0faC84FaB2B3242E43C25A7": {
        "max": 1,
        "address": "0x83C1BAF7DE0f9846F0faC84FaB2B3242E43C25A7",
        "signature": "0xfafcab1e0a326f08a24aff6fe0c691f051aeeda7dd08612bb8936d69de77a8fb5581dacfcee8c3e401aac73a829c4c0c105db18ad4ebf9983fda27e5dd8a6f5f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2a1D553ef95cd5EBF700D8f8dd77abBb309717f7": {
        "max": 2,
        "address": "0x2a1D553ef95cd5EBF700D8f8dd77abBb309717f7",
        "signature": "0xd7d03549ca62b9daf8c3a4fb3bcd2010c13298817a8149f7f22df51e4a322a0a4520a3585e57b16942657790c8acb5fd3e1d959fa16d634abfc36006309e7ba91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x28910617E9991ff9702c9DD479D19F28613dd48E": {
        "max": 2,
        "address": "0x28910617E9991ff9702c9DD479D19F28613dd48E",
        "signature": "0x77a2f1905be83ae4c5baacfda67ff77082d55c1d19e17713da9316efe99fc2cc06015f2d8251dd44f47cdbd08192cb4d48ece0ba112d6ed2a50bae59ba640ede1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x565B3bB666772a8ccB0285652e93d6C79597620e": {
        "max": 1,
        "address": "0x565B3bB666772a8ccB0285652e93d6C79597620e",
        "signature": "0x0df54bb2c9190dbd2af6d01a2e0a55c75d24898904c448b56cabe95d4ae6f87f0376fdd25933afe412c2074f851562459e0aa1de22de845cbdb247eea9f190381c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeD3Ff3DE2Eba234eD98e0E20C4040531E0464B65": {
        "max": 2,
        "address": "0xeD3Ff3DE2Eba234eD98e0E20C4040531E0464B65",
        "signature": "0x8d3e9932f5c9e422971727363c1568ea2f1fdb2f3b9213e04535c0427e8d48e30b71c03207a0a85593fdf2051785e5cccf03200286a473f667392d894f87ce051b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x709a788A407e6585df718abb39F96773FB80F050": {
        "max": 1,
        "address": "0x709a788A407e6585df718abb39F96773FB80F050",
        "signature": "0x4bd0cf9e584618a71a745a11e9b0aeec07c79858041bc0a074fe9186d7cb41b61256ce80abb1baf930c25d8b75981e07987a7c0818138c138e269f1a34de62641b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC414eCb86C8B9091986d9FD08312dc49d21354e3": {
        "max": 2,
        "address": "0xC414eCb86C8B9091986d9FD08312dc49d21354e3",
        "signature": "0x6480d5017ce4a88073ea9fa4a08ae025216b6f2b5365768bbea2cff5049d3c5950d27edcaddff0e774d4f7c81677fed464829d7845fb513786150ddd76d0e5f71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf04B75C46d6a95F9327ebB80024ba244eD66ABE0": {
        "max": 2,
        "address": "0xf04B75C46d6a95F9327ebB80024ba244eD66ABE0",
        "signature": "0xba67aced24f34fdab1628cac3f9a7c31890383ce98299e07ad9a2dfdb10ccae94d4313b874f9dc13a19f574a501ae6054eea01c50077b76dbd6eda3cd3cb1bd71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5a0544700526c8D67db369F89FefA1DD208B2D08": {
        "max": 1,
        "address": "0x5a0544700526c8D67db369F89FefA1DD208B2D08",
        "signature": "0xf6911ef4b7ccc4bc58c9e346aa4032be86231870e34adc87ab17980c3182cc6561c9502593b800f79e1e490e3d7cbc1d2d1e5bc76cc8e1b0c6a36e4ff9f3d7b71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1539CfEBe16493924BF8913c8C94Fa3E78832748": {
        "max": 1,
        "address": "0x1539CfEBe16493924BF8913c8C94Fa3E78832748",
        "signature": "0xac44407ecc32c4a73443db557c7a4f1bb7d8eb1e0628ca46efd94b02cda7a85939237c58be61f45678f06ba48192b2b71d8e9bced291a9f2b62743bfb85251011b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x27C7Fd93669a395d39F892Fa83b8ec1aB1A2feBE": {
        "max": 1,
        "address": "0x27C7Fd93669a395d39F892Fa83b8ec1aB1A2feBE",
        "signature": "0x93cbdc961de91bd68a25570710918f383b7c2ce283fb9ad19370bcafa169fdc254ca3ce865692bd64a740cfd60901572800773b349776b4815451fe7af1e83f41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbea2014BDA7b632c574763720Ee7708c92356407": {
        "max": 1,
        "address": "0xbea2014BDA7b632c574763720Ee7708c92356407",
        "signature": "0x49b4c15907e914e3667f1a02f80f55d1b7764d4990b390ba3770cdafac11b01500ca9566b1e92f4d3b11f8d86c6fc6c7a496eb2a0686a6b7030ffcba42a5b7841b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6698620E2Dc780D52B8b514CACB1B7D47A7e7b52": {
        "max": 1,
        "address": "0x6698620E2Dc780D52B8b514CACB1B7D47A7e7b52",
        "signature": "0x01472dd10e77ef91c5315a1cccf2c03bc0d9f0fd9a820b841bdbde3191b3cd710169049ab0cae958e84533e4c4609d80915c66cf66cb983ab43182817a97912f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x91B6Ec494352bef817c4c2B13a1DF91706641981": {
        "max": 1,
        "address": "0x91B6Ec494352bef817c4c2B13a1DF91706641981",
        "signature": "0xd7c2b6b77953874ddfa6a5fe938ffa08e4f1ff4596228ed001773816ff111b6a0d413c8db1db693b2e228f1113488d5ff888568cf97aa447808042d4436877381b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5F2B9194F8d8f7800F65590Db370362fA386D8d9": {
        "max": 1,
        "address": "0x5F2B9194F8d8f7800F65590Db370362fA386D8d9",
        "signature": "0xfff9c246733393ddf77ea38349bba6b6a1e29d52b2af09b62d893e575f5d3a3662f2170c099797aa52e8e0c4cdb3af6c3f1e20b79f2c8a64bdc75b0bece176321c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3157DeD4D5C3985d62AD5Bf9c49dC973AF7dC748": {
        "max": 1,
        "address": "0x3157DeD4D5C3985d62AD5Bf9c49dC973AF7dC748",
        "signature": "0x6f204a70816d6c3b52592fdaa0d88900c25f366487f94c898f90110c91ee82f7242a5704fe12d97dfe65f4ee7948725f5ec7b0c3020054c430860b497d210b881c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x847a3C33D75793A41363e55f2174b2560aAE91f5": {
        "max": 1,
        "address": "0x847a3C33D75793A41363e55f2174b2560aAE91f5",
        "signature": "0x6cbf9ca27babd675c524af9f5e11d38d6b59a1e451a5fd2229645133366d184d649c98aa8809f12f056ac55a533959bda13af0225db0b105602c9486011148561b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7F7d125DdaAf56259246c83784a2275B08928319": {
        "max": 2,
        "address": "0x7F7d125DdaAf56259246c83784a2275B08928319",
        "signature": "0x023e92a5a75fc1f6008cedefb0c0c5d9d5988c7aa32d6f89d0134274d8f2714f50ef23b52575062200260158d8badb2922c802eacb7e3a7b73e75b7d538edc381b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x74307301ee4Af8341457f353996F64Df8A382362": {
        "max": 1,
        "address": "0x74307301ee4Af8341457f353996F64Df8A382362",
        "signature": "0xa0d7b301421f42e0807e501bdb1e93a1eb1cd8d19fd5fa21a7f41985481e16cb1da8b107e3facd6f8190e1077e33e6bc06dce37504063174ad6d9842d8d67ab21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe05E83c6Abbc89C70c7dD57050F42E2A4103f20a": {
        "max": 3,
        "address": "0xe05E83c6Abbc89C70c7dD57050F42E2A4103f20a",
        "signature": "0x6b86cd2ad5fbb97f57ddcd5f74c1570369b3386e887ad3220e9d6df1ec178de4746f07fbd7fbd38f152afa3c57ed10f45e6c61e7913a0752c3d4500e497cba991b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe16aEBd13CF9ea4611b0bB6995bFe555f5a05804": {
        "max": 1,
        "address": "0xe16aEBd13CF9ea4611b0bB6995bFe555f5a05804",
        "signature": "0xb57394741cf58768481be619a9ce5f3e751d5f02c29a7f57ad6ab9870701f89b3c5329003efb0fdb6227c38e99bd439f10cccb1a1fe5fca9bb3b8c5fcccd1aaa1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4490034f5891B6b584d78151E94674c517B92470": {
        "max": 1,
        "address": "0x4490034f5891B6b584d78151E94674c517B92470",
        "signature": "0x5f89674853fe436ed552d8ceaa1f69a207e775b50e778fb702ade91dd737865d33103c13b73ced550f30e6dc54a0e4db6e9dede0b615f3950f82097c38ba99af1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6B19b1af385e2eC8D5fa9adf52b057B1c00A07fc": {
        "max": 3,
        "address": "0x6B19b1af385e2eC8D5fa9adf52b057B1c00A07fc",
        "signature": "0xdbd746fb3a267f90ba65ab942d85ca2e3a5286edac9567521c940fac4889047227b5d4720992300593bcbe16af077c8a3443896efccc167ddf2f6e941f2466b81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6d9834a574561e10BdD0c22Ca17a2D9196A1513b": {
        "max": 2,
        "address": "0x6d9834a574561e10BdD0c22Ca17a2D9196A1513b",
        "signature": "0xa5eef2dfc1fa892e52845dc7e29351ce0665ad302d4d033a5a80c22d4ec8249a06e2ce63be328bf3da94ae74660c1d24895f033e4861dca9bc74bea65fc8295f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb662460837E50561ebf83B52453507Ff068d6AC9": {
        "max": 1,
        "address": "0xb662460837E50561ebf83B52453507Ff068d6AC9",
        "signature": "0x517d6e48f35b24d37c72f10fc68d27c74c25ebe09ba0d6ca479ad90d6d8d40332027c26b16da2073262313d83b14aad528ea9896b49b34dbe2aa4b6754ad3c4f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB4944cF80CFd324a0aADd62bC074D426d6Cc38cA": {
        "max": 1,
        "address": "0xB4944cF80CFd324a0aADd62bC074D426d6Cc38cA",
        "signature": "0x3f40b5ed7c46b454625f7175b263686687cc3448598204d77160d02abeed7a6530df58d7ff4260b69b49cf72a811942f8a0a4c5f2181fa3e555a7abef0b6c7401b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03feEe9576b1298ca29F7fEd08a28bFd0A72fFd6": {
        "max": 1,
        "address": "0x03feEe9576b1298ca29F7fEd08a28bFd0A72fFd6",
        "signature": "0x624f97e7ab1ca65610e4a7e4b11d59ce791900ea847c6c2503feb3a33378ed566643c9876fcc8b6b7aad52a8293dce8dc7c80456c0a6af91482a53f149ddded11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9e7b0f95d369A5b7F75150A439E33D2a802098dB": {
        "max": 1,
        "address": "0x9e7b0f95d369A5b7F75150A439E33D2a802098dB",
        "signature": "0x14c0866dc1a1f3f4f5a55cf65738bb7833e4bd4488edb60ac5c33dc0c94b7b051636d5327e5c9bd2ddf67b031c07644a31a0fd6751ad7babffb0b5d36ce0838e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9bF6649f180b28bA60aBe887De111e9A4FdE93dB": {
        "max": 1,
        "address": "0x9bF6649f180b28bA60aBe887De111e9A4FdE93dB",
        "signature": "0x31afa277d4abdf3ef3baf83583d6f1e46a7658f9bb7cabef2a293044aa5b53d40d2867ef7e6fe3002da368c2c6c0746dcea6051a82c348f0d418136419cc07eb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x505e41c67fd4A1845345ba42fbDb032370b1944A": {
        "max": 3,
        "address": "0x505e41c67fd4A1845345ba42fbDb032370b1944A",
        "signature": "0x954ae1a564390e132f9f6996a0fa56661a6cef3daff6834c4888c36c6c1884ac2c08e73ebb181698d8ccc1eabd5bdd90447818a6443c462379e9ed5c6833e2621b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2b1B60e1f520b2561885F51EF6160D854846b3B6": {
        "max": 1,
        "address": "0x2b1B60e1f520b2561885F51EF6160D854846b3B6",
        "signature": "0x07e66118bad525326972c04a2456aa59cd5763372a11f6da4113bfc098ebbf491e23c19684c32ca874c2999cf454a4de5869017c76dd67b7d12aa904924ee1f91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3Cceef8100242eccf161E3A7f0D1A117223f40F4": {
        "max": 1,
        "address": "0x3Cceef8100242eccf161E3A7f0D1A117223f40F4",
        "signature": "0x8d8355460308a7aef9e43fcc1788d9cd5c9071b17d975523100a31e6068c882906d462b8b5c8073aad8cff95d719af0dd5b2a3d0f7198c72a58ef07d28eb9a211c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaeaea747c3Ed7f312C0A8Ae359C4B36456c6C5Bf": {
        "max": 1,
        "address": "0xaeaea747c3Ed7f312C0A8Ae359C4B36456c6C5Bf",
        "signature": "0xad25482c08b4e24f1c21cda8cc604fdd9ae859a8b34e8b19c5aba1cea0faad7401fcd29301a1514b3186b62635ad7b47703bd2abd1cd393922bf698278dc34fc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x54edB4138Da6501380E2cd232b39B604d9a21AE2": {
        "max": 3,
        "address": "0x54edB4138Da6501380E2cd232b39B604d9a21AE2",
        "signature": "0x1cff3ad3a3b8c510a2019c4053fe0ca9266ce532ad35a10ebe6afe363126f989371941f503d680e80c780633fb81d0c3e97324906ed8dc91eaf12af59a42247f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6dCD7dFFbDFF67dF6D98a5dbEc7980d25abfded4": {
        "max": 1,
        "address": "0x6dCD7dFFbDFF67dF6D98a5dbEc7980d25abfded4",
        "signature": "0xdeb37cfe6c9925e057bd43ea95b2364bdc8e517504c2231e4761a9a090754ce61229d8a641bddc6b77a3c2ec8a0d9f59de069a6f83ef0768e49aa8730c0c9eba1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x84Df5F066FAD302dc6D5BA364C47BFb3de0F1256": {
        "max": 1,
        "address": "0x84Df5F066FAD302dc6D5BA364C47BFb3de0F1256",
        "signature": "0x7a6154838cf94d2474d406ff908bde6664dd15a440467f9bb0eff436e65861dd7a5abcbfbe413eac925d131b564b60fc040a011a74ff634949e032a5e322f5d21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0973C2f4B41A7206a23cf5a871797244277730eb": {
        "max": 1,
        "address": "0x0973C2f4B41A7206a23cf5a871797244277730eb",
        "signature": "0x8b18bba12a2e4efe64f72fd1586a13120068e0aeb43e2849a6bc2cc482950fc75563c7ecf7afa6359accbf67b9495f93d860542e8507368201a364a47e7e581f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x31262CF4E302805353e84a6Fef40d1F5fFbC93A0": {
        "max": 1,
        "address": "0x31262CF4E302805353e84a6Fef40d1F5fFbC93A0",
        "signature": "0xcf3bd0b20b5e9cb7c72605c190af5354aa03867a8ed696801eb716c2421563703063d3cc6223f0240492e8eaee924dc872cd31d01016364a92ae5342e617fa341c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xef42503a73159A5D6A1F503F8f254692B74bb09b": {
        "max": 1,
        "address": "0xef42503a73159A5D6A1F503F8f254692B74bb09b",
        "signature": "0xa0b2798a2a89f32f1c5139f64c5b632521c6e19ec8a8926767ccd951b38daff1328cac1fa3ffe4e14a6a1b0c9fb278da53d1f77d512818cb6b0654508d100e941c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCeD0F74bd0ce17aBECAc7a8B041Fd6029EA75De9": {
        "max": 1,
        "address": "0xCeD0F74bd0ce17aBECAc7a8B041Fd6029EA75De9",
        "signature": "0x6553016173465b29db64ebc816054ade5716170c0a583ba29f52c1210f8ff1dc31bc3920dec84cfbf801b75b4c737fa0a0129aca7e31ad8642f19f1fe935ad871b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD9E077E066125d7C97d57241b4671bBe36c7a861": {
        "max": 1,
        "address": "0xD9E077E066125d7C97d57241b4671bBe36c7a861",
        "signature": "0x9fba24d3dd20ba78250ec1829db3f4c7f3efadd0b273dc020c72eef980534e9f21e86f6f33f9a0c147be325e49e377a73c04ccd8df15ec443665b5d0f09fda081c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4282213c956b9Ba62F56a0Cda0dD9179e7A6F410": {
        "max": 1,
        "address": "0x4282213c956b9Ba62F56a0Cda0dD9179e7A6F410",
        "signature": "0xd032df834dc862996a9af26146c81cf7a2084dbbe8643ccd6921734a871c2b424e2a732d8cafcb0ce74886852b0110b682abec62dc9aec2f76c91ecbf47810a61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x767FdfAAC0D01878D2ceC9148FE8585C22B1A1E2": {
        "max": 1,
        "address": "0x767FdfAAC0D01878D2ceC9148FE8585C22B1A1E2",
        "signature": "0xb30646650271aaf417c6ee74173044e86aea0bca383c74f373e5f64bb2d1680331db8705396f6fcf51a69c7553e5c8187622cd25292e6e90c28b300d685f0fd41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB7865bc5BBA29A473889eC8097e0950182122664": {
        "max": 1,
        "address": "0xB7865bc5BBA29A473889eC8097e0950182122664",
        "signature": "0xcdab6b8c29864f32bec0809b15e59ced53e14695e8a2cff6994dba2d949c6e1461be6157f59f199333cd880eb99754b375de992e24ef5045b9bbbc094a4bf0581b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x155026cF3D32957a7fC4bf6e0e076659A7e4529f": {
        "max": 2,
        "address": "0x155026cF3D32957a7fC4bf6e0e076659A7e4529f",
        "signature": "0x209b450ac93f5c0fc9d6ff99cfbe8c8a57ba46336cfe052de56a6e0f80524a420278009892ada330eca76eb9825400c9b0a4cee70a7484e61ee7c09b9a6b7de21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03F4b7178aE3308690E49b3202Cb5377bDE6D64e": {
        "max": 2,
        "address": "0x03F4b7178aE3308690E49b3202Cb5377bDE6D64e",
        "signature": "0x4597b24bcafabd0086d117b167d021cc95439435eee4b2847cf710e644ab95ad14d36057eeac5d9d29d044cac6025ade9d20adfee42ee389005388cef2a764251b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2Fda6b369677BC0637B27b276125683359d1C995": {
        "max": 1,
        "address": "0x2Fda6b369677BC0637B27b276125683359d1C995",
        "signature": "0xd7d1b6db06ff653f7cbb2853665a1e0f2cf5c697f6b4edd266ab1c6c2b3ff3c75c632db75f7d0696a938d73719bc8238acbfa2fe905ef6cc06e6738f15b0dc3f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3284C43B42D0acDB07d934210C5E8De03085d2f0": {
        "max": 1,
        "address": "0x3284C43B42D0acDB07d934210C5E8De03085d2f0",
        "signature": "0x0a7dc784f8433a7f2a476cdc7841d1022cf566661b1fe5d85bcc9e31b290a83d71e6e47beeb551896cbe08944a8ca382567175d629c103ade87ca597926032521b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8707D5B6d710927d0edC87a097821C77b84D6823": {
        "max": 1,
        "address": "0x8707D5B6d710927d0edC87a097821C77b84D6823",
        "signature": "0x70fb1c1368b8e2273dec9a6033f476258ac3a52d9847c8791e578699aff273ff342376e1918fef5f361a57bc3032fb90d0262ebdc201ab1ab872653022eb04381c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf089e6AFA48B595BCe067E32EC1084a8287409C7": {
        "max": 3,
        "address": "0xf089e6AFA48B595BCe067E32EC1084a8287409C7",
        "signature": "0x0960d704aae0326e46c707416e3ec8f966e889159063d1962e52648fc65186aa18bc003b22fc4130e0b3398e06327323ba6b1995d74faae05d35e5404e6e44871b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x21a4B38788C50b1AbeCf407ce5fcc2D09FE76f33": {
        "max": 1,
        "address": "0x21a4B38788C50b1AbeCf407ce5fcc2D09FE76f33",
        "signature": "0xaac07afb7d6295f453e6b9483d8db31f2a2f91b3cbca052c7d2d450f5a18c4455fdb6f6848ba772ffa0fc13980ac7ef55104f40184315ed2b5fe750397459c4f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x89542B5f2DBC163a2CC748e1F668E1dfbE7AD1C6": {
        "max": 1,
        "address": "0x89542B5f2DBC163a2CC748e1F668E1dfbE7AD1C6",
        "signature": "0xb332251442012761ec39606ef04d793d248f75f30b7924edf12ea2c001fc5d7064f923e867bec87968c36a13cdc5ba563451db5f0b3f702ba44722ab70fc372f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7cD4FE4AdCd9De1A1c1256d87840bc808f87FA57": {
        "max": 1,
        "address": "0x7cD4FE4AdCd9De1A1c1256d87840bc808f87FA57",
        "signature": "0x96cf6b9ef35fc2ae9806909fd982a854acb2d0c0987c24bf65581c23ee2124ea7039b9294d9081b44560bb98d87b4fb78126816a7d363db52c604a9f703a25c21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x30c98Ab8FB66212634bF284F3C13b1e1fe61B3CE": {
        "max": 1,
        "address": "0x30c98Ab8FB66212634bF284F3C13b1e1fe61B3CE",
        "signature": "0x9857a9408ee8045a8320bb6cd75071551e0354886577885bbbda16d281d1c18c35a455b02c7834c1ecd53aad6ff129e59df712b18cf87b8e06dce7080778f72b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x65A20e69246D47dC201590222A15F459ad886409": {
        "max": 1,
        "address": "0x65A20e69246D47dC201590222A15F459ad886409",
        "signature": "0xcca5b004b436565305d26bd338b014ee4ae11772ec4d4fda76e911c3943ce229032aa9db33961243f143673db0ad71b5a6f906a16718ecc6743468c14a1875f91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa7552C4a1b57258C79E020ac74E13AAc0f89Ed18": {
        "max": 1,
        "address": "0xa7552C4a1b57258C79E020ac74E13AAc0f89Ed18",
        "signature": "0xba8840c4d9e32a9f10cfb319f0a2b02c12e52957e30df89d2df0d4e7498df8b54699dd34d15013f7be87778b1d22b251a640266967aeaf5100a68b600deea52c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3bb4bC94332B0185516AF99D880EF1d826aFfDC0": {
        "max": 2,
        "address": "0x3bb4bC94332B0185516AF99D880EF1d826aFfDC0",
        "signature": "0x3b5630d254aad57a1c54c08ad12e38be8e7c59ae6a79b30b14473795af3a3e937829ff0f5bad245a872cc36600306c2395d4f519024374784f3625a769bf1ac11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x25476a94e36452B5c80e1737e82F59684957a41d": {
        "max": 1,
        "address": "0x25476a94e36452B5c80e1737e82F59684957a41d",
        "signature": "0xf51212c57aa9dc4a8aef4ae8265128c19717fa40524c173295738ad7293277ab3816ee5f4506b62029ff2b81c7f406f8d20a886dab5181156f710ede7acc8eb91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8C5598aaE9A32df22E2714c46f3898D0ded0b69C": {
        "max": 1,
        "address": "0x8C5598aaE9A32df22E2714c46f3898D0ded0b69C",
        "signature": "0x805f592e83c0a7a45356d26dd8d1cd0fef860e3d9a563f574e005708b672db8003a98c3963313aa1878694675e8ed2a19f47e899c2122f48528b49d0157f507a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfb18473b7Ae5FeEACcde894FcB55C849f0afBB85": {
        "max": 1,
        "address": "0xfb18473b7Ae5FeEACcde894FcB55C849f0afBB85",
        "signature": "0x214e14b7f815757b2d1e355dec065856e4cce731f4976a0b1a8332fe947da93e5855fc965e610570c3cf0868e9dbc3b91c73951318b8edec60d43e4efa57fd6c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF5C46bbFF0bf633297c02494edB770b86E2d7045": {
        "max": 1,
        "address": "0xF5C46bbFF0bf633297c02494edB770b86E2d7045",
        "signature": "0x57aecf7acee202002c4a1e83a0320ce40e06107a3c9869d4ec42e4202d284c134289bc9db96c927ec68635081abe03e9886ea2d0c69ac2989d3312f7536710ec1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x037C29d38001032Adbb4c6454ae28aeeBFB48341": {
        "max": 1,
        "address": "0x037C29d38001032Adbb4c6454ae28aeeBFB48341",
        "signature": "0x2962555793cb83e4a32efb08bab61137dd3f516c776816c7892fff6b4f520bca5a3788bf5943639ff085273535fb2a1373648253caf8e31254c9c6817c1cce681c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x36D88d1187Cbdc924E4BdB8eDf9C827083C92393": {
        "max": 2,
        "address": "0x36D88d1187Cbdc924E4BdB8eDf9C827083C92393",
        "signature": "0xbebd528cf1c1d1ce50cbfb4181915a7cd376c2ef74ab8516f142844fc1df74c918ef500e951b24c6940254b07fc3f9a0c88c6f3f6d3002aa3094e1c17e2b86571c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x90f1fd5464053F2A500642B6E0D407CB18e8b6bC": {
        "max": 1,
        "address": "0x90f1fd5464053F2A500642B6E0D407CB18e8b6bC",
        "signature": "0x18c720cd3b5c05c359f13fc761e90d8de2feaf719d93e70ef5c3bf3d6ff4a20e6980fa267a7b5aa6c373b8eb0f70dcc62a30533dd3c88aaddbdc61781b8e9d3c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3C681aEcE2e47494a95faD6cA526C21F74467F31": {
        "max": 1,
        "address": "0x3C681aEcE2e47494a95faD6cA526C21F74467F31",
        "signature": "0xbd0fa4ea4906214e93fc4a7ec2b1369d05665f0528a60aea59c916d51236295b315cc06c65868a3456f98b77fabda11583723fca5664f1c9ad733c57d8fc665a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAbf29c808d00dA6E573a7903dd55Dadb9878C0d7": {
        "max": 1,
        "address": "0xAbf29c808d00dA6E573a7903dd55Dadb9878C0d7",
        "signature": "0x9bd9ad83a4ce5b90c5a920f8c5a56ead437f7fec4478cea96a4b6a9d265d5aec74fd3ffb479c3a260cc73140d79bf6b4fdb623ace43698b7246acfec9917a1461b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x926FB37146ADf8a5aAC139E142E3029F087d68e4": {
        "max": 1,
        "address": "0x926FB37146ADf8a5aAC139E142E3029F087d68e4",
        "signature": "0xe82ef53b449b35d6e4e25d5d45091b4c4abd9260d34ab83c6dd00bdaef2d6970293ef00962c7bade7cb9428ac27b22c0a9a16c48c8451313847eec3bb71505df1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9f1A7c3C5aC03C36B8d58a78854a3109c163f6A9": {
        "max": 3,
        "address": "0x9f1A7c3C5aC03C36B8d58a78854a3109c163f6A9",
        "signature": "0x1cde210c94bb30b72cc65ad90acb00f12175d7d6a525c87289f95830abb9d4ad3f6c2df949819711c4b188a27b9d26c8a8dd5f1a3f58780b934d574e1cf3df351b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA8363C98579752c531ae5C616BC5DCb0Ed072C15": {
        "max": 2,
        "address": "0xA8363C98579752c531ae5C616BC5DCb0Ed072C15",
        "signature": "0x7e2d4b71cb3078679fdd0c95b6a4521fca93ad33e4213750c22f7b41b97b57436637d9d2fb74a31a07d8b65b2526879971211aa1cba243d9189101b2b1e18f921c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x646eF0b780dbDbaEA2d8a44B39AbCD0DC44B8363": {
        "max": 1,
        "address": "0x646eF0b780dbDbaEA2d8a44B39AbCD0DC44B8363",
        "signature": "0xd9cf62684d0c1b44fc7aa5e304d24444d28480aa8289defb430d59bcd0618e967e831a6abad32fc77f2622ec61ba61978826075934a310105170253357e44c2d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0Ce8a7A4dD77aE159520B4F6f9B5079d2aBe390F": {
        "max": 1,
        "address": "0x0Ce8a7A4dD77aE159520B4F6f9B5079d2aBe390F",
        "signature": "0xe15f5eeb4b6ec822f81f22be19f923154b6ff15fa74a1b959ac2da8742175b45736fee39383f16daf4fe3de0f45db2fde3bb7a044468b5fa4f50e5e1c657525f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe2daA8a682385A160fE0685ACdf9f6f0BE968fcC": {
        "max": 3,
        "address": "0xe2daA8a682385A160fE0685ACdf9f6f0BE968fcC",
        "signature": "0x1a82570039264319084f5e1aa3ef8c1cbed5c56ed6f78524c5e4eed91b3672c95c800234396ad97d95547428190d2d7bde540de3447e92d60296145bd84918f51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x066Af8d14A4EA690403c5Ac5bE8Ba9948d9BAc9B": {
        "max": 1,
        "address": "0x066Af8d14A4EA690403c5Ac5bE8Ba9948d9BAc9B",
        "signature": "0xcc4fb41b26bd1b9cd863839a8621c8c421a29357897b12b88cab0724a0a9db521e5023c80d1df1da9c5d711e5286c5f3e79287b085788cede7b75a8a3710b66a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8F63A07B390410E59B92230C7a11c9371F43b8BD": {
        "max": 1,
        "address": "0x8F63A07B390410E59B92230C7a11c9371F43b8BD",
        "signature": "0x74a2b9d5f146de8cad262dbed2e6c2d45c8b974d09b07434e2de9786431e43fe75834ffb998de0764c17693cf82757ad315403498c88d8a83f09fb9128d1bf541b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x08924f908484eA57EFe132C0dbA1924Cd1B9eE7E": {
        "max": 1,
        "address": "0x08924f908484eA57EFe132C0dbA1924Cd1B9eE7E",
        "signature": "0x1d28dedb0745e7829eb8fb13cdbd7dfcca7d48326e451a831e3ea0a9ebe11ae755d3f2ffcbc71a0fc0d4fb0ee6519c470b8f25216614f595a6bd3cad2c92079d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa8B896601aC4dBFdE6b29E5C10FAa7beEA1317B0": {
        "max": 1,
        "address": "0xa8B896601aC4dBFdE6b29E5C10FAa7beEA1317B0",
        "signature": "0xffe40523a3a959e2ae2f288f82b5894caa63cf41a3bc89541a74f90727f82b05678c4f3967782501464deb54ff327e04ceaa25016ed6934153ac2c0cfbe7c81f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5aF3eCc4BE55c97CD10A72ea55dF83e89A0c5A29": {
        "max": 1,
        "address": "0x5aF3eCc4BE55c97CD10A72ea55dF83e89A0c5A29",
        "signature": "0xe31bc9dd86f024ebc250dd54c1faf2e3cf0daf5e6431486ac4d28a0ea70917d9753e32f8aab39438e704991dec77e206b8a9ed797da633aac16b802ff529de2a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0E7110d45E847629116cdA4BA184560a7d40F14a": {
        "max": 1,
        "address": "0x0E7110d45E847629116cdA4BA184560a7d40F14a",
        "signature": "0x17c362838bcd866bbad237cdbb9f43468d830b24866560e3afa291063bdeb95d06342997ef462ee7e6528ed5181466c57fa7d43d65f2ec7a79e40c66891ccb4b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x43f5BfaF9FdcBB59e13f20A7664EA94bCB0FA577": {
        "max": 1,
        "address": "0x43f5BfaF9FdcBB59e13f20A7664EA94bCB0FA577",
        "signature": "0xb8b38fd2364fb9030e92b92068d74a6c1b1ee029606f9ccb3a6823b03c25527022c8208d3c3bb9486069a8293ef0eefe4ec9beb42226e18e39d3aea4d75bbd691b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA6065dA86288C0A79BCD9DeA123CB582335714A2": {
        "max": 1,
        "address": "0xA6065dA86288C0A79BCD9DeA123CB582335714A2",
        "signature": "0x4797a6a4f7a7429af12a4758e3a7b535470cc300b843a72f95c05d173fb2eb2941d25eefe756f29e6da1fdf63a4111aa1cbea3a60fa50c75e10465b5262792111b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7b61A2C92DD964931C0c49C39cE616A81165A3dC": {
        "max": 1,
        "address": "0x7b61A2C92DD964931C0c49C39cE616A81165A3dC",
        "signature": "0xd437763afe0a6dd161a35f942ac21e216688e953015d39f443140b8139517bee53fd906cacf784ec487968d79c92b778a273eb4cbc5cf1f287659b4977e298691c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x090941a93cf21c0811D880C43a93A89fDfac3000": {
        "max": 1,
        "address": "0x090941a93cf21c0811D880C43a93A89fDfac3000",
        "signature": "0xf05eef91e7777efe2e4105efe9a1c2e6021488a1c6a58cbbfe789fb64d71184c5bc45f831fbbc699e79607d859c57e909aa15c46ca5be1b0159c466371bf595a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x279D2CD9167a020FBa1Fd3CEaC92FdaAa474938f": {
        "max": 1,
        "address": "0x279D2CD9167a020FBa1Fd3CEaC92FdaAa474938f",
        "signature": "0xef4d07d393dcbd55da6b62e7e1bc91371522f39cc72b04a93a6c332372cc5a4a4c5e0c1e64be92b162bdc0f92e4bb64536ac3d4e9172a1b3c958cd389bb5bc391c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0fCDe6635ad4257161cB8132620665CcF0F664D1": {
        "max": 1,
        "address": "0x0fCDe6635ad4257161cB8132620665CcF0F664D1",
        "signature": "0xf3f267e53494fdd488772ad7d34598e20fc5b64b4c23aa87b953793124871a050a7fc90044d04d14d9d2f539d92a5482422de7b44ba53bbec090521304f4923e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE81271bDBDB7d2DAe4Ef1ee288d4c827628eA4E5": {
        "max": 1,
        "address": "0xE81271bDBDB7d2DAe4Ef1ee288d4c827628eA4E5",
        "signature": "0x3d90a4c203255ca9286bddeca34829849905aa31028b7278145cd0ae1d49793607826e7eaa5d91fbf523b4221f299925d664ef723408e487fcbe35fa7cdeeb911c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x754546a93304602561140fb112c896bc0345D5bD": {
        "max": 1,
        "address": "0x754546a93304602561140fb112c896bc0345D5bD",
        "signature": "0x4fc3eb091d2e177a559066bdf4ec93a78338a60c4f3e933c44675e1f2647bfdf15ac0791591162370ae83b1b2f96e883572deb5135ecb12a090db049352f84b81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x821b0D93E18E6453e1aee741B610E3ce2060606E": {
        "max": 3,
        "address": "0x821b0D93E18E6453e1aee741B610E3ce2060606E",
        "signature": "0x4b9f60b4eab88ebbfe09fc9c0b175c2d48b50df80caa3dc5b43edeeec2f5158f33a5379c1a4cbca6d819e45d1b5602a680816a717b46a230c621a2209ee464c91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xca53BBAde6A12AF54Ad02115B2eCFFdF8EBc2C3b": {
        "max": 2,
        "address": "0xca53BBAde6A12AF54Ad02115B2eCFFdF8EBc2C3b",
        "signature": "0x647318462846dc69bb161cacc2e4386897252f5719bda6fcefff80f5d99c15a53a24d0f8a84b115ba07e1e3109c3822debf11ae956a5e30f50a92f892ce3b90e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x40D698A5a4AEF73511cF49Db7DA46CEA5eF596eb": {
        "max": 1,
        "address": "0x40D698A5a4AEF73511cF49Db7DA46CEA5eF596eb",
        "signature": "0xd49f7b712684ab1561cdeaa7958f8265c561f51334190a7563870d585de5345b5522bf97bd5588e379d44485a41bd2d266cfce9f083412f26e902ad7155242711b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3ac3000cEFa0fD5A1CF71cDE75C307f259550f62": {
        "max": 3,
        "address": "0x3ac3000cEFa0fD5A1CF71cDE75C307f259550f62",
        "signature": "0x28a6e6e5dd105be139bf29b7f36ad0f8a4b754dd64c4b796783f92a06eda6ceb1f1c96ff1b45a4c1b1041771a361f17b45835cf26eceec9936b9c40b7df283361b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x60D71d296875cfB701949cEc1eA6AC1FFd2e9A62": {
        "max": 1,
        "address": "0x60D71d296875cfB701949cEc1eA6AC1FFd2e9A62",
        "signature": "0x67878b617809fd47c15a89567da510388698276e5639f1ac960a866f7e2687a934191969df8740e0ca4850fecbb5c20ec601b9aafdb486b8a576a7dfa305a1531c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9548Be921bdbA1Ae101ea00d67552b12C929Bf14": {
        "max": 1,
        "address": "0x9548Be921bdbA1Ae101ea00d67552b12C929Bf14",
        "signature": "0xa35287ef04fbb6c9687f097002feb6c7996d12444e583380b8984018dd11903669dcd7e12d649fd3064be641fe6dfcfa0ac85e87dfb947b3c402189ced3eae991c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD3C105e18075D0F6835e3BC1aFDFf5e71aBCc6C6": {
        "max": 2,
        "address": "0xD3C105e18075D0F6835e3BC1aFDFf5e71aBCc6C6",
        "signature": "0xb0b2f4bd338d57daa7d8767cd35ed580cbc41e6bd97fdd8d24844adee4ef9f405f705b008a66299763e180f775f50e517bffb012d7c303b3baf38629c604250d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE8B603CD96DEC002354F7cB5bFC83EEc70346688": {
        "max": 1,
        "address": "0xE8B603CD96DEC002354F7cB5bFC83EEc70346688",
        "signature": "0x3a465dbe2ae4b491058d8d119113a3311056e57e6d821f3d78bcc75871a14b7f35d404ebcf7ba2e21b39b11f23deef2e5742ad4b79e68ef79d2fbbdc303a45e71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x35623aa4aD645b06EFc73088DB30A866fC0fA82a": {
        "max": 1,
        "address": "0x35623aa4aD645b06EFc73088DB30A866fC0fA82a",
        "signature": "0xfebc76148b07299f385872f7506583758cbb3aea8ca718760872c2445bb9a05f28366781f51baa0f85f01efdd5d0e1f6d16a25dab0ca869637c8ec3ff756165c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x931b4a77Dbc4b74841A17BE9c42393ACCC6A2106": {
        "max": 3,
        "address": "0x931b4a77Dbc4b74841A17BE9c42393ACCC6A2106",
        "signature": "0x61f1346ad96ae6643d96cd8f4d900b48e6cf9ad359a18c589592a636a6f1ea364e8fa49dec85dc6000061c4a9bd1368782f5504994fdc58a03a23a66e28617d41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6434Df9265bE4cfCD6f87490De6a9066595F57ae": {
        "max": 2,
        "address": "0x6434Df9265bE4cfCD6f87490De6a9066595F57ae",
        "signature": "0x01e77a07b3922688d5ff76a46db3b98ebecd6bb06876b17e423f8bf4046bf80847b746806922b3a69a0a27a7dc276de0453d9e63db9718cbc729aa48ca4bfe641b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbcD2E843FE41Da351cDe58bceB40D69242b229Db": {
        "max": 2,
        "address": "0xbcD2E843FE41Da351cDe58bceB40D69242b229Db",
        "signature": "0x5fcc729e8c3345ff04428f610e527ba5f95242e63af05f8a64fa08720cc628b55e05d172fb9a7ea2ad9733f48f648da36c3f9aa344074a94ca6706a4861dd2cb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7c947ccE5f01F2b5De341266b30D40811b094944": {
        "max": 3,
        "address": "0x7c947ccE5f01F2b5De341266b30D40811b094944",
        "signature": "0xd5ae6c08280412d10f600a1aefd2555d71cac495aa3e40b150a1c50c98669bf140108d8a0adb0295e9b2d85fc8b7d000fa0320f1bb929968fa919c91eb9581fc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1682c82be3853b00481e9400732616389Ded7316": {
        "max": 1,
        "address": "0x1682c82be3853b00481e9400732616389Ded7316",
        "signature": "0xd7aedf9b006713ba129909972961b09cd46dc951242ffc0ee53388c67b2230416f8234abf986a68773e183f23629514a8f916f0128e2d626734e2a6b1b6eb8971c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x39A279d54db098F5c41821Ef4b0Ce20242448C39": {
        "max": 1,
        "address": "0x39A279d54db098F5c41821Ef4b0Ce20242448C39",
        "signature": "0xdeb6c78bafcf5205f57d4afbfbe43b981e4200eb18a9da60ad0621f7d646b3fd1f1757c3543c606ec034f8b472b262cfc7963e979ce0b33d510273ef2890c4661b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xed4E51b5dd1E2def4e772b3f553a7EBBd0B5D547": {
        "max": 1,
        "address": "0xed4E51b5dd1E2def4e772b3f553a7EBBd0B5D547",
        "signature": "0x8cb4b1e9df017894dd3d3c8eb200dc72bfdcfc62c7e5726f821d55c77f0afaf5729b488f25c09b3961a6a8b2cad0ef531044067985159190827abf46b702af231c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4D1480d245FCEa63C86bEe65b4D282f93eFd8354": {
        "max": 1,
        "address": "0x4D1480d245FCEa63C86bEe65b4D282f93eFd8354",
        "signature": "0xb5108ca977d9b7a4159e18e74bc12a5b2add41cf3dac5cfe8ae048c1431ec9572927f43f556d7b56b8817ca73bb9dc0f05bec35a3d83e55d79fc0d83452948e61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2EA267fdb526F9dEA1131D2AEAE37a972F5118b0": {
        "max": 1,
        "address": "0x2EA267fdb526F9dEA1131D2AEAE37a972F5118b0",
        "signature": "0x84c5f3a44ed3e37e61cbb1b141a6a2ff620e231f7634234a2bdf12801b76727852f3d6f7ac90a85d023ab1efdfb05c2f9d2b8b5e8519521a1128ea287f118d431c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF4c401126e4a9710AB41459528aFCcD86b652C3b": {
        "max": 2,
        "address": "0xF4c401126e4a9710AB41459528aFCcD86b652C3b",
        "signature": "0x3f766c9334eece392e336e223d1303fd1ee763a28e11d2fb448d83ca9f76727929484ec57c3a744d8437fc0e449c7bbfa5cea52674a6928bbe7f42b44c9f6f5b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3Df9bBEBF9FDb140f72603403514570F21236C1d": {
        "max": 2,
        "address": "0x3Df9bBEBF9FDb140f72603403514570F21236C1d",
        "signature": "0x6cd87b6b8ccf6d8248fe235021177bc9f142a2c06a6eba6e33675c42f7a9248c39e0047c2cc9ffd6f484fec67970e1a2bebd5bf733097917be873e144f7e35811c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF0FB20542B94e990a867aEb9A89Cf1CCF7ac9c7E": {
        "max": 1,
        "address": "0xF0FB20542B94e990a867aEb9A89Cf1CCF7ac9c7E",
        "signature": "0x0260beaf4f4d3dc663fa2ddcc7aca0ef450ea263c481933cf2dad7af89d0e38b3d1c07e2027e1fddc6fe5c24c7708c00c02ce55c22ab3f6895f07c8ab2f9f5fe1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD4725a3c238f07dEf8c49de1E2Ed705313aDc50b": {
        "max": 1,
        "address": "0xD4725a3c238f07dEf8c49de1E2Ed705313aDc50b",
        "signature": "0x3536aebfa67ddec272dadb8fa30c8b7ed374d1ab593766b9a3789fe5ea8d11856b443e0d89d6858da4cc4c5da9e4cf17c8b19f6efebb989395979972908c8c911b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC8e240845e353be548e62D9fC0bA867d15e39Fd4": {
        "max": 1,
        "address": "0xC8e240845e353be548e62D9fC0bA867d15e39Fd4",
        "signature": "0xf1e0be7d4b4d671bda2ff921f567f55bcbc3f1df45591e67e3844fcf3232ec1a38617df17af2ee3c0f98256bdf576bb17a0cccd63c1977257cac705599a684681c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb3AE34c23374aA8EF97ED5993651F229eA71b177": {
        "max": 1,
        "address": "0xb3AE34c23374aA8EF97ED5993651F229eA71b177",
        "signature": "0xb2428d8c04745f6493a071ed01dcc51ad336f94a07f111ddc021b60316ccedf45a12d4ee70ceca7725109b2d6ce7b106c31e4eb7518e76f9afc90d476628e3411c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf481742F3725436a239285D59207f74E59B92469": {
        "max": 1,
        "address": "0xf481742F3725436a239285D59207f74E59B92469",
        "signature": "0xc6f38d541e5dc8411bc85c8be041c5118100ade31b4d9d002543074cd7b78c272ee3c737452027a97937bc1aa983efdfdd0f80d1b7c12c577d5fbba0e83e44981c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4982F0fb4c7A6CEA1514Dd4A649231E1CCEcce85": {
        "max": 1,
        "address": "0x4982F0fb4c7A6CEA1514Dd4A649231E1CCEcce85",
        "signature": "0xd5e42a380b26c4653849fcc79fa224f7b8cfd0cbb8a86677d848e43fbf7cbcac658ee62745920015df65305ca7e244d681fec8fac81f57b7a3094776d36b187e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfa2647C708fC69edad7340d683faAcDBF0fCc192": {
        "max": 1,
        "address": "0xfa2647C708fC69edad7340d683faAcDBF0fCc192",
        "signature": "0x563cf604b5cc7e5feec69c348487f58ec9436cbb2706e4adf5e047813e727679437a04acfb2a32eedcc5367ce452a1e24e5bf652a86cf1ca01f1e46146820f6b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x79a3239F19c769c687954283C9235C68D9625d86": {
        "max": 1,
        "address": "0x79a3239F19c769c687954283C9235C68D9625d86",
        "signature": "0x36e28c6b595569f0672c3f385e54c662fb1185748ad702c6a7f256f7962c6eec6ec30b391c664de82b8a247d5978af69076337a3dcecc73dce1372ffa5600f7a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAC2DD8a732d6ce49BaB1cd48a5672Ce076bbB5E4": {
        "max": 1,
        "address": "0xAC2DD8a732d6ce49BaB1cd48a5672Ce076bbB5E4",
        "signature": "0x6641b31736fabf82de48328870af4d0311759ae857e15a8f5b306154d42c490b4145a2145abdc35f27b40e4935c72b1d3a487c7794b100c1beb05987465792331c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xddF36806DD8b1C724F6f18BB0623C87BaC6f6812": {
        "max": 2,
        "address": "0xddF36806DD8b1C724F6f18BB0623C87BaC6f6812",
        "signature": "0x87045687045a2e1bb0a2569e91d10853d5ffbe7869549f76690aaca8818324bd737a7a52c38e21bfcc02204b2ef1d4bdcefdb39a798ad25e59b793e9740584901c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfA43Be6e14E0e36B7a8fb8ce4A1B6b5E4aDdf42d": {
        "max": 1,
        "address": "0xfA43Be6e14E0e36B7a8fb8ce4A1B6b5E4aDdf42d",
        "signature": "0x2ea296c6eab6220b1ea731ac50826f713e7e8baf73f9f5b83c15549bce13358d15a4f2ef7667d3d1b40d879e020f8c591752c801e80f0a45d9b81f8d8b03b5571b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x730228dc081E56f3F359DC43333D57203c52CF78": {
        "max": 1,
        "address": "0x730228dc081E56f3F359DC43333D57203c52CF78",
        "signature": "0xbbe622bf8c1638b2f4bf8d26bac6e2dd208abc359be4ef42acb1f58c2a9e2714414b3b7129a62742b65719b2ae15d8993aab9e5a736158570e615e6c755be7611c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x725F4e6B8166A888DB587D937997201324ABB131": {
        "max": 1,
        "address": "0x725F4e6B8166A888DB587D937997201324ABB131",
        "signature": "0x47dbe3aac37b3466daf9cfaabc6bb69f04ca55d519eecebae30586229555e2b0519b94a9370b1d87e6ab3fd900f232396af1ca176441b2dcb32406eb5797ac2f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6bbF822152d750aCf598f7b5076552cFFF18d812": {
        "max": 1,
        "address": "0x6bbF822152d750aCf598f7b5076552cFFF18d812",
        "signature": "0xbd7f25c288c70cd870060319196f34579b7be4661386d53f2ee5c573f45ed1d2600f73ec4797871f5ecbed69657c30fb18ef2e3d7929772becc72161a71518b31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x538EF5bf433b2Bc608C12ea93923E039AA87eE4A": {
        "max": 2,
        "address": "0x538EF5bf433b2Bc608C12ea93923E039AA87eE4A",
        "signature": "0x54d0fb7a0d60155d03e2d502e2dc2c2b45319f6e870c2889b42327cf34aaee611d54d8b21e517de2ba29d6fa032484936bed1559e5e15406a07f3cfbc626bc461c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEEdF66927405f7E5f98c623C303D1aA39d93Ce40": {
        "max": 3,
        "address": "0xEEdF66927405f7E5f98c623C303D1aA39d93Ce40",
        "signature": "0x4876edf912adc8918b1a760a90be7c13101c1a2313f1af5fe59e013b9c4fa8b3418c2158484ea9dda222be4c62212be6f854c5ec3f8e39b04d56dc63b6acd4671b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDd7bB93ed040AAd3d5D63180a76B52889162c428": {
        "max": 3,
        "address": "0xDd7bB93ed040AAd3d5D63180a76B52889162c428",
        "signature": "0x9dc7403343bb7f03a23e088b861d04d26c908472b03144245c574431c34930bd5bda9026beb67aafabd3fc0acf431a56b56405e54feda0f57672e189745f98251c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x511a15b5a39DAd11d48f3Dbd479F13456152fCFd": {
        "max": 1,
        "address": "0x511a15b5a39DAd11d48f3Dbd479F13456152fCFd",
        "signature": "0xcdd64226c2eeb0c2b037e32e51224b05aec881eb6953534a4b9e69b4afa5595419bed3f5b1541ceda7c3f3f002e8fdbd4114209a0e17823dd70ad42fcd2abdb11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x50Dd9C1ad3aC1fb4bdF087df9FFC6b301146eb74": {
        "max": 1,
        "address": "0x50Dd9C1ad3aC1fb4bdF087df9FFC6b301146eb74",
        "signature": "0x3063175822fe720c52addaafb41a5f7cd305131c90e13b5cb17ad0c67581a71e31b7c33627ee53594b07d1397635b1012ed0da3ddf34883ad2281e3faf09f01d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2CC859ba8f7606E299f529B1B0b0d4270A9a14Ca": {
        "max": 1,
        "address": "0x2CC859ba8f7606E299f529B1B0b0d4270A9a14Ca",
        "signature": "0xc586fa7cd5442904917726e3d7b80f2e02cd568528b547783f9f45359f574dfc7c885d3730f138feb6aa59e08579a387ae7f4ed48aed88ad4e2caafcc4cf44c31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5B1Fd3E5d79ff63b533B26603Db74aF0844dF49a": {
        "max": 1,
        "address": "0x5B1Fd3E5d79ff63b533B26603Db74aF0844dF49a",
        "signature": "0x99439827d5bda09fde204046d7448d7f002136b5ca0567372ca6fe32117b248b402cc19cf141b1c89b25d47e3bfe0658c53b7e16703eb3be9e3cd59966d481731c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x840082A12eAF509800f47b9cE1FB89A880505Ca9": {
        "max": 2,
        "address": "0x840082A12eAF509800f47b9cE1FB89A880505Ca9",
        "signature": "0x2ce4770e7ac9e7e3c13694365f8e3c6cf26b802ddde7a72cf4d6793fc1aa9fbc109d173df01aa6933a4217750a2dd88c6c341f4daa7a91df12234f41d18a5a7e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x79ab4c8ca2C25bf2071678915A813b64371aAD3A": {
        "max": 1,
        "address": "0x79ab4c8ca2C25bf2071678915A813b64371aAD3A",
        "signature": "0x91a1a0315c44d3c8f6bd6259f2947236ea13519bb7783f183983cb588fb9051a232912eed12ad54ab289bc221419cbd4269460593edbc793ca0b9f40656b78401c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5238881F170D19fbFBB496692B537bF91af1c93B": {
        "max": 2,
        "address": "0x5238881F170D19fbFBB496692B537bF91af1c93B",
        "signature": "0x217615ced7ca8e297b9bdb51b5b2590c7692d688e6346e3ea3f4adcc840a50800783244c2fa835802ecce3ec3fa04830f2020d318ca14b1070c3e3f28392cb9c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD46C847654941707546d26827Ab1Ce109be2CA10": {
        "max": 1,
        "address": "0xD46C847654941707546d26827Ab1Ce109be2CA10",
        "signature": "0x70a4a0943d79c5bfd9d400ac241578d2aad165887e7fe23d1b1fda29c802ecab195426d205c5997af67b8a36a023d68d34f1a32ddd15039b686ce1c786066d621b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x39a589F8d6F6365A4F67dFCb3aef4cE0a5469543": {
        "max": 1,
        "address": "0x39a589F8d6F6365A4F67dFCb3aef4cE0a5469543",
        "signature": "0x6c2758728874bae8734b446228526c01498eefae80198669f109412a63eb8d9e514d0fcd463b1323ede7dcb6dade53e2bef5fc8bcddcfd90e3d0fea537801fcc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x87FcCF7C98BAf3a9529cA3233B0aA351B6e811D2": {
        "max": 2,
        "address": "0x87FcCF7C98BAf3a9529cA3233B0aA351B6e811D2",
        "signature": "0x0451d2e5392d9a93c1f130dd516e1d482d7412d3d6f60af00810a803534314b75f4da6ea608e9ef84249c00cd9cb013c7ca23bc1e1706835be3eaa69ef81b7c71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc404116735b8Cf318CA80f303d40Be701392243D": {
        "max": 1,
        "address": "0xc404116735b8Cf318CA80f303d40Be701392243D",
        "signature": "0xe7852ec8d1a11a680b64c0b5518d2f8cac944c74c240465054b5e84ff5f9a726385dd6d2c1a4525fc44d1d081d85ae0226d617ae5fc9e8334687fb62cd5da76e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF76b23004c67930F8BE5cB88aA121bae03e9935E": {
        "max": 3,
        "address": "0xF76b23004c67930F8BE5cB88aA121bae03e9935E",
        "signature": "0x6f94f5670c08b1ec608000fa64356de7ea53f3ba761b318329b5eae3327e71ec09fab395e2b23aad5e47a297b06d0e233e10b9d8f4d353eb9d34291eb4ff464a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3a4974C33f81bf1bBB7aA6C6Bcf1b0a616C86F30": {
        "max": 1,
        "address": "0x3a4974C33f81bf1bBB7aA6C6Bcf1b0a616C86F30",
        "signature": "0x05d74b4b315a1b005739abb05be7e1b9fce49ec088dff0079769de0516326f0f30c0cff086bfbdca0dc00355fa451ad30fde6ddb19d7f0b19a4d7efa65ba40571b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0ac6dE30bfFC039EA8d0f8F7D179aD36D3f8FbEf": {
        "max": 1,
        "address": "0x0ac6dE30bfFC039EA8d0f8F7D179aD36D3f8FbEf",
        "signature": "0xa07f28abb5bbc4407e9ab7ee3a1c2a12c1d45120ae8bc96247c05b85a65ad0ba573b4d00e7753900db1b2a8488fc217370f7521465d24479db65388104e45ffe1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x64106bc738D7047b2734Aa5f82ED5dbBB34A741B": {
        "max": 1,
        "address": "0x64106bc738D7047b2734Aa5f82ED5dbBB34A741B",
        "signature": "0x7fc9472e4501e0a777feddc2c70e84483d2fd6040720c6e3918cc3a0c99fe25056c8d52847fc5b6d92ef0f2a777e0a339f44ee765de60043032bbb5a56655ac51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB00Adf5621F1D300a696692ce790f953FC76392d": {
        "max": 2,
        "address": "0xB00Adf5621F1D300a696692ce790f953FC76392d",
        "signature": "0x0d7dd37666bb2d45d55808224e9162ea072a22b0292343c07f3256dc433a0f352c6f01070aded3e94b87eb531ff524bab6150655b488fa72f5a10b500090d8cd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEE6605C6eE07D3c74157Abf65E0b09D05d39296F": {
        "max": 2,
        "address": "0xEE6605C6eE07D3c74157Abf65E0b09D05d39296F",
        "signature": "0x987663e471e77caf8161e096ce876ec65162c12b19ec18d82f16127b8465f3d671d800dced4e51b1e4dc6b1caa000d67d376f3f4158a002e3de2736482739ba71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE22482F6f73cfD62070ddA0eb5Ce98Db15934F09": {
        "max": 2,
        "address": "0xE22482F6f73cfD62070ddA0eb5Ce98Db15934F09",
        "signature": "0x5a1839428d35f7ee1febffaa9e409725db649d41a6933d1163cb444cbfb37ca75e2868e22d9828aca64a486906514a717f289a2432774db010a14abc67ac03c81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC8687f0F67Cb16f6121B1337B20B6b7B6f016Ff7": {
        "max": 1,
        "address": "0xC8687f0F67Cb16f6121B1337B20B6b7B6f016Ff7",
        "signature": "0x3f4aff4f6b4f32d4880919d22dd4d6d72616ce792d1390e9d5d5465d8df0e0715c215432f546a94a352f010d5c1e44b6896f0079ef036a1d99b75ef1a76736f61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdbCeCB71cD5B0dCF5020ECC3Bc2283d81C7D773a": {
        "max": 1,
        "address": "0xdbCeCB71cD5B0dCF5020ECC3Bc2283d81C7D773a",
        "signature": "0x3ef9116a367be20140e3715d02f191a764b03419681b3b250b9b2a79a02758495fc67c9406f11dc654d21b030801a15c5e592aad09c7f01ae73faa867ad22bc41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFDC98F42b2F7BBAbC4cb9dD06EA47c8391c93c39": {
        "max": 1,
        "address": "0xFDC98F42b2F7BBAbC4cb9dD06EA47c8391c93c39",
        "signature": "0xcc53e542bb135b6eb9d89642760f47bb20fce97634575acbc4a9b748f568f7f84fdf1e30e06ce3c3d183e190bf091203d3113361883cabf8defbe7576aa930641c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF9C8d277b7485D29a0e76Bdf2f53F584f9050baf": {
        "max": 1,
        "address": "0xF9C8d277b7485D29a0e76Bdf2f53F584f9050baf",
        "signature": "0x66db91f8b89c3ef5481cbc3989229a86fb1fab9e8ea638f352738221ef4716214fdb95de173a16017c1d548f30993ccd91fadb6c3a06937f10f38eb7ce5492a91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe6519b4726aA433ED7a748125e3445876bc34100": {
        "max": 1,
        "address": "0xe6519b4726aA433ED7a748125e3445876bc34100",
        "signature": "0x89a6cc37234c658288fd04a5542c54e912deb4f2d47198921e5a20d934859604528c6cf45ba69f66d5df29d7a0a734f2ca21a9778f0a5fb1aac5f9774466a58f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0489726421f3b7B3Eb2Ff98b29C6cFc5aA54F3E2": {
        "max": 1,
        "address": "0x0489726421f3b7B3Eb2Ff98b29C6cFc5aA54F3E2",
        "signature": "0x03763e0a129d3f0604f62fef0df99a8bcb9ba540995f4d0bc10d09deb13797d42873f825624259930b6812f5edd41dfb750acd845897d3372baf479b07df07831c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC1ea72FC1D78F94433E1232ebD8B6F95e8163E5A": {
        "max": 1,
        "address": "0xC1ea72FC1D78F94433E1232ebD8B6F95e8163E5A",
        "signature": "0x30dc42662cee1f645f00e0044a14a3beab68bf31b6deaff5a1dbe826edce7e44327617c8ecd8d1013f8022b6dbeac59de0ffd19f57b56a1ae9ce4cb63e377a381c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa9BF472a3Cf18FD377Cb2a5AA987a09a9762977F": {
        "max": 2,
        "address": "0xa9BF472a3Cf18FD377Cb2a5AA987a09a9762977F",
        "signature": "0xc9075eb2506ce2964046a8a02d82838949233877358051ff1f5b7643d65ec38a1e2fa7c7867d4c76e60c18288129d312c2ee87d8dd7a6a5b24f1e250eb822e2f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x84c3bb6c347844c16CaC18E0E3CC735ecdF1f9a0": {
        "max": 1,
        "address": "0x84c3bb6c347844c16CaC18E0E3CC735ecdF1f9a0",
        "signature": "0x7e3169d6fea07b8b989494e991275e7628b3e51547ae01baf67cbd826961473972ffca9c1da85fa8e4e6a7d5d2a5ab828fcc25b826925d05e429ac037712c86f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x25815e9fC1391a21A49D50Ed73ea186a912F9a8f": {
        "max": 1,
        "address": "0x25815e9fC1391a21A49D50Ed73ea186a912F9a8f",
        "signature": "0x6914fc6e5f0c29cdee9b23322cf01098475f8dd63c482c0ce0ab5d4e3c26aecf5369b565fabade2271f9456bc2a983568147aa3b5b456bbbdc12c9522217ddbf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x13C37Ad5cDF55C32d09Ab2B216A782026655F5c7": {
        "max": 1,
        "address": "0x13C37Ad5cDF55C32d09Ab2B216A782026655F5c7",
        "signature": "0x331ea9a131c99310ffd4b84849717c56f59582e6f35d437185517194a605c0a44b0908865094e9ec15627880588e62976e7cd532827b8c6a731f0022958c3e971c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x06Fb43d9Fe5F257c4A45dd57F39A6e967584AB1C": {
        "max": 1,
        "address": "0x06Fb43d9Fe5F257c4A45dd57F39A6e967584AB1C",
        "signature": "0xc2ec3865e3f8e017fb73d140085cca7da2c41e8eadd1edf14458b7830c5b19d97bbf71bed142acc92d0b39239ea1ca969af886b36fe31356587f64ec7ddda13b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3a94c7088aa86Fbba7C7649D740A1120691C4037": {
        "max": 1,
        "address": "0x3a94c7088aa86Fbba7C7649D740A1120691C4037",
        "signature": "0x375aed843ae209f1c4d01e52498dd6af433cbe0516cba3dde35e6d1221e0c56b76c15a187154e7d535cde057b81ecb6782a1d1c50d312c15bcd20f97600bcab81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc4DEE2E2B28BC202C783aB887a77c599FC2013EC": {
        "max": 1,
        "address": "0xc4DEE2E2B28BC202C783aB887a77c599FC2013EC",
        "signature": "0x7e4a3ec4856adb82b370b2e7fc13e591257b539c5e4d2ce64625642dc4be366b412c201fc5b240c652f8192293b6caf994dd12aec13e6eae3b9227c77d69f8221c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x34E75060B7C4FdD4861Bcc2070db73BBc1c2f3Fe": {
        "max": 3,
        "address": "0x34E75060B7C4FdD4861Bcc2070db73BBc1c2f3Fe",
        "signature": "0x1d367063bd705fd5b331d392b2473691551a09640ec41bf2c10b7e10ead54f307ce36679fd2ab8ed10398f00571cdc0c058be2fe6842e8d6267b27287cf53ccc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4a58085D2984b2AD2703F5fe9aEBD4352e66C6a5": {
        "max": 1,
        "address": "0x4a58085D2984b2AD2703F5fe9aEBD4352e66C6a5",
        "signature": "0xfe505f3537cc92096b075a333fe09566ef5d5201cd61afed33918e116556ad464f8f0725a22dda07967d61ac4943492ee211ca7fd0176675d1c3263cc6e8dd5f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x08050D20Aaf3F906cB89E60a1C086c75e40A9999": {
        "max": 2,
        "address": "0x08050D20Aaf3F906cB89E60a1C086c75e40A9999",
        "signature": "0x2dc0c782c3860cc3c4b988e72cd2920d1b7513188b6a8ee34a074dda3209294d4d95c7d868795fb5cc0015fd897c8568dbbf03c37657525042b0bb95b0700eb71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6c2e898e6AE1Aa80bd5a0029eCaD768a9d312d81": {
        "max": 1,
        "address": "0x6c2e898e6AE1Aa80bd5a0029eCaD768a9d312d81",
        "signature": "0x16cdc14bb7d72fd71ea47a34326d44b84b40ca63f2a50ff36f446e91bc7bdb015473c97f4b09c97b9c3b5ff947a748287eaf4a0c9aff212c9fbe5d98f24d5cb71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x44E290a081117FD8A8B3018b8A87c6b501C805f2": {
        "max": 2,
        "address": "0x44E290a081117FD8A8B3018b8A87c6b501C805f2",
        "signature": "0xf6c8b93da51e6b16a9635c0047ea90e6218479b3d1087b9251c7d439e1829ff91993fb2f8db62da204db79143a5ff5429ab892e0be421cdb59689d3477071faf1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa9ce904dfF0FDF887D714Dc5AC3CDC2757cAd4D4": {
        "max": 3,
        "address": "0xa9ce904dfF0FDF887D714Dc5AC3CDC2757cAd4D4",
        "signature": "0x80aa59fcb8ba2018a66f1200c1f78b5681ab80e3cfba126126f8dd8e55c787d739062b5a3f2840ee1f83f0c8828c8768ad8554ff4dd8866e21791dc46c8e608a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE6c3F8B67F4Fb35ecC857837d24b56296E2cC2E8": {
        "max": 1,
        "address": "0xE6c3F8B67F4Fb35ecC857837d24b56296E2cC2E8",
        "signature": "0x7ecb1978e1c1521830072eb753cfd1dfbcabfb6f98d3b163a6541f2d05c79a9b04c706306a700a244f5162dc9daa2bcc8f38df2e8f178f16cf8fb30c75d4e2811b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x06cDB43DDAEbfFDbA35c760293690a2B84977d4d": {
        "max": 1,
        "address": "0x06cDB43DDAEbfFDbA35c760293690a2B84977d4d",
        "signature": "0xd4b100b7901e15ac126183baef39b912ebd5eea87f4fe8e9491314e7662e7d7c2e868a10fb46ce9007cdbde6b248bec5e7dd38700ce413ec202617b79d9557821b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x22Bc119DA78B7EB7C14c7FaD98A81608B06C8b71": {
        "max": 1,
        "address": "0x22Bc119DA78B7EB7C14c7FaD98A81608B06C8b71",
        "signature": "0x4d586f0a1c3dfea2437adbdcfb6f28c31dcd854669b87308b6822720c60eaba217fa3d9254ab032019a0551a78be988d2abc11f03bffbe6a69b1bc6f34feccbf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x34c629FE5610Da62d1D02532ee30a840EcB3e5e1": {
        "max": 3,
        "address": "0x34c629FE5610Da62d1D02532ee30a840EcB3e5e1",
        "signature": "0xb8d4a6ee0288e76aec38d7cb43a17671932ecd2afc9dbbd1bc02963b716f6f9b12ba9489bb70ce9b433d723748cf023272456a0a309fad9e5158d054f08f4ad61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAFfB93AfaD9570199BFdB00f1cA297287c30dcaf": {
        "max": 3,
        "address": "0xAFfB93AfaD9570199BFdB00f1cA297287c30dcaf",
        "signature": "0x910b692947fd57f203c4cdf9371283bd4629ea41ccd882ec840257352ae044c0264fd26eb6b84178fccdb0c01383f06bfa4c0770fd40b6b57b3d34d001136f8e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc4Dd99B451c3d705f4d60261c2CC51d815D0a97D": {
        "max": 1,
        "address": "0xc4Dd99B451c3d705f4d60261c2CC51d815D0a97D",
        "signature": "0x42f73b4bd681ef60d4525fbd6b8796f4e763036755946d1d7cbb07a469d6a7022fa81b11595c96cf65d3cf7b40c838a0a84a5becdba53a7e7332183df5acfe7b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE50E7D427CE2796Cca00b3C0f822680C2109CDFd": {
        "max": 1,
        "address": "0xE50E7D427CE2796Cca00b3C0f822680C2109CDFd",
        "signature": "0x0dc1798b9455fe302a8c69b0a079ee31861babb40b7a50c9f92dfedc718a90962c6b865cefde5c476e1894e7ba62debf6caf57d1e4b34958bfe6b12365a40f501b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x686c5BB4435F89aBC97d2AC3B89D1F0F1D22cb40": {
        "max": 3,
        "address": "0x686c5BB4435F89aBC97d2AC3B89D1F0F1D22cb40",
        "signature": "0x14e488d26adbcc77115a04c74d2fa50a1d130599d74a53287171b8b5cb63ec350e924f40032c7de2e09a9fc9c7eb718e721986c3d5d87cab0bf38c5aef9b22ce1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1945095343c558707ac8fcbC43bfF80444cfAb5a": {
        "max": 1,
        "address": "0x1945095343c558707ac8fcbC43bfF80444cfAb5a",
        "signature": "0x6c38c72c27d1d1f833770387f35741792266b0b97c5f71a0f032cb13df050b45640e39215b33cf56981766222344388ea1ab3a83f8b862aa98f0d3d791034d581c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6f16F3a8f08AAD3f39D8f85960165fE30c35b68D": {
        "max": 2,
        "address": "0x6f16F3a8f08AAD3f39D8f85960165fE30c35b68D",
        "signature": "0xc74c961448f640959fc7d49db12ab7a9b95eed5517689094d76af97e287d8ed4459f70ce7d15c516765b42d7af32edb6166916972613dfc9f2d404330ce7091d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF65BB2a70BB827247403c98cbDA7c7CfF90dB292": {
        "max": 3,
        "address": "0xF65BB2a70BB827247403c98cbDA7c7CfF90dB292",
        "signature": "0x5be5de87467687bc26b646aaeba03fc2257d3ce6b7fe25b1ba531100115cc0a7579518ada46f5146013cd2601a722d0588ccaaefb4efdeb399576ca4643a47181b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x498A71DDd5d5653d0a207FCbd57b1ae66AF5cEc7": {
        "max": 1,
        "address": "0x498A71DDd5d5653d0a207FCbd57b1ae66AF5cEc7",
        "signature": "0xc230a4aa2f2092a7ce74304083e017fa706b0832ce9b74f0df6ec709a1b1b9aa3ac2d806243a5acba142d5d67034e49a0634e47cbad7b6dee0e3ac0d23ce58581b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3963DCc2b52466eb9f78F3C4320b57a739F0b8F7": {
        "max": 3,
        "address": "0x3963DCc2b52466eb9f78F3C4320b57a739F0b8F7",
        "signature": "0x5eac733ae33b4e69f206dc4f95910a88012e1226a4f52df8dc6361f6db292adc7e72054fc67d653f8eff29de357da1bdf982dc38130f57de01a2299cb73a6e4a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb79e8F5dCAABFBA8429873018Ca84D8172046861": {
        "max": 2,
        "address": "0xb79e8F5dCAABFBA8429873018Ca84D8172046861",
        "signature": "0x0d4626a4cf491a236971657fe14335749b1b62afefe822dc88bc94451684b27328047bf0067b7b5d46fe6662a4f5d1717073672481cc61a10b4ff119945129cf1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF844929ac5424E61fD7BF300B57032d9BfC03CDf": {
        "max": 3,
        "address": "0xF844929ac5424E61fD7BF300B57032d9BfC03CDf",
        "signature": "0x10912d2ecf2498e16b6461ee7c9d6968e204cc5060e0963cb690050580546f5c609ec4511a3bc9168e908f8da18b2b8ed3f86de1db0f2705b9dd8bae466b2b9b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7Ae03A493485f7D948D6Eb76af136A96fdF411C8": {
        "max": 3,
        "address": "0x7Ae03A493485f7D948D6Eb76af136A96fdF411C8",
        "signature": "0xcdf81aec5f676c3fb4b593ab5b32501cf76a9f264ebebfe41d426947275cb05264e42d073ad138659facf53d173394477c064fb7833afe049adfb938017b03cd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x398c75ae8D584F282c943B99A1D23D0BFDa51B90": {
        "max": 2,
        "address": "0x398c75ae8D584F282c943B99A1D23D0BFDa51B90",
        "signature": "0xbee0adfa802d64d098f7beb43e6ba2856a38ceef7fd8441e7ee72e1bcd6542be0b29cf1ca594f84c4436ab85c2c5ce33caa015d3edfe02733e9358885921706d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD1fE30f846B4685B1527913C3019eC5829195e60": {
        "max": 2,
        "address": "0xD1fE30f846B4685B1527913C3019eC5829195e60",
        "signature": "0x64683a7a3d0516d6827018c7e8adb3ce9021dc7e1925c455abef1949870b5a162c8e5eda58f3c5cb96d8df6933560d3209a068bca427f1727e4bb335a2ed35341b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9D0C213B734F0cA1FdF6c5B96f2A154759aC0113": {
        "max": 3,
        "address": "0x9D0C213B734F0cA1FdF6c5B96f2A154759aC0113",
        "signature": "0xecb1aef33e6f00f83e508df5b5018c2d575fed4a316f7c4bb1f37d48862bd50d7b87faaff0c694f94781d382bbf24ad72ca0a6fb2f5159b7b1377e14a9a097681c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5B2B5cdD0EF444df2fF0105B24eEdbBe47054a65": {
        "max": 1,
        "address": "0x5B2B5cdD0EF444df2fF0105B24eEdbBe47054a65",
        "signature": "0x9e7f515d95cd19f05e52f5c68b31a4e489c4d998447edac282d409ce149e5ce101300bebd40563dcd10eed3c1dfc230200ead4d63e7cfb4b7e5b22b65cd2d0711b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAB4ce2a08cBeA809692218DD7841F681B80069A9": {
        "max": 1,
        "address": "0xAB4ce2a08cBeA809692218DD7841F681B80069A9",
        "signature": "0x571d7dfd2c9ac5bea5d3c4f0529f476fd2927ede0faa592ed36ed7c58b449bf9363900473eb72b4ee76c913387e20a4a2c1250b916838dfb4dd614c704a5da4d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8269e14B9a3eD95DB483861788F3B4f006894a34": {
        "max": 1,
        "address": "0x8269e14B9a3eD95DB483861788F3B4f006894a34",
        "signature": "0x3aae21c2fb5b9c0dbd60170b05c0f8c753aedbd7491ec596908d725fc55513346a889a2aadb48ed71fdcd5e49b5e73339608955db7e463a857d92d2fcd2a54271c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbE51C0a50bE87AE158d1dcb8000619f20ccC2803": {
        "max": 1,
        "address": "0xbE51C0a50bE87AE158d1dcb8000619f20ccC2803",
        "signature": "0xda59e6f6d69f70c60f5ec17bf555ec856d2942f655b549669b617cd88c66602357d064437dd7d38e29df1446c9a9c8bb4a844545b4613620f7a3ae0f52455ccc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8e01ff100f62151FCAbf57d80F0A7de16EE8568d": {
        "max": 1,
        "address": "0x8e01ff100f62151FCAbf57d80F0A7de16EE8568d",
        "signature": "0xa0032775c2515adf1ac62e498979a9813c3856c78bde5b29a5a7b232f34aad382fa4c1ee18b1432e2a048504eae728739d71910cfe229e28449ba57e6402e3bd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x73da29e91AaFe10cAd7A40b79d84a5449519B533": {
        "max": 1,
        "address": "0x73da29e91AaFe10cAd7A40b79d84a5449519B533",
        "signature": "0xf965aa2fe403532e727d601463317268be5a3a6a9249e834dab8d5ba52ad3bc469da6aa54af1230a5c9a954b7441a0a5c71a7a6f3fa4cbc8a80f5f0cbf9bb3131b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0452C29AA6BC90B85088B2f4d29f8067e9Be257c": {
        "max": 1,
        "address": "0x0452C29AA6BC90B85088B2f4d29f8067e9Be257c",
        "signature": "0xe7527038279d128a66bb749383d5f0e07d195eab7a60c76f18a9bed3ca3e3e8f54a2f9b5a6d517624a7acc21186bdc6529def4e57e314b79972ce628f0899e411c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEb5fB40B672f02dB802D1A2dD5988874f314c2e0": {
        "max": 3,
        "address": "0xEb5fB40B672f02dB802D1A2dD5988874f314c2e0",
        "signature": "0x50274ba6d4102aacbcab54b71ab988866dd837524211b245ef8b8c0f6938543c3342dfaf6d46f8849ffd3262623d29ef082d3fbdc3950cfa39035aa0616fcb521c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8082dae9E758d9cf76c17cB8edFFF8b1fb312eb2": {
        "max": 3,
        "address": "0x8082dae9E758d9cf76c17cB8edFFF8b1fb312eb2",
        "signature": "0xe0be12c0c1316d96867a36e3e767aec86030436f9e6c5f33dbd863668ac6e73a53a6fc082795b43ab7a9d74f9ddecaeda92366f0ece2f111473829fb80c7faa51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x83269F1ba78603296278746BaB0c5c5A2b27Fc93": {
        "max": 1,
        "address": "0x83269F1ba78603296278746BaB0c5c5A2b27Fc93",
        "signature": "0xebfcfba1c4d010300f986cdff5940e668fd954debed465001a9ab1f36e9474362ae85f4cf8db59291a4c0e17657effb668fa38a4810c52573513bf0a1ce49c2a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1a1743bE45F2af484E7a5841Df6Ab5dcC83649B8": {
        "max": 1,
        "address": "0x1a1743bE45F2af484E7a5841Df6Ab5dcC83649B8",
        "signature": "0xdff004131329bcecbabe455fe752acab664cc002991187c714eff0f52f6e4bf24f3a9b118b758819821e04b450a14c4ee4718c030b265ea6c2ad013bfa3822c51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCF0981E48E2CA8B05566C0f6E48fBd170CE64291": {
        "max": 3,
        "address": "0xCF0981E48E2CA8B05566C0f6E48fBd170CE64291",
        "signature": "0x51006d651370b6e895ed55fa95ea60bca525ae24866564d8ee546b86c7345163489b4f5b7c91b7b82468dbf47299bffae700391da888bca21571676dc3c46a2e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5B66c925A37d1Ee62cb5510FAc282c695c2F334c": {
        "max": 1,
        "address": "0x5B66c925A37d1Ee62cb5510FAc282c695c2F334c",
        "signature": "0x3a78640e7307840b2880ccf7185bfc840917b1abd012188910d0646d7e954b073ed4a3363a2a20078ce37f87469694e11fea5d375de656e5b4e6032192de54371c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc3B4B9D5e0f633BFbf890Ef182a10B119F16b7a6": {
        "max": 1,
        "address": "0xc3B4B9D5e0f633BFbf890Ef182a10B119F16b7a6",
        "signature": "0x6be292cdefd516b455c6620e41fe7a18c742835a58cab04273683b610f4f85a26e4c88fd19c1fa72ecdd89c6195c83c903be9ca567709189d5002d04491db7231c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x966b18012Bf5CF7E28eABfADB6a5F8c35e3eeB80": {
        "max": 3,
        "address": "0x966b18012Bf5CF7E28eABfADB6a5F8c35e3eeB80",
        "signature": "0xd17a9d4b472225c04156a9cdaa14519f95f33748b8d15e123b2058d4957fd47f30af6ea36020ced60d696d23ae8255de122c034762ae48191833db0785a6bf9b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA4B1cb465d0b32e41a7bCfCCCD39e59e8e7BB10A": {
        "max": 2,
        "address": "0xA4B1cb465d0b32e41a7bCfCCCD39e59e8e7BB10A",
        "signature": "0x7bfd3dcba40e68e6cd003f1ad80aa81e8fc0da43b88456b945774f16f17c699a1f9701534ae87a020ff4439539e62e64ea49bbb29cb1c0898807c93807ac5aac1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4eD067568823E3A9c6DAdFFd8fe05fA82b3Cbfc5": {
        "max": 1,
        "address": "0x4eD067568823E3A9c6DAdFFd8fe05fA82b3Cbfc5",
        "signature": "0x22fc6a19e450cbbabb328bcf2d5c5d7a7a99887e41e7930a1d9e3ff4c7b57e203561b5de7eee57e8269c10db1c9da7bc9e575dceae9e01332a82aef566fa3f8c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF20787576a8c0a3FEcaF9d5530E387a75aF0c545": {
        "max": 1,
        "address": "0xF20787576a8c0a3FEcaF9d5530E387a75aF0c545",
        "signature": "0xc402f7507cec583e0015abb403d8b25cc5f3595df0dc9cec0099f5a783f914d5415e8e14110a38a396e8a2569c0b9f60beb31414883270b76b17aa870c1882241b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x18c2490aC151216e1499678A39f5AE58d19C62c6": {
        "max": 2,
        "address": "0x18c2490aC151216e1499678A39f5AE58d19C62c6",
        "signature": "0x06fcde52a3f304af0685ab1f4dfdcbb658bbd8bd586053fe58b012b56d938ffa341d2325f0214a564de61b6aebef13bec4602c61806195bc95785c2e61c1fecf1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9B9325b63ace4966d9d34B63249e0aa1a6c5DdB8": {
        "max": 3,
        "address": "0x9B9325b63ace4966d9d34B63249e0aa1a6c5DdB8",
        "signature": "0xbfe23bbb93fce262cf500ad725b46ef85cbc17f9e6a4d799e70afbaf02b539d462ad396af76f86d389786ce8b37fd61e3994c19c40213464fce765aecb442a841c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1FFAD0f78FF0C92C34dC324128e2A40Ca7CC58c0": {
        "max": 2,
        "address": "0x1FFAD0f78FF0C92C34dC324128e2A40Ca7CC58c0",
        "signature": "0xb55dce67aa81aed5b31fc3086f1edff4605fccba0b4276ad0183df1d700ad8b57590cfe99fd3a712b5ddda68655dbcda3440cea9457d607232a802c173a7caa71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5AD3aAAB94838460825AB8D075F99Df62b38FbDC": {
        "max": 2,
        "address": "0x5AD3aAAB94838460825AB8D075F99Df62b38FbDC",
        "signature": "0x6ff03eac9687dc784793d57f1447d23b23505894be7ed76985462dc70b2e5c3c6618ab98832c633e97da15a5901d173ffcc29c6636129c1f3c10d061900146201c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x56055A0D5F7305C9031367446CC02f5a67FA4D81": {
        "max": 1,
        "address": "0x56055A0D5F7305C9031367446CC02f5a67FA4D81",
        "signature": "0xd952c101dc1196bbc86be5aa8a7c0037b91f98776f070b00284b2a009d4df88611c315f590c89ce54b8c3f5c26e6081f8aac602b2f21d00806571f7ac975354b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x610Bb996CAdc469fF4985a20b22278DdA954E83a": {
        "max": 2,
        "address": "0x610Bb996CAdc469fF4985a20b22278DdA954E83a",
        "signature": "0xf723ae0481c39043ebaa3b192ad6120eda4dab5d4ab1745f1b808494548f55773e7f9a9f6519605950a1cc9a8d8733685f7e69bf06e060bf2f1b52bf0e7ef8481c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7B17fF234a0C625a454ebBdBcbF2DcC34c341CD8": {
        "max": 2,
        "address": "0x7B17fF234a0C625a454ebBdBcbF2DcC34c341CD8",
        "signature": "0x6d3656515c58f2e16ca0015d660020a5328a0342012af78e13756934b35fbc7831a758dfba757e06d59ef404c1d1dbac229a10ea58722c73bce9d7682800066f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03c27a8dD29F8a12470c916D93497e63831995E4": {
        "max": 1,
        "address": "0x03c27a8dD29F8a12470c916D93497e63831995E4",
        "signature": "0x66b85dd9e031694aaa54f84f720e99346bb3e4a729529ba9412c152437538a6a2fe67cb905d5fd8e4a99396f454df16aba98e4d74a72d6e4eb4270b57ef066841b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd579BB0771a1dA9CCAdE9fafb0E733e493A10F01": {
        "max": 2,
        "address": "0xd579BB0771a1dA9CCAdE9fafb0E733e493A10F01",
        "signature": "0xcffc4d78c20738648ada27e4c6ea696f262d8da775fcdd7441215fb0e5b63b5a004daf1d048b590faa692f9249189cf3782bc91e8b50387f72610b053f8ef10a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2b326603Ee2DbE428DBdB60436A3882c3667c0A3": {
        "max": 1,
        "address": "0x2b326603Ee2DbE428DBdB60436A3882c3667c0A3",
        "signature": "0x5dd5adf4b6f2bb3b1d68a78c0df9d68b76fdc0385b3d0575ef20587644f7618c48fb4ced1173865d130cbd274cc32991b3cb603cfdc4dcdfb5e016123893400c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3C69a44b4b92C34Bb23D9f06bB06e8377E44F266": {
        "max": 1,
        "address": "0x3C69a44b4b92C34Bb23D9f06bB06e8377E44F266",
        "signature": "0x5647d6daba4a94459a1ca3a5db39e5fbcdbfa493280386bbc6c6176a66c62f966031acbce04b450a7343f15761eab77f2f427e4318574a39827962eaa0c1dab71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0674751942f1a35597c15F608615Aa1ae0a10169": {
        "max": 3,
        "address": "0x0674751942f1a35597c15F608615Aa1ae0a10169",
        "signature": "0xf74dbac934e8601099e130d4441549a927b57c2b10fa838440ca514b04545d4521d670f264d28bb9e28501d6b4e9a148fc200e41b613575dddb2f6617ce372251b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x59cC5713deF793D87d0fC0FB1946D8642cE260D6": {
        "max": 1,
        "address": "0x59cC5713deF793D87d0fC0FB1946D8642cE260D6",
        "signature": "0x3a9e52d0085ef1ba0da89d6a7b00ed34788a3470f451f88385c970005dfd0fa85d8285f2967b150cf1875d935f1b06e1ef3a6b7f51142a21c6d035bc9c7c32d61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF4367cB33183DC06D17088EC892e09F051722dCb": {
        "max": 1,
        "address": "0xF4367cB33183DC06D17088EC892e09F051722dCb",
        "signature": "0xaff5e1068fc969205d3f545f82bfe07f6d282e9bf46c9dbd0f7f9ee0d6f8c9ba19f39cc1b0c682684b7a35bdbd7866e6bfe9f01b15cd5d3ae4000c7e13b67ff61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x317de35c1c462F191EeB5b989B81F5786ACF0d03": {
        "max": 3,
        "address": "0x317de35c1c462F191EeB5b989B81F5786ACF0d03",
        "signature": "0xf068c39b82edb11a84a23f92fe76b8615ff56203e31d4865fd38d326acff76614a82d5bc459c91183af462813af63d709bb4901869a25c9e24e93cf546a4779e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDB76331e7D049f9002abA0392C7013E37E5669fA": {
        "max": 2,
        "address": "0xDB76331e7D049f9002abA0392C7013E37E5669fA",
        "signature": "0x058fa63ad5d976cbac4fb156d6d8f2c9190a1c223e870f6c825f4a3ea37abe1f1e5379fcd63c9256c762c8e56f235071f4e611086fa842ee4ca80c148fcc01f11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC8a8C22964BE38C2d2E5B54E109eE7E6Cc7765ac": {
        "max": 3,
        "address": "0xC8a8C22964BE38C2d2E5B54E109eE7E6Cc7765ac",
        "signature": "0x9ccfb98130ef5bfa01d63438df4b08e511df78fa4301fd231b85b111a9af015f431e24edda5842f3bad63c0fc23452286152f1f6ef577ef225e49e395e22554c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe145B6FC5f20c87217669a2e7EecBBEF524dEE73": {
        "max": 1,
        "address": "0xe145B6FC5f20c87217669a2e7EecBBEF524dEE73",
        "signature": "0xa90f5b7daace88604c3fffb58835e531788856a5cd7089deb184b057053904ec395037ae56943fde6325e8ba4ece60289818157f8b47b1e00f91d2984d799f7b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0C98aa9C2F4abFdfD65adA324b052faF98763f63": {
        "max": 1,
        "address": "0x0C98aa9C2F4abFdfD65adA324b052faF98763f63",
        "signature": "0xc5dd3ab110bcb36328ef15e28eacd9a639ecc0187508d8998fa251b3073647e86c81dd7e5c39273d5ab6f30f1675d77b08fb13380d748dffab893d2c82dc79801b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF958342A0fd3F0256FD7a6B57377bA8f6838aA70": {
        "max": 1,
        "address": "0xF958342A0fd3F0256FD7a6B57377bA8f6838aA70",
        "signature": "0x738294137712e1d1b6d95b3009ffc5560e1310b5bcf098df0c2611c93381cb0c51dc9cd8f64078e47ef2c4d10785e082bf9f6f5373181e7ca6cf90f90c4cf7b81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3391c1c0bB76e08BECe21d0D0B9800F95732cc21": {
        "max": 3,
        "address": "0x3391c1c0bB76e08BECe21d0D0B9800F95732cc21",
        "signature": "0x9612a51c84deb7b91db4981e7ba7d53738c2b27c0b9b3bc69cfeba96b16fba806cc46d3ab1906aeac869fd3e940e4971b4337610f2342e32964ed70d72a1fc771c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0238569976376519A00F0A6db508633D76cAECC6": {
        "max": 1,
        "address": "0x0238569976376519A00F0A6db508633D76cAECC6",
        "signature": "0x861c6e6ca50b1e3f9f0ee354c67f876cdedae39a8477fb3846cd0330162ecbd4745be4440501d704fe6f261787eab5e9ec29bed815342759e120c7e643c9b9a01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBd1aC588F0BA73b989AD21940079657Fc7F2bBC3": {
        "max": 1,
        "address": "0xBd1aC588F0BA73b989AD21940079657Fc7F2bBC3",
        "signature": "0xe3f01b68785eeb8bcbfd4e807735debbabb345025d44f3c86db09fbc93e6ea0a295a0632b5d4958f333954d3121f6d27dfec478fa6c1ebb32ae4281167d516b81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x98DF6C33A70c8A5559E156176cF67A9A9B435057": {
        "max": 1,
        "address": "0x98DF6C33A70c8A5559E156176cF67A9A9B435057",
        "signature": "0xb643c0da74e37d1b5cb25378ef96bcdb37a75486e83b460c251ab8559980a54b3c7355d53a29f03ece325a16339da95e3aadfe9ff31df0f9016cddf7401022c51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFca4431D3E8216977B633532194dd69e5aBAC501": {
        "max": 1,
        "address": "0xFca4431D3E8216977B633532194dd69e5aBAC501",
        "signature": "0x38e35237abb7419c92ac12a78af4941c5a8ddeede6cd843b7cf2a6e6e14484b57864becbea01f728fa844c3288045e2906feb6d373852c47eb16ddf89f61826b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x65aa9d769eDbcc170acEd9dcEEd464f19ED755a7": {
        "max": 1,
        "address": "0x65aa9d769eDbcc170acEd9dcEEd464f19ED755a7",
        "signature": "0x37b36f05f3e2b46b8217951b8556d109e2142b644bbc66b839347125ec7b93360da9c16b9dc368b2481bb61382149444308545fd0a955cde5305133634eb95bf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCC9C183aFcd723aA6c5E5b0219D62c99A55169E0": {
        "max": 1,
        "address": "0xCC9C183aFcd723aA6c5E5b0219D62c99A55169E0",
        "signature": "0x1a3378b9c8f3124c7801cd15d771c184484bc3d29b80edeaa267d738b35f244e6c6a8616a878cac6843b6418688730c6e0794faf3ffedaa79b9355abbfcef13f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0474C08a98f6845F0aa857D2eb411732Cf543dF4": {
        "max": 2,
        "address": "0x0474C08a98f6845F0aa857D2eb411732Cf543dF4",
        "signature": "0x36bfd6154ce739fbb52f27f6b8b42ed1fa65ef25231988199bc0f6aa76bab765198ff298eb38f5a39a39257e05f904997f810039c1b83b0760af69453b3ba77f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2B473153b56Cf8f398C2A5ffAAa92DDf9cBdB011": {
        "max": 2,
        "address": "0x2B473153b56Cf8f398C2A5ffAAa92DDf9cBdB011",
        "signature": "0x2b11ad9612b837ce918e4f672fd0e287ebef78ec90259124e04b490ede7f64106a171568b11170b390d8b42e00ad108665028ddf389588b18e932535939e96461b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA2d2116370a4a417dE23c71fdC89b13d1ebBe3D0": {
        "max": 2,
        "address": "0xA2d2116370a4a417dE23c71fdC89b13d1ebBe3D0",
        "signature": "0xc0f709696636f1fe279a1866896711a66acc8043d3a4a8f6d5fb5a67641506000839dfd20169caf33a3772fa76fedaee051ed6c873073892762c0c6e43ff87921c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x669a95bf33Db898e16aaC8f75B9892edfb7A7d01": {
        "max": 1,
        "address": "0x669a95bf33Db898e16aaC8f75B9892edfb7A7d01",
        "signature": "0xf052198956099161bd07a48a74996fb61887027b2bfe3ff81af98bb62c280cc2059b405e21f39460f532fd3f4ce70c1969c5a2054c16b8e1422d3d458d65ed9a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB93e5c5aA1Ac58B1Aa94Ac1a697fFec7c911f82A": {
        "max": 1,
        "address": "0xB93e5c5aA1Ac58B1Aa94Ac1a697fFec7c911f82A",
        "signature": "0xcabefb0c12cbcb3a49f39dab731fb3f8617f7f15c711bb5b18b8316f67da02912206df0f03b428e4552c3484d6c3fa1789bcaef0d5140e900d0e93be73fff3891c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8381176d4F288Ef45754F0226689554d4000dcAd": {
        "max": 3,
        "address": "0x8381176d4F288Ef45754F0226689554d4000dcAd",
        "signature": "0x7a33918771f9b23da359748e29eb8c0db7c94b668fe5ae237cec77a159bbbfa36c83a860591f90fcdefb38c41c0b9c4d2d0fdd811398b8e734e0df5aeb604c6a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x004744d52937393b2aa742eaa692eEFF1435CDac": {
        "max": 1,
        "address": "0x004744d52937393b2aa742eaa692eEFF1435CDac",
        "signature": "0x0a19e228c619704eb29a55e3817665fc741e73ec6ea568771cab2d26e7cc91127d243c82c470eda9861f895c8ed4445a48f03abc08eac9983c6432296a61de461b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x64D1a936BCC768A928CAF0f250668cAC10e5A08B": {
        "max": 1,
        "address": "0x64D1a936BCC768A928CAF0f250668cAC10e5A08B",
        "signature": "0xb74c8d2c33c24dbd989e7ae365bc3305f8205f726668bdae305dc34dfca667582f44001c6bbf025436ffc70a6a8ef9fabe4f854b915bad0d0c64a3e8aa6cd9491c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5d73ec54695195860cEe8febb55472c8ACF2Ba28": {
        "max": 1,
        "address": "0x5d73ec54695195860cEe8febb55472c8ACF2Ba28",
        "signature": "0xe2076227f72b1ce383ce5a1f0620f5b012c4c0820f55937a3bf5256a12b582312a4b44bb434f5d7d8805e3c2a1874ab68a2a9f2896b108dd9c68721cfae8ec7d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x01cF1e2F3Ccd396034FA53B9932bE82Cc9C7BF34": {
        "max": 1,
        "address": "0x01cF1e2F3Ccd396034FA53B9932bE82Cc9C7BF34",
        "signature": "0xa5670085966bada03462e53d4569a482bd0cd40679c5056818ac3e10c52372c91c819885e79322f9c79b759f298a92b370a458c7c96888281ffc6eb92b60672f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x744FaA7B4bB5F3964946f15BC5B6FC76d2540973": {
        "max": 1,
        "address": "0x744FaA7B4bB5F3964946f15BC5B6FC76d2540973",
        "signature": "0x0aeb7e94883f8fe4d9243ae23cc81540259fdc5e1c8d3a86721f5e239db2cc0269b7f792249a969088185f984c55c1e02f2b7c6b8e4f1995b0a2ed599b02a61f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd7382dE5A85485Da6a79885E6757B108EBebc758": {
        "max": 1,
        "address": "0xd7382dE5A85485Da6a79885E6757B108EBebc758",
        "signature": "0x7a4b5bdac01ea911af34643ce1e08e4ff126634f327d25d268fa3d226b5e83da0ea6f03e8b083ae4654ef9abe6708ec8a28c251ae90c194f68e176e72a4da2dc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x83f6CDa11E74c75a5f0FEf8be0E1693ecC314f35": {
        "max": 1,
        "address": "0x83f6CDa11E74c75a5f0FEf8be0E1693ecC314f35",
        "signature": "0xcf0fef3684ad6da1f9ba418e348d19f1c6729d68a332307c4234680080b2b9101e3a189a47986cc09694c501f9ecc91e760acf3abc0cf53e24792b9969cd62d51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9B4AB2a78B730207D734c6dfE8C5d87818A42489": {
        "max": 1,
        "address": "0x9B4AB2a78B730207D734c6dfE8C5d87818A42489",
        "signature": "0x43ec377ec52b28546642e108a8d61772c1d1fcf4fa91bed2910a2d7b8e4e10145a72b46027b7c369b854640593c0a076583f9a2805119cd64d78616bf92aa5c31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5E9544be579F5241C695D42062CE95f1AB12b14a": {
        "max": 1,
        "address": "0x5E9544be579F5241C695D42062CE95f1AB12b14a",
        "signature": "0x8839fedd3aba834afe4b7e24745c610a027cf2123bc396ab655ca458d1c8f5845237e0884b654f2214350a48d20d8fc03aed79aa329b89f26b4af05e8a401a181b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeD4AA93D5f556adB2CC6afb3aB18faE07D01E869": {
        "max": 1,
        "address": "0xeD4AA93D5f556adB2CC6afb3aB18faE07D01E869",
        "signature": "0xeb5723660311304d92383e1275881578ff30806fdb85c1cd4e92d20a6fa492197770ebf2f6c5ebf5b273a64c4df683a4b0209de2e8be5fcc9e18efd43090a5f71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2B461046668b5424C21EF1038EA22D0a238fE0fd": {
        "max": 3,
        "address": "0x2B461046668b5424C21EF1038EA22D0a238fE0fd",
        "signature": "0x4158c127586314bf6e6e2544edfca13065c52841596ed8f8c261021edd08433530576eb7e59e8b4f56b9b6835a10961e89213d891056ba4867922953ddaa2c031c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcaF57B87972ac549069C6112d727d5C42160e19F": {
        "max": 2,
        "address": "0xcaF57B87972ac549069C6112d727d5C42160e19F",
        "signature": "0x9d87b5a9c2f46f2de03ba518d11379c6bf99c886c89eec79d03c705eba15d0d8523e88158e0e1e78b149e1ad6b9f86d48ceb79fe6f7fe6e050be5679723b533a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x96bA57265714E67F1A24Ce6F2dBE924De8D3E714": {
        "max": 1,
        "address": "0x96bA57265714E67F1A24Ce6F2dBE924De8D3E714",
        "signature": "0x417867ddbf89bfccd57948aab611c440bf6859f93ade7930b6b52768c66aff5c10b740549d603f708a0aa9b6debbf620b7f1d2ecf6b2dc51ae7d6fdc61949bb01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4f71F48cf4726D984685fe26c851031826363979": {
        "max": 1,
        "address": "0x4f71F48cf4726D984685fe26c851031826363979",
        "signature": "0x4e4f607be15d231f9d367184c4e734657f5b0c0912dea1c47430f0ac4d7ddef52ea7eb2532c0baeca2ea75a2e9e366e2d748ab330b3f0187c37905fbede5ddc91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2E76c7bB897965131737B153fcD75106c2449dF2": {
        "max": 1,
        "address": "0x2E76c7bB897965131737B153fcD75106c2449dF2",
        "signature": "0xd940c3a34f8a0329b8add4414f0deb2591e6f1784b50865b5d0a6796ba7ff02a086a5522a914a57df1256f32e8486fa360680da12ab84580e62661ebc8e57e821b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7fEe6c5812b8fDa071a78000Ec18370fC6a3d2E8": {
        "max": 2,
        "address": "0x7fEe6c5812b8fDa071a78000Ec18370fC6a3d2E8",
        "signature": "0x701d2d314bff8e64bc5b5fd3a63109d62133886ed315e7f16df983f1970a94b6590a2cfb72827d2482034a66bf5ec6043c0dcf25b242b3a03ec5583a5fa5a3301c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7ef0C31Dd630633F8f671F6f67a8aC237ecB8336": {
        "max": 1,
        "address": "0x7ef0C31Dd630633F8f671F6f67a8aC237ecB8336",
        "signature": "0x3dba1a7077e0be3507d8058c99d4f0fa35de44eee94f2b20680c89c65082bd7e5633e0fb977616e04dd50132094826243aee47061ecc392e56abd98e61caf3d11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8fF49f9f637A3e81157359EeecC57FA13b251c24": {
        "max": 1,
        "address": "0x8fF49f9f637A3e81157359EeecC57FA13b251c24",
        "signature": "0xf8150430e830b50c2e69dd725f6996d5a39314a3f2fb863ea3dd1aa3972b79813c2cd3dff0156975ef1d114e8b8fb75f546d65cbe6091a256fe3a979ff1b7ada1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdDB2d308Ebf66a7Df99C8D3Ad2D3923E1ebfE8ab": {
        "max": 3,
        "address": "0xdDB2d308Ebf66a7Df99C8D3Ad2D3923E1ebfE8ab",
        "signature": "0x3159d8c5c44462d867bc0a0b11e94e783d423d2446efda185e09e0354a1a5a5160cbcd4dbe20b565233b0269eba6eea4aa670054c87e617d6a55928aff1db7841b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdc5742c5A1DA0f9356a442eD1e4cA5662235DFe8": {
        "max": 2,
        "address": "0xdc5742c5A1DA0f9356a442eD1e4cA5662235DFe8",
        "signature": "0xd84cfdee5a665147db766304e3153a8c5bdb6bdabe4c915c6d37cf59b7bd3b853ec0998fe1ffe037ac9731cc1e8c1ba916806098e74860ce3bda46afd31d50f31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x54906e73610920EC7E92dcB6ce2AC3B5C6DAcAC2": {
        "max": 1,
        "address": "0x54906e73610920EC7E92dcB6ce2AC3B5C6DAcAC2",
        "signature": "0x2cbb13e6747f70fbaaae4e5fdae7836ecbb498ee9672c3e257d04d9f2c55aaac41e7407127c7d2f60a1a513e68a2bac81c2568bb1a0d790d3f2132962ae7868b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2a0DA66Fbff0853f3dc878DB16f8ad0c3416C12d": {
        "max": 1,
        "address": "0x2a0DA66Fbff0853f3dc878DB16f8ad0c3416C12d",
        "signature": "0x904ea79788b4f35ae2855a7c02176e5bb63fecaf9d28819ba7f992d6a02729e40d3ae25e9c3d7359ac4e7f42083b2e49a630296227341feced1c406a87bdebd81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5bb3D5e0c3992354307f48D4c31F8abC933FABb9": {
        "max": 2,
        "address": "0x5bb3D5e0c3992354307f48D4c31F8abC933FABb9",
        "signature": "0x5316c45fa6ccece99893f3215de9f99342a3aff0ac48902a188efc607dd46c9974b86dbb3da68cc03d21377a1fe70bfd238b0d228705d7b985ac138812a468031b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBF9e2d8a0BE736d3a8d8b4eb668A3D041f467cb2": {
        "max": 2,
        "address": "0xBF9e2d8a0BE736d3a8d8b4eb668A3D041f467cb2",
        "signature": "0xb07a7fca88b7f535b1c0ea56cc0353952dc6643c53be9a36d538c51b1c37eb92676e3d19dafb3aab55bb1cba774ded1b8e226523836264ff2cab9e0567d560861b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x04681Aacf56bDa136095AB0BbE54F350D7653954": {
        "max": 1,
        "address": "0x04681Aacf56bDa136095AB0BbE54F350D7653954",
        "signature": "0x6870ec5ed366c9a7f329aa8ba3f0f77c6f27b7a1d53839053a39b25a0702b2e74f52b959d6266ebd09a2c2156ac0402dd53fbb99521f313a35ce22ce2d97cc4e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa954b6629e036379B6d45023f4e7683129c38C98": {
        "max": 1,
        "address": "0xa954b6629e036379B6d45023f4e7683129c38C98",
        "signature": "0x6ffdcaa933f33afa19fe2421301d6feaf648b7d41ebc8a7301aa2d3f952e9c00309e87c51572eebbf0efde2b638440f137bbafc0f9f434a85171c6661f3b7c811b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdAb43Ce668a5e4f915410251185D9e4669Dd9B37": {
        "max": 2,
        "address": "0xdAb43Ce668a5e4f915410251185D9e4669Dd9B37",
        "signature": "0x33b3c988bc27c35d875156489afd9c57ed23213dda7a8d6feb541ee780c8b28c456de0647056195ce553a57fb01476e9016eb7314ac3bc9b6d270d0a40a5e4dc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9A237d2c4a8c2203719Ff179c9423Aed92751313": {
        "max": 1,
        "address": "0x9A237d2c4a8c2203719Ff179c9423Aed92751313",
        "signature": "0x55788c374122805ec26a689eb7e9fd6ff780996a5e2bbe92c489db0c163f73241d71dc25020177a5f333f75a39e6b6dad224602ca020f91c39d8d8644439f7621b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEee78057F0238f15426891EF46Fcf8ddDFE57503": {
        "max": 1,
        "address": "0xEee78057F0238f15426891EF46Fcf8ddDFE57503",
        "signature": "0xd43e026c9f70fd4b498a63ea8232ef687bcd628d999f2d61f1bf84f88448f089293f9ed7ce7e63f65d0156e766690aa1f5f78a3874601d536e8b37865a9289e21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x82cD2515a119d9094aDe91E0F592c849471AaAc2": {
        "max": 1,
        "address": "0x82cD2515a119d9094aDe91E0F592c849471AaAc2",
        "signature": "0x47f24b3457d1ab6b239d4a976699f749b0903c10d5ce4b2f8926a98105eb942d5704f10f8b27a0e287ba3a3610316898c8790776376f9849fa747904f421b85f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6cAb7A83Fe322c63609d77B50530286FaA2d06b0": {
        "max": 3,
        "address": "0x6cAb7A83Fe322c63609d77B50530286FaA2d06b0",
        "signature": "0x777c80a48df6037840cf69a1ff94f3c636c63313f3e0dbf83c97ed829ec03cf0408415a44878160d0d2650e030d4fe8cf1441fd94df7eeaf1971c23ba2c7039f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfD5692E32AFeCB78f1351C38a85a1B4D16C9bF50": {
        "max": 1,
        "address": "0xfD5692E32AFeCB78f1351C38a85a1B4D16C9bF50",
        "signature": "0x67d1c61f5a76dd7ee0b43e559e8f5721bf74b9f84a482caf36fc32a84d4bd24466828d09816a24cb129e5e19b7367467de870fba891524f0b9fe3cd23ab771fb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8365236b8b29EBe2A67eE167E605cFb7f28bd393": {
        "max": 2,
        "address": "0x8365236b8b29EBe2A67eE167E605cFb7f28bd393",
        "signature": "0x269e11384605b3a52bfd6343f0b94a5feedc42290cca8d3925ec33a355b34dfa5a0e1a113e78b8c28232b2518a5ed2ce65fc87a1e55b5f2ff88f165aed15c8311c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1B74E79266B75eB0E4ae8C391383F1264AB71e96": {
        "max": 1,
        "address": "0x1B74E79266B75eB0E4ae8C391383F1264AB71e96",
        "signature": "0x646e6b29a7619f9d62aea4c5b19903d0407297e1d51e82995b81934d7ef6290178de46fd3f07553f4f443ebcb2193b6370d86c45551ec98d77663e3623f2ff9f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE0450e07C8645F87617D256C479630FD304F225c": {
        "max": 1,
        "address": "0xE0450e07C8645F87617D256C479630FD304F225c",
        "signature": "0xd06a94ad6670cc4a344aff77cf9fa4cacf365fb2a61cdc082da942a222b8e0fa61e69e7bfdebc19c405f16bb0303ed0431d1f89730a1fd8d02dfda416257d9e01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7115e88f39F95146C4dA7d97d45522eAe7fAD8Ea": {
        "max": 2,
        "address": "0x7115e88f39F95146C4dA7d97d45522eAe7fAD8Ea",
        "signature": "0x5d7cff00880137a126e6c2ddbce731f70fcd7df7bd573890087fab786ebd1db528336ad1ecfae464b24d24770e8886e21b8bfe99bfc818d7b65d8ed6418c818f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5F4bC750432d6EEf261da9C9441c92008fdCcDDF": {
        "max": 3,
        "address": "0x5F4bC750432d6EEf261da9C9441c92008fdCcDDF",
        "signature": "0x4f8db781ebd1f60f4de838a6adda99327a86c2b171dd1c32ffd852c9ea3aa16321b0bb7fd98b0572b043d100d41be5c493dd01a625d81877d7367b2c890e2db21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7c5b40058473Fc813Ce0e024dcb0c9Dce3B84BaE": {
        "max": 1,
        "address": "0x7c5b40058473Fc813Ce0e024dcb0c9Dce3B84BaE",
        "signature": "0x0161d08170142c7aa7319c2016d8ce9df3fc5d578eefef2acd6a66370af4e50626de88f4286c65eafd1fff33f7f234c0b35b4640c59702a8d29a89ca2cacf6971c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x14BeE91EFb79A8EB2332C31177FD3A61481cCc99": {
        "max": 1,
        "address": "0x14BeE91EFb79A8EB2332C31177FD3A61481cCc99",
        "signature": "0x3db0817bfafddd3ab7b22066d6dedbef508efbe66be252321558ccdcb16f0d2c294ddfca887531cf36c48b5421a67031ce51aa7e47f6d9f1507574a28cc435531b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x833C06271fcc6A3895eA0D51Ecd0d581adC2f3b9": {
        "max": 3,
        "address": "0x833C06271fcc6A3895eA0D51Ecd0d581adC2f3b9",
        "signature": "0xb8e4dd2908781ba8805497d64c3f5fa88a101e6b57bbb8aa9e6708362dd730f77f9b01c23262672279cd55c5040a96cd14a481aa1513af48f0990bb81e6aa3611c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBec938b83D289Ce61476BFB7D71419b7e8c1acdE": {
        "max": 1,
        "address": "0xBec938b83D289Ce61476BFB7D71419b7e8c1acdE",
        "signature": "0x2fe2d0c55dccb14c6273b2a3ff22884f56ae965cfec1ac40e4339f2fa784f7c15a979cff91f2e2ada03d3314dab479fa8dfa9bae8872b97968ef5aad83eb6f151b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD4A13A41598e1404a019D025F14a60C796d21aa9": {
        "max": 2,
        "address": "0xD4A13A41598e1404a019D025F14a60C796d21aa9",
        "signature": "0x1e7070f2dd402c9188b01aa4931255fc1b88e3a7a804f01c6e02c16a6a1e2fde39a952b611f5916cb322e23055d38cbb0fbdd999d87f99197fe17f50ce0064531b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x55E29AdA6fA377D75cAAB61e391aa5FC188960b2": {
        "max": 1,
        "address": "0x55E29AdA6fA377D75cAAB61e391aa5FC188960b2",
        "signature": "0x0f5b615ff41f77913ab87a37c8561ba311b03a74987f16758db9e0a010887bcb585859e656ccd5b0864812848c0632a2fef7a13679f2ce9a64cae4bad2da571d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC69751dCdc504fF6447BeF3b76ad2fb79719E216": {
        "max": 1,
        "address": "0xC69751dCdc504fF6447BeF3b76ad2fb79719E216",
        "signature": "0x578317fe70ee1692b7cb1e7600af0d9d4ab4d50ade6310a1ac2432f773c1680e65d20254e5a2d58eac0b480e8c9fc5d2703444ae79eac00f227cc25870366b601c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7beBe7982915E9C40C67Cf2f4AFAE9A4D22F6383": {
        "max": 1,
        "address": "0x7beBe7982915E9C40C67Cf2f4AFAE9A4D22F6383",
        "signature": "0x3bc582a3fdd305210c83fc427b69c4883a3f619f9710f6863675fb37b326c1c41e9e5375070d440b121da2e0e64c6cf5bd237be612069a6c8d62fe0c9a4f0c581c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9e2AC16E1489c0963235A98909559d5CcdEdffe7": {
        "max": 1,
        "address": "0x9e2AC16E1489c0963235A98909559d5CcdEdffe7",
        "signature": "0xbb8f7252a07a818a4c661d3e9afbee6bfa31b5b38ae0ac8765f6b7f201b088ee4a4f76048dd3bf20632f1ff794777951eac9470bdd8ff1c3d0fe6cd9af1548091b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBe316987eD1d5105beb5E6Da20049B4FB023Cf1a": {
        "max": 2,
        "address": "0xBe316987eD1d5105beb5E6Da20049B4FB023Cf1a",
        "signature": "0x349075ec3303a70615934021980c89b415a9ab77b16714b4200f9e4b787ac05b1659f179b7c06f869ece93b60bb664b2c133e6745493b8932ce02ad1a0f305771c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x36CCb4aD668a3bC8b00CaeB74dD93d1C70471deC": {
        "max": 1,
        "address": "0x36CCb4aD668a3bC8b00CaeB74dD93d1C70471deC",
        "signature": "0x4cfe782299a68319a8e8d2fe1ab49f3f49ebf2f6a73f9e88916bfb640c77d9d610c6c7f152aad1c98f47d57a92f5242bd6908dd1c1f9f73f9978a78c14bee0b21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9AfacD94d2A328a89A993b1D7AB0aA75d20D8884": {
        "max": 1,
        "address": "0x9AfacD94d2A328a89A993b1D7AB0aA75d20D8884",
        "signature": "0x182c3c99290f3f6f014e9e0e10898e3761bcbaa6d989429ed57b705f649dcc664dd37ecc3c21629bbc4cc34215bf6c5cfba44d165f1acf1392a5c95fcbe359871b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB050FdE04c1E5aaed62f94F7aC14d5Ed9b166057": {
        "max": 3,
        "address": "0xB050FdE04c1E5aaed62f94F7aC14d5Ed9b166057",
        "signature": "0xf6af4493360509018543ef623b1690de242e400988122dfd59b04a40d0a30e0247e12cf6318a2545c1d5d079f2e35b6ccfef0d39d332c8fc88116104c95550601b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x60540D4321AF4928800811d3F785031c861e9004": {
        "max": 1,
        "address": "0x60540D4321AF4928800811d3F785031c861e9004",
        "signature": "0xe103f215a02cc08e35272f899f7b863e2706ed17e5f4a51da988f4cb6eec91da11f9662097b68555d8f1dc434f91a1f8d2e6fe2f51ec6ad283f791e2680779f11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2E5D9552eb7E449465528E02774fBc1785677f88": {
        "max": 2,
        "address": "0x2E5D9552eb7E449465528E02774fBc1785677f88",
        "signature": "0xa4de89001d2a9e89599a49cf6f85ce7c54f3782e60502329e9f3858d217d7f3e7fda714068e9367f3e6fe2e573740a994758c2904fdeac5975413d5cc532c0b01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x11eb96C7262b06612ec14e32A599A6eADE130f24": {
        "max": 3,
        "address": "0x11eb96C7262b06612ec14e32A599A6eADE130f24",
        "signature": "0x422da0d01f8a0bb8366f4154dbf2df56903233a0d72348cf036adcc8f1f5718f5b3003fcd32cdda54ebb265c685f625d073872ce3027d31f8f1ac82b2eac13601b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x023D0355195dae794095e87824e538Ba60E3c360": {
        "max": 1,
        "address": "0x023D0355195dae794095e87824e538Ba60E3c360",
        "signature": "0xa4979ce5b88b75ad64f1e91c39daeab8431781344038fcbc9c845ba088b43b5b0d0de815d00f721fae74c740e8a509f3fa2c7e8409ddcf08b9c48752d22e0e561c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa79C792d2D53ed58b9c1CEE86b26b51c6AB722D8": {
        "max": 3,
        "address": "0xa79C792d2D53ed58b9c1CEE86b26b51c6AB722D8",
        "signature": "0x9c00eb5a5e721e01819a6f49a157fbc614b6d6ded3761b0f775fb2826aec51df1e194c428f00ec171a7027aafe44e0e2960814cf559835147a8ffcc681bc765b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeE03a8356e5d3D1F74316b9d95D1E6f2736Ae4C8": {
        "max": 1,
        "address": "0xeE03a8356e5d3D1F74316b9d95D1E6f2736Ae4C8",
        "signature": "0xf9b805c08103278e1e7ffaa3bd76d70b8d45fbece4a52bdb6f00bad9d90bf8bf789f8f970817bd151804e1aeeacf9c8f44df0b9f5504016005abcb00995d3ec81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7E1E02dF7Fb78e0c2a77F271faD5C4f426b7f882": {
        "max": 2,
        "address": "0x7E1E02dF7Fb78e0c2a77F271faD5C4f426b7f882",
        "signature": "0x5ef30ab101c12ab878679b376f93cc97c0fd6d82d8c250960bc07cf128a21d161ba447588a9834064c72deee0739bc805100c993705bc8a0b83da27ad4bc24c01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03C6547A6935Ec26dc9c9440bbE758afB2E06797": {
        "max": 1,
        "address": "0x03C6547A6935Ec26dc9c9440bbE758afB2E06797",
        "signature": "0x3f6020853634e7e58083cb0a109adc880add2165252cbd25e29f1a8b49222404027836afd26171852be6abd215cc28a65382c808ba9312ab8a32287ed9c17afc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6af3cEB3C9eD2d2F93387B9d80b8C44615A7fa3d": {
        "max": 2,
        "address": "0x6af3cEB3C9eD2d2F93387B9d80b8C44615A7fa3d",
        "signature": "0x1ef766c5a084980ad8916a78c753e8f9c7cad76608de0bc970dd7a449625e992385bc45da4e0e30ff8c38040f77a8e55a4b5e0fdf825a23afbb38be3dae0eaf91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x71b4B07B721F52122a91E2F8381Feb923aBabdfD": {
        "max": 3,
        "address": "0x71b4B07B721F52122a91E2F8381Feb923aBabdfD",
        "signature": "0x9009b8dc05fbfe85c470b46ebabd77f8ffe220cb93456680a54d4e0d3a69ebd16fb56cf97b4fefc08b2d10a881fb4580770c5599a29e8cfe57662fa6b2ce47ec1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x46122be09CD38aE8F07FFA27C7BAfedC3ae62B68": {
        "max": 1,
        "address": "0x46122be09CD38aE8F07FFA27C7BAfedC3ae62B68",
        "signature": "0x617d26cd7a51deb3619824cae7bfb6804ab05cb068d16fe35e8d986d45e02290776855755d44deb3e338f2b79517937af783a7707984ec01e81246e7f141fe201b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x32Bc4C0bA60a861c0C11AC56687D295dbC310Bc9": {
        "max": 2,
        "address": "0x32Bc4C0bA60a861c0C11AC56687D295dbC310Bc9",
        "signature": "0x40b5e5003f2a65acdcb438ca23f0635201a0ee32b318d083a3f6230ed2fcfef9226e31958932c8685d1d126e92f4c3aa584eb522aaeaaba55dff52ce0f1404ed1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6B348B9217fF65Cfd38A790A9e53064AD4aC8891": {
        "max": 3,
        "address": "0x6B348B9217fF65Cfd38A790A9e53064AD4aC8891",
        "signature": "0x2d4a03a908c1aa71584766aae6b6a01a20ed3871e9779dca2d0b8020ce3bc32578ce3b917671b7e9ee64fbdd9ec18a76a1ef6b6a6c47d8050e217b66b4b4ae1d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEE31d60c2b92acd23B72D7bB88a3C6b23DBDC821": {
        "max": 1,
        "address": "0xEE31d60c2b92acd23B72D7bB88a3C6b23DBDC821",
        "signature": "0x59c6bd7d6db301bb57c8ac87bc339add3d9f655c28622cc22dff59f50e8675f1198d900ee3937990cb89f8ffecfc9227b659152a75c17d41ff71652a3aa71e151c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE8e570a109D78E66DF71681aaFf7dD738cc89384": {
        "max": 1,
        "address": "0xE8e570a109D78E66DF71681aaFf7dD738cc89384",
        "signature": "0x42dac480b7dd7720096d30be210ca1181032824285dfe3a72eebca7ea580113b7a824740047ed5cb65eb76991815de2ce794e86c09bd724c9bc75c7b8681043a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6D608ea4dE61F9A331D725749B7e283fbE7c534e": {
        "max": 1,
        "address": "0x6D608ea4dE61F9A331D725749B7e283fbE7c534e",
        "signature": "0x0797de16c107e151fc4ceca8cccbe0835598703d441c80c6d9b77055394d065862288f64dfe0116cf9cfdf2353678110605fe6eb128f1ae2523792d7dff20d581c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x84db18F29FDE971Ad75567AcF3Ae0E5cD11FC462": {
        "max": 2,
        "address": "0x84db18F29FDE971Ad75567AcF3Ae0E5cD11FC462",
        "signature": "0x61ce61c3fcb1fd37d1043706370fd8ee575922e74a66b6bf45e282c2f03748c33d4388f67c6aa4671dd81811e2edac0f889cb681b9c1a785e0d1cf4418d4a6961b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x45Ce93D93717Be2926a062400E1B39ae188Ff8F5": {
        "max": 1,
        "address": "0x45Ce93D93717Be2926a062400E1B39ae188Ff8F5",
        "signature": "0x37cc78ae6433f75e5bf74c53329b7e1c9526ec1a6be6003381a1c2a587db67636341249407ac6bafd280dc229edaffa57b59f9b45a142d9812353d10baa5dc971b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd4aC486D86B19176458B0a8548E0A25d56531D77": {
        "max": 2,
        "address": "0xd4aC486D86B19176458B0a8548E0A25d56531D77",
        "signature": "0x4fd84407b4a529d05d7c0ce93f8924896b1437403cd052238d87e12afca1bd97740e0d4b51115e2f38b92548528e0cbd82885399322a750d9f1e4c82af99ae441c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb1F3a539D6f8D99f5dC470B082E5935d4DD1080D": {
        "max": 1,
        "address": "0xb1F3a539D6f8D99f5dC470B082E5935d4DD1080D",
        "signature": "0x8b300712d45a4de57726ec5e722a0967a17ee7a4a91cbbda0f419c74199a08e26e126da1d2c6163252405ffbe55204a2e76d8fbf220eeff226c4f61f7461d7741c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x231e21a1cfFfe58862993b29c02875073124c776": {
        "max": 1,
        "address": "0x231e21a1cfFfe58862993b29c02875073124c776",
        "signature": "0x33c86a83a2e6ee4276d261799b33c9d0821188d2a08561ee513e4a9dcd1bf5e05f8e0411cc47977074adfa913110498ff3f5b3463547985686460c7a81cfbe4c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x564e79cbcF9De92991505F8a676056F6EA990F18": {
        "max": 1,
        "address": "0x564e79cbcF9De92991505F8a676056F6EA990F18",
        "signature": "0x06a923c47c632c47944515de75fe5b2689c72fff3e710fccb5f9ec47b053111944b501f8e34e84619eebdd0ba2db7a0e1058afd466b7e221fb2a1f1054fefdb81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0720545068c6EFF57192FE08B89b8a5D5215FD0F": {
        "max": 1,
        "address": "0x0720545068c6EFF57192FE08B89b8a5D5215FD0F",
        "signature": "0x426c4d25a7557311557eb261715ba605d7b8bf0727c1521124db2b538ec99ace429af1f3c5257239b940f2c7095bc42a68446897b2c9eaa478399af101d8fb471b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF7ec405B406761e4dED2B851110e1bdcA3873cD7": {
        "max": 1,
        "address": "0xF7ec405B406761e4dED2B851110e1bdcA3873cD7",
        "signature": "0x0d604bad3dfe81c363527ed54a07c0ec58e5ecb16af32288a8248cf92e7797bc7f29c5f7ab3940e24f2321b731d0c65f74b2f87a0da96eb6771f742cd7767b121c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6ED2e54b7C458329BfdBef80bd44813093eF737C": {
        "max": 1,
        "address": "0x6ED2e54b7C458329BfdBef80bd44813093eF737C",
        "signature": "0x7e73a64c2bb3f2e736d8bea230675bd8c35517e4d68a608e9cc9868959d25f8b0f324c4a0ef196ec5d389c319ccd8d79a682d2690c35c19d6fae498b8ff982e51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4B82Ed644C14DD61B914Eb71F680F1213Ee5b838": {
        "max": 2,
        "address": "0x4B82Ed644C14DD61B914Eb71F680F1213Ee5b838",
        "signature": "0xb3016df35800e17449f8269c13f4d34d1c7e02321387fba8b0992a11381fc3567acc0f566d25d756d95890d9a88d6fbbb67306987d76af1fa36e9f178337a8801b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3ca736eAad08BD4597DdceD3B267Eee72bf150C8": {
        "max": 2,
        "address": "0x3ca736eAad08BD4597DdceD3B267Eee72bf150C8",
        "signature": "0xd11de206083ea2506ced41a652ebab0269a4b5b620f82af10550334d6c332de54df180a7e3dd0ac8798f0d65543bd7c42b5fb4341629b9d8124673676b1f08001b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4CEf98D34e5BBe12491CeF4D565FFBEb128A4B46": {
        "max": 1,
        "address": "0x4CEf98D34e5BBe12491CeF4D565FFBEb128A4B46",
        "signature": "0xa0032af4460934f353361f7cae3991b489244b64a9eb83f9031a8b533a6c293950a5ce6c1319828c4f0997f25d364aa5942c913cd09d8bab507f37c223d878251b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xACD144707995F6AF32D4961EEaA2376A19825f4C": {
        "max": 1,
        "address": "0xACD144707995F6AF32D4961EEaA2376A19825f4C",
        "signature": "0xc8a015ddf866b39f0ebb9e4e6d40c3c7fc1e2f983b2c890baa282abbeebe933e59c3ab2de820a60989d91aa0befba8bcb8a2eac883b7aa6a657d821e6d9c8aa61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4CdF49A2baA2e595574D0aE929F9DF58F84621aE": {
        "max": 1,
        "address": "0x4CdF49A2baA2e595574D0aE929F9DF58F84621aE",
        "signature": "0xb6e528352799c1af0ee7c2ac881becddd52261936b138e16a20a4fbff8f2671917b338dd2115fbd08575cc5117938b1b073811a5773957b024d1118ccc68dd7d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFc2083f135646E74Ea7087fFf7502b3b159e0b47": {
        "max": 1,
        "address": "0xFc2083f135646E74Ea7087fFf7502b3b159e0b47",
        "signature": "0xed0f27def198648367e99416b934e81589885d1adea1cc4043fbb273bd3443c11c7151a96e5ef66e294fe69f6909e975013ffb00d71dbbfb868ffc6703ec3cd01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA1f0823567C600fD597Acd40bD098C53c7B3f192": {
        "max": 1,
        "address": "0xA1f0823567C600fD597Acd40bD098C53c7B3f192",
        "signature": "0x30f3f545129805cd76044981f5cc102c65d0a86ab600b5f6c70a65ca5b1a9c200a895451b4b7885797eeea421fa89245773321e23c2f02ecfdd9e70b70ce0cf01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC82bAE7bE0290F0C55BFa1830080f2AE59394776": {
        "max": 2,
        "address": "0xC82bAE7bE0290F0C55BFa1830080f2AE59394776",
        "signature": "0x23e6bbc545727ef13779355df86d4313213dabdc961d43451e2c6eed29c51c34355e98daf013d696603dc8eb2fd5837e697442843b365268712c3de3bd06ba0b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x96d5e9ede455B5F871e0c3C483286435Dc135370": {
        "max": 1,
        "address": "0x96d5e9ede455B5F871e0c3C483286435Dc135370",
        "signature": "0xaa0217bfc08e9de4fa34892d4b64937447be82f77f52e453c67838f4ad80626b0475c3fc596d584f66f282adb413070041333c43f8c2ec1e7cb224e3424882cb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03EED5383cd57b8155De4A67fEdB906dC3C9eB6D": {
        "max": 1,
        "address": "0x03EED5383cd57b8155De4A67fEdB906dC3C9eB6D",
        "signature": "0xe8713b535bc3078e8180f35824afa8521249491abf45b1744c56b6cd82aa04fe42ffd0422db8938fc93cca35d0f2c33257e9effe3bab5292013d44e6f8c1c1751b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC9483dc485CB9b389137a0f522D7F97D6b38dCD3": {
        "max": 1,
        "address": "0xC9483dc485CB9b389137a0f522D7F97D6b38dCD3",
        "signature": "0x83875e44dfc3d35bfde34594136b923bfbda254a45a486bc4c703a23fcc2158c3388230c069a2c1b1646639fd29e6ae293756d4d8372788c0c55c26fbb95bc6d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x460A8beb9a585D81e9d7526EF3f1C10443ded892": {
        "max": 1,
        "address": "0x460A8beb9a585D81e9d7526EF3f1C10443ded892",
        "signature": "0x516173bbdbe14d0672a573926e8e289f14fbaaa241153e78cb10354305b2ae2950472f6c45f01e05cf952573e01c39ba02a1d76229b0dcccb7274231d353a6e41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4233C93649444871FdB2a5bB63ab548F41E9a71f": {
        "max": 1,
        "address": "0x4233C93649444871FdB2a5bB63ab548F41E9a71f",
        "signature": "0x1c9483392e7ac88b3da2a872e6b4cbb73509acceb2b2a4eb3e430fecfa8d92da420f32dfeab7720d04298519ebc9644f422b8faeace1db5ad4e83a81a4a16b721b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x30E6181B0291791933d7dC512355841d69262758": {
        "max": 1,
        "address": "0x30E6181B0291791933d7dC512355841d69262758",
        "signature": "0xe025400759d4cc579a03f584837442d5e2d0b56caf003826517573f7b1f326bc5e3b0510e1cad3b15507712715fe59e8f711b5df76023d08fbf2aac1d995c8e31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9e635293C11f5f30a5c581D334449c024099AE35": {
        "max": 1,
        "address": "0x9e635293C11f5f30a5c581D334449c024099AE35",
        "signature": "0xa58b83730c48ab571ec438579885f4c1d46a81bcba67817bc9cd6c01e9b25ba110b6f854e33fe0e6a05712bbb4ff25481bbeeb2091fb2b2d061fd82cce332b2c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb7Df7d1F7838885Ed712A967c77bCFec93F6647d": {
        "max": 1,
        "address": "0xb7Df7d1F7838885Ed712A967c77bCFec93F6647d",
        "signature": "0xafeef710be9431529123544ee8c22c0787f26947bfa2b204fde24b6b33562a9176ac5678e385b73b15d21f44c0b08de09f809f70cb380ab43cf559fa764d0cfa1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x824fcF408c145F28d5FAb58E74c5e326D09AAad7": {
        "max": 1,
        "address": "0x824fcF408c145F28d5FAb58E74c5e326D09AAad7",
        "signature": "0xa77fdb896635ff9a214175819ecd1251837cd5131f1e0823040eb707acbd0743695ae59e12869c4d05cf8818d72ff9f8db75c7d1f111bc0868515d74b62d83ec1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x60519d65cA55529AF5c76B0aa0E25f9aE4891b0B": {
        "max": 1,
        "address": "0x60519d65cA55529AF5c76B0aa0E25f9aE4891b0B",
        "signature": "0xce2138305ce68aab5bbc521eb9289ade1f5284c6957569a793e7a0e1c48e3c174f3dae7551efa4bc686888e3a3a46c18f360bc839d9940af0901d36cc71adc1a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x09Eb0a7d5346de2E58E370C82bA7EC59A65CCE08": {
        "max": 3,
        "address": "0x09Eb0a7d5346de2E58E370C82bA7EC59A65CCE08",
        "signature": "0x9f99c72cb2a9a3b2ca21eb2d085798acd3c90220dce879596f7db5ec8981a4f576fb13f6d558c7a3c2b93236b2f2916fe75b17d023a9bf24a22c07aaf68077a51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3B201926eE69249Dd4D2cb8A0e17644B4e5D79Bf": {
        "max": 1,
        "address": "0x3B201926eE69249Dd4D2cb8A0e17644B4e5D79Bf",
        "signature": "0xb82d3a3c3619b39782e1887a0afdd21b5ade8559ada4e1a7ba198c29905bbd1d19f2a2552c4ec86175daa3d7b737dc3958f7b1421f9327563be1f1c5b1f986021c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0fd3D50ECF56d752B65aa45fA636dF91fc90B419": {
        "max": 1,
        "address": "0x0fd3D50ECF56d752B65aa45fA636dF91fc90B419",
        "signature": "0xa6f826470d38d657bb007589f611db1c8ff36b4062417c1e4daa4567405923b71a87de00ca365c126f1eb4901c271e947e3fda15725927a1e8c38eb64c6d510e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7C3142827f6D47A6a5dc009Ba4A25371eD4cbAaf": {
        "max": 1,
        "address": "0x7C3142827f6D47A6a5dc009Ba4A25371eD4cbAaf",
        "signature": "0x10b3de8bdae1a0916716cd57fb058f68e228c810aa66943934b84fd96f5edd5f2838205d4ecbb2873d714ed02e41c5e29b041d4506b444397274b8bcb643764d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcB6a8d5A715eE449eD704Ec87F3d79b31834adD8": {
        "max": 1,
        "address": "0xcB6a8d5A715eE449eD704Ec87F3d79b31834adD8",
        "signature": "0xbfa3e6618664c0b78cd97b2d7ee8787f38bcfd04bd7a1584612a9c0e51f1a74d4cfa4dafb7bed3bf925748896c966acf281ca33cd307434d9daf41f521eeedcb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDad2ef48a939691D932fce851609eC2e3f5C35Df": {
        "max": 1,
        "address": "0xDad2ef48a939691D932fce851609eC2e3f5C35Df",
        "signature": "0xa173001c19f844a7ab78b8f40559ae1d4e12d626a5ba942071d05223803b5c6714a2d74f69f30c636622a2faaa51333890681786090d6a49a2533ce2e855304e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd11071FD3dfd4FbDF3EE8Fbe88D97894cf777375": {
        "max": 1,
        "address": "0xd11071FD3dfd4FbDF3EE8Fbe88D97894cf777375",
        "signature": "0x00f595cf4b45e0614e56fc026240017f3b7b3bf7e1306a31a4543a4ecf05b4315d658c7283994f61c451ae17ca7891ad6943d03e715b9c166f923e4c5c6505621b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8ff19817bB771D6EC28376fC5D872FC8727d7Ff1": {
        "max": 3,
        "address": "0x8ff19817bB771D6EC28376fC5D872FC8727d7Ff1",
        "signature": "0x98dd19bede1bcd80024cfbf0724f5e81d75e37605ec06f6d66935927236fe78b2a30a102b0489b5aafe46d0b522df66cb3ca47bf0b1c0516c6e8f02bfc6719c31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe4a8840ba281865797cfaf7Bcfc0876771285643": {
        "max": 1,
        "address": "0xe4a8840ba281865797cfaf7Bcfc0876771285643",
        "signature": "0xa7a39cba7d32c83577740447bd09181facd70180d4daf6efb85debf327ae67c0462cd49c01564e0c2c5858c232e2fe257248824b703252e197b2fc6633e569b91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x07F4b7f3c85Aa8a3fceb113b7A424aB822ce77a9": {
        "max": 2,
        "address": "0x07F4b7f3c85Aa8a3fceb113b7A424aB822ce77a9",
        "signature": "0xa641b29823829648edc8dcd74f88a91abaae88a9b5eb1e4513fda951fc359a566f881b24cf2f16bb09ecef289ff7f37d687c20fbd5482eb80da4d80ff54d0ef61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe871D2F7b77229f87F58aFaEf47bA9FFFc9A0d0C": {
        "max": 2,
        "address": "0xe871D2F7b77229f87F58aFaEf47bA9FFFc9A0d0C",
        "signature": "0xd2ffdfb36d4041720d22c66612f6ceecdf275613a697d75bb74d3febdf997dee14c45265b9e669769ab023af89374b280cdd4671efcb166bcd24afd22f9f61cf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd7bA705F230dCA4b970c438C99Afe55A37b1b433": {
        "max": 2,
        "address": "0xd7bA705F230dCA4b970c438C99Afe55A37b1b433",
        "signature": "0xeab13133fbe5e0b6e3901834d06106a47e7037bce2b90fc9c80b02407f30968f4e613af69dc8be5030e46ef42d6247455c14bb6836c3017bf9b44ba2fffcaa5d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5EDD48C6CEC28bDA216863eF113aD52Ef7cF65c0": {
        "max": 3,
        "address": "0x5EDD48C6CEC28bDA216863eF113aD52Ef7cF65c0",
        "signature": "0x38375356439a703fe247afc2ea26aa56f5273df1d1dd489b24e679af2c60fe354c8c06d2905b78d6097fd3e71eab8454efa3f22a98dff333bf8896906e7866d71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3a319d0F3B60a3fa2A4a4daa36e41B9521C6C229": {
        "max": 2,
        "address": "0x3a319d0F3B60a3fa2A4a4daa36e41B9521C6C229",
        "signature": "0x0569cfc2112c0d51069f43d7402523cd081e9b5bca19320fa6edd9d28a1bab9d372348e9a6d38930190bd4e9a60443a47971c2ba1bcacd69761b8a3db1e4a91d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0cB86744e661C6ebf964995050c5d06E24215C8C": {
        "max": 1,
        "address": "0x0cB86744e661C6ebf964995050c5d06E24215C8C",
        "signature": "0xb9102d16ca91c37abc7ed9c117419d46df5cdbdee10fd29d2f82fc62291190371f37e9e9d53eb4c8b7b81d7a88c8fe9ccd7c486b6aa3a079a51b6b4d3e4704711c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeCCCF816D1A509EA69A5165F4eFd9538d990DDfB": {
        "max": 1,
        "address": "0xeCCCF816D1A509EA69A5165F4eFd9538d990DDfB",
        "signature": "0x754a4770cf9177b1c59e6597f69d0da6f1fd50ecd08ff3a554e0201814ccd71b5c8aac4ed18fc2a65a187cdf4f995a84bcad310b0d1bd7ea9ac4b64762c62b701b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x236ECb3Ebc99485330d682C73Efc89508F3B7CB2": {
        "max": 1,
        "address": "0x236ECb3Ebc99485330d682C73Efc89508F3B7CB2",
        "signature": "0xa2dfd3281b0595ef5a3c0eea1b3bc58395a56b331687bf54cfd47a22a20f26b6629da1fbc4fd5432e478c1a1a944dc33340887bbeb36343319a566e84ff0aaa91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x75046e101FeAFE3f913fB4e8d7816dc8B8B78E32": {
        "max": 1,
        "address": "0x75046e101FeAFE3f913fB4e8d7816dc8B8B78E32",
        "signature": "0xcae6193893c4b7b6e4a8fccfed8109d6021bcb31001d91377f0bad79c1172da24fe78ea0e91f3c94444bc6a742ea0cc8a99e1a41274c5d3c0af97c68bd615c231b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4dbFfb6cCE2876A67A3ea179C6B5C97Aa0aa7cfa": {
        "max": 1,
        "address": "0x4dbFfb6cCE2876A67A3ea179C6B5C97Aa0aa7cfa",
        "signature": "0xdbbfbbc18c34855d92fd8e3830da04dac403b88a0375b44b6d2af2554adbda2130cc5c5da6744206ea7facb933bbf385c6ee0f2d9bb33d1f0554eeccdab4ffaf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1e8f83B55e1fA534a885a270ef6ed48F26778278": {
        "max": 3,
        "address": "0x1e8f83B55e1fA534a885a270ef6ed48F26778278",
        "signature": "0xc4255ace070cc23ffa96c5cd0d44155cd16cd2534c45cda986304df5e8dee40d1df4fdc21522513ba9f1af623d8890d2a988c990a238e8ec81e1b89f291425fc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x51a17De80EA4312e19717945E53051E3C399C26A": {
        "max": 1,
        "address": "0x51a17De80EA4312e19717945E53051E3C399C26A",
        "signature": "0x4bd5b0f5e45742b45b625adc76b85faa1270cde45845f83fc63ed86f3b5f8ae77b2f7233b1421f8ca757707f7726103b741fd8d96fd8b70dc84cb1b2fcad8e351b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCabC4F2867F4cc5d9B4F38D313B60c24c0920933": {
        "max": 2,
        "address": "0xCabC4F2867F4cc5d9B4F38D313B60c24c0920933",
        "signature": "0x62d673aad44bd50b2a798ea0483bf8065905a724ef6ba3eb300ba7acd1b8c86b3b2e53807025320b96c46aedad44a05c6f8d7c3cb8f06a6be9d03ae02813a4641c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x95657cEDf4689d953d6dbDdc57d7B97f46329320": {
        "max": 3,
        "address": "0x95657cEDf4689d953d6dbDdc57d7B97f46329320",
        "signature": "0x57463b62c0f1e9f68c0489df59ca997a5e861771ff7e6079f2e3726eef4c5a8658131b5c45674af37722c04ca954468a3c7e4a5c3e28546778806dba4272b3c71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbfA22b6CB246c657a4C5e5C5873ceC672BfC4506": {
        "max": 2,
        "address": "0xbfA22b6CB246c657a4C5e5C5873ceC672BfC4506",
        "signature": "0xc5761ff29642e852d141c75678c77a0b960eff2212b37ab656528efd71adc17e42e35094bf5ee5038dd8f13fa910c9893b4887032c426a1b7228db186614b26f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2958EF6f3083994fb84EE502315D8c37D2eB56A9": {
        "max": 3,
        "address": "0x2958EF6f3083994fb84EE502315D8c37D2eB56A9",
        "signature": "0x02a1e2cfed84e4431a025883da97da89f3ce9382eee165cef5ccde0545867856103bf145440a394afaa47989aec4efa9c990f9c80ed282f4845a5b09bd6de82b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x96Cb84ac416602cec04B6778fa3F8e588e84cc95": {
        "max": 1,
        "address": "0x96Cb84ac416602cec04B6778fa3F8e588e84cc95",
        "signature": "0xd547659c97e44dac3e77449cb4b9a61c856ab2242951cdc21363ee27e3f56da24a025acd95e023d1eb7efd6d8299d625d86402774be799178afe2fbb4fa076541b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2787f2FFb984D684Ed979a4A725308266F52b06e": {
        "max": 1,
        "address": "0x2787f2FFb984D684Ed979a4A725308266F52b06e",
        "signature": "0x3e6e2abae94e4c833b7e2d093c6777242ec2734c135560f7e0862f1e078573b565eac9d421009651dfa1871df23d5efbf2f61b2443c71bf3112253e0791cd1d01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe7A8fe7cB66a9A089dC92165eE71120B2aAa0694": {
        "max": 1,
        "address": "0xe7A8fe7cB66a9A089dC92165eE71120B2aAa0694",
        "signature": "0xc398dd59d7d82eb573a8a96a891c2f8ecefd10f1c47a2c34b61f6a8e4159d9630543aec820ecedba7a9bfdd04d1819153672deaa3518f4aa2555304e23ecd33b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaE0Ab89006ceE7c69c68239c0eBdBF84b5025c2e": {
        "max": 2,
        "address": "0xaE0Ab89006ceE7c69c68239c0eBdBF84b5025c2e",
        "signature": "0xad23ecd84f57f7b0479e400b2422204ae550399975725a6861d8bde12c8f257f6b51992dc220e62dc963e9f5d215ac24c3a307b89ed2eb0df985925741bf80441b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x854Eb34197Dc141dDE46708a80286457b9aF32C2": {
        "max": 1,
        "address": "0x854Eb34197Dc141dDE46708a80286457b9aF32C2",
        "signature": "0xfb49707d2cd4c51fef637d2241009c8cda269ce2de7565346d23d66a6aa83700535cfdd5b33fcc63ce324022d3134378a0be18fc9fbb1d9d4d35336f18de55b21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2E196Fbc672d16fd489d8A3af37fe145d25E8b38": {
        "max": 1,
        "address": "0x2E196Fbc672d16fd489d8A3af37fe145d25E8b38",
        "signature": "0x20d1cd4eb96bd79e4b992159ec9a8d2ab565934445c3c4eca370da401927e81c7ac19bd975f6db89d122bbb4c0dfaf55760afd3654ca052519e706f5065e8a5b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0F34E9EC6971eBb89EbAB75e9fd194ac6295a194": {
        "max": 1,
        "address": "0x0F34E9EC6971eBb89EbAB75e9fd194ac6295a194",
        "signature": "0xc7c05f79877f43168c5d98f78b2a41dea7bf86d8203d0a228b9fe74a8c3ad5a3602e07db7c30265650e2ec2571a14f6e7906a6726b5920e74628cc5d051e4c051c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1D794083e7a10c46f6cF8F413e7c3bD36b68fBf6": {
        "max": 1,
        "address": "0x1D794083e7a10c46f6cF8F413e7c3bD36b68fBf6",
        "signature": "0x4cb45e6c9d5bfc72e2f1b5f142c75738882ce648be0f072a2e7cbb1b9a1bf8fc636b8a53772fed910f4e5b1de6bee5fefb46446cd5a2aa7bb922380b492899351c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeBDc4Dfdd2f81c5552774076e19679B3FC34C6d2": {
        "max": 2,
        "address": "0xeBDc4Dfdd2f81c5552774076e19679B3FC34C6d2",
        "signature": "0x14a344fab0874394a57a2756b27fc86281ac7b7694a1b90b9d88a56a07f8f4454c16dd6a6f8c96c05ee5f9c74730fd5f1007ec03568e7da5d912ee784f354dec1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd64763b46655c14c68f8B40D398965ece98fecDA": {
        "max": 1,
        "address": "0xd64763b46655c14c68f8B40D398965ece98fecDA",
        "signature": "0x20676aae9c3c9f2687e54f4d338b4fb79116bf4c4eb1567b6bbb7d8e4e4db94c333b504de2af04070e6a7bf45a6566ee5070b5ad86c7c5e394b9ddd3b5d6a1fb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd82C5b80FD9c42067dbC6a19E3124b5c5a7d008F": {
        "max": 1,
        "address": "0xd82C5b80FD9c42067dbC6a19E3124b5c5a7d008F",
        "signature": "0x80cb2c1999333891b730bded50a2229a74ab4d66dd01fc93cb2fbe06ba5b68710b0bee2c5073eb2afff22b62131ca22a9063565142261c5aad88be2f6a795ee91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4Bf891E8fddd955AC90c437e8F0c6Fa814067566": {
        "max": 1,
        "address": "0x4Bf891E8fddd955AC90c437e8F0c6Fa814067566",
        "signature": "0xc1349cc1f4e9f607929fbce00da67f0c293d4d69659f6aa78cc88dbdf32c5362685ff94322c44a266f9c59d18deafad5ef1d6c69f0cd811f18cffcbcbd65bfb01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd69a4AC19349cf007DD598d7f2Ee4f7f37d0542A": {
        "max": 2,
        "address": "0xd69a4AC19349cf007DD598d7f2Ee4f7f37d0542A",
        "signature": "0x65df8d8a83032978db11741617db2da24a6bb57ec7db053f8d1d08c15963696b69f9f938b544080cca3ebcaea88e3ab23d4f22544c960276f931bd8e712187b71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC9029A9446670121D57196a890fba16d0e4457f3": {
        "max": 2,
        "address": "0xC9029A9446670121D57196a890fba16d0e4457f3",
        "signature": "0x1cbfd6fccb2b85ac82610199828bbc023005c20ec53c21f33c1d1839f33bb05601d5466573e8ecca91de7e56215d8713771648547e94a84189bd21806a53088a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03ca9D1cae97D0f5E55E2184A096bF6236A02D5c": {
        "max": 1,
        "address": "0x03ca9D1cae97D0f5E55E2184A096bF6236A02D5c",
        "signature": "0xec481056f9ef160be7da50973472571920aef92795056c297ecc7a0e6d290e04632348191b1a6edbcf73f59d7b448ea4fda666b7eac7db1856a15c55cb844be51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe1B7D56ef54170EEAAb7DAFE2482265751E6A75b": {
        "max": 1,
        "address": "0xe1B7D56ef54170EEAAb7DAFE2482265751E6A75b",
        "signature": "0xee78b6ed14c65d92dddb24346d0b2701c964ea2d558ddc8fb5ac3ca3917b03027e7cc96df8ca6becf665b16e28d8a553d1369dd3d14859f0d945065634ebc32a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA3A56DD2Be92D2251F313a4387D111317a564080": {
        "max": 1,
        "address": "0xA3A56DD2Be92D2251F313a4387D111317a564080",
        "signature": "0xe663323305d2551b9f93e1c3097b70df0b3eadc94d500f6db67c91e6edaca8492a22a2b7efcb6865171e53b68c2c6f5e9501c6d34ceb9abf62a487eee47260971c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa2B985E800c465a724654bdb6368eDD9017de2F7": {
        "max": 2,
        "address": "0xa2B985E800c465a724654bdb6368eDD9017de2F7",
        "signature": "0x1fc2234edac7bc0ae30ab82e462b7275fe4be69b15ad9deb21a6e5b3add2bd4037fefffd5a0f134267b0fac4ab3f6caf5cfdcac621d6105a70b574264c9df43f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb492c35F454B83B2c19eCa5CBEC8e1792C71dfaC": {
        "max": 1,
        "address": "0xb492c35F454B83B2c19eCa5CBEC8e1792C71dfaC",
        "signature": "0xbf951c2debcc1617cf16a8cc17803243032d3c8fc4cb094de76cc627f44d62aa2c5b742a1c8e575a208144ad41f110b76cb987b2529ba5e4a7c01d8552d301d21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAD468E8336182e2CEc7022F3434F91227c33a723": {
        "max": 1,
        "address": "0xAD468E8336182e2CEc7022F3434F91227c33a723",
        "signature": "0x49a6e16b9655eafc1aa1efc7c11a1805d6d39bef63a0eaa827d92583583a42a803cee8f434e3dd1c12656d00fb77aa411ed7b00304af0b2bac8dc191b13dd5441b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7456d0080eD35214b26A5e7102b292f3Cf0167BC": {
        "max": 1,
        "address": "0x7456d0080eD35214b26A5e7102b292f3Cf0167BC",
        "signature": "0xf053d93a8cd1358fcd8dbd3c332584ea2dc7d739195051e8f13138cca832f32e7d154851b581ff17264ef493f418f38d5a7f3fae8b82c42aa0543f1ced4e791d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1CaC4cAB427B905FAE784C59a54e96f81e709Ce6": {
        "max": 2,
        "address": "0x1CaC4cAB427B905FAE784C59a54e96f81e709Ce6",
        "signature": "0x566bd115c4d8d83b9a57a18ed2871671b318c4903deee92e7ecb90cf70ebd8461ebe6172c506650eb1628bdee710c7e5c20ee305c9a31bbea7ba0d7d79152edd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbBCC626377e08F04349859CBB33B07795dc9eE96": {
        "max": 1,
        "address": "0xbBCC626377e08F04349859CBB33B07795dc9eE96",
        "signature": "0xbd428e2bd140a3615ea91182272e54842a4db9cdaf7716fc3db036b739270c7277ece9113e12299f4b5287270d2ca2399df37b850ff4a958a05ec85a0db086201c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x98DEf6C15EA07B5b622833cd892eA899B2CFFbAC": {
        "max": 2,
        "address": "0x98DEf6C15EA07B5b622833cd892eA899B2CFFbAC",
        "signature": "0xc5a40c5eefef46402428ea7492f02bc1ab5a60be899ab183e44086b0c0c1a2155e66f1db7d5e9a12c9a11ece7be37a90f430437c1b55548874a7a6e54b5f09481b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4759D631022A55bd4A6b6bAcdC30AaE08EEe629D": {
        "max": 1,
        "address": "0x4759D631022A55bd4A6b6bAcdC30AaE08EEe629D",
        "signature": "0xd681686042e660cc8df13f36ddc13251b2ee13374e547980960e9b65e9c3ecee61f55c63894e99535082d76e5a072530657658c4517831d36b22c21f9c4095a81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0c1aDe8f637485b9599D0f6FF8885b45dBAF11e2": {
        "max": 1,
        "address": "0x0c1aDe8f637485b9599D0f6FF8885b45dBAF11e2",
        "signature": "0xc4f67434834b3acf75cdfd48e2229a2db96d29644b8444729d16e0d1f7983d8927304a92a704d8ff8331bc16c4abf3ed018b67cd1e970072d15d5c75231b1c7d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf464B2BF85E202e9FADBaC4362D737b9BF88f5c6": {
        "max": 1,
        "address": "0xf464B2BF85E202e9FADBaC4362D737b9BF88f5c6",
        "signature": "0x2d33dae2e9df023b1d323feac4823314d856fda35cc4e403d64e8dbca3f8705a353a6efa82b1b204b9e01206852a054de8c0e11ec3df88b2f2fbe2e71e21aaa51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5Dc461DaE0eB76d8fD3E29dC75501839db7e96CD": {
        "max": 1,
        "address": "0x5Dc461DaE0eB76d8fD3E29dC75501839db7e96CD",
        "signature": "0xe7b6efe079ae6628f7490eaceed55c141544da36966cf187935f86d14d3c2d595144627547fca129821e908bcbfd73b66aba05544eba31d5f3bc6bacceb818dc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x798b796985d35D94A3bef2C10996EafD20CBB2f5": {
        "max": 1,
        "address": "0x798b796985d35D94A3bef2C10996EafD20CBB2f5",
        "signature": "0x7eeb4776345b47af2d08a77cf9aa07d862fe33cc48deca7b0c13fad547e9a8433bba5164a4f6bbbf1a7518e44346dfab286c3ff645d5a77b15e7c59547d615a31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5D3E18d69c7b3A1276562f75E3da3671472164D6": {
        "max": 1,
        "address": "0x5D3E18d69c7b3A1276562f75E3da3671472164D6",
        "signature": "0xa37d2ae99ecc3edf90dfc16116e2806c96efa82997685591a8a6b29f79af3e1b29329634c146e7f0a6a142bac66008d216a051bc3dad0feaa25a54c9e42f57201c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1F613fAeFa1825F5b2b6c08aa515a145c7e6b687": {
        "max": 2,
        "address": "0x1F613fAeFa1825F5b2b6c08aa515a145c7e6b687",
        "signature": "0xdc0f3135de993d38ec492e64b581a5aa9f8f8a2b2e66356483ab1cbacb0c2c50127e330f9c08b2604418e1e27be2a06b194e69a3eb20b67c1916bc8f5f71741f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x86526288B1b69172Ed2C64A96bfB9877684e828E": {
        "max": 2,
        "address": "0x86526288B1b69172Ed2C64A96bfB9877684e828E",
        "signature": "0x8d26b8d366ca00d5b164168fda4b567b3381a9ebce9fe42259ebdbb2a1966cb13b2d45d4e53757387094b11f7e9f2c93ce8216f0107c4b1b387044e981ded15b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3d91c40508C855d0B7657D71A908382F9478E3c9": {
        "max": 1,
        "address": "0x3d91c40508C855d0B7657D71A908382F9478E3c9",
        "signature": "0x639fd93165a30316a6cd2da45d97b21ec4d3fd8b86db897701cf0c46a154338f01c5257fc626df3326270950c2c9276898374460b35e5eb5288d05b54da0e3cb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x67B15A5858508164CFCe389CAEBEDde14274C787": {
        "max": 2,
        "address": "0x67B15A5858508164CFCe389CAEBEDde14274C787",
        "signature": "0x3b8a8b32f9a5397963af2151f870b91f94081d89238d9d6254d2cd0f33521e7d7f54a186219fb0217d480389fdc5143d41830cf087580267a4b124c1deba28d11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x81192d47F0B15Bff16Ca8F13d718F60f2D83AB9B": {
        "max": 1,
        "address": "0x81192d47F0B15Bff16Ca8F13d718F60f2D83AB9B",
        "signature": "0x64002774414e13043325b836b9b56a335f38814742bb91859cb6f323ec4d68bd50f8910467e733a725fae75db4a61f060b91365a8b7198856dfe73f78d47b01d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAE1FA4f43f3cc34CA15BbE6b385e64CA1D2BBbfD": {
        "max": 1,
        "address": "0xAE1FA4f43f3cc34CA15BbE6b385e64CA1D2BBbfD",
        "signature": "0x11ff8d1e9671e5c69289eeb05d1426ffedb8097123661bcc5076a3e3269c22ab03fbdbb56652a614dafe1cf0847859a2047d8f2351656224900f03d6e7383f971b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x94B6de83a70106740dDADA5b307C4b3C72A77bF1": {
        "max": 1,
        "address": "0x94B6de83a70106740dDADA5b307C4b3C72A77bF1",
        "signature": "0x259fa8775310f471d51bd541d3c84a0b9065bd6c347c7493b25116c254a2a1bd72822ac93612a40aa1979ba4bf8a41166bad4b29bb6e2b5838c34d6b337a53c21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd734840BB5b2977D9D86bbD9d2aCa909593a6De6": {
        "max": 2,
        "address": "0xd734840BB5b2977D9D86bbD9d2aCa909593a6De6",
        "signature": "0x708a6ca16577320560ce43ce8272f08cb3bc2fd476d4187c39b77e889b25528004c0a27979492266f2714b4789b0c3a925880bfed4ec63b8b5a47b984de363df1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4737346078bFa3ED7B01eE4282863Aa1C021345d": {
        "max": 1,
        "address": "0x4737346078bFa3ED7B01eE4282863Aa1C021345d",
        "signature": "0x1968a226b460b4749a74226ea019fdbe1f91c2b1213254f117f7df976b3ffeff28d1259fef68cb352a9234c2de2522c7272f7a683beba144d0ea430c4a9e50101c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x310421C955b9a714Ad7a86C1c57c9698FD962318": {
        "max": 1,
        "address": "0x310421C955b9a714Ad7a86C1c57c9698FD962318",
        "signature": "0x8f8db92f499ba4d186b000e6d78383350e648da99035b4a6994aac579f93e1c00f23bece7d53e1ff6cbcb490446ad42547363303fb1f66fdc84071fa078793681b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbD42D94a24c3123E45dB075A4313AE1a692d5CEF": {
        "max": 2,
        "address": "0xbD42D94a24c3123E45dB075A4313AE1a692d5CEF",
        "signature": "0x52aa15e17fb1b8537776cd376c1f22dbc55c888cb5898511750714ba2f2cd3425a037412d4e7513be0a4b860c4d1742a641c4cf2deb45186e6ad5dddcb9e200f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc636Edb44a2C75A6B6256CDa2F849F9cf4b0A8D3": {
        "max": 3,
        "address": "0xc636Edb44a2C75A6B6256CDa2F849F9cf4b0A8D3",
        "signature": "0xfe5a619238e74ec2c41541da42d2a0b599efe50eb07ce3227776d92210507c2b1b956a7717e39859e08a3414b0c4f59a5f9e36f90fe8b8f07c7b3986f0acd6121b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1EBeA9Ec649F0Cb505F59b18798b10B61CFA6508": {
        "max": 1,
        "address": "0x1EBeA9Ec649F0Cb505F59b18798b10B61CFA6508",
        "signature": "0x62bdf284f1ae48f13ba7dd644a89ed6f0416a293f9a98dad0bc56cfb554fa14d6e5e3d0b3d08da0a3edd26b02eb29768a73a5778d09d9de940eb4e4c62bb85961b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x75Cb75765578FefD6E249aF2Fd868C6e96B20BBc": {
        "max": 3,
        "address": "0x75Cb75765578FefD6E249aF2Fd868C6e96B20BBc",
        "signature": "0xdb14344de239217bbd8753ec1ecaff7c2e00cbbd17229a23b4b218323352818e5e81f05b9caa8eb28092d5c6e99119f20a3f9f83a488a283aee7cb5c91c435961c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7bd799fD4d0A67e8D8Df16aE471302229Af6B529": {
        "max": 1,
        "address": "0x7bd799fD4d0A67e8D8Df16aE471302229Af6B529",
        "signature": "0x1c8628785be667fd642e2d597465686a3b52807b4e2c9185bdd953a15a69f7fb292964162b1b1a263462a071af3b474a0e26f95a9176ed903ba50bc164bab7bd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5fc28E8003584E20155a30EDcA905fb43d9447ca": {
        "max": 1,
        "address": "0x5fc28E8003584E20155a30EDcA905fb43d9447ca",
        "signature": "0xbab58d32d57851a739b9fddd92baf216ecacc06f2a9ca9bfe45bfbc298e48a4153bc23863c634f99ae3906dd990c49289225f7f67773775e5005afe453f20f691b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb0C0D08cAF1fD490966391907b59652a2790583D": {
        "max": 2,
        "address": "0xb0C0D08cAF1fD490966391907b59652a2790583D",
        "signature": "0x880219015cf3fed090bc7e5837da355bd5c3d94f32888b1abe8020251cc593e224edae52ab5f6de102d593551f859102bdf9349d7a24fb02fe1b2b2dbf8e4e661b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0C6e5fc8B6a0C17600ACC537c1055320DF4fcF60": {
        "max": 1,
        "address": "0x0C6e5fc8B6a0C17600ACC537c1055320DF4fcF60",
        "signature": "0x3d596bafb36d751809269d32a2eb4ad4f426d1e5e756ef679164fa59dcd1eca647d57e3d974c8aa0b87559999d6f2e9fa815405915500a98361716c04d1bc4bc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x29Ce17DC989D316C183EADe3932DcC2f25af273A": {
        "max": 1,
        "address": "0x29Ce17DC989D316C183EADe3932DcC2f25af273A",
        "signature": "0x49067bdbb07ad057c7a37af1330d305d0f7240b66b6f02bc45238ae4e8177ffd76602da2320b0117c85b9b15d4f360154af66bdb7329ea5b6d35a94dd90870aa1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe7C2152a126d7b09CB2BF463Ba3A539F472Ac7c4": {
        "max": 3,
        "address": "0xe7C2152a126d7b09CB2BF463Ba3A539F472Ac7c4",
        "signature": "0x1c5bb778fa699de7c88b3af7e3b0244501a70431cee3f4d7c12a35afc9fb0eea6a648f17070f480d6388ca30638f6a74048dfe93634199b5d2b3df357d5bb4751b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x71cEd9441ff6C792B77E2F0394ea943176c08B24": {
        "max": 1,
        "address": "0x71cEd9441ff6C792B77E2F0394ea943176c08B24",
        "signature": "0x09080fddf14d73bc35da9b42c88e31cffb51b45dfed57ecd98907891919672984b170caff3ee805c9e1ffd547a093411b4de350855ea06b90c3e0f850023631a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF4E5204657B7c0402cA8A44f9De7f6E2e2cfF3AD": {
        "max": 1,
        "address": "0xF4E5204657B7c0402cA8A44f9De7f6E2e2cfF3AD",
        "signature": "0x9c99616da85b54392340d19f57e7df86ea816e76eec3d2ae5ff29d7cb63badf32c760fcd4ad7bbedd4d903e90063295c8313a14e1324f28a478715de0bd10e631b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6Db49d9593c3902Cf4050680D206456D82ef4481": {
        "max": 1,
        "address": "0x6Db49d9593c3902Cf4050680D206456D82ef4481",
        "signature": "0xf296bd5e2639993e601183d005228915383c220ca23096d057687e1a5a25e93a30e491fc2380170b898d1239b68016ea94141b7fe614d27984407932c77fe10c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2896Ca90d02833CEc3838699054C9B209EEe6803": {
        "max": 2,
        "address": "0x2896Ca90d02833CEc3838699054C9B209EEe6803",
        "signature": "0x5f5cb3ad95c65c087f74a727d4b1e4d2afe38578fcc654ee018ae4e942af038069f5e4542f43c67bdbba0d8b727837b31bbbae38091565fa6990cd29ddf10b1e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5bD0920aF6DCcAe3d4d90C51D6fc7e34583F2314": {
        "max": 1,
        "address": "0x5bD0920aF6DCcAe3d4d90C51D6fc7e34583F2314",
        "signature": "0xa4437c7052a87681ba78f2a19c272b48111e70ad9dee8416323ad8af9be863767db63393cd9498aff144948c7fa049a00dac2633b9141eafb50785362668259c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7b578cBB2B51AB82c4D2609BB3fd2F752F14ae6c": {
        "max": 1,
        "address": "0x7b578cBB2B51AB82c4D2609BB3fd2F752F14ae6c",
        "signature": "0x9108b8d60efea0fd199dcf76af233aea5922498c61f25f35964a0810a21c04521b0954e23536117bc4479a7d8f6a66dd2abda20145501849bb48dbe0d78ae3931b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x55786C2009E833c198F43F7793aE6d9C7D14D31C": {
        "max": 2,
        "address": "0x55786C2009E833c198F43F7793aE6d9C7D14D31C",
        "signature": "0x576fb784378317b4c75101f1608f269a481eedd87e46c622d0860b3f2889d9d32ec12059bb0cac3184cd34e1dcf948569a513c234ac8d65dadedbe34992c33d81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x533dE2EfaE60B0F036646Ab3F48a9A3e60E52C6e": {
        "max": 1,
        "address": "0x533dE2EfaE60B0F036646Ab3F48a9A3e60E52C6e",
        "signature": "0x2fd6719df1895a0e56e88f8f76f9cc2209baf9ab82a60221a01a72220687710276763e545de4a1961e3d331ef2a6782ccbb80baf5f4d8235f73d89fd052c68c11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x267b1109E514A38a66F24BaCAD55e36Edd738a58": {
        "max": 3,
        "address": "0x267b1109E514A38a66F24BaCAD55e36Edd738a58",
        "signature": "0x96eb1214aa7da000731e5ff5d3231bfa0f62f6e2457904b58d19eaa0ef0bbba1695cfe2b3285f458d18996af67b9a5c7e32471f142ca2cfc72b95bc2a7f8680a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x22F196300411D44BDa6273C8a9c9EaCb0917aceb": {
        "max": 2,
        "address": "0x22F196300411D44BDa6273C8a9c9EaCb0917aceb",
        "signature": "0x9b965fefd7e40e14dea9e9ae50e5effcec3d16b91ba058e9b4ab57e5efe558644a39b277d5ad078729af9c0db2c9cb37d4271ecb88046398415374700f07577c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3E3fD41aDD7dE67a12CBF9575d442826B067C71a": {
        "max": 1,
        "address": "0x3E3fD41aDD7dE67a12CBF9575d442826B067C71a",
        "signature": "0x45f814d6c98f607978e1bf5307ef0b86ca6e9be3688c594b2cb9cd84998de4f93a2321d120cd98821cc6f981fe7022c3d8de9f753df5d11d71b8cccad95bc7771b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD9Be0D484341598ad43AD84EbBdB311a94548aB1": {
        "max": 1,
        "address": "0xD9Be0D484341598ad43AD84EbBdB311a94548aB1",
        "signature": "0x4304064c2354ee47b2f57936586a0924e85e9edaa1588b4bdcd3cff39047cc7325730096bada0fe27cf922883f5b13e697b96db8d99cda302a880d51ad491f281c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x576d67f28E41E071F523Ad26c6307c1D81640471": {
        "max": 1,
        "address": "0x576d67f28E41E071F523Ad26c6307c1D81640471",
        "signature": "0x033aae392ddaf6fc4ef5ac930d2cd4182b08adbf58621b580e790c4cef0301b43a75a870eecfc74e009d3a57a347c9dfa2ea9e77ca9c7491e3f1c7b0ae49ccfd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7C4290d663c58658256Dba88be9425C9C9caB77D": {
        "max": 3,
        "address": "0x7C4290d663c58658256Dba88be9425C9C9caB77D",
        "signature": "0x2cfa36dc286eab5c14adf8cfd0dbf63ca6578582063f22c4d40f83fe294b4d4107f257dc88cd5a42f7f9dce61828ca76a756694c38af53f71ec365018e4c27f31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x81DBD5981eF86eD6E885A3c3063895d1F9D0D0C5": {
        "max": 3,
        "address": "0x81DBD5981eF86eD6E885A3c3063895d1F9D0D0C5",
        "signature": "0xb2f64d223e9e16563d63589ec0b85de520c49907e4221e3ba11fdb0e3a55f6dd79a3ed5c4438e9fe8ba5be5e07ed3aec6328efd7bb1fa9a6bfd1308fa34da40c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xafFaacCCaacc03906346E85423A50BBf2D5ca7f6": {
        "max": 1,
        "address": "0xafFaacCCaacc03906346E85423A50BBf2D5ca7f6",
        "signature": "0x8ec80881425cd9f78806acc6916ac6e3ade5b1bfef5d95a43c8a2986f23e133c5df4fe8740187897522aa17d6bf4861711d1c42a48b21f597a02b63e2befff1a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x47a2576EbAbB87D439241f71ee400FBE018D42bB": {
        "max": 2,
        "address": "0x47a2576EbAbB87D439241f71ee400FBE018D42bB",
        "signature": "0xad54b12da89d35d69df1ad0442a10dac58df12c62ceeec083668e6833ae53637580a9434b0d3e96c9f91afbbb2a63f5d4660201dd992ccad34d9cfc497d176b61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7472e9CF11c361Cf8BE4DeF6ade87DBB2681bd1b": {
        "max": 1,
        "address": "0x7472e9CF11c361Cf8BE4DeF6ade87DBB2681bd1b",
        "signature": "0x42c3ada49ec91456c34d33d4c1da5fc273b71341aaf2bd5159e0c334a578bcd77eac7bc02aac64e9954fcdbc60663f44c111583ff67c34dc045c67ee639906e21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x89043Af982b8Fb395Ade5D410afA4392458De485": {
        "max": 2,
        "address": "0x89043Af982b8Fb395Ade5D410afA4392458De485",
        "signature": "0x9645ebab9ece1eee1ca27e1d31812ae954196b1ac39429aa1c324358fbcfc8145cc01d38ee42a8183c8fc0e3a30fb44591944aafc5fbf19f9ac0db99f047a80e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1d9D98B9348417C9C967adD43C18da7CeA6884ef": {
        "max": 2,
        "address": "0x1d9D98B9348417C9C967adD43C18da7CeA6884ef",
        "signature": "0x5231a310859f54c875c17215cb15b45a9c3227fe089fbd56b188c1061a96d7d829764f170fdf409c5a853982da02e0ac1d95d985c6817b42ca621b35a34fd9581c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x407b1D33BC3ed82D232017B401b45c2f482f8e55": {
        "max": 1,
        "address": "0x407b1D33BC3ed82D232017B401b45c2f482f8e55",
        "signature": "0xf2d515f2d7dc63f7c6c1e03a9060b88a6909ce3fd3c60f0a199d858b20d5aa744911b683591bb9e9fc069dfd51e66ff7c5dfd8acf2db94541fcfc09ed2e89b2a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8607185c992f1707D142395D0319b4332540eeA9": {
        "max": 1,
        "address": "0x8607185c992f1707D142395D0319b4332540eeA9",
        "signature": "0x56899f9d79f0b61e8f5c4b9682af3db73ec6fa8507bf4af5b4065db9c5273b741bb3d443ec84965645aa0374838d868efb6900eadfc86a62c4094d51fa211ac91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x953568CF42f8acfC95162e21358eF29c899424a4": {
        "max": 1,
        "address": "0x953568CF42f8acfC95162e21358eF29c899424a4",
        "signature": "0x3ab8e4f8aef884e687ee49a91d635174bd72f4b10ce5d18b1434b1fc44f896992cebb6e318808712103f1ec75ef0793ad174d29ef52423a004607ff4291bae0f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x842dE110205625172Bf0dD199F67A8529612A988": {
        "max": 1,
        "address": "0x842dE110205625172Bf0dD199F67A8529612A988",
        "signature": "0x102008d7fc64eb6df9503053b3e337fa09dd86ebc6a101d9c21e2430875942ba2217641e4c88c4079a669d7fa3a9c41018140c85a0c9236713b5f74bd58647c91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0fAb8F2546555CA0B95874626F7FceFb80255844": {
        "max": 1,
        "address": "0x0fAb8F2546555CA0B95874626F7FceFb80255844",
        "signature": "0x0af3dc4771cf68bcc389ad71d6556127de51b5dcbbaf789dc387996baaaffb2041c261d5c3d4bc8edb90a3b0da9f77ba72eb82c277d5d49729a9d5c6c66932b31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe2a7c706e682405918EE1E3F5462eE6c6FCC933B": {
        "max": 1,
        "address": "0xe2a7c706e682405918EE1E3F5462eE6c6FCC933B",
        "signature": "0x650e1b989611802096f2652534d7f696af7268dd12114524cdd185599b7a471e637560a32545f509c30f3af6e3b52ace9d2084aca8599c8f1a8306e38b7a22d21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0743FABE7d0108bfD62183729490072388BCb9Db": {
        "max": 2,
        "address": "0x0743FABE7d0108bfD62183729490072388BCb9Db",
        "signature": "0xdbfee557cc284adc08a9e297145f88652fc97ddffa6ef8ff1784738cfc670e727243e562b01f5c51afe70831d0bb73001d2cbffad36a3e858c37618e8ad8183d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9C023ec9779B8A37301AeD493C76e93e1766F1D2": {
        "max": 1,
        "address": "0x9C023ec9779B8A37301AeD493C76e93e1766F1D2",
        "signature": "0xd056acf23c74a896c377f4bd98622f0714ef149ce3f5521d6ef676ac46d2bbfe012eb1a8fa778a26dc07544a017465725a6f7a7674caedc314f34d719846bed81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2e1D09928e855ea76DD1cBF129738d530F95CcfA": {
        "max": 2,
        "address": "0x2e1D09928e855ea76DD1cBF129738d530F95CcfA",
        "signature": "0x90e8d75beef534146d488cdad1216441a10b280a7db62b06f0a37b736798c7f5595b24187927d2d9586bf3a1925727464373af12934b4c590419bb96b99c85561c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0E74FA977a49B91Af6aF2338B70073bA1250ae04": {
        "max": 1,
        "address": "0x0E74FA977a49B91Af6aF2338B70073bA1250ae04",
        "signature": "0x7790a48755c008e3d39fc2320c576543a325bc96490dfed26ba9937f2977bdf26bd74be1babc6574f3f1af5833f8d75766541342964cd8768d49f09cd45f10271b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaE3754df0459fA3c5A65A90155d71E09c9a604Fd": {
        "max": 2,
        "address": "0xaE3754df0459fA3c5A65A90155d71E09c9a604Fd",
        "signature": "0xf4d14f6ec487cc943e0ea4ad2adc6ae1344dd4df769081988fc572a2f9e0249a04df1b935562e01c12a17668c6b64c5ced30858c541713a591e96c299a9fc4241c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x880B448b1F804d05903ac2e57b5a0edB1c001f91": {
        "max": 1,
        "address": "0x880B448b1F804d05903ac2e57b5a0edB1c001f91",
        "signature": "0xb2a8cc63a539a10613a3e547195abbe75c9a87920991f3d2213692004031354c3f2ea855320efff0d11982b30489cde4a565b6badc36120fa3f9e41f40a7a6121b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa545fBbC8b154A7e25d3d974cc18208404BCF22b": {
        "max": 1,
        "address": "0xa545fBbC8b154A7e25d3d974cc18208404BCF22b",
        "signature": "0xc344dc6eb90c19316d2d2bde90351289c825fe47f3758e5eb4b522c6599287a24e8d319cb8f8c6b7cbd6a4c9fac26bc25ef263b307ba127a9aa48d6a96bad74e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5C851F80b17bBb84D2a9b30291546ae2C6286626": {
        "max": 1,
        "address": "0x5C851F80b17bBb84D2a9b30291546ae2C6286626",
        "signature": "0x039d915096f19f66830020c85a33c306393325841544ba56dbc1c3d7b731795854510f01353a6d2be7f07e4be686966943f515219300bd8f087a433670e3e9261c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x351d5e6A0ef13fE13B8C99E5e295f573E08eF80b": {
        "max": 3,
        "address": "0x351d5e6A0ef13fE13B8C99E5e295f573E08eF80b",
        "signature": "0x213140b7c58e3f63a7d206dd2825ccd19ff1b4f717e7b1f9e5aa209968a7e7232dba7e918d6584b897638abc668ba32bc818f2ffef1b6140ba7c340fb9da7a821b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaAD157939e7428e3bE54B28cd4c8c4523eC4ac5a": {
        "max": 1,
        "address": "0xaAD157939e7428e3bE54B28cd4c8c4523eC4ac5a",
        "signature": "0xeb6651c6ebf37c0ff2c088e4bead8dcc2dcbab7a391d5f31e97bbe3b1ffcb70a32ff51e0c47a28f8febdf6f9a8fc2ccdae2b80d6e8a45b4dc5c29870e24a57cb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3a128Dcf798150F0B64e09D361Fc60136337bFd2": {
        "max": 3,
        "address": "0x3a128Dcf798150F0B64e09D361Fc60136337bFd2",
        "signature": "0xc7bb2a608b9fc0e2f769b6656cd2eaecb1add8fa09da18454a0201787f0078366b3b2a862a5da59ff2bb2c6768e7bf7d49d3e67842a7a8dd2eedeb81ca2b35921c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x97abda83F93897E91F69172b452c063084371b11": {
        "max": 3,
        "address": "0x97abda83F93897E91F69172b452c063084371b11",
        "signature": "0x95d2bb5013efed228c94889e73eac3c782bc44c6b65f49f7c4180574e482aaa04b28c50feb1299b1f5b6f4c23efe54a5fe661835352c60f547b88f02a53e24bb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc9196cde9ef83e45C36e9D7Dc1a1d5Ac662B4913": {
        "max": 2,
        "address": "0xc9196cde9ef83e45C36e9D7Dc1a1d5Ac662B4913",
        "signature": "0xbed38b7882b4bf63d1e23dbad019a4f2b725250e4632f1dd381f8e6bfb028f73204c7c667dccaa25bfe578179bf82bf266fa7524f171239105e18ae5757515f21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x818abDAd41D8Da344be6b9B29Bf0d3655048FDC4": {
        "max": 1,
        "address": "0x818abDAd41D8Da344be6b9B29Bf0d3655048FDC4",
        "signature": "0x91538ff73ef755d2fc61d6c57f04b8e485d9276d0dcba3c3a0d15e1bdac0aef22680a141ada6b053a3e9096249102f995889f5a32f2989e9d6ea710483311a741c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd65a09670aE3eBF98bd5B75A1f6533065f12Fb5C": {
        "max": 1,
        "address": "0xd65a09670aE3eBF98bd5B75A1f6533065f12Fb5C",
        "signature": "0x3431299ea3dd15ec6db75766e9f0a2333fa0800062603bc578fea0ac8e83f89131f05e0fb07b0ba231a9a2bf045d67fdc99063950358bcbf8bb796fbfd64d4dd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB110e84E4254eA29410333B4DA7E8F62b0e1dfeF": {
        "max": 1,
        "address": "0xB110e84E4254eA29410333B4DA7E8F62b0e1dfeF",
        "signature": "0x7e2602e26c7274034ed2094b9f46b20d96b5fbc0251153a6882d925b84c42c7e707d7a8afdceca36b16da3214b070afbe935f037b0ec48f1936327ee567481681b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfc7e8D8c765c7A0961CDE92DB1BFf274207961eF": {
        "max": 1,
        "address": "0xfc7e8D8c765c7A0961CDE92DB1BFf274207961eF",
        "signature": "0x83244bda3172a2d20510ec908122293ed15f920cde554b0e59406cf07f296486060b919bd70e97e9f016122d03c54d28ff491d96e3c1e453fdd2e18b5b9194ab1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5E10E5302CD4F06a2D0b40f10be6f2BdFcbeffF8": {
        "max": 1,
        "address": "0x5E10E5302CD4F06a2D0b40f10be6f2BdFcbeffF8",
        "signature": "0xd00c021005725f26c101125a1ef58835faeb1fbca7e28c0b18d4643c912149897443f576309388e0fa6fffe9361d977b1ff85ad9d046416ea25dc1b869ca91821c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x750420A63Cb975c8A3A157c6463Cb9feF8a21690": {
        "max": 3,
        "address": "0x750420A63Cb975c8A3A157c6463Cb9feF8a21690",
        "signature": "0xffc13407bfc148401b3a36d38f87f0fd33a1f563eb602c0be5539ccc36fcd4fa451be2abec894f1e4036666eea860eceaae345583ddecdecadbd2582a62c1df11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2E2370489903d7eD48f4CdC00322cd66A4757723": {
        "max": 1,
        "address": "0x2E2370489903d7eD48f4CdC00322cd66A4757723",
        "signature": "0x12fc75ae611a23653e026b3d2db355caa757c695e85ec9ccecb5a86087750c2c1fffa744ec31b588b98868f91bc698498554bef1c95bfd2f2471288af9d85f4d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3d72A400caBe8303142D7351cF2C7776ba1c44ae": {
        "max": 1,
        "address": "0x3d72A400caBe8303142D7351cF2C7776ba1c44ae",
        "signature": "0x2249b01294f10f6f7cce3c378f0635a5162fcaae67485230d471d6fbe2791d99248fb2c22dfbebf778a772c9fcadde6a40af95f66f39fdfa04c65e88c7889aa61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5e3443BfDE115276CdBDC403302C65B1AaBebfe0": {
        "max": 2,
        "address": "0x5e3443BfDE115276CdBDC403302C65B1AaBebfe0",
        "signature": "0x8be70f8d86f6b020ee87c9fe65de502b4569812c45aae3377af3ff398c643d2c6cc68f7511fcf302c8dc786abfd407d16afca1d7112399b596f12e824c2c757b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xac6538CACb1D89d52B1f5B67b22741AdD5b693F6": {
        "max": 2,
        "address": "0xac6538CACb1D89d52B1f5B67b22741AdD5b693F6",
        "signature": "0x0e4c2c6c0899049b36fc8c8b9ec491e6981a058a0f500da90168dd94d3ae35ff61adfe569758faad032967f0742a8b97072eb18fcaea1a2b06aeb1c997158fd21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5082ebf0F8a9eF2747d4a502FEf9826F744fe917": {
        "max": 1,
        "address": "0x5082ebf0F8a9eF2747d4a502FEf9826F744fe917",
        "signature": "0x3b19ee59dee8aa2826305045791238ba84d05292628ad83a985193d5a9385bc60f3e7305fb70e91db1fab7ed677897291a0bfbd38402252994a79384207f887d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDBb1b3922a8eeE9A3ae6980b1fa50872E7a928ed": {
        "max": 1,
        "address": "0xDBb1b3922a8eeE9A3ae6980b1fa50872E7a928ed",
        "signature": "0xfe9750ed45faf11ab8e94d654a7d7a8ceea8866e105f696fa7cd60037c180fc85aae00a8c8813fc5177dbddf71bcd63fb7fdd3e6399e9079aeee1625b37265321b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1013604e012A917E33104Bb0c63Cc98E1b8D2bdc": {
        "max": 1,
        "address": "0x1013604e012A917E33104Bb0c63Cc98E1b8D2bdc",
        "signature": "0x2c51732a4be71bfaae8ff320966831863726d5cdbfd195b94fca6dfd2a8d451f14c353c225e2265753453c734a6a75ab625cafab143d6b66eed0b268c30ac8ec1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x04DC1e988FF93baFA22599122bF6678576c631D1": {
        "max": 1,
        "address": "0x04DC1e988FF93baFA22599122bF6678576c631D1",
        "signature": "0x71ab194f77e94a4d157d3f697a62bc1f8794d32551768450f5b11f2eab9e64285dee6a0488ce20723931e5f6fc0b3c2de3dfb8481d367823de7476c3515f28e81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAD18F70346e633D92b12edb4239E8fe97F4cB60a": {
        "max": 1,
        "address": "0xAD18F70346e633D92b12edb4239E8fe97F4cB60a",
        "signature": "0x872230a45335389bd27bf53f3feb7538831575f37f92f9f387a5026dd778f6d5601d965ee1d285a17ae50bb1dcafa62d71ec45a75be5a48ed1471068be9c9a2b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDCEBb5aB04617d68d27b0d45EaF36e5AAdAde023": {
        "max": 1,
        "address": "0xDCEBb5aB04617d68d27b0d45EaF36e5AAdAde023",
        "signature": "0xc3fa551758d89782ac4b9d7f6e80bbec7272a309896b7de1766efc03897701dd516501bbe0caa13c64c176c4be4ac9229571244821e3fab7f03e4c8dfad6b6ab1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x04B75af9Cb2612aDec5d1D776B164eD4B864850B": {
        "max": 1,
        "address": "0x04B75af9Cb2612aDec5d1D776B164eD4B864850B",
        "signature": "0x987fe963eaea960ce5ac7fc68797bb4c75aa68588d51198c1e476af2a9d107177f04ef6b7ee99445426c75d717bb8549e35f26b02fae523a2b325883ac4886a91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5c3e8974467f6f22a4Ce29A2eE19Dc2074A9ec35": {
        "max": 1,
        "address": "0x5c3e8974467f6f22a4Ce29A2eE19Dc2074A9ec35",
        "signature": "0x99af3d58cda9a6c0cf8c6055f6c13b130e69a9e137e7babf5bc4aef75e9ae8146012cd9144faaa022aa1cca9abec401296c4e81d03415c85c09f5c26e31e2d9d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDfe6511c81b849bc0c22a30eba5206807AE8aF29": {
        "max": 2,
        "address": "0xDfe6511c81b849bc0c22a30eba5206807AE8aF29",
        "signature": "0x47668a54d1bf198e02d8d7e792e76257e441f24ee50b2a9627618c812dd5a2f014bc441b4c9af489b6b7f4b40baca11851a169398cfaa0c37c2ca1949ef24bdb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x65ccA79890029b9e26e724D5Ce70D48e4C6E2a56": {
        "max": 2,
        "address": "0x65ccA79890029b9e26e724D5Ce70D48e4C6E2a56",
        "signature": "0x7f7c3739422fe19f3ad16b5cd654e1e37f4d30dcd5653e28a197775430b578a4718a09a125017e78a784ebaa74f0eb12334432e053e603b68a13fdea325b24d21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdbb89bae4a9731c142B7E20E2BACE47d6aC4f3b8": {
        "max": 3,
        "address": "0xdbb89bae4a9731c142B7E20E2BACE47d6aC4f3b8",
        "signature": "0xd8fd349443b9ff19feed37765f7a7f502778d8a47fcd89589ed29188b2d241026651241834b7cd5220cf4cfb7ea86a413c02a62b61a77a82c7c84c0d55ff89271b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8A67D6158C25EA1BCf0A864c0a25798EED9F2E1e": {
        "max": 1,
        "address": "0x8A67D6158C25EA1BCf0A864c0a25798EED9F2E1e",
        "signature": "0x9c50874ce1baf72993147e8b64689d33f4276860f4562a0cfe815402b4717ff83a8fa6a1532ad127cf1de8a38c3ad6e549f490bb77d4cd9fb6a35c6089de11d71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEacD04C896B5AB0E4c9ee67847169bB587cEcAa1": {
        "max": 1,
        "address": "0xEacD04C896B5AB0E4c9ee67847169bB587cEcAa1",
        "signature": "0x0c9c7548829a1364754acb5b7097f4dd021d0e2d65a306e3f6635032da3add832857d4c2110addccfc095b4acf4c3934649462d3bccd7443956bc08bb38a1baf1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x56fD6b2daB262d4355708565A0d694294B53ec21": {
        "max": 1,
        "address": "0x56fD6b2daB262d4355708565A0d694294B53ec21",
        "signature": "0x1bbc67f4018808e5e522c3a1a4a200c68244882a9f24087651a2ede411d8ae820ef1af13491a387f79ed329d382f7574a0ed854c5823b940c8dfa105197993f41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5d72121593C83656cb1C3A6925B48C7809dc45B9": {
        "max": 1,
        "address": "0x5d72121593C83656cb1C3A6925B48C7809dc45B9",
        "signature": "0xa9fc663081ff9d10a0df5c1a7c9549bb40a1e2b2254f7a45f8281dd7b45d1060192bef0c432cff675be352add1b1adc7b64a5cd808cd8d38764ad6e0328b57841c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa907F57577dB15b5A49B929fe9eC50BB0E696580": {
        "max": 1,
        "address": "0xa907F57577dB15b5A49B929fe9eC50BB0E696580",
        "signature": "0x463fa5137bc591813bd9c222b0f151601627c3f3efcbff8b7ba80cc936db44974c0493441f3a0bd7124a5e7e2ad93a01e82906675c6279effcee49c461cd6ffa1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7055B751f188e1c564d5d9cd0702b3D78fDeD50F": {
        "max": 2,
        "address": "0x7055B751f188e1c564d5d9cd0702b3D78fDeD50F",
        "signature": "0xbfc4a3d60aae10d439d777e91671dc3ad2cb074eab0da07e217dd6bdd3323a4839d48d72192fcd35d828e43bec2723638f524e48437916072a88678b26c884ae1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x22A837B1550A2293284B3c61A7fD4bbD1d71C6F7": {
        "max": 2,
        "address": "0x22A837B1550A2293284B3c61A7fD4bbD1d71C6F7",
        "signature": "0x530d83b30ba0a71ff7c945dfcb42f494f4dd639618fc1a42bc54e281ea73fcba2e0f1ef53b8f60adc15d5e841b64feb18e7caee21806cef30a8af6140d9d60fc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x585d82FF26525D923999574172782b7372A48f9c": {
        "max": 1,
        "address": "0x585d82FF26525D923999574172782b7372A48f9c",
        "signature": "0xae152590c3920444c22c0e148fbc18dceb41c38db8a661866efbea74899b35704fa821da8481502b6a4274c0c2325289286015c45b509a7104242b12a8fc4f8c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x12eAc92Ac2326A392f5a6e899d6D636829C51848": {
        "max": 1,
        "address": "0x12eAc92Ac2326A392f5a6e899d6D636829C51848",
        "signature": "0x3693fada0fd4fdd22daaf168537f34c4368cc2420ddb9e7fc622bce9029ccd702e2948800fe6fbbaceff989fa1afdf3b062c0296c92f0f19ef75ed12340b0c881b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFe5601a751F55A0f095dEE9f6904c92FC115a8c5": {
        "max": 1,
        "address": "0xFe5601a751F55A0f095dEE9f6904c92FC115a8c5",
        "signature": "0xba396ca7dd3a94f374ccdb02d8284f02cb237d57423f4c53af435d804599459c35c6255c27762c0796dd56290cb1ae1bc87c4941f535ed1a68bdd14033d65ddc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0f3b515313f85F896142067af145c69FB56cD5B8": {
        "max": 2,
        "address": "0x0f3b515313f85F896142067af145c69FB56cD5B8",
        "signature": "0x202e3935ede64f84a824e617a6dfcc84185da08190428a3dd69271800b47963062765604738acd7531a87a3c2a28b230ae77bba84022b0daef81431bb67c7a4b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC53C9CE817992b7679ccd355D25B172AE611D6c1": {
        "max": 1,
        "address": "0xC53C9CE817992b7679ccd355D25B172AE611D6c1",
        "signature": "0xc7c2bfdf5f43083c1d9f8c82697d75c59018f86d8c9b52edc68a6c9860fbe629630cdabcc3b708e865a0868a5a1ccdbd9e99591c8a3e9398b69c524ee7ab711d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb0733F682eDBe8340914e50AD690fb5D6546397D": {
        "max": 1,
        "address": "0xb0733F682eDBe8340914e50AD690fb5D6546397D",
        "signature": "0xe30dfd4d6b4367075e5dd2789d2879c012e57ef95c9bd67339ccaa1137b70a4d6b3acd4851251fd00a8190174838ec9d0e166a42c5d76af264ae4b8322f7acbc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb90afcd73a415ad3C877ae662D2802640B6e1773": {
        "max": 1,
        "address": "0xb90afcd73a415ad3C877ae662D2802640B6e1773",
        "signature": "0x69da00eca9242e17df50409fcda3b28fb062888cb495a2e5b4a3380be3324c4f317ac8add306067fd63f38c2f06aeac2ad0bd0e408744d6f0d9a2f2843494f211c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb711DE8A450e9B41BEd68Ad6bd6f98290eBBAa8b": {
        "max": 1,
        "address": "0xb711DE8A450e9B41BEd68Ad6bd6f98290eBBAa8b",
        "signature": "0x066565fff39cc6ace64aec07d54ab4b9c57a32f136f3792dd692faee02a1742a7950d58d40b11113f3f91740cdb59aaec2211bfa657f8ef8a479952b336ad4df1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa202761D9bFc52dc51391f80afD840e56AF47e7a": {
        "max": 1,
        "address": "0xa202761D9bFc52dc51391f80afD840e56AF47e7a",
        "signature": "0x78fbc54827d1fa9e2061b17df22e4db1f7ea85511ab497e3df1a085d03c859330606592b42d962d7a3367d7d252c0fadf7aba7f527719fb27db31deb32b01fce1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8559E47b47Cc2661b89Ecd56E5fe0dDB17f31E70": {
        "max": 1,
        "address": "0x8559E47b47Cc2661b89Ecd56E5fe0dDB17f31E70",
        "signature": "0x48c4d89a5789733209a771f08a78ef05026de59de6496aa598c59095b56bf01f2664f73de443f64374c3a6db5390c8f658c2cf8c12372d1b66db5102913a27e01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03c1a4649f8387D02D16229adaDe2B3CbB32Ba1A": {
        "max": 1,
        "address": "0x03c1a4649f8387D02D16229adaDe2B3CbB32Ba1A",
        "signature": "0xdc261b641ecef14012f8057ebd4ce96ea63c41948d3eeb348e2787cf6aefac2f4f669b46fe0b4a8c19f6d8c234ba73a88c7238ff19dbc6edad06a54ddaca6b4f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8d32b74717dF76ac24b7Dd7D34dBFE10b2BB1576": {
        "max": 1,
        "address": "0x8d32b74717dF76ac24b7Dd7D34dBFE10b2BB1576",
        "signature": "0x63796448c1807f67cfb2f318653eee222675ea84ba4095cf8f4b8a0acc0540ec6f641c3bb943fd1d8ea43f89f4e62764df88dfdc27d1b92c62737d07397d256a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x54E99032D4151edd718701Eaf93E320f96171474": {
        "max": 1,
        "address": "0x54E99032D4151edd718701Eaf93E320f96171474",
        "signature": "0x7c8417148d898ca3a87e9bb6256a66f0c35062e5b9c9cc69f43c49f582d8b0e62e016de98ef01e9670e9ea9968cff4d75d3007699067ff2a08b330c3967f36461b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc6a92e6FDf88b635BD151bC9e5e93B8a71270821": {
        "max": 1,
        "address": "0xc6a92e6FDf88b635BD151bC9e5e93B8a71270821",
        "signature": "0xf153b7bd23b99c181cecb9bd5c87f648ffc1632055aad1fc53f50032352670b95de7ec03358bd65560e511bdd4ad93ed9a33b2b10a408a130fc542d2b69a27ae1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x32402605cB85ef63A1cd239eDD4AAa42cDb3C4e1": {
        "max": 1,
        "address": "0x32402605cB85ef63A1cd239eDD4AAa42cDb3C4e1",
        "signature": "0x795aa722ae606e7ce62b5d266b3e9d4eb106bbc4f2e0e563ef9f8e519b99d38379e8a3b6d7f3a7eb0f095d603bca7991ea5c6da9d3c726773f5e89c099c32be91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA694f2720DA2d5B1E95A7A7817356beCB6eC4D87": {
        "max": 1,
        "address": "0xA694f2720DA2d5B1E95A7A7817356beCB6eC4D87",
        "signature": "0x25e38a04c24d0c15e37c380e04128421239e2a4e592ae316d5cb068ff3856d3734255994ca929655b2c473b690c76417819139bfe97ec0bf1f4b9e8a50ff90951b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5042F5e1822D94dEcAB2215E0A63e1CbA3275109": {
        "max": 1,
        "address": "0x5042F5e1822D94dEcAB2215E0A63e1CbA3275109",
        "signature": "0x8328ee77a20192103fb8d38a9d8510c67205085518be389dae41d6038941ad4c4b537f7ea9d6e2b9aec35bf767ee709c804efeaf6762e44ad131d2a8f457d3161b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9721c4d0DEd3C24664d1C50021B8eecf49941E2F": {
        "max": 3,
        "address": "0x9721c4d0DEd3C24664d1C50021B8eecf49941E2F",
        "signature": "0x6a24d154ebce285d644027c9ab856a7e9b8291184e1650cc56db46b49cd1203469bc44d9aefae115322588e3c79aa06c74d37103af9465d690c368f42d74c3a81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x814Bc2e41ff132e9c239f60dF39bD4ab67bC30e5": {
        "max": 1,
        "address": "0x814Bc2e41ff132e9c239f60dF39bD4ab67bC30e5",
        "signature": "0x9cf0b1078901e030e37d954bf4068a997d2f862d0ed30e4c54400ca3138248902f89e9f8e11aebcdb38b655c302b89c6669dda94a30ad6f4a1da1fd18300b8bd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x76158FEf0d9e2B60ff6D0e0D842eB500e1F80De5": {
        "max": 1,
        "address": "0x76158FEf0d9e2B60ff6D0e0D842eB500e1F80De5",
        "signature": "0xd4d024a8bf967d8b5c3895bc34f2ea76cd55176859612fd6630eea06210eb3442b137f8b6ef451c1ce13ac8ff5c3f847f1f94c3930067ba6bf84b91180daa1a91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB199BB44C770B75504EEDd376d736E388a59A053": {
        "max": 1,
        "address": "0xB199BB44C770B75504EEDd376d736E388a59A053",
        "signature": "0x1aabacfbef53b024032e5fcf5fb2dd31b840e2db04aa606aa19f73c49d7f21f2619c2afbc62c55ae263bb991da9411390dc7ee330137bd48e39baaa4f00042021c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF5B2fd08AF5de940c08c77231e416818063ee0d9": {
        "max": 3,
        "address": "0xF5B2fd08AF5de940c08c77231e416818063ee0d9",
        "signature": "0xe9adf4631f9573cb6f241378354771292434ddfa5840c9021cf773fcf9b6b88d041c42d7c1741ef7dba731f3e849c475a22cc75f28a76898a1c626a0e4a2417d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7009D91C0cdC153001fFE873c14De723DFc894C2": {
        "max": 1,
        "address": "0x7009D91C0cdC153001fFE873c14De723DFc894C2",
        "signature": "0x1e99bb50af1249b31258e8a8cbcdad338ed0755b10b2ef139c1e455327f5a2c615ba1844dec7ae7fd394af411c60fcabed962b011d687f7d7f792d8ccd1ce8321b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe5bBce4A4ee942EB56459EEE83E028D7c30db829": {
        "max": 1,
        "address": "0xe5bBce4A4ee942EB56459EEE83E028D7c30db829",
        "signature": "0xa03a45557366cadebdfe901243011c053f1ac09f0a5706607158b7bd310f71a07ee2b83295aca5b2e6fa4a2b48cc5da773dd71ae08bc72d7349bffd20e2908391b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x552e366f9D3c4f4C1f9F2bebC493715F866Fe9D9": {
        "max": 1,
        "address": "0x552e366f9D3c4f4C1f9F2bebC493715F866Fe9D9",
        "signature": "0x7fdeba04b714e3a26e016ea832cf2908247872aaaa08d372068adf8e5f0a0505663d99c1e990af86ac14336c5067bd166ca578ab3885d921cedac9eea535ef4c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x85697Bd2229794b7A29B8420DBEA8376ecBB6D00": {
        "max": 2,
        "address": "0x85697Bd2229794b7A29B8420DBEA8376ecBB6D00",
        "signature": "0xba62bdd9eb8a26b0d592a492954c1d9e377a629f764c553004a50758ed91bf850225bc6ec5fc54d23534e8e8fa7e7439fa0f9edeb4fb673c33c08c0f8f1e06df1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc8d6753E8c8c66294B74367a1c36D771193F4258": {
        "max": 2,
        "address": "0xc8d6753E8c8c66294B74367a1c36D771193F4258",
        "signature": "0xdef38f28648e676f347c864f62a575b27294793615dee12dc3ce5c142b30c224573f7908dee2ed0e3733322489c8f770a516c422c9143b45a7cbabb633b230aa1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x735E8dF37d96030F70c075a827E215a67058655f": {
        "max": 1,
        "address": "0x735E8dF37d96030F70c075a827E215a67058655f",
        "signature": "0xed48b2571c5bcb4c050215456725f5367d4919a152d9c94bf2461ad563b2a8c50456fe89d76ab956b01904f0f3d86cf1f54e820a18540d291da5e5235f6c026d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x48ad4fB7b9D0110c6Ab1122c225F67CB6a99Db71": {
        "max": 1,
        "address": "0x48ad4fB7b9D0110c6Ab1122c225F67CB6a99Db71",
        "signature": "0xc1d0b9961f5205d85a81ab1ed7851ae34c0d0ca3ef6c1ccf0745f4698a90fcb2060290ef6195c256a1fd723460d9364d119fdbf63d5cab3b8045dcf75ef753b21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2c441cC701351Be600c5e8e5DcF458F4cFba4337": {
        "max": 1,
        "address": "0x2c441cC701351Be600c5e8e5DcF458F4cFba4337",
        "signature": "0x8b9f6362f69f045869aaff44a623469cef86a66ac2d025c8d3709ee7485161064ac4cfd5202cd1b3cbac14e887a0dd52a0946f978875f6e470decc14e61b059e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x174De1CD3913671BF168A9885898E283DF273B7d": {
        "max": 3,
        "address": "0x174De1CD3913671BF168A9885898E283DF273B7d",
        "signature": "0x1f27ec06f9fc13465687e0d63ce17c1f051065a41a7c062af7497f6c29630e9631bc86611c4d833812dd8606129cee033986373f7ca29dc8d2d317e8f7a1d1441c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3757F0C8825C302548aC120c90c6497B3d36c5D3": {
        "max": 2,
        "address": "0x3757F0C8825C302548aC120c90c6497B3d36c5D3",
        "signature": "0x4b778cd64cfc6aaf00d08eecda2f084af2b5657013d7c4497e8ccb14f4263f68493d39ece1f64bd8df23f26cdbcd3bb24ea5cb46ac076c588c71f93c53eb13dc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5F00e67eFf0E69848E5a27e3023B019798cc4C63": {
        "max": 1,
        "address": "0x5F00e67eFf0E69848E5a27e3023B019798cc4C63",
        "signature": "0x081a2c7bd0b388e740873ff33e425b07af252c38488f7ec46878df8a130fddfb0ca7d379746d9d275924dd0b9547639454c0c969bc3745e748a4aca3673ab5831b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB9dcA9391ED90b2219758c601B8677f361F298A9": {
        "max": 2,
        "address": "0xB9dcA9391ED90b2219758c601B8677f361F298A9",
        "signature": "0x04f1a7a8c733bb4cdb53f2632824372ee52fb3eda0104fad565b5ea2f9e517720992851743cb2f73326320aa7f996e008c70da7523b1b304a74279741af5d01a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcBEf76B825F1F995D7DEBaA3f11Be2A793A4DE9b": {
        "max": 1,
        "address": "0xcBEf76B825F1F995D7DEBaA3f11Be2A793A4DE9b",
        "signature": "0x3b099e8beb036c1ca30bf540d0c0db730d1baade05a1e8cb24bcdd7f09ed96182231236ea482fcb1fbf09a70be6b1e76256c369d12019576561060f05d56134d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDA3fdE8669AA5E143f1b5E05b5d90e273FB997e5": {
        "max": 1,
        "address": "0xDA3fdE8669AA5E143f1b5E05b5d90e273FB997e5",
        "signature": "0xf12cc6183d5263e6a3b0cddb49a721aed46cbc46fbac19ff5ff39bb4fa7a4bfa5054710db00ad0fbce29bec00f99a3dae784932e9879809dcd183d75bbda0f201b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1d9492901285715E053c1510Ad32C72a78998718": {
        "max": 2,
        "address": "0x1d9492901285715E053c1510Ad32C72a78998718",
        "signature": "0xdae3b4abb7b77c6420e26af8c268a127965f32f5320852ed6050a7d1515fa8be6b083466d9b1448a9263ef8d73a9454065b357011405cb98e7bc506e56f0e6441c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6bC852D53E7a42baa916812609F588aAE5CFD7b5": {
        "max": 1,
        "address": "0x6bC852D53E7a42baa916812609F588aAE5CFD7b5",
        "signature": "0xc054d18562933f4af042f70ae1fa7e8f51bb3c08a5ebf5e07ed00492438688091ae238116a14553de2adac44a61c9af1ac4bb0afe9a5ef94d7d13b9da4f19bcf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdc2C430e6610e77b21182fBe2aF0A5Bc6D90bdE8": {
        "max": 2,
        "address": "0xdc2C430e6610e77b21182fBe2aF0A5Bc6D90bdE8",
        "signature": "0xc425b8ef8ab7d56918e1073e5ede1ecde785393cb544c7388e7882805c7ef614383681e95025bdcbe66fb36d0309fdf26e65d52de08a9f6f637c3b7d96dd5fde1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3A1b7a674739047bf175f01eb23CA956C1c0fC83": {
        "max": 2,
        "address": "0x3A1b7a674739047bf175f01eb23CA956C1c0fC83",
        "signature": "0xe82f4bce79753fd0a98e46383e89dae23d655957da8fe9ae80894f4f2be20d8c11206f2578a45c76c8d65d81a70729de100abf89de2fcbe817f82444fe669b591c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6f786e9AaA2C0222075A329eF0834633474883B2": {
        "max": 2,
        "address": "0x6f786e9AaA2C0222075A329eF0834633474883B2",
        "signature": "0x6702e448753954c6e4aef4b7c5720283cdd37ffaff4db8bf2799303e163f0e3d201a9b982cbae1e74a839685b7704191300885aaf5ab6f51c58e97c6e052e7261c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC195efC58af0099db05fb6F32146C11B2b810dEc": {
        "max": 2,
        "address": "0xC195efC58af0099db05fb6F32146C11B2b810dEc",
        "signature": "0x4f98aca6bff3c57fde9751d8cb549579b7f5210ff9156de8371576001e948ed97426aa492eab1e88dc61e7a0ee38ccceaae8c505820490eb3a4b5460875b78681b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x75cb79e6061a8a1Eb7577E1c660Cdd574b0ac1D5": {
        "max": 2,
        "address": "0x75cb79e6061a8a1Eb7577E1c660Cdd574b0ac1D5",
        "signature": "0x310c43826d815caea3e7d894e64df141054e9f21bd28ced93444a5a02f8fbbf80a2e528dbcfe8746e61c3367c5357152d5ead350f578ec505c616077af482b8a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf7Eb069d2F3ab34f4EA1aBB35eD29d865F63ED36": {
        "max": 2,
        "address": "0xf7Eb069d2F3ab34f4EA1aBB35eD29d865F63ED36",
        "signature": "0x2b606334767c48d1593f9aafc84699d4d7ec6d8163db42a435b9eb615bb8067a1f30f235b4be9d98539bd245a78c308e36dfa386331f0d3de487cae0a976672b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9bc3d602d709553928dc4Fe7ea8F5ba66Eb4cf4F": {
        "max": 2,
        "address": "0x9bc3d602d709553928dc4Fe7ea8F5ba66Eb4cf4F",
        "signature": "0x913024c42482df706455685f4c4ba173a87b402cf53357ae2cddca1431be439575f030849ecd03cdf76f52870eac53322c6e0375df98879e78e000ea5dc98e6d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x28ae98aEFcB40A6e4891085D5cfDBf783Fb3A093": {
        "max": 2,
        "address": "0x28ae98aEFcB40A6e4891085D5cfDBf783Fb3A093",
        "signature": "0xbaa8cc45db162266cf8989712f6428774ce851425810f662408f74e6f3ee32c705b5fecfa49dd2aee7210122dde687051b55854a471a8c51651c857b92d83f051c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd348a99a1904cC657328666b3bD2DE556C3E8E2A": {
        "max": 2,
        "address": "0xd348a99a1904cC657328666b3bD2DE556C3E8E2A",
        "signature": "0x9cdbc31cfd05e9f46181dcb52bab0656f5ab05869d357898269883101a65ca001f29d52d331e668e83a5460d03a8fb13bfcac676fe81bf2c930aeac49582c9991c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x32Dddf474B068c024150946907DD4b93b5FbB72F": {
        "max": 2,
        "address": "0x32Dddf474B068c024150946907DD4b93b5FbB72F",
        "signature": "0x9ea7be8d1fe772c459977f79e6231f2f2cfa1d65fecc9fc160ece5f92fe90978254ea7c23e91ecad4db5ef27bc13e24b7fe7b7fe59de868e852ecb777122cc311c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCD1c4e53da45cB51DeC041Ae8eD7EFD36Fd7fAa5": {
        "max": 2,
        "address": "0xCD1c4e53da45cB51DeC041Ae8eD7EFD36Fd7fAa5",
        "signature": "0x2b98e7026b63f578a6f639281afab40808ee4dcaff0f870ef318caf9f065c1113965403001f4e36569d8156ee82275abb8c708ed0f8eb22ad4a677a6a51812a21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4A34e313b45ab4eB5C0306EDD14D673EBAB5560d": {
        "max": 2,
        "address": "0x4A34e313b45ab4eB5C0306EDD14D673EBAB5560d",
        "signature": "0x25175c1e92da2beaf4b45b3ec8740515f3c60c0265ec4ba7f002aafd51f64a590feb4ce07907965f44d70a96e1e59be6839f37d912ed7b063fe5fc1ce922fb791b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x39514322235A5d8488640a8034c2d10DE1d78592": {
        "max": 2,
        "address": "0x39514322235A5d8488640a8034c2d10DE1d78592",
        "signature": "0x098c96940c17fe76488625f182079d7db14f778a5716b6f9a6daea1bdf57bd3c4ef7335473448a2771fa0481a8cece278f044f82d1ce74c03cc79204e08a90a71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4A8DbfCE4eb1BC51f63BFb7e7a144745261D6f11": {
        "max": 2,
        "address": "0x4A8DbfCE4eb1BC51f63BFb7e7a144745261D6f11",
        "signature": "0xde138bfc1069e7c24a7bf489d7b1d43853c9d7b380ef0f404ef2bb8abcd803e9469ee060e079909817bafa869f20a956f9d3f456ba9d68734d668c9aad89614f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE0FD9FBFC0588e153C206303654c54700b682139": {
        "max": 1,
        "address": "0xE0FD9FBFC0588e153C206303654c54700b682139",
        "signature": "0x014435a02a60b4df552cfcd11d33f6ed261dbcbb97b6af0a2c6ce2d0d1d13fdc019a7abe2ca69df65cb72c8645e288ff73fa300557c366d3c26492ca5ecece691c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3e5f22Be83D70a57522e2687827670A8de6a9013": {
        "max": 1,
        "address": "0x3e5f22Be83D70a57522e2687827670A8de6a9013",
        "signature": "0x0be7568cde52f31ffdb828fca502cf3f5a08ca61f1a67c8712db21551b806c3c27334eb043866749778af8a3b0abcca4cfe5fe102c746946a7fcbc470fc871b31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5647f7e8F649caca38241E823a330Dd92D65740C": {
        "max": 2,
        "address": "0x5647f7e8F649caca38241E823a330Dd92D65740C",
        "signature": "0x8d06f029bb3499149510ca8b580bfd9fe6a8f0cecdcf37cd63d86ad4e3c3ef4d2928ee86991c86d6f238b8b42ffbfe096269d2da0efc4a701325bb96369389c61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x005B9317Ff2644A1Dc7798764C6F63C193Ce0881": {
        "max": 2,
        "address": "0x005B9317Ff2644A1Dc7798764C6F63C193Ce0881",
        "signature": "0x896e5d2249eebf7b6ba8202c43deaa98ad7747f425315bcc79377be45e8b2ba26cdde3351fe799b9fbea6a5f379b3da2a8db9a0b35e0bfa8277ed915eeb517cf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeBd85caB4dea917F1Be9F89a1789de34A42Ce593": {
        "max": 3,
        "address": "0xeBd85caB4dea917F1Be9F89a1789de34A42Ce593",
        "signature": "0xf4305a1e32ee70d8855cd9d25a9af7154593d7eb3136c3fce0df2f3c198fc7ea66f924164e8fcc6adaa5e856f5c195cc023b39f16e0f14401a38698cb628a8d71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4b90ad8Bed0737C5E66913b9bd8c1856f1b96C0D": {
        "max": 1,
        "address": "0x4b90ad8Bed0737C5E66913b9bd8c1856f1b96C0D",
        "signature": "0x6659b96d76632d978f4c5959aa910d02caa5d9e2449cc31ee3750d4c6f9e02ab4072de8fd1474857b290a3d1dd17cfc742a9adcc877b5ee50fbd2c923995fe6f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE17273bf7b646EadBC6285273b417fBf630ee87e": {
        "max": 2,
        "address": "0xE17273bf7b646EadBC6285273b417fBf630ee87e",
        "signature": "0x4e04ebae5c144745fe59b69f9255d1ad270397409bb2bd8db42eae6a519ad2955afa12e7040f7139362ebec7f5829cf67b22898662336fc3f133610de32c66f21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x623806c35bcf2B1cA8ceb2724C050b2707eA882b": {
        "max": 2,
        "address": "0x623806c35bcf2B1cA8ceb2724C050b2707eA882b",
        "signature": "0x6c3ec6e68b97efb630d64c0094f746b8e497cfcd7e8eb6e9b5641898580fd33f7dd89039a836895cb73bfa107bd94e9be8e4bcb76c8ecbb591a40404a6401cae1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2B9Af6BC026db080203c86989ff6c979B380cBbf": {
        "max": 1,
        "address": "0x2B9Af6BC026db080203c86989ff6c979B380cBbf",
        "signature": "0xed533ba050cc7da31f4c514e2ff614782d1cc5c08eba7a1404c3de615dab9f37701448bd0110eb653930b5498cdf5089b5a40a526746d082e8bbc28d986498eb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF69f91DE1889013708b42995BE19fF6195dEbB9b": {
        "max": 1,
        "address": "0xF69f91DE1889013708b42995BE19fF6195dEbB9b",
        "signature": "0xa5d2fd8964dface8335d5b5439caef2b58bfdd69d1086a6524a3633988bc60166bbfcb300763f7e0cc47d969458fe91f41d3a406a63e8efca6610edf264fb8451b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x764Bd8622988adE3991bd5434DCb62f98ed0BDe3": {
        "max": 1,
        "address": "0x764Bd8622988adE3991bd5434DCb62f98ed0BDe3",
        "signature": "0x1157645a9e5b08dcb0d8fe970ff5a9f84fb8e85aab0b5d94c8c6d9b1a42202791e6b7df0fb035809e5e604ef546f9a7060c73a2c1fbc999e628e12b98213fd991c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfd7e022c7A29A03B39B32D391B780bfE4b646D2B": {
        "max": 1,
        "address": "0xfd7e022c7A29A03B39B32D391B780bfE4b646D2B",
        "signature": "0x3896f01d38773070be4b60114e256c9769ea9d9028113778c5e136edf6f9e66f4bbd7aa1de645c8d14a76be5777880acfae45fa33b829236d780d90a53dc36511c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x71dcB19A6D322C9D826aA6b61442828436Aa6fb2": {
        "max": 2,
        "address": "0x71dcB19A6D322C9D826aA6b61442828436Aa6fb2",
        "signature": "0x979c3bb67ed3ae53cca9711eae0a3bf3e3c71111f91cfb4217a3666f08f157c52f7f22be1593f495697a3cb008cac3939607e9ef71c6042d6c9cca0357e89ab11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD35014d4f8564F15029657f6911fdbC3d5622d37": {
        "max": 2,
        "address": "0xD35014d4f8564F15029657f6911fdbC3d5622d37",
        "signature": "0xee3215db0e0df74eedf6199ba6e955c0ca9bbd15c796714a7250cdc672f4b6f264f829708b18fbecd1e229ea17ca8f004753c074131b492e2f0e1c78823695c51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD102d767808BDd2cc9ff85A4Ebd23E5e5748BdD6": {
        "max": 1,
        "address": "0xD102d767808BDd2cc9ff85A4Ebd23E5e5748BdD6",
        "signature": "0xb21f3bdc688bd405bbb91b80fd3f2f6aafa15e25ed142275cbfe7cbf47c1691b00e5b8ae22839aa1c407d868e260cd12e39de104d2d8b26dcbce067a706fff441b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEA504A1C116e4446DCdbC9f885043ABB5cA9eB35": {
        "max": 1,
        "address": "0xEA504A1C116e4446DCdbC9f885043ABB5cA9eB35",
        "signature": "0x44915484e0037ff60ccabaf4765ddd75e83066ea70478b6dd1f6687d1b812ed1020bf63f7cda51a8d48fc17c300faa7e5efc042a19f93c064e7e0795d9e46d541b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x35948609753e169b0DCCc6412f537c8eE7eCeA73": {
        "max": 2,
        "address": "0x35948609753e169b0DCCc6412f537c8eE7eCeA73",
        "signature": "0x2e1ef0d0e86d547f310c2b0c8a001596f31973f8ba2b7ca593a1784b8b51ee6c20b06d29c501f35c1c7b245055944a5ecbb204931ffd46d2c95e041ba65bbffb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x97Bb12e8427E6FDC7881927dB0B0dA14445327BB": {
        "max": 1,
        "address": "0x97Bb12e8427E6FDC7881927dB0B0dA14445327BB",
        "signature": "0x93625f9959169f8283d35adab10e8748031392ad7550974a8b0500a2a2b8da21141bab389ed4c29adcf222e76f4ef019fa87e6fadaed58a5c5bcf905bb0ca2821b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEd2bf7bce7e577E9ab4B9244d3457D20a1fc5d78": {
        "max": 1,
        "address": "0xEd2bf7bce7e577E9ab4B9244d3457D20a1fc5d78",
        "signature": "0x40e44e0be6b51fc8141d19dd9f7c9ca06bbd8544030870b3d0160d77fb72d80c17e03dc43862fb6d5d9f67a2c9a2ae681d74b59bb5c20084119240f7a3442a331c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x09d088e5aA8a9c5629E4aa577C1D8fEB6cD81338": {
        "max": 1,
        "address": "0x09d088e5aA8a9c5629E4aa577C1D8fEB6cD81338",
        "signature": "0x7194f13726ecc45140538d2573c1620f0a96d61410f6b2bcb51d3b723b808513766f03a3e4351d6d920ad5e06a567cac26306846162a457a5c8bd419320b0e0c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFcc2e892bb08e640bC420e26a5018417102AC807": {
        "max": 2,
        "address": "0xFcc2e892bb08e640bC420e26a5018417102AC807",
        "signature": "0x129ca759ed08ab7e2dbd5a7c01a429ae0d409d71204eb8d2753bc7745206b24534dd5b12a12922c6c1a4a184bea9bf03bd84567fbe16e0eda23063974509bec21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7D360d99E6805fB65E4594d6B9080666af80A916": {
        "max": 1,
        "address": "0x7D360d99E6805fB65E4594d6B9080666af80A916",
        "signature": "0xa74f4bff66493aa3c7402f384a5baeacec2fe3fc456caac4e7fb4160661322ca32860a8bbaca02db74e24ef7d155fd8a6692be4dabb16f71092bd4951cfebd781c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x049bf1369aeC014aC7EF54EBA0C2572B1Dc96212": {
        "max": 1,
        "address": "0x049bf1369aeC014aC7EF54EBA0C2572B1Dc96212",
        "signature": "0x4a2f131c611d818857fc8cba5c67833812344fd2d4cc47a90d35851f5e1c20e514c116952b482fccefadd060ea8b041918dac0119f4bfc0bd2cb71dc95352eb61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x42A7f1b707C6C55efa37aaef7Bc8578A90921BBe": {
        "max": 1,
        "address": "0x42A7f1b707C6C55efa37aaef7Bc8578A90921BBe",
        "signature": "0x3f1a4aa4648e11349a7a1ab1d73c7272959bc9d20a70a52d8a351309456a14c5031e2e6f5e788949167dbe9f3ef8f9a6ff1b5ba19fd9912e7ecc8455d997fbdc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDe9f863f579FbFAddb270b8995cb24849c47c02e": {
        "max": 1,
        "address": "0xDe9f863f579FbFAddb270b8995cb24849c47c02e",
        "signature": "0x3afbfc36c0d9afa26fc4eb703010b69e0ff1b2a5ad4f4aa86d926c3d07daf07b46c655275afdb6240d84ffdbd856c64955539675cf951fce2a73671425dd64d91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE13B07464012db1c325e1b22c72DD9Cf36B71a71": {
        "max": 1,
        "address": "0xE13B07464012db1c325e1b22c72DD9Cf36B71a71",
        "signature": "0x81d4abda1ed3b2088ee3cfaf1eba0ce08ad741bee3cdb5f4a3e2b46a7b3e56143a9413bf655275250ba57e8478f30b4f442b788fe75cca7409cc12806e6e8c121c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x065ed1954e7811278654c588E7E1027d9849f486": {
        "max": 1,
        "address": "0x065ed1954e7811278654c588E7E1027d9849f486",
        "signature": "0xbf293cb5dd9afbcfa726df73b4f6837e28ab0c5d86b06a693c2858729e348e834f3aaf81353266a37a5c269b9adf852a1e5f69d50ec5bc50a1968eaa7da03e861c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC779f5abc7422572C9A6BAe8f457468A5CF195cF": {
        "max": 1,
        "address": "0xC779f5abc7422572C9A6BAe8f457468A5CF195cF",
        "signature": "0x52569fb91b1135c2731f6581faeaed7df177e54a884d79fdd251bc6cf602fda27e5d5ccaad0d93a52a4fea245cbb50fba9218cf8c379f46434db25e29ea3392c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8653B29006D5fF212041D8727D2AE89E07EB0d5F": {
        "max": 2,
        "address": "0x8653B29006D5fF212041D8727D2AE89E07EB0d5F",
        "signature": "0xbf6b9e0507b3369abf3daae1105842a4b549ad29264d10eb5fcf9dd9d39875cc3efb57674a9092b4f834edd3bc6499799b84af01da6dc0d54b83e459cb920d7e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xacbB69dAC5E15Eb352A39ce3e6660f028d012EA0": {
        "max": 1,
        "address": "0xacbB69dAC5E15Eb352A39ce3e6660f028d012EA0",
        "signature": "0xe5575396543f7ce34a17671f4f31ad99fb395f07edb2dd1951d39d8683e7dc1831ad65585576c1807c0c7aeb49758eb2492276c62241c258b745ecd54483bbd41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x780d706cfb9E903275339Fe37AF98b2B3e0090e6": {
        "max": 2,
        "address": "0x780d706cfb9E903275339Fe37AF98b2B3e0090e6",
        "signature": "0x9a36fabdaf8ba74bbd2cc22d5fac2435f6f0896efca123adab0e92b89884046575ceaf5322a00f2187b6e0a2cccaaf77f74756463d74589c0f4a1b6062d82ef81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA82713C0A98dF01AfDC32Dbd043608c0156427ae": {
        "max": 2,
        "address": "0xA82713C0A98dF01AfDC32Dbd043608c0156427ae",
        "signature": "0xa54e0d4388c27535a98b280b76031bbbe7d22a950fdc5b21d77c43831d561fe71d946b3efa6131a3c10805f196c3506aeefcd4d6a320915390ad5deabfeb97681b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD6a02bbcc789BEcA35b72bdf1092AF64ce019193": {
        "max": 1,
        "address": "0xD6a02bbcc789BEcA35b72bdf1092AF64ce019193",
        "signature": "0xaa6b24392ea255d671e39306e7ebb95082a0af6f4fe47046661c431273f6a55e38da5e2759e9423da5d9b177ce782418dbc6aac5ab6a628bdef1d6461c6d978b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF30043B2E54f15BCc35B2D19b45Ce453654b1833": {
        "max": 1,
        "address": "0xF30043B2E54f15BCc35B2D19b45Ce453654b1833",
        "signature": "0xed7d04cf85cfaa723dbd93e742c2e56957f81b478b88eb55e0498dd3a540595c63de5005d3513a38ba46392166c68d50eb1c6540794de7e3087cf941ab780c3d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAC8522a3540d6d90036D875C0063ae9034545CC5": {
        "max": 1,
        "address": "0xAC8522a3540d6d90036D875C0063ae9034545CC5",
        "signature": "0x535e46e0b5d4b976f2f967897e591be84eed9030a0e63eb5558dee1f1cce43df50abad59fc3c54216fcbb8e87c7bfd4cada55ed76f7819f6ae504312149962ba1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb65d7e25b99e512eb0EC8DDe6bF261bC75e4Cc64": {
        "max": 1,
        "address": "0xb65d7e25b99e512eb0EC8DDe6bF261bC75e4Cc64",
        "signature": "0xd0ca0aff4b17e10b20d1f0947e322c7b3408d9cea48f593acd03cec2e7b617a73a7488bc39c62d850d8b2e4d55bdf9bf90aa666f6d46b11baaa5ca2ff82af2921c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x828b292DaeFAA119C021AF635879D9629177391B": {
        "max": 1,
        "address": "0x828b292DaeFAA119C021AF635879D9629177391B",
        "signature": "0x7526d7b8d3499ed2644888cd073bb3ddea81d0eeb397e0fc6ced99bc6f20fcfc159e55e09c4a6d4fc5b4270dd99bb69e98f3456382991acdb7346b93e202d2051b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3452072f31BF2243F1293884a6C5d8DF2726A7E8": {
        "max": 1,
        "address": "0x3452072f31BF2243F1293884a6C5d8DF2726A7E8",
        "signature": "0xda4f5a69fbab1066f6f4dc698d9cedde96da49365de2b9a7bdf3f3dd7ab879ed6fc5ddb7277ea78ea05ba4a3f10f14ce8c0706bba168174d5449a91f6e65669c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x173D7b77a5e908d24d6Ff1CAD4B082Bb3825724B": {
        "max": 1,
        "address": "0x173D7b77a5e908d24d6Ff1CAD4B082Bb3825724B",
        "signature": "0xe63c849674a89eb2842cbcb23636a5abf201f160f0c98206292e695cd4e809f84088bb0444ae4dace08a7d7944a8742d68604164c11b0db8b9d386b977969f981c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9802b31d3E46fDD92439cADaAB022F0b2273893E": {
        "max": 1,
        "address": "0x9802b31d3E46fDD92439cADaAB022F0b2273893E",
        "signature": "0xc2ee7c972ba070578bfcb3740d056f162ce22dd04573917deba81b8fa45149f60b4c2698beb3df4fb28aeaee37821040dbce74bc2dca9783708190e4f0184d231b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x05B86060F6844320d46E390A44CE6EB215e09Ec9": {
        "max": 1,
        "address": "0x05B86060F6844320d46E390A44CE6EB215e09Ec9",
        "signature": "0xd6523be893d8a27186797cb2563f925bd05240fa68a48d520cdb78de1bd6f2225063d6af42936da5f28c316c9f4dba0787f086e3eec744420abc6f4b2718fe241c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2F781802630539E82e19e1Bd84161FA70B1146dC": {
        "max": 1,
        "address": "0x2F781802630539E82e19e1Bd84161FA70B1146dC",
        "signature": "0x6e9dd36d706ed7beee314eae0392a6d17b213714ca23ef6681268b39939618ef005dd152fad0f38cb8f8eb2aecb85e749272b2be2eb38000465214bbb7c1f6db1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x14e0Fb729d4804f634D8F0dF561A79846Ba8fb4a": {
        "max": 1,
        "address": "0x14e0Fb729d4804f634D8F0dF561A79846Ba8fb4a",
        "signature": "0xb9c95443f9915c1fb756e1a7cca39dc5ba12343547bf9d99fcc0664791a9c8cf742def97b3f1f97438f98ee565b0f75d2efd9bfc9e0a59d896eca3bd01b967881b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8637E47CDAD56Cd812A57ab54b93B94B44f6bC82": {
        "max": 1,
        "address": "0x8637E47CDAD56Cd812A57ab54b93B94B44f6bC82",
        "signature": "0xe6c8697ffbbcbd6b0d0acb3cf9eda9cadb4ea86c62572bdf50834c24cfdbe417564ca5ddaaadf21787dff2afe084e76b60d58cdfdfa3616251c999b525435ad11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd5a1Af4Ac217b0704EA668DB1DE98e6875e20d33": {
        "max": 2,
        "address": "0xd5a1Af4Ac217b0704EA668DB1DE98e6875e20d33",
        "signature": "0x7a8f08fc4927859dc59335d445c947abbf706026e2546e0991348153a62c2e3148014b77ea15b56310b0b79cf28ef2a62399120e98adebd7ffcebe65da746ccf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03e5a3223efde11730914eb04dD137dB67bB62d8": {
        "max": 1,
        "address": "0x03e5a3223efde11730914eb04dD137dB67bB62d8",
        "signature": "0x456f2c5f45ee44e935df2a3b62fa94164e468e6d16149bf3bf4dbd808308acbe372a2d66a40e22e4debf64ce34a5b850dbf3a5d83dddc3fe00e6534ead8f95081c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7b737A771378d034Be42437dBce23eb59d2c1a0a": {
        "max": 1,
        "address": "0x7b737A771378d034Be42437dBce23eb59d2c1a0a",
        "signature": "0x7a172458babf9362fa647739e353404b145fe173398c79128c9d7fc10f0fa0851972841ef73b56cfdde27f08a3b7e053b69272624910df82b575e9942196845f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaa203A88D05Ee0B50f134C84500d41BFF5eE758A": {
        "max": 1,
        "address": "0xaa203A88D05Ee0B50f134C84500d41BFF5eE758A",
        "signature": "0x2caf468b001fa1d409d4b38803408e7f60a89f4e5157de9b9c7d5abed884941d1aea32d91e42a51ffa0e059d9d7326621e5a7c6cc5ade901d888cfa61ba01dca1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb4F37891ed72613563bf565a8F84180544CE5D4f": {
        "max": 3,
        "address": "0xb4F37891ed72613563bf565a8F84180544CE5D4f",
        "signature": "0xf874c9c062def03598171ab054adce8d0b021967ccf48e120fa36cdc75fe6e2b782b0be2bb2379195579f8676d3296f953d893dad081b9ba541930c2ad3fba061b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x43783562F42002441Cec1d7dE85FBEf61772b344": {
        "max": 2,
        "address": "0x43783562F42002441Cec1d7dE85FBEf61772b344",
        "signature": "0x052dc31d74d65a0e77e65868b8f4e96737e4c49110ccd6af4d17d1682cb2685e65c936f852296f35895e9061f00a1d8eab5b5e944d7c0ba5e4ab56bf1597856b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8CD22BAf1E0e452d2caDF58A499B398ccEDc9843": {
        "max": 1,
        "address": "0x8CD22BAf1E0e452d2caDF58A499B398ccEDc9843",
        "signature": "0xa7195f70da1303ea53b5575f3b32043fa494d3a981d15b7e0c8e9c3d13d534e35ddb529423216335892e7c85c3252bb34fea4555d9419851ccb9d460e788a7091b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4F7913bd0d55c03d1fcc4F5c35dD2047cE00630e": {
        "max": 1,
        "address": "0x4F7913bd0d55c03d1fcc4F5c35dD2047cE00630e",
        "signature": "0x2032ea0c3aeb59b7273ff9759c4b45922485017af3d8d3a262c7b9eb4899032243e3347beda6f1ebf513df0ee568ed2383d289b0e147c50686e5e126d096b0ba1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbE7C73263E60F0c0595822283738452A5bD00948": {
        "max": 3,
        "address": "0xbE7C73263E60F0c0595822283738452A5bD00948",
        "signature": "0xcb5fc075f2883a7d6ba9bbd29ea6d8dd23b3cae59172889faf83c84d14d04bfe7a6cf18d0edc8ae31dc152ccf6d9628ca7e47f048279548db2f39358a8a004551b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD9d490F5ab572D80E063f8Af00828e605F9D8BBA": {
        "max": 2,
        "address": "0xD9d490F5ab572D80E063f8Af00828e605F9D8BBA",
        "signature": "0xf07badade6874e4c06da3c47496c5c361f36614aff8d71b8b54a2c28c7899a946c60d3f905d02cad195d823521a0b34057c205878be0c0e5d899ab862ff3db611b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAB070F88C04E7bBc3E04703779714eB4784a9dAb": {
        "max": 1,
        "address": "0xAB070F88C04E7bBc3E04703779714eB4784a9dAb",
        "signature": "0x1e68e17f7623277545a27c238a6481de50e55cc682c2be769d16f25f89cad0c850aced9e1b5b43fd2af1433c4493a73504505e29e03176ad2f7bf9859ade424f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfC13092BED04946930e0F6fe1fCC8343E7cdae41": {
        "max": 1,
        "address": "0xfC13092BED04946930e0F6fe1fCC8343E7cdae41",
        "signature": "0x0866ed2466f8bba9db1dc7de8890c4756007177e031a7c270c65ff399b1abcbf4c0cb865edbde3c5f7a80e8d01fab05ea2a5a550fb4a97cae240ab2e7c856b131c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9FD4DBb6623150e99E48824bD3c5dD700fB9039c": {
        "max": 1,
        "address": "0x9FD4DBb6623150e99E48824bD3c5dD700fB9039c",
        "signature": "0x132d45857d49ec6c9e733c7ca58149737440f103f9c8288556836e3d3d7dcbc619217af4855a9edb30716634ffae45803fd58ddc95e76051dd752a79fdbc69661b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDB6F401f187F0850Fc11Fd0ED0922094e35D10cA": {
        "max": 2,
        "address": "0xDB6F401f187F0850Fc11Fd0ED0922094e35D10cA",
        "signature": "0x643d21bb0c070981594ae0d8833cda44ffa940ef53a8754f5fc48b3c2b272ef270279afe99cd969ed257cacca0b76097fb2509251d930dabc2994a9fc6fa00a21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4F6414199F04013BE1408Eb6D3e1df2713A66093": {
        "max": 1,
        "address": "0x4F6414199F04013BE1408Eb6D3e1df2713A66093",
        "signature": "0x5d3d05446a9fabe41222b5b94fb82b198aa524909e49ed60a0d68a939565373569f159a4306a472b6ffff196918141af05c3ce12a082734b000176a008ca48701b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB77AbF8b2788C937E6a763914781190c57DA81b7": {
        "max": 2,
        "address": "0xB77AbF8b2788C937E6a763914781190c57DA81b7",
        "signature": "0xe8a325ef9d2d9556b29bf14c303c19dea936ff91f4898ca0c464760788f3a1ec4bec8502bc46f06e673254f26a632a0b70de6fc072792522797336b2fb6ad6c31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9d8e797b153F41209EA76c203A1bdEF04516894a": {
        "max": 1,
        "address": "0x9d8e797b153F41209EA76c203A1bdEF04516894a",
        "signature": "0xf8fecefe1d83fee6d43667b0d2cbb9881493367edd72079588976df42837745e16d45fb7a12c0aee74e475d007828679f4eb387ef93157312f895d26e8c8aa571b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf96732310F5aDE32b2A70C82a1967c6e88BebA26": {
        "max": 2,
        "address": "0xf96732310F5aDE32b2A70C82a1967c6e88BebA26",
        "signature": "0x001bebd39e0e27e40c39f2d12703199dfec2cac36cf8c4797c0c67984461a193715f7f336bebd672114771715fb6910aac82ce170b9d8e884162b7649f9134f21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1375c3A95723588f9FA39c17Cd3C9A8fF97a3898": {
        "max": 2,
        "address": "0x1375c3A95723588f9FA39c17Cd3C9A8fF97a3898",
        "signature": "0xc1fd0f93f4d89a77f3ab6cb8c8d9da7f5a8283f97e03e06e852806370b06b54757ca298af514443a2ef0d1a5ab6936e4908fee42fefa407ccb7f34e98802d2f91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x25D12cD8af29F7c565349C4a6c678238e04cB21e": {
        "max": 1,
        "address": "0x25D12cD8af29F7c565349C4a6c678238e04cB21e",
        "signature": "0x38b594ebbd64d43df8745c5cee34b352d8a9fb59c34ee1c09f4a1effdb4bf1020a214e08b6d98b8798039a5bbb8a769560cf660ea8d6197b0680ba892eef898f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA2155aaC6b3E9f7a5B601ee16cE5F98527303068": {
        "max": 1,
        "address": "0xA2155aaC6b3E9f7a5B601ee16cE5F98527303068",
        "signature": "0xd08ecf22ca11ae59970463f5bea5de2fb5461e0ebfe88eb45c49aaa836c76058387a7cb8c3b6c525013099b697851cefe61f4cd5664d53bee7649913e2e246a21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd0Bd63210f1dD8684CC7227aC4f0a3e4328BC368": {
        "max": 1,
        "address": "0xd0Bd63210f1dD8684CC7227aC4f0a3e4328BC368",
        "signature": "0x18e5b845f893b6ca471366a98cd9fdf97c6453ba329517d6791f7fa96eed69231021a2e551307e481899b80736b9c125e0a0eebca154986a76231fc6140869441c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc698Be76e45d15f7bbb9e5628cD42c6B4246E710": {
        "max": 1,
        "address": "0xc698Be76e45d15f7bbb9e5628cD42c6B4246E710",
        "signature": "0xb3e86c1641645d90cff67737b73f897890542b443afc19d6b6aede6a1efe3646611619ec00d14a9c6d9dfd2a0c6179db509120da8204c488caafbb58de350b531c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1C106b52077c015906Cd8c0ED531114B9F7D9b3D": {
        "max": 2,
        "address": "0x1C106b52077c015906Cd8c0ED531114B9F7D9b3D",
        "signature": "0xd48d23ef3454650c2df9eb7ba0b7ff19c6eaf7594e1f52df17b77d40b483285947d5f01fb22252e607204c9d93d09b38dd15285a3a06aa302e21e29c8ea5533d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd0d8C3c68aEf17AA904059E21879A4dc1675228A": {
        "max": 1,
        "address": "0xd0d8C3c68aEf17AA904059E21879A4dc1675228A",
        "signature": "0xa10f655cb641246903fd3a4bbbbf5269efb7ea5a0326af27c0706c158eaf261c526be4c4f135d0dc94417a9014ace4f92ea7f5f1e619072a4b6e7cc0611c94c61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9a0a0cf43581f51a88316f8c84dde5a2e0C92c75": {
        "max": 1,
        "address": "0x9a0a0cf43581f51a88316f8c84dde5a2e0C92c75",
        "signature": "0x6e7187fd67009a34eabd324fdb563973fb6722abf031032feafac530ab6591167dcc1aed608e703147706ef2d107518e2cfc426e0b38dbe80d0f049f6551e6511c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x865854EB52Be34f24a7501A423dF68764b1078E1": {
        "max": 2,
        "address": "0x865854EB52Be34f24a7501A423dF68764b1078E1",
        "signature": "0x1b446bbb127f960e0185951f4e50a5ac77d819fc7ac967a6329b1b090569f5bf6a27874454eb22c4e74d11ecf5413c5224d9cd1c05cc760756fe2522df3c2a191b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x13704AD4460874fA18dB0F054683BD23A14ef540": {
        "max": 2,
        "address": "0x13704AD4460874fA18dB0F054683BD23A14ef540",
        "signature": "0x3782e9a3d194cb01e29d3d48a2a24f70e601f162994db015663f28214ef00cc350cafb663ce580b291c3bac2e9b618c544a747ca6930d19b98d11711f0031da01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x68fe4F773F50b15D396Aae30Da83Fa2f1285E5dd": {
        "max": 1,
        "address": "0x68fe4F773F50b15D396Aae30Da83Fa2f1285E5dd",
        "signature": "0xa63932e8d3010a7efe6b60311e13ff7d8f9cc76c87ef9446371f112f01ecaa476b8dfcd257dda78084de778e937508c15d405c2f31e8ac4e5a7275887f710b0a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x519935dC8522e3D1B07a915A82Be162Aa6CBCA0e": {
        "max": 1,
        "address": "0x519935dC8522e3D1B07a915A82Be162Aa6CBCA0e",
        "signature": "0xa5cb26644717fc61970a66a0435c7022dfcc11d9c084c822696b75010bebb85965191694955266b0e40a2a64b39341d2f9741ddddfd1edc09e4da43a63e80e5b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3AE891d1f0bc354e19f5BcF2136998f6e82e8eBB": {
        "max": 1,
        "address": "0x3AE891d1f0bc354e19f5BcF2136998f6e82e8eBB",
        "signature": "0x431d5cf8def39a42c2bd1d6ee908a8967ddfc772d3e470d2f2efece9616c0f9a63148459e48dc72e00534b741db6d920b5c3439b8797a1ff6eb561d2b7bef0151c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1a4Ee9C4089B827DA341261b72FFad7cb2f06767": {
        "max": 3,
        "address": "0x1a4Ee9C4089B827DA341261b72FFad7cb2f06767",
        "signature": "0x8f1f938099a432c991bb144d8e8adfa1daaa8f220e5770e49911cfb4745a01434dc6e231c6fff8d614681eaa090a02a668b58e9ebad00c348efc39300e2474791c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x81f5F23799cFa4108822CDe90B03586f309FCCF2": {
        "max": 1,
        "address": "0x81f5F23799cFa4108822CDe90B03586f309FCCF2",
        "signature": "0xcab605ab684c59c3929442ebdcb1afbae3e60e6a4eea121fd4964771b7e152654bbb235f31e1d526214ed4430d141c78496f90d8937c73ca27025c0a123e8a201c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF457d03c565c421BC7D79D63c54A1D52B6356497": {
        "max": 1,
        "address": "0xF457d03c565c421BC7D79D63c54A1D52B6356497",
        "signature": "0x41a7f56ea40d09369efa5522e2147ee0950f6d402f938b0df8b3e9abdbf2235c411601f174474007eca9cf62cd358b9cc519fded841104dbb9d19a4bf8200df41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6B49646E380c3bEa9A0842252bfF9De0A461A1D2": {
        "max": 1,
        "address": "0x6B49646E380c3bEa9A0842252bfF9De0A461A1D2",
        "signature": "0xbb6181405d5ce252d447eeca715a756bbd681fbe4b5c165b6fd95e23cd3334515b0aa77cff1c7690cafc0bf08a9c934f80173c06372b5d46edc47af015ca14bc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x930077506eBF1A0898b75c3da57864434310b678": {
        "max": 1,
        "address": "0x930077506eBF1A0898b75c3da57864434310b678",
        "signature": "0x8c0cfb60ef381732353462425a52f736b2528c9de9ceb65b30fc69c106b8c681556300c964a79009824c308de92c1f2db6ec107f5694a5e8d0655ed9dd5a16c11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbE7E576848b434925D744FCf98d8229Affa941Dd": {
        "max": 1,
        "address": "0xbE7E576848b434925D744FCf98d8229Affa941Dd",
        "signature": "0x83422bc46a281ad028d7da86703f486a09a0b7386a780984239fa16511b97c6351295ed535cdf19119ff34d24c48c316388f5a34705f542107271859ee9af6e91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5AC72F7F92EA6372D9E8a6131ac741163c97611F": {
        "max": 1,
        "address": "0x5AC72F7F92EA6372D9E8a6131ac741163c97611F",
        "signature": "0x493bf74958afc94ff6274f14751c975f26071daeaa79910af2e141c4b9afd8de4be7adc04b469b7dedb27876ecebc189bb3a24819769b70ab3fa3a7716631eac1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7C61E461dAB071D929247C94fDC3f2397a57b212": {
        "max": 1,
        "address": "0x7C61E461dAB071D929247C94fDC3f2397a57b212",
        "signature": "0xcd41e8a4c356a33896320541fd2ebf95148087745b3063c3fcf623bf21bfdc9200a2c553b86ec9627f5b16c78d8b71731e8ba3282ea7e242e402e079ddf781301b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x824Cd75db050A171bbfDB8067544BB009a866B87": {
        "max": 1,
        "address": "0x824Cd75db050A171bbfDB8067544BB009a866B87",
        "signature": "0xcdfb402db5f3a677ed6c4b3a6b18438f021aa7d9fbfce368722fba22b756240e3cc47f32fa64db0e00bd795bb9a6c560ce859f1ca620fec0fe3dcff07ca2b6861c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF56D340af0eaCc1C8E654c2a385d59a8b1e253eE": {
        "max": 2,
        "address": "0xF56D340af0eaCc1C8E654c2a385d59a8b1e253eE",
        "signature": "0x780f158cefd878efdfe6290373aadfa94091b22aabe38aacb024023f73e41e081137178bce07d348ce392995340308049d0b211166216c5b827db6511868a65a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x31276cdce0AA962E687cb8EbDa819df0C02A289e": {
        "max": 1,
        "address": "0x31276cdce0AA962E687cb8EbDa819df0C02A289e",
        "signature": "0x86454f90de8d8d625096be816afdff446ab090a96e75bca651b1f5166a14b65a2a230c5d7ddb2851065855914690030e71d34ec0ef6ac8610c6c991c769ebe221c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6561c7A1eef5FCb05Fe7C1022b8c11D6029Eeb98": {
        "max": 1,
        "address": "0x6561c7A1eef5FCb05Fe7C1022b8c11D6029Eeb98",
        "signature": "0xb181b905da1c3fb49deb1f7d8627c1236d63dc9db264c33e4c2d75816b7eb2da20d72cc14c018e279abdc1363d3c86b68cc0fd3dd9efa92b208255f07f1613b01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x989CD7DcdE01c2db5aa0B7Fd2C50981bbabcc0Fa": {
        "max": 2,
        "address": "0x989CD7DcdE01c2db5aa0B7Fd2C50981bbabcc0Fa",
        "signature": "0xaacd5ecb30fcfd8ff8c3b2ead380c193940a4e7a5f93163f3fc1fe53ed6c37ff1fa8716eb3bc1c96bdb116fb33bb7b4a2ea032307862e548499c48e3b99461a31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE4a3B16804934a0AF6d26e8a266c5a16Ec22BaBc": {
        "max": 1,
        "address": "0xE4a3B16804934a0AF6d26e8a266c5a16Ec22BaBc",
        "signature": "0x3d48f6324fe1e8526b1e143a26e3be162890cc032cfe99e3867aa62de512395a63b6ae9f56e89b3a8193f8e90e5c97ea6b1fe3c003ffd9b1c723b0c71d9709d21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBB1b24c4498B8a0341EA7489c2f8CCf284C59e4C": {
        "max": 1,
        "address": "0xBB1b24c4498B8a0341EA7489c2f8CCf284C59e4C",
        "signature": "0x7b7e3a496518134dff54a330494141f8bd3c8c2a15ac722f40df4d6b69bf46dd03bfa8ea03b780d9ef068a7a02cf37a08cce3039066043c486f2a853965c62801b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc2fce8d389F61aC02F8BEC67bD5245B86e2aF15A": {
        "max": 2,
        "address": "0xc2fce8d389F61aC02F8BEC67bD5245B86e2aF15A",
        "signature": "0x057e995addf63f5b1529b1bdffb45779a06c9366a84e3117898e1da32873261f5236b6a237b53b4233816af73ae428e41d39861074aa507a93b2265e826e147f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4f7a7159fB431eccf87Da106Ba77F87177b5F668": {
        "max": 1,
        "address": "0x4f7a7159fB431eccf87Da106Ba77F87177b5F668",
        "signature": "0xf6d2574c91b29fea738d9565a8ec75a12819905f1e1397d01ec0517e6d2293665e1652b53291875f07a1e2a5faa7944e405870d8202a35e026ef9e25c230d0c31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0B1Cb1e17A81587B5b35659118dB393dED080374": {
        "max": 1,
        "address": "0x0B1Cb1e17A81587B5b35659118dB393dED080374",
        "signature": "0x456afa14eb4b55266140cf5b95c4c1eff6ad765c1a492e869c2dacea20272cb7453891a9515adc712e02cd4446749e124ab55bc55f25f15b5742b3ac0b9f64661c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3Ab32aA93e9cC07da3841510613bbEfBF8609b28": {
        "max": 1,
        "address": "0x3Ab32aA93e9cC07da3841510613bbEfBF8609b28",
        "signature": "0x0546e32e8ecf290849ccd5e20f3c812b44309d774a9feb0774309d879123a0ac526eb8b7d4e436a4b0a6ea93b58b161dac140e9b0e6cb58a2a8e19b92d6489791c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x63bCfd6Fe909957C22e7c045da91703A08aEe7D7": {
        "max": 1,
        "address": "0x63bCfd6Fe909957C22e7c045da91703A08aEe7D7",
        "signature": "0xdf6ab8d57cbbcf663e1398c70ea08c67062bb37efa1bc18b3dcd0c0b70ad2d231539f52b2fd84aebdd0a8521f33571845c26112e3080e1544c56c05f142f8a7f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE76712fe1dC12cA2CA9104EE02806fd2920318d0": {
        "max": 1,
        "address": "0xE76712fe1dC12cA2CA9104EE02806fd2920318d0",
        "signature": "0xd267f25e3d5b8bf8974fe1cbbd64392985fcd32dcf352b97dbc19d3008a37c995e6b61d7ad92fb85635618e3fa49e20fc0ad57fd64bd7586aa03966538b80d3b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0C2d8deF28cD4053Aa2C191B02243f06D23dBA12": {
        "max": 1,
        "address": "0x0C2d8deF28cD4053Aa2C191B02243f06D23dBA12",
        "signature": "0xed53564bfcdfd32b4fcd873f9aec7d17a76fbdcd653e9a6ade6d16749551c0111463f88fad08055c424819cd30efc65f1b1f485051f888751379ae6cdd4397cd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x06Db70CC9bBa81436C6dFD5249A3f3d8bE362F29": {
        "max": 1,
        "address": "0x06Db70CC9bBa81436C6dFD5249A3f3d8bE362F29",
        "signature": "0x53f83f68f292bc7870b718cbf1c8436239658d96b7d7e623b48b58dfaaa6d30278ef18e38cf892dd4e6565bb4f7f3fb0ee34486c0be83e661a6c8c2453e0be9c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDA05d4331a73e35ABC7da87FdC9eFE39efF4D67D": {
        "max": 1,
        "address": "0xDA05d4331a73e35ABC7da87FdC9eFE39efF4D67D",
        "signature": "0x4d39de3706ac590a6b46239482b1f99a00be59f936778fade5cc332978229d0439b48e81fc217bcc10bce54f730d4f74c9920bcae1930b27a309ab2a260cb24a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdb44849f3ab8637BFa46CABAfD88cf85c152cF6F": {
        "max": 1,
        "address": "0xdb44849f3ab8637BFa46CABAfD88cf85c152cF6F",
        "signature": "0xe88b6ef3b3120c33f178fb84693f575444c3e937aeab6211b27a791b1679c873460548bfa4921e56e8e7383157c332c7e102ff1fde5d86f1f148484cba2214151b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x47628c22dAC7aff6Aa96C31a135370B55996b857": {
        "max": 2,
        "address": "0x47628c22dAC7aff6Aa96C31a135370B55996b857",
        "signature": "0xeb02c5d91da9f1c0d309a46dde1da976392195432dc2f132e55882ff30af929575b61fa5c8a428502bcd58a9a16067f90c7b01a452089b8eca1a2a4f0a6eb4611b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF552C4EcB6a421d70950b9B8318031323248Fa7F": {
        "max": 3,
        "address": "0xF552C4EcB6a421d70950b9B8318031323248Fa7F",
        "signature": "0x87b6311b2360297c60bcf39349c0b0a25dac3fef383417986be31c2016023d7229cf481c108dd878c88aa666c37aa628cdfd3a8d249a7e1b59b30d11ea957c9c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7b19D51968Ba483cF8367C959351cC12D4462DeB": {
        "max": 2,
        "address": "0x7b19D51968Ba483cF8367C959351cC12D4462DeB",
        "signature": "0x82ff9f42fe611f102a77f318ead6728900cebdf945a10c12dc777f4a9fd9c5e12cb3f10b9e1a9c79d2f1f7f5f2bcb8a8bf5b7734bc5d95832488b8dffe0c4ad71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6fa46ccB60Ae9E96609676abf1dA59881B53F640": {
        "max": 2,
        "address": "0x6fa46ccB60Ae9E96609676abf1dA59881B53F640",
        "signature": "0x5daf9e9f22cb2ad946530c3d9f7f1792e73b2460463ee5c028f3e236c5cdb8fb1dd7739cf4c3bb49034b195f8bf56483de53c49718ee3b72ac7c185b1ddc553a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5AE13C90Ac77C44A2d5B2f3C651D4B0f5BC13A68": {
        "max": 1,
        "address": "0x5AE13C90Ac77C44A2d5B2f3C651D4B0f5BC13A68",
        "signature": "0x4278c44d149ae8dad86cd890c4481e1006f9819926a0cb0be459a41e94e169b62fa028d424bf043f464ffc61ea60fa4077046798cfc1f6d380e4370e7708c3d11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2adfc86a4E073169aC5f8C850a9e80C90383F3f8": {
        "max": 1,
        "address": "0x2adfc86a4E073169aC5f8C850a9e80C90383F3f8",
        "signature": "0xb47128d29512c8ec5cce5d0e7b2adc31b01c542c3668e7d1aba7b2abd20e297338b1a31d0c111f615907a8e060d249a5f0902aeaed96e2d3e7c2d3d6d9f072cc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x61fbB6888AA94706A4152D06FD3aE48D202Ad80d": {
        "max": 1,
        "address": "0x61fbB6888AA94706A4152D06FD3aE48D202Ad80d",
        "signature": "0xcfbe2d3f431f62aa069edefaedacd6cfdb5851a647689d81d39849d855ff5d3e5b7101785fdeb5dd1ee7f7c9277987d8ba2b00852ef9c4ea8367750c440e93cf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x05aDF3093237D32d5cfB0a2F7C013197AE070545": {
        "max": 1,
        "address": "0x05aDF3093237D32d5cfB0a2F7C013197AE070545",
        "signature": "0xfdae17819dbcc9dbed3eb237a9c315e6329a522b027880f1cde8e1712955fcb95aae7b15d706975563882055c808a131bdf0da020e46aa5aa474aa17441fafe11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x59D7F88e8db06cf313b85cB89999E9e46C773dD9": {
        "max": 1,
        "address": "0x59D7F88e8db06cf313b85cB89999E9e46C773dD9",
        "signature": "0xb959a471e1c14bfb42c03fe1aaac2b8f01814cffbf991757ad396b8a91d59eae59ea6976f185fea688ed942b4f4fae14cceb7658643f9766d99c9c0221f2665f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3BE03f5152A6894314c655018d3251bAC00F4a62": {
        "max": 1,
        "address": "0x3BE03f5152A6894314c655018d3251bAC00F4a62",
        "signature": "0x5afe866b511946c685b2aa6320a8ac3a78154b44ca9de636dc62f0c557b0fda56c1cb14222f9356833c11173f124072f5e5893dfe284b082446b4a9c6699e88b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0cBc3f6923aa71a208CF6f8F2B3C6dE0Dd862bF1": {
        "max": 1,
        "address": "0x0cBc3f6923aa71a208CF6f8F2B3C6dE0Dd862bF1",
        "signature": "0x0d3a86dc2872a18848f2575e049fda24ea0bdb7371d12cede49c04773e80a7c80028a1653b785fe724b1eda2df6a8cf47481431d42c60d382c3746673dff077d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC82478Be149B294B1F838e982a0E92aa31F35F30": {
        "max": 2,
        "address": "0xC82478Be149B294B1F838e982a0E92aa31F35F30",
        "signature": "0xeb4a63af0e83ecc33e9f3b163ee73d6cca795688c6cfe38d62a6c7624bb71c2430bc765cc558376e52b22e09bf1f8c77fc010bc75c86bf2011b532e0b2defd6e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03293cb4793d9007cae6d5620926944D692D4D26": {
        "max": 1,
        "address": "0x03293cb4793d9007cae6d5620926944D692D4D26",
        "signature": "0x1cea8f66657bb42413343e10d4df571785429ac738263858b9ea65dd6d38205778fda1a6b506b0cce3b7013b64df2bdc7841fcd50bfd66774662432884c9e79b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeeBd7267D4a00e254A40c0873Ef7998d4cd4A0e1": {
        "max": 2,
        "address": "0xeeBd7267D4a00e254A40c0873Ef7998d4cd4A0e1",
        "signature": "0xb6806625b996c8c4783dae4317f27e4952e58b62dcbda2d7f532317845dc481a66b2ea89288431c24175ba8e33234648440114b99c51277a77256f8fba4867a51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEa7e56bf26843d5d0F7D42aE9FcCE36426afA88B": {
        "max": 3,
        "address": "0xEa7e56bf26843d5d0F7D42aE9FcCE36426afA88B",
        "signature": "0x79c2b3d72901f3936f0a346ebcf4ba32e9ff46ae97186bf1311165d401625b3d15ccfa403523ee826bb340fe363379d61af268fd24d350c37cb3f42c2a748d6d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3df701a36E8ce01b5f81CB4c3e2e99331079a4e6": {
        "max": 1,
        "address": "0x3df701a36E8ce01b5f81CB4c3e2e99331079a4e6",
        "signature": "0x0c7b9641d4ee7ea8d33a499ee3b7d647bfe99fcf3c660785dc312ccc4ac720c33f4a24576738787d3abb6535cab14d7d84db8b0124fde892d65081e0b741776a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x39b9789C4D3c415545eF2610F3955A7C261ca16f": {
        "max": 1,
        "address": "0x39b9789C4D3c415545eF2610F3955A7C261ca16f",
        "signature": "0x40824a968d15dd61ca9683a96be01cc5f09c1a580809168b1f00797d2d28d01744881bc28742ee38d933d6712b610196f9554e114d2f8aae3358525bea2ce17b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1bC4717DE2FAD69b7Ce97284d3fE8616008e2F39": {
        "max": 1,
        "address": "0x1bC4717DE2FAD69b7Ce97284d3fE8616008e2F39",
        "signature": "0xb0d7ce4782610cf19feb043379bed2c670e5a3f7cc4eaad4b4015f0df59387937c1b772e3f629222c8791dd499baa3d4a08279fc17dccd93a19ca3aa623323901b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x364e9866CC5C11A778f9708c0fa7D9Bd47421307": {
        "max": 1,
        "address": "0x364e9866CC5C11A778f9708c0fa7D9Bd47421307",
        "signature": "0x5547fc94a58195788106b5e8317548d0943a13f75f8b8cae047021eb4199eb420d9a05ef75209031aaebd67be1b8fa6efbad5c9b9722bf2cb21ec31b1955ce841b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA4d526EeAaa27D4d2108A7a9d4493626779b05e4": {
        "max": 1,
        "address": "0xA4d526EeAaa27D4d2108A7a9d4493626779b05e4",
        "signature": "0xc4d5799ee195d60e716cef7b102aa99596c8f33bf98662a99266c0ee0a7a7c84580fd87a3953e00f4d01dee2d4eed6b51ad8a0c6d620b5c14a39024ff6a607be1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1657216af06cdE06f9f797FF607Fbccb1b88D7F7": {
        "max": 1,
        "address": "0x1657216af06cdE06f9f797FF607Fbccb1b88D7F7",
        "signature": "0xebe004c119c0f8bc59ec1994e09935773def6e887f7f71da9a5f8af934c648a6680288e2ef718579816a3be19e42e4ea585ae652567bebbeeeecaa61a91c8fc51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAD108Ae0F8f19c21B7e94a7f212e47591C1Dec8C": {
        "max": 1,
        "address": "0xAD108Ae0F8f19c21B7e94a7f212e47591C1Dec8C",
        "signature": "0x0f96934d9c575963aded98238ea08ab4562d8a8da1fc805621daf2c8b19120dd3f7d931e6472277a04f56f428df07c3d2570d442b917bb6f98392cd86d1b596d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE296803aCADD6a12c66dD8608A760020219b763B": {
        "max": 1,
        "address": "0xE296803aCADD6a12c66dD8608A760020219b763B",
        "signature": "0xd33b9e308cd258d32b22f581e4ea459275c3e3041e92c0bc2d558d00e49c763413d438d27ef0b3fecec7c63a576ed6a4186df57580f5d724868fea26b09f60061b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEC2958841DddFEdD9e220003bb900A07F88920bF": {
        "max": 1,
        "address": "0xEC2958841DddFEdD9e220003bb900A07F88920bF",
        "signature": "0x92839b7c50919fda26fada0875044fe4dfb366a3ed93a1a28b00163956c671ca7efdd34e0e38ae35ed05aba135ff34b65dbcfd67ca1360b00d5fccb20d9622e91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6D584753858216D863eCA3E466c2032Af59A640d": {
        "max": 1,
        "address": "0x6D584753858216D863eCA3E466c2032Af59A640d",
        "signature": "0xcff1b630e4ce690c5d1b0e231543d49d8603eba1716f0532b630b9c6e2e3ade6100f7ec857db56917bb465378b7fc8e75db09b4f6edd7b4bae5511e7fc002d941b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x19c2396C0F6b45b05C94245525933cBe8ddcB27c": {
        "max": 1,
        "address": "0x19c2396C0F6b45b05C94245525933cBe8ddcB27c",
        "signature": "0xf17e401a03cf3a841b2380b7750e26947a67b2c9d6fd047f4d105396501ab7e22d4a9c64bd077ab72e1503e1f0007f5ee9c588e686d2a8a8e7ea72df3cccc4af1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf052938cF73df310cEd83D4E91BBD350Fa347079": {
        "max": 1,
        "address": "0xf052938cF73df310cEd83D4E91BBD350Fa347079",
        "signature": "0xf82532043b2841fec768ae346f799df0f12bdd46ec44c02d93299bb74342af733f94ad96412539eff246b9228de45a73e006b29e2762fa58e58279bdaa41aef01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbf5d0D681739eC26C40af9446A650C9fcC911863": {
        "max": 1,
        "address": "0xbf5d0D681739eC26C40af9446A650C9fcC911863",
        "signature": "0xf449eb0b39f6e534aebdb5d9883cbfa01c35e35e1c5b97a620f1a96770cc8a63199042dd290652705297bebec08303dcd6d92895bf9fcb5041b796fd3ff304351b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x40BB8ddE11FD49546B6113257988Bf20560f2eDC": {
        "max": 1,
        "address": "0x40BB8ddE11FD49546B6113257988Bf20560f2eDC",
        "signature": "0xbbdb61573d99d1757d88ecace0308e32cd55744794dfd40a59530a9ea6c98bf42b3b2098263b3f01df2f8f948e456d6c8f78490ad40da2339d04d9aaa41e37711b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x327aE13Ac39d6Cd76E57B015Adf4Be00e86664Bc": {
        "max": 1,
        "address": "0x327aE13Ac39d6Cd76E57B015Adf4Be00e86664Bc",
        "signature": "0x7b6c0ec9e74168471f7531b6233cc0d089cd6033b0029f7392f3776a347d240a27e4e0818e17ab41c0ac47eb7d05836def50d7854703c2b64f4479a943f4ba701c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9b5086DC729302A5014B73BB56F0A9F1e2176Dd8": {
        "max": 2,
        "address": "0x9b5086DC729302A5014B73BB56F0A9F1e2176Dd8",
        "signature": "0x762b5186925ddb3b21343b1fa75284996e9734042ec866b56eb8246096bc3d586e9368fa29a3bb459e44403eb8207536a1c7a4923a7aa957fddbd52d56e47ae51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x19F250e4eFB1660D6df6A11E94B46AA9530313A1": {
        "max": 1,
        "address": "0x19F250e4eFB1660D6df6A11E94B46AA9530313A1",
        "signature": "0x140e8a45c0dd63cda33e5088113acb8c8f8556c51f2fe276e75df83ed56cda3c15cb9dcc072c18f636bb633dc4650bcbf7b28c34b781f4bb94c1593dfc65afa41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2501d66d059376CD9EBCB318c1399ecD2C95CBa2": {
        "max": 1,
        "address": "0x2501d66d059376CD9EBCB318c1399ecD2C95CBa2",
        "signature": "0x4c625b8a227573836c5e9cb2ef84c097553bfa9b6e01370f082baa98b4cc15a4109c2fb57f9a9cdf4bffa7556ea4638b0aa3a93d40318ae953da7e7825dcbaef1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x372cEd7af27e31828Db5AD1D1B09417c14430FB2": {
        "max": 1,
        "address": "0x372cEd7af27e31828Db5AD1D1B09417c14430FB2",
        "signature": "0x7780f7f90bddad2fa075c9bf1b4accfb04f517d242a314096138363ce6e8d4b325006d014698c5a7f8cc8c6309a811bbd8d1d607fd5f8b4bda4c5e9e6e8460cd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x93c7D082DD69fED6cA853273d8AaDdc3e3A21e55": {
        "max": 1,
        "address": "0x93c7D082DD69fED6cA853273d8AaDdc3e3A21e55",
        "signature": "0x2caf8c28fee3c90701b7096f00ecb1996e93768c877f904a6d3b5723e1558e094fd04b611ac7380d48cbee8ad8f15580070b30ded5973d41b9c7598d61f5f7601b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7f25ca81b244A2812eC7B4CC65B605A30aF96Fe5": {
        "max": 2,
        "address": "0x7f25ca81b244A2812eC7B4CC65B605A30aF96Fe5",
        "signature": "0xd6bcf0048bec9e1a0723b5bcef2f10b1392c5041c307971db693e2b7358895c678ea6c90476dd653ecb47aab4c96657daebfc87737362f0de936e0a9f8607ad51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA5153E0D703bE88afc60d595D881024f15879e7e": {
        "max": 2,
        "address": "0xA5153E0D703bE88afc60d595D881024f15879e7e",
        "signature": "0x52491c49de7c93da3815d952f04df2c68d4e5bcee6d1cdcacc9f841a16d62e8e6c39c6e266a2d8ecf41a6a6cdf3cecdb7eeac20c23566648da6aace6b7f611511b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x337DEBCBe5f5Be8a57488E6E3F6F5A1511223120": {
        "max": 2,
        "address": "0x337DEBCBe5f5Be8a57488E6E3F6F5A1511223120",
        "signature": "0xbb579238f8a6f0d431354ec195b61d61bc362f59da84df455486c431e907dcd675a5306cf4d44ea545e7c09adc6941369e70b930d2aea897aae2201e62f1a3821b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9e54d103B00AECb003459BeA82475DBFC370Fddf": {
        "max": 2,
        "address": "0x9e54d103B00AECb003459BeA82475DBFC370Fddf",
        "signature": "0x48f15cd50c7c5cf7318a9c1ddfa1ae923c5ce87dbf28c82846bb25faf61502f13cb741773d0a1b331b6a3705148b6d808790e6ef1364fd006cdf7f9d0134f5f21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd7344Ffb9722a8E2693F9FA6D2df5eb27c16A3ca": {
        "max": 1,
        "address": "0xd7344Ffb9722a8E2693F9FA6D2df5eb27c16A3ca",
        "signature": "0x4179700f80d98557711b85638211f804ef15648a5767fb21cca5f0a37ec16a8e0f770730cf178565a7e7c63031f2007d1b64a2307425d721ad7012106accef3a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x323613E50A136077655A949150bB75E46E056E04": {
        "max": 3,
        "address": "0x323613E50A136077655A949150bB75E46E056E04",
        "signature": "0xa10252d651c466233b382fdfe66214f472ae044fc273f51795fc54c1d62967be2c5c7391ba8a07a032d8d055b88ad3339b520ab03c1a35b2c3a8e3d6deafd1701b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0c0531a1341D86F75f88674e56D0E3fbba49e495": {
        "max": 1,
        "address": "0x0c0531a1341D86F75f88674e56D0E3fbba49e495",
        "signature": "0xf23e01a963c49968af190cb44c774c235cf00c0871f6c11da363a028e11be83e2e291fee3337707c5ea30edad1a2b188458935d7b5804f2f1a36bee88bc92ef81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8653034aef0F926A3f6aFF56749e2e13745dDb15": {
        "max": 1,
        "address": "0x8653034aef0F926A3f6aFF56749e2e13745dDb15",
        "signature": "0x6f6bc31d8fa06d8ee6fe0c060df7af3dd68b2dd51bf2f3883499b6a9bfa44632191bf4c03a1e0c688143e6ced06ce2d9f21b92819f8cf88dacc44941408147e31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x32A565A4F514FC02B65f0354D8f25c232E9BEDB7": {
        "max": 1,
        "address": "0x32A565A4F514FC02B65f0354D8f25c232E9BEDB7",
        "signature": "0x1dbf98dd7ea58e3a230663f2da4fa9f60cb12f76f7d6945e5539644a29f7725d53b1fe274290ccc56e04a1f80cc179722d56608ed910a23d79bb31377a0c18321b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7250F31a55aa3c9C5aD2fe8C7385CD8CfD8e5dd5": {
        "max": 2,
        "address": "0x7250F31a55aa3c9C5aD2fe8C7385CD8CfD8e5dd5",
        "signature": "0x181edb919cfcd16eb26efce3718a0f73709fec70bea526b6dba34939151480490254a28b08ff8c447e88b568ce983de8868fc2b4f5c47e7d3e2d20b4e5162d861b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7d02E8Bf927088A0Aabf4Dc833eeF6E90CA76256": {
        "max": 1,
        "address": "0x7d02E8Bf927088A0Aabf4Dc833eeF6E90CA76256",
        "signature": "0xe9c8fffb24bb4f770b6543f359b8dbeae87d8332762d22486587ae7f14c14e2704b292aa4d0c69e4c115a03f863bdf65196644f39e45e635d898c8a6002175ed1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF692ec3d930D26A0287bA28f75DC718330558168": {
        "max": 2,
        "address": "0xF692ec3d930D26A0287bA28f75DC718330558168",
        "signature": "0x640c9ea90607a132f9b06c320f9ffdeb64dd6b2a8bf744e6befb91501a291c602b758ea5460befa167daf6b201d7f3e894b5326deaf570535127d36cbc0f7ac61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x065184A25a067432c1900063dF48975eB7Bcb68c": {
        "max": 3,
        "address": "0x065184A25a067432c1900063dF48975eB7Bcb68c",
        "signature": "0x52d8a24132ed7b3503c1533ef4b941f7c11740f5cf5dfae5a5dde588f99f35771156eab1c5acdb33883f800b805e04cabced48864dc07f268e17aef8f36707f81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe8E7ff5eC4a73229E427Ad08DA49C0B95e40b778": {
        "max": 1,
        "address": "0xe8E7ff5eC4a73229E427Ad08DA49C0B95e40b778",
        "signature": "0xfdcb5ebf35773a42d34e280edeef0c245db2feb3e09ec06a274c42fe78a0fbd321a485d3e04fb62a4858c148f4e984732fcc9feb281642219125034c460251d71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xda06a08ea93d84749A20728af92ebD6bB434e36D": {
        "max": 2,
        "address": "0xda06a08ea93d84749A20728af92ebD6bB434e36D",
        "signature": "0x289fb82f1a2e7447f05218f0ad45dec5f3a7daac2338c63c1fa3cba6a7b5d67d63a04fe590fe36192aa68e4209176f50b065739023b0315cdc6b62c7e1735a8c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeD65878F505A6f2789F4f166169E37226Adf6c5f": {
        "max": 1,
        "address": "0xeD65878F505A6f2789F4f166169E37226Adf6c5f",
        "signature": "0x6fd008921921b5114ea15c5ac52986fc224b7b9087b2f59b9d4e4f5054b2e8271daf7f963bd3ffc19599d10dcf95e0d10dd1380c486c5c34d167ab87f8a615f91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8EBB8DF4e6B769A778D9bAA617F0Fd3146de9345": {
        "max": 1,
        "address": "0x8EBB8DF4e6B769A778D9bAA617F0Fd3146de9345",
        "signature": "0x910bd6a579f9c315f9fa7bc7fac8b202157c99f5d09d73ba5f00a5b48c67c2d00db17164497abc0f78ecae7b22499d987b01504c95a486d7427e0b5c596817451b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xac5e74BB6F21b7e73107AE05E342ab3d07b25EF9": {
        "max": 2,
        "address": "0xac5e74BB6F21b7e73107AE05E342ab3d07b25EF9",
        "signature": "0x43982c667cc70878dee8f89584452560cc0feddd482274f4f2d69f07240c009d12c6f122509076a3b935b06426a801ee225a723cc89e61964385ecd4d6e4a70b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x47Cc9b2fFDC5D22725367873dCfDeF20EF6daEC0": {
        "max": 3,
        "address": "0x47Cc9b2fFDC5D22725367873dCfDeF20EF6daEC0",
        "signature": "0xdde0b92f702eaea079252b4489ca0f3525fd0107f05b009cb71620591e5f30e632d2cfd44572e858deeacef34224a915c09148766d1411af00d7219dc0ee20151b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9262c18d8083FFcC405cc153C16a746b2ca7400A": {
        "max": 2,
        "address": "0x9262c18d8083FFcC405cc153C16a746b2ca7400A",
        "signature": "0x3b482fcfd9529a11645c519f2f983944560f9c188c617afd56cdd04bb048e443026dfd46f162134dc2d9d77262c97dc08790923e869e1c3b7210af2525de95421c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x23f562a7d671Fb41EF3fBEc4a0e982bFcFE8F285": {
        "max": 1,
        "address": "0x23f562a7d671Fb41EF3fBEc4a0e982bFcFE8F285",
        "signature": "0x4273249422388591c59f1930f35106291aa68314fbb527de08ae01aaad07341f49740c0725c14b3cc28a592b488c76a70a9b77a9bd9d808a9ebdf06a7a515e991c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x85Db9923206bAA24022e0c2ddD7f3C914D1D0E57": {
        "max": 1,
        "address": "0x85Db9923206bAA24022e0c2ddD7f3C914D1D0E57",
        "signature": "0x5f6c29273a807c736f70e5719f8b9aa9fd4c655f91baee07c329d9d2b58423392b8df9150a5b7d3a62873327a73a5de45ec02551bc3397c8ad50ce947fc59eb21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x13408ABd6848A4315e0BdFbE9c49ef5f4E87c80e": {
        "max": 1,
        "address": "0x13408ABd6848A4315e0BdFbE9c49ef5f4E87c80e",
        "signature": "0x121680c37582b4370166e0c90674cca8e4fb5c8ba3f003d87678016ef5e90f2431d15d6fba3145ad9d9cb84719703337fbfc179e9a3e7073b279e94059d266771b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x77E291Fbe9418ef6500C0473ee01869D09698A67": {
        "max": 1,
        "address": "0x77E291Fbe9418ef6500C0473ee01869D09698A67",
        "signature": "0x3b8206894e38d89a5556e702b2d1f8bdba04a9ccd7193038bdbbafc6f18aba852d2884ae99b2038f9c6c58bd11fa3a662ed36bd4ba6489e047c8a8b9d3abb4021b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8d6A9DDEb4025AcE2d1EDdC198f76251E34e6698": {
        "max": 1,
        "address": "0x8d6A9DDEb4025AcE2d1EDdC198f76251E34e6698",
        "signature": "0x7bf71f43137ee5aabe4ad8f4a399864366d20fd32bdda2a2fafb3e1c3b245ebe453f36831101af2ac6808a944252ac26a8c487c9a5cd0a6d8e82d2d22780ae2b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC6839EF32B0eea310176c79c20cC4B8822Ef2830": {
        "max": 2,
        "address": "0xC6839EF32B0eea310176c79c20cC4B8822Ef2830",
        "signature": "0x1d7299f48e7319ea534094db485546e9fae8f1b84a198e5ee3b722008093f9a2023cd70472435bc92db36d83a3b8c7d32513535195aceaede060c2f7fa3969ac1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x249BB829fc673624B83Fc7C1fC1Bd153bd5D89BA": {
        "max": 2,
        "address": "0x249BB829fc673624B83Fc7C1fC1Bd153bd5D89BA",
        "signature": "0x9104573e055dd74534b230ab59e2d90182ff8b92414011b204a8d98b5fc308082a48b5f66814610d44f7c2fedfc64d34d78201b04b09c24719ee555d074c090f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc93913b6aa7896Dc53069dCe401A2e0E38496B3a": {
        "max": 1,
        "address": "0xc93913b6aa7896Dc53069dCe401A2e0E38496B3a",
        "signature": "0x0c1799a9195297b0abe72214fb5d4a6e29970e8dcb8f9065544b628147c557a31aa63c30d0689f84c9ea6c9d31a700caa8e177dfec92df296e8ad820aea8c7b11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xafCa6267D6C657c4F1c461E73ec9967365C3Be21": {
        "max": 1,
        "address": "0xafCa6267D6C657c4F1c461E73ec9967365C3Be21",
        "signature": "0x6007591369069a0d25af7d9307acb44b9e082bbdfd99b535993f0ef1b2d1d834407ad17703d7464a838a0ccbef2905bdceec3a39429930ce468ba60f55f924531c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x776FbaD9d3956761992D968b1919D03E5f1dd3ac": {
        "max": 1,
        "address": "0x776FbaD9d3956761992D968b1919D03E5f1dd3ac",
        "signature": "0xab81089e9f773bd2d9a9c92804aed5e33ff4393f61b9daad25e341c5d68db9935754968d95a8b09bab1da93bdd18f9023b239a40829f1762483e49c2ddd719351c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2E21602Cd484F210A127b250c655cd47106a5F8a": {
        "max": 1,
        "address": "0x2E21602Cd484F210A127b250c655cd47106a5F8a",
        "signature": "0xc3c2888f5ac321557ee950e0d5e403ee07b489d45bbb3e0970a5ea40650cba3f1cb40dddaa44b121f2bf79f31d83178948d3a82321eff7d0a925940c94461cec1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8DBB02D2caec19C873E695Cf11342033a3ff0258": {
        "max": 1,
        "address": "0x8DBB02D2caec19C873E695Cf11342033a3ff0258",
        "signature": "0x439709544445a9696382556ca7991ec895c32d12e5acc10cb0a66dbd3f06494c7aba3be4c46c048da363a1e5782c242edc1cda1ca384183da9387c97e12310711c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf7FdEF439412851F155fB40E242942782F533c27": {
        "max": 1,
        "address": "0xf7FdEF439412851F155fB40E242942782F533c27",
        "signature": "0x6a10d66db447e87ccb6fccfd0fba27ae92ae0bd40b16721def2a57ad308b6a747946bb270424705c1e60f3dd458c0f16f7d050ccfedb8e03e2069918058234561b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x24ABe938973f20f86C47E5748eF5FE08014f5795": {
        "max": 2,
        "address": "0x24ABe938973f20f86C47E5748eF5FE08014f5795",
        "signature": "0x013e64d139352858af5c007e585bed8a57518098dadfcbb8352c08f61b2bd3a2073e938b40f1a0b10694db9b951ea8ad8c9892c454b16da7eab7f22a5c03cba01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD944931C101D750f87E96b1D290046b2dC5da113": {
        "max": 1,
        "address": "0xD944931C101D750f87E96b1D290046b2dC5da113",
        "signature": "0x6f05df2f24309985b8cc6e0f679a313e09f3fa44b79e121b49b1a207a0204c6b32e9c375bc81a594fbe3894a8cffd2766713fef20958a333efe79985c67d32c01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBF447D45A5A56B27d3511a9C5211Eaad28703FEc": {
        "max": 3,
        "address": "0xBF447D45A5A56B27d3511a9C5211Eaad28703FEc",
        "signature": "0x6d5b2df9b57f61123a2740251300abace7bdbd89679897d49ad887209db9c4d42a5ba64843366de3d4bf7ca8e792e75e4038d41c3426f5efca5a990d403eb5211c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x86339A63B82A4Fb5e1EA888EEA7c760b9662f1C2": {
        "max": 1,
        "address": "0x86339A63B82A4Fb5e1EA888EEA7c760b9662f1C2",
        "signature": "0xa09bcfd1db0140644114331e4b031e078cdd1f4d306acd456e3d082c4a5d4a6e6bb5d23af0e150997ea81d5dd463ad23cfdaa973956a8117dceeb2baa680de8a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x128Db0689C294f934df3f52e73877a78f2d783B5": {
        "max": 2,
        "address": "0x128Db0689C294f934df3f52e73877a78f2d783B5",
        "signature": "0xbe89f64d415f39609379865e85f401c9bff6db8ae04f813d17236c053df5feb65d4a65816af7b524e9ceb967564d7ba7d5847801a335f63f3dfeeb1aae147f511b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x21815561eCA554054fa7Aba71e12682f50028CE6": {
        "max": 1,
        "address": "0x21815561eCA554054fa7Aba71e12682f50028CE6",
        "signature": "0xfc05c934bceaf53e5931fa4c891687564f19c9ac65b9ec712d6aeb68e36eaa540fac7bd910e240a5e0697a9a580d0c8eff0767b211c5d247ae2e132349b9a6fc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF7F53c29641108af78B2860C177B3bc307d58EeD": {
        "max": 1,
        "address": "0xF7F53c29641108af78B2860C177B3bc307d58EeD",
        "signature": "0xd8f68f147c19499752097ccd1637cdcc22b91852e80370e148276b77681ab8dc2f88ce2a3c5d3512fbf838fcd172dff964d6836332ef8a07a0e1b5a874cbdc071b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6D87A400C22708Fd2682423C66bF57b549e60A3d": {
        "max": 1,
        "address": "0x6D87A400C22708Fd2682423C66bF57b549e60A3d",
        "signature": "0xf066dc069e8975bf385fc2827c5e142b9640300f0475d840ee46312365507ffa2ebdf4658f0c0549cde69ffa3f366f02e3d2dd17902abec1817bd10f071894d41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF5f0c37873c86E9b35CC192ac860026C7a92A17C": {
        "max": 2,
        "address": "0xF5f0c37873c86E9b35CC192ac860026C7a92A17C",
        "signature": "0xe2f10459ece13d7852f6457a5a592cb2796dd26e41a3229cb4ccff8d1819280e54da1e522db7858e685c5ac66e161198aaba9df88cc313c022c089b2c3444d401b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe29242A0541f6Cbd6899eE11C0956fd1Cb0b8668": {
        "max": 1,
        "address": "0xe29242A0541f6Cbd6899eE11C0956fd1Cb0b8668",
        "signature": "0xb1192b452a69d4dc91d2090ea28cf6114a50af1d9d672155103be65c98829ac57e6423367a6dc870ae5f56a79da2e1d4871954fb69c2762d4e374c5aaed3f5ba1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x180c06dE66E2d5bC1bC13ad17634B992F76eA90C": {
        "max": 3,
        "address": "0x180c06dE66E2d5bC1bC13ad17634B992F76eA90C",
        "signature": "0xacf9b03c24a59474bfd79f8a80eb3db73392e010ad7328e6256d61af48940af0715b9b2905287696f291fa06dd748d1530ef3b8c0440457de3069767642bd7fe1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x140D218B85215E050B697953aeDBb4Fe49Bd10BA": {
        "max": 2,
        "address": "0x140D218B85215E050B697953aeDBb4Fe49Bd10BA",
        "signature": "0xc3a838f751302a69e31b69e6f1ab75ac654b5fbde10604baf242a333771fef1b32b546c13758b7c739e997ee49f5637909584b88a279f1044f27cc20ac00036a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6F28Ca76A3499f882B2f57C0B4fCFC0034fa5AD6": {
        "max": 2,
        "address": "0x6F28Ca76A3499f882B2f57C0B4fCFC0034fa5AD6",
        "signature": "0x4a4f80f59a4a5e2c84f04411be379af0207451fff9f0092c58bf3235baee17862e61e5d5a67c1472302e0e7e6bfb2c97788639a505d355f3aa341051a09c08061b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8afA39d3EBA8C62A29b8Dd040d96FcE83Ea849CF": {
        "max": 1,
        "address": "0x8afA39d3EBA8C62A29b8Dd040d96FcE83Ea849CF",
        "signature": "0x7ff1536d4d3a4cc7abd4e7b6ba9148aed4000fd09e1119ccb3c414c7e24426a8323ceff33c7e853ed9f15a545ec5f959ea2914c273a72daf017f82ae9daea5511b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4D4d73dDc94118309736626e558d421c6d3cF393": {
        "max": 1,
        "address": "0x4D4d73dDc94118309736626e558d421c6d3cF393",
        "signature": "0xdafefbe67d82b1f0539e19c305ded2a0cede01d2513be7eae16ee004a137f44541008448260831aaf778a103fadbf99b168c8ae783a303ce6e2c5a41df376d1d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF4763CF2aB2e9B0652b03BBA131f55D9DC4e46a4": {
        "max": 1,
        "address": "0xF4763CF2aB2e9B0652b03BBA131f55D9DC4e46a4",
        "signature": "0x458140f0144a3302d36924dfbb3e7bdfef807d54b25f4bc203412d452bd88a1411d26316265935ca4744b84a9fa4b2864dd51a6b371d10cdada9a1c9b34fd4fc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x99577b1BdfEcEa6a4e5C9B3667a78dff059100d5": {
        "max": 1,
        "address": "0x99577b1BdfEcEa6a4e5C9B3667a78dff059100d5",
        "signature": "0x7087a385fb49a0820a295eee3ddb56b46fb5d9f991c8640da9644d457af98da8217e83904a7ccc6648c57578cb2fd230306c5cfc3ec9350ae1e24576bb0c784e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x10E0746C30773e0FEFF9452bc652107dB5eD7c13": {
        "max": 1,
        "address": "0x10E0746C30773e0FEFF9452bc652107dB5eD7c13",
        "signature": "0x5e5883fe54268a90f32225ee8183e7910a253415f82202c28d507573172a6c3b143ceeab8af6ef0b33c52687600661dd1fed197756a85a2ea0bac15dfb2c73c31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4cd6435cE1c6e391779e234A12BcD2B3fBC47c39": {
        "max": 1,
        "address": "0x4cd6435cE1c6e391779e234A12BcD2B3fBC47c39",
        "signature": "0x4acf1f5566a1f137e4b4cb6c48d72c2072feed0ef801f92faa472c75872ac55b22844bde46ff9b50b51c6a1b6f6b4ffc8b05c95a741d70f4e5c5a296c022ff761c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x54599674a59541fFE0CeE450ff6F09a05861Bd42": {
        "max": 1,
        "address": "0x54599674a59541fFE0CeE450ff6F09a05861Bd42",
        "signature": "0xe0ad21fc9e981b31d57b7b611a40373081a567b3718e54db00191333bb5377a033ea4102fbcf542298a182f30bf860583e4683e92b0f6161f459388f786b0c411b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd27A2CaF566B95b9F6e0159CAa920c1d25919d7C": {
        "max": 1,
        "address": "0xd27A2CaF566B95b9F6e0159CAa920c1d25919d7C",
        "signature": "0x6cf108336fe04db56d4ec4d470bc2ddad67ed178a23b83404d46283fb90f45f32ab29c1b43d7aee24b094fcbfb90f2c365a10ce8b2365e832b76101069c5f7d11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaA582A4f10CFA6967DB9B9947df3Aab861342f38": {
        "max": 1,
        "address": "0xaA582A4f10CFA6967DB9B9947df3Aab861342f38",
        "signature": "0x2a04046a5f0681567468be845d7608d4af095b6826844e015326d10f21f0326b7642b0fa4993b95d000c4a59c70e636fbcf5f2aeef33878b510b7fad750279791b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCB1a4d17BeB6dEf7c665950dF0D4C7B4FB93B334": {
        "max": 1,
        "address": "0xCB1a4d17BeB6dEf7c665950dF0D4C7B4FB93B334",
        "signature": "0x4cb0bd2f9a7135fe6593ec77ab40093094700dfb9a98f26116bf82a04ae670f932481b80adb6ffcbe8216671b2423002526c53f6234c4a938c1b96637b7188641b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5C26bDb99f0cCc09908779Cb0cAc82Abb839Ca0F": {
        "max": 1,
        "address": "0x5C26bDb99f0cCc09908779Cb0cAc82Abb839Ca0F",
        "signature": "0xd3a17284a52d9f2f70cbc2f93b0669580df5c74937297c5bb82ca2c90190416b34228f8c77c25cadb88241f8a748f14a59a08fb0c9720dc0a7fa5fe19aaf5aef1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6f6B0bDbC01CA09608C6d941Da3C29aC452819e2": {
        "max": 1,
        "address": "0x6f6B0bDbC01CA09608C6d941Da3C29aC452819e2",
        "signature": "0x608bf13e9148b5114f89827667bb56c657d9e7662886b8d4d97b9b79118d667f4a0c25617054652408d02a2403d894b9f09fd43d391e746294a090fdb279a16c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x782BFf5A6074148b1f8c4E81B41F9297eFee2f8d": {
        "max": 1,
        "address": "0x782BFf5A6074148b1f8c4E81B41F9297eFee2f8d",
        "signature": "0x46e34524530f7cfc97d251d5651e10fed8561f1b63d204ada3a2bd361a9274903baa9e8effc3c9232a94ae6664bec1ff77bb02a8e88a4759fc64c41eab95d5a71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6A624A1160C8Ee9bd8e02De21194D52ae4E82DBb": {
        "max": 2,
        "address": "0x6A624A1160C8Ee9bd8e02De21194D52ae4E82DBb",
        "signature": "0x69a190cbe88863816c8d309ab780691ea4355d5fe35cda97abfaeb663966344234c582240098fe5cfa53f45dbb2ad0f608945a228c0cc486a23a04e998b60fa91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1f85aA3b8DDda2f94391E196241aD728d24e7f3D": {
        "max": 1,
        "address": "0x1f85aA3b8DDda2f94391E196241aD728d24e7f3D",
        "signature": "0xeea25a82c68df271ad12abde60586a0a702e3fb935d85643a484c7ed679fff73469a0d011c806dbd89d326cd32bb3a2b06122210de7ed034de4eefa7c7bdc7701b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x43633349D15C7156F84F46bE81b4E86182DF12Ae": {
        "max": 1,
        "address": "0x43633349D15C7156F84F46bE81b4E86182DF12Ae",
        "signature": "0x89ab781f64b345a2a6155497b95967aa3819b28a739d7a65f2fa79357b53c98759eb1a681c5b1319944a7e4ad7314929e4ee175e917f711e53001c543de211c41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa7786CfA7B5e53599e666D33a9d5E1D05b2688c3": {
        "max": 1,
        "address": "0xa7786CfA7B5e53599e666D33a9d5E1D05b2688c3",
        "signature": "0xf95be853435db41ab61132a046019f2dcc42e4d8e6b4f9c72dcc6d2e1f0b82587e6bf7cbade5a506c9a91883bd5e40de17babf8a8fd061e85c1a0882b0184f841b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1e81866C8c9b03Ce9153e04260C385889d779f59": {
        "max": 1,
        "address": "0x1e81866C8c9b03Ce9153e04260C385889d779f59",
        "signature": "0xd1f1164ada95599b9b731b322fd7baedbd8e5048828f623e891571def9c5d11215c91d7484718696b551ccf2db950039b46c84317172bad839e2560c7557ba9f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1d52c0E4888c01c5B26eFB2E68a137732A9495A2": {
        "max": 1,
        "address": "0x1d52c0E4888c01c5B26eFB2E68a137732A9495A2",
        "signature": "0x09cbb741774e3f392005cd4024fa9aa035c879e1d25cfe863f27acd6bef438d52d5a5f98b439327b0f3b74037747135849ca67286031f673a4dda5716663e0a71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x743e7a163eF1C1A8c9E4C27B149470fE42ff3740": {
        "max": 2,
        "address": "0x743e7a163eF1C1A8c9E4C27B149470fE42ff3740",
        "signature": "0x22d8ab856548437b82627ec3420592eb5410af3a621985e9e957c23b6163c599781c8b83b4f415c2e8707f0adc3b5f73f3d3ad67ca71068f06c69a3ba47f7f1c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf66db5b19b4A94F9EdD439A12C578377c99B6845": {
        "max": 2,
        "address": "0xf66db5b19b4A94F9EdD439A12C578377c99B6845",
        "signature": "0xb6b3f3779735871f8e1a21dd66980176c234e16db91042fe069e9eafcc39cb24104acea084eedf2ad3436cd64102659898e95e2e61062beda6b04f80c41fbee91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x637Fd60cc34883CAb05Ac8B3548c31265A0072a8": {
        "max": 1,
        "address": "0x637Fd60cc34883CAb05Ac8B3548c31265A0072a8",
        "signature": "0x78a68a8df8d0fcb6bc5d3e1de1f73276b3b58884be68aca44ffd1058eaca39f4346eede894510a6493bbf58c674279cf60422c6aa7b71ed2dc394364a10b4b8b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf5c10b9266aefa7d44D950a1dFcBAE1Ac4846207": {
        "max": 1,
        "address": "0xf5c10b9266aefa7d44D950a1dFcBAE1Ac4846207",
        "signature": "0xc51291b0f1829d40fe566b1ed17b06e8ae357b1b360f476e465ce0106c6ec24d3533afde7b17b0820c5eeb0036ac0e2aabe880e1ff1a3ed5e9cb46bb7574a7291c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2BFf57c6Fc67E0E27170103aDbB7648945a3Ae31": {
        "max": 2,
        "address": "0x2BFf57c6Fc67E0E27170103aDbB7648945a3Ae31",
        "signature": "0x28dfefe883c1fbc74b3c59ebd62a18cec9db340fd88809c23cd2f68412d4ebaa276ae8e1e98bf9feee47434685031c4f4901425d2081d7e92d0bb5ced91ed9ad1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x972BFAdA00E910A3d32c72271E17b4c299A6973E": {
        "max": 1,
        "address": "0x972BFAdA00E910A3d32c72271E17b4c299A6973E",
        "signature": "0x1dce52acbb04316bc5fe6ac283a524783247413751f471f55ad256f371cdb1514b74afc103f631dd7460aa09f0536434a548904ebd96ca6e6059668a450fedec1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6FC89D77979D72491314F82Cc46A4d8910d26500": {
        "max": 1,
        "address": "0x6FC89D77979D72491314F82Cc46A4d8910d26500",
        "signature": "0xac3f50ddb78c84bbda97e2e1f6d97849edf6b269cc82a1470010860e4f2d5edb6692d7eb6c683051a651e97e423c55246f4fd531077ff52284718d761366d2b21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x25088BDA816AAa730da5eaBFC85FE49d153A6589": {
        "max": 1,
        "address": "0x25088BDA816AAa730da5eaBFC85FE49d153A6589",
        "signature": "0xf95d429f66bb866934ce208e969c47432608afb4e738b3d240dc99c2eba715da3f137e086e97cb9e14fd06fff968b18a50f2ac154791eec9665d26ff704e55c41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb775fE8acE8A483E3A21Ac0E5d5C0B8c05b7237b": {
        "max": 1,
        "address": "0xb775fE8acE8A483E3A21Ac0E5d5C0B8c05b7237b",
        "signature": "0x40cf6c6aa0c127b314aa1e11de0d2fdf0ddaa1b04c7ea08d3cd1ab1f3ef23c89507daf9a7b2269e596ca7e12cd909412c3aa9f963b57bd426aee976dbf7075181b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5C34108f41Ff072C9c84548f2b368b3D016D52da": {
        "max": 1,
        "address": "0x5C34108f41Ff072C9c84548f2b368b3D016D52da",
        "signature": "0xdf974650192deab7fa1d4788ec075743b887c53cfe63082cc2b1231ac9e45ebe4864f226614e50cd42e6ee9e26fea0ddc7b3b66f1d81dac79f49ea6088b2ef761c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x27aCED470E1E232BabF2Da93A78269bB5739C48C": {
        "max": 1,
        "address": "0x27aCED470E1E232BabF2Da93A78269bB5739C48C",
        "signature": "0xd00558ab0da1cc042e27ae28405203fa35b57f6356e72fe7794dabb241343f7515d28985798aa43e0ee9f1e360abcbca449ed0e01973c770fb121a7cc6ed117c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3C30Db70EA2c01Fe3aaa662140903A90A1852A1F": {
        "max": 1,
        "address": "0x3C30Db70EA2c01Fe3aaa662140903A90A1852A1F",
        "signature": "0x5e006d5ced9fa3705eb72dd7a09ee50f684151c886770fad068e35184fc3a57b26527ab685c50e5519301fd24e455dfece0fdc710bf8cc44e9cd9156ef661d231b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x17cB989874EA0e70dA7AEc4e6f759Cc31128d071": {
        "max": 2,
        "address": "0x17cB989874EA0e70dA7AEc4e6f759Cc31128d071",
        "signature": "0xb86e0b176911be456ae28e09225874b7c6521a9ae914e0760f3bfc17e52f6b275680f6aad79a4859dd70319f3375d4890a71478c16efa303933d13178eca26861b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7eC9F1F7e5c1B6D8f395e2eE31B0B4e7360c8405": {
        "max": 1,
        "address": "0x7eC9F1F7e5c1B6D8f395e2eE31B0B4e7360c8405",
        "signature": "0xde26ec327522db37616376b1f58bdd3eebdb9c44b1bdd2d30db9e3ead34289ab084138d5207f12b780621a5654ae5e7212b6f9cd4f0e2e24e9a739ff5056d90d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2Cd595a91e350Eb3f606e83A441D86F76744f524": {
        "max": 2,
        "address": "0x2Cd595a91e350Eb3f606e83A441D86F76744f524",
        "signature": "0x972cb377137035d9c4274cdef88ba9382fcedbdbf17204ce8960a9bf4f0456c247a37cb9ee0754059ed4bd38e9c8aba95996c53624cc3172216b8340b57dc82a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6b8D260d58C544E556d6f6dE2691BDC0D7707EEA": {
        "max": 1,
        "address": "0x6b8D260d58C544E556d6f6dE2691BDC0D7707EEA",
        "signature": "0xc605069c22c415c9dcebd1276ab8d24d8ab8ee061b4a2d71d56a0d560308ac4f3e38e2be0b12820f0b9bb4657628923f5520b571c48aa5953b0127ef834390d81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8F6CD443E3d8F03FA17cB3694eF95F437667e9d2": {
        "max": 1,
        "address": "0x8F6CD443E3d8F03FA17cB3694eF95F437667e9d2",
        "signature": "0x4204dbdf5d0939f8bc40f93b22d3d97a8c7e2b8493d3b4eb9b3311a0c8af6128751e52c5ef6452c39dc2af119ca37edff6b579a0def67442cd6b7dc92b15c18d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE5954b066A11ff30a00D23f62cEa54B9de2382D5": {
        "max": 1,
        "address": "0xE5954b066A11ff30a00D23f62cEa54B9de2382D5",
        "signature": "0xfb46f21ab4311f89b460775b968290cde948798debbf1a25c0e7b0b36ec6c4090ade75ea0df5ea0624d909c4be697fb8d40d809b76bbc56ce934a3168c0fb5101b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x99ec9E66a0E1852BAF525B9ae31EAec9DB105A0B": {
        "max": 1,
        "address": "0x99ec9E66a0E1852BAF525B9ae31EAec9DB105A0B",
        "signature": "0x2aa7c042e2dbc1271a2e83e5e554c52b6c69fc3c5661aebbd46054a869c673f468022b1d9ee37abb9311f0bc89fe73140b42aeed549e0138d984aeb4b48d2f381b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x29F12602Af73a020b28EA6534693Fc5b1750644e": {
        "max": 1,
        "address": "0x29F12602Af73a020b28EA6534693Fc5b1750644e",
        "signature": "0xab7c357840f648526bab46a97f7e50b160c359febcbcfff31a431bd1713d9f530d78563c9877eabdbf3512295e2ac346d0ba2d2f9e53d6db0bf59e0fe5df00d01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC54AA901b257d4c47C44217f538c84Da28b21308": {
        "max": 3,
        "address": "0xC54AA901b257d4c47C44217f538c84Da28b21308",
        "signature": "0x8a9f3a687c9ce80507b65c823a40a14a5b10ef9a0cc6215f04fe583e12d2342c269c1f68f9ea84af65714567cee84277b6c8ef674f391d0cbc36d9763d51fbea1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd011878cBEE251d148857A58EB737ADc3A4a9e98": {
        "max": 2,
        "address": "0xd011878cBEE251d148857A58EB737ADc3A4a9e98",
        "signature": "0x7b184ccb20aabe4b1c013b0847a89c85fe627beb8b2844e194725ad3cd87de4e3581d0fd5e816b8ec5a23b16cb3c053f23bbd96d277e08a36e898fc51ff0c0e81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5f1B8474a4CD115a1FA5870C1e4A3AB80bc7e83a": {
        "max": 1,
        "address": "0x5f1B8474a4CD115a1FA5870C1e4A3AB80bc7e83a",
        "signature": "0x1ab0632a37d52e8a56dcaffca09068dcc48138c1df8b377ef7579e042004d3cd730fb7013164b92d35c62f6e4440079af35283a9879c16676634a87d382017251c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB49e4823549b0A4c665257ea3dfef8eeD77257cC": {
        "max": 1,
        "address": "0xB49e4823549b0A4c665257ea3dfef8eeD77257cC",
        "signature": "0x404e6345ab9a355b20ccea6f797610889189f007b719622bf2a43e968dcb859830be454a39be2be4e105c8fd3d4b718b2c5f3347b93c4280cce134feed0071bc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0e0B15C2E0E02a7dD78cD8e927F0a1e03A7473EA": {
        "max": 1,
        "address": "0x0e0B15C2E0E02a7dD78cD8e927F0a1e03A7473EA",
        "signature": "0xc7a22148b82f3d145551f0bfeb171d09cd413a6f963aa505ec36778050890fa309655d2a1ba2beec7c36416cfba971f26209e53de2b04bde92ffe4191c526c0c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd79a9865F5866760B77D7f82e35316662dEC6793": {
        "max": 1,
        "address": "0xd79a9865F5866760B77D7f82e35316662dEC6793",
        "signature": "0x960e3c2f502c5c026619d1639afbe28fc3b5ce5a2404b0b0adc7636815968c9039272ed34638ad81497d818f5e95249e310d2491f6030b025d4ae9396aa8058a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1F7a5288C948d391A7Fc5F37fF5F0128530a3F4f": {
        "max": 1,
        "address": "0x1F7a5288C948d391A7Fc5F37fF5F0128530a3F4f",
        "signature": "0x7f1c67d22e0a61d5b54ac47c477cb0269b2ae5aa7d2da214be5fc788300251a10801f69973a37e3027ef39dbe418f4e8db9d4a9d615219f4c4ae6fbade543d7d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x57275F7Cd5cfA7c94E142D1dE13AC25B74bF764E": {
        "max": 1,
        "address": "0x57275F7Cd5cfA7c94E142D1dE13AC25B74bF764E",
        "signature": "0x6d38e59a607c5c3eaad390993a6c519f17538247cd6e242dba24a5fe94ab52c85b70e2dce31395a06eba81612b66b403cc14d59dac1bb81ff032180eabb44a7d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x02142494373E983C1E6DeAe269fa73FFf2254851": {
        "max": 2,
        "address": "0x02142494373E983C1E6DeAe269fa73FFf2254851",
        "signature": "0x2784ff33619989e4a6cff5f18ca33e9346ddb02a3160c60bfa9e682f93acd562150301193c7749f6922fe40c78dc64932a5b1a20c51ff35b6888aaa8c54d7f931c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9b104455faF72E2CffC68d4114e43D247314aE37": {
        "max": 1,
        "address": "0x9b104455faF72E2CffC68d4114e43D247314aE37",
        "signature": "0x4a49f05b796a0fd6795694facea037ec685efb4213bc5222360c43e4295fd33a5b77f5a0bfa265270476158afa6773c9a884584c31d24dbc434430902f09a4c91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8Bd679De872B40411E8A98FAd88C10e90c00466C": {
        "max": 1,
        "address": "0x8Bd679De872B40411E8A98FAd88C10e90c00466C",
        "signature": "0x4ed62f4f029ea80a9c5887415321a88135843072b59a9e4939b766377d96c6f74a2449ed4f85e4e32cb19846a29a3ee40a10a1aa45fbd3a15ad50832f53b1dc31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAe18A8B148e157B91f7C1583b9c7101bea39AC32": {
        "max": 1,
        "address": "0xAe18A8B148e157B91f7C1583b9c7101bea39AC32",
        "signature": "0xba82540cf94e332df28679cd31311131002f009c53118ce091b07497f4b4bafd1942540b70b63338dbf1f5ed7cfde7b30396b16cb842991b7079657e1d2ee25a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x429F377528874f3cBe274266F2E335b39f4771E4": {
        "max": 1,
        "address": "0x429F377528874f3cBe274266F2E335b39f4771E4",
        "signature": "0xdee569a321c644aadf3d515c2b0230bf15c8b97479621806932e5abed01fce2a5f8eace3da595d38ef33d957d8e1ed5e212f0bb2f6a215a06c506a2b89b1191a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2E26B11cd0386C6150D6dE2F5fB237736A382031": {
        "max": 1,
        "address": "0x2E26B11cd0386C6150D6dE2F5fB237736A382031",
        "signature": "0xfe3ca9aee741fc9d5d7867ed9ea697113775de3881abcaa03e4908217dcb7e6a593cd22412d761109c7bd2e8a848c6a6ada89c0cea4f4ae691cea186fdcb7cb21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE04bD00B3d945AC2D96f71A6dD5133b7b1F0083D": {
        "max": 2,
        "address": "0xE04bD00B3d945AC2D96f71A6dD5133b7b1F0083D",
        "signature": "0x1d8abfdeef364666e102da7bd717cb94e9cc00e7b29f77c09ed47f2c6816253366d3fccef99f5c0ff98cb01c0789e04fff6f86ba09242793580e84135fc8c71f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC132c396Cb11e2FbB41dACFdf69bf0D5Ea857458": {
        "max": 1,
        "address": "0xC132c396Cb11e2FbB41dACFdf69bf0D5Ea857458",
        "signature": "0x8bf987e68c016ff55e6eeca2fbb86cb1d9c7348f79373dbe13d3960726f5f3485589848227abe832c6c048b9c8249072a02960c1007d8e1b965c505d8b284b0d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbF556b5Ae13590a12E58aF7C088f9b1b95FB6506": {
        "max": 2,
        "address": "0xbF556b5Ae13590a12E58aF7C088f9b1b95FB6506",
        "signature": "0x07fe0a6b9336aad494904ec4052fd41617090b1881cd4db54de907cdc3066bf166d3023e497d74c3e0fd7c8a4dfc31a596faeeda823727e267ad55e8f036596a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x589C5F92B5B8fc248b3c6bB9Bbbbc3d8c52d057c": {
        "max": 2,
        "address": "0x589C5F92B5B8fc248b3c6bB9Bbbbc3d8c52d057c",
        "signature": "0x70a151ec088696c59df66d585e2b7ad0e103d41ee90c1315fd2d45f72cfef9857662d209af6c884ea967f6b28fd0b147e7baa6a90225b116a18c248143b2127c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD96EaCf4B6766325F107e267A793a8708FB59125": {
        "max": 2,
        "address": "0xD96EaCf4B6766325F107e267A793a8708FB59125",
        "signature": "0x4c3a8fde6facbc40660806e38337015435e7c32f57d9f3b893a250883bef8c225476e27ceb86df03be1850d482baade2c8e907c2fdafcedbcd56ec15fa085e8f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x741e7b4A449DB1C0F34312716dD7ba46AE0b962a": {
        "max": 2,
        "address": "0x741e7b4A449DB1C0F34312716dD7ba46AE0b962a",
        "signature": "0xaf58078b4231f033c75ecfffa7b5ffb28ffd2ffcb2188a225fb3c82569bf19516bdd35eb3386438da64c564305fabea47fe303a0dc13c391994d9ae9abd93d2d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9C93333E47F84cC88C2890A741fa437F772C6430": {
        "max": 2,
        "address": "0x9C93333E47F84cC88C2890A741fa437F772C6430",
        "signature": "0x89938266cbf502d785d4fb5d0b944d7e15e5dbe11061eb0d4f585ade966764052e7e0f9d389d4cfbb8f116baf6e575145b22c78d628e89c77cf154c77c08d1e61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2aa91CFFEbF3AEf8Bf79712249D6eCD3aBCEBedD": {
        "max": 1,
        "address": "0x2aa91CFFEbF3AEf8Bf79712249D6eCD3aBCEBedD",
        "signature": "0xe6d800513cc65233956fbad4f8a1d46ea22747363fd35205e99a91bf2f0af77d534e48e5df94a76997532dd7d9bed4574d859b0d3176c4138341855e62c245041b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2664287eF75fA1fD392201709B3C1b608b4fbE1F": {
        "max": 1,
        "address": "0x2664287eF75fA1fD392201709B3C1b608b4fbE1F",
        "signature": "0xf8256dadf8b0f15547757eaa4656a6946bbd7e25e86f41d215428804d9d94ec214f85335415d355bfa09654abc77b1301de5a166a27b0a72e2c200d62f7a36101c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x42A24FFb2c0163Fb1de315745d7ABAAf728a1620": {
        "max": 2,
        "address": "0x42A24FFb2c0163Fb1de315745d7ABAAf728a1620",
        "signature": "0x49607f218ea60ce9140776c63c14a564c33309dafd4a78220a70fef11c3f764703107b53806f679cc35804c81dc70a035b6270dc2fb89ca28115493f352748e41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF7B493620d11DC6B24C526286ee6F95f05420B7F": {
        "max": 2,
        "address": "0xF7B493620d11DC6B24C526286ee6F95f05420B7F",
        "signature": "0xd74abdb29e06d42ba433abfc0817643a62f5771ee7096a047b254d777fa1e3f64a020c3ce662f82bc31b70a82704f5dd9e66b87707ae093105150e36755488c41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0047890f39c3f27F57bfA1ddf1CeabEFdD6b0f35": {
        "max": 2,
        "address": "0x0047890f39c3f27F57bfA1ddf1CeabEFdD6b0f35",
        "signature": "0xf62b38f226d8af2290affe9909dbc7b03e0f287390a2b14a38221f68e8954b6e080e5bf03ce8231d4be86ea6c8687721fb446de694882de11d7f32053a1994a71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x95fdD3157edae1f77E5E65Cd269018353938A585": {
        "max": 2,
        "address": "0x95fdD3157edae1f77E5E65Cd269018353938A585",
        "signature": "0x12c289d166c4ce90afeec590a132758a7e9b58d25336d006b24d26ed905d6f6565525c0a004252b7e864f2c4daf62eb60aa0a4e8f11e99545868d586d20730e41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x45Abce2de4CA863f421642D263FFC4C4b7b1EC06": {
        "max": 1,
        "address": "0x45Abce2de4CA863f421642D263FFC4C4b7b1EC06",
        "signature": "0x7de63524b0fd608567bda79fc407e0023b0f6fe658f9cc8a1866bddd67f1db953ffba73b061352693efc0a855a7cd278954a710f41ca0e9c3fd73b6ca154ff271b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8bCb198b6156C12b37a4f3B16E5929cadda22681": {
        "max": 3,
        "address": "0x8bCb198b6156C12b37a4f3B16E5929cadda22681",
        "signature": "0xdb484e8877f19b50be1cbcc996f000d8180d93c47bbc7711c3b59180c0aa602d55a014a10805a5453095336c454777a49cae68912f1e480d2f94ccf6823c45551b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6f17fC05a8970E28276c2a2cbE5a92EfBbC7Dad5": {
        "max": 2,
        "address": "0x6f17fC05a8970E28276c2a2cbE5a92EfBbC7Dad5",
        "signature": "0xf378de1aeafb75231740acac290179b5214e7113c5827a641d88dbb69da2badc4e45ece2fa892f2cc97a33e7fcec5345e92cd72981cd10b481b482ed4ab7c78c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAAd74959532B32eecCF84D9c759C0db57A8B81d2": {
        "max": 1,
        "address": "0xAAd74959532B32eecCF84D9c759C0db57A8B81d2",
        "signature": "0x4a4e944aec712d5188a8a92c2b090ad700040e9f045bd0af325144852c3069942f4b9a932c50dd7699b5dec27728fbe93d61711681223f99a02ad1f0d60007941b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9Ed9Be36cfA3Daa5B3699AC3ac9eb4d16E37334E": {
        "max": 1,
        "address": "0x9Ed9Be36cfA3Daa5B3699AC3ac9eb4d16E37334E",
        "signature": "0x2b616555d0673b0a470096f53980aadcf8427c3789973cf8dfb988e00b005e803bf8d3b7ebbe18570829ce33ae00bfed47e43ce0d504caf18d087006d2e910451c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6049224A0AbD391F432d7D44803f22e6335F472b": {
        "max": 1,
        "address": "0x6049224A0AbD391F432d7D44803f22e6335F472b",
        "signature": "0x8f510f9ff6c756ae3407ba0f90df639d724de9585546dd33e14e7953c76b586a7ca3f5997025bbe18b32c293e45a82ee6db5c7becff393278a363776b46af3b11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xebF88E36110e2d628D6ac4A9F1C3dE09bC710689": {
        "max": 2,
        "address": "0xebF88E36110e2d628D6ac4A9F1C3dE09bC710689",
        "signature": "0xa0cc74040896709e6c6647e003e16894f5a21ec08cbddde7b039ca14ff8a72797cb2d386fb5d1294a61d6cd7d2233c75de0ac36f25f5e709b040a0d35b4ea4931c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF4A8bF0F8e53A7479F0a1F86c28402B26581d21c": {
        "max": 1,
        "address": "0xF4A8bF0F8e53A7479F0a1F86c28402B26581d21c",
        "signature": "0x1236b5fd6dd5bd242e8cb3e387fed0194420f6d5883d3b1ba1e13d49be18ab3b72727b7515062c29989d771ea8c333c27d82692f66f93e96a7d5205fd4f1a25c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1da8cfB5fA5D1c7753eC08316f76700000D961C5": {
        "max": 2,
        "address": "0x1da8cfB5fA5D1c7753eC08316f76700000D961C5",
        "signature": "0xa00600a60f0e6c8972e029b32e33fae1389ea8b14486d87b8ddaef4f3ddc11e123a09482238b716a18f2d32aa64deaac4fdbf4ddabfd4922f995d2662644c9e41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x83FD7410158A17e97d9753a54240521f8AFC973d": {
        "max": 1,
        "address": "0x83FD7410158A17e97d9753a54240521f8AFC973d",
        "signature": "0x3fc0e01502e476ae4cec227cf3764a63fb3483018b89441397a7d3125ee69354768d24103a8468b7acd2ec7cf4c6995492d94ac5610f3f8a0b57f7765dfdcfb21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4eca095E7E947BFA71F33ca5a5dB5fd41DCa49b1": {
        "max": 1,
        "address": "0x4eca095E7E947BFA71F33ca5a5dB5fd41DCa49b1",
        "signature": "0xcec3212fbf8fd5f3f386ed97e744500948c9f9425ba3aa82c2949ba3749ccd664d8d98ae23994149539d81821f26eb56feb0a1efcb97fdecc964e54928b9e9431c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x102DD33ef3c1af8736EDdCc30985fEB69e099cD8": {
        "max": 1,
        "address": "0x102DD33ef3c1af8736EDdCc30985fEB69e099cD8",
        "signature": "0xc16bebc5f87e70754847c41f92b4f7dc174eeecd127b3ca4e6f0730a9a39d2f47e2380f0c43eb88bc60c44fbc7c7115ce618214720312ee08db049220761b7961b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0F28C74FE605C5B9407e1dAfb8Fdbd6D28ae8400": {
        "max": 2,
        "address": "0x0F28C74FE605C5B9407e1dAfb8Fdbd6D28ae8400",
        "signature": "0x64ed6107255afb3c9c680e8420c004e307cfaa96c4a04e91f0ee12a28ae0518202c36481a96fef0c5ffd42df4346d3a0a0372a3ffcd1fe24540a13e6874fe1c31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAbcF0aCd650dB812faEF2dfE5790f696a064E1ED": {
        "max": 1,
        "address": "0xAbcF0aCd650dB812faEF2dfE5790f696a064E1ED",
        "signature": "0xa23af5d77ff268e7c3fc8356725482031ffb90e83c3ff35c5de1b64b7aa81755054f6a9f1340b9996e2acc55c0ec02484bc709801c3c15e16e44580b6c7c04af1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7448E97DD1f1a600A8832450BD962d3fCd8C5198": {
        "max": 1,
        "address": "0x7448E97DD1f1a600A8832450BD962d3fCd8C5198",
        "signature": "0x7f79e40d6d3807e279f579e619b9c31639bc497ec16e71556886266f6b5f04ba0a466b27f03bdb6146cc33c97bb8ba2a174b7bbb2576c555bccc5214e911141c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9175D65E32c0D132addd8256040d197A4Dfc8FC3": {
        "max": 1,
        "address": "0x9175D65E32c0D132addd8256040d197A4Dfc8FC3",
        "signature": "0x130e2b9504f3bee0ae608b0da6fdcb5fb09590ef5d8c8eee4bec00dafc42b5ae3754edb1ff5853cd7195c961e09b8212a87c16eb9450976aa96231df02ab0d991c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x591CDa6Ed4FA796954082486C2e700Dde04C420D": {
        "max": 1,
        "address": "0x591CDa6Ed4FA796954082486C2e700Dde04C420D",
        "signature": "0x678f6c53e6a01761ad112d927eb07c496ba5299dc0cfe03279b5662900b3f8e83596454e43dd3668234198ccdde3e0c08c6ccd74fa7982f4c18ba53562e5431d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa4775de38E45233cE796dE83c816eF2A9831A14c": {
        "max": 2,
        "address": "0xa4775de38E45233cE796dE83c816eF2A9831A14c",
        "signature": "0x3ae622ff2bce0c5fe9a0f9446b4b424089fd95bad15f2d3246b68bd281951ce1318c2d6ffdebcc1cefa5a3e4a4e437793e3f5760ffec8f6c3205cfffd60ac5d01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa08DB45E0b68A4973361E3D6b85385365725e973": {
        "max": 1,
        "address": "0xa08DB45E0b68A4973361E3D6b85385365725e973",
        "signature": "0xd58747c16d48ad7c6cef33af308e2a4f270f47889d52a4b21a7629d378b0bd7a17a61eef544def02c5b32d444b783769fd1f3a9c6877e29e6a99c01ac6bc23571b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6b6D14095D22888D08FE8630d19cd94D04fd8E3b": {
        "max": 2,
        "address": "0x6b6D14095D22888D08FE8630d19cd94D04fd8E3b",
        "signature": "0xdd39253db21c004bef9755a37939ce08eb6316117a5182cb393a9784b1cb35a93740585cd98cb323d4fe1324a203c1756fbccfee37972b4e1595800a35a96dee1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB20C4a388E7b232e44396b9675aBcBb5976c9E55": {
        "max": 2,
        "address": "0xB20C4a388E7b232e44396b9675aBcBb5976c9E55",
        "signature": "0x93389c2cfd09fa67a5f8b2e8d249dbc7fb09f89b8e902ce4a36e5a40f85a58f27c669f97a0862daa39ffc5f5b568a5589367dd59c5d32072f9e855b498c4efe91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8cf303cf8d16E6289CEB1304417C8A083bD1623b": {
        "max": 1,
        "address": "0x8cf303cf8d16E6289CEB1304417C8A083bD1623b",
        "signature": "0xd7bfaf2950b263e2a4b31b4e16bf1a925477bb1a1c6c11e8b230d97a3b3ad982676522710c3c1953f9d27e0b1905ff78c189c6d4117b82d561b9d6ccef1819a41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x42aFeaD40916fE8A7CA80fFbf41f070063c0854b": {
        "max": 3,
        "address": "0x42aFeaD40916fE8A7CA80fFbf41f070063c0854b",
        "signature": "0x33f123615085650d57fafc44c628af26eb4aad4d6e5d707d717fafac207bfda569bd73ec76c194717930d45a08ff8944c87e6d60b285589ca25c13c9d5d7b11b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x077525C12690548b45C29c93b6F00Dc3cBF8EB37": {
        "max": 1,
        "address": "0x077525C12690548b45C29c93b6F00Dc3cBF8EB37",
        "signature": "0xc837cb68f706cc7caf8f977e885d782f89c2ce163c411b6432c9bb75b0e3494a551b8bfb397f552eb61361d83507115e133efb201d9b5fb11dc4259762bb0c901b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x61c9Cf6Cf9B2c477E3B72f86F04984512Af1EC2F": {
        "max": 1,
        "address": "0x61c9Cf6Cf9B2c477E3B72f86F04984512Af1EC2F",
        "signature": "0x5bc4540856ced5cd257fff80b8fd6501b4c54d6b8d3a444d270ae3e8eb52463535c3cf44056df365e876a71dd5ac609657d42c8411442d2eca00d5b1d3bc6afe1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcCd43138D0CdB27a6596BdA4A8d4a2D64B285b20": {
        "max": 1,
        "address": "0xcCd43138D0CdB27a6596BdA4A8d4a2D64B285b20",
        "signature": "0x60ca3bebdacda59713f43a314aa857a552dc0d0f1e535a1c6a28f9b6e907a40f5c2c62b55e57d5514d75a94666f5c927f3a83a29d9641482b8332e266f2817b71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x40eEC9Ce693eD93D1be4F3693E6538C69f384a2f": {
        "max": 1,
        "address": "0x40eEC9Ce693eD93D1be4F3693E6538C69f384a2f",
        "signature": "0x90e584e3407fc101e0d6cefc818d2221898ea0db51daf3c34b11aa1e092d57602354727e4b7b014126bf08074918593c2b0a5d21f49ae0da3250866298633f501b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBc0FF1691292C1A2BE278a289A9aB75c269881c9": {
        "max": 2,
        "address": "0xBc0FF1691292C1A2BE278a289A9aB75c269881c9",
        "signature": "0xc2ad352306f033abd3dea2f9487280a56d9834cc9dbd6e5cb7181dfa312b9f583cc76f86c081ec673ef885600e6123fe7b9a99646e358e4dda8e4ba4de573b4d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9b3c8DE2f0B33e8627d72910418A717c731194dE": {
        "max": 1,
        "address": "0x9b3c8DE2f0B33e8627d72910418A717c731194dE",
        "signature": "0xf745230b823a522d8574d6ff59b57fe166c517cc516994489b8b3f4421b5ef3b6747cfb8ef8fad2809767c4838a5428d812c3d143aa2af1984b9fcb113aac9bb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd88066b40100502e623539Ce8496a6a03f89aaC3": {
        "max": 1,
        "address": "0xd88066b40100502e623539Ce8496a6a03f89aaC3",
        "signature": "0xf261c3cf981aacfb007d6ce2e69cc9006b094b5d550852c1d0fc309d7ae169ea6b3b692b5f27768961b282f1ea91213d2040d87dafa3e96ace0779fe79440eb01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD0a7C1fB7cbbA57d43649eA49B4532F93877B028": {
        "max": 2,
        "address": "0xD0a7C1fB7cbbA57d43649eA49B4532F93877B028",
        "signature": "0x58b59cafbee7b5fecc1e3234179f705a4ca454a5f188acf59907e8979818d32409429f435e49c08deccadf22ef6bf86a4908aa7a83173a33a45d58906a755e441b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x89F3C80f380cb02E89750889cA7b20eB87122F12": {
        "max": 1,
        "address": "0x89F3C80f380cb02E89750889cA7b20eB87122F12",
        "signature": "0x6bed6aad57b56032ee18a172c310beb60c5c9ac87a03bc9d8e2b25a993a725b41c0d3a33aaab331a2996e5a9c192d692723f128921ad62d7df12484de0d4d0021b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x711Ed6Dff07A4E61C4158350557B0606FF79a6b5": {
        "max": 1,
        "address": "0x711Ed6Dff07A4E61C4158350557B0606FF79a6b5",
        "signature": "0xdec0c0764f175c9f5bdfd442e5a791ee7ae0966d3571f0f870c8af5293fd27694b3188c4f7b20b003d3c00ad3a0b74201ad081db923030a5bcda011d4802fa521b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAca06f1f99BB8AB2DE0e209884d2601B62EdA5c2": {
        "max": 1,
        "address": "0xAca06f1f99BB8AB2DE0e209884d2601B62EdA5c2",
        "signature": "0xbeffb256834919214680435349f84929958933ce924f9fab9ab66786fffb1e6e7ad05cd9447bc723be074e2d9f7b0eb053312bced5d3cbde219f1e80608a7a391b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0f50E35d5A75d876b5C88a8A64c6E4566DC5372f": {
        "max": 1,
        "address": "0x0f50E35d5A75d876b5C88a8A64c6E4566DC5372f",
        "signature": "0xcf8879458b2149dc73a8f43904ffbdd45450a45a118200617abedeb61338c951232c6e0b2a8df306afdf8c41dcc8c49c6b4f77e77669c78caf592df9a3aa196b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5b6E57bAeb62c530cF369853e15ED25D0c82A865": {
        "max": 1,
        "address": "0x5b6E57bAeb62c530cF369853e15ED25D0c82A865",
        "signature": "0xb8e8cd26340b6382c80a0ad663d52e50cd2d2f2d9df4da85db1d4b3545dca5d37fe9ffa101da98cffc5521030b4e19856835ea0f9392e58f9f6456aad27131441c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2e2b0927bED98dB53c02c696eDF151d04896039f": {
        "max": 2,
        "address": "0x2e2b0927bED98dB53c02c696eDF151d04896039f",
        "signature": "0x8ddfa22ef4253acce0d72a173968d5ac9c1740fb55b7145554c850e183dc7259014169375a9b706f753b6d42687e83c40f694d2d7694b9c33ffd7f5d8360d8701b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3493511BE0A48F1b4d395984A7FcB9B9127EC9d2": {
        "max": 2,
        "address": "0x3493511BE0A48F1b4d395984A7FcB9B9127EC9d2",
        "signature": "0xe90827948058ba7f40f60d7e3b5c7eebc4545e88e0dd0400ee09f350976f7a285c62ddecc253e02e03ea54d004d7cbbee69977a7c3a3d9bf00ab95e0e38123f61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8442bD11113c6BFeBc670a26d2B04ec6Cd978F2C": {
        "max": 2,
        "address": "0x8442bD11113c6BFeBc670a26d2B04ec6Cd978F2C",
        "signature": "0xa8d2b67e67dbaf9071030d90c9c1f39e44de51291a238f7ed7724caa53cf6f0a64dfd6e52f15115ad2434bcf16c3a45a3eeea42cece4a5588bc20d52078c1cb51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xba26BEbfB345e4C38e69cC34ADbCe54f33748972": {
        "max": 2,
        "address": "0xba26BEbfB345e4C38e69cC34ADbCe54f33748972",
        "signature": "0x7b57f856c34d7617bb9ade651ea88b958371c0ce5e9dd9e7a98bf80ddf5c3aa8033ed3c451c9b13f0d2ed42db5db26bf354fdf628b2263a579dc5dd2a6b210701b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x177F8fB6e3a93f93db0eA7CdF9459c516a07D9fc": {
        "max": 2,
        "address": "0x177F8fB6e3a93f93db0eA7CdF9459c516a07D9fc",
        "signature": "0x92bad74b5aa8c91369ec89b706fc373d6c2025ea3e6c744b30b66caef1c5636863e854d7bcf79c02abff3d628cb5c2990b0a25e87047de019ef1d5a5680353601b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeD7aD5c39f972bA55cd1010eC41c6412e04e3AcA": {
        "max": 1,
        "address": "0xeD7aD5c39f972bA55cd1010eC41c6412e04e3AcA",
        "signature": "0x17cb7542830a3aea2b3269b5b5f20b9688971779acb80d3b28708f09097ecef903f918c6c189766db2d37a30d58e03d1f6c8630a5dd9a0d2e234a9b43d5f1fd51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdeedF0491Ba535F2eab24ff92AE1a26cc3A8EB83": {
        "max": 1,
        "address": "0xdeedF0491Ba535F2eab24ff92AE1a26cc3A8EB83",
        "signature": "0xa80c0683878eff32b649deac05ca97e8246ae2c913f9a11b84181a27034e133574d02fb16758efa43d9a64c3f5f7960c5f0ebd23f0a3d622559da649c563ef0a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFf1f95cF03AEaDAae6185D06feB3AD552b8b82c5": {
        "max": 1,
        "address": "0xFf1f95cF03AEaDAae6185D06feB3AD552b8b82c5",
        "signature": "0x852ef6094f6c6d27295a4b93903c6c0d57670038652408d5cfe5f1d4e97669081528d594ad463c96e82205b27f238aeb2bf5cb346baaab052496e9d6222afcc91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x09a22A6e37167FC1951e783d2dE7B9861c41f71b": {
        "max": 1,
        "address": "0x09a22A6e37167FC1951e783d2dE7B9861c41f71b",
        "signature": "0x26a5aa9a8d8e73cde6c8a482a49bc0ae081f934eec9cd41b2a2143d33aa76f1d591c69ea5f47935746ccbb2a46e44deef37d89a5f8ded3f4b9b8f45f5c761eb61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8B9e1e2A3e918fF651e72d1A41926e3EC625DDe7": {
        "max": 2,
        "address": "0x8B9e1e2A3e918fF651e72d1A41926e3EC625DDe7",
        "signature": "0x057ea89253db5fcb37f6e3d05e94225e6b278045504c71300cd73e005c012c533f5406a5bd2bdb44493868b91c154f75364ba0de1e7ff8add1ac8388cd9223361b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfec6A7B8B54C0F5C481a004A092644Ff3AAc3783": {
        "max": 1,
        "address": "0xfec6A7B8B54C0F5C481a004A092644Ff3AAc3783",
        "signature": "0xa8f385fd79f0bb6ed6f0ae2afa4ad5cff7a6c75969361cdeec96ec503ebd4e8f43e7f1e58f4ecdeb85d6c758ac684e4b9d017636acbebab1ddf7c25df964333b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf3C587a90de0AD18E2A81fe1F5D4A61B766b721b": {
        "max": 1,
        "address": "0xf3C587a90de0AD18E2A81fe1F5D4A61B766b721b",
        "signature": "0x5b9c2c334246043abb70ec74cdc90c4d581dd39deb5c65ef13c139ea04fbc8526a4d8ad37871d8c3ecec577a7c0149ce545392748f35140429622da82713a7d31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4A0484e9A5FAbD940A75cAeAb176021d0B258A5e": {
        "max": 1,
        "address": "0x4A0484e9A5FAbD940A75cAeAb176021d0B258A5e",
        "signature": "0x7c1b9ef8d9c12b70794d4fbed7feac585f4c25db87f0488657d027568fd018a33073b6948825444d854a81984faf227f2dc5b83cf830cce1a6b6b705687650281b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD40Bcb50B2099FC59231d4A19f61Cc29b4a35301": {
        "max": 3,
        "address": "0xD40Bcb50B2099FC59231d4A19f61Cc29b4a35301",
        "signature": "0x6dc9f93785e2f8bd7137f5c6c8e71daeafbba18f50390ccfa50bdf7bb2c8a1fd30c7a23dad382fefcb2e7e090430bd12cc478e790260248703ef53892e8e60561b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6F10719408d99d917f395efC2e2EC9136b873cEb": {
        "max": 1,
        "address": "0x6F10719408d99d917f395efC2e2EC9136b873cEb",
        "signature": "0x63b5076aa93a6764fdad7d1f62e42836699313989672e0f2293907ba1ba889af5359b378896ae9e4a83815c8c5df890d85a0f196ef530b2f21c11b5700b3b0091c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x74fa1bE357bC182c88BA77E771a5502e2b271F1B": {
        "max": 1,
        "address": "0x74fa1bE357bC182c88BA77E771a5502e2b271F1B",
        "signature": "0x9032b19224e50aa92437c7a80ba068adc7badc70375f9101e0f607646b246e973223e37c897293e8d5c3b3f592b1d84e6cf98be03d1b20406962d1a766c2e4b71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBDb1404C31Cc5760697cCd79Fe2A68F26B54cE85": {
        "max": 2,
        "address": "0xBDb1404C31Cc5760697cCd79Fe2A68F26B54cE85",
        "signature": "0x543fa5bc77ba2e40692acd98fc49380da7a94f30e0c498d1788c7d19c5b6419770383c573d80d780cf8c202df56ab895d935c132dca3425bd3ea8fb2b9499eab1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfeacDa74835c13467aae00C17353A6A13a657D06": {
        "max": 2,
        "address": "0xfeacDa74835c13467aae00C17353A6A13a657D06",
        "signature": "0xf5ae2f88ac184e7026e5c2c2a73b4425c263e540da6075f9b5a550fb92f9576a28ea31a1c34a2ea6080e109c6b9c3b9fbfb663ff03fe0fad625e6664a7a82e6d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf8362683C08B5de1Eb379C6Ac55590974b45D4b4": {
        "max": 1,
        "address": "0xf8362683C08B5de1Eb379C6Ac55590974b45D4b4",
        "signature": "0x580265c4d3013dfb777ed5d242546e83175f43b81d2393ebd237cf8a5bb7748e38d4ea4d440993c491cb768267153ed27ac1268e2aed1ac81376db3eb13233031c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x37c6428CA46E23BA90e8DaCC43312B39BA34E01F": {
        "max": 2,
        "address": "0x37c6428CA46E23BA90e8DaCC43312B39BA34E01F",
        "signature": "0x9bcc20ccdbe3a49ec3057a1305d8640f270ae4d634cb5b3a9f91da84fca902a655fa95dadd615838e26057beebea9b924db7b5ebe6874c08451a45558c87a5e91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeB55b65bD07C139c8026f7dbA901362A45FbB7EB": {
        "max": 1,
        "address": "0xeB55b65bD07C139c8026f7dbA901362A45FbB7EB",
        "signature": "0x3e03eb9e821888153197dda81114c408209be002ffa4f4d6194f4311ab2ea8087ce373605a9a563ef7bd5c08ec9ca3ef614020f605c53e8ca416cf18cc64c1ad1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4d99dD17D82D4a7B7AD6B234419C724Ab1CEc7B2": {
        "max": 2,
        "address": "0x4d99dD17D82D4a7B7AD6B234419C724Ab1CEc7B2",
        "signature": "0x3c8fa2c32c9dd141cff1902e5bf2acd60cd4560f864fac3a72960148b6f7ab7d2e1b1d07f0c5d74e0e3d2d2e946b2db85fd4a06d885a6dde9b7be774f8bf6af41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6c021b681FF049ebFeD8817aA0A762531DB9f896": {
        "max": 1,
        "address": "0x6c021b681FF049ebFeD8817aA0A762531DB9f896",
        "signature": "0x1b32e2bba89f20e2e0b74ca3696c85b4d441be005edbdb28c50e4f1ddc64eed16f77fb2dff11e6572797a6e328ce58bc718b4128f05f0188b6e37e23d4a3e24a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFBc902A96A3EC33FB2747E6AA766a72fE1952875": {
        "max": 3,
        "address": "0xFBc902A96A3EC33FB2747E6AA766a72fE1952875",
        "signature": "0xec9f633e6e43818a092e23d7820739954c39436610fe47ed632582d5ef3c5b3a3fdaac86bdec4521c65fb1e52ce6406eacfd4a9d656c60d9c76da4f4623c51eb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8a0a677d64E5A428BDD04C2A96610010835396F6": {
        "max": 1,
        "address": "0x8a0a677d64E5A428BDD04C2A96610010835396F6",
        "signature": "0x272f74080984303c87832ab26e8aef4c88e37a870ebdc744c42cd9f873198ef962b0f7b08fbad6466b7078209eccd6451130713bd856eddb4ff2123cd362c5e81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbEf075b0E7ab38A2752E30Ea282E1D6b50813C6F": {
        "max": 2,
        "address": "0xbEf075b0E7ab38A2752E30Ea282E1D6b50813C6F",
        "signature": "0xee16a949270d4cb3df906611622e3d884005d4fb2f5506c81079e244576c9af069ef9cc5080d83b257852d352f9c85d8ab2854f62dc331fcbe5063badf3862ba1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb1521b48F1010C77725B71D6Edb58Af30509f344": {
        "max": 1,
        "address": "0xb1521b48F1010C77725B71D6Edb58Af30509f344",
        "signature": "0x7945c62bfb5fc9e77415eddf586319efafb854b9bbf252acc1706a79517e578b2af16685c16bd8c0773c0e30d7ff51e5d908394d90d2174efd8511d5817125251c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x608755935b69e7d28B4891c81e91933a5935D2Cd": {
        "max": 1,
        "address": "0x608755935b69e7d28B4891c81e91933a5935D2Cd",
        "signature": "0x699b1ccddba581cb40f61cb4939889dfeed0bcde11c5a2e57582033cbf70b1ac3cbfc1ffb852a310dceba4b2de80e9124ed2f60284522717213e31eec5eb01a51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD0D8DBf1A7B2406b7A2caa213C93e3aa0Cbb1CEE": {
        "max": 1,
        "address": "0xD0D8DBf1A7B2406b7A2caa213C93e3aa0Cbb1CEE",
        "signature": "0x2d77f1749e6f437bf7c6948694d6954cac684f2c7c3fb865262b7c7b6f8246601094faae2e621463dd19601f8086ef54e0da074dccab615f94d26015f588d0fa1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x69cAbd69C1AD6A19EA0c85B59AB3Eb5631C20fb4": {
        "max": 1,
        "address": "0x69cAbd69C1AD6A19EA0c85B59AB3Eb5631C20fb4",
        "signature": "0x70023939d80c130e6178dd8885f16ce7a32b95d68463cfe2a9f3abc7ca86120e52274e2906cf2f39157a0b1edec0bf0a4de5d678b35946aa46770707427fc3361b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0fEbB3fb06D174167A0296c8Fb8709Ff468A182c": {
        "max": 1,
        "address": "0x0fEbB3fb06D174167A0296c8Fb8709Ff468A182c",
        "signature": "0xf8907642390cfa24aeaf86ebe82a856fbb85851085ee4bf03e8f2e5f9c48d13b3612228fb0e98d46f0527f201bba06f0461f66b2561c0adfc04b7060f75de0241b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x631c05cD587216C8c5a911F7ac433138deBEF165": {
        "max": 1,
        "address": "0x631c05cD587216C8c5a911F7ac433138deBEF165",
        "signature": "0xba50ea6409848dd7e98fa287c5caa6b032ec1d429e564bbc411b4387cd49752350d6ee0016844d4da73ddc6ff7e780032bf50176e952c83b83e260584d00f8e51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x949123f1260e50270e614ba5893eE3469FcC2115": {
        "max": 1,
        "address": "0x949123f1260e50270e614ba5893eE3469FcC2115",
        "signature": "0x051932f3d5a567e7a01d2ce5eb29d3d3b4316050320745587af019c96cfa2aac2ce5f8ec3c2653b10b18d49f3f927d0cda6a63e115a87bb05cd013a76b3c52e51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf5604687FC8063F8A5018eBE9F7Bc40f7ffbbcF5": {
        "max": 1,
        "address": "0xf5604687FC8063F8A5018eBE9F7Bc40f7ffbbcF5",
        "signature": "0x5709a61a67b34858efacfb5c164e929b122dacb74d2222bdd30b2e919f31f2384c86af6fe3358f1fee362821da62041bf6e543fd1d58028b20172328ed6b47df1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA055D89905A6425A4568B6e2951E4fcaDE59aAdc": {
        "max": 1,
        "address": "0xA055D89905A6425A4568B6e2951E4fcaDE59aAdc",
        "signature": "0x2e2781aa461c1c6bde5f049c00276e08d32edb0cb2200a61184c2da0dcd8f6325b6bf687c26e90613eecdf9675525dc9e73169524aff294e3ae0e771ad40859e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x07277d44F962aF34C11D57248c9357310F52A9E2": {
        "max": 1,
        "address": "0x07277d44F962aF34C11D57248c9357310F52A9E2",
        "signature": "0xdb8d66dc5fb6ec08ab3fcece65255ec9b0eb481ca220ea4aecbca84ce8a4f7981524a960d2b31ca967cb50471e33b509e9ecd3437b162d165e6ba2e33262edbe1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF6689918b8a29cf929eD2ee2C3aea1d65a907AF6": {
        "max": 2,
        "address": "0xF6689918b8a29cf929eD2ee2C3aea1d65a907AF6",
        "signature": "0xa3597d8076c16fb1908b8ca9c20a7f5751e204d532e9a865414bdb9b642b865e3b57ac9b0ed0855d1bb49fad009df763856dddc8bc12f7486ff843644f3c0bb21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x275272639EAEb5e0C4C607ba93b551336182754e": {
        "max": 2,
        "address": "0x275272639EAEb5e0C4C607ba93b551336182754e",
        "signature": "0xd7071a36ac002987e4eec0e0c2490d3c974bd17621752ec3f73a7de85c39fdb0458b8c35843e61f84bc0dbaa4e6e026ee23aa9948138c6a051b01b016d682b541b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x921482709cD7608d4a8f2890af623425f5d6aB66": {
        "max": 1,
        "address": "0x921482709cD7608d4a8f2890af623425f5d6aB66",
        "signature": "0x711bf57e9028db3f8fab7533fdaa967d6c678dded5a061d4f9a1953dbe86939a12a96447b45d28a65fdd6baab59a53248762a79c6f0985d78615527469e795281b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD8cBB1F93f9356A9260c7F454f09CA9130b6FD8A": {
        "max": 1,
        "address": "0xD8cBB1F93f9356A9260c7F454f09CA9130b6FD8A",
        "signature": "0x74fd4f72f0fe0287e0cc4a5eec8e63c953f81499be8906794caf0b30b979352e31cbb34f85ecf9f9e75635a95a5561599a0100b2a0d9f585a43b4023f80c9c701c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf5A1Cf1A7656fd1C2a40c539C6a355050489C620": {
        "max": 1,
        "address": "0xf5A1Cf1A7656fd1C2a40c539C6a355050489C620",
        "signature": "0x361a15d8103022ef3255f9dad54286e85cef62f621d38bd7b7cc546c3ccd6b7f0835aebf420767836f1f460a07249f3b315ef2c841e2e722dbb676f9a6afb9c41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xae57349a0C3507b180DF0965a64b8879d2656560": {
        "max": 1,
        "address": "0xae57349a0C3507b180DF0965a64b8879d2656560",
        "signature": "0x9dd0bd27977c2e035edb2b69471cda20044503b6b89936d01b66130545dc815c5396f779edd2fe10081fd04f56aa970cd4de41871985c2059cdb0af64f56cd031c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x127da83C62D97b2a7d9a43196f5F956cAC42c314": {
        "max": 2,
        "address": "0x127da83C62D97b2a7d9a43196f5F956cAC42c314",
        "signature": "0x1adecac538f496d3dce8ff57db4220813488e640b673fd73166b9403745c4489630b88042fc9d7ba44d07f7a5619082db8cddebbf3cec807b3c1eb6b65f0facc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5f312DF04b19979EF4Bd5876737cfa481A928C0D": {
        "max": 3,
        "address": "0x5f312DF04b19979EF4Bd5876737cfa481A928C0D",
        "signature": "0xa166944f96e746d676dab0a8edc38c4f39ef46043638d38e4961b92052b2f7727168851a26749baedbf7103b5406dae0a7a76d7e4e4e09d098f5ec9f0e755b661c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x33ccB9825E1d1892e06672014adDCe25F84F90d5": {
        "max": 1,
        "address": "0x33ccB9825E1d1892e06672014adDCe25F84F90d5",
        "signature": "0x5241f7bfe1c4b745d7611ec7ea4219a8292f38368c2b20ca3867a3cc9cc876657bed682d8bce39e5c6ec44f558d525aeaf1f5aec7fb18797d3b30b21ddfa4f201b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2441Ec9b3f20B0FA0FF1420e6835f45351ebFA82": {
        "max": 1,
        "address": "0x2441Ec9b3f20B0FA0FF1420e6835f45351ebFA82",
        "signature": "0x813e1a7e232558da43e619b80826ee1d83ea6eee1e1c3717feaf992ca131d981110cdea6fdf0f2e35be49ca5cc1930d7ef8bfbb74ed2c6cfab040807ae9026301c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB58418fFBf94A9c66F1F157021a4c1be243DEa90": {
        "max": 3,
        "address": "0xB58418fFBf94A9c66F1F157021a4c1be243DEa90",
        "signature": "0x3f48cd67d5eefc5085b316516d93024418066a0b995d5c07e68fdb174229077765fead59661fd06a1bf15cefdff6660b797e9f65c38cd8947c2e82e97a2568281c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb139151fd296003502D44210e469DDCfB785bf0e": {
        "max": 1,
        "address": "0xb139151fd296003502D44210e469DDCfB785bf0e",
        "signature": "0x41957ad5f50c3f0f076357b67baa6ab83d6558e2a19dc21bd12e3c13f74a3c3d0a10e57ecdd4868eed82ecfdf6be6698875ce7f03928bd1ffc067683062117c21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA8D9295aF65A0D4A803F2F6cC83CCD7D614E97Ce": {
        "max": 1,
        "address": "0xA8D9295aF65A0D4A803F2F6cC83CCD7D614E97Ce",
        "signature": "0xcfbd7f6f642bad21d5283653e9df6417df9b8daa8997ad61036c5d69b5f8fcdc09ea6232a79b23b7a17826c673efe19f9486b9493d4eb74537bb02c381b6f92a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x452ff5540b39326fEA93A8fD7C63bfd533B0C76a": {
        "max": 1,
        "address": "0x452ff5540b39326fEA93A8fD7C63bfd533B0C76a",
        "signature": "0xe62c48329a784321ca43ad60c30b3c37c5187f03dbc3fda55b6db7966a8d0fcc13a3c49670e9be29ec1bd1e912f576498dfc723121cb83797d6bd9233a9d4dc61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x67a420147cb82bc38c271dD0F12fA141ce954752": {
        "max": 1,
        "address": "0x67a420147cb82bc38c271dD0F12fA141ce954752",
        "signature": "0xb4202939ab785e3dd725ab26779da214e353f5dbd115871fc433f3b7584ec3be7e327f48d659cbe60698683f7560d35391a228b7f755cecc05c6a0e1c11aaba51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x93eEaE3e57Ae9Ee709755fce3d3b4A0D427BE003": {
        "max": 1,
        "address": "0x93eEaE3e57Ae9Ee709755fce3d3b4A0D427BE003",
        "signature": "0xacdc9a687c7ac3b13ee61ecb6b27c60aff833d92b8661d84258fc6aa39e6af4f33c427bb65485af9368cfd99abaf68f9c2d03f7d86f4aab15262e52dd38d1b261c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb9b2355417d03F3716b6427E6407864F0b634744": {
        "max": 1,
        "address": "0xb9b2355417d03F3716b6427E6407864F0b634744",
        "signature": "0x086bed3a2a9aa61da5d69e6d1c29814afa92604b4e98312b35c2bb67e0f77434062fc424459083d0f1dce0ea5cac2fa18df9e1e72dcc8241af8d2bfad22cb0871c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x417459E1c86f6e4cb23d7d2aB384e6311c4E554C": {
        "max": 2,
        "address": "0x417459E1c86f6e4cb23d7d2aB384e6311c4E554C",
        "signature": "0x0977aaffec46231ead192ba164fd20e249a3ce084a5f2dd8d2523f96e80a81a81ead92a68c003b9288879f95835c708cac3a54c75a4c856e2dc70933cab92afc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAF879e242B8002Bd98F8971885471364a393d52E": {
        "max": 1,
        "address": "0xAF879e242B8002Bd98F8971885471364a393d52E",
        "signature": "0xb620cf3cc07272cb6600c598725e32b7049c4d4e941e0da12ed34e296d7fd7a84600f931a40a9c55de1178596bd546dc30c007be375727f87224ae376c3301771b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x80cDD55197066cb264Cb4CD8c9fE9DAe757A540C": {
        "max": 2,
        "address": "0x80cDD55197066cb264Cb4CD8c9fE9DAe757A540C",
        "signature": "0x8a9bab49938468d86b94fb5778d5f22f016c8b30ac7c87366038ecf4caa679ca60169baf02885bec0c9da002020704a2dbd8b8ad9b5f1e4b64da04245e754aff1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1b27F965071AC1C84cCE03aa93F7089D00A4d449": {
        "max": 1,
        "address": "0x1b27F965071AC1C84cCE03aa93F7089D00A4d449",
        "signature": "0x37000d71ca3af5aca811860abd1e560e069cb222fa4b6ee7a1b46b39ca26da561377357502f7113f2a689dcac2ce2efb0fe18262c8bc8c8c0e4ebee14ece19701b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x684472BF2F46706a8184B54EA42ba8f85Ca302a2": {
        "max": 1,
        "address": "0x684472BF2F46706a8184B54EA42ba8f85Ca302a2",
        "signature": "0x3a2d09dee69e16acaf2017d43a2148213c2ae320b8d4144a66ec29e986fd83337c52a0f0bcd74aec35621c8d5993849f3c9a31832399a7f0e9e5068712d4f1671b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCb2DeE2E7c1EAeF9944988Df4CF92E691651c0eC": {
        "max": 2,
        "address": "0xCb2DeE2E7c1EAeF9944988Df4CF92E691651c0eC",
        "signature": "0x77b94512922eab725bf19d4713a05763fd9f52f607a5deb7b414247e2095d1c920b71ddc3e8e9f47fbb90ca1bf8b29aa1d1a91dc044d3336aed865ea720b52df1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBD995BEb135f947fCfbC081B9F4c774A9E80C9cC": {
        "max": 1,
        "address": "0xBD995BEb135f947fCfbC081B9F4c774A9E80C9cC",
        "signature": "0x64819ff4de34ae4c54885ce80b08b828859a315e1bc773e8fb0f9746f944c52e5a70af3b6125ab0cb286991b4724d447c9fc49ae3e764d654d5a98e538883fc91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0d11DFe2dBf2fbb9a145460cc33A50ef9D228d41": {
        "max": 1,
        "address": "0x0d11DFe2dBf2fbb9a145460cc33A50ef9D228d41",
        "signature": "0x154fed4637d9a75c82381f4eac2781e6973c47dc5f6a90ee16bb1238de32ee1e4e6bf1f8026b3d4cf05b77f4b3815ef1c69c6c67fc1e47a11d24229f1a3eebbf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5AEDe59255E77CcfaB97fDE9E4E914921fb245fA": {
        "max": 1,
        "address": "0x5AEDe59255E77CcfaB97fDE9E4E914921fb245fA",
        "signature": "0xfb733eea16533770a248ab6294ecbf74533fe342c76a154812a92b65cb935934140bd332fec6bd555179592302ffa046b16a70c9b40b3aa442d5fcc5d666459c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4bfccF3168205A0b376C44F5FFA8836B6E42F19a": {
        "max": 1,
        "address": "0x4bfccF3168205A0b376C44F5FFA8836B6E42F19a",
        "signature": "0x83eebb7aec4c9d2e9aa4ef8787ff0900fc2d1bef1c74feff74cdc56cf86a93782553fc1dac959e27d7bcc3677e996b24436b99363414bf783b9cc6e31d7a8c3d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7c82f6E4F53d09f108E729bF8e8ec1Eba4F8bd5D": {
        "max": 1,
        "address": "0x7c82f6E4F53d09f108E729bF8e8ec1Eba4F8bd5D",
        "signature": "0x77c7ed692e5c12c78f2851047e418dd0d4f9068fa6adb50101806ab41419bdd5006232f3371b577461706e5e637ba66de97d65eaccb018d6a3585bd04d63b8c21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9B39F19627203eE08e068Caad3369Be3183F2a6e": {
        "max": 1,
        "address": "0x9B39F19627203eE08e068Caad3369Be3183F2a6e",
        "signature": "0xf7714f8ada621d41dba022bf3df78df121004ed734eb21249ca47ec1f8cf425417d9b825ffc1e3b9bf7778813722e6dd7d6c36eff23b93f43eabcaaace8e8c8d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC8A2258d75006463A9435dA52c0F376F72e5E1e9": {
        "max": 2,
        "address": "0xC8A2258d75006463A9435dA52c0F376F72e5E1e9",
        "signature": "0xdd60d36dc2d4459486161443d7d64fa84cd61fe560a73d1ca84829154c356be30f160c8908a7f6b0bd52c5146d00428f05a6870b7f6596fdf29bb2e22d249c0b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA23fff253D8B51a2A8d93AafD4C58824004a9478": {
        "max": 1,
        "address": "0xA23fff253D8B51a2A8d93AafD4C58824004a9478",
        "signature": "0x3cf55b69ec498375310bb0e21c2ba691165330be96d1f7416c00249a54df5e0a1911132739f10f020bcee3263e95bd215951a50a1e4e0c0f17983acf942e3a951c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5E76B3C1B2BfBDccE33d353b3273C936272bb956": {
        "max": 2,
        "address": "0x5E76B3C1B2BfBDccE33d353b3273C936272bb956",
        "signature": "0x7afb2de071876265978513bd5534022f8f126f4e29c22db943f398190d6cb4c400f16031f1b6a2bbcaba8f8b7c43c1c24af2130fa04d31a787f09167538b32ed1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC1BD2568D4aE3D3aff479675eDE8903076fC764E": {
        "max": 2,
        "address": "0xC1BD2568D4aE3D3aff479675eDE8903076fC764E",
        "signature": "0x8ef2bbde7e6e0dc7bbc33e32ecdb6b6911341b84d9c3e5d7f98fc31b710552340c86eb1ed0b26b151dbed4206fc339630a9135e3026c1a4e6c134f627688979f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x917d48F59e9aB31eD738b4D314bAB1C2B5dd4A71": {
        "max": 1,
        "address": "0x917d48F59e9aB31eD738b4D314bAB1C2B5dd4A71",
        "signature": "0xb884c95f3af1bf96122b6782fb037e8c15bfd6fa2568c7f0fc1f5070eedacf55261754fee390aed6039aa9449468c40abde4f0efd4d0c140268c579795d78f091b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x428a418Ac60Cc38313072413e742248E8CDF3c54": {
        "max": 1,
        "address": "0x428a418Ac60Cc38313072413e742248E8CDF3c54",
        "signature": "0xdf91eadd2574b699863c797748653960f5d032c63dcc69d843053fa7dc26dcd71874d082f45a624aa740d8251d1d11297f8a7276a85b5c142c5af278ddc7c0cc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8182776c64e80Bd9C226432C2e92560b71bf530D": {
        "max": 1,
        "address": "0x8182776c64e80Bd9C226432C2e92560b71bf530D",
        "signature": "0x68aa7ceaade8a4ffef04f620a474a29d2e45a036d4d24423cd10bb15d9898c5a0adce03eec3b04f06f3f9220b255247932c3ed36c48b08c35dc33ddf04fca6441c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc6102e20fC45f8C0c8186A56Ba65cB02B9A8aECB": {
        "max": 2,
        "address": "0xc6102e20fC45f8C0c8186A56Ba65cB02B9A8aECB",
        "signature": "0x497085ae0f427ffee784451e8b523c78b75679bc93754621ae6e508e2ee0ec394380b0df8d2f5a7fa36cb233e492c38739cc54cadb05db3abde482ca75394a341b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa1f8272acE63CB008D8757a842376dc84891eb2d": {
        "max": 1,
        "address": "0xa1f8272acE63CB008D8757a842376dc84891eb2d",
        "signature": "0xe77c459c1c09dc9f60d3ebd4d318c8f6caa014cd5c7e04bdc26931c78b015e5949a6c41115c2954f1023df81af80a986856f26eda925eb8313f5449466be94db1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x66a91Fc46EAe4dAf5Ad46140d6B76F43c3F0fdAc": {
        "max": 1,
        "address": "0x66a91Fc46EAe4dAf5Ad46140d6B76F43c3F0fdAc",
        "signature": "0x76ee220768018bca0446f55e9e0b446c0df133e7c77ab0e677821ee96a55449d098bd36bd42843fb1ab1cb7d3a7a550b045e90c8c1dc0e45a14ac6c89e19df8e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x01c83cce1efeE75E89dc4c1a6197D8408CAB0968": {
        "max": 1,
        "address": "0x01c83cce1efeE75E89dc4c1a6197D8408CAB0968",
        "signature": "0x5204d422e236e7b7feae51bafcf4b75bea7113bf528f3e78a0577af5989aeb9f0a8dd57fe04e1d0bd572b11c44f08dc06f02b12ecdaaf9bb2b4add743d5ebe241b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x56A81623311D9aF88eA3eaDc2f7D6eCD367c982C": {
        "max": 1,
        "address": "0x56A81623311D9aF88eA3eaDc2f7D6eCD367c982C",
        "signature": "0x073fc9e5e92414c10b8183ed3a6cf2b7c94bf2a89bfdede4caca0ad49d6cdfcb692a20945bbb451b5d3f2b55f7ce791ece0e1caf85c1be16c88449cb62385bee1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x37d000df7dE09b3eA85c03029dB68d1b50ff2847": {
        "max": 2,
        "address": "0x37d000df7dE09b3eA85c03029dB68d1b50ff2847",
        "signature": "0x9f00b33a74d3ffad66c43be5501e360370d6d3a8237035ddd27afa1dd2f9aa124368407988270d1f8235c3f0c5eb4fc19aa2912c4680fa599fd41fbc7fa2c79e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc05C4B4189E3E8CAcAfaD406a23e24E6cEB32D8e": {
        "max": 1,
        "address": "0xc05C4B4189E3E8CAcAfaD406a23e24E6cEB32D8e",
        "signature": "0xbdd5091555c3f4de77bdbcf98bf6baaea8fe677f7059f92194d992a9c7cc0a3375145ebbb96e00ec9fe32ef2348d9da9abbdebc6acd5cfc6d10e04f207c47bfe1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc34a1E5B63aC19c4E08E21598bC106a4D5B094B3": {
        "max": 2,
        "address": "0xc34a1E5B63aC19c4E08E21598bC106a4D5B094B3",
        "signature": "0x4f0ea68fef6c5d1fb7e4e60c68f49deff335ed4fd1e62e80343f920be0c945655b1a57becad3823d797ff32de3b5c972fe234137c62cd03e19f7678b8cf828891c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA25977e9eC59b8a67AC1DbF305AC40CC96A86971": {
        "max": 1,
        "address": "0xA25977e9eC59b8a67AC1DbF305AC40CC96A86971",
        "signature": "0x1796828fbe24d0b35b94dcc47c9d172370a556d1e86d7277921aaff48b5ba5af14b55e2eb3e081b00d1a5a8d35d1e9d9a284982329c2283c4dde692bab80ee591b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x99DE55712B028D2fd62411dDa392d762fc924B7d": {
        "max": 1,
        "address": "0x99DE55712B028D2fd62411dDa392d762fc924B7d",
        "signature": "0xeb32200614c6e13330bf6354d9c1944ae7e639e62d6d500ddc70f862147d47477a965630926bdaec0bf2bc4d2a1b4ca6227997601005041b8aea0230749b2b7d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4460a4bD792585b7b1290A1e5C10A92D71d2d8f2": {
        "max": 1,
        "address": "0x4460a4bD792585b7b1290A1e5C10A92D71d2d8f2",
        "signature": "0x12a8f5a2e86d7aab88c06af0a81af7e53fcc2bccdccd285de0315db91810326b1b3dd0b63947b18dd120584ccf8ba05bf142eeccba793aa01215a4f6efea63711c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc873D1eef9779Ee8936Df848820f1D91Fdda55A5": {
        "max": 1,
        "address": "0xc873D1eef9779Ee8936Df848820f1D91Fdda55A5",
        "signature": "0x817c4a6806dd03c449d5efa99c0f5e91ac42d4e0884b639aae1e9105aa6800ea2c302e10d286741c8b588b8e0abb9adf3c589d8c4512796c691bf128c9448f581b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeEF5465a37D62CbCCdD21195d4cD7FA86784F296": {
        "max": 2,
        "address": "0xeEF5465a37D62CbCCdD21195d4cD7FA86784F296",
        "signature": "0xc7cc880524ab6e6d80683f34a2c1b7f3a314493cf1144e3e0312d80a40ecafc31026d6d690480d7fee61e12f710c3d1f23a53bbff102ada1a2c517716ae3bbf21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5F20c9f62aDC4db675B44556CC95434A5A39b29A": {
        "max": 1,
        "address": "0x5F20c9f62aDC4db675B44556CC95434A5A39b29A",
        "signature": "0xb94f19c4a1787b8f6a5e7826ac49544d289c548829cc2caaf301ae055c15b8c84b7219418fcee74f449f4812b46ceb2a3dfef9dd78177a212d7fe819467e260d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x86123D57d987EE48C33EC3Fe2A5b0fB6a11a6dD4": {
        "max": 1,
        "address": "0x86123D57d987EE48C33EC3Fe2A5b0fB6a11a6dD4",
        "signature": "0x09ab9a5207ce73a234c2b8cbd445a2fd95455c9518d7dc771504da75921c3af95a1f2f3362ab8c3516849e0509762dc5bf774ea569bcadd40632e1cec307a5331c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x90b73e9db9a4337cE331f10c71359cFeaB8899ed": {
        "max": 1,
        "address": "0x90b73e9db9a4337cE331f10c71359cFeaB8899ed",
        "signature": "0x0e6590bbd76d816511c801a3dd2d35cf02900ba04b46595fa32e42360b90bc573ef23dfca4e9c85832efb784443758975b0faff7a0ec4dd09f6fc6e0e601eabe1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEe5c214DA2969B40dc09fa5A43807EE24f6952CB": {
        "max": 1,
        "address": "0xEe5c214DA2969B40dc09fa5A43807EE24f6952CB",
        "signature": "0xb6bbfb9491d465b4f7468b7215ce69c533f3517ed3eaa4f40847e8cf701dc94e78951d7bb7de6ef8d5b988c619fe52ff48322720032e15f126e6a6ad06e3226b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf40d5ed37f438DF97517d1Df846837D13197552f": {
        "max": 2,
        "address": "0xf40d5ed37f438DF97517d1Df846837D13197552f",
        "signature": "0x2a2f582cd94cef88cf67928982fdfb4f99fddd869096edd9698a34dc956ffd431b334534e8b7d5f50192420925de0c5d9d4488e239549837f72b09e455dbdff11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x31a871D9AFf7E281c7d3006E2336A2fEF22E8DfA": {
        "max": 2,
        "address": "0x31a871D9AFf7E281c7d3006E2336A2fEF22E8DfA",
        "signature": "0x8618abc6a797b0b48a77befb46e17e0a8f2c3b2fe8b3698d7dce5fe5e09e76fb1187a1a023aadbbd32e322001fa703f5f94378dbf33b217caef285a3f6e8f9f11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x873784157aD08880Ef32D2b5931E40103a7465cf": {
        "max": 1,
        "address": "0x873784157aD08880Ef32D2b5931E40103a7465cf",
        "signature": "0xbdae79301dad4500d40a14aad62e82deca89e1c06dc7eee6760eca79b693c4a17755ca903191b4e34839327bb56ecc11614390532b865fac5c4e2a426c95eca21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf59A68fe824f66244C68cFE14e19880a8A3DD102": {
        "max": 2,
        "address": "0xf59A68fe824f66244C68cFE14e19880a8A3DD102",
        "signature": "0x56e9ba9ca6eb1277f418e33fdb87aa0d2b66fd675d102075a1d1661f8fd541fa0e3ceb181edac7cf54fd27c4a21cd9438294013dccc4f9d4c345765d4d628e851c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd9B869439f1eb9baa888f2482F98A9573dDdeD0D": {
        "max": 1,
        "address": "0xd9B869439f1eb9baa888f2482F98A9573dDdeD0D",
        "signature": "0xb57faad32edb8bcbb2492b575b2c505477381af4b06140e74bdc5ec9608adff46afedda3d5d30c859008d4198e004fbf7d06f8b9b14d2a08348b8efc605e45861b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFA0019113b63213D01FCEFaF772669Ee66E0D9A8": {
        "max": 1,
        "address": "0xFA0019113b63213D01FCEFaF772669Ee66E0D9A8",
        "signature": "0x0a5bd72f3398524d291df58d17189b19b6321f6c2eb8108e3def4826b5c5c93a1a8dcd1e2cddc843fd9bad9e58a375b37a7a0da60ff2f02ef45f43262e7992de1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x82a1D9ff7B66A912f268C5758f125886E9d0aF72": {
        "max": 1,
        "address": "0x82a1D9ff7B66A912f268C5758f125886E9d0aF72",
        "signature": "0x0c6929cb5a4310ba0b9d9c49b82cb7524731ee300e45378fccc534bf0a66a1893d3efcc03854427fd2466368c8122fb0df54d2b9d6091d2bbd4009c8ac4cd36a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x939CF3FEA33760Fedeb23808BC6758D768e26674": {
        "max": 2,
        "address": "0x939CF3FEA33760Fedeb23808BC6758D768e26674",
        "signature": "0x8fe2bef66d297f4b65ea949f080ab01311808d18a77a3eafa13ca91104aa98ab37bf9429ea9a7e7d3c747d99c7bf763d671dc299293a3b3afe2cef209625ee961c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF0A23fCa6863398627A8241CE0C480b10F1e2cCB": {
        "max": 2,
        "address": "0xF0A23fCa6863398627A8241CE0C480b10F1e2cCB",
        "signature": "0xf5af65bc734997118435a01757cd53515d6aa8fc1537105a36da1176f18badb0716bf4f11215e28f17484200dfbf2f58bb6919b368ead585a74447a423b9f0941c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe52ea7A90C169DAdE92234BDbCA11ea9cE936F4a": {
        "max": 2,
        "address": "0xe52ea7A90C169DAdE92234BDbCA11ea9cE936F4a",
        "signature": "0x20c20115cf827a3cdc58e50adfa52ff22adf7b119b34b36154b511792469933666ddfe95de42102b66e48a85367998aaf76f49126477f1df1df881e908da69001c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x37b28dE8b11ce28866D55C9E83EAc90d073B70c3": {
        "max": 2,
        "address": "0x37b28dE8b11ce28866D55C9E83EAc90d073B70c3",
        "signature": "0x9f138882d77fda1a152fbe07dad2ccc15a467bb2038d16df735db5b13479373d6fb042678e390de404180e479a51ca7417d1b2da5d785994b4877eab205987de1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3DE2D3bF4b7F9A6be8b796301277B8385b824Fb6": {
        "max": 1,
        "address": "0x3DE2D3bF4b7F9A6be8b796301277B8385b824Fb6",
        "signature": "0xf53d35e1728a8ffbcac920f8f8f863c079359791296daa0d58127ec66e34fb384f4656a793bc90f57a1b5b7bae982606c6e904cbfbdb0fb6aa2d87383998f9e21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x55E00265F075f336f4Ca8760280031a67c5c9e45": {
        "max": 1,
        "address": "0x55E00265F075f336f4Ca8760280031a67c5c9e45",
        "signature": "0x7bca16365fb5b7b72634d204a8226f4123fbb9c3fcab7c1e65d8d71b3ee1f66a5cf8b58c1a2e45633795e838e3720966b6bb1d2b96421373ae9fc3ebfb342e121b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7E160A9490A3C4303720f3935C61529686814313": {
        "max": 1,
        "address": "0x7E160A9490A3C4303720f3935C61529686814313",
        "signature": "0x48e652aafcb38c2b8ee9316e770661f1397c8c7a8eacbc3b6090f8ff42c071b9394060c4686cd4677ba63392c3a18b45907c0386524c0d5baee98af26d3db8311c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfE93e9Ce4f730a1c64846bC782E45904FfAA633A": {
        "max": 2,
        "address": "0xfE93e9Ce4f730a1c64846bC782E45904FfAA633A",
        "signature": "0x396b110e4c7fe12c49187f71e80cad15f369ea1021765f2c2898ae089a8cf2512565f621f2fe3b4f8aadad2d0eb22f0a2e6e528defec0abddc236e1e515c3b0e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB8097Eb6344225D3D160202058640fd0E6f018D5": {
        "max": 2,
        "address": "0xB8097Eb6344225D3D160202058640fd0E6f018D5",
        "signature": "0x8fe5bd5443532ea5e09ffaf765e515f3075c9c11dc930f19e3d85c4d27f668010938ba691e3027694da8c7ed6394e7b03cb514351584b46c9758009dbf1fa5fc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8721aAe749FE9c38d21cBea34f997d1A216E6909": {
        "max": 2,
        "address": "0x8721aAe749FE9c38d21cBea34f997d1A216E6909",
        "signature": "0x034b36c59159ffa461c2e9713c8110eeaffdb899ae766bad496878c3949f2cfc7c579668e65469e4c2be8cf1f57460157a4f21b7409a0571984ef8c40b0115c81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2D09b091922809a2b0Ead12866211fB4389A256C": {
        "max": 1,
        "address": "0x2D09b091922809a2b0Ead12866211fB4389A256C",
        "signature": "0x08600e24adf4670805c1aa59b55eac8a3da03991ec04606c362072c54c7f026268a5d0cf64ce3e0c808d5e058c9424114e868e3cb5f8393fa6434adb7d61df2e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0C22A0988930Ee7936de28dB49067d5AeCe3dA9a": {
        "max": 2,
        "address": "0x0C22A0988930Ee7936de28dB49067d5AeCe3dA9a",
        "signature": "0xfb011d4e5225817cd5e2bd353793220fba09461d8e0fdc1dbf3c6abc83e3bb3d19d3f49b9d4bc9bcb763f837ffd21855fb78010c90b13f1f970442d171d824111c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9a6dFfB91d69bB23CF3B1862298540ACe0eB3DC1": {
        "max": 1,
        "address": "0x9a6dFfB91d69bB23CF3B1862298540ACe0eB3DC1",
        "signature": "0x407af6358c1f669adc308fb65c4582801c0bcccb114e5d85a67bd713cc1796dc2ced04d45c0298f6e4c0579b4debefc38a2605c16b4de657ae592ed92840256b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x69Ef212c20BDcc9BB21D72FAB0ac7204c0351358": {
        "max": 1,
        "address": "0x69Ef212c20BDcc9BB21D72FAB0ac7204c0351358",
        "signature": "0x588562452b41f8b496fdd4e9d51891661c94a8013fd54e71072a12effd033d7c5e26f8aa9bf0f42eff810116a4c1d5fba5b11524c3870a364ef4c5b5488e0ebf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3238c83817958F0444C19Dbc9817cED2fc1256BE": {
        "max": 1,
        "address": "0x3238c83817958F0444C19Dbc9817cED2fc1256BE",
        "signature": "0x3a4c21ab0b313b6056032794c9b155a3225beb454b56bb9d7b8bc0b4c66cbd5d08038d8945c50b907f0a5457ce6dd8aa9863fb3a9731ae3c1269a54d5c5de1ab1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1A52bD34A5e7eA6d42E7bDc99d81e0aee9354203": {
        "max": 1,
        "address": "0x1A52bD34A5e7eA6d42E7bDc99d81e0aee9354203",
        "signature": "0xf34b83cbb4a685baba6235c1b6abee61f195e39112d3d5f5f4fc2aa677e7f1e764a2398a6dd1b04a549e1fa15e2dfa2d7f8b6fb541438de37abd0db7926da9261c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x287Ef48B18a6796dFa861463ecE8224E7978e6bc": {
        "max": 1,
        "address": "0x287Ef48B18a6796dFa861463ecE8224E7978e6bc",
        "signature": "0xd7716e9db00080a4a10a34fc5a0e1fdfff4b6c84cf61a2d754c17442129f0c684ffc223a9500467e53371721876994f181d51e55dd75b86c176fb9492729d0401b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x216B0f99C9eA6821D4d5389aA3327a4a495f45c4": {
        "max": 1,
        "address": "0x216B0f99C9eA6821D4d5389aA3327a4a495f45c4",
        "signature": "0x217b73db2bdda1481385de879ee74ad0cfa8266586fe2e96793c3acc6b2ed1005148f84c0acc4faeb47cf351f4bf52e5aacd7a8d43909efa2a84bc40b9a95a401c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3F848a4418Db4b85582df19BFF10f2F48ec65B4c": {
        "max": 1,
        "address": "0x3F848a4418Db4b85582df19BFF10f2F48ec65B4c",
        "signature": "0x18986ff48236f0b9d2498e9e77d7d8093cd8fafbab322b3265d83fbf564572a9253aa080c6e70f7e254931982261446e2778d514adcac51ed6a61d4ed40ede341b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa81D9Ae27cBe04a761d375Df8252f7AbB8F925Fe": {
        "max": 1,
        "address": "0xa81D9Ae27cBe04a761d375Df8252f7AbB8F925Fe",
        "signature": "0x0a9cfe81e54a6833a263dd9a21f6352c80347d77f62a017e74ba3256ad1f46ce3671f4666db6cac81b80e593a0d1427e5e5f075a56f797f1343ec35f95146c721c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD05539B85184b6EC7CBE810772E900a648322792": {
        "max": 2,
        "address": "0xD05539B85184b6EC7CBE810772E900a648322792",
        "signature": "0xd0c90f3cedac421cf08816d1f084ba788b87326c4debfd26f005d9fdbf41b87f6bf81ac624e8e43e4cb480dc150e00ac5fca5bde27727a5f59934014a5c7cf551b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCFB28F7A83e265ADDBB4a2ca7d6E53e89c80b4D2": {
        "max": 1,
        "address": "0xCFB28F7A83e265ADDBB4a2ca7d6E53e89c80b4D2",
        "signature": "0x9673e2886b8834285c544a44da18a8dcee11a3886963f79305d1c554a74d2a5366d13e534e4d7b080e0a6d5f2f93815de0ea9b03c6d6f086f2108173234f85491c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc6A944BA652c036BFc04EE37F69bE473623e62A5": {
        "max": 1,
        "address": "0xc6A944BA652c036BFc04EE37F69bE473623e62A5",
        "signature": "0x7c5f33e4896d894981d13cdbe06069d504c3e2b92cf62ee331daec238952777832e967c65a95ba60febbb03ebb1db7704fc4c5c2729ce80c8de0d38d9663edab1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6bC4F5aB8DfCf2f7b3c9452da1856eD16309aaD1": {
        "max": 1,
        "address": "0x6bC4F5aB8DfCf2f7b3c9452da1856eD16309aaD1",
        "signature": "0x20966604d194c6053ebf6ccc2bb679d6bf1bfe8aa7ccbb2171317d8d1e4d54e14a02b5447d59adf737777a35c4e688f7ed733956d6fff208235b449205a4143d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2933de4946B0De93fbb054b48976AdA0560FC005": {
        "max": 1,
        "address": "0x2933de4946B0De93fbb054b48976AdA0560FC005",
        "signature": "0x08bcb94f8c5e5ed1e6639fd60c753b8ef208d084c0b127ea63373d41849d437d74850bfc2a5e5ed94aae58ad88546f935bc6972a99fc651439891ffaa8f294681b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE007604D44d2D62462e8EE2975BE52B4dbD8cBd0": {
        "max": 1,
        "address": "0xE007604D44d2D62462e8EE2975BE52B4dbD8cBd0",
        "signature": "0xe603bdb1259686966ad557df0dc1623d7160fee71b19257b414a0a34c94e3c6233c7e1d662e93081a03722ad002a2b5fc92b060761c487bfa141dbb299f455521b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB7Cf33E2c5b3fBf95609BcE4B89babE143549495": {
        "max": 2,
        "address": "0xB7Cf33E2c5b3fBf95609BcE4B89babE143549495",
        "signature": "0x9e46fc03a6effd793c509e24d419b8025fd162fc1a47551fe2ad274d9106446c2d8e089b9537a2b909224a15990341622802750e90c07da5533274952c64653f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x108A37C2C94Eab5FbbCE118aCfc5B103d366B271": {
        "max": 2,
        "address": "0x108A37C2C94Eab5FbbCE118aCfc5B103d366B271",
        "signature": "0x923afe6129d8f1669a99fd3e102ff8269aa5aab936a24da4748f109cd3f336ee4e18d77fddbfae4033d552630137b537ea42c342ca14ca7890ceb2dafd35151e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3E3Ac465F2D35E679c07Fb724731aFC08b65e8f5": {
        "max": 2,
        "address": "0x3E3Ac465F2D35E679c07Fb724731aFC08b65e8f5",
        "signature": "0xcf1860f773b8af0bf59e0fc2a39925a998be9cf3746e1b58514db6eddf880f0f1ccfeeba691849bc26a563648a4b3f20e00a7bfaf5c73e540c95a9fc2dab16c31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x347D05A51b86273048311EAdD44c620b06CB8ff2": {
        "max": 1,
        "address": "0x347D05A51b86273048311EAdD44c620b06CB8ff2",
        "signature": "0xb520402469e305cf0abe38bf1644002c0b6993d5ab226a9250379b3bfb1031eb7679b95d679cc71e2b96f8d4688421813d71edb058248cd3cdc529e321a2eac71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1FCa55657d807DA6982768151993f5dA672872E2": {
        "max": 1,
        "address": "0x1FCa55657d807DA6982768151993f5dA672872E2",
        "signature": "0xdb5584646c186998b4ae743dccf2b7cec2fdc74ebd6bdd8651518cd22c60db411ac33f42359a201cfb88cb860351eb91bfd86f117aafb420b08e10effadb661d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x66F088017516317a2Db9481f05199d07d6152E01": {
        "max": 1,
        "address": "0x66F088017516317a2Db9481f05199d07d6152E01",
        "signature": "0x34661c7ee2dfae4d052b3537c5d65fed580aa6e5fd3c46477ab5fd6f6047ff447464216204aab5e2d772023801fc18538d3c3e6e33a606be94e9fe56bdc87b481c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4915fe6aC1F1Eff7Cc50C04309D981F4C01dd536": {
        "max": 1,
        "address": "0x4915fe6aC1F1Eff7Cc50C04309D981F4C01dd536",
        "signature": "0x986161622389c8683655ce5113b5db4c925dea42ddfdc6f9912256510b256908193013ef1d219f82967c05939c62530786fe78942429fc4ea5a9f3cbb2343ea01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x14B3EbB35dFa72721f8430f9206A927A5405b723": {
        "max": 1,
        "address": "0x14B3EbB35dFa72721f8430f9206A927A5405b723",
        "signature": "0x9e8181049f2f42ee353702ceb3b8b2d16f1d82bd1d2c36678d0e58dab2e6f3ea689011fc84432636d294775acae26a522550c1d12f7addf8f762830320cd4f7b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9fDf0a84a2b120B0c793eb40D713fD80783E7cdd": {
        "max": 1,
        "address": "0x9fDf0a84a2b120B0c793eb40D713fD80783E7cdd",
        "signature": "0x3e3b5665522e1597e160d5638f1912010035e8570c85ea74a3a41743c120e9b738c19a42099e4a0247ac45a6e0e3315279a87476c04e36e5e1051db7c2b2f2001c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x10dBe487F09D96663BbE6712500294a7C07755f4": {
        "max": 1,
        "address": "0x10dBe487F09D96663BbE6712500294a7C07755f4",
        "signature": "0xe6790f885e92ba22ee95a59f06d1bc9bfa6f39b49a07a4d6499ca99ef68d7ef528d7969f9c6958a016706883e50208c1e396cc71138d71c0a769413a1335af5d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE54C1880e9e37D4d310CE70514f17f5CE6222b98": {
        "max": 1,
        "address": "0xE54C1880e9e37D4d310CE70514f17f5CE6222b98",
        "signature": "0x8542612ef43e1ee961f1fe0c02ccebe93c4ecf151b2da7d6578068583977775c4f099c05783d7a6b6241371a6f69a195d445db6c23027d53bb074ae482244fe21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7bC3A3bf54Fb122533EbA814e11a015Ae2361822": {
        "max": 1,
        "address": "0x7bC3A3bf54Fb122533EbA814e11a015Ae2361822",
        "signature": "0xd246e8b9e8628f019681d9a80fdf40b129c31a810816b78fc86350901515058a602d5947be7220bd1b6911b715eb815267e7273d93ff51bcc1043fd647eba13b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x33409703a73575548A75dEf221d3DE1c46a8ec2c": {
        "max": 2,
        "address": "0x33409703a73575548A75dEf221d3DE1c46a8ec2c",
        "signature": "0xbf133952945fc1dd8e1cae59adf303b81c65d950cb8a180b7b9713146dec8be757884e0fe81beeb35464e0348d163530879bab7955040cd4561efb9937c046d81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x35b85036be9FEb8F14634cb30305d2cEaF2bAF32": {
        "max": 2,
        "address": "0x35b85036be9FEb8F14634cb30305d2cEaF2bAF32",
        "signature": "0x9abb1360b56824ccd3aa696c53f4655cbb8b11c3252aee119c8f5d14949a4b1e723aec0c7456c9b0ad23a90dbd127072721cba983aa09319ca83a0f75d7624e31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x408e172c8c45C7Bb7190258Df645a9553f7062D9": {
        "max": 1,
        "address": "0x408e172c8c45C7Bb7190258Df645a9553f7062D9",
        "signature": "0xf370be677d8ac71134d3daacc56fb8ada588d3a1c9e9c62194ab5e9465278bb461cc48c462a033857a1e9f9a83fdaaaf32ed8692a1287f676770aedd0c74f95b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x46D747C8BF871c0363209f64038B860b8c00B44D": {
        "max": 1,
        "address": "0x46D747C8BF871c0363209f64038B860b8c00B44D",
        "signature": "0xd3b6bc5429e5787b57173c07f4f7a8682cc037063a98425aa19010c2a1036a54305784973c1973b0ee72210e9146ca054f1cdab1a1fe3c64b62185d791a746ab1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0ed2E0ecE0596b58ED057949C2764A363DEA075F": {
        "max": 1,
        "address": "0x0ed2E0ecE0596b58ED057949C2764A363DEA075F",
        "signature": "0x26987a868312ea1326d70514d784ff86dd3890bbe8bffb7e4b570e22fb6c006f5875f9cde0a6a1c86387a344e1197a5bfb55541edc824ab2c67a2447b326f3771b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbAbf3680751E317A886FA22bc5Ed3f13bC8AD8CE": {
        "max": 1,
        "address": "0xbAbf3680751E317A886FA22bc5Ed3f13bC8AD8CE",
        "signature": "0xa306e6b7758541ea7ffce7801e15a99c567c4b36966d7719b8e857182df8112c13a6b840159e4249df70e8ce8148eb0cea5d1c9b0a3cb01cc1bb107d1ece42d11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5427468261c7137D304686bBAa18021eAbcFCA0d": {
        "max": 2,
        "address": "0x5427468261c7137D304686bBAa18021eAbcFCA0d",
        "signature": "0x6c98607746156850cbcd3efae19bc7224536d15b85afb761ccdb933c4c8378dc3f8215bc298084f80e778295b47255111c3ae1df85c2a22bc3bd95e3053e5c201c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF129B46d7E3392a829a6D6921b7153e4375B9f77": {
        "max": 2,
        "address": "0xF129B46d7E3392a829a6D6921b7153e4375B9f77",
        "signature": "0xe2f5deccc2dfacb6726e7115f53e8b71000537f69b81e439cd72f9691690338b5a6e47e78db3bac03a61ccf09fa94af38398e54b94f919d89979c20bf5992e331c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x156f3116488ed4681C748C3eeEca4913FAfe4b82": {
        "max": 3,
        "address": "0x156f3116488ed4681C748C3eeEca4913FAfe4b82",
        "signature": "0x53492d27106993dd1439f814338fab85e1a8702e48fe2af1b0e07603bd023dd1262f39aca75c81d4afe47bb718ef43c9072c9aa3f67a6a4d654fbffa680acd381c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x25F2827D87e261B02D1429066817ee9A59a0324d": {
        "max": 1,
        "address": "0x25F2827D87e261B02D1429066817ee9A59a0324d",
        "signature": "0x72c998226976f04754eaa24f4a4315fb10551b2252011042c0284d6f26404ac638903aa92cff022d633b60ed1a9167e87d51f11f8c72d47cbc1e78f0f0c32ded1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7719629CAeC6296FE70a94bCa50C5f412814997b": {
        "max": 2,
        "address": "0x7719629CAeC6296FE70a94bCa50C5f412814997b",
        "signature": "0x3a7cf41359d0f8024071faad608b4f50691b203368642e58790db200568dcc0517b5e4031d1b765f976428930dfa1a6b4cceb2b7e0bc593be7d1d4a73f8d15621c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF018BB296F087471bDB9F70B0F4f2b040bCfD749": {
        "max": 1,
        "address": "0xF018BB296F087471bDB9F70B0F4f2b040bCfD749",
        "signature": "0x355d1b68ea432dcf712576ceaba458370e11a8e898e419daf30f7427fbfe63251be6d0141f83989ede44ad0716b12655b594209290a757d900fbd85bfcf39cba1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFc4214E2eD57aB7b0Ffd5f376cF5d31512222DB3": {
        "max": 1,
        "address": "0xFc4214E2eD57aB7b0Ffd5f376cF5d31512222DB3",
        "signature": "0xe7bfe544d2625408c6f28dcc5716d9d9a17f45e577bd98e1c89b33339f37d33a60cb855d0bfb1e7bbcbf88b7715b860c4879cad1a7736cf7d07690481553d2e61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcEB1f94347FBc5444fe0d8f02470C39Cf8C76893": {
        "max": 2,
        "address": "0xcEB1f94347FBc5444fe0d8f02470C39Cf8C76893",
        "signature": "0x0943f2e55662667d3e6bb2f4b5e5cf9f3022a2e5efab5a698b466c510eff0e5d56270bd231b1e232535529e640a76fa996683af90b2a353c446e24de341ced2c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8a94B1CF04999e155118E033FF77cAa03FeC7d31": {
        "max": 1,
        "address": "0x8a94B1CF04999e155118E033FF77cAa03FeC7d31",
        "signature": "0xe93cc8b001c5a00d8e96eb8605eedc67da04f8e8173d0b1b7ada495be31941f15d95163c295e676c9148f28de050fb5009acc16cc7d2343500e9105ec0e7e6761c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1070E379FF3B9CAb9E3a7953aCb034349Ec92097": {
        "max": 2,
        "address": "0x1070E379FF3B9CAb9E3a7953aCb034349Ec92097",
        "signature": "0x1c94a8df2a5547e93e2209add8f0f20406eb23c07aeb1e29433b003f8f2999344f84a67d9ca022db1e0fc76618d2c96aa75c92cb077738938ab19390a07db4d51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x97956FCcEF9AFFC8fFb0D2a6C35a3b8D108F048b": {
        "max": 3,
        "address": "0x97956FCcEF9AFFC8fFb0D2a6C35a3b8D108F048b",
        "signature": "0xac148f470b542756804415e9b57a903e216d29504c9678862e6519b0ffd4cd974ac218d2da3e5653da42231653e5135df00c0c7a7f5ccbffd83b63fb7d3004a81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x914a032C028a94cf1090a4237D7d049958140985": {
        "max": 1,
        "address": "0x914a032C028a94cf1090a4237D7d049958140985",
        "signature": "0xe98530198f7a7b47b64c8eb72587754736a6fe8777bda75dee1111c22b59a2ae6664ae455adc742d54cc69d36e6ecae48d828f111c72b454c403f4fb83b3a5061c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6b85B192C912ebd7177AEEdfD7D924977156fD33": {
        "max": 3,
        "address": "0x6b85B192C912ebd7177AEEdfD7D924977156fD33",
        "signature": "0x5c6df35a94b0cbf5cb6a0ea36e0cd3989dc988aae17c6a0e6c10f3593f10eb61003a49a7168d4e39a0b4549683c3a6b296abb108731163605d0064cd779c6bf61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9deB1b8062a6A319c8928E0506BF00524b7Df08c": {
        "max": 3,
        "address": "0x9deB1b8062a6A319c8928E0506BF00524b7Df08c",
        "signature": "0x07b559b624a8d08d32c26b2c3e0218756806573a5bfda9053db0795136be2ebd22408529b1e3d68b2a0c9457a6a42f2598e98c7f66749213341742c923a907841b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x151579815EE843242facB0FF2F86391c1bF65c9f": {
        "max": 1,
        "address": "0x151579815EE843242facB0FF2F86391c1bF65c9f",
        "signature": "0x9cab95c2623bd66accb44fb67f0a00d5a445782676a4272d0efecd951021eab03b39191a84fd1fcb2b44894eae0061d210e91babd7ac932d43e1e7362b567d951c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDB74749397d10764F55407a142FB8888f4e96768": {
        "max": 1,
        "address": "0xDB74749397d10764F55407a142FB8888f4e96768",
        "signature": "0x64c64d1f24ce174170cea0ecd9791917547314213b821d5bb5e15517352b692366ea1e95f2eb84a20aa2621128b27060ac5d4932722aa3b0f329f1c3505438921b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xca29916cADff6541cc05250b37cd2a8929c1Db44": {
        "max": 1,
        "address": "0xca29916cADff6541cc05250b37cd2a8929c1Db44",
        "signature": "0xbc39e01d769458812b9d39fd42feffa839d26f974ecebc4ddf3409bc676144706c8210f0e3082f14ac721b5962346c8edab1252fa792cb9f9673f7c780767fe21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd090F3a9294DBBfB164c5c87C2Ec0C5d5Adcef81": {
        "max": 2,
        "address": "0xd090F3a9294DBBfB164c5c87C2Ec0C5d5Adcef81",
        "signature": "0x6dfe4445a279e0b89dc8cc63d59a6f41671b951a184727b43082490682b269d814895dcc3e67101368cbe36e3289af312472a0edf5606732a23f30cf7896076e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3be4e18ceE8E7b63ba4B7F67aa8beDF189C63255": {
        "max": 1,
        "address": "0x3be4e18ceE8E7b63ba4B7F67aa8beDF189C63255",
        "signature": "0x4e28e16f3e77df0259f9efaa91ba26b0aa1dea10bd521397ed18a4e743053fcc6d291aaee0f0028bc7d632cc7a0ea3c8e456b74332c6dfd9411544dd06f192491c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcFc5Cdff902679F47AdB5c8DC20f6FC88287e97B": {
        "max": 3,
        "address": "0xcFc5Cdff902679F47AdB5c8DC20f6FC88287e97B",
        "signature": "0xadfafa77de60e5593e0af16c843d718108f2426d8c60f3ac9c558f14fab0c99e4d72d8a4b1e27a92fa776a6889499beb2cefbbaf866d0c0117ab4e7d4acee4c91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x39f73B8be01fbF0ff1fB05621b260d287B9D5D7F": {
        "max": 2,
        "address": "0x39f73B8be01fbF0ff1fB05621b260d287B9D5D7F",
        "signature": "0xb11d4157c8ae8149f4fb725bed1065f0f809ae956c19d67b0aec50c3f2db6b643e715c4202fa6ab932b438fe3e4df6bc939290a0e19fa754574d05184c65afab1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1e339F6493A17BDe08D7B5c3F8B2567C44800B08": {
        "max": 1,
        "address": "0x1e339F6493A17BDe08D7B5c3F8B2567C44800B08",
        "signature": "0x071012c0a151890dbe19695de24b8a9bd916e357a0ae67aaa630248218c6e5323b260c543a720bbb547df927923e5063a6cc5d87d5b985fd02a7f063b327be031b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x12Bd613282B3C0399e8AB0607AbA3dd4c6397d12": {
        "max": 2,
        "address": "0x12Bd613282B3C0399e8AB0607AbA3dd4c6397d12",
        "signature": "0x9197abfdc01f70126de3a4950e9cb54438c1da7a3ad4ca7a71c9a0954885364271c14bfade45e9bdbf791949afbb2ae8f42021f224e5dc7ed779a38b6b5d61ff1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1e0e8FF0406501fe793C3a356413C49163Ce5c02": {
        "max": 1,
        "address": "0x1e0e8FF0406501fe793C3a356413C49163Ce5c02",
        "signature": "0x0ceb18dbc969bac2eac50df37a5a48f93254883e79253cb968626689cda67bed73aea074491b60b3533aa3530dcc2a872141060bf2d624ec07c13077fd6e378b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2Aa25F4b9f7Bc7D2725Ba9611977c4b640F6bF48": {
        "max": 1,
        "address": "0x2Aa25F4b9f7Bc7D2725Ba9611977c4b640F6bF48",
        "signature": "0x87a286a99e89186493aad06e4b2a3fcf4e1da8151c2687c3cde959a50bb9eb3b5d62a4467e86afe3a184b64b103b38840cb31c7e587fdacdc25d11c935505d0a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9CD5a3f2EbB62a1E6A6F97edb2042450661d61aa": {
        "max": 3,
        "address": "0x9CD5a3f2EbB62a1E6A6F97edb2042450661d61aa",
        "signature": "0x6bdabfb78f72879b7d5e963188b575c17b058ced9d13f67366ec5067946589892a7f836ccc4048d79fbc21c1f740d6062298b5004e109c83336c44635fdab4791b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x11290e5C348568aFa1b70C9F7c430c7d38C233c0": {
        "max": 1,
        "address": "0x11290e5C348568aFa1b70C9F7c430c7d38C233c0",
        "signature": "0x6b61683c12a4d6619ddb3a359b706ed33376776f9844f0fb1f95e8409f0b58ff7865c5334f45e7e6105225d04b3a23429c65e34e139581c50e8e587db4abf0551c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6374e6b71b0dbecA0B995b1039b9670128958D6f": {
        "max": 2,
        "address": "0x6374e6b71b0dbecA0B995b1039b9670128958D6f",
        "signature": "0x259cd55731359247d7148d85d0857b0cbc58d6cbecc9e338321ea1608b6cbc9e677a6944150df111ffabe30956ee2950970dda0f711d0302a12ec6bafcb20a421b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x29A3cf618b011221905131969242E0fA514DFF9b": {
        "max": 1,
        "address": "0x29A3cf618b011221905131969242E0fA514DFF9b",
        "signature": "0x8c74912a7c7e2d3c672d7269947e1234ceae4c372c8569ae8e62da678c3b3c89062b187aff4ad3a153eb8ef5420c5218d990ba51be854cd88f9d90bac17549991c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x73EA036e89c0D8a8daA9b8FF97b210FeCcF54f4a": {
        "max": 1,
        "address": "0x73EA036e89c0D8a8daA9b8FF97b210FeCcF54f4a",
        "signature": "0x8c5424b3f34ca21bf4f6e058d25d7dcb930a2331282bbeeaa10151d60efc6acd01cc4bd529dcb0ec1cd8fd2ad82574834bee20f9a7a3b274db26ef9e98ee0fd11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2D5cA547aa8F8fa5796174F1672bc9435Ccf0c86": {
        "max": 1,
        "address": "0x2D5cA547aa8F8fa5796174F1672bc9435Ccf0c86",
        "signature": "0x0e3644617a5072e6f6cbfbc416689ffbaa55b0abbfe887eccad81e0f07327d0d2c7f64dbb46979df21b5fbbe15344d1d71fe34480bb0ed5aad5cb9cec519d8ba1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAc36c85D94f2e16Bb6fF82e6345079C5C65033da": {
        "max": 1,
        "address": "0xAc36c85D94f2e16Bb6fF82e6345079C5C65033da",
        "signature": "0x7c60062a5f6047412582913c8064e01f1e89fa46755f7071bf41e776879b9db144989ca4232d5101cd9ea4d78ef5b9382f54cf03ab8d049badfd73879c7e45731c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCBCbB480D7151551035442CbE7AC85d2f69f672b": {
        "max": 1,
        "address": "0xCBCbB480D7151551035442CbE7AC85d2f69f672b",
        "signature": "0x8bb6655765b562f8a9f5e3b5655b90ac28b8cddef120e7e278472ed73d4ce55a6a7ac327f31e2e6dd67b208e20b35647fdc0fc3b05b94f53ce0b58c85dd007001c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf3484ba7B426E8fdAc162d998307FcBABF4e693c": {
        "max": 1,
        "address": "0xf3484ba7B426E8fdAc162d998307FcBABF4e693c",
        "signature": "0xbf0142e3b0f7714ed991e6689e678e754b040db5e93230d69f29c56b2ba7338a3c45cc0ea88d7f60f9c59ca1aa49febefd0fe052f316d8bca3bb7850d1d8ea8e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbDcdD0615A8eC10d3c981360E3d8ff35Cf66E121": {
        "max": 1,
        "address": "0xbDcdD0615A8eC10d3c981360E3d8ff35Cf66E121",
        "signature": "0x53fc2c13802633e808b1bfb2fc4c6f6a20029461c1c3603d4a7f5992f6d9dd132619cd9328c3a264c95e543365575c3ee47c2480b9cf97e33f8fee4d90a699761b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x10BC2E81704086285fc701af155658DEa46b263C": {
        "max": 1,
        "address": "0x10BC2E81704086285fc701af155658DEa46b263C",
        "signature": "0x197c46fea5bc2db2fb4888489f1e7d5b7a83fcafd93fd278db5bd38b29561bf2640f01fb4a0d79b4e48fc2481679da1578a86297d7d44512d04b11a81d8101d71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x592975a846727Cc6926A54A2195dd45eAEFa5631": {
        "max": 1,
        "address": "0x592975a846727Cc6926A54A2195dd45eAEFa5631",
        "signature": "0x842cb79185837c2922b54b51edfce1c30dcd1dbfff549dac680167884b735dd862147060ff1b8588df3e2f4d4d15511932da1949e8d99f7f5f5ffdab8377853c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5F353710f6207364D34bE9328406e23Bea5c7191": {
        "max": 1,
        "address": "0x5F353710f6207364D34bE9328406e23Bea5c7191",
        "signature": "0xf23b0e2f33c35121106a7c4a351367ab30e6e514f1c23e9045b57ed3d4a8c8357fbada27ab6e30583fc28258162d73481ecccda85c419dfc20a23000639a55691b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x53ad9cF96f4fa5d4a35fF7F0e114EaBC7292D636": {
        "max": 1,
        "address": "0x53ad9cF96f4fa5d4a35fF7F0e114EaBC7292D636",
        "signature": "0x7f8a257c499b5903ad567a031c740f37e3389cf08c69fce7e13331279eefe89612905852cadae53ee32a7a9d12a0af5ce3ba48986e2c686c32b9e1d8d0f91fcc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4f619A294b9ef860eFA0a56B8924AD2e254ab538": {
        "max": 2,
        "address": "0x4f619A294b9ef860eFA0a56B8924AD2e254ab538",
        "signature": "0x14e2b7b2014f45fed77950a191ca0e613f53cf9587edeb5ea73a7569ba4e918a53e1c067d2ec5f2593ad6e72c4dfd6f445c20950f21da4c27d5d02bd70ca174e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x15D19e3F3af0D4ac2E0891065c2724C8bf98BBB6": {
        "max": 1,
        "address": "0x15D19e3F3af0D4ac2E0891065c2724C8bf98BBB6",
        "signature": "0xb6280ea148870e7d06c8ab3a96827965602f95ecfb80152ee9109d3724c306737a11203db54f5d01fde77f07ebb78ba90fea786d4c968a501fa27e9332c94ddf1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDb13E2B713e935180623a7F68483D9941001ce65": {
        "max": 1,
        "address": "0xDb13E2B713e935180623a7F68483D9941001ce65",
        "signature": "0xeb16c980feaa76e26431ae12babfa64743cda6dc7ce9c38721516605de58f3fe140e465c3264ffe74d9c8b861d2cce854d3f7d3f61d666911a1f5cc0fe202a451b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9fe5E263b302510b8950ed48Dc4fB1d06aEa578c": {
        "max": 2,
        "address": "0x9fe5E263b302510b8950ed48Dc4fB1d06aEa578c",
        "signature": "0x31628a88fd9d5b22492b71369a71213f5a11b0f3b1cd891f7065d8dec99f69e60a86488d2e2a0455dbb66ac604527a1d65d3704653969d69c5e78f1b2f3871a61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9aac6AFEc792f54f1F780E7bEE1A6fB667Bb6C36": {
        "max": 1,
        "address": "0x9aac6AFEc792f54f1F780E7bEE1A6fB667Bb6C36",
        "signature": "0xe07bd1835ecce9a7e2c3a5ecd7dcf4d81026a0952afeacef1c980aab9e198d675ebe4214eb808c8b29bfc8fb854438c413814a67f47ab278ba5aa1758a75cf891c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x07A17E4413F6e6d2730621Ee0714C515408923aE": {
        "max": 1,
        "address": "0x07A17E4413F6e6d2730621Ee0714C515408923aE",
        "signature": "0xc91f0b81efc51bf3d777ff1f905729a0eaf3351920423a1f9bbe1c3e1e8a2e905ae9b79a31b356cbf55c25f44d5a82f0301499c22ee3fe553880424cafc808f11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x90de76aFb23593a7988d98D027E6D00e3fA4EC2F": {
        "max": 1,
        "address": "0x90de76aFb23593a7988d98D027E6D00e3fA4EC2F",
        "signature": "0xb9788227310bda1eb1d877420e0e22965c0c92f6277cad578c53cfe035d21238797e2d417074d37f617c74c92faf0cab00b13a0a7451f64a6c2dabb2696f09e61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xADB040F4F27eA941BcCAC56B763f577da2eD0311": {
        "max": 1,
        "address": "0xADB040F4F27eA941BcCAC56B763f577da2eD0311",
        "signature": "0x12ef4fbf716c716655ca12afe09b92ae084917b5251feecf3d09dcb12b11f9be2d1e9d627f3fd399ea13db7ea1b4622b3e41684d9909d15b1b02ffcf21ea455b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFe6f001EDcECf20febd0d5BA1E2a59AecCEC0336": {
        "max": 1,
        "address": "0xFe6f001EDcECf20febd0d5BA1E2a59AecCEC0336",
        "signature": "0xfd0e4bbb4b993cef8af116a038115b9bccc6392dd61029cc37392ecefde5daf1666c9b6bb4a2f5f56d06eea30a46c27cf2b5f344c04dccbb84bb4f3deb46efe71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x00F73159a9A8981052CABE3730C0d707dB4A3C0c": {
        "max": 1,
        "address": "0x00F73159a9A8981052CABE3730C0d707dB4A3C0c",
        "signature": "0x259b6bfedbf73b59b5727ed10ebf53fc04d8a2fce856bf7f8398e00e36b0de3e1392ac704cfd398abee1be58b93fba7531c672b57243e21999ad8138448613ae1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7B008d408319C4Fb635a06B2cC5250b5f35333e2": {
        "max": 1,
        "address": "0x7B008d408319C4Fb635a06B2cC5250b5f35333e2",
        "signature": "0xb921653e2a7a6fc6de7766eeaf7a5facc03a4a3937648b5d779ce015fc0d061556bc2f9c2c1a847646f181d37ab14d2fb7ec0bf72457ae0861b9b6a3781e630f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x77FFf5d88e331c8945a8FCE36F4152B8DC00e3eB": {
        "max": 1,
        "address": "0x77FFf5d88e331c8945a8FCE36F4152B8DC00e3eB",
        "signature": "0xc769f6c5ca54282f83439c2eecccaee182d82dfa3f7fd7d8b42f7264a6eb2ce14ba081feeab78d744935ec5583b078ac0c93248e3bf8da4fbcc42e04c85f88551c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x25817174e3Bc0AbE3Ab09358905558881B722F96": {
        "max": 1,
        "address": "0x25817174e3Bc0AbE3Ab09358905558881B722F96",
        "signature": "0x7f8484020e7bfcbd5643f8a37c1ce1f4981f8cf799907dd58abb08d78cc4373867453f22c32527e0c582a39ba7dcd9d18bace74a5bf8e7c795ed5335fde6eaf91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC87e9DFf30A7472329237C4aD222B35b529f0Fb7": {
        "max": 1,
        "address": "0xC87e9DFf30A7472329237C4aD222B35b529f0Fb7",
        "signature": "0xf769d260258d02414c0fefbd1c66edbc666be908630f940371e3f085c36877254ed93d4d6d10931043411c8af183648639f8e88c9dd8dcc808c8004fd76e62b01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4D0A80f22230D7DFb11B1eB658EbaD677e256826": {
        "max": 1,
        "address": "0x4D0A80f22230D7DFb11B1eB658EbaD677e256826",
        "signature": "0x0a8cf41d7c0ebdc9b6d3f6238620253d32f3334f6f658157f9447175bcd736f73caf159578fed62f7591262fdf704b30dd80760e65d4c8d394d75bac691a0c741c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0E5508615B9FE5CA1252a2F3c223512B64E38944": {
        "max": 1,
        "address": "0x0E5508615B9FE5CA1252a2F3c223512B64E38944",
        "signature": "0xc802d6dc6304e98d1988b2b6c20a279fbf3c734bfe836231264a1d3cdaed876e59a0d5b6402180b90bf6b458bd94d92bb91bff34e9d8d1d06c6cb0b117bbf1661c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFD2b7ae6047a0D76188894EdA68035ea6eD5C853": {
        "max": 1,
        "address": "0xFD2b7ae6047a0D76188894EdA68035ea6eD5C853",
        "signature": "0xcc298a14e7b6d40f11c162a9ab724b20b8c09517a1bc198e9cf24f303afbd8de70e196f4d66aaa56c7f71b1c163b346f769ed97b2b1e658c46e954d26a654fc41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdAbd0D8Cf23C877030fc6a08ecC8e5aBC925328c": {
        "max": 1,
        "address": "0xdAbd0D8Cf23C877030fc6a08ecC8e5aBC925328c",
        "signature": "0x2e6426f794a36d1c9840a129dd2e603a14aa4b5c8e98677ab714a607b9ac6cfe346d292df67139e56772e479614aaad8f7cd24709e8feff531db961e5fdadb901c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe66a52474370E0CbDa0F867da4D25471aA3C1615": {
        "max": 1,
        "address": "0xe66a52474370E0CbDa0F867da4D25471aA3C1615",
        "signature": "0x674fc7a681cf5b1002d40129714920dc2a9326bfd9d5d041f81025b516a1e07802a41f4146d0cadaf7af4cc2414469de778713213eea8b61836867b7c6c3fdf41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3c7A45D1db331fC56dC48c369E5C8c02Df84e354": {
        "max": 1,
        "address": "0x3c7A45D1db331fC56dC48c369E5C8c02Df84e354",
        "signature": "0x0e7a0f7ef128f4ea53702ab831281e4cb52149474c8ff6bd08e0401a0be6c94b011f6de42ea0d66fc9328d7307a77df3d58508906caf07e8ea6831af5412c9d71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa8743B305BEeC63Fa57731824c369b16134E428B": {
        "max": 1,
        "address": "0xa8743B305BEeC63Fa57731824c369b16134E428B",
        "signature": "0x0fbca435b15a646ec4e47c23adf9a8fad1581f152b52e735352217bab70e0419490b14d3041b9a76c58b69a19e12736dc495dbae4edc1532144ca7338dc8d6b11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0DEAB98DBA9291d42A0238D579A1348e4421Cf77": {
        "max": 1,
        "address": "0x0DEAB98DBA9291d42A0238D579A1348e4421Cf77",
        "signature": "0x5975c5356c1dffd36e1dcb06976ab6ab95c089061db4d0ebbf9c03f97a71f78934005236e4ca5c33499841f0ec8211f95190f7640fd32dcdad164370c46802bb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1a7CA30dF8f50cFd02fA9bfe8394435171766b7B": {
        "max": 1,
        "address": "0x1a7CA30dF8f50cFd02fA9bfe8394435171766b7B",
        "signature": "0xfa46bf23b7b1e6e7e004cb807706170573c8d154e65ce4a81cb96b7c106d4d1b416e72f35a3393aa9d17d1563b90eb5bb1d0e7a7139c8a731db5e4bae4e755e31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x494FFb3Ce35783FC64A6486245d458a8029De8fB": {
        "max": 1,
        "address": "0x494FFb3Ce35783FC64A6486245d458a8029De8fB",
        "signature": "0x19564a8860b1a004c4a493f73dec005a3fc183c0966a8d69a41f7e20a16d57952392e3ac229603258c87b9ca4a942ea19fe4e2a85923ec6a740857856e0953681b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb54b31157133d12623aB1df99362C342ef64E5eF": {
        "max": 1,
        "address": "0xb54b31157133d12623aB1df99362C342ef64E5eF",
        "signature": "0x2e8127b20099ed6ec4797cfd740e40c2e4382712999e5e7a90afa03ce07b320674943dceac5de7ca86d4976552e4bf9d02e35bdcbd04befb2e25ef37c432571e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1DF20cE366c25399b68b024EEa04f3290c1E06Cc": {
        "max": 1,
        "address": "0x1DF20cE366c25399b68b024EEa04f3290c1E06Cc",
        "signature": "0xf5a5c10e8fe9fbefceba4cc38a92ee3e872ec1f28e808257611ea127462a9da25ae534008866f1a1feb4dd1a4596a61133b2bd028127feca6b3135e7a4fdecd01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x311500b3b75755518A187f2D30c5eCDCcDd0ce69": {
        "max": 1,
        "address": "0x311500b3b75755518A187f2D30c5eCDCcDd0ce69",
        "signature": "0xb50bb4e864d5c470274f884c80eaf9da2760a45d20d6762200381528f02876b36392d545cb4882f5f9f97341d56ed30ac07b6b2481e142c890a0f0a97c6d6d971b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAe2F3b58087D807FD1531070cc0bf9C141643399": {
        "max": 1,
        "address": "0xAe2F3b58087D807FD1531070cc0bf9C141643399",
        "signature": "0xf69aca8ed8202620aab3ff1337343bb8ca8b6ca1ce77bf5c5bbad4d82778ed3c2cf9ec1a45cac84ddbc13d57ca393218f741813f99eac6e8e065c4823ea275021c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7e509462f511158371071425Bf264F1DF81f43b8": {
        "max": 1,
        "address": "0x7e509462f511158371071425Bf264F1DF81f43b8",
        "signature": "0xb11261c0bbab1a9ad431e579015074c8c6859c34f7025e777c0bd4781ba3f53b504fa69f2c98a4aee8757594bec89476a708ab6f4c46eb16a804410d32e061651b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x571c0dDa1994Aa182bA57752F6F20110E36b7074": {
        "max": 1,
        "address": "0x571c0dDa1994Aa182bA57752F6F20110E36b7074",
        "signature": "0xb626ef9f94cab03050fb9646d8dccbaca400e45abfcb9dcff4ad292e5e08a7ae0c481df139ab6d44a87ffb2d9328fe2dac9dcb57d41a039b1181cfa2302f6e8d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3B502B054715A8e0D8F657169615A88B2CCDD429": {
        "max": 1,
        "address": "0x3B502B054715A8e0D8F657169615A88B2CCDD429",
        "signature": "0x1e00e038488aca3a02820776b0ff0d744c8567b62303c7035200325c1c9af7ce3653f75879fe4055c485ca50d2b59059ea17338c620dceb39a9fc98a4173925b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc771C722e626C0d9E345dfF74Fcd23e14Bd3a781": {
        "max": 1,
        "address": "0xc771C722e626C0d9E345dfF74Fcd23e14Bd3a781",
        "signature": "0x31d2989a9d6f36b8bfa48dc9dd0572924736520d27400f372761d70292af61d95a850589f0417e6d3e607bdc3408ed6e0fb4db302469453aaf28dca3e7a194e61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1CE8E1954Ebe27bfD2b21fe15C74106A0a59D42A": {
        "max": 1,
        "address": "0x1CE8E1954Ebe27bfD2b21fe15C74106A0a59D42A",
        "signature": "0xb96f66c157bde2b40cc4c86d51a100d32b36b8be3683fccca20ab5ee5fd9b3b374c1dad52f53d5f5791fb8bbe342681d362e71e4852c95b16de406dfb344312e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF66067CD3154841ceff32e0ba719e354385D71C0": {
        "max": 1,
        "address": "0xF66067CD3154841ceff32e0ba719e354385D71C0",
        "signature": "0xe0d3bce5afd1519f45951aba43fc7f3472eb7fdc440c976da7c37f0bbe80e7f5560e7098701840a36ea2b5497d1bb9e413b1d917ff3bf239ae802353d5bfbbf21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFAaF2D0fd433CE55D430C9cB4F90b81706885Adf": {
        "max": 1,
        "address": "0xFAaF2D0fd433CE55D430C9cB4F90b81706885Adf",
        "signature": "0x152b8320dccde6382ba4ec529d4b7ede89b2679c94f547d46b005ea9a881bf14133168f00da46fd4fb5de773578e48fe509a8ebcedf19b248b8af115455223e31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC614505E31C6d4ba953890560219E9d55D82750a": {
        "max": 1,
        "address": "0xC614505E31C6d4ba953890560219E9d55D82750a",
        "signature": "0xde9545133655e5998887f2a6b6b774619b22d9c9ddd85cf0d9caeea7cafec85849ae06a4ca2b7f964509b682993916b858d67ecc1cd0d601a8c63c9c889b09bb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1d25C12831FCBa607109D0a24a564963912Cd051": {
        "max": 1,
        "address": "0x1d25C12831FCBa607109D0a24a564963912Cd051",
        "signature": "0xfd979b42699967016ff7c35ff156c8557c74eb9616af36518c98d8a5b767cd296450f20d04c70ffc86d24994f93227ec6caea2b7d99723457aeaa57e727e86311b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6f2657E806eCc145c51242A412C65E6E66154414": {
        "max": 1,
        "address": "0x6f2657E806eCc145c51242A412C65E6E66154414",
        "signature": "0xec0e50cea459b27ffda5eb3cafe3ad76e6fd281f06e4b53a779de527a70af97f2e5111a0d5f1063020f451ea00976efb2bf90890c4a3a09fb56978c91209f6441b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8401288070cc831F5169eF5e71b073F62aF2Bea2": {
        "max": 1,
        "address": "0x8401288070cc831F5169eF5e71b073F62aF2Bea2",
        "signature": "0xc969077f333d06fcd8ebb557bf9f46d4293e7e96478eb48c7130dc887a28c469300e7fa5fca29aab2f361f91831ac08047a8fb23576e04e5ced7f73d002431711b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x437d1fe3A267B401C513BC6Ab6B7613E127FEcD0": {
        "max": 1,
        "address": "0x437d1fe3A267B401C513BC6Ab6B7613E127FEcD0",
        "signature": "0xade12ec2779be6678ee9c578d9ebf757b978f138df7fe4062ada413654c33a160d0e4000e74a37bd3a204fae979f08939cef1445ef2f5094766ed824dd54eaba1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFe912F7f98df6946F4517257A13Dd297723a28C2": {
        "max": 1,
        "address": "0xFe912F7f98df6946F4517257A13Dd297723a28C2",
        "signature": "0x5f4f59f77328868c9de3e3100fecadfa6d19d0aa0270e8860072f14591008fdd3f0945df52c9caf4d9156a1b6f0a50ab92b00f37b8dc152ff99824bbf395f8de1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD3Ba8649c393083981614b1EFD6d8E45C9394EFc": {
        "max": 1,
        "address": "0xD3Ba8649c393083981614b1EFD6d8E45C9394EFc",
        "signature": "0x42e0c184e34b18b14320e357032ce2c63327f432eee95090f558fae7b2ce46dc6b7bd492ed070034fea25fbe6814632495dfc16efb9d465741b3d363c94e91e71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x945A4cbae4eF06C0114F53457404A49a6765d9a6": {
        "max": 1,
        "address": "0x945A4cbae4eF06C0114F53457404A49a6765d9a6",
        "signature": "0xca55412d16f6eccd9e7f4d8b29f4ff6fdc7506b56ca633b5e7ef8943088e0c69310dcc3aa347e966fa86ccc385c8e766308a5ac6560a3222446004a020cc6bc81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x12D75b5be82b0FF35FF6c89bC8EeB30Bca398638": {
        "max": 1,
        "address": "0x12D75b5be82b0FF35FF6c89bC8EeB30Bca398638",
        "signature": "0x45159ff79c95b4e4f7b3b44808037cf04d7d44fa6475fe4c3f0c7e011c964dd43669d7899fdb07f591a0e7b2a988fb7496e90f2c44f885bfe223cc682100a7351c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x37Ffcc8FDBC15677598C267a953301f52296e29B": {
        "max": 1,
        "address": "0x37Ffcc8FDBC15677598C267a953301f52296e29B",
        "signature": "0x548cad237ff1ab5a998f4e1ba6228bb19910de64f3a7beaabe9d4e9c389570770f567065aebaf323723726c2c26a629a1664d462b1180c07976026d785fda0d41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x157A8fDE864e9622b9344B6293Fa67B414459e5C": {
        "max": 1,
        "address": "0x157A8fDE864e9622b9344B6293Fa67B414459e5C",
        "signature": "0xb408812f29f179809a50e8742093dc606ece9ea1484964c9ab8fca8b549841ab7defa8d24375b76fcdbfc1c31c665ded5cf0e638d0fb3ed0ea545b55ed9c505f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa4A8823ac8f164760C0bcE16A080cd1960C2Ba17": {
        "max": 1,
        "address": "0xa4A8823ac8f164760C0bcE16A080cd1960C2Ba17",
        "signature": "0x560a24d1df43818d5f685bb13da6d1c4e99850140fb2abe30e2674bbe6c83bf400827672e73ca345e3ed5e454c50d40a2a01cd3473ec3872b816b843fe0113141b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x981af8b52d4a9d8A06B50CCD04b01B0dBe418Eb0": {
        "max": 1,
        "address": "0x981af8b52d4a9d8A06B50CCD04b01B0dBe418Eb0",
        "signature": "0x025a0d47cf73bc16080b95cd989c37ff86832a3dc6119cd58f639b1e609389467a9846fa5012c816910ba65b264c03ebae928dff391da331a9ee843805f5737c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x19c6D7d2d4Ea655cF4D3A3c6B82A6e52Ff88a160": {
        "max": 1,
        "address": "0x19c6D7d2d4Ea655cF4D3A3c6B82A6e52Ff88a160",
        "signature": "0x036735b1caeabf7223b72c20b96ab30ba26e9679ddeb2cd3d3657937f6e147453540230f14bcbe4878fed34d6fe8ec827f89470b78c58e559516f8a2b272cfd71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x34233F57770f6BDD35ad501deF66a78228ed08C6": {
        "max": 1,
        "address": "0x34233F57770f6BDD35ad501deF66a78228ed08C6",
        "signature": "0x7f691f1bef64c9a850005929ddab8967f58674bfe63c391df4c3f81e3f08739c3c72bc72e286445c60d58f61e99dd701a0502c4f28f1269937205c6d8d228da01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1b0E6dA2Ee865dEcFa25606BE46D6b1c15613016": {
        "max": 1,
        "address": "0x1b0E6dA2Ee865dEcFa25606BE46D6b1c15613016",
        "signature": "0x4d0efc00498d9d7d4f87fb025f6f5d3e16538ab94447fac31630653af8b5b0920238f6cb423342302fce9aa7f2da831fd341c8ece1cb2a710d80f35abb41bd171b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc1b2f0ee97D1d85bba931897717fe49A7723C8Ba": {
        "max": 1,
        "address": "0xc1b2f0ee97D1d85bba931897717fe49A7723C8Ba",
        "signature": "0x21ab80ef0172b117924b30c6fa1f37ffcb68eb26afa1710d19216edf23365fdb6645050581f5dbd50904582e9fb62f646115f6444bf6fde0a1574cdc5435207f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x44f69C7Cbe1c142B4539613661086727b08B9CF2": {
        "max": 1,
        "address": "0x44f69C7Cbe1c142B4539613661086727b08B9CF2",
        "signature": "0xcc3891eba1c791e5f8b4fb5449f89aacfb27cd986c21941b5fa99d7f531531d26893261959e1012a8735c9b7150221ab9fdd6bd8d762d14195617f379b1346581c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x477e447D9be9E9e9E06C85b37B82772a870c5aD5": {
        "max": 1,
        "address": "0x477e447D9be9E9e9E06C85b37B82772a870c5aD5",
        "signature": "0x32b47ba2934ffbb8370057a76e47460de022cf12cec8ebf788bac4e97a23071f04abffd8e3624e243491cf14b0c4bc2dcd139001840952ddb89b6fff2ed1f56b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3213A70aD2Be6b2750056B54f2AE02B648DF9971": {
        "max": 1,
        "address": "0x3213A70aD2Be6b2750056B54f2AE02B648DF9971",
        "signature": "0x4b857f0add559e24387b2c20e6d818402c29d954857f56dacf7a91bdfdcdc770195d72ce6ae9dc7a1a399b96bcbe7701c7ad7c7606614ecc56c41ffe4de6185b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF5DFdB8E86C55ca852a0c5b45F19E12be4B9f141": {
        "max": 1,
        "address": "0xF5DFdB8E86C55ca852a0c5b45F19E12be4B9f141",
        "signature": "0x04793fddc73653009738dba72a56e61cfe7f3696ba22db10df2188c6604528443fd3c743007aa730296ac7123d8fe11698479010dc0cc580d0e0edc1e77f9b7a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x828F8EA2e2a558d955F6427D648493244FdfAF73": {
        "max": 1,
        "address": "0x828F8EA2e2a558d955F6427D648493244FdfAF73",
        "signature": "0x1a0a32f2d6f4933cfb2d7b9ab5b3dcdf1ef8abcf2d5c560a5f6798e21970ce7b6712008145c903f499427de3063efa19669e8d1eb3aed2f1161f10e6228a66d21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa858bC58EEc4075f40C4b962C58b21856BF30C23": {
        "max": 1,
        "address": "0xa858bC58EEc4075f40C4b962C58b21856BF30C23",
        "signature": "0x388ba6d133e4c36fc3f18151c65dbd4692aab99017ee0aa3c707ceb973839ec0693cf832add3e33b793cc0397f2451f3cc64707b914a3ba6becd492cadbd06e61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x880bAe5e0FF2c4bf5D2819680E50fA4534657671": {
        "max": 1,
        "address": "0x880bAe5e0FF2c4bf5D2819680E50fA4534657671",
        "signature": "0x67c8df399a03ed765c05be76d5ced71115ad797c4b4ef4ee018e0bb26ee1937d2bafd0debddbcdb2dda126bebc81d44a6ff6595252024d5fd6f26f49b076dd171c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA41408997a3e4D51b813318fA3314322A8Dac943": {
        "max": 1,
        "address": "0xA41408997a3e4D51b813318fA3314322A8Dac943",
        "signature": "0xc95492a7bcfe568592b31bc1dd5724c6ed184f9f0518d04cae12ac5a195738ab776d8e9ef768bb32d8d526a18d1d65083d1e57e44be6623f8867c994176886231c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5B644DfF2f0B547A08f090d23FC8ca32a5aC4206": {
        "max": 2,
        "address": "0x5B644DfF2f0B547A08f090d23FC8ca32a5aC4206",
        "signature": "0x62a881ac53356a9216ac5475f90fbc6321bfb909b9473a66135453edbb6eb15604c1bc354972034f6a29cabcee006f0d6e17a17f0099fdd1c038a387fbb94faf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2F99c7E5D6BD90290a569E99C9852c854D882255": {
        "max": 1,
        "address": "0x2F99c7E5D6BD90290a569E99C9852c854D882255",
        "signature": "0x395ede893d0f1632c2b5fb595ca555c4b6d03d34c518c54d1ec6af44766c881143ae6ed4adae6f59ae093737be30aa6ea169f34133c84b01091870a7f38080441c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x005A4377E1Fd36Dd3eb905318f7A9883e835023f": {
        "max": 1,
        "address": "0x005A4377E1Fd36Dd3eb905318f7A9883e835023f",
        "signature": "0xaac88b18fe09c81a42d9fda19977f867dfde2496748a5ca54c7db3748e6e18ed72d2c07a10e4fe415e0c9793f815adb61faac793e99c6e610a78ad1b1837ce7e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x77Fd7d308fF88b6ffa8c43ed74017cBFDf0c4d34": {
        "max": 1,
        "address": "0x77Fd7d308fF88b6ffa8c43ed74017cBFDf0c4d34",
        "signature": "0x4b42bc8b0c0ffa73b67a786fe9b985f17db697ef7ec30e39ca20004e6560b5283720443345683cd540a6c461f4f1a97c07db502bec3093137d6a7b9c63b7bd361c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1D297a7eE129b04ea957DE8269E78CC765877055": {
        "max": 1,
        "address": "0x1D297a7eE129b04ea957DE8269E78CC765877055",
        "signature": "0x6cd79dabda4c06c311a8c7d493dd422646552e3d6a8d36c4b04c713069375fc65f49ad7375c4bdc6f7c9f2d70799410b51326785b043dd65487df4f52e5aab7c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE802Dc9E3C3a13505565cF7137b510A9da21D4d0": {
        "max": 1,
        "address": "0xE802Dc9E3C3a13505565cF7137b510A9da21D4d0",
        "signature": "0x741d5d46199cf4c0da8d6ce583b7d9a7d56e20753301fb9b5b0af88048c7e9fd642c548e466f1809b443edf41da0de7dfa8ee4517f4fdcb51a73080a29c142b31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8Fb7890b67E898d26736ba4e679b410c8D5708BE": {
        "max": 1,
        "address": "0x8Fb7890b67E898d26736ba4e679b410c8D5708BE",
        "signature": "0xf10502b240095a6efbeb7e39cc9de06d10748b53ae5ffa08748ace11d32537057e0e6fdcdfbad56f3f47bf016f13f77ce02dc3e2dc7cc3a536288413b4931bbd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdCF2ed16648b67ab18017E39fEE90577a696FC83": {
        "max": 1,
        "address": "0xdCF2ed16648b67ab18017E39fEE90577a696FC83",
        "signature": "0xfec369d72907ee401236496d284bcc32dd05141c206c4dc7053e28788bf3d0ce4d9369ccc2da0013aa8d9ddff6a06a71c69f9ac41a944a0220f2881628ce1e521b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x954dbC7c73721562093562b908E66cc69fd02F2d": {
        "max": 1,
        "address": "0x954dbC7c73721562093562b908E66cc69fd02F2d",
        "signature": "0x7470018a773b216269239c337d4c1188c4725445bb78b64838f0d136203e3fba4199c3424a514c07f6a21339c5719812b1342d760458c7ff8234f0d52f086d4c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaa84AB4381068694ab5d0dF6220671F3A6361384": {
        "max": 1,
        "address": "0xaa84AB4381068694ab5d0dF6220671F3A6361384",
        "signature": "0x297fd2fcc629a5d3f4b59deed8cd172ad11fb813fc0743a1a12d4076f76cdde3582007dbb1d186c7d74d0d3964f9a0d438629a0994a7db878212b0abc66168411c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3691f305721CFAe1257454DE645A39274c70a19a": {
        "max": 1,
        "address": "0x3691f305721CFAe1257454DE645A39274c70a19a",
        "signature": "0x345dc15b34ecf57d75b86494cc50f8ad34d6c4cf573184c9a6fe6b1dd5bc6fbe175540e9c1ca3ae426882e5c5988cea352247519870410a4ecc2470b040ec1ef1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x13ABE5DA19351926568Fe1E31dE3C2b4eE29AAaF": {
        "max": 1,
        "address": "0x13ABE5DA19351926568Fe1E31dE3C2b4eE29AAaF",
        "signature": "0x4a73043c183701e7b405f6aec29e8f28a580248d67a0f28d5147c6ec9b786cdc626e96349054d02eb86f9e2b8ba2f4ba992c5edbf626ca3d4128b873612000031b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeD59383c0B4db3AA8e20E2d180C60b418B1A69D7": {
        "max": 1,
        "address": "0xeD59383c0B4db3AA8e20E2d180C60b418B1A69D7",
        "signature": "0x6582c22e7476c34f4de66fc0ff38e0fe733d948b725d63cb98e980cb468ed382136806f7347a369ce94af7b3c0d39ee716852f7e0e253fc2f649668ea18697d41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x50688310F9929e5505D0E1e016060dEDf22059C0": {
        "max": 1,
        "address": "0x50688310F9929e5505D0E1e016060dEDf22059C0",
        "signature": "0x374792829ae33829c093f9b686d636118e2864c64958e16c9cccdd3cdbacee31080205ff82a5716eb3bb12cf87275fc1aacf06111c8277789ff89b77fb30339e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5203Ac1B065EE50A5e0F3272C6205d99A833790b": {
        "max": 1,
        "address": "0x5203Ac1B065EE50A5e0F3272C6205d99A833790b",
        "signature": "0x4480b34c2b4db26b11c04e3655941a8cf73251537aad99177d526967e755bdec53755e837b6c0a4d0a8637aa08efb624e1c435bf6f26a3965d38d7c53b4581d21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x929F2228313624FC0A7692022b87C6C6E5b84C48": {
        "max": 1,
        "address": "0x929F2228313624FC0A7692022b87C6C6E5b84C48",
        "signature": "0xc16b6b5d488511f5af59cac0d0f07c60a9d54bd7d9e709b3efd4c2aa46195cde154d1583f012bb5ecb4ef251819222dcb90322458919c913631301022025b6011b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x53BDfCB4b25720D6FeeE61DEcc819fe4c84Caa5B": {
        "max": 1,
        "address": "0x53BDfCB4b25720D6FeeE61DEcc819fe4c84Caa5B",
        "signature": "0x2af4e2f561e77a4686c371f358112cce45fe160380cc06b194f2f5bfa24684a4491957353f8649aeb0a40784038d0f8eaa485c78c6b3b707a1bd5d7c7309399f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6A28D347E31b1e39402285EDE15807139D03E303": {
        "max": 1,
        "address": "0x6A28D347E31b1e39402285EDE15807139D03E303",
        "signature": "0xf279aa186f412ad58c0e0c238310b36dd14c710b39ba26c5fb6fabdaffdc37a3728d9241a6f10920cc774ca9df6cccf698bb03ca3a3f8694fefceb3828a7d4921b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x876D713bE7e14cf96A00f5097D1f48f7b4D3A3Fd": {
        "max": 1,
        "address": "0x876D713bE7e14cf96A00f5097D1f48f7b4D3A3Fd",
        "signature": "0x2567886eb25a2f22fcfc8d122f9079909982609ae08c210e229faa31247862eb76bdd5eb6f441a7f99808641f25b85652dec34f7cdc010853ba3d7b359fac7311b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAd5aA0c48481C92382529D667cB12D854F0349D0": {
        "max": 1,
        "address": "0xAd5aA0c48481C92382529D667cB12D854F0349D0",
        "signature": "0x515f671e3085d43ac4dfda56fef1ceff2070e863b31ab67baeb7bed35b01028e4548e344b16e0fc1ef47bc67eb39f87b975da49598505ed6e67675c124d4b5c31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x037071Bc86b2dd6f6E579bC75DE6Dff1936dca86": {
        "max": 1,
        "address": "0x037071Bc86b2dd6f6E579bC75DE6Dff1936dca86",
        "signature": "0x086ae9e1f61898e7fb7c1fc39bb48d91336e5beb07f89fae1715b9a7e229856d04957263bbff728df574fd61fba1fe11bcd0420b62217028a33630ad11fc55ce1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC056790e2532a73bDd833f5BEf01015aB3A99f86": {
        "max": 1,
        "address": "0xC056790e2532a73bDd833f5BEf01015aB3A99f86",
        "signature": "0x156095abef9d41d5df9a6584a2859ac83d8a24e8695285b740386d62522101a65146e9774a7fbb5ba65ce256414a1eb36597e12f17465f72277b89810c3fd7c51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x583Ae442a93b098bbc0556A5e4D892C66aEA6b47": {
        "max": 1,
        "address": "0x583Ae442a93b098bbc0556A5e4D892C66aEA6b47",
        "signature": "0x262342dde19c5fc40ffe9e3eeff9d19a964dd5e482efdc61e48d8ed31a8d254336b948ad59d99cf04b6f8d598b450141e9b2a3480047c27e378f32a81e076d131c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF690bcEe2C4116c5A257C8F6c0574D71BEE42085": {
        "max": 1,
        "address": "0xF690bcEe2C4116c5A257C8F6c0574D71BEE42085",
        "signature": "0x4d143526ad4e36dae96323b6ad3d20469210d26491ddbd5403f2c1a38afd58b16dfa30b22b7cbab72cd0918ac6937677a27016c23122740f8e41869cc454bb2d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x277f5499b1dB94e215f24db590334cd488DF7d44": {
        "max": 1,
        "address": "0x277f5499b1dB94e215f24db590334cd488DF7d44",
        "signature": "0x34e1b6b1aa126562ebb34a3c78b304bc5282d8369c2fd9902407cb3c1c68c46a5074438bdd8ca91e658dae678f94a09d7affb5e347c259ec4d74fc3cf59971de1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x59cE56B7C5c5Cec829dCd690EdE26707b49bDC08": {
        "max": 1,
        "address": "0x59cE56B7C5c5Cec829dCd690EdE26707b49bDC08",
        "signature": "0xa7485482e378a6e856de6be8797d68fc7a6bd4eb5b05381873e481465c57cf6a13b1b2c291c0354bbe646760e07fd713bfc4e0712b3aada2d084808a7f95c5811b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x751728c00B0F671BC2b6A1Dd60FdBb76AdfE8D99": {
        "max": 1,
        "address": "0x751728c00B0F671BC2b6A1Dd60FdBb76AdfE8D99",
        "signature": "0x33cdb96af04292c16d2d7f83bd9503755d3ec5f735d79797cfad222049c9a5b9377fc41ebeff7359c45e53150c3a96a62af1a864ea449a6a0352db1cad64d5c01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x05de7185D0A89c40Ef6a4b684296DEBfd2e7BD0d": {
        "max": 1,
        "address": "0x05de7185D0A89c40Ef6a4b684296DEBfd2e7BD0d",
        "signature": "0xb42a5bc850881ad097dcf1bf04f535a2faf92bef87ae7272b27b25c07a1b3c5771ccdac39a8da7e217074493a4e82e03704583e547d791515a7dc76ad8a310df1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x96051588Ee65acDE2453e972e1120cf6b2f4651d": {
        "max": 1,
        "address": "0x96051588Ee65acDE2453e972e1120cf6b2f4651d",
        "signature": "0x8b3ac2a2a00f9aae3fea1d6548a4a900df82669b19099c9c2b9352236ccda820371f7cfa80b98e6aa99375e0ef25c4fc62c68c87ac14dacf5d2b25ed4f4a9c1d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0957A22f53170d258226E57114163787AeBF2ff2": {
        "max": 1,
        "address": "0x0957A22f53170d258226E57114163787AeBF2ff2",
        "signature": "0x3c4322fe2234fd65f5c2d5f1dae7ddcad6b630f27ce92f2200583b7198bff0e5633d2e9d61799fa1eb0e54353152be4e4c5a2960c7a16bd551efdd32890905e61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB39a4006b91ACd2Eed0ca7b13aACfc8F7AfBE36E": {
        "max": 1,
        "address": "0xB39a4006b91ACd2Eed0ca7b13aACfc8F7AfBE36E",
        "signature": "0x956e8add5f5a79dae5402f2fb2bd10d6443159fc789d34ba1348ad9dba543540685e9c8b34cef03c2971cd378fb56697b8c75e32a1003ecb940348454377db281c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4C5d5C82005Fe8f82A58Cb56dE2C728448b10Bb3": {
        "max": 1,
        "address": "0x4C5d5C82005Fe8f82A58Cb56dE2C728448b10Bb3",
        "signature": "0xac86c27c1a91c8a4f77d0f54203fb3d3928d82ed52c673dced7b7d2dd925fc213d6fe551bd0c33162d8452bef23eee49b6617ca333bf225fd357cb400880ad291b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1ebc712c4fe85D8bf73bE303028311d600cB8eC4": {
        "max": 1,
        "address": "0x1ebc712c4fe85D8bf73bE303028311d600cB8eC4",
        "signature": "0xdc99a2215140fcd23ea088a5f9d9546e6a4022c96dc292473ddadeb0d9ab181b65623a3d5d7e303ee46b1ef5e81b4443d4baaf424cd1ee3abe0f9dac446ff4f01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x96b83b32CAF2d3491DCBEAeF4c858A9377F2bb5e": {
        "max": 1,
        "address": "0x96b83b32CAF2d3491DCBEAeF4c858A9377F2bb5e",
        "signature": "0x4e256b36e3c4d446c637c08a1bd289edc5aa7f452c967d3d606db22bf1e94782207af0554b00705a1fe4c252861eb79e0efa3b9fb683b30785a43212c3f78ac81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3edc863789a36f508340ea3f2Aa40674139cF5B6": {
        "max": 1,
        "address": "0x3edc863789a36f508340ea3f2Aa40674139cF5B6",
        "signature": "0x75214c93e5d32b9896d62a271be3012005ab0271fac82ad106dd5e506091ad8b029c13b6da02346f19d8ddba985883d5e69e111d33aa554b1a0d5f8c86a21c501c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x62a66C6Ea5F774F019f7723b22D0eAEbD3440dC8": {
        "max": 1,
        "address": "0x62a66C6Ea5F774F019f7723b22D0eAEbD3440dC8",
        "signature": "0x13382790a1a4451d64458f4d7e27d88dcb65f75541a2704f0ec91c9c0199bf89415a26c9d43a4748cb7f77c1708d26d5ffdacfa400d64a374970221ccf1789461b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x602e6020A20C583e08BABAFE5202b33F2DF87fd1": {
        "max": 1,
        "address": "0x602e6020A20C583e08BABAFE5202b33F2DF87fd1",
        "signature": "0xd1f629412dcf88422e2f8723c587709d158ce3a2e5c74cc2e608538f4de75a196d0bdd6ee32ef9d4cb2c4f8ce2162a1395a0a36388258ad3a2dd5f6754fadb811b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x84aB68698403E3C820281b331FA83463fFE239f3": {
        "max": 1,
        "address": "0x84aB68698403E3C820281b331FA83463fFE239f3",
        "signature": "0x43d8731d6b846a3154e15bb963f1513bffa8b8e822f1852fb77284e9d9a05cbb323f5387c376091d6e36c96b900311e1df5ebe92f48a759d3747d4815d8bd44b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC786b29D677bf9Cb25d7f6D50E241ED4Fbd1Bb54": {
        "max": 1,
        "address": "0xC786b29D677bf9Cb25d7f6D50E241ED4Fbd1Bb54",
        "signature": "0x7d02ac0985381914cc65850a2d5cb6151aed5b526928c950717fa1f9f261da546297ca99256c3502dda3d53b4f1d19a7f952669e19b0d6061c9c1d07ab42b39c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x54b71a82572E29Af22a19fD3026ECEcb58cBFc03": {
        "max": 1,
        "address": "0x54b71a82572E29Af22a19fD3026ECEcb58cBFc03",
        "signature": "0x7248a21c83f3d718a111ceb2169430a6732f42613cdba432339890aa4a4a0fcd7dff433c3ba83779cec83bb56f100410b30ab12f90660b563049ba269b0553de1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8045F4620e1BF168C0D43813f898fc470F57C98B": {
        "max": 1,
        "address": "0x8045F4620e1BF168C0D43813f898fc470F57C98B",
        "signature": "0xa82b598521dc13436a04a6c411170f6e2fe0a1a9e7b8d49f4a1a8f37fec637b50cd485afd70277c39e0d9b78164ea03efdf1b757768ab0810e18d69d564845eb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6A9edaD27697f4CCF95a35CB6c25028971F06Ca9": {
        "max": 1,
        "address": "0x6A9edaD27697f4CCF95a35CB6c25028971F06Ca9",
        "signature": "0xc43fc3a562866e7d1ee40437f1e0e1249b80da5fce647279c684105aa59b23860766e7b2ec62c200606de5acc233255e8734f1c68a0d9538891db56a8a8244d11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBc569eDD4D6325be32CA312Bc3C61107101d2C3B": {
        "max": 1,
        "address": "0xBc569eDD4D6325be32CA312Bc3C61107101d2C3B",
        "signature": "0x80a7beb359042a2d6d4a9ba2e688586055e3c730b21fffe7e8de2d2abae0e6803bd924c5dc32eb66acb30d5a14623c37447191651afa115d3b12b969eb5faa5b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x72a4dc78c0C4A2F804eC467cbD6c3f89063cE62a": {
        "max": 1,
        "address": "0x72a4dc78c0C4A2F804eC467cbD6c3f89063cE62a",
        "signature": "0xeb4f867ac3de2da9b53b5a1c55290773efd72b95edf29681ec4b16bbe93b73f932bb222d5f6df8a9c88d7cf709d9cac9e916ba11d153b6deb593f1ec6288bbda1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9Bc143C56162101ceAdE6060A752764A35B7e1F8": {
        "max": 1,
        "address": "0x9Bc143C56162101ceAdE6060A752764A35B7e1F8",
        "signature": "0x4e9f945979a59d12b4df268e724fd9df2f265dc6396af268637ae03b22607693547cf1e9aa4f27001aaef36183562a2514c456e858d9f94a014b35c1b6dc16521c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7781edF9C76011679A579E81bD3Ff76653f1d2bb": {
        "max": 1,
        "address": "0x7781edF9C76011679A579E81bD3Ff76653f1d2bb",
        "signature": "0x800788fd133bb0280bf550bc46504139739bf77dbfb551dd4d6945614fcb24187dc7a0640bbeec48e2caae56d0a6fe1e1b4ca91769b898de8926b3fe063943fa1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC0072c5793771a7563F43698a4710Ba56BD6d9a8": {
        "max": 1,
        "address": "0xC0072c5793771a7563F43698a4710Ba56BD6d9a8",
        "signature": "0x48c2cd6e9e5d6c66283ca5442ce97b9957059d770b223b6eca7273ffd7227ff9078769c0e73d3bcb36a04884a7ae42aaec8242bec230a8fec7416fb7c24645611c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0F11cB8E1FE941d1A675bf9F722D2f7dd3D429a0": {
        "max": 1,
        "address": "0x0F11cB8E1FE941d1A675bf9F722D2f7dd3D429a0",
        "signature": "0xf284d202e0a5e5b845568264d9236cd2c758273effb1cde196adf2fcae8ebb4338fa35492c38a9e1579ac06df43553dcaa6481bcb5170b7049cfe00d59e491111c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1a852B872acB5Ab4D521Dd31BEc7b6a7319ed7bB": {
        "max": 1,
        "address": "0x1a852B872acB5Ab4D521Dd31BEc7b6a7319ed7bB",
        "signature": "0x5608fd90624ac25defbaf3228f4e6c732b260604b21e03eedf9177b300eb497f725174550033a3f5fff3ddd81b46be92d8dce0926fc3b07b5c16a1ee5bbbe1b41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x26E37307EF11d56F5Fd4dB41116505AFe4766D12": {
        "max": 1,
        "address": "0x26E37307EF11d56F5Fd4dB41116505AFe4766D12",
        "signature": "0x96cfdfb0bf6b1e228da3482698af5211a8347ee333e8e0b810a9ac9cb2ea694655d5e9b1da541ac1c112a03999d6c9f5cc3c38cbecdc0dc8005abbe57b8b6cf11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEF9aC01A922703BAC973FB67525C35fB59203111": {
        "max": 1,
        "address": "0xEF9aC01A922703BAC973FB67525C35fB59203111",
        "signature": "0x6d28ee11a9afcb283577ac461e973e50abd85bb496bb4ee172d35b3ec828ba0a286ae31d26458adabc80b0c2c3b1d64cf677cc7c3aef99b3e5449cbc7feac5321c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA530B243a20Cb49317288EA530Dc8A03b0C0Ed63": {
        "max": 1,
        "address": "0xA530B243a20Cb49317288EA530Dc8A03b0C0Ed63",
        "signature": "0xd6feacbcfb2d9d627c44d55d09405ca67a6e1c729f12b0f0aaf81e732a8897711c09b2d53c825b131c32e453a798c135e38266410ffe3962e0bc9059bab600001c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x41FE13e98cE33d586A653a2A8778372529335ADF": {
        "max": 1,
        "address": "0x41FE13e98cE33d586A653a2A8778372529335ADF",
        "signature": "0x850756a56babce2185a92fed375ceb25afed8dd784b5f051cc25e2bdf5eb001d78bed2f9d0ebd3becfb75a2c7652d98afc2c86f1dfe52a0d51c21615e185fe1b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x75caAAdcd038Ba3313adD4389D666967deeF1607": {
        "max": 1,
        "address": "0x75caAAdcd038Ba3313adD4389D666967deeF1607",
        "signature": "0xe0108f59c67d9f004434f3098e5321a2bf3f7051880c04f75278b60374aaf6a701a89c33593e290a0c1d244e3318243e17298c9ba828468785abdaad64c71ff01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x24b2F8E85585433061de2b4a2C5219C1569242c1": {
        "max": 1,
        "address": "0x24b2F8E85585433061de2b4a2C5219C1569242c1",
        "signature": "0x6e32c14f678a9a232f3c85e1e0cdb7ab5f3723203e4719b1b9cc5631211a1b614b0487e179dd78baef9040d59de7ce9689068a5198f69bada5036986bdfa93311b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa6D45Ef04F6958adAbbC45360AA3033B5c57439A": {
        "max": 1,
        "address": "0xa6D45Ef04F6958adAbbC45360AA3033B5c57439A",
        "signature": "0x9b0774bd00dbb966b6947f140db5ee0974f1a0274ecb9c8acef6da19d8372e1a0e6691a1501df48be47ebe384e727ddbda9ddd734e486f7b698c97f209c475cb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE58299AB678228081d9c682Bc77d5dD6B1f3D6a5": {
        "max": 1,
        "address": "0xE58299AB678228081d9c682Bc77d5dD6B1f3D6a5",
        "signature": "0xf3c274046cd565a01d11395941a857799f4505a82a271254eba4ea83e38bf1133d37674bcddcc3bcdc195192bcd5357d52aeef2ac75cbcb22b54653603df08f71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x063fca77B5655C383bc6E365AfCED2D99bCcE515": {
        "max": 1,
        "address": "0x063fca77B5655C383bc6E365AfCED2D99bCcE515",
        "signature": "0x717642a1af30a353e6a410c2620e4bd0c00b4abc2815c0ee45f62f87b23dfde74da54947d9592cf9187f1fe42c50e1123ca03704b5cdfe3263c629f02c7faeed1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x95dF515077b7BFE200DC3f9C541F9563E19Aa3Af": {
        "max": 1,
        "address": "0x95dF515077b7BFE200DC3f9C541F9563E19Aa3Af",
        "signature": "0xe42383d4dce219bd047d5dc603b1109979cad6740f98100c85318868378f8339272e7fd4d245e51009bc38bb8b8131cbfd7a6914af071c96073d25af894b04541c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x436D637E0F602f345f7D7EA6C07fFDdA7D997961": {
        "max": 1,
        "address": "0x436D637E0F602f345f7D7EA6C07fFDdA7D997961",
        "signature": "0xb1a21060112278b47469943c38a2bdada8bac7c1fef7fdfd1ed92b52c27fbcc260e74a2372aedf8e6f4a05b4aaf2e2ca8a31c9f3450c2beb985a2bb62b22f4651b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD64Cf576caCBD30a9b552Fca88E577bC56C9cF56": {
        "max": 1,
        "address": "0xD64Cf576caCBD30a9b552Fca88E577bC56C9cF56",
        "signature": "0xc20b83ac5bb75fc3debfabc20bbdf72daa03ead8b6c07d1bbdec9b21e9a7e3023e9f00e5444d2e09c761d5bdb917a6e2181f0e608b939c8705d384284e65db651c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa749668720248348506E240944978Dd97f277CE9": {
        "max": 1,
        "address": "0xa749668720248348506E240944978Dd97f277CE9",
        "signature": "0xd9f83ba0230f2e5975a172c4116957efcb89d756cdee3ac9732fecd040f08a9f37bc6b62e775bd5718adf634a6992f530b5a21d09b4bdfbdccc7b7a5a682098c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa7fb1c0522a5757C59383c6961E7217FD0Fd6a06": {
        "max": 1,
        "address": "0xa7fb1c0522a5757C59383c6961E7217FD0Fd6a06",
        "signature": "0x076262baaf099ad913ae5dbb9a79b886e5535d075f23260e6946b3127715a70b19d8ab3c8caa809b10e68690657cf703845fe205f8f2fbd62537775654cda3111c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb0B3d3CB5D9469286B9F75824Bd16a6bb133Fe09": {
        "max": 1,
        "address": "0xb0B3d3CB5D9469286B9F75824Bd16a6bb133Fe09",
        "signature": "0x05fe195020d0f804f3a81121109cb3fb637aaba0096e9627637d0fe654fae43b67d6fc698d0602d299dd2f85365ca3dd626a449216f0d5a99ee8031f8013dfb71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x91E6161Da3906F3d7DFEcBa22C3E8c6C33732a99": {
        "max": 1,
        "address": "0x91E6161Da3906F3d7DFEcBa22C3E8c6C33732a99",
        "signature": "0xe788dc7f9f62fe450b2ff50b22ad870fdbf2f4d81954a33a89561ea9c0329f5779c75cb66a72bfb0d8665461e537103018e51819f3000ebe4cdf8d3784dc8b771b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x45D6e986b247763BDB58Fb7c36D79AA7bFc1Dd7E": {
        "max": 1,
        "address": "0x45D6e986b247763BDB58Fb7c36D79AA7bFc1Dd7E",
        "signature": "0x00cde92f61f804a8fe6ac699fe44970d4ccfd356dab0a439a03eb1818d7cf8c44e9cf84c7dc329480185219d479f8aa502f6fd9e3facc09ee313604b658eea931c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x42ec7f12e6B84DfE531E52E937614c8C63D0075e": {
        "max": 1,
        "address": "0x42ec7f12e6B84DfE531E52E937614c8C63D0075e",
        "signature": "0x43763b95f106d0b556aee466621ad62660aa4c7a794b213a8567bc0710b8195c2e9aca6304e2063a197bb12637050d9df851c7cfe94429e57ddd697a0f3c3b881b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x594007e7cb661782830f95C22C4ee7BDA5189B36": {
        "max": 1,
        "address": "0x594007e7cb661782830f95C22C4ee7BDA5189B36",
        "signature": "0x3ff723d91b0c9db18963ec49185384799475a5796649452046000909a247d08a0ef7400424d3869adc7590751d0f77b34bd9cfb7a98975760bd7d8ff59c1fdda1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf09D2E7f103299b6b1BD90cD01Efc1710723fE7d": {
        "max": 1,
        "address": "0xf09D2E7f103299b6b1BD90cD01Efc1710723fE7d",
        "signature": "0x3115fc1d072b3cfb3f7983d7f44d836d9b1d1dc457e9681e9a03666bd7dfb43f5ab6acb3e645af5d738c3a3fb3273cdbe39de70c06c663cc610232e695db98a81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe24C9e8DB8BC14236811e253945f262cE402ea3A": {
        "max": 1,
        "address": "0xe24C9e8DB8BC14236811e253945f262cE402ea3A",
        "signature": "0x6a52dade31da18fc06421aaf9196cbea71d456488bcc714267caa882f6a0324c4abc8322a6986d359560fc4527cfe5c2ed809c026a43eaf2dc6114f0f3f6439d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0795536288350475BB77B5d4e5cB862B4FD1792B": {
        "max": 1,
        "address": "0x0795536288350475BB77B5d4e5cB862B4FD1792B",
        "signature": "0xc768b4f53f96454d920c59cf782d2241e79d35a18ad9b5e58e0e80f65a9dab153e0c0306ee4d04a66a37ad56731e2325e588718f0711b7d97d86bcfe1866868a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0e2da5d44A76AFb57a19C9F933653384d6d65613": {
        "max": 1,
        "address": "0x0e2da5d44A76AFb57a19C9F933653384d6d65613",
        "signature": "0xbb791847cb5df4fc82756d8e1f2ec67b24b43fc8e661f0cfbdcee38bb018633938cbada7b7087f92718ac3c40a9d3f8a6ab39fadee320b5164d9bac44ca1fbd71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1B76eA5c3AFCE9Ef223d8eF08dDb5A90f001F42E": {
        "max": 1,
        "address": "0x1B76eA5c3AFCE9Ef223d8eF08dDb5A90f001F42E",
        "signature": "0x39c8f6af557911b0a7aebf7e5113881f9fbce6016e307c836fb7f680df67225025696eb652888770c32a2b20fe954e8c2224e93a3f3a6c31bafef4e80f2da7311c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2a62C85Af932daFfb036810F9074bb05Ba2789D5": {
        "max": 1,
        "address": "0x2a62C85Af932daFfb036810F9074bb05Ba2789D5",
        "signature": "0x653598fe3ef04b4b9fcc65ba0e5986638064b0249fe255890590c320d6716b0e4bcffbcf6a727b3753ff0136d4600fe2c8a7ab8e41ddcc303ed067eeafd00efe1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1b65DBCEb04d9A2cdF42b8945C1E21179271eF51": {
        "max": 1,
        "address": "0x1b65DBCEb04d9A2cdF42b8945C1E21179271eF51",
        "signature": "0xcc1f594adb60658969f9876385a99d0c8d58290450d9d11a810b8708ed4a034d53198b159697b4cfa425cb34a5b2d39167a8c90a5356bfcb02f050eda3cf8ec41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb6524111b586be9fa6f5C7c06754e38584350f53": {
        "max": 1,
        "address": "0xb6524111b586be9fa6f5C7c06754e38584350f53",
        "signature": "0x0904954925ae7e71e4579ac52405873bc3259e412e28a85e9143fbbe1db9dbee4be4c71f45ef4d10449ac8c6f9fb20181ae943e265293ac902bb7529774dbeb51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb0f7150808BE601981137f9ec7cD9614A37fE076": {
        "max": 1,
        "address": "0xb0f7150808BE601981137f9ec7cD9614A37fE076",
        "signature": "0x5967ed8f0e54ebc950ed9c4ecd56285980ec52e55ad66fa3f46ca0beaf58804b4a560a21394ab9357803b6780d1ffd766e7fdb513866ec16fb2ec0f192d3f93f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb2ef6d479c54fC50DEDf884B2Af59087D5a5f10f": {
        "max": 1,
        "address": "0xb2ef6d479c54fC50DEDf884B2Af59087D5a5f10f",
        "signature": "0x43dfe6ece230d5ee0711d6e7be4b606d2c87ce83ae655fd0e117c695258750d42fa8d45fb982ca308b28e3337bf3034242822492add22937e854dc28cd9d92991c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0Be170ce6fa35BcbdB3227c49aF274d429f33205": {
        "max": 1,
        "address": "0x0Be170ce6fa35BcbdB3227c49aF274d429f33205",
        "signature": "0xf0ef61232ab994f57b65f15e478951d072c53c56eba1e0c6896d94acf1492b35128f8deb00db8bf9f37412004cc3d3e2c65676a4e3406213d4d09915b03627871b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4E1E6c0622363A128087d114Ef837CE03dC7932a": {
        "max": 1,
        "address": "0x4E1E6c0622363A128087d114Ef837CE03dC7932a",
        "signature": "0x8c93d0c5dcdbc5a8b8e8019800b82d06b7928e77326af706b504f2595e876bc93118a8c37bb7347a6ccc3468a09bf8dcde7ab70751a7086abc637fd15375f2b21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x085591817e360FCCAdc79463E933A0624b49D968": {
        "max": 1,
        "address": "0x085591817e360FCCAdc79463E933A0624b49D968",
        "signature": "0x21d26717f8f00b6947883514e934b423d523d169f1186ef0a1453fcc6713871b1e2e8a88012a408cb045d490a9048a88fd72917f408520ac165ac79c3e48d28d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x611909B813359e3FAcC833812559E07dF37E418F": {
        "max": 1,
        "address": "0x611909B813359e3FAcC833812559E07dF37E418F",
        "signature": "0x8a35b38cff0649c20cf02dedee7aa1f62890f1c1c0e7e9b0aeafc2a74215f29745b7e95194708823fa285b0afb4a86ba7332e77170b6ff556c062b583ca50edb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9351e316D2809e30ec1e22AB79597113a758BfAc": {
        "max": 1,
        "address": "0x9351e316D2809e30ec1e22AB79597113a758BfAc",
        "signature": "0x76a8d12268de74760548703db524914094585c5995fb347302a0b809879f18fc6ba7853d22d277791159a5ef74c767fc7f300e5043372cfd52a062de7cd39f081b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x408E5416c6Bd8008DA54CD2362B6500a9702f205": {
        "max": 1,
        "address": "0x408E5416c6Bd8008DA54CD2362B6500a9702f205",
        "signature": "0x70ec1aafd3e08842e44789257bfc782d0f77b20153f4869aab2f8877d8eae34f4c1b4011f31b72fec48e0eb528ed0c862cbdc0030574b2a72c8f4c7e1bd3a60b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x220d332a156778CC44468408a99BC0357104aCd5": {
        "max": 1,
        "address": "0x220d332a156778CC44468408a99BC0357104aCd5",
        "signature": "0x21e9b432c16dde8708b03695cef0d0e88858f13ea94815bf7c1cf07a1c15f6dc17e0e85c177688f9f44f06c3ea3d307348a344d5784b77e9371a60f02ead50251c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8D6071e042bea37a0168949E6C3347efB4277485": {
        "max": 1,
        "address": "0x8D6071e042bea37a0168949E6C3347efB4277485",
        "signature": "0x0753650716ab35398c7e31193933717913ac16d1e87c44c82d33870cb782697c6b86fe6989684b01da68a4a37d8e494cb700e9b8130f0703850db3c23dafa7401c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB8bcebd1F29a9001E4DB7215FFe73FE03e35f6e0": {
        "max": 1,
        "address": "0xB8bcebd1F29a9001E4DB7215FFe73FE03e35f6e0",
        "signature": "0xdfdb5b301c28db34bf0b3515ac06e3920c9aa988c6565a4e1824709e7cd6f18c7a5cb70835ad67aa0694adab27641bef3923a736d1efb1ca54999dac783566011c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x990A439724531bee72D862fc15F0F22F673Bc211": {
        "max": 1,
        "address": "0x990A439724531bee72D862fc15F0F22F673Bc211",
        "signature": "0x8c33f966c346fedc73f16bceb4fddccf2b06915fa10fd43c20498a41bdb167674baa7330e07b820d22f2ef023a4f769d14fff9f7c69018e7d321971e6d6c339f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe13e08332E9f9fAF5dE9e7979ceB78F687Efc00F": {
        "max": 1,
        "address": "0xe13e08332E9f9fAF5dE9e7979ceB78F687Efc00F",
        "signature": "0xc129b6329fcbeaf336b94c7a01b6230e521383642b130040dec9b13dddb18a745b0521e571240f4d89b3594e7143f2c38a1e997c83cdfa31b9a5c3122f9f39481c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5A5B3B6DE03cc72de30C4775DB7b3992803C3ccd": {
        "max": 1,
        "address": "0x5A5B3B6DE03cc72de30C4775DB7b3992803C3ccd",
        "signature": "0xc4ae09bf466d92d20f7fa126ee16917e9a9078296c826a9bff2d433260f914db4062ae971cbb9dacabab063e876b5c3ddc6acaa3d1ebcd2aa8fdc3b1f0b411f61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2f15a795382C6751192f57a4CCA5D62605CAe804": {
        "max": 1,
        "address": "0x2f15a795382C6751192f57a4CCA5D62605CAe804",
        "signature": "0xccfdc1bea6b880efbaed6704f074ba8e06ec9fa45d2a1a416b83cbed1dd78a1c03d5e248e6e41a417da32324da14e344b9d6986e1830fce427dad63f78f6e1781b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8B7907B7f942Cf1797F4F21BeF916A1441ab3C76": {
        "max": 1,
        "address": "0x8B7907B7f942Cf1797F4F21BeF916A1441ab3C76",
        "signature": "0x23b9117120e8e0fa3d17adbb9b9aea48519f7bf26afaa5ad3f5fd4f3499f53ca7b88476e4e2d744ed11165fd56e8707e697e7e629aedeba6e79e2bc1bb3ead411b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAE5966CF8cd369a0162aD2EB7325BCD0E488A681": {
        "max": 1,
        "address": "0xAE5966CF8cd369a0162aD2EB7325BCD0E488A681",
        "signature": "0x2bdfae85db6a173af17c4220d470b3e8e46fd14f8a5a45f8b0696a879379727f71de18fce8e0b7278a0e1897833cd62887fac849d4a7587691c4c92656d74fcf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x078CC4caa131eB9d6D3E31847946d5F37fd3F58b": {
        "max": 1,
        "address": "0x078CC4caa131eB9d6D3E31847946d5F37fd3F58b",
        "signature": "0x865b3dcd92450225e2fa10c80b48ab762aebf9092966ed0bac928120c7760c40430fb4f0e654def58ec74fde2c26d14ad9cc02404c4cc998ea15ce0e784432cb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7dF768FF28bB3D6D5D221caB65F70Bc3B1b9aF8e": {
        "max": 1,
        "address": "0x7dF768FF28bB3D6D5D221caB65F70Bc3B1b9aF8e",
        "signature": "0xad2ce316d01a2fc40bb651c16277f14226c28aed5bfc22f06ffbbec9ffa35925515549e760f1d9cc71f6493c651da848fc73569439c0936a4942c24daded5ddb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCBfe1060FcB67bF62A609e5F77bDae3cA3848E35": {
        "max": 1,
        "address": "0xCBfe1060FcB67bF62A609e5F77bDae3cA3848E35",
        "signature": "0x2d43de66f49999ffa328cbad18bfc1a57decfbb5f74b9576b6562680382bb73628bbc8f0f68576e1b01b772991b049757be6bb49b0d440439d4273fea57ca6e61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4A0a33272B9e814ab8aa83a776Ff27CeE18aAf0E": {
        "max": 1,
        "address": "0x4A0a33272B9e814ab8aa83a776Ff27CeE18aAf0E",
        "signature": "0x8dbb72fe822eecb4cad82bb06a79f51746c57466520c64d6f182deccac40a5fa01fe5e244bb3cc19a70734d4453b2c4bcb785b7c25597dfab1c4df3fd58c51231b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC0adD347555bfBDd0A15bE9003C4a757013D1A5c": {
        "max": 1,
        "address": "0xC0adD347555bfBDd0A15bE9003C4a757013D1A5c",
        "signature": "0x3842f809ed5f11b1ccf9d7b439d9f773b563f3cff01c2126c17c614ad2e27a945ca013e1a1d68f62cb9bf9911326f8b739e2ed0283e99a1d847a5f432dc8355d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x56E4388C68262777c80ab1c9C0aCccD3a2Df2B91": {
        "max": 1,
        "address": "0x56E4388C68262777c80ab1c9C0aCccD3a2Df2B91",
        "signature": "0x8c9d4c3087c39359205ed0adca929b744d0de93240bddeafb224f716e5b117391e123954f13e809b182c78f9e147ff711edfd1659d31b483abbd3c9386b1b9d21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCeB61eA7C1C1a7Fdb6C17833CafD76145061948C": {
        "max": 1,
        "address": "0xCeB61eA7C1C1a7Fdb6C17833CafD76145061948C",
        "signature": "0x29368346b9d6e8a87edc6177c18e919590b57dab3f0292b05cc697cac46b0364436bc3bc35fe1854dad5073059bed0e3c674f70277956a86d6abb3e567e0393a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6940d187E40817005e8e05827B06ebf3469F2295": {
        "max": 1,
        "address": "0x6940d187E40817005e8e05827B06ebf3469F2295",
        "signature": "0x0ee7637fbdd9d71c5b462bb95aecef7fe783f2bc9158b196935fe9a22ced2f3958094bd2ed85923bb8463b33dd90a95587a99eac4def492026bed0983d87ff501c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x25370840a97eB6E09A45721fDF42E51CF5Ab4f5e": {
        "max": 1,
        "address": "0x25370840a97eB6E09A45721fDF42E51CF5Ab4f5e",
        "signature": "0xad9894f33acc0f93610f61ac590cb4f38f56bd9342dd2c6b70ae78641260be6358818b9388bc54f69f8d6558f934a1d9c8684fcbfcd987bb1ba49f2d2fb69c071c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6D1fF3aCa6dAf601ee46AC10EfeBF626B81D5b86": {
        "max": 1,
        "address": "0x6D1fF3aCa6dAf601ee46AC10EfeBF626B81D5b86",
        "signature": "0xd33395239e9c842a7f50e361fc92deb59133d39e4751fd497416c7ac8b4e90506af360c2afbffd018e5dd77c399e42df0400d2b11f5bbee39fd68a8a892852c11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x79261a7cEEc687d6151e81cbBEbDbF840302035e": {
        "max": 1,
        "address": "0x79261a7cEEc687d6151e81cbBEbDbF840302035e",
        "signature": "0xbe83f1dde6c9a1c1a7f56963308f572a305a0af04c39e0124caa3b05009cd9d85344580ebe0d41b1cc05d58cbba3873cc4b7bbb54294ee797741b65327b437af1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE120edDd8A1FEA7D73AaD75d8eD8406988B2C98D": {
        "max": 1,
        "address": "0xE120edDd8A1FEA7D73AaD75d8eD8406988B2C98D",
        "signature": "0x5fb1456cb8194f5eae595080e8d2608fe8e79a163c4fa755227f9215f1db663571cf8272dd9a84ee57f33eea13f917d346a886dce3ff73612b9d8a9fad2877101b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1eA233B537CBBAeb488C852Ec19e0EA1F6543236": {
        "max": 1,
        "address": "0x1eA233B537CBBAeb488C852Ec19e0EA1F6543236",
        "signature": "0x0cc29697ce1c17b12b084fbbb4e430bfb20b72b0aa8ba7d21992d28010ce33e520d3ab4da32b6fb6dcfe57620e2e9c979caa5504529c80f4b2fb521753b7e6ea1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF49861b506FA02465BC7C00A7dFb12d3900aaD99": {
        "max": 1,
        "address": "0xF49861b506FA02465BC7C00A7dFb12d3900aaD99",
        "signature": "0xd5a1083194d0dc29dc81f637f5304749105bc6cb8bb2ff69779840540d727c89294abe8043e038e7a26f84b1437c088a16ef0fa5d5bfb5a8ea504aff45af5ea51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8C4cB48c1400c7be279F9E0C8449EF4B61c77483": {
        "max": 1,
        "address": "0x8C4cB48c1400c7be279F9E0C8449EF4B61c77483",
        "signature": "0xe1ebea409d47cefbb01a0a62ba16b40676b17b4b57c10645919b139f6fcbe36b64d3998dfbdb696ecd44106c2b4d4a6a1757a1d4f274ab5e5d04b068b45571811b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3872C604eFd3CDDAeC3ddeF8C28d94Fcd3385d02": {
        "max": 1,
        "address": "0x3872C604eFd3CDDAeC3ddeF8C28d94Fcd3385d02",
        "signature": "0xd109fa8f1b1e24c5290249b03f64194a72f45e2dc0a30d49161f87591da2e68f4acaa8c5431cddb3611731ded38c3be77e30ef8c06a9610df81dd236d6e381ca1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0eC9A2b522bac8f7fE0cC80cfE9246e0194BAE1e": {
        "max": 1,
        "address": "0x0eC9A2b522bac8f7fE0cC80cfE9246e0194BAE1e",
        "signature": "0x71a9268aa44d580b7abf2897348230fa6cb7b72c55da4107f9aa0f8366a2e660668c994d1b8241d8597aa1448c386fb93d20d498a0152c80a3178424df5f04b71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe5f924a2D902966274bC71D4D53BA230191F52Af": {
        "max": 1,
        "address": "0xe5f924a2D902966274bC71D4D53BA230191F52Af",
        "signature": "0x6a818171b017d10938ddb631011d36402007074e4e26ce978699b02ab70462ca0c449d43020ddb3c060cf2357d03b1ba3afc406eb447b54c553b0c2bccb646131b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x728722C63F2dbA3D7dA9eE11B9F8843ba5071DB5": {
        "max": 1,
        "address": "0x728722C63F2dbA3D7dA9eE11B9F8843ba5071DB5",
        "signature": "0xad586a08f6f9b3cbe9f568a7cf65905995d911efd3541e0efb7943a999d66ff65ef3a32894001ca7a43b4c889bece5bd7f036e922ceed0bd60a77aaf2d7b893f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4ee71A9e87968819299aC8a58eC2a7EefA510EC7": {
        "max": 1,
        "address": "0x4ee71A9e87968819299aC8a58eC2a7EefA510EC7",
        "signature": "0x8e7f089c1cc1bcf4a81d34265bcfef74632c4867f22bd629c3f504c4f5fb86a717a240b7167921dde37e4c36ce0e12e870b787065dbad2e8ce73f053e85828df1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x04b640D5f8c7c1b483f390b010dcacD1Bc47cbA7": {
        "max": 1,
        "address": "0x04b640D5f8c7c1b483f390b010dcacD1Bc47cbA7",
        "signature": "0x6526b8acf8797bbf6dc69e43b7d80dc2726a4c7eac9567c51fc9a62978a2816f1f3596bb41ad1258da3c4287bc1fa82b6fe4feae4afedc190c9509fc5813b45d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3C3a9f32Bc777b457267aDca42739e5A2BCca12F": {
        "max": 1,
        "address": "0x3C3a9f32Bc777b457267aDca42739e5A2BCca12F",
        "signature": "0x1fbdf2372d1c9d01345689dff781899e1a0fe4eede6f186ce23fb599b09b33313ad2f9ca38a693e9d214e201dcb9ddb2cd941dbca369298fb385bd24bd0c9ea81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE35DD95bE58a690F459E53863eA08AaFfc070dE9": {
        "max": 1,
        "address": "0xE35DD95bE58a690F459E53863eA08AaFfc070dE9",
        "signature": "0x963a26dac99b4536fad8784b469e065bd9f416eaecd94364be668e55f597991348eabe041e934a90ab9d835cad8c14d278fc193edbb9a25d1b3b731c64afa86f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x52BcfaFd15b68912D9e7AFa48DDE17eA7FC20010": {
        "max": 1,
        "address": "0x52BcfaFd15b68912D9e7AFa48DDE17eA7FC20010",
        "signature": "0xab3be4e5b85619ffd144cd6cb67aa85f20253a08dd526078e020376d9e76e1a0404822ba3a20cb71d5ea2668c42aaca7559a6b820bada196d909761617d9c46f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0763cB7FC792A0AD0EE5593be50f82e2Da7aeb09": {
        "max": 1,
        "address": "0x0763cB7FC792A0AD0EE5593be50f82e2Da7aeb09",
        "signature": "0x870edbfa450f04a24811b1181edf4f1168428c9072d822b2c2700b8013dae7b770cfa04051f404ee54f31bc152b3f4e00ab195e60990de1c49b01757e1dec9cc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8F140829C52B06BEDD29EE3d8dF018035992805D": {
        "max": 1,
        "address": "0x8F140829C52B06BEDD29EE3d8dF018035992805D",
        "signature": "0xec122e8e28f739ad58d6d07e1474d24138fe7ed6d4bf9c0e37e165780437ea5a1d4f6e9ff8e57b3cc64386c049780d54eb52323a1372273f65b1c5c8350f45cc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0040347b5f6d17C6Fd3969103246a70B234Ce80C": {
        "max": 1,
        "address": "0x0040347b5f6d17C6Fd3969103246a70B234Ce80C",
        "signature": "0x8ee9bc881f62ae813760fdd17f45818ac860e9b1570247209d30797bd8c524de01a76317b5bd0b09899d9e7533acdec5b37eeefad5ab5dd4cf5b2c0d8a166ef21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8aF9dbbC1459590f435E9C476C8964bAcC2DD3C9": {
        "max": 1,
        "address": "0x8aF9dbbC1459590f435E9C476C8964bAcC2DD3C9",
        "signature": "0x68628c197a18a85c8b4faf17e57b4e3106f2bb8c385ff9afeb44e849e35b83a832acc6da999edfd1efb686f043ea31aa46b99a5402fa26418fa3b4bc222f44511b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe12797E367589ddf880B38EE3002FBDa6a6d61CB": {
        "max": 1,
        "address": "0xe12797E367589ddf880B38EE3002FBDa6a6d61CB",
        "signature": "0x5c8d3259df7c06783ad07afefa022b9dd2885df9893bbdb8425218b1cf8dfffa5bdc50393e11e752826e532c19b73df6518f82eee66c79fd6c3162d1482c333c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCC3c18D2ADE601C59A3711E2A753B806B919Cc55": {
        "max": 1,
        "address": "0xCC3c18D2ADE601C59A3711E2A753B806B919Cc55",
        "signature": "0x2a432d7d2991d5dff9c9f4b118ff45be1c0b477a0750b51f73f59fc831d695ba1bfb4bcd291accd75b7e7cb55ad4e48c70540c536245fb9d1f646e0eba8f40761c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x42608c122255129551CEA518c2f2a3CeE1F1456d": {
        "max": 1,
        "address": "0x42608c122255129551CEA518c2f2a3CeE1F1456d",
        "signature": "0x6c412b8f22ffc846a6397fe10e351ef8f383da05c2781c2d5d7a14a8c1322a8c3e8c561e23306e864c7c0cfe62462d2b0c7568420a5031a5b3f8f5486240e6321b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8a08B4d367e4D700A031aEE1FDf1678acB2bf454": {
        "max": 1,
        "address": "0x8a08B4d367e4D700A031aEE1FDf1678acB2bf454",
        "signature": "0x96432af40b890832f35f2ca168e25adfe40514711e223f6daa28a46a3bb687c22c1be1e3c51d5f061e889d631419e74e07b10e5e6ded075844715a5a93bdcba91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaD77D5241450088BF6760B068C9a43424087fd40": {
        "max": 1,
        "address": "0xaD77D5241450088BF6760B068C9a43424087fd40",
        "signature": "0xd6ea230ead09c6f85a2a46482619b26af1898dcb92c5d1c32a815a4270f54b4065048fad7005f4bba1c9d7c76306cf60cfc662e11ca60d3be575a1b331b96f0f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB15dBda8FA9e3eBEe1c57754a0387977171269C4": {
        "max": 1,
        "address": "0xB15dBda8FA9e3eBEe1c57754a0387977171269C4",
        "signature": "0xdcdb489ad616ec4dfbe7fd6b08334dae8d8b7c4fe1ad14d1e264eaabaa28f2bf27acb25d3a26a6b64b48fca0f1979fa87ee8130b9a706010b255e3bde9cc255f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb895d47Db05E057e0AC5fa3456f3D0F58313f71a": {
        "max": 1,
        "address": "0xb895d47Db05E057e0AC5fa3456f3D0F58313f71a",
        "signature": "0xef13154e9a45b1c3a8b99018f25cd190e0a8acac45f346c8cd712501729e877556f02ab2dab9c23476c1a436d50c91aeba067b72275da536cbb0caa5dda1bf261b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4cFFF901a7393822CE74B7aA6C2f1d2b4aeA859a": {
        "max": 1,
        "address": "0x4cFFF901a7393822CE74B7aA6C2f1d2b4aeA859a",
        "signature": "0x96e864bd8cd32fd4c28f542ec1175047726ca687ddd84e42faf7db2fa600056d03c1ec8516ba027391cd93be083379a3d5037abf71906a3219457d0d39fdc3eb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x79B861D92f132A9Cc4bd11b5682753E21AE44055": {
        "max": 1,
        "address": "0x79B861D92f132A9Cc4bd11b5682753E21AE44055",
        "signature": "0xe0c0df0b35d4d2e454bcc578f74ea8d6c4cdc13e92c83106c55d2fd895971261344d7c84217da7425ac63d33559ab6008e756087050b18eeed9519a1a0849dea1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x35540a42A32554f01aDeFAA61A038b571bb698B1": {
        "max": 1,
        "address": "0x35540a42A32554f01aDeFAA61A038b571bb698B1",
        "signature": "0xc915b3203259fe90e8c70e3b34cf3516f518a1f9dd4dbf06137a9efa08bc664e6b743f5cc647a5c8d6d87009dfaee014a9770ad1773ed666d1c3cb8da9b837c81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7fC855d576C05189DdE9910602e39F1C35D9984d": {
        "max": 1,
        "address": "0x7fC855d576C05189DdE9910602e39F1C35D9984d",
        "signature": "0x39523de50b93f7d124e9e368cdee36915b69599b832ebf3149e5dc5a3f491bc31269893866eaf6af074d5438df21257c8b20ce2b749699cfd38272855ce0fbdb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEC1d5CfB0bf18925aB722EeeBCB53Dc636834e8a": {
        "max": 1,
        "address": "0xEC1d5CfB0bf18925aB722EeeBCB53Dc636834e8a",
        "signature": "0x5d79582b581f39a225ec22acb58f86de03ea20de83ba0a13323378d40505423a29e7b8c5a68cefcd15247aa71092b3918017e2c7db9173293f34d6a299609e1a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x98Fac076e29CeBb34D04747d2c713Ad119554EfE": {
        "max": 1,
        "address": "0x98Fac076e29CeBb34D04747d2c713Ad119554EfE",
        "signature": "0x52e69896b8272de047cd0b578b6176ff01a5bcac6cdbaa6f2943685bf1025fe0489f36b7bd72ce68fa8a702346305ca977e0b771881039667cba3ea03c8fa7821b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9168d7b5135Eca43D748743882C41D550d49DeF3": {
        "max": 1,
        "address": "0x9168d7b5135Eca43D748743882C41D550d49DeF3",
        "signature": "0x2d6d3d170a1764bafe11a5de082d2b6f487071845ab4f1e17ce276096b22f37d6bd491c5e70b027e5b07c52a711809d7d699ae1aba80aa365852a42a3c859d721c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7A6Be82e4268086D8BDb723F5ca7e3f47699528F": {
        "max": 1,
        "address": "0x7A6Be82e4268086D8BDb723F5ca7e3f47699528F",
        "signature": "0x21509fadbabb093aad2b4722ce13c0ab9cb4364f3ce288d8228dc485189045fb3a71cff2dbed9ddcdc806b8f72d99d19105118ae4ccfc59433abf0530fcf67021c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC174d55ae464943E439E3B6150030398fF00F234": {
        "max": 1,
        "address": "0xC174d55ae464943E439E3B6150030398fF00F234",
        "signature": "0x4523de6db34d5bb848af0552881d77aac701bffc89709322c945f7a4be9286e551ce6281fc866fc9540366b5f7dee4cebe99bd0754b11863ec7fbc0a9d35c0961b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeF7E9eC69C9BE9D0EF61E29f71Ced2Ede4138dCF": {
        "max": 1,
        "address": "0xeF7E9eC69C9BE9D0EF61E29f71Ced2Ede4138dCF",
        "signature": "0x4ff6407ab5b807fcb35dabfd96de4f39f60710e30e5548c6f93f93abf0ab88936fa95e89fc19c92b808298fad8f8adad987e562c47196c8e5cc85211abd4632c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7067ff4e106Ef1510d6A1F5dc58b9d63DA5B6a3e": {
        "max": 1,
        "address": "0x7067ff4e106Ef1510d6A1F5dc58b9d63DA5B6a3e",
        "signature": "0x2d89f899b829c271bf279b6ed70d53f5b7c21f212984f78af986312485f8698b1ceee9abcdaf8a8266c7e4dee90fd46b9b0a8a854a8c35ac38e561fc849fb8d51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4735Fc4c75C08845F86c0CB403a5c46E6070874E": {
        "max": 1,
        "address": "0x4735Fc4c75C08845F86c0CB403a5c46E6070874E",
        "signature": "0x03e6bca5712b849b12240547923a077a255946d1497323e2ebadb0eae737ac8a2a1247f31ee5544e32439f977ebb14518f949dadd066278bde76a538b29431b91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9Ea60a19Fde50c9087C38b5b6D393Df1F5180cED": {
        "max": 1,
        "address": "0x9Ea60a19Fde50c9087C38b5b6D393Df1F5180cED",
        "signature": "0x9730e9d39789d83d3d6f2ae14cd3cbf52f878c0bfe911bd23f48793acf9776ff1b7e02a2d8263d13a7386f4475904c8ffed50cf607fb15e5ff135824c8bec78e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x92e51066B0B245E24C531a829E5Cb5386202b5e1": {
        "max": 1,
        "address": "0x92e51066B0B245E24C531a829E5Cb5386202b5e1",
        "signature": "0x38a3d72edd77dfdf8aa0bdf3b56b16e15d3dc4cff9271ee0f0c68c87364ead9621b31d293a4eb94e975cb22d497e6182a0dd6b57687777e2d02fdd6c265162221c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3FF6517C30BD615d0A3c51EF2460F1886625C900": {
        "max": 1,
        "address": "0x3FF6517C30BD615d0A3c51EF2460F1886625C900",
        "signature": "0xa8e317e5dfddfc857d2de342f33da0ff2bb6e6df164b6016269eb9181154d1f16b2477374be15d4ac1a28f9538d3b241d1f247a4078f91174b85fe4502ac010a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x427fEb9E79f8b97Ad30e0dB63faC9e92819bb785": {
        "max": 1,
        "address": "0x427fEb9E79f8b97Ad30e0dB63faC9e92819bb785",
        "signature": "0x6385b7f4eb88d99d6b7240fedddf4302749efefee862ef1c354344e8ca4fb1891000248d10b544cd9336c17463e80dc0cad427c43ced53c8956e3a45b96a3ac31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x844032cd3f9fD6CA246adf8aFf55616f93D59bA7": {
        "max": 1,
        "address": "0x844032cd3f9fD6CA246adf8aFf55616f93D59bA7",
        "signature": "0xf43f266c7e4b312d16c3e66cf52ac68d33f8850295ec7353dd4c625c3ad29d736ad96ae327533ba7fd87357c716d7d732a088e52ed6035fc40bfad8199591b211b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x633A64Eb685C27fc665556EDc96C18FffA991B54": {
        "max": 1,
        "address": "0x633A64Eb685C27fc665556EDc96C18FffA991B54",
        "signature": "0xd649af0f7ae2ab8e77910ff58cc8aff511e22b4d23f1b6f40932d1ffa10533e05ce835b7105be27266dd47f449b696639b85eefee2a3fa19369bf7fb78dd64ec1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x33A1697b2DB47124F72Ab12F8745889ffAfeB819": {
        "max": 1,
        "address": "0x33A1697b2DB47124F72Ab12F8745889ffAfeB819",
        "signature": "0x695d1c98595592f58a88c3b514cb18bff9272776c167e11013df6d597712d94f31f44d18fbfcf93e718d9e4ab1559727a6893a226c31bdee00ed17d5f673cc731c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x58Fea8b7Ee765B1D559E025C4F456485d2DeE1db": {
        "max": 1,
        "address": "0x58Fea8b7Ee765B1D559E025C4F456485d2DeE1db",
        "signature": "0x44102430ea7c41fde08f920d29a70b1a09354a2694d610e1ea1938d9f9fc05ad0ed5879a4ff091c0cea3788330b89a4403a2bd26f8ee84127133749979eb3f5e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9591c6817ce17edcF58827aC3C22d2064120C389": {
        "max": 1,
        "address": "0x9591c6817ce17edcF58827aC3C22d2064120C389",
        "signature": "0x69aa0474ff509ab722a9384294e76607353b11c45a51c55606933d892af5c6a1764348a5871e36fa5cbb0fe3fceb177019bf195102a9c9a61f529f963d0bb10d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa710CA95150B2e7ADcDDfCD06543CdB9491a99ee": {
        "max": 1,
        "address": "0xa710CA95150B2e7ADcDDfCD06543CdB9491a99ee",
        "signature": "0x1a6c1188c4bc400d4d2dbabd221838a0f52667d3ca8dc8dcad32a7918edc67f5372de75750ce65d040a4d4731d23519dd3f1627265960748f29b05c3ba92b7c21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAca3F06100f998a89b52d785BA89A827fDF04085": {
        "max": 1,
        "address": "0xAca3F06100f998a89b52d785BA89A827fDF04085",
        "signature": "0x9af5966aa03e407a865cee77ea4a7de2760647e089d9e83ad29ec51ab0c93d295b7c566ed3b66fcd77fcb05baaa3ab562e8e9b316c270afab93ad52e5c04ac701b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0e41709590698084887f194C32735acF2D064809": {
        "max": 1,
        "address": "0x0e41709590698084887f194C32735acF2D064809",
        "signature": "0xc7b01d8606b4b7e9e28a5394cda88d50ad2bff263d1d869c4c0b64a59b8e785671e670d9a6c1716fe3e5968ab0079a640c5a4a4bc15e94a477f1fe13174960df1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc7cF7f77A4973C440f6FD5D68beB2D6c62FADD12": {
        "max": 1,
        "address": "0xc7cF7f77A4973C440f6FD5D68beB2D6c62FADD12",
        "signature": "0xbd69722a4f029923e833b25aa71c82c4290ef3268f0f1ff642154cb93b8776f0481225987a1f2292ce2d487b33cb9acdcf7f84a69460c6204f44e37ff33e1ae11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDC638b317a87ECC06d68D6539187bCB99Ce1c45d": {
        "max": 1,
        "address": "0xDC638b317a87ECC06d68D6539187bCB99Ce1c45d",
        "signature": "0x71800a210724faade309f363d2803f420b88ae747f2ddb903190d65847e5a3ca15ce66e3afef5fea2ffdb15e9eb3f05d67bd62a28701f33babf7c570c7ea20eb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF1749bDf6778d3FF38BD69c08452a3A3E0034fcD": {
        "max": 1,
        "address": "0xF1749bDf6778d3FF38BD69c08452a3A3E0034fcD",
        "signature": "0xae00928963dfc97a2c47320a7b6a33522bbe9f627003f6626640110d185230121e66021f47d128ae3d107e15277f6628b89b11b4e5c0fb5ed3a7a2f12561840c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xec1BA32267A1b0ed615da332a493e1Cf87Cc79c0": {
        "max": 1,
        "address": "0xec1BA32267A1b0ed615da332a493e1Cf87Cc79c0",
        "signature": "0x92e9da3d7f945e1c3ec5524025a760d1ca6cef08c188faff3f1b61e5a03827621d416315af1e7a44b35eab745b7d63ebb43296e625e99387dcb9f91277875f071b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFEceB07d5B8F4520EaA3401af0157F2dbF449eFc": {
        "max": 1,
        "address": "0xFEceB07d5B8F4520EaA3401af0157F2dbF449eFc",
        "signature": "0x3de1299d4670504d1d33e1888cae46371f7bf7b85b30a16f55b67a65cb4675fd67896ed927c1fb632bd30dcd297793f6504836d941ce7e995c42fc4cf0d3e95b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x426b7da1e4d2253EFa01af13f792eed9eD57D2eD": {
        "max": 1,
        "address": "0x426b7da1e4d2253EFa01af13f792eed9eD57D2eD",
        "signature": "0xbb95032bbdaf929170670d0c0474655ffe1c9c212f8171c8fd7b9aefd336853f508ae0838a0f8d5ddc04719b4fd3e70b9d66f717ed76cf995b5db4efa7ee5fe11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8a5a59cDf53878f3f3F3E1A169720AeD4A5FC31E": {
        "max": 2,
        "address": "0x8a5a59cDf53878f3f3F3E1A169720AeD4A5FC31E",
        "signature": "0x50014087b8039ec4db2294fdd1b1ce3dfde5f681d8ac63bca71ac583916c45c72ef90535f33b6e36561752248368c1afb7d220b3df3783091fcdc39e97dce96e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFe1B9DF4e601Ef59B0b9bB9Ae0B8D8cf0D1E923d": {
        "max": 1,
        "address": "0xFe1B9DF4e601Ef59B0b9bB9Ae0B8D8cf0D1E923d",
        "signature": "0x552286e5c4c596ff2877e04cc2562e223a74e798f4a72af205892400be830362086b6c4091070b50793ec0e8d8488b752767864e8ff4d8358717d70d81fae82a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4808fd40E3A5C30f0B2aef4aE7591bb3e1248Dff": {
        "max": 1,
        "address": "0x4808fd40E3A5C30f0B2aef4aE7591bb3e1248Dff",
        "signature": "0xfbbaa276d7b128d4579eb0d24a463bf9b640689331c033642459b07296eae3cb55ba1b59d6a1d4946ca37c33c3e8804d71d81a73dea4ee7db31cfb9ef9e0377f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeEd972e3E56ab2B3236C57239fEED3465C96CF3E": {
        "max": 1,
        "address": "0xeEd972e3E56ab2B3236C57239fEED3465C96CF3E",
        "signature": "0x74d31a2f1578b9dd1bd4b8eb7d31facfd3beaa1a98e1fc62f75211ee32dcfb582d577cbb4edf027ff616aaf610c582da2783890c643311e4b9407224fd55355d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9D64ed6aB3C1D46c920FA457377e21A8270e70bA": {
        "max": 1,
        "address": "0x9D64ed6aB3C1D46c920FA457377e21A8270e70bA",
        "signature": "0xa6048614f5ee1ce746b98c5e4106decef26520f1f111f1aa6223d88d37bcf0ab3995d8d2f387bc96f66fd3bcf9f17bf343996ab50aa500a9b994121973f119dd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7161f08905e9735196749586AA581743A41840B6": {
        "max": 1,
        "address": "0x7161f08905e9735196749586AA581743A41840B6",
        "signature": "0x9faac721d924dd55c9cfc5c3203d8530dee52259ba1cfd52da2f42e2baafe5230ad60d4b30a578eeec6e915e11cddc81990074d091b7af0aa7cc4bc4f4b5c15c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9Fa8524456979bB3B11cA9FDD2C49Da348cB11f1": {
        "max": 1,
        "address": "0x9Fa8524456979bB3B11cA9FDD2C49Da348cB11f1",
        "signature": "0x85f6bb66f1d54217879921ab44ebaa53d170cd1bc20062e21569382128e8e7c616c0e925ab0fb1cdc86d58b6182a0dd656fd8481c859cd0cfe86bb6fff5dae811b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5504Eb56A5afDDa0C13c8ad34639F07821A76859": {
        "max": 1,
        "address": "0x5504Eb56A5afDDa0C13c8ad34639F07821A76859",
        "signature": "0xa90289ffbd06aa1fbb80b201fe4a92fcac8393cc135546b84e59d175308dd0725b95bd8ad30c72f2fd70b63188b253bd027b3d6ce85fdc32dcdc0976254032e51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFd95709C0bCBf738Bc96b0fCd088efc22A1c5A15": {
        "max": 2,
        "address": "0xFd95709C0bCBf738Bc96b0fCd088efc22A1c5A15",
        "signature": "0x0231a57d203095e3bbb85070f1dc2fc44c86ee37ed69c476db4b0dfea2bdbaba23813dcf3dfcc240d2e0feaeb3ee81b397d5977937dfffe08ef09e436c3677601b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf6Ac8932af75F717b2a6D656e45bFe2646a84dA7": {
        "max": 1,
        "address": "0xf6Ac8932af75F717b2a6D656e45bFe2646a84dA7",
        "signature": "0x3a2b49b374d835aa5c0eb1c09acd3e5dd6a1703176faf72f21f4a092e7a71390331f1c59e6a7c3cef48b6724136f01e50214a3d09cdc30a026fdea5738fb21771c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2794dE4D1AC1B4c630bAB46dF9029651F38446cb": {
        "max": 1,
        "address": "0x2794dE4D1AC1B4c630bAB46dF9029651F38446cb",
        "signature": "0x040e55e445f578f266dbe080ea0db0127d78b393fd8e93225738cf0be236bd8e091efcc3b00c671a9f4b897fb85ceeb923b27ef6f4034907c2b8ce8068bea9d01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE4E4C68bca68FabCc4a215BDCb669b933C3b2989": {
        "max": 1,
        "address": "0xE4E4C68bca68FabCc4a215BDCb669b933C3b2989",
        "signature": "0x14ca4ba06c4c3fa53c8963b3671c0877fbf7916ba1c8212b830e1bdbcbef84b0792a8b1873e34cabe984ad390e561a48be84438bb40f83afc039b97ff69b8edd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb439bd9ea4f5Da31a9Be95dd80e3b84f08d8ea1D": {
        "max": 1,
        "address": "0xb439bd9ea4f5Da31a9Be95dd80e3b84f08d8ea1D",
        "signature": "0xcf38b62f2757a203df7e1339d3712a703170af175dbdad7bc191a25cceaa72ae718315c3970976181bb1c61d1f1e23d1b1b8f3e0379dc8435263b56840d0ddfc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x066f7e1E5bF2c6084156357f8097955cB005E339": {
        "max": 1,
        "address": "0x066f7e1E5bF2c6084156357f8097955cB005E339",
        "signature": "0x449bd6b1595d6a47ce8c5d444b5f45f86dd8a0c9001b53a145c63c3ddfae4ab21477260737cfa9734016e1b296bb4a71458fafe3a172ac28aa9e92828b1508f41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1731A1e2053FCA86Cf11163220c7B1868C429dB4": {
        "max": 1,
        "address": "0x1731A1e2053FCA86Cf11163220c7B1868C429dB4",
        "signature": "0x4e076952147b1f011d9749ab6478f7a7f3f09fe8453b2b770f0b1ed2d274c467751b86b6bed339a4047c1d7f678b95db001f0ab2864cf1c5e67f703d412e765c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x98aE83217432a0bA8a9AbbB327dd34868aF54C09": {
        "max": 1,
        "address": "0x98aE83217432a0bA8a9AbbB327dd34868aF54C09",
        "signature": "0xaa96682f8a2cc5a23b624c5267488b7a751c404bfb2f697662befec03bcdae454f46480fcfcbe9ce3ee37f4f1f23d696775617a0205b3bb84dfb6ab9e194d2b31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9Cbf1D62c31009C624A40DED082000c2a5CF883a": {
        "max": 1,
        "address": "0x9Cbf1D62c31009C624A40DED082000c2a5CF883a",
        "signature": "0xf6d1bb910bbb4b67b84a11a26e952737e3e890bca8a13967a8d5b426b28dba064edeb001406322c77dcfb7f4b03241947e21acbe3545ea034d2724658862b9801b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x42fcA87ED77f85dfE198715F1817B0278a4ABaAf": {
        "max": 1,
        "address": "0x42fcA87ED77f85dfE198715F1817B0278a4ABaAf",
        "signature": "0xf5475f5d70df10fb117a0ada415ad691ab2846e36d8f4bfe5cea5c70d6d369bb558c58be48256448043b2716531d219e557e5f5d1fde9c404ae1edf5ce6fb2a51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2322FC135E6698a4e34423F2B519aAcd9964D2fe": {
        "max": 1,
        "address": "0x2322FC135E6698a4e34423F2B519aAcd9964D2fe",
        "signature": "0x0de647549f652acf599153f5af6d5d33035d97a06bdf415820709de5a3f88ddf6c600fa3446f5af560052c0f64ac3290f5bb61f48765083dad6c9707c5dc7d041b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2610Fe2A6746A8f39eF26a68090B9cf04d8B1441": {
        "max": 1,
        "address": "0x2610Fe2A6746A8f39eF26a68090B9cf04d8B1441",
        "signature": "0x987a58df17adbd54335ddf7e3b0fbdd12b112bba7e4e5af9c882a953c867b5c5733014228fe7057e02b767449976444db59b8b5a9b82e6e22b9d9203700c4f841b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB9c42271C16164BfaD487E5f16B60DA239B5E322": {
        "max": 1,
        "address": "0xB9c42271C16164BfaD487E5f16B60DA239B5E322",
        "signature": "0xffa4db2f380ffb9f0a24f5c51bba121fef27ff58ca0b51473c4174d264edf1c20b8eb477a7afd18487cd5c15495863dcf06fd270f6f557a67caeb5ee2f7074821b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x329cE55eE5Fb647B126B71038FD835c6BA0C99D5": {
        "max": 3,
        "address": "0x329cE55eE5Fb647B126B71038FD835c6BA0C99D5",
        "signature": "0x38f6a55d919f33fc6be1d86b8aa5321617f93039acb55d53bb8979d9bbe2c2740accf29ecab370f401d7a83ff9ea6e3d1d9795c7a9641734174073efae2c4c7c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x09DFbde2BBA8B763cb7970b2cbF18bf0Ed0D0a5c": {
        "max": 1,
        "address": "0x09DFbde2BBA8B763cb7970b2cbF18bf0Ed0D0a5c",
        "signature": "0x42253edb24e0c082a6342a8556fdf05212fd53d3b14f7c644c7019509da6acf8154af9e6ec7684dc09486adf0a1c572f5686a18c3368f443811fd30bb78834b01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcCDb41d3888cB73eD0876E90360Df3Dbd6889065": {
        "max": 1,
        "address": "0xcCDb41d3888cB73eD0876E90360Df3Dbd6889065",
        "signature": "0x671094788c6ede45963be993e7e633feda0460745c4e25277eadf9e5fbd783574fa4b414fd480770d7525a6a41b59f2a31c3577a0b8028a8861e616fd983f8481b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x461aDb309dBc497d22Bd1DD7267074C43e36C17E": {
        "max": 1,
        "address": "0x461aDb309dBc497d22Bd1DD7267074C43e36C17E",
        "signature": "0xdc864b23566c175d385cac803000b81866299f5da0df3c098cbc8ea106eccf162c1c75dad259db6c363ef24b8e11f20270ba3c1d50ef00530cb36c3b9f6e4a241c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1DC8A1653EcdD65771112ED6a88854EfF47b6BEc": {
        "max": 1,
        "address": "0x1DC8A1653EcdD65771112ED6a88854EfF47b6BEc",
        "signature": "0x0079b3f994a3e18c4c4e1ac1779eec5f837c543d77c15b1f920baa127f1e3afe7b62306225a37cdf9caf5fd7934977bf2811a5ec495d7059f955d34fbfc8a7d81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9Cc521118380922388eDCC5Fb7d4dd9fc28fb9e0": {
        "max": 1,
        "address": "0x9Cc521118380922388eDCC5Fb7d4dd9fc28fb9e0",
        "signature": "0x88a8acbb866aa078002c8d9ec0796e2cfbc13247c3f07a2a659c31d7ee2eab9923a6c01cd019a9716c7be5f79eb1e611d1893da5b67a1e783cacba3f88dc4a8c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA3DF1f3d4d7dF672eecfcE57E9250fE9b9541028": {
        "max": 1,
        "address": "0xA3DF1f3d4d7dF672eecfcE57E9250fE9b9541028",
        "signature": "0x456305fb6d1a043856a98ba47db7a5b19fac9ec6aa81b6ef3c5ba373bf3ef2ce69f0f9d5d01d1aee70687707b83f94f44cca8ca23f708f69e77bf358746eded11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2c49c790F9d4e58C302a5eEe5BcA8ff2EBc32bc4": {
        "max": 1,
        "address": "0x2c49c790F9d4e58C302a5eEe5BcA8ff2EBc32bc4",
        "signature": "0xd3a6b9b60560ca8b4a9000bdb85b9d9d8386199a93845e56fc873419959c806e307c2da078247c90be3f63ad8df9ce8baa21f5bdb55028a42e19414a279d325f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x097a7c618EC8fDe3f65CE1c7c7195F99D517De6b": {
        "max": 2,
        "address": "0x097a7c618EC8fDe3f65CE1c7c7195F99D517De6b",
        "signature": "0xab4845aa795043710f58b076abb7776c6b0bde41b6fe1d81503b186619352d1910e32225bfce2e5f2c3bc77ba263ec15f614b55a4b3316f25be27f7307df297c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd8D8c640E982a69E4E948359613EfE2863929027": {
        "max": 1,
        "address": "0xd8D8c640E982a69E4E948359613EfE2863929027",
        "signature": "0x375c965fef6e957d8dca05cf2ca701a12b0e5472a59203b3b41188ed9bc00a007cfa159e1d09bc270dabd5ac720034f6226c6a78f1b872a06b345c8e2b0c50de1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x092619eb6a157c3Eb9DEe3589Ca40F0F3B720b5f": {
        "max": 2,
        "address": "0x092619eb6a157c3Eb9DEe3589Ca40F0F3B720b5f",
        "signature": "0x9267e58731eb9d20e599e9ccb7e3fe97d541817a3a32a47bd2f8411f496da4bc4f4df306310b32553a21ad29e6968f2d32ea272f1dc1db79ff2968c1e0cb08a71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfF0CB31fd74B1a1bc08bC9830ab4A799B3c16474": {
        "max": 1,
        "address": "0xfF0CB31fd74B1a1bc08bC9830ab4A799B3c16474",
        "signature": "0xd56d50544a74d7233a7549e330f6745afbb0a04c3645acff1083ce5bb454c91e613ea2dfb8a506e249f7c43542c8beea41ceb8ad57d5a17d9d6bfdc647f910681c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeA5785D4BBeFeF0b52dbDb85dBA77A8437168a0E": {
        "max": 1,
        "address": "0xeA5785D4BBeFeF0b52dbDb85dBA77A8437168a0E",
        "signature": "0x1a0f776bb3fe9cab55cfd689613792b80b9da71620a04ae3a844f0619199774c4e73f7879374179218601fbf2d4cc2c953fe42677db35ad6072775b5b111a5bd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x83cF410B5AafFFa2397551B69886246F2798e70b": {
        "max": 1,
        "address": "0x83cF410B5AafFFa2397551B69886246F2798e70b",
        "signature": "0x2043288a74b439fbfdb410f3449690698f2021b12fba45a54e03308565cf15273acf7fc297eda910e17f74b1fe2f6eb587f40cf33ab17828ee9c1cca4565c3331c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x89A02b98B3776ce4b4af075D3c25c72454122E31": {
        "max": 1,
        "address": "0x89A02b98B3776ce4b4af075D3c25c72454122E31",
        "signature": "0x07fee31ba59c69ea44b6e814608f25efb6f06fa94b5ebf51d56165246f987dae74b876322a164e2f04a847f5cd4c9f007160220ad63d51ccb6cd73450f2ebfa21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x06BE72D7b49915D10A7768A7F44e97D042D8291e": {
        "max": 1,
        "address": "0x06BE72D7b49915D10A7768A7F44e97D042D8291e",
        "signature": "0x729874c44d9ee51bdb5d41a11fd873c6d9993b3ee5951b4915da648f26ce23a078b2cb95ea86112d0e1525901c3d05dbe0e42d27ffb8eb8f4904bba9ed6afa771c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x389D7925F691079A912BdE2Cc756a5482736d7Fd": {
        "max": 1,
        "address": "0x389D7925F691079A912BdE2Cc756a5482736d7Fd",
        "signature": "0xbe15596ce690acb601caf8209163240ebb041fa910f886b8f1cf00b2952c712d3910450856f66f2eb425d9a2dc1b5467f8d3d32892c627ca5fe791bef2adac1a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6435016aB96F8566ec88d815568f1700E1FFEe4c": {
        "max": 1,
        "address": "0x6435016aB96F8566ec88d815568f1700E1FFEe4c",
        "signature": "0x572baa8303ac0a9981494e148f8c0250ed8f6d13d15798aedf84a2fc9fd4e4de710368848e4202250bcf320d50a0db39cf7c2b7c298763cc6af0ebcb422e46b01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD260239e6c2a9ED894D88B16aC89487624ff74D4": {
        "max": 1,
        "address": "0xD260239e6c2a9ED894D88B16aC89487624ff74D4",
        "signature": "0x549c6a380bc51c9a5bae2426ce151f0b96300ca042c64aff98a49b46655afebe4506e57edfda6976c6c73eef45e2c2722e63e55ab8b3633dc02164da69e5ae301c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdDC9FD90FC8D5a5692455480d47Ce1C6aEe8B5c2": {
        "max": 1,
        "address": "0xdDC9FD90FC8D5a5692455480d47Ce1C6aEe8B5c2",
        "signature": "0x1e608e1203724aa2fe32d37aa7bdc2bd85d61bc8393debbc90c06905985b0ce85f36599289e0fc9d87f4d0e89f796b74e397ba3eaf5eb050888b6fa8d073b65d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x161C0600D3b3F195A91Bea6131E0642f4Bb41064": {
        "max": 1,
        "address": "0x161C0600D3b3F195A91Bea6131E0642f4Bb41064",
        "signature": "0x9b095ae829c4e29f62950f40f9dd7769bf50ceacb72a20356ac112faade4bcf976f4923eef62bcc8845abcd4b7ed3ccc42ddaac982a78f8f4e570ab800e704b21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x34979787d3f1A82B3Fceff92913e95C77f04878E": {
        "max": 1,
        "address": "0x34979787d3f1A82B3Fceff92913e95C77f04878E",
        "signature": "0x9fe516e0347b73fdc0f901c11d36aa423fb9c4409635a0281754a16b82c9b92f6e0a01e19bd2f5411ad5a5b86fdf0d42a5a5bc4cd11140d9599534dba2d64eac1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7af4f533E34f68AFFeB77eE73eCBe73fB4F87303": {
        "max": 1,
        "address": "0x7af4f533E34f68AFFeB77eE73eCBe73fB4F87303",
        "signature": "0x25fe5c26366b0adaf78159aeb780dae4e99f1f82181876ce789f6b0fe128a4bb26592bd5579a7b65d5f3dedad7db2ced4630f1c54429a93a76500dac9ba8e2251b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7a39303F2712107e0E7078810AeA91d4bcAC9419": {
        "max": 1,
        "address": "0x7a39303F2712107e0E7078810AeA91d4bcAC9419",
        "signature": "0xe21d2614708f253a48b0e6150c4501f647bdeb503f195e0054413ba2443399705e4cf066b79433ec56598dd3e1d2fb96704031ecf2b0b4c7d42b82d2e17bbccb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA5904cb702ABe3C9EAf1Dc1534a99A259173272f": {
        "max": 1,
        "address": "0xA5904cb702ABe3C9EAf1Dc1534a99A259173272f",
        "signature": "0x63a975f1e914f256de0e90e2c7c197b7ccf94d22036c7b40180945fd2a8c673d6ccf5251abba3b9284b9af5dc6179d2ae2c1e5b81182e1a7ffc56a3cd8c8099d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x59718703427CcE7d49D8ce73557438b2E76B4bD2": {
        "max": 1,
        "address": "0x59718703427CcE7d49D8ce73557438b2E76B4bD2",
        "signature": "0x3ef5d2cc31c66aa3fd3a8d5aad6be51bb71e91a50330f085c184430156e41fe10dfac4e7ede554831050bd562e58623d9a63ed557cfdc51374de77315dff05151b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9957Bea6dBb7cB980c5c02D8100287eb138fa846": {
        "max": 1,
        "address": "0x9957Bea6dBb7cB980c5c02D8100287eb138fa846",
        "signature": "0xc2f764231925ab60f437926c7c90d67020a3c3977540cd2f2ca088fa703a7fc2211ed6777e50091a92cb450a9da59b601c32140f09cf74b5ef3155faae11c75e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD0182764587c3877bfAf7A61f40104890F6Bb0e6": {
        "max": 1,
        "address": "0xD0182764587c3877bfAf7A61f40104890F6Bb0e6",
        "signature": "0xf19bc20482aeddf636849082bda03e43715e8a81d0d84625be874f8ebae8802f31a0a4e541c09d9de7663f7631a9a21e337254393892d43f975485f7b4b507271c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x39EFbD711Cee0bb866b0991A9c10b733174CbeC7": {
        "max": 1,
        "address": "0x39EFbD711Cee0bb866b0991A9c10b733174CbeC7",
        "signature": "0x4b0ef0cdbf82542da67f89ee76e036abc8fa9d256984c46bffd02d0b1c2c3c6b7f4cf7fdd9cf1284146d7ac863a1d61bf6990fbf8da91be9cdbc127e5ab32a0c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1Fdf11e5848bA230ff895e9784B8343d8e45Faf1": {
        "max": 1,
        "address": "0x1Fdf11e5848bA230ff895e9784B8343d8e45Faf1",
        "signature": "0x57fa9aa1811cedee1b1cb39598c722ccb77de1d848235f2bf0016a7a21a380ee740caa6fccc59b9e97f989e3538830c628d827c3a7de859358c5538e0f854f661c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf57C0a9A7E81Ae5cA983fd26Aad511e347CdEEc2": {
        "max": 1,
        "address": "0xf57C0a9A7E81Ae5cA983fd26Aad511e347CdEEc2",
        "signature": "0x19e4258754d45674b046a3dd35e5da62a46b5b0566e4f690d4526e5b376a9d27291c5e762be5a64675d07942d0a1e0cd55126e7b5b58aed45a277482d5346c2f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb0FEDB686e0568dc53F66173461025E5dBbA78F3": {
        "max": 1,
        "address": "0xb0FEDB686e0568dc53F66173461025E5dBbA78F3",
        "signature": "0xc4f1f6fb3c343b6cf7270cc1d23424f00a1073d3c5ba784c1f0ab6a3e9409a8257290e528d4a082fc70f2769f050c9a772cf78377b94f77ff67a0cb9e0728d361c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdFAbeCc8Df256DD6F248c529a70Ca349D61Ca1Fd": {
        "max": 1,
        "address": "0xdFAbeCc8Df256DD6F248c529a70Ca349D61Ca1Fd",
        "signature": "0x1ce89dc1306f079183c4c5d08091b5e33bc7242146c29b1d4eb18716169476cd7d62ba5b382e6be1f151c761bb9fef21c1077af6c526976c855229778502acf81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x457e79E04D33B27E1C64803C72D786EdF45a117d": {
        "max": 1,
        "address": "0x457e79E04D33B27E1C64803C72D786EdF45a117d",
        "signature": "0x2d52ab201badeab68d242ba168df5176ca82ed65a00b21ce4a6d48005ec0ff72123dda57f6b91b1453d95b120c9ed5cab4c036e3f7e4b3c3b2e9198df2b278a81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x24E91F9EC2Bc0aF21f6D33C6b5aBE2d37E68749B": {
        "max": 1,
        "address": "0x24E91F9EC2Bc0aF21f6D33C6b5aBE2d37E68749B",
        "signature": "0x2b5f53eae6028e9d0c11a2af0ec31ab34fb13e5f10e8980048ad0955882f8ebc1bf0f7746d1bd1b7456cebf6ec42a780152a271169c8b7972b763ac08392d1dd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB467f020eDB54c954A95B312088e5B1B81aA4ce6": {
        "max": 1,
        "address": "0xB467f020eDB54c954A95B312088e5B1B81aA4ce6",
        "signature": "0x220e2e49fc392a06f2cc890ba2a30947613c0ec1af01140139666c3513d22de50ce4a08c75a4d33248f195d5cdac03af85b12cec1e189f941874af55f6d447051c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2aB5D9f2b963d3d161756f74A60D530188087bfe": {
        "max": 1,
        "address": "0x2aB5D9f2b963d3d161756f74A60D530188087bfe",
        "signature": "0xe318259c9753c66503de3d829b0dba54e809ff6e5281fcc2e615379619f2e19f5cf546c975d0489ec0a5607550b3e0ecc093d4d7bb9f51da0631f896ff03c6df1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0745b719eF5aDBbF06d155b58801C2C1961f9EE1": {
        "max": 2,
        "address": "0x0745b719eF5aDBbF06d155b58801C2C1961f9EE1",
        "signature": "0x4c62a99a20bae516f0266fb09295ee68b1d1732c39dc2cb17056a1171a21a072513c1879352e82bde57bae8806e9facd1af2cd33a38b792ad15fecee390fb4131c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb640efa7BAFC3C83526b6863764D7F09aFD9eb79": {
        "max": 1,
        "address": "0xb640efa7BAFC3C83526b6863764D7F09aFD9eb79",
        "signature": "0x1d5f8062d2b95c9c177bba5b74c4970e27b12e5e0a42b78a0b283b6346f296c26567ff6d572d5b49215b1f4405ac273f1af7b43d49f1a4d060c2ab1ea882f2bc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x04Fd8d0C5eb0589d4d9625C9D2a8F5ABF27C395a": {
        "max": 3,
        "address": "0x04Fd8d0C5eb0589d4d9625C9D2a8F5ABF27C395a",
        "signature": "0x643205c23070a746a322a78483222f71469269146631145081623ce16b968adf5b607c8274bba61a97e8729f2ae757100f0619de15acb1f34b5ee8e34d938b4f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x239a9FC4d71b1728C19484Bc63291369b91EbF63": {
        "max": 1,
        "address": "0x239a9FC4d71b1728C19484Bc63291369b91EbF63",
        "signature": "0x3e85130e1373212f38a96b73808c4ae967df6d987258e76b1afa8ef45fd32e9b1fb2133b13930f6bf15e63b90566bf74acf88cf0238ffa68dccfb906306841f21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1D28BE12A93f5Dcf2F03F0A19C281a5b29619127": {
        "max": 1,
        "address": "0x1D28BE12A93f5Dcf2F03F0A19C281a5b29619127",
        "signature": "0x68fe26bbdc76a0b49a40c5b599bf36fea6a777fe5ee0fedbe4ba7dde4e750559443d2d98444af6e08fc43efdbfe85f866a782808716e3e178041128e201cabb01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeb612fE0ba23d0a0480D8bC5CB87ab9cfed2C9a9": {
        "max": 2,
        "address": "0xeb612fE0ba23d0a0480D8bC5CB87ab9cfed2C9a9",
        "signature": "0x89d79f0ba27a475a915a11b6ef49e547eda5f29bbca893c5163e128edcb6d5e46ab72e2305eeadbb6445b03a1fe5910193c75297422836ad7b9607d00d4d8f1a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFC11E0eA39b41b39455AA6E9BA1Ebc0dFD48Db6B": {
        "max": 1,
        "address": "0xFC11E0eA39b41b39455AA6E9BA1Ebc0dFD48Db6B",
        "signature": "0x695f829b066539d28d5fd0994cc13825e00f4192a6832dea39b5ca2ac16c43634f3fb74669cda48472583b4e46a3de589fdc7d891a4ba0ab058c668f4ab033031b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4FcE560Cc4dBbd1F19535247051BA802bAf2dC7A": {
        "max": 2,
        "address": "0x4FcE560Cc4dBbd1F19535247051BA802bAf2dC7A",
        "signature": "0x9d50107a927d7220daa901bfb250bc48316bb6fb897854c1b2493a653bb7c382737b1052dc025a339280714bb6aa749d9c314d1f00e468343bd4e3bff3045e991b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf6Fa561D74784c5a95E597cC915edA7B851B2570": {
        "max": 2,
        "address": "0xf6Fa561D74784c5a95E597cC915edA7B851B2570",
        "signature": "0x3eee27b099e3568234de29279a73658661dcd5af6a4a704938f9d6b6104393ef7ee050b67667a5b095c4bcffb2d6ac654ea682a7c1473d749baa368273cf96221b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8fBf82Ec4fbf6368baFFD7eeE2c984BAD482B9a1": {
        "max": 1,
        "address": "0x8fBf82Ec4fbf6368baFFD7eeE2c984BAD482B9a1",
        "signature": "0xd00d91ebcd1224aec2e3bcdb5b8b818a063624f170e7d32fe6e01d857dced2fb2262c73822d90bd999d20d9261222ab61ed7d8f5ac86b735d2e2c21592bfadf31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDF07F30157E5446A53CB6b49b7F236ecE773DF47": {
        "max": 1,
        "address": "0xDF07F30157E5446A53CB6b49b7F236ecE773DF47",
        "signature": "0x4d8e339e02355b92ee8ff012dc68eebf86db213868edb8be635a9e534bdd1f8454624b3a94706f7f7cfc9ee0df5992ceabe607b1e8e612f4ccc39663744850c01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8fC9114671f22C9f79df3fFecA3EbF672FEF3cFe": {
        "max": 1,
        "address": "0x8fC9114671f22C9f79df3fFecA3EbF672FEF3cFe",
        "signature": "0x5c605ade67865478f10ea4534f21c6fa81b4233d17402405102aa7a79a24e8b14b76e91a18061f280687605ee961d355d1b24e16031f3b47962c40787812acdc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDFC14cf2241F6065b3446674FdeE5164cF430833": {
        "max": 1,
        "address": "0xDFC14cf2241F6065b3446674FdeE5164cF430833",
        "signature": "0x65f69b679eab03e04cf0d547e88068eb1560c23e5fa494d2f9431121e82dbeee6509031266451cb189fe96462f7e23000dbe94a885b1d1911d88e0ecb638008a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3228698d5f7481A947D5Fd2734C7dbFF7603C315": {
        "max": 1,
        "address": "0x3228698d5f7481A947D5Fd2734C7dbFF7603C315",
        "signature": "0x6550f523cb6b71d92f16943430575d6bb34e4cbf3cba902e56110f8f7fe3a9c84281a897d009711900b8f873b6cf4a727d28e91b45ca3bfde711f618953ef5b11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x75D251f95888e963AA1Fe4a7E5b755F0b181AB07": {
        "max": 1,
        "address": "0x75D251f95888e963AA1Fe4a7E5b755F0b181AB07",
        "signature": "0x78d258c417b9946b797201138e81bdd99ff1331282a881cec561fdfefa9246d23e0e1479f892908e3563d5cc764caba9c3346c983bab5b2154d01b22b3dd70801b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9b1E4a9B941000595769534cA382D60521cc9b94": {
        "max": 2,
        "address": "0x9b1E4a9B941000595769534cA382D60521cc9b94",
        "signature": "0x77b14f2bceb64358ef0006d2b16a78d949f34354c2057c69c3057845b9bbef5e492de8ee5ed6907ce62648a0bc0ada51a4723cb9f0156f772c295516369c7fa81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x00C1e13B21Ce3e604D7CA9aA1B307A9C94a7F45d": {
        "max": 1,
        "address": "0x00C1e13B21Ce3e604D7CA9aA1B307A9C94a7F45d",
        "signature": "0x4c93a1f6cd5bfd0b71e14dc8952de79c310e4de8cd6f3f9f1767eb8f68a1cad148bfecc3e676784c982df3a5dd6e07d2ab278161075cee6138eb82f808e290091c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x89392330276aEF6d1dEc5DfCe1afcb19C1cEd1a5": {
        "max": 1,
        "address": "0x89392330276aEF6d1dEc5DfCe1afcb19C1cEd1a5",
        "signature": "0xe73ce422b4c9424509ec5f3c1785d6b654b41c12356cec99d8e212390f98ea4d25766b9fd1140bbef579de96d0da41cf2b4060a6ee06dac41538f27b5769fd7b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x003105F4FE6922Aa5b8eB7d6068aE60Fe529b686": {
        "max": 1,
        "address": "0x003105F4FE6922Aa5b8eB7d6068aE60Fe529b686",
        "signature": "0xdc4e3817202c84a307a6108dd1b8519dcd468311056a24662534fcd1805e64762b684f327c87c996ceae0cee6d7a862a3362e346bce37a734e60b50862d58cb31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC7A3994657fC06EBB575CFe1EFA424B9C9ee6dfd": {
        "max": 1,
        "address": "0xC7A3994657fC06EBB575CFe1EFA424B9C9ee6dfd",
        "signature": "0x4078607000dff2662cd6eb07474c8047464ff20ce8549a6a445863ea97a35aa852f17363aba2842f68d8378e58233f6cdd505230f11f1bdaab4f5d0aa77f11e41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x41e6732F0bbe183EaA772F75BCAC2398D817B95F": {
        "max": 1,
        "address": "0x41e6732F0bbe183EaA772F75BCAC2398D817B95F",
        "signature": "0xce8b605165d73b0fe7e0d68861cabe480cb8c05f0ef7ed85e73cef2de1103084214248fa4241360c53c215bec255bed1d3eb47fc8d3c13241f5ca35316fb55f61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDaBc6dC8407c24b9fEaB0ba1e2a48000f259a14b": {
        "max": 2,
        "address": "0xDaBc6dC8407c24b9fEaB0ba1e2a48000f259a14b",
        "signature": "0xf682622d27cb5f07e16309462c48ec3844bf531339c791df9e5d13fa756271bc2cd94bdb53e1865a5371b4350b629a88a12aee9f631231f42c9b5dff2124cc1d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x42c5d2C555BBc71fAa802CBA01000CEee68Bdc45": {
        "max": 1,
        "address": "0x42c5d2C555BBc71fAa802CBA01000CEee68Bdc45",
        "signature": "0x527e42237fcb4e6315e1ac210a17e76617a87bd60c68b0c781396e93fc94a097053d21603ac6e9d3fdd06836329a9976413cd21ebd348f9b24b11262dda731221b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x82bF1AE69A9C5d5A23b05F52FAc42d36f43c3e9F": {
        "max": 1,
        "address": "0x82bF1AE69A9C5d5A23b05F52FAc42d36f43c3e9F",
        "signature": "0x96336f02e3cc472c915369433647e61ede301c1725ebc675d08bdbb4079e408f7718ffcd37cb2961c396ee748221737ad38bdfdd579c377e61de4ad0deb54df91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4Af4f0Ea14070Ee3EB65335aDc2bC4C44b778428": {
        "max": 1,
        "address": "0x4Af4f0Ea14070Ee3EB65335aDc2bC4C44b778428",
        "signature": "0xb533881ef9d9cdd4fcefe2f20bc2c41673c9d3c9b699c8eb769eba18d65bbb4f0d861b8c2d7c80b2821690fe55ddb9028ee0f8c7438ceb90594aa0d219c146591c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x555fab084Bd0ccf53370a02b1B637DbBBacDDB8F": {
        "max": 1,
        "address": "0x555fab084Bd0ccf53370a02b1B637DbBBacDDB8F",
        "signature": "0xa7b0726884de19cf197a2382d249cfe671d3f3eb8c590e3c99783eb31c691b310cd44731c0140bf1162c2b2c33419044d51452e236d13aa5b3d12947391797c91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc529FD4E02F2872455E49931381761483297aac5": {
        "max": 2,
        "address": "0xc529FD4E02F2872455E49931381761483297aac5",
        "signature": "0xf5ca7b5a48d8f6a501e00e6f6c51dfcc32dce63bd534bddd83781bafa9dd429d28b085c8a0d5ad84722e22e81f9866f8a9cada6f26fc816c9304c0b4d7f943211c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf958C5EB003C527488E887605aB55fD76B6B9e88": {
        "max": 1,
        "address": "0xf958C5EB003C527488E887605aB55fD76B6B9e88",
        "signature": "0xde7b2e32d101feee00661d45cdf31dfdae54896009ccd4cfee87b8a2965162384866243512843b18d55225cf8dec5ba3b0f83b4eb48a57ef9693049fb429a2681c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0eb2bA24768175f9ebE98942df13a8D7646f8433": {
        "max": 1,
        "address": "0x0eb2bA24768175f9ebE98942df13a8D7646f8433",
        "signature": "0x23ce40692c9433533f3e9e468818198fea10d9766f6aaf5be686e04f92f74d7b1bb643ed46c6c3dfa61931c3a33082b79074a5c69abaeb5c465dfd32310346451c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5bd195fB9A55F334aeC2284b9c06Ae0791f10F44": {
        "max": 1,
        "address": "0x5bd195fB9A55F334aeC2284b9c06Ae0791f10F44",
        "signature": "0x663aa9d6d22379e2ceb690f99bdba1697abb064d624efd425962b7f7b2f2430a654ad6da3d7aceacaaa45a2a5ae93fcc3ded1d3808481c7becd357254731457e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF35cFfF6Eb8538CC3f230bC703240E80D841D5C0": {
        "max": 2,
        "address": "0xF35cFfF6Eb8538CC3f230bC703240E80D841D5C0",
        "signature": "0x154cf474f5914b9ae0723ef4e1a8837a83e8fe6c4b0fd5d6facc5e51a8a0e4d966eaf7deff431ef0d60e92e74c25ee469c8cb85c3f952a2d005c59aba6e702f81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEC01c8093342C3C38A5415D97f0d02DD6B1C494e": {
        "max": 1,
        "address": "0xEC01c8093342C3C38A5415D97f0d02DD6B1C494e",
        "signature": "0x5dc6a26b061d903c5785d5e9ecf5ae156f5366df6c4dd9f25dd5515b80b5e5bc44dbd26de1e6db84391f67a158fd351150c81b34e39070a47730dc6b6ce0c7c11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x988e3aCD7c31cC349E8d2eBa63187e542a64FFFf": {
        "max": 1,
        "address": "0x988e3aCD7c31cC349E8d2eBa63187e542a64FFFf",
        "signature": "0xd69a63cec70783016492904b5806cab5a7c0cfa41443f63a1caeae05460cdae311f642f02fe521e6a3e6199590744adbe61a192985b23df5693c51731f0f50b81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcB869aD785A15E1822841Fdb7a03D445c775ef54": {
        "max": 1,
        "address": "0xcB869aD785A15E1822841Fdb7a03D445c775ef54",
        "signature": "0x05eb375624041baa7502fe01cfbed8e56d8490208d44d8f0ef17dc12969f12ae3ec7e865e97b973db31308e586a7689a7bb17e89a657c0871a70b42187d216d91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xceA9F9e08954c2696058c327feDae2ac0Ec0DDae": {
        "max": 1,
        "address": "0xceA9F9e08954c2696058c327feDae2ac0Ec0DDae",
        "signature": "0x8ea5c42f9de98b0b1e599f0248316ec2c26dd2488bdaf9ef3dc0d32d646ba19e6bc8e4995235a43406abc1106cccae43f0e61e69cd05d1cc05ef798cb29d9e251b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x138Cb6Cb14C238d19dC93ddF5F681F3A4e30C911": {
        "max": 1,
        "address": "0x138Cb6Cb14C238d19dC93ddF5F681F3A4e30C911",
        "signature": "0x5ee372141b4872b774923ec0feb871dec86f258871504023e3d20ea24b15ca6843d84a4153734e690ee165219a3909bbd294a75af117bcea93ff345e569107551c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1BB6DC39ebC757Db05557d00b16C28A753A20558": {
        "max": 1,
        "address": "0x1BB6DC39ebC757Db05557d00b16C28A753A20558",
        "signature": "0x5dc22f185c0f6a0905faf9187278285f33233fa7dc0f97fbd8b5eb889f4aa5384a5925f42c271822320a30a9fa9858a759c9961505af67516e53e96112ef83901b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7f18076948B3E414A81113f05045F6c1408c3b0f": {
        "max": 2,
        "address": "0x7f18076948B3E414A81113f05045F6c1408c3b0f",
        "signature": "0xcb548ac0b48785d8da772664850b81f759cc6be9154edd37d22da208b6134505098703e50de52c1c91a58a9ff36deddb6c7de70d32573eae777e34b1de501cde1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDC1F1916CF9f23e0a20ed266c7337c19f15E72c9": {
        "max": 1,
        "address": "0xDC1F1916CF9f23e0a20ed266c7337c19f15E72c9",
        "signature": "0x6799a69f669db4c96dc928617e0e018df106a0ebbbf09ac71212aa815237bf5e53163d19b27cd4aa692c82f58b7e1fe60951d4dfebed6545f8ea729dd03561b81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd1b7Ba65398fFf9D581acf6aEB4CB80c216EF95A": {
        "max": 1,
        "address": "0xd1b7Ba65398fFf9D581acf6aEB4CB80c216EF95A",
        "signature": "0x2e8fd1dc63e156d045876956a40352a28cd7c5f0e2bfa30eefbd99c5375175614e7475d0b06e71dd8f700ecbfea301479f331d99a6049968e25945cc0610a6df1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfcd7d70E3a41fd96488604BfF7cC6156F386Ee3F": {
        "max": 1,
        "address": "0xfcd7d70E3a41fd96488604BfF7cC6156F386Ee3F",
        "signature": "0xcc4b0bd8912a903e549138df9ea0eb1d383447949ad08237629cede3da7903763c30c63ce0d41e5485ad10c0b58341a874b9de5798303f663e0d132de0b2ea2c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xac6dFD9678855F6993E411183AD43484F8A2a4A3": {
        "max": 1,
        "address": "0xac6dFD9678855F6993E411183AD43484F8A2a4A3",
        "signature": "0x934b3504528813993ccdd0465c7bf130018b47eb051d9ed13e16e1e5eb51ad816c1caf84a3436d3ff0673f8d1c576acab8bcc2b72e1837faeaf95e675cd222671c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7599c16dda1F7F5B266329f6d6e468a79c24483D": {
        "max": 2,
        "address": "0x7599c16dda1F7F5B266329f6d6e468a79c24483D",
        "signature": "0x37cae51b629cc84eb80ba374263e74ea34f7791354557d43066250ee6813ea8151af637a091c60ad1a369e69b544338f8db6a6d9cda6c331d5900f71993aaafd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x739158777D9b2E659afE8456cbcaAeDdE86678b6": {
        "max": 1,
        "address": "0x739158777D9b2E659afE8456cbcaAeDdE86678b6",
        "signature": "0x3a39d090299352ad7a4492a4da4e4ca36579bcd8ba8c952dd14eae06a4eb583f64d6cf62a39929424bbd35d9fffbb1c1068c64098c017e5f97545d978bd569f11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe260E328Ebe3eB62fa67F8d5560F203611dAFB00": {
        "max": 1,
        "address": "0xe260E328Ebe3eB62fa67F8d5560F203611dAFB00",
        "signature": "0x67a2a31183ccd577e095a439b42493e57b270538d476899ba74c2697cb65591f534b46792a0c95196d7b72737341306cf9ed41c4bfa9e26175b43f92e9227db41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x34F13109029482d71627e32F386B4B1C860Bb606": {
        "max": 1,
        "address": "0x34F13109029482d71627e32F386B4B1C860Bb606",
        "signature": "0x9cbb69ddda5801cd623efe3a3cc59aa159f5e098978bb7789993a0f6d6f736541a82db57558b15f5c98313cecbd65eddffa970cab166ee7d91b1f7e95e4d9bc21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB29479Ef88dFFf8daC466cF6C716e7985c4241c5": {
        "max": 1,
        "address": "0xB29479Ef88dFFf8daC466cF6C716e7985c4241c5",
        "signature": "0xa7c5ab9433d921c381bc385b14fd8df43d3855f6ca2ef2ca059fe1498a2af5891c8ce314b44101b5d5cb18f47f0648166f527c7a611047c58acdfb3090c465601b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9Ed2f6a9be011A485f8A7F85C239438F7F2828cd": {
        "max": 2,
        "address": "0x9Ed2f6a9be011A485f8A7F85C239438F7F2828cd",
        "signature": "0xba8983a36670807a87bc33390365941f7ae2e0a83965fbf6361365b61f1650d40aaf158e636dfae47b32a4054dab0f2816588b5c686e2a9fabc3cd2d064f497f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x01d570dBe9E6400fDe8AAe603452a99CC8390368": {
        "max": 1,
        "address": "0x01d570dBe9E6400fDe8AAe603452a99CC8390368",
        "signature": "0x8850846aec9baf2fc1ed1c08925fe3751059aab56508468f279f34376916c554564cedb8504261556719a3e579c68bf8afa49bf94e8f061222d9fed0b44e97f91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x68f545BA4D8915d2A8BCfB8128F5b0e356A9a6b2": {
        "max": 1,
        "address": "0x68f545BA4D8915d2A8BCfB8128F5b0e356A9a6b2",
        "signature": "0x7efec7df12ffea125c67671b2521a33df6e85d8a95580705a98a3a69ed707c890049c0e327a7e9b09131d713961845590d5a7a04468237b323b5c3f6fb920fa61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x83A45b56B5F4a7Bfd9C8A69A883B2C2e144B600D": {
        "max": 1,
        "address": "0x83A45b56B5F4a7Bfd9C8A69A883B2C2e144B600D",
        "signature": "0xb56ae079b0f89137231dfc276a4728d88c282c8390623f0ee8a84afb730c611a270a8ee6b3f6b43d5357b353da39314cf980d3bb4c911dc761c0025655a491f11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaEda34e8Be47bf150b533fFf64276963309fd281": {
        "max": 1,
        "address": "0xaEda34e8Be47bf150b533fFf64276963309fd281",
        "signature": "0x0d92ec24ce1057fd892222993d8fbe6da21b45d329d76f7bb36fcfed134444bb56a63ba1cc861a2786f8e89d759bdc4080da4e284cc406f1c4e484d2539a49ae1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF9313AD9DCBa735F9B0b99b92E381Da37C0f5F69": {
        "max": 1,
        "address": "0xF9313AD9DCBa735F9B0b99b92E381Da37C0f5F69",
        "signature": "0xc98cd03b72df3360e0a08756c29afcc097279587192aa0954141646f5c86bac1341d62df839a81e70cebafbee847941185953bb5475abf90e9dc28019643f9ee1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x98e9ce2be5a63b362428f635A7817D06395fDa5c": {
        "max": 1,
        "address": "0x98e9ce2be5a63b362428f635A7817D06395fDa5c",
        "signature": "0x114e9e6418ff8c9e71dc5aaf82420c42c19819b741fd82bf71c78ce41569b8de58f1dfd274053044328fca0199a5d8b50f6b2b731477dc5a5bbd78e9d49c36c81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x761b9557937E2E386ff40a86C8Df02f5bD5af76F": {
        "max": 2,
        "address": "0x761b9557937E2E386ff40a86C8Df02f5bD5af76F",
        "signature": "0x31069b4745b30a4e7d6460ac0e6a6850a623fb0fb04e24845545bdab3973d7d507304aaf3f977b29b1ea6e378660039f43f5dbbd97f9508c8378bbd1610cfe0c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5613343Dc71176C7A8e04FeDA2Cd4fDC5c92C3d9": {
        "max": 1,
        "address": "0x5613343Dc71176C7A8e04FeDA2Cd4fDC5c92C3d9",
        "signature": "0x21a790da758a5fca31f297e5b7c8f104c05030007ad9556f6684d9041ef2a91a01f0c841c8e4317bc6d21928390a1865c04ab083a4f712ed5967a1f7fc93251f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcBE4b88bfD151d1AB1Ee2f18E9fB98128892bFCB": {
        "max": 1,
        "address": "0xcBE4b88bfD151d1AB1Ee2f18E9fB98128892bFCB",
        "signature": "0xe7951b0d103ac029f985de1ced76df1fb832b3d97f0bc2e06717cb0b9b05f82d01237a8a574a8ad1d60b795bb1bcf981b0c7bb45ab2189e037ad060cf1b8afd41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD737D5005bA1D534737fe640E9FC236e7e350Bf6": {
        "max": 1,
        "address": "0xD737D5005bA1D534737fe640E9FC236e7e350Bf6",
        "signature": "0x958608ac5f3c93b476008ee431b35265dce756802cbd9dab14e8ef583edfb9522134b1cc6db2fd87226a452d76900d2c57ddc67c3919c677bb4bf58119e714c91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x822d86010D335D6E7c1f9b2Ea7359A061F575007": {
        "max": 1,
        "address": "0x822d86010D335D6E7c1f9b2Ea7359A061F575007",
        "signature": "0x0a40b2d3430decd7c0718a1c6e086eb6680f1e241599a8ee20617958dc9dd01b5e0696e3f1ec9fc0208adc4e1407783c0e911429cce7eca8422c36bbed599be71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x24726bb1C7996dBE80dae1e87799034125577144": {
        "max": 1,
        "address": "0x24726bb1C7996dBE80dae1e87799034125577144",
        "signature": "0x854388d05516b898631e3b606960d9c2c85fe4d74f9a956269d9c459feb02c8915d247fa50f9c30a7ff5b38641cdccfe7318606df7c5af9e95d9c2bb591ef5101b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3946Cf1593BC868003DA553B3238912702ce1686": {
        "max": 1,
        "address": "0x3946Cf1593BC868003DA553B3238912702ce1686",
        "signature": "0xc08e4729bd182ed645a64c0394123084edfa23a59e057e4120b0ce5831b38c777d2422681edba71aa103f14a2b93d9ef6885c34d124963ab1a85fe8f822e348b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB28F381AEFbA3d5337d9Afceacb5c95bb65B3772": {
        "max": 1,
        "address": "0xB28F381AEFbA3d5337d9Afceacb5c95bb65B3772",
        "signature": "0x5f0ebff7937133326ec294d6a58db72718b1cc256119126fb14f0c59f79f173818de167907673aa20c1d280a03860cfaa063b2eecae0a603ee2059f5cc2d859f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1DDa1a89E76FaCC2f79595f7B36E1ECEa6E6e7Df": {
        "max": 1,
        "address": "0x1DDa1a89E76FaCC2f79595f7B36E1ECEa6E6e7Df",
        "signature": "0x7fc132ead38ab2731e1097c93d67794ccbcc96f088753b3f56d762dad44bdc4e243ab8bb640b297dd11002db11b239a18364418a1e53c660e542ac52388970e71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x674D02bB189c345e689b846262167c222136e627": {
        "max": 1,
        "address": "0x674D02bB189c345e689b846262167c222136e627",
        "signature": "0x1aadccd9351daab77934f1a34fe4c8e7a6c6397386b433705ef8b40b55f1a79c5e690958f3d33b38d6f98baed2dc2956a998692e8c9eb68ac1101e4aeda255c01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5Fe45bd60Cd91b8BBD35F6194D499Ed7b672f2DA": {
        "max": 3,
        "address": "0x5Fe45bd60Cd91b8BBD35F6194D499Ed7b672f2DA",
        "signature": "0xeead6d29a04ff0062d1a85360e20af4c9d37f5dd1200aab94d96a66ea4b0ed781629ef2ea423666cbb3a85cb0964f4531cf57d53bd515591631769575a0919751b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x76299df088d06050F83a3363A1Aa21cd811D89E6": {
        "max": 1,
        "address": "0x76299df088d06050F83a3363A1Aa21cd811D89E6",
        "signature": "0xdaf06ddf00ee487257545348104fd8b4327a9fdd0eb6caef226d4f912c4fc5583a846abdeb53bc476b7c8d8c103475172d566688e2f05db6fea8ec024d245f6d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc0490D3333C34109e3B5Ce65bdF4254A5Ec1a7a0": {
        "max": 1,
        "address": "0xc0490D3333C34109e3B5Ce65bdF4254A5Ec1a7a0",
        "signature": "0x3b5cbc6fb75433cc3f731b69b74eccc0493a7223b4490db6004b50a88957a9d66590ce2d780de84ecfd249f66bd5a329a0d9c6556a372e30400e52f388750dfd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaf181A5223Df62aDb6F5dd4940cA04D8FacC8291": {
        "max": 1,
        "address": "0xaf181A5223Df62aDb6F5dd4940cA04D8FacC8291",
        "signature": "0xc3bb85bfe9615e0a6ad6eddef6cef4635d798d62eedc9f9801def025f0f394243e6426ac2791a2fe053ab1094480c21a00fc4ae89f58c3c7be9b38475194a47b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc5e4f05364B22070D870C6E90B5eE58b2816c293": {
        "max": 1,
        "address": "0xc5e4f05364B22070D870C6E90B5eE58b2816c293",
        "signature": "0x5dd53cb35d02055d7ae41a78fe4f162ff56fe15e8fc542e2beddc349dceedbfe33c5c4bb072f87bc57c7b0e13a7acf3ab48a14693f0dc929ba1b2cd50b678f901b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x01A530f781B0a08cF92938Fbb11528debece2C33": {
        "max": 1,
        "address": "0x01A530f781B0a08cF92938Fbb11528debece2C33",
        "signature": "0x3ee992774c11e22691640fd9ed536a618706e7d40f3121f7ba577b422150b93464ed22a7587c7f920d55a69b63f0e16fdf24092c5437c5f0079b4777f16d514a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2DB97Fb07d168811b4274E7e7Bd685b9fBb612A8": {
        "max": 1,
        "address": "0x2DB97Fb07d168811b4274E7e7Bd685b9fBb612A8",
        "signature": "0xe33e6a8c606d63f1d4c1a3053e1be82eea19d048f700d1593ac25efddaf3ccab4c6afe14ccfe6ea01885bbde7179e21afb4dcba30d6ba9b42d8f35f30fbe5d791b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa676b071280087Ad8cB773ACaa39654D5939F67b": {
        "max": 1,
        "address": "0xa676b071280087Ad8cB773ACaa39654D5939F67b",
        "signature": "0xe37ce0d5fae281672a2b423437118c715d33f926d1830cb6dad449476d774ee20fb3b8416a862991796d917d0c43e270160c0692ec62d41a9a259790374670011b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6dc8E39E6ACba732F4D267515839624Fc575D6AC": {
        "max": 1,
        "address": "0x6dc8E39E6ACba732F4D267515839624Fc575D6AC",
        "signature": "0x854c6dc5f255f9bb1175ce5fb8cdd1c464ba88df86709bdfb5c81ba428e23fb43906d18945276d8e3196c7a5d20e293df72bf5f6e33fad4345be8a95452156ce1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x48c6A83208f5Fc41Bd4A17856f4D8349c9f1365C": {
        "max": 1,
        "address": "0x48c6A83208f5Fc41Bd4A17856f4D8349c9f1365C",
        "signature": "0x0b2cba23e413cda626414a54b05aa1c1af57a001a08de2003249e4183c9b05246a8bbd1fbe47dca99796a9c4c65be327d1c764f10a56aa60d9b8fd4ac4dae65e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x91C4690Ce6fAab2B035D52408dCF597534437400": {
        "max": 1,
        "address": "0x91C4690Ce6fAab2B035D52408dCF597534437400",
        "signature": "0x705c81ebb9bbee3904929637171c4dbbef54d17a6f4f852b0da237435b118fdf54c568579a5a6e6b76796c5f51b0171a3316eb067ad928aa23041778b67d7bac1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfF4d6e2Af2b0E7CBDb64A529441DFA1dD516B398": {
        "max": 1,
        "address": "0xfF4d6e2Af2b0E7CBDb64A529441DFA1dD516B398",
        "signature": "0xc2b71dcc3ed11ee8bed366f2cbe70897606def413466c1838afe7dc8fd8974404626b42458103b6c2ffecc89dc9453c88e5c0e66b8f1f32904a43738e63fb07e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfa7254a300E1D33779D8107A5d4074B568B9Fb9c": {
        "max": 1,
        "address": "0xfa7254a300E1D33779D8107A5d4074B568B9Fb9c",
        "signature": "0xea88562d0e5dea6ce288944ec3698acf49742642ccb811eca8b5273e12f44857199878e5c3ddde8454fa3300435b5076fa8618f16ac3ea530c27a2f1070100431b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe1E07Fc57034ba2c6ee6f77A4B27bb64bd764cCF": {
        "max": 1,
        "address": "0xe1E07Fc57034ba2c6ee6f77A4B27bb64bd764cCF",
        "signature": "0xb7ae4a0792857908a7a708361bda0b741c8b0f8266c89d0c47e11322f233f2f75ed87de89338641fe6bafa499105962584cc62634d21f94686fcf9dd6175fa4c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7C800089d1B742e6F73e3F93BF98a9B57754dB19": {
        "max": 1,
        "address": "0x7C800089d1B742e6F73e3F93BF98a9B57754dB19",
        "signature": "0xaabd7c5567a04865f84ebb1aa65001fdde0a5f70e99375b5a6e709a58bbefaed3c3ce47eb2179168671a38b6204fdb876c56fd143c5a0e093fa7df040c5a18161c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x849Ec139Ff4A91ADF59d2e8c1b620dF35775f3EB": {
        "max": 1,
        "address": "0x849Ec139Ff4A91ADF59d2e8c1b620dF35775f3EB",
        "signature": "0xf3e446d7eb93ff53ad2df40a8a5558a082aa426d6630072ec9fa889c676e8f284dae393b2ff4250f72a8c92935afdc5cb3fd36456a0bd9289e30f05f8d0794d81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd725f7513144389dC2451c2765E0E4Ae61900dea": {
        "max": 1,
        "address": "0xd725f7513144389dC2451c2765E0E4Ae61900dea",
        "signature": "0x8e636cb850cfa3ec4589c07de5200e2cb0b43b197fd3cda43a06b59e40305258007aa314e2d911abd1187b517e527a87bb89a9de7118f038dcfcace826d732461c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb952F5E5EEe6b072527417830b619772234AE2f0": {
        "max": 1,
        "address": "0xb952F5E5EEe6b072527417830b619772234AE2f0",
        "signature": "0x0bd39d1f4af7fed152ff191da9204744eb215c0094b773a328ffaffecdcfc1f61afc9658b2b11c8ebd2d6a595831bc1de8f8790c49dfbc5df1a9e295e0b33bd41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbBff3cF4A0f87BBD240D250aae1B42ae8D69A357": {
        "max": 1,
        "address": "0xbBff3cF4A0f87BBD240D250aae1B42ae8D69A357",
        "signature": "0x2e6f7157f74ebe09ce6a135a59c4dfb565edb23b2c28b4f7eedcf3ac3ddbd53b6f1e5de6f114fcc5325a41730e143f06d82911bc2bda4f6ed3985e92bc4c02fb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF558a5e7EB73B9D382a033725e454D0E820c8963": {
        "max": 1,
        "address": "0xF558a5e7EB73B9D382a033725e454D0E820c8963",
        "signature": "0x665f9dcb4aaa9668f06a0fb055072226ffaaeb11a01be6b262db88ee5d90a07d1066c196ad74991ee5991faf5cb3e3a9e1fff96d0880108d6db02e5a617fd1d51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x78C003144Ebe84F54d43bA58383054a3f6E17047": {
        "max": 1,
        "address": "0x78C003144Ebe84F54d43bA58383054a3f6E17047",
        "signature": "0xd0245e503005578544ba08fa4898e1de39c09465c26a638d1d61786d80fe786a044455281923fe60a9cd9698c95b0365f52c2b4def31ec24aeec3c487e5cc3b61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeb6936620B8300e70F23346656Bf1eeFfFD908f8": {
        "max": 1,
        "address": "0xeb6936620B8300e70F23346656Bf1eeFfFD908f8",
        "signature": "0xc868d99b5919411bc4f273b0af7e2faa075b3a1596c243314921370d5d4bfe3f2d6cc8c08dc37b685f8c3d0c7cf5d80d82c7b46cacfc1356f35631e7fc7922311b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC072fC7095eeB23fBb4cC2E87A665258a4D2dB5E": {
        "max": 1,
        "address": "0xC072fC7095eeB23fBb4cC2E87A665258a4D2dB5E",
        "signature": "0xedcfcc5271809121c01f5ab4dd4350e1343c176e56da48cd392663dc00346052698a669d0e9b98be13f1767f27342c1b73a501675af4d17a0bc5f8813774a8881b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x46A2Ef74225423Ce13B4Ad479f71cb204b8Cc4B5": {
        "max": 2,
        "address": "0x46A2Ef74225423Ce13B4Ad479f71cb204b8Cc4B5",
        "signature": "0xc77cc032bee37bc73d1f569b76c46dcbc903179617d69252b6406ad8f9dd30147a6a303368a0093fc7e11c67aca9a9a39798edd062f192bc571b0dd9fea24b831b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe2D9D5fEf55c47fEfA8Da60Cb8B1160E0808bab9": {
        "max": 1,
        "address": "0xe2D9D5fEf55c47fEfA8Da60Cb8B1160E0808bab9",
        "signature": "0xb26883edcbecea0ff15787cc67e1427f854b3913e867935257c0bed5f963c86f23bb54398f5d3700ca8fe5f6b1442ed469b7ef250facfa5e3a2848b6143773eb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE1BF88cAE64D4862B75953c683029De1870AF74c": {
        "max": 1,
        "address": "0xE1BF88cAE64D4862B75953c683029De1870AF74c",
        "signature": "0x5098e9b2e7e869a404152541dac907d00cbe7d786815115312fee30452e03c12216a7a968a7ee06e250859e986e7f14c255a94fbe8ca8efb35b75f7175eff5c41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4e1067921Fa725ab30A6a1B0084855e91A111fA5": {
        "max": 1,
        "address": "0x4e1067921Fa725ab30A6a1B0084855e91A111fA5",
        "signature": "0x5478286757512c2348bbbc4223797824545a86dd10cd22ae011fe6ba6cca7e077e91d5ede00dc320fbac4234a25930ae1a7434bf097e16b80966775b8d25df011c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8CB55ab9D5d7E1671094456f98063F8f8EF3248e": {
        "max": 1,
        "address": "0x8CB55ab9D5d7E1671094456f98063F8f8EF3248e",
        "signature": "0xd9daa92d3d69ac868e1118af15a32e9d9b77e878842e623818302e2f491d80e619a0a167b31ac7a5bbe224c1a21fd886d6e827704bb0a096a29a5059b835a4af1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe449C814B4451D5AF98f3Cfa529Ea3928Fac9103": {
        "max": 1,
        "address": "0xe449C814B4451D5AF98f3Cfa529Ea3928Fac9103",
        "signature": "0x1ab95686fc37c8e6d96aa11e7fddfde9950f1d2d0219fb5f9a791d0d91a1e9de2488ccf0bf461124c6d67d51eac576d8382559ffdc1f96914887c6a51b696f411c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x110D8815518ffd4fB3525d5163581f889c14C7e8": {
        "max": 1,
        "address": "0x110D8815518ffd4fB3525d5163581f889c14C7e8",
        "signature": "0x65650528d2a25ebd222409f219d02184a8a3a84385d8940081de5350d65abc2e7d37eaa04b0d6692bff8032899cd96b2012db77f408643f306ab2bb0c8efe5871c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x83EeEAE852EF8de1843113A6D4B751651670E380": {
        "max": 1,
        "address": "0x83EeEAE852EF8de1843113A6D4B751651670E380",
        "signature": "0x163faa9eaac9321b3e20e0726aa50dcc79cac108cf0d61f334f801d8cdff8d5852e00e30c5b97e04e4e78c1ffac91467c46a9310030d434bdbcb4f22fe1c6e531b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x569133eE480155Af8B3Aee53D7763045bE9Fe8b0": {
        "max": 1,
        "address": "0x569133eE480155Af8B3Aee53D7763045bE9Fe8b0",
        "signature": "0x1f3a2f225331931ea5f72f1859bdf5644d366407056138cf13b80b88fd15bb7c07b4a697efa844d2c68d06f8908f8b5cf425b38772e9d4f0c375d3f9cb57dd961b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x003eb82c5019e766933272cEBEe90C2F27C555C6": {
        "max": 1,
        "address": "0x003eb82c5019e766933272cEBEe90C2F27C555C6",
        "signature": "0xe53b417a549f4d33b01601a7903e5cfe9e8d021d18eaa29e32877e802062347e5076cc9c19843884f3ac1ce0245b58f9eb7a541a5c1560b29a88e6067fda6e131b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6c10aF4D520E712eE4a68F57aab5Bf908214E762": {
        "max": 1,
        "address": "0x6c10aF4D520E712eE4a68F57aab5Bf908214E762",
        "signature": "0xc3c60e3f9d1b20470ec54c9e5c4f7256c6a90c70e98ec98156f4abc17e0dd7ee272fa8de86788a94448cab2bd4d68f7c1654b8004771528a85a423447df7cb481c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8559a1f57b81720041262bf9D3fD9E6F33a52494": {
        "max": 1,
        "address": "0x8559a1f57b81720041262bf9D3fD9E6F33a52494",
        "signature": "0xf3dcca6764564ffb9168935d842f33b1ff35acac6a7b4b926f0b35e43047c13d2a2d166e3fc10512575232d2d13969c7c329d5446109b394616e822f606596a31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf1946dad97AC91332F34bf5655ea2381902B061b": {
        "max": 1,
        "address": "0xf1946dad97AC91332F34bf5655ea2381902B061b",
        "signature": "0x101015ff6258324ec3653b4036f98ecd0a5535e22285d445bb9f401b5bc88add5a82c1fd197893f9bcb67c941928806f2904d38dc4a1ceab8a309653fdd736301b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5bB1d72c002d76Da7327E51F21005215FB858d92": {
        "max": 1,
        "address": "0x5bB1d72c002d76Da7327E51F21005215FB858d92",
        "signature": "0x1683a4fdf5e62e1dffa42165506d821d66814d0c4e588024e45da7a09caab2c91bcfea09b58f05c2c655cd9236331931e0fb1e9d8444297115a8aa05c41adabf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4903b8BA88A908cBb94a874F70556E7B20f45656": {
        "max": 1,
        "address": "0x4903b8BA88A908cBb94a874F70556E7B20f45656",
        "signature": "0x96208399ee4477ca4a0f77beb7836146ba3933b067efae629728324a42d907001f737ac7ef106e0e43adbbd04f136d74987197e68fab5cb1d4022baa3064ccb51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB0b8a803867Fc0827b57e6376773DD0050855Ec7": {
        "max": 2,
        "address": "0xB0b8a803867Fc0827b57e6376773DD0050855Ec7",
        "signature": "0x59b5622189e9c3846c75e5890477737213c37deae78d5b4b5e99e0ad2eef3b9a74c0b3a85c3eee91b5b601379a741725b6e0a35aab03f3ab58376cfe1a273f2f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc433C384D3804190A5FBc337b69b711aB7a4BDf1": {
        "max": 2,
        "address": "0xc433C384D3804190A5FBc337b69b711aB7a4BDf1",
        "signature": "0x0d35f928dab79d85db4beabf191d984ae29595d8a1691af21ab69c5417bac3d04934eed98c746e0f952bf3242de26fab38391ce729a3dc4d746109229d5b734c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe9033c7ec5E92d64E7F4685168F79d0d2c22e9de": {
        "max": 1,
        "address": "0xe9033c7ec5E92d64E7F4685168F79d0d2c22e9de",
        "signature": "0xdaf4d984104253e6942d8c249b0099501c5fc3b25848fccd930cd995dac3937c1ad8a5bbc8b91ad7ba4d7ace21a024b7c9f2f3163437d5a4ad56884a772b935d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x404183B0F33fCAB8E0e77Cc0E8425b4a24983B1A": {
        "max": 1,
        "address": "0x404183B0F33fCAB8E0e77Cc0E8425b4a24983B1A",
        "signature": "0x0c975649857373ac0857c42ffe7618ec14d8f89c6df3a633990d6f447fcf279e758d530133117f20a92b7fd3146e0b98b6b3deedb6b056a9085d1182a50414781c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf210375832Ec6D555BCf5CeEE695B17D8502B26D": {
        "max": 2,
        "address": "0xf210375832Ec6D555BCf5CeEE695B17D8502B26D",
        "signature": "0x737de69c05ae6d0118bb4edc2ee3fa7d2f31fa7d7d2e47262bbed8f09818a12f5d0ec337783fb29df59d61712ad9596e648a69f01e6ecb64d55f1dc81edc61391c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x98cB8D20cdCAC264d05CB5F94292E4cE74890C5F": {
        "max": 1,
        "address": "0x98cB8D20cdCAC264d05CB5F94292E4cE74890C5F",
        "signature": "0x4500148d4047d4c0ca89be6411360ea6c871445ceff94abb007de49df783b49c17a40ea4e0e42b45381f7fd240a9b29071a62a48bd919bb894e4c5b7c29032001c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd7380ef3607dE6Fc99Eb40d3B777d2F13Ab01b3D": {
        "max": 1,
        "address": "0xd7380ef3607dE6Fc99Eb40d3B777d2F13Ab01b3D",
        "signature": "0xab7bd41d3d1c9b964f042cce5ffada4f7f08f149ecc47efb17526de92f5a6cf856dff1f0a2fda63e3747e88b373bcf3820cfb051d684af35d014327375740b971c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x94F516d85FB2490bcaD991551b0d0C406F8e38DC": {
        "max": 1,
        "address": "0x94F516d85FB2490bcaD991551b0d0C406F8e38DC",
        "signature": "0x0ff5907b4c317730f61aa266ef455a83bc7466ee7156cdc025e23bdd2c9207d35a367981d59c31818b9155f11351d5d2055d4f1342f9d1cd8b0e5239bf381c5d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x034CE81fc619aE3C291aB7C58Ea1C07Ed8E25AFF": {
        "max": 1,
        "address": "0x034CE81fc619aE3C291aB7C58Ea1C07Ed8E25AFF",
        "signature": "0x1dea35432d9df5594b0ab8c700418b70abdff52f27f611751be40df97e70af22273b6520490fc586b0ca0f0ad7ffb866b0c721a59bf26d25d4007fe23df226161c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x09d921B6b725C1Ee47661F447bDE23eE52Eb58fd": {
        "max": 1,
        "address": "0x09d921B6b725C1Ee47661F447bDE23eE52Eb58fd",
        "signature": "0xed1df51be18c0216f47baf148a6198a7da32f3174cf76e2d5165544dce5b07cc26cddeb863ffbb0bcb95b1728c47091a02f28ce2aba7247df4ec2bf2dd64c4f61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x223A1b8d6F2ef8d83E0dF91542b99601BC558e2c": {
        "max": 3,
        "address": "0x223A1b8d6F2ef8d83E0dF91542b99601BC558e2c",
        "signature": "0xc80c8928ca51ab96a1f2a1786eaef77d5784cd1f8642782ce1e6d1c0a6f45a817caec44d26138723058e147529f0633037801a99a177a611e145d67f84bb8b371b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5A9D4A4111DeA1BF7e6C365B3a6C72766C10e830": {
        "max": 1,
        "address": "0x5A9D4A4111DeA1BF7e6C365B3a6C72766C10e830",
        "signature": "0xe5c5d7c28673bd07ade0a5eb8a80a8fde0923190ea16193a2dda3a7bbdf124df1b3639bb7ae4a11de7a8454e173905beb566e1c0402ae1011b8d39bd2a77e7131c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x31BD503638DE96e1EC35946D89ef803B2b2a5FD9": {
        "max": 2,
        "address": "0x31BD503638DE96e1EC35946D89ef803B2b2a5FD9",
        "signature": "0x525369e71d5c10afc6b5af021c6430ca7cf1f4dce8eaa3db3302e82d6330a8cc60431958ad93432cf49a9558d140e500b2bd938161b149e65202c1c714ff6fe61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x98856e387c1d19A7D5FfDdECDFda4b1f858dE30A": {
        "max": 1,
        "address": "0x98856e387c1d19A7D5FfDdECDFda4b1f858dE30A",
        "signature": "0x4e5c77c9a4d951b7e52709d6f0c884226b4acca9c68a02769083e1375385fca6218b31de5d1d7c24a20210196a9e362d4aae3a05803d6b13e4b23206005cbbea1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x62c7FFf230FDD9bE3f2360aeCFbe53ea26745802": {
        "max": 2,
        "address": "0x62c7FFf230FDD9bE3f2360aeCFbe53ea26745802",
        "signature": "0xab545820ac805e6faf2591e73a07f7401178bdd14d45f7d94bbffb24ee6db2a256d12b392a8f0d5ae28cb42580bb68b40add8f058bf0907e0c3b9122a641851c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf86b7323f60cd70C2D45DCdD3C1b47E0aF1B49d1": {
        "max": 1,
        "address": "0xf86b7323f60cd70C2D45DCdD3C1b47E0aF1B49d1",
        "signature": "0xe54d04285caf8d20a0743fb47dbe883b1af38f354f4d7e56c81029d19a9e776b23aa755c022807dd6fb8dcd486d7488bbb9e4465c6095daf70b5e0d3146752fa1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x503685FEa47cD3bc770d5aC773DfcC241D2AC022": {
        "max": 1,
        "address": "0x503685FEa47cD3bc770d5aC773DfcC241D2AC022",
        "signature": "0x332ba0b851dfb8f383cb2521e5dd2a36fb74fd80045bef00130843434352a8d43278a20c3dbd0be07898b53b7b4ae21b432b186fc7f01de413783e55ad59c02a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFB4ad703e9FB8843E187C9538d11bD7a91be034d": {
        "max": 1,
        "address": "0xFB4ad703e9FB8843E187C9538d11bD7a91be034d",
        "signature": "0x84a215e3230eaaa49f2211da7a6f1eddc7082572bdb21d872fe7b99d50f442bb06bfc4cc564ceb7692f6cc1ff91e7769a77663680826ed0abfabaf2d1716b6a71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x809a5757fccA6929c63E7cD010d1feeaED3737C0": {
        "max": 1,
        "address": "0x809a5757fccA6929c63E7cD010d1feeaED3737C0",
        "signature": "0xfc43a7dc8aa83f930f2934c7a59f26c8e1269109aa4382a72fbd8225aebca5835a28d12f99b817d0942158088bdbe90ab0800b33368fceffc8390498c3153b251c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x46c72258ef3266BD874e391E7A55666A532aeCbA": {
        "max": 1,
        "address": "0x46c72258ef3266BD874e391E7A55666A532aeCbA",
        "signature": "0x10e9cf10388228da4ebdd7670a938b25d2c1856791f14735c48344a22868c4406c80715bf412801180db1541f1fff44eb735bfe82a3e703ee588da12ec4464e01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc9d838f981862Ab9AB38F8cD952eb4cd1e9f5AEB": {
        "max": 1,
        "address": "0xc9d838f981862Ab9AB38F8cD952eb4cd1e9f5AEB",
        "signature": "0x7ed0e58ae53df99b8d7a67e82910ea1ce4945420c56fe9a20b56ec25ff9bd89518c370332d7a75c4d6fdf7d8dac95c48c808ce2269190fe0f139959f8a4535b51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3c6B59A5AB5C9b98A515A1482cC8467f6cD34E15": {
        "max": 1,
        "address": "0x3c6B59A5AB5C9b98A515A1482cC8467f6cD34E15",
        "signature": "0x843217513ca3168b86053bb3af3d4b51a65214c9143bcd3c2cb567d33a98f2cc687cd3a2db2bebd4a49a0dec84fb70154bc6a71c926105cf553ffa5d6aedd4341b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb5B8F8D1091967eF759A991005863Bd0dE581FBe": {
        "max": 1,
        "address": "0xb5B8F8D1091967eF759A991005863Bd0dE581FBe",
        "signature": "0xd5cf04473ba08034f073d258885a581715affcc6a1acffe2a5694acf57bad0b07bb97d1e4aca4d998b7a43009cd118672f345b4c68ed2a2aead477ecd3587d1b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x26b56F799AE703BF603e10828166E1E37425D4D5": {
        "max": 1,
        "address": "0x26b56F799AE703BF603e10828166E1E37425D4D5",
        "signature": "0x8bb290f433c6c62e92da8039c3e828587869d87db61535f5c210432e9c0ec9244a473c4631d615929613e8881997e1c0b7c66776135ce898dca1e7b479eea8371c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3BA3E83bC1b3a70e756aF850F042a754C0d06Ab7": {
        "max": 1,
        "address": "0x3BA3E83bC1b3a70e756aF850F042a754C0d06Ab7",
        "signature": "0x4dc9fd4993db0021ce1429d8437cf9c3b0089622acb8bcbfa181bdad1e4e64080e470cbd0ec8b64e85f6b9d22c2e8131b7332949338e64e96907900e72de55721c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc1FF5F247d5Fb7Bf827270DA7fF8015f79fFEa90": {
        "max": 1,
        "address": "0xc1FF5F247d5Fb7Bf827270DA7fF8015f79fFEa90",
        "signature": "0x6113163f3e4de48c66da3c472bfed9d72021fd3b0c246a75e6b7be8b8903d6fb1f098dae649fc73dceaff4ae9cdac7801b7f3f665ce9f75b4de348a95622e4d41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x11EaCD471624cDCd4f4CE9ce4a5799D0e274b859": {
        "max": 1,
        "address": "0x11EaCD471624cDCd4f4CE9ce4a5799D0e274b859",
        "signature": "0x7ca026518d2d7e5d29c36ef4cc1350911835c254b64373a175536f46c2ece44616bcc9654768c657b6b3d33f63a28789a1ec3cfc1a81816721ce73fa61923d341c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4FE169cE833C258dBcfDf4a90523cE9c8C62b10E": {
        "max": 1,
        "address": "0x4FE169cE833C258dBcfDf4a90523cE9c8C62b10E",
        "signature": "0x00789c10413342db38d2ca287775b23ce4a8aa224d87be265c601fcf8de8379c3ecb061cd9556248e942f075a65f2b365d62bfba918d8955914b49f6694934361b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1e1BA4ce7eb540dD1eE7832D7e3aD15e5fD071cA": {
        "max": 1,
        "address": "0x1e1BA4ce7eb540dD1eE7832D7e3aD15e5fD071cA",
        "signature": "0xd7af899a8648c9e58be96d6edda9c92acc0f1afdb55217258d101fac5cd0cdd04befcee6ef95cb2f490411fbe1ac3491162f09f89f4a559b2ee8a4d8e41760c41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7de332Ef0E1F195E677B849c2f08F8a491af86c5": {
        "max": 1,
        "address": "0x7de332Ef0E1F195E677B849c2f08F8a491af86c5",
        "signature": "0x3dfdb398137e931dd665efe414e5b2a302bd1ad7fdac9187fb89ddebc6da11c2797478e23c7d5d7c72e028a77eeebd28845dddaa42daf945e60fd2b71a228d7e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x02DF9F4c87494b7f9Ba527D622b663e36Dda188e": {
        "max": 1,
        "address": "0x02DF9F4c87494b7f9Ba527D622b663e36Dda188e",
        "signature": "0xd0b7ecba8a3f4269b5ac3bbc76d77ccd3c150fb35995f2e8f07ee95f6e26f2606e9e2f80600275cb11fe376eef509bdb9b51261997217e1756354590a78315221b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcDEc48a99164D9231cA941801705b31f375DA870": {
        "max": 1,
        "address": "0xcDEc48a99164D9231cA941801705b31f375DA870",
        "signature": "0x7c7dd432b2dcd35deafb87fa59ad5195358aaca05cbcad53f7130fbd3cc4e23a476a117397823465b20335b2101a541c93b1362db6a0340f0a94942aa12dc5e01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC1fe0e8B4276A1d0Fc3eF7aa31C12A72FB41eE11": {
        "max": 1,
        "address": "0xC1fe0e8B4276A1d0Fc3eF7aa31C12A72FB41eE11",
        "signature": "0x91b6e177a2b6418758c1ca319a93ddfed6f1f4d6a7d3e1722638d57cf3e52f2b48b236cbb66b208ddc443fc31828db24835d57f0ad776178abd4675e404001491b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x01243953237635de1502F523C6eEd05Fd24Fb38d": {
        "max": 1,
        "address": "0x01243953237635de1502F523C6eEd05Fd24Fb38d",
        "signature": "0x99b6152315f50ed9e4c11b8ba216164a62a5414ca0491fed818849771aed979e7470aa581e022807182f8d35b9c7fce40f065dbec4647570e43fe5ea795581121c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3A91a0E7Bc63F57F8461269e976Dae04cBfBDda3": {
        "max": 1,
        "address": "0x3A91a0E7Bc63F57F8461269e976Dae04cBfBDda3",
        "signature": "0xf172b05898ea935c8b1ed323a82f1b03a17cef1093d8fa992878f10165053055245a007438edad024798e837945fe1c7daac5ed7b9b8718d6f05d68b422941901b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x42A44b3dB69B09b13Cdc135971A03137fCBe8D5c": {
        "max": 1,
        "address": "0x42A44b3dB69B09b13Cdc135971A03137fCBe8D5c",
        "signature": "0x884872bc7852731215260848e05f8a057353cd818177d2e3098e91cd39be323666912ff0f5abcd208e60dd29ed0e91bdddb8c39fb269c9c24025bc3f925d1c501b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x369615Bc57975B06C418f42d3FDA3754A385B97b": {
        "max": 1,
        "address": "0x369615Bc57975B06C418f42d3FDA3754A385B97b",
        "signature": "0x3dd92b2eb18ff7d538be34bb980c448ac3d6617e7768b56101cea80be46568e50d3a137290a8037b05c162edfa7971a62387256469e924e43ba139ce742104a31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6D39eB6bbc41D660e66982F99abd3c07f1CA26F7": {
        "max": 1,
        "address": "0x6D39eB6bbc41D660e66982F99abd3c07f1CA26F7",
        "signature": "0xd378c978a02ee27b0bb470a2415bc379c30a53a8cd93931acc511df156e068c9075659734156bf48f7c45baa2e5b3dae507f7b5c4e1a9bff55ad5d70fb77281e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x75F552c316084fEc7edEfDe7DD3Dd3aF9C647E5e": {
        "max": 2,
        "address": "0x75F552c316084fEc7edEfDe7DD3Dd3aF9C647E5e",
        "signature": "0xee0907fb113dd71c2abda03af989f6e5dc53d5241f46f3e4025513306737e00271cd20d586f8cacdbb7e48aa5b9bfec80adcfd4f3a05178acfd8c3595ff392c31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9DB1522C999360f070a09dE39CE3FC801a8429E3": {
        "max": 1,
        "address": "0x9DB1522C999360f070a09dE39CE3FC801a8429E3",
        "signature": "0x76843846bef2c19f6c327e969385a2e9b8e1bdc81319ab7e37ed2620d27edac057041fad901e391d327b1245fa467278bd5f63e597aae9a0a8630d1129cfcc081c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x989F909C5F72B18d350bEbf298C558cc1b8E7e47": {
        "max": 1,
        "address": "0x989F909C5F72B18d350bEbf298C558cc1b8E7e47",
        "signature": "0xbb7d11a9ecab9504e3f6d6a6af114188cf98c56ee64cf36dde08f55759e51df431244fd8e2ee65fb4d88664f6bc4c8155f9e595babb0ffdb98dcf52f2ccfde2f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x206586B59C9F4B2cfA7604a18bc9c4061c334e7C": {
        "max": 1,
        "address": "0x206586B59C9F4B2cfA7604a18bc9c4061c334e7C",
        "signature": "0x74acefdb6b7247b8572b917a598385b6178eb0be3b18bd37d8f54fd1546faf7c796e2303d16dccd57f884796497ea6afd94929488fa553dede5b9aa39d3a0ec21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x302036Eb9E43247b5B666eEB44205A0B72029ee9": {
        "max": 1,
        "address": "0x302036Eb9E43247b5B666eEB44205A0B72029ee9",
        "signature": "0x511ec5dc217671b46e0e1a32f98bfa5edbe6cb8c5554763b40830b1c3781966d5786c2042c25297d97986a915d092fb09ee2a0b7e690f6e3ebaf8fe107b6c5271c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2F4670e83A49ec22ba9FBBE699F449C10456083d": {
        "max": 1,
        "address": "0x2F4670e83A49ec22ba9FBBE699F449C10456083d",
        "signature": "0x92fa351d203f6bab8501eca8cc0ec735fece3c7f6985c86c3b4bac17bd00f83e2d737c50b1252325e23067d75af5705b8c95f3f5181bcdb777fd31b02f5f27391c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2294a22f797e8De4FEDE963dbD5C9246f2Dd9420": {
        "max": 1,
        "address": "0x2294a22f797e8De4FEDE963dbD5C9246f2Dd9420",
        "signature": "0xfaef85eadbe2de473399623bc760f46351650d165ede1cec736ad64b5a91d50502d342350c7544b5bc8a8965e56c71896d8cfe15d1e7ccf44af60c5687f0a17c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA4B3Fcde71962e380cb2a0b47aB2CB47d3CC4700": {
        "max": 1,
        "address": "0xA4B3Fcde71962e380cb2a0b47aB2CB47d3CC4700",
        "signature": "0x319b878b9449813a14b93918647ad45d8e341c848ee734224eb7dd323896fb84336c9f16295b338b70c2eb5055e70deaa73483aece826d65411302bb70d518fe1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3490efc56EfB546219fDeE6ACb115b428864C675": {
        "max": 2,
        "address": "0x3490efc56EfB546219fDeE6ACb115b428864C675",
        "signature": "0xa53a117f810c8f71e6c45e2aeb3d01f5c96d9e321593bd1b04b669b55d6db7b073468e605665da8e099c3519d106bccad752eda892a1fecacf6d22ebb358c46d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x81f927795b64Eb3867Cb55D4eb47a06ffceB5daA": {
        "max": 1,
        "address": "0x81f927795b64Eb3867Cb55D4eb47a06ffceB5daA",
        "signature": "0x58dc4753e0a2de70b2a4317c4be64db1b4726856c1ec8d53a6b058afeba02f3e26d864c21de854bfe823209f6cc884617f450e4062c26d7e90b4774582e0db331c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x25C1674A82B614Fa3459e4cE5a2C153E99D6325B": {
        "max": 1,
        "address": "0x25C1674A82B614Fa3459e4cE5a2C153E99D6325B",
        "signature": "0x6fa79cc2848a2baca16039dc04f845d8a446a0b3f181d6a91356f8286ed15e4c5e50ecf32dc90107563d2bfa2b9fe095e719c50be9b89f248349ae0238a919621b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb42618e689328634bE0bB6349f453e95217e4565": {
        "max": 1,
        "address": "0xb42618e689328634bE0bB6349f453e95217e4565",
        "signature": "0x2f7c1d0836baa8b614bbcad6b03ec6ac0c8de6586274a71c04fcedb2858c7b4f7f8b6033a69cd6e04c303973b768707c5319e2ac10172e5fbaef85558309da2f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE6f65a180099314672b6d746668a5690b0C51254": {
        "max": 1,
        "address": "0xE6f65a180099314672b6d746668a5690b0C51254",
        "signature": "0xc12a47490bd952c63dd55affe188001a9b5627f3ccb6523c3f5a1628c660842102a0ff7b7c5e79561f42302a6a4a03fbc5668fb6d614cb2839b18ba2cd8d23081b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x47b5339cA467bEe2a46871c7f8eaA758Db4F1084": {
        "max": 1,
        "address": "0x47b5339cA467bEe2a46871c7f8eaA758Db4F1084",
        "signature": "0x72ba4c84bb2183395c0245aeabea9de7f4aeb5da0ccfa317af2407f9e83efca43a2499fb289b65e7e723a7bb58d02ef7d07c40f54ae2622123bede862690fa5f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4768ECbd43659bbCDe026D07410d9167e093170A": {
        "max": 2,
        "address": "0x4768ECbd43659bbCDe026D07410d9167e093170A",
        "signature": "0x11c9a97a371d5f65c34144e4cfefcec41d995bc61a9d97e9bc197e55228858f635da819446aec25634fb755d3be430a4250ebedb81f1ef7010dd9fefddf5517d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5fF13C1c3aaB374a4B94a700cC1e1288CCfd1cb1": {
        "max": 1,
        "address": "0x5fF13C1c3aaB374a4B94a700cC1e1288CCfd1cb1",
        "signature": "0xa55cd11591dfc71785e9b6b664a26f622f895e40bbd5e7fbafb0c423a477b81925c0cadde956944df5a516aac6dda8fabec463e6e2c3d22564297aebf6eeee8e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF12C0392b73A3bEB13FAfcb691Ac09Bb74cD869a": {
        "max": 1,
        "address": "0xF12C0392b73A3bEB13FAfcb691Ac09Bb74cD869a",
        "signature": "0xe9c8820178f9719e42169f9553ca49dab064e704d6861e8fe2432ccd44fe2ec753e34fdc4be09e25d5ba6bd57ce5a4999a4760931dca0005d7f595be4749fcea1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6d97cD4c2f027D3d8A676f050bAb84831D130373": {
        "max": 1,
        "address": "0x6d97cD4c2f027D3d8A676f050bAb84831D130373",
        "signature": "0x19f4a02d77232ff7bcd473e816c517d755545eefd6d4407ba405ede430a8e4bf27336a69c91c884e685fd7b742787b0a3bed2285ba6224820cca6863dc0d5f991c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDC3ED2238350AacB484AA6b0d17C4Fc3353f0046": {
        "max": 1,
        "address": "0xDC3ED2238350AacB484AA6b0d17C4Fc3353f0046",
        "signature": "0xb49464e535ca4f2d30f00dcf4099f9329343e1692cc54383d3eaf634cec81fe543aafccacbe4e3097c1fdcbcb55b31c789941663768d5b79a4b16b3e83b12c461b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd76907f41048F30367c9035C957f269fA17093BB": {
        "max": 1,
        "address": "0xd76907f41048F30367c9035C957f269fA17093BB",
        "signature": "0x345a2089ce1b6e8a9b21d86a80395adb73d0003679692cf09a756e59a5b207422f243370504b9c7a9d826058d7691e7e1601d4b8f99df15de825db3b58e66ac01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD971fa66573d973d3345C17a01cDdf135ec94261": {
        "max": 1,
        "address": "0xD971fa66573d973d3345C17a01cDdf135ec94261",
        "signature": "0x5d4224189c778079e06f687bf3b041ec043429399a0ab03bb1a94cb71bf1ad0b4bddad0fd83a54c95cc5843ce7c8fcfbaedc8d43c79daf863a108f57042c6d961c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x94b252C854a967a21DeE1C5Fe0D2c3F47A23d693": {
        "max": 1,
        "address": "0x94b252C854a967a21DeE1C5Fe0D2c3F47A23d693",
        "signature": "0x7ec496b25c0a5e0602032ce82d21f0fd64919c1681ced44995f93dc32e6d5e8240a3c36a3f5ad50741cde0682b122b80c582214204a769a2664ab5edef7f468b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa6fB08F3C8f967bA8dE8F1F9BCc3f5FBe5AAF366": {
        "max": 1,
        "address": "0xa6fB08F3C8f967bA8dE8F1F9BCc3f5FBe5AAF366",
        "signature": "0x9906c900412df2a8d7dd2613dab1af7131e2d7795310c647d19b5620c418491b5f32dc718971e0646207798e2cb482901d50d88c9e7a6e3df7e88d885e11f50f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03b070F2144C4EC5C1153F14BD4A03F151271901": {
        "max": 1,
        "address": "0x03b070F2144C4EC5C1153F14BD4A03F151271901",
        "signature": "0xfe470877167819046136a106a8587f8806a74a6a4f06f0147e6a4d1053ebd7f87e1f4fb7a80482e6271a1a5481e9be0be83747d3cc8b61d8377db35e3e063db81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x21bc82E6406e216669D75f8E2f84e73EB6FC2679": {
        "max": 1,
        "address": "0x21bc82E6406e216669D75f8E2f84e73EB6FC2679",
        "signature": "0x8862e97b6bb12f915490da292858b51789e8e6fff8e92af562542854330d34ec6d7f6e32fed72b584b353bdbba7f66463ddcc8afc41524a782a8757f62c16ed31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x64FC8C50d3Ae974Bf9a32AFb7232AE96bCFBb35B": {
        "max": 1,
        "address": "0x64FC8C50d3Ae974Bf9a32AFb7232AE96bCFBb35B",
        "signature": "0x19deeec2462fb4be87c2d80937d3e517019eaed3e0e615cb20a1100c4a10b65f0276600d50d73b4851d7c91ed1bfcb7c097529b5bd7a21796e963c283bb0d8891b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7B70aD92b2BB10d68d4794268135B66714ddBa38": {
        "max": 1,
        "address": "0x7B70aD92b2BB10d68d4794268135B66714ddBa38",
        "signature": "0x7895d8cc9eb0e6683d498c3f28c1ba81faf1b39271253c511d76f98e0c1580b811ec6738655a6a38f4c7609cb210eb94bd6afaf43e2c94d325693204a655ff941b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x66BdD806E1ee1899aa2eCd061e8b886f6fbf1eA3": {
        "max": 1,
        "address": "0x66BdD806E1ee1899aa2eCd061e8b886f6fbf1eA3",
        "signature": "0x22e3cc5a3753a5573f439fe4f12820a350022a7fa3186c9696f18f86d8213fd312ee2cd28b5294c04f270fc5f43fa0bca626ed277ffe64326a3cf168e70f5c181b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd6cd6c24571d45D4e1Ed6837ca0df7130190e8A9": {
        "max": 1,
        "address": "0xd6cd6c24571d45D4e1Ed6837ca0df7130190e8A9",
        "signature": "0xdf6926d5d061d42231b14e37e9f627047cd8043fd4e74f730f116fc82fe2e8fe78cd0441018f8516a2d32b31c19def7c8d9ba4408e65a4f38e49ff5a9ce19d751c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCd64E71F361cA9ec8DDC777380F1D02EDFc7b3AA": {
        "max": 1,
        "address": "0xCd64E71F361cA9ec8DDC777380F1D02EDFc7b3AA",
        "signature": "0x8a2f7f539434584daf906e6970595f1996b6708197e70abfb9724193fca5c6d570669240e50055939543cc642ef199ea1cc746f775d4bf076bc7e5782249aced1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x629888Ee90d32bf8101c5ae354e51b7EAC4AD97d": {
        "max": 1,
        "address": "0x629888Ee90d32bf8101c5ae354e51b7EAC4AD97d",
        "signature": "0xfe324039d0b6f1b93aafa58fc39a4a7d41fc36896a481db57da44c7de178ef1c1fd021a916d53bd665677c607d86cd00c67f6d3fe3225948f0e0e161d07234ff1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x485609c3439F33bF39519DEb2d6aFEe1143e4aaf": {
        "max": 1,
        "address": "0x485609c3439F33bF39519DEb2d6aFEe1143e4aaf",
        "signature": "0x8459bd3b6a43ff2bd4727f587a0a25d37e100d33964242a0bc161d0b30aab16e1f3aee94ba76213635da63d578b2fcd91ec43ff6134bfa7fbe497e8811a716c61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x08F88Be496A9339D95952d57b1d41908D63D5BF6": {
        "max": 2,
        "address": "0x08F88Be496A9339D95952d57b1d41908D63D5BF6",
        "signature": "0x69d20123b43cf1263d097d4ea8eb7644b59c16a904f41c0fc0dafa64cb79f2bb48eae9c313ff4d5fec2956f38cf19aa5a19958e6df848d34bc530872f46a89811c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1d01e2010C7018AA9A10Ee81bEb8e88F212a1209": {
        "max": 1,
        "address": "0x1d01e2010C7018AA9A10Ee81bEb8e88F212a1209",
        "signature": "0xa6c9c659eff9885137ec409b5a71a3085b897a3b10db4530682f5e7adac1814705d8968e3c8f3df910459742939e11e877365c3c774ba142f8b0dac58add0a001b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB2fA4244b05f677Db8A295005496E8d28767fdb0": {
        "max": 1,
        "address": "0xB2fA4244b05f677Db8A295005496E8d28767fdb0",
        "signature": "0x2e003913d28ea73ad9aa5d969f5922b1d205fa7f0ca1df0610216d7f567f1eb5493ae7dac4b283e3cced95cd8b7666f803b7fd8624cb7026b57b03f30c92176d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x339Eb519B5E14Da1ae580206A771B4BFC730F39B": {
        "max": 3,
        "address": "0x339Eb519B5E14Da1ae580206A771B4BFC730F39B",
        "signature": "0x81bbc2af052666c1f0eac0ff976c5f778b5242dc69b87c5ba04e0dd0f44e644c7b8f8205337409263b1b0d6be2d71f94bf0cc6b5c22e4f33bebbba4b3c240d501b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9d279a05261D0Dd07174ea6F0F553D86BB3e779b": {
        "max": 1,
        "address": "0x9d279a05261D0Dd07174ea6F0F553D86BB3e779b",
        "signature": "0xfc173fb2c29b6c79a0df749ef43cc96454d4aabbc7d639a3769f3f7a8c335f781af98cc663e9eb924cd52aa291d75239e53bd884a2bee31b69658d991be3bb2f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x171aCdE62E454F1DDEe6F53704ae6Dba94F3CA3C": {
        "max": 2,
        "address": "0x171aCdE62E454F1DDEe6F53704ae6Dba94F3CA3C",
        "signature": "0x0096bba59bcbb740660e7296389e641e0663bf1f457b7b348952441bb02c08ff44737646dce114e2c0abc4a774fe466d64c5d4d65ddeced342660419e9c62b4b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD2E90392f044273991432b1851faC36759Ec30f9": {
        "max": 1,
        "address": "0xD2E90392f044273991432b1851faC36759Ec30f9",
        "signature": "0xed4094e9e17e116d2f4e63ab6a60896ec239b40de1a1e3c1cd71d4b7587935df38e0f805415aa3c7f424998c73765f83de0d88904a803a21967088888d7c86df1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2016ca933D95Ef5fbC5D82C3d5bD281701BeBeD5": {
        "max": 1,
        "address": "0x2016ca933D95Ef5fbC5D82C3d5bD281701BeBeD5",
        "signature": "0x9ae1734f8d5b9b264a2c6fe3b2a7d06edf8143001564d8dcc86d6c55d1f9290757a8fb7e93e1f29ff9f8d5f22b0a39754e479b2bfa8337b0e17f9b63bdc570b51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9AD99A3F968af0163719C33001106CA45D0DC640": {
        "max": 1,
        "address": "0x9AD99A3F968af0163719C33001106CA45D0DC640",
        "signature": "0xa4fa6fcd7ab4189361a4045d15d85333c1667f1b3b488c46e612bda95e66cb0858730d0267b1201209c09be41ff2ed8b0173234a8f2f1e212496aba85d73bba01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB6D87E4A65b53781cdfdCf6543A5139Eba636442": {
        "max": 1,
        "address": "0xB6D87E4A65b53781cdfdCf6543A5139Eba636442",
        "signature": "0x39bbdbafdbb1c7d5a6fa13497f73f44065c0303001142d8e13ead19a9f5ee5463f18ca483661e25d92591796abd564924c12e7bf316c857a9727d7e5655a31681c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0567fD85fac6a0D857AbefdFAbc5d405499a1618": {
        "max": 1,
        "address": "0x0567fD85fac6a0D857AbefdFAbc5d405499a1618",
        "signature": "0x13b95ce9ab59422bf708a1acff7162c6738103710f600762119f0cdb02b8140a0a421a2d3ecae3a90265fe6bdb83147529fc53476672544c4e6426ce468f64761c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc396e98f5302529B10a23D62C99765945Dcb4619": {
        "max": 1,
        "address": "0xc396e98f5302529B10a23D62C99765945Dcb4619",
        "signature": "0xcd66b22cac374ee28980a3a4ffc25559f54640d34f062361b67aa8a2d8e2ff9e776a982ae6749f033c2c35caf8e61e1d63ea9f1e37701e2f36c7149c830700e41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBeCd4b531bBa5AFAE64a81B1bD4938FFe97F8eD7": {
        "max": 1,
        "address": "0xBeCd4b531bBa5AFAE64a81B1bD4938FFe97F8eD7",
        "signature": "0xfa032fc7bdfde6254ff83e3d2f07cdd3198bea4631240a1fbc953fe24c46b64d627773de8c0be2ea220717017c7c401933dc5a7b1955e3b341aa3f650d632fd91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7A0A5Eb6EADb8df7D6B7d1F483B4b93A791d3a02": {
        "max": 1,
        "address": "0x7A0A5Eb6EADb8df7D6B7d1F483B4b93A791d3a02",
        "signature": "0x032a488040481a6300e993e54604ef532a75b6d89b98cbb5e2ab9d2af6e504a8342159a51bebdc19d75249dd5d1c1c45d87ceed7429bbd4383b5df258f1b08581b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5082deC9Ed494Cb654C9dc49378FD94Fe0ce4E96": {
        "max": 1,
        "address": "0x5082deC9Ed494Cb654C9dc49378FD94Fe0ce4E96",
        "signature": "0xb7661d281d86a93390f14921090cf4933a49dfa2d2f9f135eeba1f1e0d7230e46dac37d5a3fe37f88cf119aef2dba531943c1012963832832fcc80064b2158ef1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB887FE98c15660DBf8Fad2F713410359d9f3a45F": {
        "max": 1,
        "address": "0xB887FE98c15660DBf8Fad2F713410359d9f3a45F",
        "signature": "0x2731ad115e015e132be8b80f3efbe6fd202613c5ddad19a5a7e76ac6f2e5d1837c20dd5c61bf3a891abd539e11100c7566db22e0bd36e14fa1086672e200f48a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xACFB35c3A570cbe36532d5B09755250CcD7f64E7": {
        "max": 1,
        "address": "0xACFB35c3A570cbe36532d5B09755250CcD7f64E7",
        "signature": "0x88a99a40d46626c460d4088285e0444c0cdac4ca91f0756a6691d0485fc0efd1220d42a4baad0537146d50acacef35c3cfbaf14721065a3e4a7194e5613353451c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x35Fa3df3b7Ac0e0E0c466dEf75Ab67B028065005": {
        "max": 1,
        "address": "0x35Fa3df3b7Ac0e0E0c466dEf75Ab67B028065005",
        "signature": "0xc58fb0109b18619d5acb9a9aaa02ca04e861a10ec2246d3fa4bea9e1e4de23635a8fef984a0d41c2834b509f55728d66ad4fd33d7bacd2eaceaf4415698ce6551b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1324E1D684F537e1b1796783e89b8c5220C80C97": {
        "max": 1,
        "address": "0x1324E1D684F537e1b1796783e89b8c5220C80C97",
        "signature": "0xeb2381e0efd763d3549f66cc8b28cf7dbcfeab1913d3f8a3bd32162567e1c1e1315cf0e4782586dccb6643f37919f0572188a704d1103f4a4a895d20fef0c4b71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd6cFA02D1198768F39993C952BC1b7A991524e70": {
        "max": 1,
        "address": "0xd6cFA02D1198768F39993C952BC1b7A991524e70",
        "signature": "0xbe68425826b1a3aebf5206d2603d7cc1886b2ce3a43e3ac00be897c641f7d7b256077a0c29bfd21672a7360c85f3eb7c257093b8064f01b10ff82c106b7525401b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x52817bE92F3C47F707B152D436763B3d9571C164": {
        "max": 1,
        "address": "0x52817bE92F3C47F707B152D436763B3d9571C164",
        "signature": "0x57c2b15253ed5dbe6f98bd944b6eec849fc8aa5836d9b7aba5441daa2e57d0fe0122621ce533d2e62629d5b0b073aa21691cb38c0b04f76a6585aebaf01c44291c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8Fb19AF9c66bdCd2D2bfC2E559Aa1c173766EA35": {
        "max": 1,
        "address": "0x8Fb19AF9c66bdCd2D2bfC2E559Aa1c173766EA35",
        "signature": "0xf7004ca400c6f76717f506373a1c5a91145ac2a399f0ee2d5dac0c79bbffc79374e422d1110426a54dbfd5252d6414e8c56438969f7a1ea150c9b36e6c8a418d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC86c8dCd4e9b422C1EB0c62c6de459fD5264c0aE": {
        "max": 1,
        "address": "0xC86c8dCd4e9b422C1EB0c62c6de459fD5264c0aE",
        "signature": "0x84f4417f34401298bef31e316a279fb383147199aee521ddc326c5c93c709418794acbd9cf406c91f596391622aa4c0d01a3fde5ca2ab2250a0281e9fee6b1c01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x12BFdc24e284E375DEAc94E909c33F4A71aE2F9F": {
        "max": 2,
        "address": "0x12BFdc24e284E375DEAc94E909c33F4A71aE2F9F",
        "signature": "0x9298ba773059606184d73298df88ea1a22dc6af443ad360ac9740da80a06342f5e63e73402a18c7cef14dd11736728fe140a7339a9d02b5c5866a90b647f20181b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x085c1Bf97ce01758f3E92661e6776B8F5Cdf8220": {
        "max": 1,
        "address": "0x085c1Bf97ce01758f3E92661e6776B8F5Cdf8220",
        "signature": "0x9b0a0bfb3ad04f349b4860af7a490b22fd6711b51df018400084c53e0fa0baa4383069dd2cdd00fa5d5de6c146ff37135300d7296db7f325d8a78ecac60647261c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x84cdEA84725943Cd3c78b2b588ab3942Ab889645": {
        "max": 1,
        "address": "0x84cdEA84725943Cd3c78b2b588ab3942Ab889645",
        "signature": "0x4fe79b16945c30e1d424d341cc3dc0386300dcac869bd311f762c4a051b737b5610a5e07cb3996ecc0fef38b5717baf95df3c59ca46b4f0e05a00f20b5a122251c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0455012bD2D352a72b8C35cd2fccF6934A5Fe280": {
        "max": 1,
        "address": "0x0455012bD2D352a72b8C35cd2fccF6934A5Fe280",
        "signature": "0x2e9aae7663ae09bcbb2e35d03496e503f7cb9d641ad8883415b11b70549aecd55921e3e7948eb32f80a2070be70bac8cab93c4c6b6f171dc6f6bca1f93707fa81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBe4abbEE89ba69BD2720B8E98e2060853b8909b3": {
        "max": 1,
        "address": "0xBe4abbEE89ba69BD2720B8E98e2060853b8909b3",
        "signature": "0x0f1e99ef0e40a0b0387954634bd32189b082eec027c88243c89c9a8d6db548eb15993c04649b10a9823e35a5aaa59a55544ea02edbfb612ed56c51f4c18214721c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7AF15e540A59246A67D26A0529EB317394eB920D": {
        "max": 1,
        "address": "0x7AF15e540A59246A67D26A0529EB317394eB920D",
        "signature": "0x50ed29d2cdd55ce1460b033d1c3d8404d0101abcfb60925017e3711eebc61dfc0a6cb5307e3350a514e8023a42e41bd550ff6fab34ae9b5573e07ae0a6709c771c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF0a63AF4C25D68F678E746d1136Abc3912757A6F": {
        "max": 1,
        "address": "0xF0a63AF4C25D68F678E746d1136Abc3912757A6F",
        "signature": "0x9cb5da859977f493887a5f4bd3deef2b82800f2b3c3914462c2e4fc6a425cf9f7f45b7d2a4c6c75c536115d0398991edefd5f8dc9150c051cac7ba9dc91f5c721c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD289d4719c9507B98fD483f7772EDB2d7CC01871": {
        "max": 1,
        "address": "0xD289d4719c9507B98fD483f7772EDB2d7CC01871",
        "signature": "0xb13a486ad85f03da3c789a3b7c769c1e2c883bb50a9e6bb47e652adfbf35168770fb76943fceaf733ce1c5ae3443eb7930fc7b8bddbf340a3aa8308c15de406a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x59Eff2859b40197a8c5282f993e6758A745b3cf8": {
        "max": 1,
        "address": "0x59Eff2859b40197a8c5282f993e6758A745b3cf8",
        "signature": "0xbc887222dd08e396c8edd05f979c4210c4b740ed6066a511b20dee8d578d3651726870fcc3a0a4c521337b8d2e64d16724448c0c9fd2c0ec05f67a7f155ca5ca1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe11706e1d59dA9c0cb36c75490044db1204Dd3E2": {
        "max": 1,
        "address": "0xe11706e1d59dA9c0cb36c75490044db1204Dd3E2",
        "signature": "0x094538bdc7e40683717af2494837c2c13e4bc612c37d6977847b181fc683cefa6ec7518bc340ce4b6a997e4ca6e148b28abb8d655da144a8f9aefd301015ad591c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc04Cc9E7A9e36bd9244a4d06AC04C891359d400E": {
        "max": 1,
        "address": "0xc04Cc9E7A9e36bd9244a4d06AC04C891359d400E",
        "signature": "0xe61a8a35f2bfcfa9b050379893210ca8beb08108b87628ffe9275a1d5761a0ad6dfcb112b8d494d9b8c329d0ec362488630346869e2534b73ea9aa401f6297791b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF3F1705159a32C1E1a25A650E2dd0992DDeD4640": {
        "max": 1,
        "address": "0xF3F1705159a32C1E1a25A650E2dd0992DDeD4640",
        "signature": "0x118fb1c54b15157cf92d71bf3ac9892f940945cacd589a6c9d4f766b0d0851437ecee54d4917876f6fb7b6203832b246ace5e1af75dc8765e1093cedbf2f0d361b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x72128361eC79f7ab8a66E3f92ab734aF6Ca53Bdb": {
        "max": 1,
        "address": "0x72128361eC79f7ab8a66E3f92ab734aF6Ca53Bdb",
        "signature": "0x904c97a955cc8981cc51c86c94de977d6eb5c565a00e9a736826ff81ba4ea8aa6bb3a4a841fbe36d3d19e2a98974713ce8898f59c4dfc5b4a910b7de59a727bc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE3Ef16784B61cc41e8234CFf25e3f47E2Cbc5550": {
        "max": 1,
        "address": "0xE3Ef16784B61cc41e8234CFf25e3f47E2Cbc5550",
        "signature": "0x1c343b643807896cbb27f2690e377293b2702435f59602a0e1566796234fae5c4098bfe95a7e26b0ff1b22d75b8a5d8077acfd7e4b3bc8b42a26eb2235fef7b91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC7565DC959df44777Ef23FcF9f4b15fb5F96a12B": {
        "max": 1,
        "address": "0xC7565DC959df44777Ef23FcF9f4b15fb5F96a12B",
        "signature": "0xa48b746e49c601c9ee5c8d039a1c9afc21248a9ef6a13f832f29432a6cf85a3e544ad807aac06184c389ec4f87564f574c3bf12bb2cf7d26c4efbb256746b3fa1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7af09eFC3F9bdD277986a3C48b571802Dc77d87C": {
        "max": 1,
        "address": "0x7af09eFC3F9bdD277986a3C48b571802Dc77d87C",
        "signature": "0xa17e752f4e67e9a6a56609d6011c36918026b6a9f8b13ccf5f00c95e7ba7999e2064b5bb38021b4b41b107b8570ca9b8e71074e55bb0826b28825a6bb3c39a221b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc4210bfD73206c3208ae1F0854ee146Ed8F1271B": {
        "max": 1,
        "address": "0xc4210bfD73206c3208ae1F0854ee146Ed8F1271B",
        "signature": "0xb37663d239e8e9f3ace260be74a9391b8bc07ac5dfb35351e39859cab013ed571de256d693615c484ee9a308d7a6fbe03c8e71c4e19e621a18faf5162e52b4311c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6Ad37883DaDcEd901a8088DF08aAaEf57968D6f7": {
        "max": 2,
        "address": "0x6Ad37883DaDcEd901a8088DF08aAaEf57968D6f7",
        "signature": "0xa8403a125bbb8af3f8b8dd65bdafbe3819b587f54a61551e2455d060abd28413409debb4504f45970895d8821d8d31857232ed61ed08fd2ab6682621b4de9b501c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF4e37e2F1f926a5577182a1a75ddb59ae4bAdFFd": {
        "max": 1,
        "address": "0xF4e37e2F1f926a5577182a1a75ddb59ae4bAdFFd",
        "signature": "0x5f1cbf8d168226ef534189dd8b46027b6a007518381e227a5352479a400ccf17598f944783bedcd2e6562653675a56df9066ae6fd81f45171e7750435f10ae1d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0199e49AAA8226841bFdD9dDB0BA61AD0B251399": {
        "max": 2,
        "address": "0x0199e49AAA8226841bFdD9dDB0BA61AD0B251399",
        "signature": "0x16e7e4c59b09cbaf606d93ef2e380e0e78ef5f547131812f738d3fb3831fa0b40b1032af7f85d760c7b0bb94cd80e35fd4ea4dc5de3c4323d6f3146b255ef6241b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x901c20dfe0e6bef2d51d2B15111bbE1335171aD1": {
        "max": 1,
        "address": "0x901c20dfe0e6bef2d51d2B15111bbE1335171aD1",
        "signature": "0x8c403d738f61bf4480d73c0181a8b8401bbaabacef2605d65cbca9ff516ab889581746f2dd26d448a5d14d77a835cf945963a94900279038d1c046a41b74e6d11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD8216459AaaF2d706B1E275a8cEBc6F3b54AfF15": {
        "max": 2,
        "address": "0xD8216459AaaF2d706B1E275a8cEBc6F3b54AfF15",
        "signature": "0x7b94b4d2cdd91b534970693211d5350ba857945bae610b53d51a7b5ba5f423335bf3f0d5a3f2b62289bb1a7c292d878333a7644b738779fdfd9edb8657ec63441b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6Fd9320d24090732A3aD626d4Dd8d2c1D0fd2ad5": {
        "max": 1,
        "address": "0x6Fd9320d24090732A3aD626d4Dd8d2c1D0fd2ad5",
        "signature": "0x84fb908b9b3be97275272cdf8aff5a5c1c5d0de2d4d73e2cc38fa73bd7e7e7e00ac3b7df1c9dcc0e6aca51d1e1925053e0e8d2ef3a562d81503f256400f95fa91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd6acddc36bbacF8FC0f9fe86501B598C73F5E3Ba": {
        "max": 1,
        "address": "0xd6acddc36bbacF8FC0f9fe86501B598C73F5E3Ba",
        "signature": "0x1e87a6d4f29e723ffd9bf60d1317ace5b399f680a9f08dddfe5598374353f2ba1246cac2ee15505a1641e5762e41756b990f04d947ba67cca614b03466f303221c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb594a0864Cb9E6ED98f7Fc4350af74468551a523": {
        "max": 1,
        "address": "0xb594a0864Cb9E6ED98f7Fc4350af74468551a523",
        "signature": "0x6c79c6eeff4240a2bc148e43a8ad491f44ae5d2341481ac83759038ac6699ff502abe73c84044e58ffa8a1f8c3130746f629744175e33d5a849c1c52271a44ce1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9cd4691aE4f18c5Fd62f598648A1468A22D8D103": {
        "max": 1,
        "address": "0x9cd4691aE4f18c5Fd62f598648A1468A22D8D103",
        "signature": "0x04779c0e0f5c4c69fefd6ff0d8341a3431fd6cb66a1f7d0ca7b6d4f2eea54bd129f5b42a4996ba4443887da24b108185563a5750a390b625463fbbd90c9da6811c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x51dCe3AC5589A7E548147d5bE1fecBC26C16b12b": {
        "max": 1,
        "address": "0x51dCe3AC5589A7E548147d5bE1fecBC26C16b12b",
        "signature": "0x2c111df381dbefd4ed9388e92f13508d62c22713733a5e5492bca5fa3849bc126a97aafc4e7cc33285246029aa8ddd4b2a1d7940f816e60e17a8ab120daf97f61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x497469Ee3CA54Eb44a306027A3d6f36dFbB2f404": {
        "max": 2,
        "address": "0x497469Ee3CA54Eb44a306027A3d6f36dFbB2f404",
        "signature": "0x9711d9e490a548808fa2afd022e20b14e6eeee4ed433d5c4dcc92f9fe0f28d0e30abb4c8c3472a2002f8a49fa01d4363759551ef8a52773d2a63fa0ffaa1a70f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x637530Ca749BCbaBCDC5023cf217c03A7063E6c4": {
        "max": 3,
        "address": "0x637530Ca749BCbaBCDC5023cf217c03A7063E6c4",
        "signature": "0x118852d35b47b4f20eb4631dd5a067d35c2510f84bd040cd6e207b48af9ae0340d73bee0e903a885cabefcf155b6e1a0ae1b3ea1376db8949ec33e63d82d1c401b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC46BCc76e4bE53a2E8b805B2e10d32c6d51cA7F5": {
        "max": 1,
        "address": "0xC46BCc76e4bE53a2E8b805B2e10d32c6d51cA7F5",
        "signature": "0x75e8d40f19c5637eef9506c5018b988125f216d365feb8f6851e4e2c1a7570bc287fcb1a7a4d976033d4477f361b72d48c49b2c047f54f45c7bc34f646ccefda1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x925bEcfD6dE66ED248Bff28D378cF2f2385E34f0": {
        "max": 1,
        "address": "0x925bEcfD6dE66ED248Bff28D378cF2f2385E34f0",
        "signature": "0x88e5d06e77a245fc4fb196f80aed66c76cfdcceb4af96cf614e552259105c65c6928dc9e3830ead43d2a37d1101cb8071d6c0916eafc5bb061f16fe66541b6d21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x969Ec2Ed7E1a674df7C133E12DA25ae353331788": {
        "max": 1,
        "address": "0x969Ec2Ed7E1a674df7C133E12DA25ae353331788",
        "signature": "0x1f7c8ffa77644b831e29038eb3f426989894be5870d4602cfc01adfa876bf31b362fd6970c233215093082ae483e6a54811209ef188ba68e2a43ecc54c9e87611c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8541279e59532C49488282f50402A830879EB0d0": {
        "max": 1,
        "address": "0x8541279e59532C49488282f50402A830879EB0d0",
        "signature": "0x5666825968c3b6e190615eb6205c68c17902b0ccd33fa281ba2ce4023bcbb9b70ae703595f82d1de818ab0c4dc47fa2c8555aec1dc71f034e0d91fb4bd25294c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0e4668fE2671a37efF012033ec2fBd929A113112": {
        "max": 1,
        "address": "0x0e4668fE2671a37efF012033ec2fBd929A113112",
        "signature": "0xb93f6c6c91a24bb93d6ba403199d55a745eb4f9ad5cf0276aa4d3f1b14a4665b595f3de7a0e6c0477be73d6e9137c0d81042848adefe5b07b8a1b8c84d32b3f31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0389334AacED455CFC8c79837550E0aEff527deC": {
        "max": 1,
        "address": "0x0389334AacED455CFC8c79837550E0aEff527deC",
        "signature": "0x08a5d3df5a7d1e4dd2d7b3209696f8e970712698edf04cac162c8d5e87db26e720d0dab4d153bc0db3764f46326752151d1dfcca61449a08a305fee361d211211c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xACd6c2F22493DF8afF4771cd2F85CccC0fd2b2dF": {
        "max": 1,
        "address": "0xACd6c2F22493DF8afF4771cd2F85CccC0fd2b2dF",
        "signature": "0x89c1cf9dd95789a94f36d68560e833bc4d4c3472d008d5c206ad1a5d42d84d117a96d503a11ccd74acb7bcd1525f10cf86d51a6eee96dcd441989846e2919af71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDd501e9214337F787622C36C9FaB4301dBD534aC": {
        "max": 1,
        "address": "0xDd501e9214337F787622C36C9FaB4301dBD534aC",
        "signature": "0xa3da0bcb856f24b7aae49d1ed0ecaf385e2777de17a76f8bae088e4c55ac332c66347aafa0e0484ee3a9b3951d61fc83081b0b51a83ec251afb7ef2ce86e59de1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb907e93A315326144E8593D4d3ED881D5d07465F": {
        "max": 1,
        "address": "0xb907e93A315326144E8593D4d3ED881D5d07465F",
        "signature": "0x56be0ef8693c501dc3189af4125edee23f83a2546ee6b5a1f280e791800ca00225bdfde5026905246f39c3aa091b130b5a69986881a4d3df8e12ebecbd77237b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCaC0D82F4d3b5E761DD553E7C8BfC2049c466994": {
        "max": 1,
        "address": "0xCaC0D82F4d3b5E761DD553E7C8BfC2049c466994",
        "signature": "0x7684344438f71ec27dda9e66fa61c7fe265005f8c3d8d9b67188ea2f3192877b2ba15f68ba51f262fa20121a11789e79c260978a6672f455eb8bf178f271f6e71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa2Aae1aD935CdF19EdF2E9a4cAD9dd34C2F667De": {
        "max": 1,
        "address": "0xa2Aae1aD935CdF19EdF2E9a4cAD9dd34C2F667De",
        "signature": "0x3ee9bddf821e24b1b858e059cff3341f50a9cece6c6919b80b0865560316e132147d1c41df90ea807fac818e128fcb0c721efd6d0263e3a734555d34f01da68b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC079dcc36a08f59a1e8345a1d61b17b914D455c4": {
        "max": 1,
        "address": "0xC079dcc36a08f59a1e8345a1d61b17b914D455c4",
        "signature": "0x0c7bf2989642442fd6e74a802c84fdb067dd00c5e9f5bba09d294bca555f6740225ccb6b8ac7847ea0cf1931882148d33c566a7e00935b134844cbc1367bef321c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeE0f9973B2159229AaA0b5E90a704F9da72A8Da1": {
        "max": 2,
        "address": "0xeE0f9973B2159229AaA0b5E90a704F9da72A8Da1",
        "signature": "0xbbe2ae6e892f38fefdd3a2f0377d547b8cef8d1a547d4a74bce2bdf4ea81b13c74117ab535b769c709076af14e2ba49c5bdf319904bc3a1056b0719acb30ecb61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcc2b2e213554a002a18707AD0299f939F52E3bEc": {
        "max": 2,
        "address": "0xcc2b2e213554a002a18707AD0299f939F52E3bEc",
        "signature": "0x41a53fc6723f3439032b3350df5dae8bb9724cc4d5e48a1d6a3703040566bd151a8dec0caa45bbbaff2b602520b6c69875d661b14a01080a27d12dc1bee360e31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3bA31Acf6b57A4d168e20780cC4869d38BBa1CE4": {
        "max": 1,
        "address": "0x3bA31Acf6b57A4d168e20780cC4869d38BBa1CE4",
        "signature": "0x29add61f791cebba4212307c6ac4c6dc89fd83ba8c24c6733a92ed6bd508828736e3a5e3417571545fcfab8a75401ed1152bdd03ed00488a73ecb36bd5012c8c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA5833B938F2829CA22F4672425F576aB2279Ddb5": {
        "max": 1,
        "address": "0xA5833B938F2829CA22F4672425F576aB2279Ddb5",
        "signature": "0x9d39c630e682544329d8b6a3c38d39752d2c5130f5b317332b182e992b9530656bb042725541ce53cd01ac0451a411a7b0d8371e052ab404dc46e50780ce29be1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5f9786112bf4A386E009d6d45663A730BE7C0b5E": {
        "max": 1,
        "address": "0x5f9786112bf4A386E009d6d45663A730BE7C0b5E",
        "signature": "0x9a87b7911d6559f88cc6db59d8e65d082b34510ce53dfd55da08f3e8cce761426605e44019fa0b39ad7310068bef11182e308e299140f1aca70297940ea86fda1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAD227c95a6A61629f8337f058da33e5CE7901A66": {
        "max": 1,
        "address": "0xAD227c95a6A61629f8337f058da33e5CE7901A66",
        "signature": "0x937fca2ed99e8a492ffcb51e227dd24d76c0025769510effb650c322c0a483430a9ef498777949db8e2e0afd4750c06ec614a1f934c266e59886835d6e47c0c21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3D128Cd0eb80627b8FebA990eA02726644E43012": {
        "max": 1,
        "address": "0x3D128Cd0eb80627b8FebA990eA02726644E43012",
        "signature": "0x7ef01a4389bed64e31ff39ff4bdb5c1e2b4d39e8d196cdb624fc16d9992df8df349705b44513a91641ce4a014cdd11b24a9329a8413186535ba1f6062f37e9f61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x389eD265B1B10EDC731bFb0f19403117C2354DCA": {
        "max": 1,
        "address": "0x389eD265B1B10EDC731bFb0f19403117C2354DCA",
        "signature": "0xac36365992b3509ba5461445ade5283a650599ae2c0b8b810dcfdd045d45af173d8360bcc95b20e926415d9f2a513b8f301dfb3b128ac24ff50b88fa020352911b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x947594184dc016CcEC78761a3AcbD2c443900B1C": {
        "max": 1,
        "address": "0x947594184dc016CcEC78761a3AcbD2c443900B1C",
        "signature": "0xed005d49f36159d68dfdc975aa3fe0b67704ecbf5e71797e2032dcb88a20a0ef2de7fa93cda77dfbf92a21d75342afc264da727aeb9fbe7d9dd8d267f267b68e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF8403C5380D6DD5fa1B05615b755e3dC8C824942": {
        "max": 1,
        "address": "0xF8403C5380D6DD5fa1B05615b755e3dC8C824942",
        "signature": "0x2b89d2b93b84d1e444f82ff4fba23d39f8d159454c462fb69f0f17c41d1c52b643117d42c7252c383954090d46f5ee79ac2a179b3d8f97afee22ed5bf144739e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x957b60afab8050d860A768B2D832C77C0C2262CD": {
        "max": 1,
        "address": "0x957b60afab8050d860A768B2D832C77C0C2262CD",
        "signature": "0x94708eddc842a8fc0655ed3bbc22ad376dafe96d5856e45c9fe5f57b3bde4bec0ee8258e0b1b80ac7e157455961463ad09862c2d5b2f70dad30211cf7ed582e61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDd4f1a94B7d50d1410ffaAF84c80E6fC7f650BB0": {
        "max": 1,
        "address": "0xDd4f1a94B7d50d1410ffaAF84c80E6fC7f650BB0",
        "signature": "0x7bc7f9ca97e4dc66ccfb8e7538d48db0bd70ef592aaabc7a97a9cdb510a5b51a044ac942d576a157ef0c4768e0a7caca8a8189b32581ff112cb482dc39075a391b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4A31A894F79b06bE8FD08D03d12D8feE87E218ec": {
        "max": 1,
        "address": "0x4A31A894F79b06bE8FD08D03d12D8feE87E218ec",
        "signature": "0x92bb9c29c80a2131cb11cc22c0e4c66aa07cabc14e4ba125d1517d6d6c3dd03c79b9f45be4de4420885539186c34e7b379b8137a111cc347988249d8e399a9e41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD75268993C71A5d1347ca103c146cdDAdB4296c5": {
        "max": 2,
        "address": "0xD75268993C71A5d1347ca103c146cdDAdB4296c5",
        "signature": "0x9daa86e19f7d3b70aa74e2fbc158696329b2deffd13e67193668e7f00d719b8a4825025338ee4203666ac52ab2a29f4d797783687610119917a7457677d312bd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb2454F13c40E75cE415897F255ef8a33c6C6F469": {
        "max": 2,
        "address": "0xb2454F13c40E75cE415897F255ef8a33c6C6F469",
        "signature": "0x256ea2dd685963f714ac3e3a1b620d032e755e8537229374fecb523f4daf10366b115140d2d72aef3d04578bbc91f2c3d1d06b279c5f022007ac9e562e895f0d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x279681641b9b7C30437F55f2FE3d18889160E510": {
        "max": 2,
        "address": "0x279681641b9b7C30437F55f2FE3d18889160E510",
        "signature": "0x225608b43de33625fd8540115825565006d9b8d044ef11df174bc1d1984b9a22484c1e788e30be0f627b146f3b1ac9b2a7a74aaf65b5d30069371f289501821d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x70c197534172F1f5dF65b8e4DbD32468D4429a89": {
        "max": 3,
        "address": "0x70c197534172F1f5dF65b8e4DbD32468D4429a89",
        "signature": "0xf39e8f82fcc8bc30c10a06f35e4352782a6d23ae6e636ae8054b57eb92578131246406cb4a4dc7a15be9f02fcdb7a610579c0517999ebe13d06f26ee1195d8571c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x733254a862773DCFC94581d62861a5063Ab971f5": {
        "max": 1,
        "address": "0x733254a862773DCFC94581d62861a5063Ab971f5",
        "signature": "0xf728cf8c4eb34c6e58bbea2870de4cb1bf39b8daaa2eb7220d1349ee4b67300a35c58a3d387ccae66c81e0ba9e914ae96efc4365dcf0aebf7a2e19d6aedb64fc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1AF6c6EFA1e88205aFc30CEd89cA47B18aE325C3": {
        "max": 1,
        "address": "0x1AF6c6EFA1e88205aFc30CEd89cA47B18aE325C3",
        "signature": "0x84dedae47799b1b3bcae3027e4ec801017439a2bae8c5e6b029985c36e551cf7113b0ce62a88214cd23b11089dc443fa8f5b91c83a7ec72a98d4cc6578c3fc741b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x78952753bF8F4c1c2C4118FdE0741e513cdF5fF9": {
        "max": 1,
        "address": "0x78952753bF8F4c1c2C4118FdE0741e513cdF5fF9",
        "signature": "0xc2e379e284acad1d3972d7e7f125892371479a8b39e54e0fe410d9305b8cac1c6afb974f28bea2dc0a0125e8974e7f103ddcb655c989f8651b3a395093d37f5a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5dca92c3b137E78e7E2D96EB64d3B3BFd0eeB775": {
        "max": 1,
        "address": "0x5dca92c3b137E78e7E2D96EB64d3B3BFd0eeB775",
        "signature": "0x3515839b45a0ae12c16f70616935a0678d6bde01b269bc074224e1b81364822a375500c17a87f7052f5d24d434378e334296d77e214d7b03326cd127556df0fe1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x484ce76a06979e90AdCB7ec1016D58c5B1B31Afc": {
        "max": 1,
        "address": "0x484ce76a06979e90AdCB7ec1016D58c5B1B31Afc",
        "signature": "0x18b094013ae84bb754ac3521c405baf2da5503484469d72dfef892940fccc3c0459cfb0e329289c8904fcfe40cf6beca00b13feb14d42c1505c1e4bb3d6a99561c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x88000cE6E891540afB417bA9c5Db687cC688599E": {
        "max": 1,
        "address": "0x88000cE6E891540afB417bA9c5Db687cC688599E",
        "signature": "0x8582b8043d4fc5b486fbdac8ba5fa8221a3e8ed2ba645cf53d407c78b9e087fa651edf855ebabfc66faa63c379861e65b378b1f51a21b10bb67bb706e1bb3bc51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4DF1434F73B4B35Fe604952c20855A8266002d2c": {
        "max": 1,
        "address": "0x4DF1434F73B4B35Fe604952c20855A8266002d2c",
        "signature": "0x3e55abbdbd798f87d356f4775dd0e27d7961113bb7771f72021855594679d885709daf2b6ce00499d1e3269d95e131fdf22d7a257e225964e0739ed2c73806311b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x125D6b3262066700dF05f55b536Bd7e8e7005784": {
        "max": 1,
        "address": "0x125D6b3262066700dF05f55b536Bd7e8e7005784",
        "signature": "0xa5c2d6a4fa36fee09a1b6f9058b16d82a08d2cc48517bcd60b9a17638684daf258e7f20ffe5e7912c8f2f9a9634ee68f86da42e8bc5198fb24e6e97bba371f471b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xed3825D0E6b5f9a3E7645e1D9391d4a8933A3141": {
        "max": 1,
        "address": "0xed3825D0E6b5f9a3E7645e1D9391d4a8933A3141",
        "signature": "0x2158bfdaa0e1b11732e5357726d69fe0b3e2d3ed3230ea0c7c5873e16339339f3a676bfe3de2bd9030bd7e9c587a957c0e9f80ca50a1243bf94ae7d914090ed61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x114adC10FC060797837615E2a18Cc5B47cbe42eD": {
        "max": 1,
        "address": "0x114adC10FC060797837615E2a18Cc5B47cbe42eD",
        "signature": "0x54baabcdc4622a05abf36e29213aa288ff354c91de37177829654252a8d9a44e17fa5812ed89105f77e25c6bc5c75ec91cb46f8de81c99a85eb758676d2b7ae71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1Fc46d86890cB931432ba21E0fA05C70fF9b0FF0": {
        "max": 1,
        "address": "0x1Fc46d86890cB931432ba21E0fA05C70fF9b0FF0",
        "signature": "0xd8ab74e56a0760b03304afa0fb4a7430020def769a02aab00947f1b80c31552240fe918c1be1bddd8810cd83ed51e18fa12d2b389bd354d8afb0df42ba4de1031c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x971179e22ecd1c8b0DF696eB44132c2cf8eEdAb0": {
        "max": 1,
        "address": "0x971179e22ecd1c8b0DF696eB44132c2cf8eEdAb0",
        "signature": "0x02747cd84a5c1fba508a5523f900bfec6862ddbf4fbe391e91b3bd6f293a360d3e239e411e55c35d61832e3abaed9f4d0012afba56332f1df5102c2ce32f563d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4B99500a80a40Cf96c74B68c7357dd5a3605dB6B": {
        "max": 1,
        "address": "0x4B99500a80a40Cf96c74B68c7357dd5a3605dB6B",
        "signature": "0x84bc49657c478f42b152f4af4f00130aeef5017e5b0a524a89e159d5d1ed94246dd2f9efdf1cbed86e706d58256debfb4e52a3d7cd758d28660b254af2a3b77f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9025494aD3F33aD1cdAAF8F6a8Dc09be2dbac24c": {
        "max": 1,
        "address": "0x9025494aD3F33aD1cdAAF8F6a8Dc09be2dbac24c",
        "signature": "0xd2346511188a6d621dbe4c18d9bc8d124e4a37647ad17a9c8dbe432b086c17f84be1534e7ba1ec5425c71eac3721c2c1e140991106065a373bf2956be946db941c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03eC95106e485b401672d13b22d9e22902e59fD7": {
        "max": 1,
        "address": "0x03eC95106e485b401672d13b22d9e22902e59fD7",
        "signature": "0x91b34b431c5bc7a5aeb92140d9f592a1f7a7f02b4ecba6ba3529f6bc125a5e7908beb98b0b05b658999901bedcc0e3b91991277cbbd359257f7e0055a37e75541b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc255599e94f60d66b7EC83f20664dcFE22b716EE": {
        "max": 1,
        "address": "0xc255599e94f60d66b7EC83f20664dcFE22b716EE",
        "signature": "0x84cecb544dd5b86d934be40b5ba3c8c7a596196f4402eb3eb6695d4ef7c6150c178d4ac4ffad6c6f0c6474700143bf8aa9e04a7668799a75a887165319a8d4811c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9504492bC0955dE59337742b5BdC2643834Ac9dF": {
        "max": 1,
        "address": "0x9504492bC0955dE59337742b5BdC2643834Ac9dF",
        "signature": "0xa58e56d14879805e6d3c08b0ff44aa4200cc23d766878c19e89c1fe06da50a5a0024a4cedab1b52c1ac38a2757de824888e745fc2bace3e55116f4fcd54028ca1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5F50676c95107b336f3479a8BdB72416f6A6B23F": {
        "max": 1,
        "address": "0x5F50676c95107b336f3479a8BdB72416f6A6B23F",
        "signature": "0x2f017aed83d465417bdff267d7c8d6b23506457c2d91985d45d8a8f451ffb27a0b5eebc5a0df51c8362418c8ec0cb32fc7484ef376308e9c052462ec895c97341b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x102FC63befB568842fDE79C5919e361172e88501": {
        "max": 1,
        "address": "0x102FC63befB568842fDE79C5919e361172e88501",
        "signature": "0x213e3177c6c024fd604fc386550c997d8afed60d659c33627441dc26a07fcfe03fbc2c1ef0dfb1b2d309d8a80ea5b2d12fe3d77d9b7d0c37431a413ae62f49d11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x890939Db6Ad01BB4262bb5b658d912bc25FD781C": {
        "max": 1,
        "address": "0x890939Db6Ad01BB4262bb5b658d912bc25FD781C",
        "signature": "0xa9d1bea2d6446308f14454b28b9380901a24c910f0c5b97ed56887f37ff28d31206a22f0242d8144d92c383a2ae395662b79fe0445c804fd6b9e4b739ec77ffa1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x878fd74368ee21a88651798880C9f28831E151D9": {
        "max": 1,
        "address": "0x878fd74368ee21a88651798880C9f28831E151D9",
        "signature": "0x0f89609e7e2fc60027525fb4129381c2d012c048c8adc991014958fbb6f0aa3c204811558c383f77449195061edc2714483325b4232b6f5547aa078297b712b51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x74C6B440647A1FCE01f3f212f719B82388338806": {
        "max": 1,
        "address": "0x74C6B440647A1FCE01f3f212f719B82388338806",
        "signature": "0x97dcda459581a0e3bd75bd7679513d9add18064936553a486b53a64ee4b7164b16298c7abda1d84c3f39deb353a52cd616a71c76f37b047deafcd4441f64b4b71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEE175Ed96e8BEEBe0b269fdD6D86d3f6Bae7a83B": {
        "max": 1,
        "address": "0xEE175Ed96e8BEEBe0b269fdD6D86d3f6Bae7a83B",
        "signature": "0x1d0785e1e4628e052a7c58054621c53168362e37f47a238b121b0050d8533969404667c21df57beea59c4454e7ef4df18ed3607dc2ce2c8530dc91eff76ba8761b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5d6eCAD3eCA7473958B2bB91a7faE6F740b1AB46": {
        "max": 1,
        "address": "0x5d6eCAD3eCA7473958B2bB91a7faE6F740b1AB46",
        "signature": "0x3dcfd1d6694a344e767f50fa5034211ba512e9fd492013b1e61188a8d2fc7b454843a536d449601554eb9138be48a97e1711c502976e78b2372cd1b516d8356b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x64411c121649aa8099dccA4aA1571D03C149Fa67": {
        "max": 1,
        "address": "0x64411c121649aa8099dccA4aA1571D03C149Fa67",
        "signature": "0x478e9d1a37941f2a8ad6f5e0f35ec000cde91b9a86bc47e71c468c2d6b25061f3258e06288de0f25793d63010ab73760da9659c1ba7df452dca4e2addfd832061b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe7ebFC0e87F1C6C19f49eDBa505821352fdBf153": {
        "max": 1,
        "address": "0xe7ebFC0e87F1C6C19f49eDBa505821352fdBf153",
        "signature": "0x7f1552ec401afafb585e50705a0e02d4be69b43eb3a2828fa22cb3f15e5b69a25e5c4b0f59a5d3f6415e453a0507a214ab0be2b11539507bb113fdca7db185ff1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe8c114A20bDb626D5c6C95D3F3A28C607fa4fad4": {
        "max": 1,
        "address": "0xe8c114A20bDb626D5c6C95D3F3A28C607fa4fad4",
        "signature": "0xf18a26de280b7580478b149ea7dd3b20b5bf410950ae95a8b9246d08e8a117a9145198d70fc3a2a327b58701898b8915892bd8c5c6b964842d2077b668f07abc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x438F17283962959c46c22864aB0E7C4Ce6ED0DAf": {
        "max": 1,
        "address": "0x438F17283962959c46c22864aB0E7C4Ce6ED0DAf",
        "signature": "0xc94915471ad05589f951ecff96c3214a6afa00d187e4303ee999b581c993d1d336eeebeb9bbe2027bc34ce00eb100432879dc627b72b306c9833ce5bf8a1d1011b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5657A97882b15584c00dE5E0670320ddc081C5a7": {
        "max": 1,
        "address": "0x5657A97882b15584c00dE5E0670320ddc081C5a7",
        "signature": "0xbdb30223da554a5418c08a703702a985b368b62dff7f0babf5a7c35f269ad6c1034a19d9b2a1f44db91ee598fbed1ffc1aed9ee2b85eacae207e0009b1e030311c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1F4FDA7FeaDCd312cFADd88C7bA7E3D708Be4c3b": {
        "max": 1,
        "address": "0x1F4FDA7FeaDCd312cFADd88C7bA7E3D708Be4c3b",
        "signature": "0x10512fa8f0ef992148150806164af2870d6b6c8f1a2294d73db0a7d3ffec81f45af6b5bd7b5db0b928aa162963f01abcfc3b2166ff3b1aa3dc8cf9a4f0353abf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5562369D2199E047d0E5fb05E178Cc0e17F76960": {
        "max": 1,
        "address": "0x5562369D2199E047d0E5fb05E178Cc0e17F76960",
        "signature": "0x272500eb196f0746127a8b141e5503c93619e8e9525247840735ee54c4bd99543047f0d451e026cdb5dcda7b12de4b1fcee3f1dcd12ebf3fe034acde3170d0dd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x758cc4f9CfB1de01b51835a2baf3dE87108D7A6b": {
        "max": 1,
        "address": "0x758cc4f9CfB1de01b51835a2baf3dE87108D7A6b",
        "signature": "0x62465888b7ec9bcd2a39bb046fa767ec7b08a24292624d2a71835901947bf587310a665b130606dc645639f53c322a93e01e49ae549f6b959667a0bf56c5b80c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x408CD8d2228103930B90d910D3583C7092ca910C": {
        "max": 1,
        "address": "0x408CD8d2228103930B90d910D3583C7092ca910C",
        "signature": "0x3766a4fee5db4ae58426df52cefa3f378f5488e2f74bbd612acec290642991827141f87041c9bf0a0327c7f2ee7678e887ccd7c37f1f54304c3a704e2e0be7541c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbd891dF7b6a7C2eBF4da7f3EE10e6B4642AbA2c9": {
        "max": 1,
        "address": "0xbd891dF7b6a7C2eBF4da7f3EE10e6B4642AbA2c9",
        "signature": "0xf1d6e0f1aed79e543e6758c58913589d27b4840c527ad260a980be378ea0e9da368f4b91d920a78aa51fd98c7493bd29757b84eef5b7ef60b7965ce138f213291c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x602766453FcdCDbcf4F8B4e4CE7205762C91911A": {
        "max": 1,
        "address": "0x602766453FcdCDbcf4F8B4e4CE7205762C91911A",
        "signature": "0x5a34fea29c39681e09236305f7d78c09230b576eb9c2157638932f32becd6a4d08e7c25403eca09896e5fa90415b1e546f2d0700d9d43ad0893ce3a6886226461b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE6DD972a2002B6b5Ab74697631E6f328569FF67A": {
        "max": 1,
        "address": "0xE6DD972a2002B6b5Ab74697631E6f328569FF67A",
        "signature": "0x38e7d648c94f3a1ff00f3b7cc8da9611a5d2488d63691c58847f30ede3ccf54d26633b94c4ee432baeb4b834a672e022fe2af7987446c314bdbb92b74b81e2981b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x956294B86DA6F36ff8f4863a430a86Dd173e6171": {
        "max": 1,
        "address": "0x956294B86DA6F36ff8f4863a430a86Dd173e6171",
        "signature": "0x0606a7dddffe33f1533af1a92d8f54212b761498e6e1fff177342c4caad134774d91aca39cc64207937d3018b94fbdbd10786e0a7e0f847a899031e3d68d4a011b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0A913347733F818e325537369CCD935206Da722F": {
        "max": 2,
        "address": "0x0A913347733F818e325537369CCD935206Da722F",
        "signature": "0x5fbc96f78bf7db76efcb88ed7521f3872841ec6a59f1a6a225bc584aec449da522825059a218d5474b01de4d336e4970bfa79cce435611a29570d2b8f42d17de1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA45464f00bC46E73f53B0bAC5a65c2DEb692eaB2": {
        "max": 1,
        "address": "0xA45464f00bC46E73f53B0bAC5a65c2DEb692eaB2",
        "signature": "0xe8132b9d9f60e53e6b97f4c9e9d2be793b55a969783120bf194e6316dd5017e3268ebe35888f953d795737b830cdb96a5c53c0f7d64f0aca57b39ace4fbd445e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF6299bA435c514d440224378C21c639170BA103D": {
        "max": 1,
        "address": "0xF6299bA435c514d440224378C21c639170BA103D",
        "signature": "0x0acb1b9acb3d2d0f8ca1c50bb86dfab5f7a41e424a5222f86cc0de445e9903003c5b6f961a4a46f1c1fb150475969f881e26a1e6f6a1e868f2fd70d74f9734871c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2A7412e740570dF63C777ad70500455e45039EC5": {
        "max": 2,
        "address": "0x2A7412e740570dF63C777ad70500455e45039EC5",
        "signature": "0x18120df944ffb15cece8b1c8ea28f31b8c85cc837137e0ab806a0a98721fe2176d9118964e00cda92e1ac8b5a0e3498ac39b4394fb54895aba1b1eafa975198a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x393B35C6221b93F76a2E856EFC03E22E187C0638": {
        "max": 1,
        "address": "0x393B35C6221b93F76a2E856EFC03E22E187C0638",
        "signature": "0x57b95d52699fa0aaa627782ea2129e47c9099700319bc27e40dceee3fc7d84841bf7882a6d4783c6b16bd6bd580a3f433a9ae4cfde3de1a8655d6ce075ffa1aa1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8FF94faDEdeC0c8B77d09d8e3d3CCDBf13D55206": {
        "max": 1,
        "address": "0x8FF94faDEdeC0c8B77d09d8e3d3CCDBf13D55206",
        "signature": "0x6ea58d34755d6edda9100c367c134246a044670b13fff65e7ab18e2975d039b24b21a5b5a8f86beee3e7aeccc618d53f95d9de1e64b03d2145fc01a4aa6a1a4b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2993707212591a3A9Dd356418FA9D8bE760c2421": {
        "max": 1,
        "address": "0x2993707212591a3A9Dd356418FA9D8bE760c2421",
        "signature": "0x11cb60cbcde5e822fb8fd758dff261cad67206e890f92f6bb3d33212895dbb0f6a34dd03d393046b50d0d57185d830c8b9e3a773b2ef4d059de7d371302df3311c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA6774532438037Cb134046B78885BCaFb974518b": {
        "max": 2,
        "address": "0xA6774532438037Cb134046B78885BCaFb974518b",
        "signature": "0x5d9132911a5076675c1a67f135f58688cc5ba2ab62693d8d497914b694d239711e3ae7f7cbd63814fe36fe127708532495a9971d5c2388f2c8758abb0217f4b71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAd152EF2715ED46dA70D1C4b87dea61e5265C6C9": {
        "max": 2,
        "address": "0xAd152EF2715ED46dA70D1C4b87dea61e5265C6C9",
        "signature": "0xf3c26355a4c95bf2cd84c7b9428053a102be1e287c565b6dc0c5bc1df58cb895753911f628e6f988e1b927208f7c57c491df55a9d5cb8bec287e04d99e59700f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0c6013e9351E4FaA64744E0091a658fcE18F9Fb9": {
        "max": 3,
        "address": "0x0c6013e9351E4FaA64744E0091a658fcE18F9Fb9",
        "signature": "0xe057fcccc81af8817e6378411942a6c02c920763694388eea686820fac97ea392ecbc15e4bcd834b8ec290aa9576b46337af18f8eb09f3f4dee46424113fbad01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x43603BF3C47781eCd5774Bf97867e725719a6164": {
        "max": 2,
        "address": "0x43603BF3C47781eCd5774Bf97867e725719a6164",
        "signature": "0xe74239f1bf38e651f4d13d5aced97f93b15f5cb0e61ab3544c68c8eec5c543882d69040854cdae8c746fcbcf6364c7bff4b2130236f0cbaa42d17bad75c03fb11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF656F3C30A6658ED4C1b2eC34FaF22414EeEf70d": {
        "max": 1,
        "address": "0xF656F3C30A6658ED4C1b2eC34FaF22414EeEf70d",
        "signature": "0x6cb421e2b56e6a1bba434fb6a6cb6250cbf2d3c4fcd4ae42770e06574b08aa775e3df4fe4cfb539e84b0b9a0d3a53f230074b72e7cbcbd11362d7624b45418601c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2BEc2392807BeD0099C882C2890f4CadCB00FD75": {
        "max": 1,
        "address": "0x2BEc2392807BeD0099C882C2890f4CadCB00FD75",
        "signature": "0x1b546b352c1aad621ebc80c3a90678b8365ce193d6470190beaa13c5889ab0fb3675e99cb8e5fac664dab72a83366ee22dc94635a29c89c34d32b6cb754662d11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF028eF3B664Fc3967adE3f7B491BAEe8C4cD6051": {
        "max": 1,
        "address": "0xF028eF3B664Fc3967adE3f7B491BAEe8C4cD6051",
        "signature": "0x4b0a90d5f929022dac6225d25a11646a69a63089921bbbe795607c0d4bb227920ad0e52fe32c20a1e60bfd37a1cd7015712ebe11444231867d0b472c0471b54d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1Ced013eDcb171bBB53926D3624643835F0cA839": {
        "max": 1,
        "address": "0x1Ced013eDcb171bBB53926D3624643835F0cA839",
        "signature": "0xbe081521e45267a65e52f3a083d1b3f8000b055e339ce3eb882066c6767e43f57990c136fb495d11cb209ddc340b8d967ba95aa0271322798a62aa3ca54346891c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4Fab0B7deD104244686bE6Ce4EEB3Bc7e0e68192": {
        "max": 1,
        "address": "0x4Fab0B7deD104244686bE6Ce4EEB3Bc7e0e68192",
        "signature": "0xab2081621208d87421b743821e697673445b6c7152e7f145b2f47f094db859976961d9a505086bf4da0a5a1b7a37578cf08b8406f0835dcad6e234cb9e36737d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcBc0B69adb14EBb3BA214F98A337e0633d34C3D2": {
        "max": 1,
        "address": "0xcBc0B69adb14EBb3BA214F98A337e0633d34C3D2",
        "signature": "0xedacbdae52914e6bbb15047b1dc36713156eb109990faa535c5da9a9a17e55e84589b665555659c9013bda0373bebc0901ebacb3ab0976df023aadb702dc6cb01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xde08BCAC0E3Fd0bBe540D117C58aEFaa16225f60": {
        "max": 1,
        "address": "0xde08BCAC0E3Fd0bBe540D117C58aEFaa16225f60",
        "signature": "0x27592b528def77557b32dc0b68c3f13eea7c40adf45bfe2c4450522769da70940e87136a94933042198b2ba5cd5f4241b5399fc4fbb3630603e50821873d0d541c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x445934820d319b9F26cD7E7675c3184C0E2013FD": {
        "max": 1,
        "address": "0x445934820d319b9F26cD7E7675c3184C0E2013FD",
        "signature": "0x1bdb80bdaa84015f7a68a49cd3f96b7476824bc2d3b8b0be6ddc250bd4b3a0a476e518619042c775293a308d80d86a29bef81aadd84c58d9add1e48e72c2e9fc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC0509a3ce4225410C94029C3834e493B9d7E89F2": {
        "max": 1,
        "address": "0xC0509a3ce4225410C94029C3834e493B9d7E89F2",
        "signature": "0xb6e585fa49136b7133056a41ac453f36267de223654c456f68b55207aca8ffdf1dd37f3c568139df8b86655f401dd35b202da74d6fcc6cfb6e47cfcf61eb22441b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x53a64e732018197c422D1216Ddae11E5df45862E": {
        "max": 1,
        "address": "0x53a64e732018197c422D1216Ddae11E5df45862E",
        "signature": "0x2ffec8ec7d6fdaf959870158f64882eb20dbf9f3f31113cc57cdaf698ab9b92e2022929769a7e78e733afab8a163ef6eff65cd4df7762f65fa8d479120fa6b131c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9ff3e39Cb942894A631a5Bfd5BeFEB60ac8b3B6d": {
        "max": 1,
        "address": "0x9ff3e39Cb942894A631a5Bfd5BeFEB60ac8b3B6d",
        "signature": "0x64c575d791138a904c09fb7e1883933a5c44e64dba1fa9581d46a9bea733fb08541ccec6166841a329a03e22ce5811dac7474e9c243f8b9ff80a381436a42dc01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa0c285362C902dbAf5bBBCdB0C78f945b7Efba3D": {
        "max": 1,
        "address": "0xa0c285362C902dbAf5bBBCdB0C78f945b7Efba3D",
        "signature": "0x66add0bc04498a88dc46602c683271599de2a01a5f8efcb2ceca728fc01c161172acd31510fbdb9d92f98d25504c0246963132f9d004862c92ec9e99e8f8e8121c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1c76879485fC22B4A81850CCA1dA22B2b442652d": {
        "max": 1,
        "address": "0x1c76879485fC22B4A81850CCA1dA22B2b442652d",
        "signature": "0x19d9ea62556f26f66fbab4be378572bb88c865db3aa1ff11a7359941fe3d4e2654c8ac349a628b364152fd977af54cd31d2012883d9c7cbcab84e978fbb574d91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x45B365834Db86549D170e038897665BcbE69B68A": {
        "max": 1,
        "address": "0x45B365834Db86549D170e038897665BcbE69B68A",
        "signature": "0xfed30934ff9dce48f3b7bbfeb205aac8e6212efdddc0d5b1f876a1fd9e8f6de734672bce4d6f28d6fe4aa48940f26a8e9c8e0517a4c6dfb4dd0179b63c669eab1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x470299A90C371B17D6DFC101cBC7c090C1555408": {
        "max": 1,
        "address": "0x470299A90C371B17D6DFC101cBC7c090C1555408",
        "signature": "0xfe136748c4bf2d7b2ef1a51151a65234e4ff9640852411eea1eea1060c0948454987f64e30ce47148d224f8834fe715541cdbc66940d6c8fe09365a36c2aa10b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd24D37dfF53Cf50faa81a832fe39B5eBcA24506d": {
        "max": 1,
        "address": "0xd24D37dfF53Cf50faa81a832fe39B5eBcA24506d",
        "signature": "0x467d276d9c3a496f3f95d66cec441309191925fbbdb3beff6a033e1956fd14b6065f36e869f8ad7e10b78655ddc7d72b99c8cd14433d0086460bbe16e7cf6b251c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8b3de5F6B42d101c6e675DCF8480BdB00441a3d2": {
        "max": 1,
        "address": "0x8b3de5F6B42d101c6e675DCF8480BdB00441a3d2",
        "signature": "0x847cf2cfbb7495e29748ed32f8be295283300bf75a20e8d608cb38f8a6285adc1d8da833c74eac15c414c48a8fd87045ac8d600385a67f047a21589022c978271c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x662524fa5E9353fC29C1A2BEd0dba6F9cEA84043": {
        "max": 1,
        "address": "0x662524fa5E9353fC29C1A2BEd0dba6F9cEA84043",
        "signature": "0xd2ae7d57b9c2f28941b850ac933a655a68c282b232da5d09deeb9186d565ee000e86953761279dc2c9ea80ed3e7be88151e44b016b0d79778312793cfef9851c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb5065C13b56116d59709882171A98FB93C5E6Eb7": {
        "max": 1,
        "address": "0xb5065C13b56116d59709882171A98FB93C5E6Eb7",
        "signature": "0x911d0c42197035afdc663469e692e2d16be1fb394f40f66a3b894fae4d9a74c038dc8c685a4eac6849bb2e175fb4aad2f71fd555a21ee22da56931e3f9a345141c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x31AA93BBa0dDA060146e68d38F363AEBDfCeD03C": {
        "max": 1,
        "address": "0x31AA93BBa0dDA060146e68d38F363AEBDfCeD03C",
        "signature": "0x607f4ea87845050ff591e67d37cc20f856d6dd2b6f212a14aa6d73f7b08e4d4f0a1d13bfc42266ac03ceeb4f02048bc6fcea298b229dbbec3d5ec05ef29ca5731b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA7c427294EB212e065D4F3Bce7F8Fd9076f9111d": {
        "max": 1,
        "address": "0xA7c427294EB212e065D4F3Bce7F8Fd9076f9111d",
        "signature": "0xd0ab31020302f6b13e9f4d0139e6a31283d23bcbdc3ecfc2cf58854f3cb29da06136afc1ca146ddc8d584a73f52931ef684b732f4b0d818abb6fdf8cb16e6c8f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFede2Ff6B1C8c6B5e5228C1a5213112562f43c27": {
        "max": 2,
        "address": "0xFede2Ff6B1C8c6B5e5228C1a5213112562f43c27",
        "signature": "0xac9a5fbc308d86ed7047f4b02686311b3299af0fccd29f55a39ca0271d498fae2467b622e72c35aa3af8fab5d07eb8b5b58a6a419f4cdb28d5da46a5bbee50981c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6A134969EAaE12cd0EeBD26B8331a6E7D26034a7": {
        "max": 1,
        "address": "0x6A134969EAaE12cd0EeBD26B8331a6E7D26034a7",
        "signature": "0x55838faecb5f344aaa8d53b3c687fd751f510c0aa51f4a104d30a544059df93d4a98aff3f92620d3278ed91a7b10352ecac9e4fea152346b3932886681c664ed1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2EAfe7265584E8C44c158B9bD5f78Aff8309684b": {
        "max": 1,
        "address": "0x2EAfe7265584E8C44c158B9bD5f78Aff8309684b",
        "signature": "0x06c927b879482e3f46624e93aa7022126dc93b1ab852e6105226f7b80bf4e714538ff2193cc249aa379a8cf813c1a06eec043b9468d763118e8867c5f50f22e81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3970F4fDbb18B546414116c88e0D561D25f3bD7b": {
        "max": 1,
        "address": "0x3970F4fDbb18B546414116c88e0D561D25f3bD7b",
        "signature": "0x8b0d5a5b2ba7a90288fce0f47b045c6f39dbc63ee2b6fb2a632a98261f40fa5f187333abfd884432429ee5641491b327afa1c5b24dcdc0bbdf521fdc2662a8a31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x273C785F5b721866a3edD1cD199647D230488429": {
        "max": 1,
        "address": "0x273C785F5b721866a3edD1cD199647D230488429",
        "signature": "0xb599709440b91ff67c281678c1af8608ed58198a7ed70cbb9927bf40973594c63ffb494d1bab83d5aa8011d499f0fcd83f33a45550d9ffa654dd5b7276ab2f9c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9639d880c674aa0907217995aaA12BceeD047086": {
        "max": 1,
        "address": "0x9639d880c674aa0907217995aaA12BceeD047086",
        "signature": "0x99fe9fceea2f43ec37a85d126c3ac88f32c32541a4ac81bc226c690f624686ca1518d87938c395fdd208cac6a5bd435e37b30105bb2cebdd5d7f0ec0184e138e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd2E05eEE9E3e08E9ACdb8766f6B60BcC3e4D86f5": {
        "max": 1,
        "address": "0xd2E05eEE9E3e08E9ACdb8766f6B60BcC3e4D86f5",
        "signature": "0x42f0b2991457064bb2e7eb3154df91cbb282aacc9a3b012fd9937592d67be4bd09655ac54ed4c0b35441de4d623a37825180cf5b02c644b6b84dfaef958646c71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe2960e4cdF27c05587ceb5d00B62898b98109e10": {
        "max": 1,
        "address": "0xe2960e4cdF27c05587ceb5d00B62898b98109e10",
        "signature": "0x82623fd7902287e76a6616d053ac2426bcd1a2f80983afe251602001284b8efa6f5a7e16a890d22f6515c996a9af9af3c4dcff4c67b7371bec559c88e77b25221c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x792b82eEdd6815b549984488CB5F557E33D8367B": {
        "max": 2,
        "address": "0x792b82eEdd6815b549984488CB5F557E33D8367B",
        "signature": "0x37986e862c45749709783d09e72df460fab0698c99ba58d17626e1db96319a166da40e969756ad75eb34e1463d47fddae829ebd927bfebaf4023c14a36c771fe1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDFa47Ae9612FEeB3D8a59e9c3dc7246284b5ED19": {
        "max": 1,
        "address": "0xDFa47Ae9612FEeB3D8a59e9c3dc7246284b5ED19",
        "signature": "0x3a86a189e80cf993c0a206b0a046033d7c0f57ae9c86d2b370b0aab62f2525871aa9140d76c8c316a05ad5fe7af1c5da7f380abfc36635ae6410f79bd96352231b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe8867b0405169b812fccbA4671F98e25a0Aa81A5": {
        "max": 3,
        "address": "0xe8867b0405169b812fccbA4671F98e25a0Aa81A5",
        "signature": "0x7655b376fa223d2d30608409707b954862080f400559e0f02faa6f984779bdee7398f09ca88186e5aab2173a21d83da40b341ba8748d5111e474eb3b633a5bc01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdDE075726E64E8D70Ce0d133681733E1310312d0": {
        "max": 1,
        "address": "0xdDE075726E64E8D70Ce0d133681733E1310312d0",
        "signature": "0xa80aa633642f295ce593261573092352ab4d6230835b9778aae2e2c8234fb6e06d1f889f494d9bfd191d42dfe2497dbf4a4f290033d58720e931e44608081fba1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x628f11988aeFef41E75fb12b4b18ceAa476EC5Bf": {
        "max": 1,
        "address": "0x628f11988aeFef41E75fb12b4b18ceAa476EC5Bf",
        "signature": "0x8017c6941eb160f418a447461948bd25853366eedbac0c3641bd08e3ba61d7802f36e75ef4b4a0b7f83e6e5fe75e0dee6fb29f10359274a9b5e6c4093eae932d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5382919A355e0b4aFb9D4B8598C00D5dF7eFa3f4": {
        "max": 1,
        "address": "0x5382919A355e0b4aFb9D4B8598C00D5dF7eFa3f4",
        "signature": "0xf02c708020fafc26235de739432b5e22184dee8d229938d811534522376c106b4538ef5476a4e139b627de1bacf67bacc99952f802a6a793eb4e8da9262de1ed1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2f6CC036641cA932b36B0051e7c0744e9c9e2c32": {
        "max": 1,
        "address": "0x2f6CC036641cA932b36B0051e7c0744e9c9e2c32",
        "signature": "0xa7dda0f28fa6d2e63259111a38dfd8b601def88cb7b80a0f6242e6f617e92fa86720a4b8f8285b19abcc59a35fec436730e2f0503bd84fff11a27bd11959419a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x353600d999f3Ec97cCb4A534de422B6Cb9a6c9D6": {
        "max": 1,
        "address": "0x353600d999f3Ec97cCb4A534de422B6Cb9a6c9D6",
        "signature": "0xa2bf45473851f378606ef05bfb7f44038ec9d440a053d5335d348d3bf0935ca421dd5d3bee654667790494fae1fc13bee18c4515d33da25fa6b5b1a845e7e4701c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9bE4d5E3196aCAD32e54FcaAAd4fbBa9A3fe1A15": {
        "max": 1,
        "address": "0x9bE4d5E3196aCAD32e54FcaAAd4fbBa9A3fe1A15",
        "signature": "0x20df78cd511d6dbc47e8ee06f8845a27fa392cdf09f5e65327265560c24b49726228b8bf1b03e17a1d9740e40fe1ec7fef83eba33ff03d5ef5c44a6cfd87b57e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x263A052aDAFC1c8324aB676ACc82Bf2E4d1395D8": {
        "max": 1,
        "address": "0x263A052aDAFC1c8324aB676ACc82Bf2E4d1395D8",
        "signature": "0x50b09755d45b4679f491153f1898432ed02c1ea9b168285c7db9af30e449b95f69b55cd132b765bd803c8539db56fefead6d62f0fd54e2f376ed0e25eed187d51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa0846e6D64E1F3a84cE849B9AE875E82692bD8ab": {
        "max": 1,
        "address": "0xa0846e6D64E1F3a84cE849B9AE875E82692bD8ab",
        "signature": "0xa856c2a9cbec0a8ec756dd9e9e3db87669bb1e2943373b2f3dbb3d778f43c9ef733f783c40191a0915b126d9be51cfef76857ec4ec117fcd867dcab3ecae74de1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc9E9Cb5b59B336c8E177a4EC963074FaaB0681B3": {
        "max": 1,
        "address": "0xc9E9Cb5b59B336c8E177a4EC963074FaaB0681B3",
        "signature": "0x1a3c2dfa05a7ce58ff3b36e645f47b43e08c2f610eb2ec96eb58bce4fd4c0efe21a967911a59807d64e957bcdfce76ff49e048d0f89626c0fb51535ae684f0671b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7dD553eA2B4442A76417C11f92754962BA1AbD3B": {
        "max": 1,
        "address": "0x7dD553eA2B4442A76417C11f92754962BA1AbD3B",
        "signature": "0xab8d8f53cfa67b3ca980bb7ef4a56f4fe23ca000f07dd4723d96d10612196fc0515559862ae974316a532aa2169d7992956fa087c81346c79f9e533e6aa9a9741b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd05A5e6e7E13337A695b29DCb5D6bFD831F43916": {
        "max": 1,
        "address": "0xd05A5e6e7E13337A695b29DCb5D6bFD831F43916",
        "signature": "0x7069880808fef3dff35108311d250898fc4096d96aa95ec4cc8df94e52998e3100f9264a0c0f468f7f8705c2eb3c8cf3fc4d84d0781bb07cb49afabf0286ae791b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x98841f5FcfFc09719254394f352c49c8b56b35aD": {
        "max": 1,
        "address": "0x98841f5FcfFc09719254394f352c49c8b56b35aD",
        "signature": "0xba62edb7e8d8e99738756a9564b53371a3cf3993078ad6c7b685f89180006ded3de6686482e9dede18874e8986a28c54aa5f6d4bc773c65eb2ebe145544c4ea01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1dA6A7F98b14DC4E5509947Ab5A576906D183Ad4": {
        "max": 1,
        "address": "0x1dA6A7F98b14DC4E5509947Ab5A576906D183Ad4",
        "signature": "0xf13943621f085e4ba1ae36d687ea7684c9b82f6168a8c9e0f97e14275b1455ab4f9f18b49c83e9f7ed54925a48a8682f4df3679f58612645ea9cd2d43b650d5e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6aACF2f92e0bFdc278B62c88F4cd66Cd07A6B731": {
        "max": 1,
        "address": "0x6aACF2f92e0bFdc278B62c88F4cd66Cd07A6B731",
        "signature": "0x85c129a944220f6224f3bdc1e71ca16b66481e01d9814374211db73f0f63e950292311a19002c98c1ec03619a0c174b2e8ebee0eb5f5b5bc5a74d1bd808734f41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe4f7B36182Bd1Bc7a5965150B784d29EE20dC4Ef": {
        "max": 1,
        "address": "0xe4f7B36182Bd1Bc7a5965150B784d29EE20dC4Ef",
        "signature": "0x178f354e8eb3f10556faa23585431a54920fc7c42b45eb58767e8a56a67f6c202b028bb82be791b5d47d0ffa353ade5ecf6d2348fba0d39e37e08978ef50c5bf1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5344Ca35C10D6a07e0c5B64ac655E1ccd836A5A0": {
        "max": 1,
        "address": "0x5344Ca35C10D6a07e0c5B64ac655E1ccd836A5A0",
        "signature": "0x1ef9f4a25b80a87148ebaac71189fd8d378a34b297dd5da42abf40398ce256cf15c683d7be8314cbb0c998dbb1f84012681d593703aed1059404c14e51e9fd721b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1c3d29EdDF3DEf8a5d732Dd2709FFc5E1BDC7756": {
        "max": 1,
        "address": "0x1c3d29EdDF3DEf8a5d732Dd2709FFc5E1BDC7756",
        "signature": "0xdb348dacb5c0b09772173ed17e5f3015a099e63863163a79ff4780f51fba7b514ea187ca92c99502dcc0c5c2678da576bfebda4d2c3eade2964dc032bfa49e381c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x321653A394b57453b770B941e4B2A37d4728736a": {
        "max": 1,
        "address": "0x321653A394b57453b770B941e4B2A37d4728736a",
        "signature": "0x5e80fd2e45b79376404fd9f00b8b77ab99d8b6c2050fe931bd744cfefeec1bdc49b9ae2b2f406c4f432573cecedcb9c1d53db63ef7cb28a3cdf55335834848ce1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x529e429871b3170bAE904dc1aC687A3A07Bd4fb4": {
        "max": 1,
        "address": "0x529e429871b3170bAE904dc1aC687A3A07Bd4fb4",
        "signature": "0x40ade95056cd3c0cff924d8fb3bcb917264492d968b4d0ba4156c3dd0f1446707b845ca2d189f111cfc2714e198dfed462b1d74cb1087f86de71d944e3a0ddd31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFbCED99DC9f8e552074F61c0534806cf4f815408": {
        "max": 1,
        "address": "0xFbCED99DC9f8e552074F61c0534806cf4f815408",
        "signature": "0x138a143cd04aeeab6a96e38eea609ad210ceb7a3b75f3dd90342deaeae7cd49b2366bff164c1bcaafff19f257d797cf95a925c4fd166c01af96c020db030c1811b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE1aB47A55ea82025EBD6cEf127B3E46C359c235C": {
        "max": 1,
        "address": "0xE1aB47A55ea82025EBD6cEf127B3E46C359c235C",
        "signature": "0xc7640dadb1531683938decf2c0ff67a258d738e525148cb0f74adee785b8c99d6650dc5e2d0f370cbf36e3a20b801ba759bfe7e9e02a2d8b5481b17b3a5404511c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb4a6c41C79D8d208a74486cBE90Ee2bD24Ba23c5": {
        "max": 1,
        "address": "0xb4a6c41C79D8d208a74486cBE90Ee2bD24Ba23c5",
        "signature": "0x15f8081c79ad2ec61f034eebc057513338c77032ff4cde485e26d8f674743c7010fc996c2582d0e3498169dcb4893bb9a5a43ae5ea4cda7437be4b1c4cfd7c9a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0f706851817825f1d7C0Aff949F7f6a46747e33B": {
        "max": 1,
        "address": "0x0f706851817825f1d7C0Aff949F7f6a46747e33B",
        "signature": "0x6731ada5b71d0628f8c8a4bea3f6dec9195ce70d3ccab6b424e9b104596ad40524a261edf5de6cc67dd98c0429b62ac05926b0016a776c7d88882539a03cfc201b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBd7C4aaBD94cB5A5b7516587e30f40B9EE823004": {
        "max": 1,
        "address": "0xBd7C4aaBD94cB5A5b7516587e30f40B9EE823004",
        "signature": "0x23fe80350e743ff0f28e1d5dbf0a291bf5a577a32b22c3c3890065673795a72f06581560bb534ed5150c1f7df7ed7ba65fb38d4e87fef2bda5f04e0825bf944d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x746e49f235b07C1B2FDBC5b616b64D392F5589b7": {
        "max": 1,
        "address": "0x746e49f235b07C1B2FDBC5b616b64D392F5589b7",
        "signature": "0x27c6530deef69e301e6fca2a78ae251e4eea73798cf710eae10fa1607c9b242978c63fe3a6b19d3f695b9588f41e4459733e6672c0065f832363b115ded271c61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDab557046a07a3ed0C0991F5021e29D6b8393704": {
        "max": 1,
        "address": "0xDab557046a07a3ed0C0991F5021e29D6b8393704",
        "signature": "0x2326ec32137dd457d6df2b9ddd342ac6438e27f731b989d16bd7d878299076ce24815e8f954533283944765806881c41ae241627e513e30e70a5c1669ebd20681b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x224B173BAccde975A805B991B6b827D62d76ed3c": {
        "max": 1,
        "address": "0x224B173BAccde975A805B991B6b827D62d76ed3c",
        "signature": "0x5700e6071032e1a1062c2f72813020418a15a82a643bd496dbf6a26929a2a1e96de1a666d11ba38967b7a69f10942d8cebb7c9df52c1dd1d769df04c3a57ff2b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x940EC171090E4920f3ADb90D52c66Ff1dFbe7037": {
        "max": 1,
        "address": "0x940EC171090E4920f3ADb90D52c66Ff1dFbe7037",
        "signature": "0x26bd930bf1cd58ae8fc47c7f4485d80b07d5f6f2fd0cf8e097b5dfe480fe19fb4dff77ec1302d11f96163679d86303229b2e9119604bfb6ddaf0d2fe36f0c5881c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x390328D7f10c10A6914e6D677a23Db32f6238A6F": {
        "max": 1,
        "address": "0x390328D7f10c10A6914e6D677a23Db32f6238A6F",
        "signature": "0x63a2898b03a28259cfc50da4a53165fed4906ece7db881ba3f2a66fd534d1ebc6fcd870284e7f795005333df0c2313294461d5123bc661bff463cf0928d459b41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6d2c2702dcFD213f84C9C1a08ad85436aA7Fe4f2": {
        "max": 1,
        "address": "0x6d2c2702dcFD213f84C9C1a08ad85436aA7Fe4f2",
        "signature": "0x96c973050323bd5450f86bd4dd944c6f0e8d268845de73b7cc9aa7eb10986be80f13534cc1f595eb20a7ca8e8a878a502557c9f9e8483dc5449b854217277c2d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3a66b33e0A47131D21356c597706e685fcf2B1dc": {
        "max": 1,
        "address": "0x3a66b33e0A47131D21356c597706e685fcf2B1dc",
        "signature": "0x8580e6d8e6d5c4e77744084f36b4bd82ef28fff960f74e58687585c9c32b4920552491f7b9cf10906f7e5fd60e874f0a77f754545ac9d9b13f99cb6c00089c231b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD6D9D0f176Ac1c0aAa7a75528AE5A0Bd29135a21": {
        "max": 1,
        "address": "0xD6D9D0f176Ac1c0aAa7a75528AE5A0Bd29135a21",
        "signature": "0x16197e5a99da7046094980c3e6f5ec947423e12c294a334ecd1d5e63f816e28b30188e2429f6a3ed720edcd27a46cc240b5f17d6623352d6339046128c0915781c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF5b07564601318e5BE73e3F3c06e9201cF7DaD3D": {
        "max": 1,
        "address": "0xF5b07564601318e5BE73e3F3c06e9201cF7DaD3D",
        "signature": "0x4f766fd3e9b7ef17da0de758a725015001e75f59190d26bf4f16a79ebedf442c2d039160dbe64ea36f824e5469df313bd8851568717cf01052160e515c397ff41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x55c04eB91819DB26a8BF67a0296a07f91a843B95": {
        "max": 1,
        "address": "0x55c04eB91819DB26a8BF67a0296a07f91a843B95",
        "signature": "0x834706dc1924d1d02740f6079d98948e46a2ac925501ed222e76f0f25a14b903607947d3b20c5b2db5b355d65398482bad7de79cafc08129ec8dbc37bd3497d91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3631FEaC54C2023b0c1B32aFe8F5F734c51125Bc": {
        "max": 3,
        "address": "0x3631FEaC54C2023b0c1B32aFe8F5F734c51125Bc",
        "signature": "0x0227ef14a7cc12259acb401239bf78b59b25a6712dab9768fd399ac6c27a4be16326b1d939dd3c52bb2a402a8386774fd40539c42f5e0376d69e8d7d3f3fd2ca1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8Ab530D95dd4C688E018a97B627A6a08Bf2AC65c": {
        "max": 1,
        "address": "0x8Ab530D95dd4C688E018a97B627A6a08Bf2AC65c",
        "signature": "0x49f2cc567553a72d8e2febbc0642c5a1a25a779a63d610746b0305644ec7b3834962198499a8919b0c11267dc3ad4b0095d4ef02e0e5d66c352623e913de85ba1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcAAf06D11980DAc58b83512473c906227aCf4De2": {
        "max": 2,
        "address": "0xcAAf06D11980DAc58b83512473c906227aCf4De2",
        "signature": "0x3e5280a8972d0eccad13ba7a098bc579588f3fe7f281921fb48b5e36fcf4a5911356198951bf06475cfb281f63a27a8f223e1a7eb78ede4d6aca78178984464d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x40baD66A0faAC0Cdf022560dCEEb9cB545E4c82B": {
        "max": 1,
        "address": "0x40baD66A0faAC0Cdf022560dCEEb9cB545E4c82B",
        "signature": "0x533277c3544d697d5bee65c2d7983363ca83f9856fdb056943aa30f57495532d3a3d1db4861eeb97cd70ce8d247d721ae608d4643efeac3d4830c53bd2c012631c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5A0bfAF68f7Aab502Eaa65C254f4A4234316a52d": {
        "max": 1,
        "address": "0x5A0bfAF68f7Aab502Eaa65C254f4A4234316a52d",
        "signature": "0x50e32f3b03f819e910b8ffa58a0b37dfd13ae08380835335fc12def61e11290f217e5d250bfbba69a495e959e58c6a79da456953e0532c9f14e00ae48e186c331c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6CD1e0abEAab40D5217cA89E0a744efB2b11647A": {
        "max": 1,
        "address": "0x6CD1e0abEAab40D5217cA89E0a744efB2b11647A",
        "signature": "0xa94d4900de0c595bd7d53cc4f442c281f5f0585f9f53b18d11e7d425dd1dc8952d0ee7cc55b3ddaa5eb39e677447dfa9ad5dcbfe7eaa7c2206e43f4d2f33df731b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc349521F271497bf4d1F6C31C63D23849bC9D772": {
        "max": 1,
        "address": "0xc349521F271497bf4d1F6C31C63D23849bC9D772",
        "signature": "0xc63628aec8e3ea271e29e69a05198ba12ab381fff8a2b89e890b83767e4b4c4614efb34c192e02e14cc42c60494a5d51b220317e591b7cd9ab9531bd6e095b061b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb6f21A362598FBc5B8191e17Bd5358a3CE167eA6": {
        "max": 1,
        "address": "0xb6f21A362598FBc5B8191e17Bd5358a3CE167eA6",
        "signature": "0x4b6beb796580dc40f01e5fecb36c7459647b29a0c061444dae5b8c00a350ee997000518d1797e94f2c6e1b10006bb6f4d1a8b11295af3f2d2b05e645a21628531b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x26C409048B5A861BD83Df6B2cb9a818b1FEe7485": {
        "max": 1,
        "address": "0x26C409048B5A861BD83Df6B2cb9a818b1FEe7485",
        "signature": "0xc783d81d2f18b49f957ff237c5da4a1d642d9f2c6c329ae1b052808a8a3ab224026dd8bae1f4c55644f037d972e4fff1775b1361609eb213e2727958dccec9be1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x069094c4AF5f209E1708Ab35F408D90945019acF": {
        "max": 1,
        "address": "0x069094c4AF5f209E1708Ab35F408D90945019acF",
        "signature": "0x6f52ddd252ea8a1525b69808677683df0263e37c9dd4b1124472cfdf9f142bce6e81d6c32af8ecacb3e675ffffb54a1eaf437e5dd4e34b5dadcba2c3cdd5d5d81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x97E85c47AAb8910AC8B3b36D69AF4CBEfEe9d9f5": {
        "max": 1,
        "address": "0x97E85c47AAb8910AC8B3b36D69AF4CBEfEe9d9f5",
        "signature": "0xccca3c82a4ea6f299ad57b0abd027e694c74378897129e9caecca9ff3fe382563677165208a5d2c93ab71cc428786671a1b3b12ccb05f2e7da96022c97b81dbd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xebA2b8cCeD760cDA89E603B58c5f465EB6349afb": {
        "max": 1,
        "address": "0xebA2b8cCeD760cDA89E603B58c5f465EB6349afb",
        "signature": "0xfad3653201f3a23765cc3ed8611757b33ce02df7706e0471a16f19743ae4627d7326e7377bac84caf9367b554530cb1e4771a769f9266f969b375e9c44d526911b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB30A335117F452B606f35F71dfdf6129E3aA7Fa5": {
        "max": 1,
        "address": "0xB30A335117F452B606f35F71dfdf6129E3aA7Fa5",
        "signature": "0xb0f618371aafef31e48932878a37f4f274a4ce320852059a544541117612ef2f02f93f917bdd6e797a1b46bd3521e31cb855bbb3b7c07b2771816110f4bb215f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa3b7A3078770DdA28a9D88066677b3514ec16E17": {
        "max": 1,
        "address": "0xa3b7A3078770DdA28a9D88066677b3514ec16E17",
        "signature": "0x414616628e64d158b2ea7f2453df0a18b269910f38b2c8ee73148fc4d6c8c0fb165ae69fb3383937a673cfb36c8340e30fdcbf8ab62672a13cd6407e72539e7b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x639c93E5511462aCd49628b4EDC9BEd71b0c8361": {
        "max": 2,
        "address": "0x639c93E5511462aCd49628b4EDC9BEd71b0c8361",
        "signature": "0x0cec974facd31f143f4891ebd8fb2788fd9e37a299f0c3cc7511f1920551c0fd443731915875f65bd9672b2b36b77c71a704fb9310faad3134b7a16f5d6a8b081c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x46Fc6e7703E559e53bFB2f24670325762E10C514": {
        "max": 1,
        "address": "0x46Fc6e7703E559e53bFB2f24670325762E10C514",
        "signature": "0x2e04fd21160a766eb6ee394b026f0f0430d1f05b783f233fe254ac51af87ad3b52ca078aa4dc1cadbd8b732d8afccbcf443832b12e53d3fc7137c835603f9a451b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x523bafa33582BDf3a1c3604543C10068D045F348": {
        "max": 2,
        "address": "0x523bafa33582BDf3a1c3604543C10068D045F348",
        "signature": "0xd6a0f4c2f0f4de6a6ca7de04cd94cd88111e414dd9dfe32f0583cd71da04804160e9868d1ceeaa8b08b8e10f6c1959e0a3661976ff01808388db86faf11683e01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE7de2fF245bcf840d0d20C04c00fEc2779861f36": {
        "max": 1,
        "address": "0xE7de2fF245bcf840d0d20C04c00fEc2779861f36",
        "signature": "0xa91d3e694eb9b2b8dac0e3f615f3931294c12a4695fead4ef30ef70116061a28670b45812b02ac87c6d5153073bae1ecede721b08fe063db6f964b40714b3dc81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd69c056085aB9615006DE618A20FC5616B1cdCD7": {
        "max": 1,
        "address": "0xd69c056085aB9615006DE618A20FC5616B1cdCD7",
        "signature": "0x1810ba71d669d8a3dbc2937c34d2d1e67c6afc2b056c13d27299ee4fb68512743e5dd0e3f7b00f7c8c77bbccebf94944b9abb1cfe077bc0d48c28ec516e515f11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x24aca143320e7f34114AC4af99dB5D0198a2FbaC": {
        "max": 1,
        "address": "0x24aca143320e7f34114AC4af99dB5D0198a2FbaC",
        "signature": "0xaf3ee973682eedf581740055662f204849122dc2c781d015bd38b8ccc07d9112395a0d9583f6e0473c32deb471e6a253414f8eaffb26f15e1b33e87a8ff60d481c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9b7C896e2fC7cb1a1aEd914A3d5dc6356fCE96eC": {
        "max": 1,
        "address": "0x9b7C896e2fC7cb1a1aEd914A3d5dc6356fCE96eC",
        "signature": "0x5c4b6a2d91396191d0934602a858d2392b64f27ef204b09b6c6955762f8afafb62c79aa4489cd944ac3db01faba00a7a939b4e0c1ea266ec72a846ee563479641b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc68d994c192E1FcDcf281f9579C3337d9B618775": {
        "max": 1,
        "address": "0xc68d994c192E1FcDcf281f9579C3337d9B618775",
        "signature": "0xfe13cf824acc7007f755eef60245878ae7e22501f438b02ea4bfcbf4700eab823d38916c8e60c52d1506207421ca224c256496316050a99007f62c7534cdcb7b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x065831f1858Db071805026a46263EF2f5fB19954": {
        "max": 1,
        "address": "0x065831f1858Db071805026a46263EF2f5fB19954",
        "signature": "0x2d656662944d0ad70d5220957c0e32410d2bd0bb997048b510820a9b8c01b1dd1eafde2472214013ae6b6fe1d599cb025d58d13458f8049697c17463a4c25c421c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaB405C3b041837a025E2C3B49a9d243cA8fc773d": {
        "max": 1,
        "address": "0xaB405C3b041837a025E2C3B49a9d243cA8fc773d",
        "signature": "0x5f049afaf5309ff0688cf39cb82f8447a13f0869ab8e763edea1c8f64aa582c807ec6cffea34c1af1f2cc98789369c3bc302d3593ce52bb45f6fba2d9ccda6da1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3bE1A4Ebcb73050a843354cfc1Acf36106F5E143": {
        "max": 1,
        "address": "0x3bE1A4Ebcb73050a843354cfc1Acf36106F5E143",
        "signature": "0xba44175d4c48108456cb995b53bc34fbb9c1807822fa5120a62252383eccd511225c582b4b32ae86336734fbbc2f842aa2e06ef60f67cc6ef6e3344047d83dde1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9090321110772d92e7A00e3F83e3E25438f44843": {
        "max": 1,
        "address": "0x9090321110772d92e7A00e3F83e3E25438f44843",
        "signature": "0x24ff6b650b6b64a1f79d442cdcab78a6f30e29b6f1bc214a7de0eefe61f2f94d0f8abad08032f39e2250c3a47bff47c6cc2b5a2e50d935c6ab418932d8863ab71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x435F14C7EF3f0ABaf95114952BAA3150b98dbFCe": {
        "max": 1,
        "address": "0x435F14C7EF3f0ABaf95114952BAA3150b98dbFCe",
        "signature": "0x7c439a91030d8f62834c646bee6b36966acf066571a36e1fb1682402328a1e5007395538455b1946cc835166e8d166fa2576bb03098689f404dbd245b62e86f01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x615292C51b0c3815F2f3594217304Aa87Becc0BF": {
        "max": 1,
        "address": "0x615292C51b0c3815F2f3594217304Aa87Becc0BF",
        "signature": "0x2a8153618c39a4aa54210d5d7c8a6f12fe4a59978956b37cad9e196d96625efc2a8ed989164c429b0e390ff9ab93b4438041095d43d0487d8f3d3afc8dfcaf841c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7390A504d1511Ac1D4c6ac004E60B214ACb4B546": {
        "max": 1,
        "address": "0x7390A504d1511Ac1D4c6ac004E60B214ACb4B546",
        "signature": "0x9f339043a64083b4f06efb67c270557c041216944996b6d309306241c5093e3a71caa3defa8bb6a557fc6a5827a8b9c54b6f2eb79796325f791becdc3ace54ae1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x30ab954EF40b218Db263e382F5672477b88f67d6": {
        "max": 1,
        "address": "0x30ab954EF40b218Db263e382F5672477b88f67d6",
        "signature": "0x3110ca4049623fb7aaf5b68de8d03efe09a0ac6ea9157a5d95b4d3597e42c5aa01b67cb721a64bba859a970e1ab605044d793d64219a153ade04895442f02eff1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x83a354A73aF670aE50Ef847Afd41E5454eEc7733": {
        "max": 1,
        "address": "0x83a354A73aF670aE50Ef847Afd41E5454eEc7733",
        "signature": "0x78e8ff434308c86306909d257a397c95813657ec638ba4eb60ab3c061058ac6642baaa833d0b580372ec71e6496df791c593dbe3bc51cd96f108638ba428f8911c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd422e83EFAaC0211B3837b95E256a986ac035808": {
        "max": 1,
        "address": "0xd422e83EFAaC0211B3837b95E256a986ac035808",
        "signature": "0xfd835af01263535208b9ec16a8c2f44f37b7429e7f198de89ae74d96404e381672b14b9f0e81b75af634e03c7d592a3118835670c9c26b9b33d84af19d17f1381c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1296C3643BE8A9d31329a408bd8336B1d4efE751": {
        "max": 1,
        "address": "0x1296C3643BE8A9d31329a408bd8336B1d4efE751",
        "signature": "0x422df5c05f5bfcfd0a5870af9b2750e125272c7aaf7f2bff2584618e199ad1030e80e877d46da7011a878f639aba347073d1043720d4edbe0b194925198864d51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9D84F156f7B118949f825F984EbbA39bD0da1920": {
        "max": 1,
        "address": "0x9D84F156f7B118949f825F984EbbA39bD0da1920",
        "signature": "0x877fd7ce16ea2dba1c7a3db20d0ccad2dd2ac2a0a061a177fb2a9211ff1737df79c44a943be0823a3affe4a26a5be9cbae2a634fa0677744b6ce402d12f6ce261c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdBF1D5Ca13094e200B99804000708C4Fb92690f0": {
        "max": 1,
        "address": "0xdBF1D5Ca13094e200B99804000708C4Fb92690f0",
        "signature": "0xf70d6f9af3b9e42b33057d2341c88606ede9c267f9ba78df960b7c0e58bd966e71f88002b1c2f7d8693faa57e73b86a4bb4446ee39faab7b25ed679763ba6a961b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8036C65b74961Af949cBeaDd967a0637B35F3Bc0": {
        "max": 1,
        "address": "0x8036C65b74961Af949cBeaDd967a0637B35F3Bc0",
        "signature": "0x1c75a469b2c656fde3b97767c7c5db62d3ea2a3b5dfa3d83d172114ec83ad4336933b3fd2a00ccff009abefaad43f460096c4589580c48ede8e4dd23148389e01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7080926A161dC783c5C6f68Cd3f01353f8e5598C": {
        "max": 2,
        "address": "0x7080926A161dC783c5C6f68Cd3f01353f8e5598C",
        "signature": "0xd4ddb8fa46dfabe4950309dd1b52574cc0dcd812e6ac6bb129bf57d00f1f6e45236b71b9e5c7da0fb3cbd441a6113f966aad11fa7ba1c6ef978915627db8d5a41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x77A8703Db1AeeE04405F8a37FDe59F669ADFa429": {
        "max": 1,
        "address": "0x77A8703Db1AeeE04405F8a37FDe59F669ADFa429",
        "signature": "0x490309d53e33a31ea4b75ef516be1211fc5b1ed4022e4096ebf5f9ad2fbdaf155b4426f1b746c216065385b2490b8de4d461a8dbdc4eb4e4d5558d37cd16663d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD1D36ef67cefa9DF8407c40B88C9eC6B966253bC": {
        "max": 1,
        "address": "0xD1D36ef67cefa9DF8407c40B88C9eC6B966253bC",
        "signature": "0x8081d7bb90bd2900a5e2e1ab2ae4def872580d2bd1e01d8efb9162143d958adc6fb954ae1bcfd494d7fc2d635f828f27a20ad5061dd1a9103d07b1f38c1cedba1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb622991B7f314bF1ef118953ade81aC08d860238": {
        "max": 1,
        "address": "0xb622991B7f314bF1ef118953ade81aC08d860238",
        "signature": "0x79e048e0d68beea5487802c79abd00c5bfe46ecc9fae03b6c9f212bab25067644ab27b4a9b3376c58b2cf8e8c1406b6c10dd62dfd16709be4b1c0f229ccf441a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1EDb49B6E6bD3EbAf26b9C1be39Dcf97C209A1bF": {
        "max": 1,
        "address": "0x1EDb49B6E6bD3EbAf26b9C1be39Dcf97C209A1bF",
        "signature": "0xc83eeaec4a861e992247d4f4a0070617c9f2617c1a5fbab4bc43281011cadb457a9a63077dc271c80145c17a96dcc12d42f80cf4e34f26da0d6f9e417c44c8b51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x81BfB3d8Edc271b338ABBb12EA44b04432302da5": {
        "max": 1,
        "address": "0x81BfB3d8Edc271b338ABBb12EA44b04432302da5",
        "signature": "0x2d2584cfc388a2599b4568acf25b434343f3973ebafc79260b25e7f3aa3a09851578234b5b278a8c0dcc00fb6fbbc140580b5b3cfa2933ca7b7b0d7181003d531b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD2d050Ef9596F841798A6094FA3a5D1F8d105242": {
        "max": 1,
        "address": "0xD2d050Ef9596F841798A6094FA3a5D1F8d105242",
        "signature": "0x881a1278f3b59ba3f066ea6b4c7c6d451ce1c4501cac30be63ebf26baa5e32bc44d52275404895f0c84406e5262ae149b5956b27f1e93dfba44fb17d139ceeae1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5fD09f558b48ee6E9096e8114477537F5783147f": {
        "max": 1,
        "address": "0x5fD09f558b48ee6E9096e8114477537F5783147f",
        "signature": "0xde88e874ad156acb64f1eca80a123532bca4ab13385c389979e34a7d5d4423e8305a64f9b8cd8953c00db79ee164201b83e05d970d3694ecde2ee2c0348b69901b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCE424249d793198A8918Fb5332a9b2912b7Bf9Eb": {
        "max": 2,
        "address": "0xCE424249d793198A8918Fb5332a9b2912b7Bf9Eb",
        "signature": "0xa5816d2d5bcf2c1e92290192cb40a48396beebf906cc02b81653461d2952778d5ea1c549c58e3bb6c70d30ef71945a4ab96e86af13feb7c9da51edfb90736e081c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC03B90A79666939710e3cb0DfC8B19A5a4753ffD": {
        "max": 1,
        "address": "0xC03B90A79666939710e3cb0DfC8B19A5a4753ffD",
        "signature": "0xbb0db24fc6c88b8ed8eece906d4984888d54099458038554241b6c64d31ab45354bfa65e374cede189de20045954f3c6d2775ac7c62604b9e68ef142ff982f461b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF55B413701c00B2c7A3D7E11c1B3D3BC84469E30": {
        "max": 2,
        "address": "0xF55B413701c00B2c7A3D7E11c1B3D3BC84469E30",
        "signature": "0x2b3e8ee045d659dfbba9cc5d96c8438d955faf80d90aafe2c1904a26cfe919964ff32722e1c96ac19fbb9a890d1da667d2dba8cfbab7b7c109e8705ad1e670a91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEdbedDe79d41AfEA769b084bFdB2552d71D44f4d": {
        "max": 1,
        "address": "0xEdbedDe79d41AfEA769b084bFdB2552d71D44f4d",
        "signature": "0xa5317650e3496bf5dd47a6720e575c233c61730991dd7c6f879a6d34866cd9ad3a40b588f8516b4eae87efadd946ccbeaf2f1e52b6abbaf6feead9f4b0c2505d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x478A73CCbda4b36Fb663EB10901e410c7f0C04A6": {
        "max": 1,
        "address": "0x478A73CCbda4b36Fb663EB10901e410c7f0C04A6",
        "signature": "0x7cbcb3998e7472d68e3c2538371972bfcf543a695f717dfc83c4be4827e4ab17485e774eb7c470301263d48817fb483f80f1a09b65759fd643bef7a9ef04dbd81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA3cfF541Eaa7e0156444EBdbeE47e2e5f85FD7D1": {
        "max": 1,
        "address": "0xA3cfF541Eaa7e0156444EBdbeE47e2e5f85FD7D1",
        "signature": "0x855209a77eb3e24114c0b3bc09e9654d6141be6b3750bec60bb8b890c0f705105d3f70bde5a41eae449d43f84530ebc1062d286848bbe54bcd313048e12d90ab1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4C84AA6a96A6B5b822C73b38765BD2D89B334944": {
        "max": 1,
        "address": "0x4C84AA6a96A6B5b822C73b38765BD2D89B334944",
        "signature": "0x75c1240afbb55b4912550d780ab3d4714ded50f6f07ca5a4f0f53616a966df4843f4222e0caba1bb1eaba26ea824619792fda5df9eb549f0e1611b4ea97feb2a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x01967dbcB4774f4e5D4e19FB9a8FDAA3189345F6": {
        "max": 1,
        "address": "0x01967dbcB4774f4e5D4e19FB9a8FDAA3189345F6",
        "signature": "0x2f309038a592619a73255093bbb9d8934142d6f8b373fe8403edde18ffe87dd91444424c8fe9d42550ef69057f3a0f97addae4b4e54f7275e36b15c5731369241c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0172CF77d553899Ea7719e2aFC8d56404d4b3a95": {
        "max": 2,
        "address": "0x0172CF77d553899Ea7719e2aFC8d56404d4b3a95",
        "signature": "0x5836be92a8b13c1265e1305789088d349b7acd8d448cbb0a29854c35c7a6f8fd51efe465f9365b42db82d2996cb13343bee6ddec236ce3d88aaabebe1b145ab71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x26d6FD8BCa1BEa1E936f9acf0aBb97c6354585Cb": {
        "max": 1,
        "address": "0x26d6FD8BCa1BEa1E936f9acf0aBb97c6354585Cb",
        "signature": "0xeef03f44a493579443a9d25beded3b631ae1d376d2637e52f73a3620bb4d8fc544836550c7586b19d411d25db48a9210313ced9d46206bfe0fa2a138d18bdad81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x12d82bFBaE8d84061a12f5BF7FC6D50bd21D5074": {
        "max": 1,
        "address": "0x12d82bFBaE8d84061a12f5BF7FC6D50bd21D5074",
        "signature": "0xe1cd7181e56f65cc4be71c546fcd0d69b79037e809dea44813dfee7d08d2560259c2fd31cf57fb1b24847841e0f6f261aa20125ada5b4b8116b740ec35f9f6471c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03f47664a24cDC34f40bD8EB40b45d96a6144cd9": {
        "max": 1,
        "address": "0x03f47664a24cDC34f40bD8EB40b45d96a6144cd9",
        "signature": "0xd8ad347a099b96a3206ccc24299ff0e0afb7ec19bef7308e80753e11d802833223ad335b155d98f6e33d3193d1342a777dadd4bb5fce1b5fc22f94f66c7e351f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x769bEB090358c5B30623Eeea82D7B02c73D7e970": {
        "max": 1,
        "address": "0x769bEB090358c5B30623Eeea82D7B02c73D7e970",
        "signature": "0x8331a5a2aaf3ead6428ef9337ff612ea995deb0f034be6fee1760b75fcc37df73c429a6ef00a409395a467d6e95681887d3cad3424ae28b72532a1b65f971f731b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9977b85b99248330913B4Cee25e5E3BA302B6Ff6": {
        "max": 2,
        "address": "0x9977b85b99248330913B4Cee25e5E3BA302B6Ff6",
        "signature": "0xb6e13d684ec1dc9143f49477d645308b9b6fdd385e662b4b02294d0a714d46a7349d57388d2c0d1e9803b50793cb6db93dd019fe1c70546b9db7e3250e3ad5f21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1E7cF5abBb02C6cab4D2c59bE8d5718579AD164D": {
        "max": 1,
        "address": "0x1E7cF5abBb02C6cab4D2c59bE8d5718579AD164D",
        "signature": "0xc37bc1fa32b1075b18b4c67e940695bcefce17c2152332cb2c00a507f07ff7524bbb26005b2b0fc346e593cd14b8f8bb06dca7e01c612522942809e00e3a67f31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA2D664d41d2935064B6820366D8aAFa482eadD3a": {
        "max": 1,
        "address": "0xA2D664d41d2935064B6820366D8aAFa482eadD3a",
        "signature": "0xad6b06d1cdff9aa7af1e5486a425e7d15ff7c3a7ed7739918c4144068f35c0e66908b6b26cb41365691e64e239dabcdf7bde7f79fd662df9944acf2511f98adc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4de8ca29F770D8e98228A59cda60B9b5aCE2a2F7": {
        "max": 1,
        "address": "0x4de8ca29F770D8e98228A59cda60B9b5aCE2a2F7",
        "signature": "0xd4a89ae4ed2b588724b86c893bb4a694333223046975d24d1f994c2897aa7ff450a2da2a2c6f6c46bf18cf4e56932bb79faa0f8fd9b0cbb7049b37195a595eb31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc8e143122F2aa1470850a88bECF0D5c9783aa8f5": {
        "max": 1,
        "address": "0xc8e143122F2aa1470850a88bECF0D5c9783aa8f5",
        "signature": "0x5b80710870177ac5b6f651e743779411abfa26a907e2aa4ec7f4ec28c0bc978d072911bba18668cc81eefdffeff69048ba6eb9e5ad4d368f435fbe8521681da81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x13dBf1279Ef7A9406d092533151187F12116e5B7": {
        "max": 1,
        "address": "0x13dBf1279Ef7A9406d092533151187F12116e5B7",
        "signature": "0xdece7cec88e65f3e6c5021aa93d6e51c2263f0900c30bb4daaff4b289ffd1a7b74373cc768b5b015bfdd33e2de38938da6c1faffe7a155118eb5e289a1dd34ba1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x83BcB549827202251017d861CDF25Ab09136F752": {
        "max": 1,
        "address": "0x83BcB549827202251017d861CDF25Ab09136F752",
        "signature": "0xd2e6ef20b47cde10b2ab58cda981e861afaf66cd289ba456d4d9fb3aec6c640e412f86830cfd6d2548cf57ee9f2bceaaf5233d8c3a96df0e7d0a78ddbb1e6b2a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc30f7c3D69242703aa0aa02013E14FA36491FD9e": {
        "max": 1,
        "address": "0xc30f7c3D69242703aa0aa02013E14FA36491FD9e",
        "signature": "0x339f84fab87fb093bbedbeddda32483215fbb5ec3bb729dd645cd4cf6bc6a31d1cbc6588f7fb5b3637b6e0d94624f819a2246e637c54fafc1528a7076bafaddb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0BAfEa2f8BC2eE75912321884B9BBB5c5F698e4f": {
        "max": 1,
        "address": "0x0BAfEa2f8BC2eE75912321884B9BBB5c5F698e4f",
        "signature": "0x9ce948ca97429af97ce358f7ea30cc6706b1ed2195081cf747396d22037692504d18c4a93cc21bfd5b9e89a29ddc69e0cba06d890fa1fb5fabcc6dbefb4e74181c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF686D2e752777651da9cF9523E9888CD35281117": {
        "max": 1,
        "address": "0xF686D2e752777651da9cF9523E9888CD35281117",
        "signature": "0x093078257a556d68f2fd8b9109f53163049ea59a5d91049c0bea13f09c527d5d3839bf13210354c1532d0b142c2ba2fa862f2eced8bd19f8b7766d55dc9d77411b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0394871b10eBCD31952F092b450Eb335eBbd329B": {
        "max": 1,
        "address": "0x0394871b10eBCD31952F092b450Eb335eBbd329B",
        "signature": "0xe1ceaa9b5c2801b1e938336a0a42cd143970eb8c05750d7a7b66b143c9e6576b7a7eb49e89f9e670136bef7bbf4f1a109cc47984557db52595fbf8b0854cdecb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAdB0689B948cFb3aa8E4e488B835878D69a6bE65": {
        "max": 1,
        "address": "0xAdB0689B948cFb3aa8E4e488B835878D69a6bE65",
        "signature": "0xca2c3194fe4f0994775d6ab0d4f06fe6d81b6f6a5483db6699444e990c89a0987c1e9516a49a1002c1b59343d398060abb923055cb9be8f842d9a03c947d896e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8Ca60F49654C99593B7d66B734e137e0a8d09C02": {
        "max": 2,
        "address": "0x8Ca60F49654C99593B7d66B734e137e0a8d09C02",
        "signature": "0xd95179ea7db1d700028ec813a54f2fb44689a02d7eecb60e3a35d7d254d8bb507fd45fedbc38c5479e37c79b4db70c4969468aadaed85ed5fd3d3705c113608d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3322EAF050e05C220896F221bB6f4ECcea3b579b": {
        "max": 2,
        "address": "0x3322EAF050e05C220896F221bB6f4ECcea3b579b",
        "signature": "0xd1c4c721f1fb70d05bfeee7e747228a72ed2258a877aa1072c9c3a8d9108c9a54f8d2fbee3b3f4e1d7d84f2fd1a6b856196d1dd14d88e5afe7ceb828b45674bf1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDE6A5947C0E2a4Dd2EB21256C876B67Ec6Cf6C63": {
        "max": 1,
        "address": "0xDE6A5947C0E2a4Dd2EB21256C876B67Ec6Cf6C63",
        "signature": "0x5ea999e21d085d7c67c7d949f5e1c2975e7c37708739765f27db64a47b2fc8ad71d6c3177e1c1020aa9fe234d9eb73546f8f7206d9d2e23454b7c07329c60be51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5F741463B148de48f7D696037c7e26D85714021F": {
        "max": 1,
        "address": "0x5F741463B148de48f7D696037c7e26D85714021F",
        "signature": "0x5ec4e0f06b51b8087c9cc83fcb7258b111bbe60e302bc35a9380952631fc21434a8ce977c45dcbc71fefbfe376a11c434b55af5b54f49a8b6feb1c51c0b3eabc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x66c8D49720E90cf020EA1Cca0274113384a33FDF": {
        "max": 1,
        "address": "0x66c8D49720E90cf020EA1Cca0274113384a33FDF",
        "signature": "0xaa195d7faa0d9368ebac5cf9b82bf0e6e96325cc4b4ba377f879030a9c044e486a2a5bf094b60eb69a56b0340dcb85df3f5a1b180f5e83fb999b18d35277091e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd2f4667011A746c4e028831b51ebA9e626780003": {
        "max": 1,
        "address": "0xd2f4667011A746c4e028831b51ebA9e626780003",
        "signature": "0x7557191e78296fb5d171af6d7cd26c2741495cd8c56276613cab97e2393fd23760c097281dab87c7b1535c17ed22694a4035ee11d09c8716f2897b5b21cd90391c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x807A4F813DdafcFCC7428F6df8a8593B3431EAc2": {
        "max": 1,
        "address": "0x807A4F813DdafcFCC7428F6df8a8593B3431EAc2",
        "signature": "0x767c64182e0b0e549d6752db57b0a408456c851e414578544bba195afe0dee033d5b26ebe7092225eb4e984f092667bdb7d2db5f1a7f73b23367617e05ab67b01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb2b9300475aF157676C44eE64d39a5eB3C294DbD": {
        "max": 1,
        "address": "0xb2b9300475aF157676C44eE64d39a5eB3C294DbD",
        "signature": "0xd220166048fc558da178dbc7a279a3ccaef60eb855c1084345730387c0c383e21f068bb920f9e9f898c5b9334ef2ce649c1f363930ccd64a8dc9b0cfd8b110241b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5c410b0f348c38e9CC74FA920f2480576579BA38": {
        "max": 1,
        "address": "0x5c410b0f348c38e9CC74FA920f2480576579BA38",
        "signature": "0xf84d22e7d19cf96e9445276c27879bed7bc333f62abc0ab06d7b8ba0656a830d3a5f4974b13f4e8e00b509ea994a3a51b36c93f76bcf347bc0411a2553c8bea21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x96acC5E193b96Ba7F2798a79D71cB25eBe71adae": {
        "max": 1,
        "address": "0x96acC5E193b96Ba7F2798a79D71cB25eBe71adae",
        "signature": "0x343636a96e898f28f1d09096158f04f5e5bb4c2f3e580c9e087603665897a00840120e32d999452cf42ddf2a4dae003b67ff9a67d75e3089b82a019ac16b36951b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4d840bD04Af3Ddd0351Fdcf224eDD7C99DFD2686": {
        "max": 1,
        "address": "0x4d840bD04Af3Ddd0351Fdcf224eDD7C99DFD2686",
        "signature": "0x43c47eb38116bcf05e5f5820424db6e740eaa74ffcf0c930356a2f7577660043352e643e07b5cd733406686d084d92429add42c987ae4b9d09444163d145fa1b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7C2bA9011a43C3982D9f0835243CeF142E0210A6": {
        "max": 1,
        "address": "0x7C2bA9011a43C3982D9f0835243CeF142E0210A6",
        "signature": "0xd4b4e155cc0bc388a5f9bd806dc9354b47cc9ba6f65e0ab794b99d83bab3658c5c0ae7cc70f33fac9d7c90061ac68d7e73e5fd898c6e1a40e07b88f1c826e5991c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7bf9854939B629b4C80CA256dA19d26529498110": {
        "max": 1,
        "address": "0x7bf9854939B629b4C80CA256dA19d26529498110",
        "signature": "0x1ff580618f0fc11d0bdf41c9728c6b7ffd6dc6365d21e50d73964cfb72b07c8f526afe9f7346e0f4dee1ab61977f83f9e8a19f03178681a0e7226ec66e473ca41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd482228f5A6ac376eB61da65111894D70c586C26": {
        "max": 1,
        "address": "0xd482228f5A6ac376eB61da65111894D70c586C26",
        "signature": "0xb0045c6b54c6f66f2d8d18d45085739c3bba1320c1a417ed7f0e966d958b8e196b66a77fa635770497569aa61186f37250d153f0aad2ecaea93aff2040be3f751c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5F479B75376FE2f4259125147d9F348F86d394c6": {
        "max": 1,
        "address": "0x5F479B75376FE2f4259125147d9F348F86d394c6",
        "signature": "0x3c8d4abc0f4e5b1e3c6b646922573cd2958ddd6de03013b49fa5603b1f7c06114961d5d23edea9a4a5012573c26e8d28538e7a778b9837711f27892c001d288b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xff4f16E4fc26495F6E00516fF93C5d86DE5e0b95": {
        "max": 1,
        "address": "0xff4f16E4fc26495F6E00516fF93C5d86DE5e0b95",
        "signature": "0xfd35fb29098de9cd895f85b30bd68609a1b0e605173a74baeef4ae11bdfd26653a18421289dabf6633e4e22c2db7c08934280dc93843fa4dd43c036d784bf8251b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb25b7a78e894d58b7DAEEE0a1f1d8D1B4a10Df61": {
        "max": 1,
        "address": "0xb25b7a78e894d58b7DAEEE0a1f1d8D1B4a10Df61",
        "signature": "0x8c0fd93c4372eabb942ca9c59cf6bf6eac5516d8eb20204480977626b2be7e2174961bea1c6a8bd03a411cc67f38461cd3c18dde53bc1005e1a5352a02cfb7591b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x57002b96F3dd3fFB8dDa18B5791eC2E760d50895": {
        "max": 1,
        "address": "0x57002b96F3dd3fFB8dDa18B5791eC2E760d50895",
        "signature": "0xeac0bda49b5997dfe08cb01adfbeb85a360b280fbf544af3124c9e2556c710843aa7c4c6cd6ccd44e3fc06e053d83ab7993ac5a059aeb8fd86489b181bdbfa851c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe2bE962DBFB2DACBdFFD3294C151D2A3d56E64Af": {
        "max": 2,
        "address": "0xe2bE962DBFB2DACBdFFD3294C151D2A3d56E64Af",
        "signature": "0x835eb88adb6e200b6e6d35f5014186b45eb595a7a3ee3503cbf94b0b082a72b9127641119d3b6bea312b7c167b5b8d42789ce5072ed87219b1260738f38e30b41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6AfD5b556D2FD91348a2357B3e886864a328106B": {
        "max": 1,
        "address": "0x6AfD5b556D2FD91348a2357B3e886864a328106B",
        "signature": "0x5d38b5fca6c64b27e38886bc40343eba8470b6e3b9125538a119741b76647dd217c01571675d424b959c95254e2a73f8b6a0fc42a4257a283a375362ebfd1ac61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBf6e0e25c86F5F8cD863b81A3B4C72bc404119eB": {
        "max": 1,
        "address": "0xBf6e0e25c86F5F8cD863b81A3B4C72bc404119eB",
        "signature": "0xfcdc8924faf19d8ad3482c7a0ab3b3616d7e774f709b1530ae56adb365f788e65df64c13330ec4c0d3a92400e1585f6f110fb6ce5fac08b2b8be127b3f7be89f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5bA7c545a5fc7A2dF0302a0132BB62Cd9cd7AD79": {
        "max": 1,
        "address": "0x5bA7c545a5fc7A2dF0302a0132BB62Cd9cd7AD79",
        "signature": "0xff7b66a5aad716ace14d413cf6ccf3ddf9f09a65cc4a5838f3ae5bcb80810d48065c98f1eecadab4fd064c7864b72da085431b0ed9822203d27a88f40e913ac11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDF83D9D517507cE1303FC8742eF005d897a7fCFb": {
        "max": 1,
        "address": "0xDF83D9D517507cE1303FC8742eF005d897a7fCFb",
        "signature": "0xa63534e3f64ff0d6a04abe7625e33ac14f3c28b429b29b8a68fc057233f61f071c913314d5bc4af9a3227b0bd8e7645466add5f709b42595b7f5e94dfa4853481b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE885A5962a993026eFA6D8d0b4564027bFD969cf": {
        "max": 2,
        "address": "0xE885A5962a993026eFA6D8d0b4564027bFD969cf",
        "signature": "0x7ac4db142a9025b560003314877a64bffb93986c08d3a67321932f523829c5ef0088f3839a4f3141a3e5ca933be667742b82156a5a3b9a2de4705250d2d197f11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x26F6Fef5B1EE7C6C7b4393db7f8843E7EaD09948": {
        "max": 1,
        "address": "0x26F6Fef5B1EE7C6C7b4393db7f8843E7EaD09948",
        "signature": "0x4e29a302725e6cf4ec9afa93ddc3577c0794b64d93141d3320e8cf14be92de7134a0ce4f882f7231431d8746caeeb2d0b1017b0db3b6685abb3e2010d1f39fc21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0f4345573e1f0e49D0D13905232e0d9CD3DCFe40": {
        "max": 1,
        "address": "0x0f4345573e1f0e49D0D13905232e0d9CD3DCFe40",
        "signature": "0x0c7b08eea2275c30e8142297738eadcca48be132a85eef0fc4f769fa936cbae740a1cb99e97ca17014cd0278c0c18b886d375971bbe7eb513fdd95113ecf817a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2cE257D1A80f180E1F1A09721886caEB3FB844eF": {
        "max": 1,
        "address": "0x2cE257D1A80f180E1F1A09721886caEB3FB844eF",
        "signature": "0x9bca51dace59b129549e7001120a4573fc3fd8e0118abca784316928a709865f112934e6d7bf2cf9ce80c1dedc802d7e6184d96f9b7f3e13e92c02955f5793211c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x364E3B9FE31334BEb5cd356ec9B346c159C7Db39": {
        "max": 1,
        "address": "0x364E3B9FE31334BEb5cd356ec9B346c159C7Db39",
        "signature": "0xfededa8f2bcb6a158bb68a9f1eb2a79100dafa13e53231213a744ebf5952f4f9287bf9b5d79d8fc385a183e6e70d2e9a56b1d810bf1ca542818698ddb901ca6c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5C46C23650e77B141B2fBA2C3aFa3BE1053bF567": {
        "max": 1,
        "address": "0x5C46C23650e77B141B2fBA2C3aFa3BE1053bF567",
        "signature": "0x307d18c3a3e25d6313f18b05dfa8b74ee15ced2a3a51f8ce35ed67145875b1a3485ac96154f995d3809dfcef6d6bc456abda22112056379bb7591619ad6cbbe21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x09a043874694C0576C66b2a37522107E2e129B52": {
        "max": 1,
        "address": "0x09a043874694C0576C66b2a37522107E2e129B52",
        "signature": "0x6b28eb2685c95f04f680cd26459ef7c0342515930561e76ca181f839e89f4b5155105fd5aad609d1af52dbd5170b99ab4cc503fb05573ecaf6118458fa2d87f11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x85921e057223F5b53DB397F4E06c9c73101DFe1A": {
        "max": 1,
        "address": "0x85921e057223F5b53DB397F4E06c9c73101DFe1A",
        "signature": "0xdae1da5d5e8b08d6268e1b4a4f17501e064ca34bb88e2d67b9fc199be851f96c47141b3104051950d9f4db6e665a0d79716f60dbf3f0d03daf0609fcdc194eb91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD02f91a91576205794039d3eDc39321470fb8490": {
        "max": 1,
        "address": "0xD02f91a91576205794039d3eDc39321470fb8490",
        "signature": "0xfecd2eaccaa92592ecbce14c3b9697a18def912686ff0ad9ec81c4e707cb49a54d2ba4286fc4f4356abd4ed4c95b5b691981a62917d1e3bc1514e6606886498b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x345Aa080b2ca5ed336D7FEA600153CB1C57Aa387": {
        "max": 1,
        "address": "0x345Aa080b2ca5ed336D7FEA600153CB1C57Aa387",
        "signature": "0xaa58bc75b27f57e6e16d00efa8ff70e6ecb14918ae75a6ef0ec4cd203264046f0159e8b9a8cf954c76192eb5200843f30be9b763c5bf462fb4e8b09f0a13a5fd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x98370d47BE642bF06e0D639ab014B80d17637738": {
        "max": 1,
        "address": "0x98370d47BE642bF06e0D639ab014B80d17637738",
        "signature": "0x41e47de77fa73e5f7175c462891678735ee986cb0d5aa4bcf9c4b9f7d165be18338f195613dbc0afd582cc0a016e270eaab677f046b390ff2c9e16bd38c89c501b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAf5B45F209Bf80164A830B5436222532043BD7e9": {
        "max": 1,
        "address": "0xAf5B45F209Bf80164A830B5436222532043BD7e9",
        "signature": "0xe2844b9bee2c8186555b1b89f4c7bd1a8df08a5b742520828f113d0f03b7e8e114b99c0132c7c6ae251737627876de2b4bc67835a473698d0261df837fb5a8741b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8212Ea9e7169b0D70135598Df20b7016DD457512": {
        "max": 1,
        "address": "0x8212Ea9e7169b0D70135598Df20b7016DD457512",
        "signature": "0x60b685f69a31fb30b99279c2c3349dd11cc7a86ec9c4bc10f08638dbc23804d21078da756dc514a6e81da7c6e88eb0f0b0bf7d5053c64901d404d8833ce743a21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1ad3B9F3b89Cc2d16B28D0429f97C409efa5BF06": {
        "max": 1,
        "address": "0x1ad3B9F3b89Cc2d16B28D0429f97C409efa5BF06",
        "signature": "0x196edb0945e0fd8e6d222e6e64317a31141cc8e12a2d5c5eeffeb5fc38be25d86cf8855cc05500b313f6f0f42d7ecb2b1505010eff0653947c8aa08d2995df0d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x85DB9605B0327471e7Efe014e0CdF75A4c004Ef2": {
        "max": 1,
        "address": "0x85DB9605B0327471e7Efe014e0CdF75A4c004Ef2",
        "signature": "0x1de9fbb4f0e9dde220ba6e518921ac6e436968ef5bd52d3a7c6482944cda23ca1ff28d9afeeb78cd9a256f45cc9cef86d9856020a84679c605d9fc88f0a4351c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x77EA17Fb1B4FbE6Ad2ce105b964E81976A124D2f": {
        "max": 1,
        "address": "0x77EA17Fb1B4FbE6Ad2ce105b964E81976A124D2f",
        "signature": "0x087fd77ff88724f727229083321b607e8b7141be68eeee3eb50dacd6a41eea6432048de32da7f0857c5aacd91f808f073cc502398d8fc0bde032d866b5f654c11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1eaB2B9E874C88f0Cb395b83DC5af2AAbb4113C4": {
        "max": 1,
        "address": "0x1eaB2B9E874C88f0Cb395b83DC5af2AAbb4113C4",
        "signature": "0x143ce500d6e0cd1eef097fa98dd31175625201de4e70fde7313f0966de0e4659469c12879b050357bb716fc7c28f05dc6130a3fbab08b4b54efe991fa520b8a61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x15b5b13d9376fCb12C53aC0b484d1b61e1C9aB38": {
        "max": 1,
        "address": "0x15b5b13d9376fCb12C53aC0b484d1b61e1C9aB38",
        "signature": "0x89bfa897a4a58aef1145d84c2d32c8e7c935b3d8b8734c4df0ecbe3cfa9643a47b21b2d07261c5b90d7c9e866d866c0c107dbb3f1966b079839dc0cc46f32ba11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1Bc37bF61ed1e20968cB3099b34621bCb5318d42": {
        "max": 1,
        "address": "0x1Bc37bF61ed1e20968cB3099b34621bCb5318d42",
        "signature": "0x40706040388d4ce6e2c74c0c1b12e17637959fcb48f1cb5ca1e50955c32413e358cdcd158086ab51b69344e9b2cad919a120c085ed5e686dcd70a638cca370721c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB0AcF08a36dE52e8f3c4F975971973F20781Dba3": {
        "max": 1,
        "address": "0xB0AcF08a36dE52e8f3c4F975971973F20781Dba3",
        "signature": "0xd6b6fd9de55c11b87a4664bc886dcc0c679284e9b9a40e5fd3f04c89f97e68fe20dc4bcd00a9689fbf7b1b6e4c773f2e68782ec4698ff6147d2e5c1ab2234c2e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0ffC4b34c5D4016179F044aF54ad850111146748": {
        "max": 1,
        "address": "0x0ffC4b34c5D4016179F044aF54ad850111146748",
        "signature": "0xd3f5adfff8f258c8d9f72da86fd9f991365cb484a6053fba7253c382f19cb77765f1e6f31775a236beaeaf4666f9a231b7cf4419a16fb9fca84ef2090b0c0d6c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x214096B13BD15cc38bAa51c95E05edc7d961E8CB": {
        "max": 1,
        "address": "0x214096B13BD15cc38bAa51c95E05edc7d961E8CB",
        "signature": "0xe0b6d4ab966cb811dcd5f06bf5de49c9cd135f7f4a4d8fc3c1727123edee002d406a33bbd7613879e1b1244e1eedb1c984337121228196f35db0b1399c5f1ad21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD8055Aaaa6Cd2994EBc00a978770746c89f51303": {
        "max": 1,
        "address": "0xD8055Aaaa6Cd2994EBc00a978770746c89f51303",
        "signature": "0x43504576ecdc92158772944b63df2f059e359b63a319c1811523defc8f647956253ca52375b55c0fbd120a27fcca275a842b9adb96050657a4ee62e2f911338b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2Db3D49B46C3e07400Afae9bf3508b863EFE2590": {
        "max": 1,
        "address": "0x2Db3D49B46C3e07400Afae9bf3508b863EFE2590",
        "signature": "0x381b0357a5e6ab056b4d56a97ab523f00cdd1c48fb2339817788756fc8045268016d2f7bb599e897b6eff35bb663324476d10a98e1d8d21405d9aeb9ebcafb111b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE34C9A10B0C1d7C680a7730de8d33a2054b38602": {
        "max": 1,
        "address": "0xE34C9A10B0C1d7C680a7730de8d33a2054b38602",
        "signature": "0x32ce580380636ad6f61261fd0b6c3a612934e70fdcffa16d34201b42dbabfd0f43eeb697ee958b698f28bbac62696d429f845359a304d933e5295b781a8e1e771b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcCCA45cAeF971a4C1c501cE7A4918003cA01B825": {
        "max": 1,
        "address": "0xcCCA45cAeF971a4C1c501cE7A4918003cA01B825",
        "signature": "0x6f2959a49d5e990a1ab10de706511efb742f936f067aafb69d1943355411c5f6049fa0631082b297a39eaa691dcd81658da3ff5a0ae36597a5ff29b712287eb81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x387Fd01eb7B7Fd5B99A5f5b8419148288d3898a4": {
        "max": 1,
        "address": "0x387Fd01eb7B7Fd5B99A5f5b8419148288d3898a4",
        "signature": "0x11cb6f3d5ccbd9e3db07d2713cb3fc0ac9a17d6881380aa53493dcc7357311e3535da32355ec982e5574a5abd64f1cabbb8d66d731c1d67aed8cf0cb4ee40bbb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2cC5bF0b0EDAC7f0f430F415847322265d928D8B": {
        "max": 1,
        "address": "0x2cC5bF0b0EDAC7f0f430F415847322265d928D8B",
        "signature": "0xd3537ab3c4dab6cce69c425b3f2b193e47917dfc9012f3f29c7673285b5eeea23dae4cbcb04f27d6f55f3c0e2370258a71ab45e5029a4a27c2479d5014ff66561c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x52Ada85c7ecE42Af0d3a5E1523A31aE9cCa2928d": {
        "max": 1,
        "address": "0x52Ada85c7ecE42Af0d3a5E1523A31aE9cCa2928d",
        "signature": "0xe2e6acd0c131043b757edd2b1fec4031fa71cd1bc5bd90876084b5574b11361b224dacae333001ff484e84dc860d4008d6769d42a5f17cf0df211ce429a8fdee1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeB015F2aa12c0e41Df7D5Dd7Ce94e0Da7AC4f0F6": {
        "max": 1,
        "address": "0xeB015F2aa12c0e41Df7D5Dd7Ce94e0Da7AC4f0F6",
        "signature": "0xa53f5b4e22a47317c3ce39a1cd6e62b7b6ae81ebdcbb9fc0d02d8da8689ba9562ba1b034b0282b8aed4fc7a0c418c0ac66ec19d3158f1d4b07dbfd0f500afcc21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb0342278516C4EBA98c2CeB8F2Fe6923dF85CF19": {
        "max": 1,
        "address": "0xb0342278516C4EBA98c2CeB8F2Fe6923dF85CF19",
        "signature": "0xd00a42aaa6c377da9877a55c33fa49f14d6ac658acab63cafe459a1dc4adb7082e4ae2e41da21e78bbe54a70d601c58e5d566f2f7d3fe89e14306175a7f542a91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7F31bE51ac75e319e68770321028df35dC04b4D4": {
        "max": 1,
        "address": "0x7F31bE51ac75e319e68770321028df35dC04b4D4",
        "signature": "0x747e981d19dea73180112e99433785f55812aaf6a150d4ee1b88bc05dfc39ca12476e03e02c33df4098cd3a4cf4a7b443e4597329c9ad431bf70b98eaf34fac01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3d91aC4cD01D100BD2a36bc1E37c3251115448dC": {
        "max": 1,
        "address": "0x3d91aC4cD01D100BD2a36bc1E37c3251115448dC",
        "signature": "0x63f03527895a87d7a7cb6844011efa9d3a81fac48e439aed1a3b5d42bae7ccef74ea769422e21c59353f708b9635b8843a148622089a7de6c507427411fbd77a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x00b8CF8561042346622d68D88961F22704d5932A": {
        "max": 1,
        "address": "0x00b8CF8561042346622d68D88961F22704d5932A",
        "signature": "0x2bc0db52788b8f7696e61618b3b3b1f53013272466d231d0d333539da8ef9841063067d5e9d5b74f6a3c5f9168d3d2387da24569e867cf6ef9ec1cf355407a421c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4Fe55865a637dF7F56a197EFa580f5AE0B7c3be8": {
        "max": 1,
        "address": "0x4Fe55865a637dF7F56a197EFa580f5AE0B7c3be8",
        "signature": "0xdc3f96c9dd9a78fff153b3324f4e4f71e38a71ded184ece4cbfa8250c4ec548b635e2d345b0f96da3df425158e74ed7002e81d47bf5258f965034224481c06de1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8A263E62a54De536Fe4CFAf820E191F5e890C100": {
        "max": 1,
        "address": "0x8A263E62a54De536Fe4CFAf820E191F5e890C100",
        "signature": "0x668f06e739e71e85a18bc5d297b8c375b7292c457992da586b343468a28404135015fb1b3e4406112da1bf93f2c9518cbc9880dd031bf5bf8df02e50bfdc34b61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x95249aE1c38C5A3AC7013Be6D08BDe961f3dc285": {
        "max": 2,
        "address": "0x95249aE1c38C5A3AC7013Be6D08BDe961f3dc285",
        "signature": "0xc00ecdb28f5db463ecadac7ec8fbc8688eac3bc99dbead71b6be0b08f2d0f08f4bd16256f43351238a84b1a9d715e15cf0d87666915dd3073876b6fcb60d99471c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2C86b24251945DAeAa61F1EE39914b639E73561c": {
        "max": 1,
        "address": "0x2C86b24251945DAeAa61F1EE39914b639E73561c",
        "signature": "0x4d33ff17620a5c4d4a16fd544b3d3af0433448e9c1dc4ff37e5c4d1674ae017245832db024fe391dbe49d898f567a6993534d60b0a608f2d0ad5d8c9b7ff0d2d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb411E7f8182BB0c3516D72d32352c0B8f6ba783c": {
        "max": 1,
        "address": "0xb411E7f8182BB0c3516D72d32352c0B8f6ba783c",
        "signature": "0x0847f2d754eba7468e3d75a062288732583bbb3fbf9e0d68098699a207336b9e118b414d6230201b4cc625af4a63c65183b61ede5fff578c6e43f853e3b042dc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE0A058abA924bCa2e9cA04BC11050eA1Bc57C8b4": {
        "max": 1,
        "address": "0xE0A058abA924bCa2e9cA04BC11050eA1Bc57C8b4",
        "signature": "0x4bd44eac836740db0daee6a31f60fa14d6e5a1d722a2028d5944001bd38a916467c1d1304369c162713712f675cddb0855c3718790c7c43e61225d7382a49b601b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x18A428CE0c31584a21CF8ceFDb8849d8013E1994": {
        "max": 1,
        "address": "0x18A428CE0c31584a21CF8ceFDb8849d8013E1994",
        "signature": "0x1f68094a89fd5fbd55f8c35b58678a50c2e19624fb24f2831e0de82462a6a1581599b18ee7e7ddf2a2428486cb44b4a33c37148745021349fca628894611b6451c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdA448364EF98aF9d4C551A46f3cE1e7DF879dF81": {
        "max": 1,
        "address": "0xdA448364EF98aF9d4C551A46f3cE1e7DF879dF81",
        "signature": "0x0dfe3abbac2324db08230714888784066e2d1a31aa659922b7ba446a17eb65515b16ee53a18650c0f1bf7a16f4739d5e9f28d370a57deac25987eb1dc4a769d21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb845d3AfF0741f083BB1A0c33996DaBc4c2CE24e": {
        "max": 1,
        "address": "0xb845d3AfF0741f083BB1A0c33996DaBc4c2CE24e",
        "signature": "0x9cf553f3a0238d17a9ecb66dfb2a40089518262a297d0b3e94b8628514bdbf30791835e303fe0a1027d62426835cab51308cd51eada00e80ff328117d78f3e3d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAA68779177fa739aE63f24A2F5ed3F9f752E00Af": {
        "max": 2,
        "address": "0xAA68779177fa739aE63f24A2F5ed3F9f752E00Af",
        "signature": "0xa89a6a02c3a482febec6723174c31351cec5e81729ee8710aee27b75aafd26081d0d0fbdd31757a4f1042808a48656330ff1f0095fc373408a60f0027218d6871c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc303aEff7F4Fd7b1A6777053229c85149dAba635": {
        "max": 1,
        "address": "0xc303aEff7F4Fd7b1A6777053229c85149dAba635",
        "signature": "0xcc7cf5615369f53a3bb2a22b5c43925888214de767753bbf9076a7f9ce03efee7339df180d70423e6814779ddef07ed7d562e52e7a4aaab1a19ed3dce0c26adc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1Bf898A27514E96A00b4AaD21A7e57407d30b1Cc": {
        "max": 1,
        "address": "0x1Bf898A27514E96A00b4AaD21A7e57407d30b1Cc",
        "signature": "0xf6aa867275063dc6e4d1992a5170191b7ed1f649caa40938842c31aade06d67a5351e8e9935689b94f62ff64002fbe3384a0f0bbd48e83680141ce04b1648c8b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x70a11c2733AB7829bf99F0299B6AF00c47B7C9dC": {
        "max": 1,
        "address": "0x70a11c2733AB7829bf99F0299B6AF00c47B7C9dC",
        "signature": "0x2367ce366db692e799d5fc7020220e7fdf235102c833fbdcbe48ee4c7e0fa23c11a6fad046839f2d9d883d8d0535d8e1a0046af743f7c118664fae4ab500a05d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4AA2feD3b5Cb3049ac679b501f7E2001cB4dfE98": {
        "max": 1,
        "address": "0x4AA2feD3b5Cb3049ac679b501f7E2001cB4dfE98",
        "signature": "0xc158294a2057c7be3041b571c90272a676e62cc97c6efcf215d8298ccebd0819302b3238c9aeb5fcd7073a4d141dd0687f9e04cb97335fada57b2c6ec31b77f41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE22f0E78a8E2cFF7ae2513e1a82033e367FA5435": {
        "max": 1,
        "address": "0xE22f0E78a8E2cFF7ae2513e1a82033e367FA5435",
        "signature": "0x78dc13dda99790472b20cd4f7a9bc58d5ffa3f4a79213e5c9db43b516fed017f5aca83f442caf39eb080f27d753025d407157c6c0cd0e80f22e02656bc86f72b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x41aC4D39fd3dc6655270f6148d8Fd1D14E818F39": {
        "max": 1,
        "address": "0x41aC4D39fd3dc6655270f6148d8Fd1D14E818F39",
        "signature": "0xeb3296c9793508e5f2e024c9a3a1840db2d456892069985883eb0f26198b835a2862b66eafed704ec89a2bf45b8e15efaee912dbd1e666e3b936a22f6b8e76da1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3111a82667D26286679A822A72bdED20374f3AF8": {
        "max": 2,
        "address": "0x3111a82667D26286679A822A72bdED20374f3AF8",
        "signature": "0xbd28c0870b51ddc78cac10198089c0ae9b1bc383303f51a1792e6971baa0e78d58b48a1bd585abfb1f490c0242cd056a7f61cb6b667d1b46b384e7bbdf316cb31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8179D9679D6bF4ebDBfB63E5189B0126c56c4E87": {
        "max": 1,
        "address": "0x8179D9679D6bF4ebDBfB63E5189B0126c56c4E87",
        "signature": "0x05202e985b92325b5b7e691dd3d6dc35176d2a019a0275bc3ba2b02e57b9752d2d4e5d5a9f6afce57ba94de5200fc3cb82003074ade8f81c4a15aa66cdf4bfb31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0d905c2bd5372B0aE6BEd1448A0Fe24663760347": {
        "max": 2,
        "address": "0x0d905c2bd5372B0aE6BEd1448A0Fe24663760347",
        "signature": "0xa1bd59f57fe39eca39c8139a29158b7e84576be8b39ad8fe4d562e46df799d360fc060c0e6073cc92413decd488b151e5cbe32ffe05d5299d989b7244dfb7d4c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0cAeb82757701B85B561A2F845d1FC01Cae389Cf": {
        "max": 1,
        "address": "0x0cAeb82757701B85B561A2F845d1FC01Cae389Cf",
        "signature": "0x74f82d5a804accee42ebe9f95f31dcef338d13131d3635a1ec3c3cdc12f97c9f13ebb19df534d592c96485eab61233ad0658c01bd5f738f4c1c161f2ffe5dc531b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4D70dCF4fde67AD66c67d56765fc892E8714f7D3": {
        "max": 1,
        "address": "0x4D70dCF4fde67AD66c67d56765fc892E8714f7D3",
        "signature": "0xb3586ecb9a2239368716664b54cb4a78d0516c0b45e1fc2254be259ecf93f5812f634529406bab2a0fb3f9c9bbe3f2391c5c90bb61769866dd7ffab1f1b26ce41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBFb5D30AC3B90dDd68647452F0269a7Cef3Fb3A9": {
        "max": 1,
        "address": "0xBFb5D30AC3B90dDd68647452F0269a7Cef3Fb3A9",
        "signature": "0x4cda225ba9dc8e3047c0fa74d63af67852c0a5085e9e79c5162d94ddd49c48660be62dcf4dc7f4cf4bbd161b20b87504350cd9c4bc325b7239a77820557befb71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0788C6B29E4951C853f1BD0BB55B3a1471fC8ad7": {
        "max": 1,
        "address": "0x0788C6B29E4951C853f1BD0BB55B3a1471fC8ad7",
        "signature": "0x691e2f19e571df2f8d2da0cf40e7c6878111fc97f1f3d4b3d5a7f3bf5db7816f47dc19f0c41ee41aa711ac6a75c236e38c7fc9f8c1b4397bc5a4785cedc7ef041c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8f9E9dd014D9392eF335b9aFb5231DDBe14F617e": {
        "max": 1,
        "address": "0x8f9E9dd014D9392eF335b9aFb5231DDBe14F617e",
        "signature": "0x575756aad4652d7aef3c07f29874a02aa93773faf47a66adecbcec4394f574b732a7d62bf793dd7c612595857b90ebf2d66a2d0bb6c3997d3620f10ebc09fb6e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x536AFB0a34dd72f348312d39DD02Ac13848C8F87": {
        "max": 1,
        "address": "0x536AFB0a34dd72f348312d39DD02Ac13848C8F87",
        "signature": "0x1b4c5159e32989adca69f10faf52d49e6ed9f3cc66d179315db042e2028dd70f54a9f4381e964a7e0f2818b290af9e1f5c10ed2acca26dd3212a01b9f86fa49d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9020EF83beD01361f09cD0e18198C58288B227fe": {
        "max": 1,
        "address": "0x9020EF83beD01361f09cD0e18198C58288B227fe",
        "signature": "0x344699ce32ee7b1fecfd61672bcc19a6d6de1b2d293f7b5c0d62060d0ef0b8fe7bd01e49f8357f70e0bd4949407bb60b2b107f4a0dac1db9efa1cfca83e1d30b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x408eC50F013Ea97297a1b3f4c8b3Ae8DE1FD04E6": {
        "max": 2,
        "address": "0x408eC50F013Ea97297a1b3f4c8b3Ae8DE1FD04E6",
        "signature": "0xe0405fc920ae5554ae393d0eddd95edb550bc74ceb904b49bca7ad2e6585f0b9583fa3d5b2369a170b2dd4306418f30ceb076b69643dce36c288c95e8f4ebdbb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x272cA71913Ea69C582Bba35AEe59d4F542936F42": {
        "max": 1,
        "address": "0x272cA71913Ea69C582Bba35AEe59d4F542936F42",
        "signature": "0x36ce8e5d1e9725883964338280c27796d10815bb531d7f13b7b1337d74bcd17d262b1759cf4529acbac1842ebd55644ab13dccb4cff39fa4d51e122c2c7811c21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd8d6839969846367e2303f20C4ED2D4Aa1b0dDe0": {
        "max": 1,
        "address": "0xd8d6839969846367e2303f20C4ED2D4Aa1b0dDe0",
        "signature": "0x09bd272862506b62124fad1a7c940fb930b3de8985eedf223280a119b6dbd35a523222f611ced93eb5c66d1969e365d9fd7b0eb13fcd7e305abadfbc4fa31e4a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7C2bA9F3F0e56f02cB0D76f6F10D6a8440BB0c80": {
        "max": 1,
        "address": "0x7C2bA9F3F0e56f02cB0D76f6F10D6a8440BB0c80",
        "signature": "0x4eddfac95c58dbb280aae12a655bb4cede852cc9ebda2139858fe22a6f6b991f30fc61d4eb7393be8ee077a9d040becb29e7cf3820247a77242977e6ceea64371b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb0CE46b5CE779bed1B9f022225829D2bC61fa08c": {
        "max": 1,
        "address": "0xb0CE46b5CE779bed1B9f022225829D2bC61fa08c",
        "signature": "0x4c4bf8c7ad4d8cb9e9435b6c783cf5576395e1da5e94f4ca84fc50d9b6fe3e4362ed228c0ab4ecc6bd95bb033031e1d962d22cb468d4023a2c7efc81f1e5e4ba1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7CdAd915D5DcF6e22683bEc9cD3fA7519eB77A3E": {
        "max": 1,
        "address": "0x7CdAd915D5DcF6e22683bEc9cD3fA7519eB77A3E",
        "signature": "0xc0114e4d39b3dc6b3266c9c926259030b635513d4203a599c2b10901ebcf1bf407112f14aeb85ede7ee5c54c633dad095905b6abc07a272bfb2ccf2b50b763761c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x767a1dcBeC3CeE1Fc3Fd36B9C48b1eB4Dd40F2b0": {
        "max": 1,
        "address": "0x767a1dcBeC3CeE1Fc3Fd36B9C48b1eB4Dd40F2b0",
        "signature": "0x54276f9d7779d0973ba669c11c460b0d126645c37ade447b7807500673080a411a1295d468020df562df3aa7f8d0c08b43b37974ec2fa24283580fb8aaffc2c81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x00cd9BA0d8e4d4bC4EBd702e92e8A43b69284226": {
        "max": 2,
        "address": "0x00cd9BA0d8e4d4bC4EBd702e92e8A43b69284226",
        "signature": "0x343ef3c87ade3cd4348d0fc224015b8b0451984a43f3afaab49979a34969823f60c715e5f9533aa9bbcfd92462ced43aab7fa9c963b4cca3a48f44590cdd54af1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0791d8035aeFaaC91BDeF11b69c36Cd8ddAA0e6C": {
        "max": 1,
        "address": "0x0791d8035aeFaaC91BDeF11b69c36Cd8ddAA0e6C",
        "signature": "0x59f83ec9afd2340bc2913186fc23a0e6fcbf96197518a6d216ddc8cd6ac08f8840954c76114a7c26f6afdfff267bdd6d0ee4fcd8e2718791808fca478699bb721b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x788D8FFAaC3c5CA604a608d233CaACb0bb41076F": {
        "max": 1,
        "address": "0x788D8FFAaC3c5CA604a608d233CaACb0bb41076F",
        "signature": "0x6ef29e722eff59885de48c5f059fbba88b807f14b6e6c81fc7809a5d0c20f4343693655b02084a80e320addf864690d033f1f751f9ddeacf631768a2bc7893fe1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB32d0fA770Cc6e5230F8BeaeeFb4aB47d6d78BAb": {
        "max": 1,
        "address": "0xB32d0fA770Cc6e5230F8BeaeeFb4aB47d6d78BAb",
        "signature": "0x6c9860f70557369f9fed0c73d9c4d025f100428756077dd6764fdd5dbc5db42912b70562b10ea45199a722156d84ecea9f70b0a7474e0459d3e6827ade170a501b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2fab9eF65f432ac350C699D1A7028Cb554F19F35": {
        "max": 1,
        "address": "0x2fab9eF65f432ac350C699D1A7028Cb554F19F35",
        "signature": "0x17d05a4621703c2f6ce530bc58878c10cff9c095f1e4210adfcbf7066338c5685c395895984970de9da35edb0ab8eddc3d6f6d22448ddf3bdd7f7836342a5f9a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC70b8665d7Afbcab831F95830B4789759A588Ad0": {
        "max": 1,
        "address": "0xC70b8665d7Afbcab831F95830B4789759A588Ad0",
        "signature": "0x26adc04e998310d1cea0a39471e8c813f8ea00de3188c6cf2f46aa469b92ddeb02fa8b05cd3dd50b324e85f2dd19bae8b816ced8f32e4662d445f6d6660c4dd71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9028EaF16E267D7a1B6a8e329524814Cb8f69FA9": {
        "max": 1,
        "address": "0x9028EaF16E267D7a1B6a8e329524814Cb8f69FA9",
        "signature": "0x51c671660b60e972e11b2843ec4da8e737b4b73a4c5bea29b0bfab1750be58431ac66eb0980d96e27f7ac5e4976927d34e31d43e42447c0d75fc1677edef479f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf82F36454E7450313Caee1Ac90Bfa508b9748a7f": {
        "max": 1,
        "address": "0xf82F36454E7450313Caee1Ac90Bfa508b9748a7f",
        "signature": "0x47f14ace114175a31dfa0c5b351b30da71c37fc5cbef679ec598dcda38a5be5b47b0dd1fd28e4524fe37db31a3e5bb61c58826c7335070cd684e811bbdad39771b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8DbFA40390C53c9f88E19E6eA1c2c35fDa0ADe90": {
        "max": 1,
        "address": "0x8DbFA40390C53c9f88E19E6eA1c2c35fDa0ADe90",
        "signature": "0xa6b58ee5e5ebdf4576c1addeb92b7e8fe4693c79588d8a5b5ac157e4c5b0718737fc973c8382686c67d952c37ce662622b50fab871b677576b5b694957acf1d71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcd9f24Ee033Ec499Bc250754067826513D4BB550": {
        "max": 1,
        "address": "0xcd9f24Ee033Ec499Bc250754067826513D4BB550",
        "signature": "0x31c5a0d49d09dba51053e21989c45b373f958bc0f1c67f38b7263743be3336705f8e43a470950f0565025963fda1dda79f3863cf200d8aa7220fe5b625d04a811c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x577DA6c3727b08bfa4723cF3263ED3b7d7389B79": {
        "max": 3,
        "address": "0x577DA6c3727b08bfa4723cF3263ED3b7d7389B79",
        "signature": "0x47959e6906d7f19ab60c00cdd3bf3bce5d09bd6efd87aa31180b624351fdf64236129485057d7603f9c48590ae8bd1ea0dae13368d122c90dc4f0820ea368f931c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE9bCcf975C1D18839CC0522647Df6004d01DD4f9": {
        "max": 1,
        "address": "0xE9bCcf975C1D18839CC0522647Df6004d01DD4f9",
        "signature": "0x5dc2da2520b26dfeab89d849e912e7e691fd155138d364c44d948bfaa851083e3ee47ebdf9a0f71658d65a82ed78f3ddfd763502aa3f285c17fadca3350c24c51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA62310801Cb3B19B332f9e783cd2C372f835208F": {
        "max": 1,
        "address": "0xA62310801Cb3B19B332f9e783cd2C372f835208F",
        "signature": "0x32eb3ef83fabf61038c2c1dffacd9bc61802c11da81db55055441218b51eb3324cefc1802913109739717a8f3427be808eb20cf8d8888c0ba5873d575737b9dc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf5eA2Ba9FC55E75D2eD43cf5f78F52481ee7D42D": {
        "max": 1,
        "address": "0xf5eA2Ba9FC55E75D2eD43cf5f78F52481ee7D42D",
        "signature": "0x8e3322d2e1c2494e5635401925ed7b6bec891a0fa32400d27e0f7351a706aaa7034c6b6a781ae5ae7304c8dd359f98d554ecdb80867db99ea351572942352bf41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7793353d2A808C8141F4F17420fC4eBdE2DE9F2B": {
        "max": 1,
        "address": "0x7793353d2A808C8141F4F17420fC4eBdE2DE9F2B",
        "signature": "0xd2ad1c114b04bf59801c688bdb1b58dbe96184e969a26b41013ad884893e67e244d8db8b458769e52dba3f87de387ff3968cc8920173c5a82c845c9d18bd29481c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFc0d0C2B48af929a1c46AE03aDDdafB28b93c4C9": {
        "max": 1,
        "address": "0xFc0d0C2B48af929a1c46AE03aDDdafB28b93c4C9",
        "signature": "0x8732324639deac341f4e356102bb6030e838e7a037b48198c908782be5139d7f4cda53a65ea14ef407afbde48d6961c2902ba65c6d4a118465365a236b83a0de1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x33440911c6eC466290aB21f72aa31287ae998178": {
        "max": 1,
        "address": "0x33440911c6eC466290aB21f72aa31287ae998178",
        "signature": "0x6739c4c861379c21f0eb5e2a0406b37331dffa47ff09d5397a891acb9f49ab133f58e5ff4cf07008fe49501efd16e75c3d13bb493eaeb01fb72711c412cbe89c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x331a986D847d5c2582F787c6A0b705B29606Fd5d": {
        "max": 1,
        "address": "0x331a986D847d5c2582F787c6A0b705B29606Fd5d",
        "signature": "0x1b522a95b52c4f2f4f37bbac8dc5a707618675d152566bc604c05b0a31ef50a435bbc64735278082a3f80416cf4d970199a72f54e43dff9731e1a54bf1ce596a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xafF794f4dD27e16fe88d774a29E7BC0cA8fEDB7f": {
        "max": 1,
        "address": "0xafF794f4dD27e16fe88d774a29E7BC0cA8fEDB7f",
        "signature": "0x373ac99f364cbf12eef987c929b87c73676051797624ebc461fab236ef5737387f40cc1dd00eab53ca8b109c6bb603692d59beefa18b3684bd7b81034516a59e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC48C89D4D052CB7D146EA1cE3F44f019f6c968E9": {
        "max": 1,
        "address": "0xC48C89D4D052CB7D146EA1cE3F44f019f6c968E9",
        "signature": "0xc6423bdcdd29af35207f39e92ab4ea8f37751f22e61a0aefc10924bb961f2e6656e2e9d5ba17aef605c2a0d9ef60cc4560e9a22ac5a2dd52ba40150eed3c6e1d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc9e829d0810f1e3aD1d84C1aDb655678EDD6b901": {
        "max": 1,
        "address": "0xc9e829d0810f1e3aD1d84C1aDb655678EDD6b901",
        "signature": "0x192580bbd3f7063b52ed220866fd229116ef67e1f65bc85e09d3ae6c627b961637bc1d0819b85479e7149a8894e3d58f32ac22a3dedb68acc3762db1adf7dca41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9a7492EdE9B6de3F06B8aFa50039dcfdD2Ac3848": {
        "max": 1,
        "address": "0x9a7492EdE9B6de3F06B8aFa50039dcfdD2Ac3848",
        "signature": "0x478973fd17934fe6badcdde120747485163db110349fd4425c9a735c7251d687312b4516210e8559e54dfb36a5a80a029e918d2a8de31bb9b6af8492e9a6e39f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE18712354bA29892712678929b2F55545A591Ec4": {
        "max": 1,
        "address": "0xE18712354bA29892712678929b2F55545A591Ec4",
        "signature": "0x986ba8ae2403d3c5ea96a33fa0f1c82767b22733e93775e2883ecb363a776af7531245780def088a8e3ad3fe0b43e27ab64cb3b338cea56485fa35c603ccfc3d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x67D67c342BB6cCA9844024658931D0Bf1C8757f4": {
        "max": 1,
        "address": "0x67D67c342BB6cCA9844024658931D0Bf1C8757f4",
        "signature": "0x66a4c0797229b8eaa69ce9e068293aef4a1529df3dec851bfc12dac612bc5fd659b348d10ea061709e35d52bd04c9b90b9628e90ff32d05b8dd0b1fac4401ce91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe067caAB51Dde0fAeFF56EC1D0866D0DdFF15dd1": {
        "max": 1,
        "address": "0xe067caAB51Dde0fAeFF56EC1D0866D0DdFF15dd1",
        "signature": "0xfe145b3af4d983c610d85f8a25543b1cac244e5edd60f5341849950abc7ea9866c1e61959fe0af62fe5e44eb3e062c94262c8b13c12765ec87a581f7dccfa47e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x193291E8baE6ec35EC0de9ad9ACb71C0d7911424": {
        "max": 1,
        "address": "0x193291E8baE6ec35EC0de9ad9ACb71C0d7911424",
        "signature": "0x27eb6a43cb879c3d6c0f8243278b926bc3e47124ce7274ea5a9bc982ccdb899a6f953b2fbc34dc9d1d6cf9cf3654e571918e2f0f65521006fda2e78b66918fa91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD460993F0041aB5f7302e2793154d33A3bE7fA53": {
        "max": 1,
        "address": "0xD460993F0041aB5f7302e2793154d33A3bE7fA53",
        "signature": "0xbd1e82b1ec489db0dc8888863adba4e8c44e08fce79cbce92fb29e8e5fc37a2f2bd1c1b347c127fed713f005f4f619e6cce13d11805cfcdea99efbdbb6f6f6a41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF57ccE511F8c40215C16C56e3D5E7B9b5b7B97A5": {
        "max": 1,
        "address": "0xF57ccE511F8c40215C16C56e3D5E7B9b5b7B97A5",
        "signature": "0x0e0a9ea5ba4a988dc45afdbc6f644d79705deb6a7183b0a6491c655743b788e82a370adf79f1b43b5c19014fad82090224cefbcb53ec3f63bbe45a52951261801c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x622cc38DaC31Ec89b1fAFF3Ee7eE3f0711E6D068": {
        "max": 1,
        "address": "0x622cc38DaC31Ec89b1fAFF3Ee7eE3f0711E6D068",
        "signature": "0x7fdf4c4b8549413c1b9bbf31a6ef1e1a2fac338a37e5e1af596db16bdf4fbd9e61bf20902d2e5f0f016d3936ac34d08bb6a375453d6e6c3ac5ba0996adf443811b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0B836F7ea6B21d4d6Fb8ac9ecb280B248753f821": {
        "max": 1,
        "address": "0x0B836F7ea6B21d4d6Fb8ac9ecb280B248753f821",
        "signature": "0x7cf6d638bf401250dc538fbe5f84f0f61925d4bb442fc0b694e34babf674fd71346be3939ac175a26dd7f0f6ea0816175ad65c7e1d511e8030002541f4ed527c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3b34eA2f4a9EC56F706D24792B56c1B08d352836": {
        "max": 2,
        "address": "0x3b34eA2f4a9EC56F706D24792B56c1B08d352836",
        "signature": "0x04243c1b8ee48500ab168e465d55f2d9fce242cf6a164dee8782838b636c26c1072a4e1cbe11bee9e87388c82173cb4c1cce5e0ad7d95d9aa59fca7bc4fefe4a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x91Fb8FcF6D90E9288d90c19AC5B34B00b1d5Ff18": {
        "max": 1,
        "address": "0x91Fb8FcF6D90E9288d90c19AC5B34B00b1d5Ff18",
        "signature": "0x2ac2d0f88944f56714ff31f681d536e9c4428c0f72bd2201516f67916577298032c6c8ec75053883f266b243dc6c66d9358410cd0c931f28b606c86197b5b83b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDe8194FC7B6642b70B7E7C8D6e7A18de2A3ABbde": {
        "max": 1,
        "address": "0xDe8194FC7B6642b70B7E7C8D6e7A18de2A3ABbde",
        "signature": "0x0f03995ab2335ce02587500a15e6e5a00a8375e43889580bc7ec7d03f91884ec2b5ee8bf85f726d19dcf17c8dc837de0f942ad9bb92d22d543da92189fef23e71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd28062e3549cF800D9502e9CCea9ECb97c4784Bb": {
        "max": 1,
        "address": "0xd28062e3549cF800D9502e9CCea9ECb97c4784Bb",
        "signature": "0x77e00d50765001013ef88e01f21a7bae51da6478a6fe47b50b6de12cb467999f7b7fbc2efe28cdcd1ebcad1b999693b9d1d7c44fecc599a819b75cd9c25396a41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x665D43b4b3167D292Fd8D2712Bb7576e9eE31334": {
        "max": 1,
        "address": "0x665D43b4b3167D292Fd8D2712Bb7576e9eE31334",
        "signature": "0x506a3893c4fb04d64abc7aa9ef9110f379430046dc5669834a5ae1d4f29088b9732a87f7f0218ec99232bc81d0b11eee37575b93f319924675cc32a415f81c5d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1a424d1042a944f69b70A937D6eB2a7b3fDcf04e": {
        "max": 2,
        "address": "0x1a424d1042a944f69b70A937D6eB2a7b3fDcf04e",
        "signature": "0xc74725eaf5693ebcf150bcaca58a3e84185132fb2d5c753f44ecbf2f0df7c1984defb08770692d37bc18aebdb655afc167faae52c7901d9ffd034c5ee8266ac51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD4Ce40888901496150C5ccCe756f9378e92FAc95": {
        "max": 1,
        "address": "0xD4Ce40888901496150C5ccCe756f9378e92FAc95",
        "signature": "0x667e1af5fa704dd04b660bd71c2a2fea8068925213138908a23ae688a8b93a2c66afa0dddf9a3810411bb71b849f4dfdfe1835d5c4a0ba90a8bc501ef087d4c01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1789CDf2cE2ADEaC34726125116d7Be79bdC5f46": {
        "max": 1,
        "address": "0x1789CDf2cE2ADEaC34726125116d7Be79bdC5f46",
        "signature": "0xaa9687f6b12fe15a9ef752ad12853ffb8f188bb91ed385ce7092aed60c81eef94d9a908d000f2e3c937744dc49a8b0345ed3fcaf11c22066c87cbb877e3280381b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x74D934a8c2D94D6A023aDA6b1A9ea4140519481b": {
        "max": 1,
        "address": "0x74D934a8c2D94D6A023aDA6b1A9ea4140519481b",
        "signature": "0x12ebed4d92f3137c1f9511c1befbdf434aab033e43945fae18374a19ef644f6c6cc5d59f6f7bc01faa9842be895b2eb264b1bfce53abfbccd18d9b86139e8cf51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa5CeAc9fADC1da97732164ABeA4FbbF4278370c7": {
        "max": 1,
        "address": "0xa5CeAc9fADC1da97732164ABeA4FbbF4278370c7",
        "signature": "0x0965cffbd4ba31791ee6d036d2d7b94acb588130dd9dfab436b85ad707e587951f481d1b772b4efe549cefdf4348cb607d77ffc4ce203d6fbc23c9c591406f661b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD02865Eb402c488Aed4E6c9593c9D0D276eC309E": {
        "max": 1,
        "address": "0xD02865Eb402c488Aed4E6c9593c9D0D276eC309E",
        "signature": "0xad5967ebd134132eb0034d2854dd2d5d3ac9f4bd79d5d88908d4f9bd0d1124262478442407df1fdf21667c24136e54b95bf0fdc0b18781d62d52a6bee69933451b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeAfe55274a58796773134514456758F60E3a8B17": {
        "max": 1,
        "address": "0xeAfe55274a58796773134514456758F60E3a8B17",
        "signature": "0x78dc4e80f9a8f71a74add6007e5d5eb793740a10f3959c50807354e42fee793c1350bbb408698482b8ec68497b89df091599dd6c7e40026e29cef13edda2a78a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE53f63d80A493Ab55d04499F05055aA86317e502": {
        "max": 1,
        "address": "0xE53f63d80A493Ab55d04499F05055aA86317e502",
        "signature": "0x40c69efee27d43e9d8f8cb3e3e398b408ae032304d8c6055d7fe4a671a8e4309718b0413d2f11c8f66c5ce98bccb92f2b0d0f3f33e863b919c075bd9e11758701c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCeD9A372CA581dCd95244793c0B7D141c20Df4AD": {
        "max": 1,
        "address": "0xCeD9A372CA581dCd95244793c0B7D141c20Df4AD",
        "signature": "0xdca6472696852b39ff5e6aacd0803d12b01b5fd6943be7a85a325b960100b87e3040b9a5e6b2b2df2af464ac81c807d39138ccfbb15512971cc08bcb1d0baea61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x22313eBA558cE661F46164C11542B635f8ff2BA5": {
        "max": 1,
        "address": "0x22313eBA558cE661F46164C11542B635f8ff2BA5",
        "signature": "0xc61abf69abe6c7d8f9e0c2a45d961f6ffd580c5641cbd48653a7f53a306a23077d91833960369d036665ae33bcef3a70a6ac088aa2f0b326d277a2cd9ca1e8ba1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBB7d323E6e6943aF6Bb57A964a4d68231f90CA52": {
        "max": 1,
        "address": "0xBB7d323E6e6943aF6Bb57A964a4d68231f90CA52",
        "signature": "0x82e7b1bb838b42b6fe39d7ae25d8555f5f5a0acae6577bf0ab74e45f95638eac001f74d409e8266c9e685523cadc141e429cdd9ac4c45a0b7885d859386613821b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2394e99B8Eac40fB87975A1f979eb563e2a41E5A": {
        "max": 1,
        "address": "0x2394e99B8Eac40fB87975A1f979eb563e2a41E5A",
        "signature": "0x4f7e6e7195f8db3b82e7f6541fe51bbab89370b63b2fc0e411d35b1f984abe9e35b724551cb714d9a54db3f1e0448a07c511515b21e04ec5cf9ca35fa6676ee11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x317Ccc6cbC90e88232eB618B22Ea64a190Ce08b3": {
        "max": 3,
        "address": "0x317Ccc6cbC90e88232eB618B22Ea64a190Ce08b3",
        "signature": "0xb895ad042fc062044644200646a3f7680143729d0fd56796470c40903c620b356f56ea1f1abe0ddfb665bb854b48a6b1cc73b0c56213ee4063daed7c554067e11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC0C61969196Ba834Ce3e694f7f911Aabe0ebD055": {
        "max": 1,
        "address": "0xC0C61969196Ba834Ce3e694f7f911Aabe0ebD055",
        "signature": "0xf10378b2aed57982c3e4a209b6f91244f6ea611f3da654ba46789e017f7e8e966fafab15654e864e290a8428fd18d5c9ee745bcada2b3b58d3883732c66ceb441b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5E318A0166F1e0B2DCfD8F457517ebCcf87Cbb42": {
        "max": 1,
        "address": "0x5E318A0166F1e0B2DCfD8F457517ebCcf87Cbb42",
        "signature": "0xeb768f14e891469b184a61725225ced09ada9245fa90390284f7541c58c7e4451b7bb2240e918d87f28b17b1e1b4d5ed38cd56c89dd74a944be3e6d4a965eb8f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA09ba92ea0c419CC18bB6Ac04332Fd56892D3874": {
        "max": 1,
        "address": "0xA09ba92ea0c419CC18bB6Ac04332Fd56892D3874",
        "signature": "0xe579281ffae1d2e58b82799e2be28567d410e08f8596aec0f82fa41674d6631652503207e0c44278b89c654a5c992baa9dfab16379df400a9ddb53947102a64a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x16A90917AA8aEA93bCBA8E410eA2C6cE83401a8A": {
        "max": 2,
        "address": "0x16A90917AA8aEA93bCBA8E410eA2C6cE83401a8A",
        "signature": "0x9dd9c2a3b9170228b2750705fbd7b5c6c09eb5adaeef91661438fbda23d7fc5a193b1b355a02172d8b323f8d56fa5ac452a3ccf28c5540dc4f5145c87ec53de61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA5319B9B94C864b73E19CB36B0FbaD6776F91487": {
        "max": 1,
        "address": "0xA5319B9B94C864b73E19CB36B0FbaD6776F91487",
        "signature": "0x3da6f27c526b14aea02e711c42ee94fccd03daeb95a5958c1587494963fea6870b8fc2466605581a24f8255d7dc942e225af3f3fc4495e853242182323adfb9d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0761145E64Dce908e55422F0802494Ff5E4f2B13": {
        "max": 1,
        "address": "0x0761145E64Dce908e55422F0802494Ff5E4f2B13",
        "signature": "0x467873ddd1bc07deafee3a4ff33e6230e2914123591c3f515a7b36eed2fd086c085987046acc5082bc6b501d5fe4b07c18ea00a746bd7ea58dd10efad3d9eaeb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDA8d95Cdf11792975190F8b1D6E5431746c71926": {
        "max": 1,
        "address": "0xDA8d95Cdf11792975190F8b1D6E5431746c71926",
        "signature": "0x0899e8ecc86e3e2ff0b2b508d616099e65656d6e5e4af0d7d208f2b929a260925adc09c0a90b423fc9194d407c9ccead6129417ed317e7540d1ba51dcf7e5bc41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x943d4ee3C1f207895E726136198F42b474FF5CCA": {
        "max": 1,
        "address": "0x943d4ee3C1f207895E726136198F42b474FF5CCA",
        "signature": "0xfc1c287e36a8cd393250d25977509401d432b3c15d39c5a25cedf2f051c96ae433c5af1249cbc498ebf25a27216b1e48b56e700fcc6827127aea8b064492e1561b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xafD12a3B3089B1781686315f854b6aC7fa5934E1": {
        "max": 1,
        "address": "0xafD12a3B3089B1781686315f854b6aC7fa5934E1",
        "signature": "0x96b2543e5b3a93b61ec256f7f1738956a203617492cb01be64a3d051913546156649510ce132be5561649e5950012412bf7f7c85e4f2430f372f28b3ba37a8e71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2f410a1bB25912b159726Ba52a18139E0fE8daE7": {
        "max": 1,
        "address": "0x2f410a1bB25912b159726Ba52a18139E0fE8daE7",
        "signature": "0xf5c9e937b005519ba2976796f68f5d2f58530657a077cd99475941284b9053af322f49146a2e63032054e2d36cb420e3c3ad6360c8c3e0a05fe2cf0700b0e65c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4D85e9702A11Faf8A397C50004f99D71a55b8F95": {
        "max": 1,
        "address": "0x4D85e9702A11Faf8A397C50004f99D71a55b8F95",
        "signature": "0x1c7e71e12710a99f01d02eb9e6dbedfe3ec1b3dc5cdd472ad58775bb2175972579de9efca8b87a6218b6caca216b6ff788b25960f88085b104cdcabc315e00f11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf2F121b46F477D94CcE06761c325a46c0A6d4EcB": {
        "max": 1,
        "address": "0xf2F121b46F477D94CcE06761c325a46c0A6d4EcB",
        "signature": "0xab42d88c6732024572aa3b9bdd50f9df81878d28b6bbbb3aab1020fdc2b72f6972f851e536890d736206d7a61bb7eb8b0f87ff114569bece6994d5369fc502631b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF06605bBCD33C6274703EC5915A83065B2a51D85": {
        "max": 1,
        "address": "0xF06605bBCD33C6274703EC5915A83065B2a51D85",
        "signature": "0xc8196419dbfc37135436e703f0fe53bbef8c6021ac33aa574c503965c9860bc03ccfdf4b9305fb2031a4a989d72fcb26b59c026d21e1d88eccde1e2a12fe27ba1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFFcB4798D953BA01D972932C02908098181aEB20": {
        "max": 1,
        "address": "0xFFcB4798D953BA01D972932C02908098181aEB20",
        "signature": "0xf42b557077acd9a1843d2c093acd9703466e8f3a8bf665380389665cd897a03969ad323ac1c4399d7579e2d9477be1f7cc44cf1cbc2dd16d63d17bd8475432db1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x36d89738405A8325b7c560CAB2dd1b88565effD3": {
        "max": 1,
        "address": "0x36d89738405A8325b7c560CAB2dd1b88565effD3",
        "signature": "0x0dc861f8a69fdfce3146351105fc8c6d36ea1b9e6fc37f0b95a5cc64d982539822439d56553f0a9047aa1d6f794c8d4675e00d843e40340dd53700d6a1cdb7981b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x534465E02c3C0c63B7c7EE4869a62b23FF447498": {
        "max": 1,
        "address": "0x534465E02c3C0c63B7c7EE4869a62b23FF447498",
        "signature": "0x0208125653a47c8d27eba6e5bb62efa2dd7eb7ec1024a7a5569b492b5f8e7ea61f0179a28fff6048badfdf8bb687ce097012aa91f2dd9c77587620225fc34ecb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1B0488a4c8952751a7c90c7596dE51BFD5D58BfA": {
        "max": 1,
        "address": "0x1B0488a4c8952751a7c90c7596dE51BFD5D58BfA",
        "signature": "0x64c507f4243d9e974b773a2d55bf817f4f1d3eb5a16bfcc81269d292c2326a983518a14868abd26f31538177b86c86fd3796be0f5c5348d86ff4f7c681d3aebd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE84eB7Ca473Ce3CBC3787A005dB702Fe2DcE7390": {
        "max": 1,
        "address": "0xE84eB7Ca473Ce3CBC3787A005dB702Fe2DcE7390",
        "signature": "0x9dbe92502f4013f35d239ec75cbd571a0fed380551f09fd2e9519f307cb505e7205ef58d5c6482a2435e36fe7bad651728714b37ca7801995517d2d48925e6921b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x572A2304D79ebb74e66779f8689904c538193Ff1": {
        "max": 1,
        "address": "0x572A2304D79ebb74e66779f8689904c538193Ff1",
        "signature": "0x7cbda5aa5f80c962dfe363784c7c7ee67d6917886093e985749f3efee2bf423106a484310b95a3b1dfde494231f958c9506e77a8cfdee05510c1ee08e8a99e601b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x82e52CCf0b91D1253DFbEBd817E890e5bc6Bf0FE": {
        "max": 1,
        "address": "0x82e52CCf0b91D1253DFbEBd817E890e5bc6Bf0FE",
        "signature": "0x6039dfc34b3659219dde6a7cb3d8577ef14b39099bd09ac3a6dcdae8f382041836720a8db61a5cc5f5131ee1b0d499d4f204de02b60b5d5bfb7c2ecde8f138e91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3De8078679cc40648600ac3Afe7267A6AdaabcF3": {
        "max": 1,
        "address": "0x3De8078679cc40648600ac3Afe7267A6AdaabcF3",
        "signature": "0x712f5cbccfb2cdc42bea91b1138b912d3860383cda2d99eb9d2dd71c1f2d94095687c9b2937c3d67b8573dc2c7aabd74abaf7c68fb04370ab7849fb78692a6b71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x454e930dEFef173E7923267d722FA0cca0907172": {
        "max": 1,
        "address": "0x454e930dEFef173E7923267d722FA0cca0907172",
        "signature": "0x152c8b397c32c9146a053f96b295c352a8d1e6e5fe01700b871e2b0e2b0488c90a3b33b1e6185a0a7dba4d7556d211d7c30d8e4b3e3f576a1ce1317fe6b68aea1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x27Adf7e0Ba3b31A5583F929DC7DB8fF99c787b79": {
        "max": 1,
        "address": "0x27Adf7e0Ba3b31A5583F929DC7DB8fF99c787b79",
        "signature": "0x5fb1d6409a4027d7e0a91abdd586dfc98fbf5562e936182cb7fbb2522ca1176e0e8cd9eb9cab997e0e315d6746585cb8d228bf87765b4a9a238baab83cc6b41d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x133B52505d1cc5A7B1Bb8d129E11f65af58cb4F8": {
        "max": 1,
        "address": "0x133B52505d1cc5A7B1Bb8d129E11f65af58cb4F8",
        "signature": "0x2fd9c688e2b96b5c0830f00d2f235f0c18468a5c9489f148af297b2f01da700647708e7852dd5d746ed720117a7088d74bac06e2f82070bbe99bd66170ba98d71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x81a556Cbbf3805389ABfF5222D22cA4bf8AeB78b": {
        "max": 1,
        "address": "0x81a556Cbbf3805389ABfF5222D22cA4bf8AeB78b",
        "signature": "0x133b24c84225a58348adb3b53c303dcb57371527cc18bc39b6a3cbf6468b84525e7fe6633cdd47b5a172bd33a7077f38abea5370b729e20c63af669fe90e029c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x97D670721d09F42f56aa6c3CF501F81D28CB4876": {
        "max": 1,
        "address": "0x97D670721d09F42f56aa6c3CF501F81D28CB4876",
        "signature": "0xc554c8dbf46131438912ca349f33d43455c33d6b170e256c0e56662628803f4c17092c7ff71c050cd599da64718c359d05e1901b82b0852071e962cbff9d6c2b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfa5cADD39fE6f9208fCEfcebe06268D0D43e653c": {
        "max": 1,
        "address": "0xfa5cADD39fE6f9208fCEfcebe06268D0D43e653c",
        "signature": "0xbfb0ae80e88c891d5c6bfc37aacf9a9a91cdbbeb147bf5052b7c1353d826123b350fb58f13f4c6e31586c72a1f2eb1c8c30ce1cdb36b56e8e5e53713085531881c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x72ccA8c3D6ce2f190d6752020Aa003f82c08b20a": {
        "max": 1,
        "address": "0x72ccA8c3D6ce2f190d6752020Aa003f82c08b20a",
        "signature": "0x9d4922af02b6f75faf293b618780b42b867d40029406dff21b4afad3c707399460c99fe43704b07c9740304a7d786d83b8c61005d9c239255954d0d8bd6ef10e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3d77015aD3b02cf0Ae93Cbc9aA622B4F01D09785": {
        "max": 1,
        "address": "0x3d77015aD3b02cf0Ae93Cbc9aA622B4F01D09785",
        "signature": "0x00c9338a6c3a8f223c3789f9a50d2fed7c68fe9c0d001c0533cc20cdd2199cfd14f90d995d4dd80a091d96fd45c606fa600a2a93b154f46ab998069796730e141c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDc46456312BF8416754c9C8145fc28E65662c8a3": {
        "max": 1,
        "address": "0xDc46456312BF8416754c9C8145fc28E65662c8a3",
        "signature": "0x7b1e73e0c261bfb46d905f252458fdf1ececef778002ab54bfeab1d29b06e7203d7bd3fd81e29e6c7a96a5db9e0d174aa77b4c6d4e007e4c0d06363c756dffda1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xee0b207CC09f2f7B30dd9034234Fa2d427530298": {
        "max": 1,
        "address": "0xee0b207CC09f2f7B30dd9034234Fa2d427530298",
        "signature": "0xe8a474f58eefc72cbe37fb3b2c4e9f0f1e17bbb2901685a940f3d06bce96ba32429a5a507b9c353992acc8f67e9e6037171fa0ddb339d9c6c27d9171797a3a5c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0F8A55a74BFC240Df2af08F07CB1a38F2330026D": {
        "max": 1,
        "address": "0x0F8A55a74BFC240Df2af08F07CB1a38F2330026D",
        "signature": "0x8026ac5bbd6f4cca312dfdc3cee601cfa06495013fb51062acf5e5c84db5d3c429ee179fe5f26f96cf0a056fe7ed3d26d044e56318a6b1d748f20a87f7e941891b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5A823F289Ef9bF4957aa5090C190196690d541C2": {
        "max": 1,
        "address": "0x5A823F289Ef9bF4957aa5090C190196690d541C2",
        "signature": "0x87a0aa7c85f98cc2858f0a152f60da8e49481a735e30e6e2888b71ecfb857c40443e4a1a867ac08e71080998e43d7a1b77db0ab0566725968bf9c19f0d3271941c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA9c8b392baadaBaFd6229c8742a71bd2144109B6": {
        "max": 1,
        "address": "0xA9c8b392baadaBaFd6229c8742a71bd2144109B6",
        "signature": "0xdc6167d3a525d449606507be29c57591e99c90b4bcfc042089cbe4da6c19527755999354d288f404844346f1ec3aa32741a3e2e60ca08e108e3b47ca9930444d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc49De40D55Cb4b12a88C3457390Cf81ed6e17a9D": {
        "max": 1,
        "address": "0xc49De40D55Cb4b12a88C3457390Cf81ed6e17a9D",
        "signature": "0x9804139f2db9895e717783507909daf395158c3b68f624542eb8b8d9d6c699714d0b540f265c018c397aae0afb12394652999cc1e9fb3b581a16475eee2ed9ba1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc65837DeEd8b5a96A3ae78B4303648250D33F389": {
        "max": 1,
        "address": "0xc65837DeEd8b5a96A3ae78B4303648250D33F389",
        "signature": "0xfb7bc45d864840a3385c4be8fbb1b71142e5ba7342018f383cefb7049159dbe45b09ac4fc8745c8db0549817b8f9a2d0fb35f345270da306f027fdaf69cf20f91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xef243BA3dadF2Ed16110A0E6B17317587e964623": {
        "max": 1,
        "address": "0xef243BA3dadF2Ed16110A0E6B17317587e964623",
        "signature": "0x6c6ecdf84b408d2e092ac329c8360cb6603a1a746e1b1ade6d1487a4900d95984ec92daf7487a37abda3a52144c582afa8e504cf43e0036846483c08eaee894d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7eefCEC7647265395632Ea4b0f728c16288C163c": {
        "max": 1,
        "address": "0x7eefCEC7647265395632Ea4b0f728c16288C163c",
        "signature": "0x26109584340d7bef56ace703478e39494711f13bfcfa03dbcccef69f7080dc3e7946323ec44b192a36e11c5d03f54022ec7311418c7d5f8ef2d29a59b50951671b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x679FA2B7F80BC50b0dFc0F7c9eAD299B4db0D728": {
        "max": 1,
        "address": "0x679FA2B7F80BC50b0dFc0F7c9eAD299B4db0D728",
        "signature": "0x18c25ed8b4fb24e8e3556a685173dd126b1c8957a2351d129e3b19de0a29c6404fee1ccd530b44c4c4b5a66609e703d9b74a6908fb2b8b2558f94d436d5b6fe91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2835c21Eb0E4642b503E859BA56781d16217Ce12": {
        "max": 1,
        "address": "0x2835c21Eb0E4642b503E859BA56781d16217Ce12",
        "signature": "0xb8b9964aabc579aa44d79d64a9861eb3c9ac6349794ec497f0392560fcc3342a2c7f66da91bd480d77c3f32bfbb38c48b12dde028e04f6d4010b6ed62b42f3751c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4b97661f7C93Ab7F4204E365556c8E937C10836F": {
        "max": 1,
        "address": "0x4b97661f7C93Ab7F4204E365556c8E937C10836F",
        "signature": "0xfa3a7ca9a00813e1d8a31bfb5f29a15a1b181727bdcaf2c04ac36364bdcee7801aaad98400082ac1b15663eaf26c640271371eecfa42473468f1defe571722a61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x378257988BC50451248c0555E511D545a8bbBD68": {
        "max": 1,
        "address": "0x378257988BC50451248c0555E511D545a8bbBD68",
        "signature": "0xd6703e96700d280d98d3e926baebfa29f9dd9782bd2338d3ae7cfb6fbb75f5341bbf2cd7881ba84c1c7644d7c256d6a39006aee0f4fe72827cc974130ba725981c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x893946F27ab9E63b31c402C747BF5c195B416151": {
        "max": 1,
        "address": "0x893946F27ab9E63b31c402C747BF5c195B416151",
        "signature": "0xb3428eb09f3127857db795942ccc18983c831f3600bba7ede5b039033a099a085088e9c567d9400fcb47e10a62b1ec1ac2abfd5ada59e7b276ffac8061cb977f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2fea9675Dc20CdeFf89514781908DC0c9325DccC": {
        "max": 1,
        "address": "0x2fea9675Dc20CdeFf89514781908DC0c9325DccC",
        "signature": "0x8080e45dbb38eff32aea8c4d4847e1a9d272d6e79e20820bc47b83365f55b77d3b52b46f852f8fe267c303a383ca95fba2a61d9b0bed44ae60207fc0ad9c6e291c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1A088A9B30339a2509537ae4971239B125Cf60a4": {
        "max": 1,
        "address": "0x1A088A9B30339a2509537ae4971239B125Cf60a4",
        "signature": "0xc0ad2c48ce8fdf0c891d7b18fb71e34fe341b667abd5d1f55f4721409c90743e18592f258bcb9a3637e3a7b1326d7ebe00c784d7b1133dcceb3e05173f1a43a91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeE574ddB70F4838e7aA39F18549124c7bA3270Aa": {
        "max": 1,
        "address": "0xeE574ddB70F4838e7aA39F18549124c7bA3270Aa",
        "signature": "0x68c183c411310e8b3b0d2fe14b4f02c5e8724c96ae3463c0b43adf76b2740da32f7f56086b5785c76715ce2e8bf6f794ecb987bed873234115c3f7a4f25fc2771c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x90fD4033564eF7791d0039CD7CD754725C759249": {
        "max": 1,
        "address": "0x90fD4033564eF7791d0039CD7CD754725C759249",
        "signature": "0xbc4b03096eede14b7caae4c7d5ca9638dae3439d32a3e6da0db4a58eddfc579a27c46f4ddb7acb2c9e4f72443177fcd66fd685e0b0c1489bcb20eadbafaabf0a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF98FCD2fB62905DF863fe06e6dF391B0C58F3830": {
        "max": 1,
        "address": "0xF98FCD2fB62905DF863fe06e6dF391B0C58F3830",
        "signature": "0x8da947dcac0918eec1b84f2dea9c2bab4d74846ecfceffcc938b536cbf375f5523be6bd46242e5656be24f471e3381dfe7293e1e6db3c6d0165a98c229564c381c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD5CAb2e9163eAa3814D4fb7297f8F599Ad118E1a": {
        "max": 1,
        "address": "0xD5CAb2e9163eAa3814D4fb7297f8F599Ad118E1a",
        "signature": "0xa9ccc4885829738a9edec5f2d445a8f4e7bef7bb50db6f307adcc36f5c7f8b6f6d86a40e0e895028fa30d5f4a6d3feaa5b4f089dad3e7c94e2cceed22241e4bf1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8BcFA94392e26E169DD3d82dbaf54b6b776DE690": {
        "max": 1,
        "address": "0x8BcFA94392e26E169DD3d82dbaf54b6b776DE690",
        "signature": "0x5070e41e9e023d662672094a5cce06a58126f3c01c06170354d581d5f7b0f3c47fedef907582b03245d4038845c23e3a454ced7c3cd7b103107f4d2fef7582fd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe13A01c7988552047c6EdD31a0C7FbD6445df680": {
        "max": 1,
        "address": "0xe13A01c7988552047c6EdD31a0C7FbD6445df680",
        "signature": "0xf4eca307ed6ce978054a729a4dd0993b3cc8cd81b601a6cf2cb42250b8024fe0546eaa7f0753768ab3f5ae89ed594c20d46c43d2c0ee7a489d119855bf5b2cff1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEFD4C7dD015CfF1dA4f006610c57e25a4aDbe062": {
        "max": 1,
        "address": "0xEFD4C7dD015CfF1dA4f006610c57e25a4aDbe062",
        "signature": "0x52eafa014efd5a1e383c741302ec97b15d7ebfb440ac2948e13e4280255379a46178bd92083932552d481db2dc7f488c686cf9de760137edeb507df382d3dea51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6DDbd4D2ccA1Af584e9C2c6855e873662F9473e2": {
        "max": 1,
        "address": "0x6DDbd4D2ccA1Af584e9C2c6855e873662F9473e2",
        "signature": "0xffc3ad603311b679d7740293359a34fc002c371983b2181a31f49e7620b1768c5be0ffbfeed96097ebe081173f3b2b58ef3d530445f95dcdfaceb4e0e19c88731c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1f2Cf5BF0ED256B93e430A6C7E99a9721FF1B994": {
        "max": 1,
        "address": "0x1f2Cf5BF0ED256B93e430A6C7E99a9721FF1B994",
        "signature": "0x332fd6a0ff2532a76e6ab3fe64eee46a8b1e8e53274e228a3d2b2d094935d24057bc5b991741c15e394a09d7bf1348fa4e6e61907ec405c5b8e1d7743cb97af41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x23D47F6AB95504bDC34704c99D202b9ae2aA2897": {
        "max": 1,
        "address": "0x23D47F6AB95504bDC34704c99D202b9ae2aA2897",
        "signature": "0x812bce3bf6ea1f5609b2f82a2d564b83863ff7485536af7b476349c5266ce081390118aff08b8b2b4a524fa48ffa3a28f6bb9b4ef96dedb69ffcfcd8256d58a01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1025d2eAA75E780306061bC48073cEB0ec6Fea00": {
        "max": 1,
        "address": "0x1025d2eAA75E780306061bC48073cEB0ec6Fea00",
        "signature": "0xc8b0fc9f8c78ec9803ff3e5fb1f176c4996e229949a97a8333a3ce8a246188352e768c4173e7c3f6ad1f0edfe6d401c1f83bdd165ada66ac747773a1354511fc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x488139eB8F7A5f789766213A9b37D89053CDFDd1": {
        "max": 1,
        "address": "0x488139eB8F7A5f789766213A9b37D89053CDFDd1",
        "signature": "0x41f5787b6c608de7bf53950ede8e4f78215a97fb4f7aa0413c706d2e1cdc4dba182dc3f884f7315592addc4e804f26320b904acdcbd31213aa376a3c956f85fc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFB818b8B01F969450c4038d2bA8393e862217904": {
        "max": 1,
        "address": "0xFB818b8B01F969450c4038d2bA8393e862217904",
        "signature": "0x8deab9cd3ebd51725f6c59c5d1c1631525bbb4b8af75f24fd89c8557a944dfa24371857b655bd1043370f5d41a1c656a14c86c04d2fb8dd7082aa51525fe2e621b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF1BBD3975FeeB8E1D609C29e1A022CBfB0211678": {
        "max": 1,
        "address": "0xF1BBD3975FeeB8E1D609C29e1A022CBfB0211678",
        "signature": "0xccaf4bd8d12571f379cd8f36357ca2c6e548bf6f6c4abf0abb4580f58f5b8ab941827c01a63661c8456f75802341f979bba7ef7f241e0d0eb39ee3aa8afffc2b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x38118e79E96852121Ab4C7d067B648B34E0AAc88": {
        "max": 1,
        "address": "0x38118e79E96852121Ab4C7d067B648B34E0AAc88",
        "signature": "0x5c3cc9fce7432813277c049f4c87d8cad070208f7befc2b36d3a3a87cdf2364a6bc16854abe183e05a92c282a4dd55244e5f3f6d3bd9100e7e3a23ba596f98431c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc180a5550BD3EAbd39E591429C34a8445F8e94D3": {
        "max": 1,
        "address": "0xc180a5550BD3EAbd39E591429C34a8445F8e94D3",
        "signature": "0x7f93b6ec9312eb695f4260e3ec797d4a95633d5de54b114964821f8cb685f49c33c304afb7d6a7f83e769395f7554629fca2c8eee8df12cb2b74c54c735666eb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xED00AacE17142BAF73Eb8FF727649724BD48389E": {
        "max": 1,
        "address": "0xED00AacE17142BAF73Eb8FF727649724BD48389E",
        "signature": "0xb237f2571b2d9ad34bbc0c5367aa4aa5fc9078b7756f57ae90b49c9ceb30a76c5767173b12dc1741beac00898996d739d5f4545e880b91a968e12e06180e48d91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x99152812192C474C50d6f36324D9e7772a777913": {
        "max": 1,
        "address": "0x99152812192C474C50d6f36324D9e7772a777913",
        "signature": "0xf2b388905c03ea8e5908d5e3083213c94fbd50a6db07ddca139782d647c7dad63d30d955b860810f90bfd658f2ebf43b6e778b5fcbcc5b1bd56aa1f2bcb28fef1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x359D04F7D4eC662D1a69ef0CaA5ef40C6DCAf50b": {
        "max": 1,
        "address": "0x359D04F7D4eC662D1a69ef0CaA5ef40C6DCAf50b",
        "signature": "0x0d0cc28fc8f9676e51d6f8be73a5ce9061ea6974675abb9f2cfa319ae2d4eacf29fcd5669516f18ccd06a53e15ed9605b70deaa6a36713d7482c8f76a15c40fa1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x16Db4bc82F2973F45ec7f9fe1e0672100CFaf665": {
        "max": 1,
        "address": "0x16Db4bc82F2973F45ec7f9fe1e0672100CFaf665",
        "signature": "0x88097e73374eda9aeab9a6904ca9322a20c893a7225429c1ac4e63488cd2c1e24c9a74eb70b414d994f1255e0fbf7fdfc7972ecb6aeaae637dc9a5a1a87a35bf1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x13fa29d0C52F0d6dC42ad5dC64b9e0F1423D0273": {
        "max": 1,
        "address": "0x13fa29d0C52F0d6dC42ad5dC64b9e0F1423D0273",
        "signature": "0x40a9eb69fd3a652b13b52577a67b7274d2c9124c137f97224f844dc00c0fe66b1e8b932eb0045107800ffc13e85bef4220c1b08853997e421e9bdfb80809f5451b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB46731D586647fc31E0a2DE4e22a207624996fe4": {
        "max": 1,
        "address": "0xB46731D586647fc31E0a2DE4e22a207624996fe4",
        "signature": "0x561c31856432b5b46936fb7d1f337384737f3785b508aa33a82972385869e84b3a062064cd3b16588ae2e5a0935c7ab04bbc4e396262b5d8bef45b910219c9f71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE7Cf7EF5e60FAE2B451FF3Bf457d8E74d85Ded92": {
        "max": 1,
        "address": "0xE7Cf7EF5e60FAE2B451FF3Bf457d8E74d85Ded92",
        "signature": "0xfe2043e6e5e17408d250925738403200d4a20fed97d0f28838b5f4613ee8477d54bbf08b2a516278cc896034a0ec1826fc1800f8389ee425c819ec06c5a6960e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8fe6c5f26a3A2d283b3Af6B8f73D62BC01fcfb1E": {
        "max": 1,
        "address": "0x8fe6c5f26a3A2d283b3Af6B8f73D62BC01fcfb1E",
        "signature": "0x2ab04b9ea68f43ed8808cb22c23f9289c06c5559cf8bc5223dab2b1720c2ed7658d8e6232286f539770032b258eb7fb0ebf560da3f9128ea21d276d4e21ba19c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x34a0bD0ac049a36a6537503CFD7181BA51af9418": {
        "max": 1,
        "address": "0x34a0bD0ac049a36a6537503CFD7181BA51af9418",
        "signature": "0xa9976bf6585eb4fbc966bd25d882b724e3523f84c72f76f1d7cabede56e9e0e33e3ff1c422ee4dbcd2366150b90055c319892819c10bbb8ce2cca31bd8d4fcbd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1fD5D24B832f35683b4fd46C0c6f340F1155517d": {
        "max": 1,
        "address": "0x1fD5D24B832f35683b4fd46C0c6f340F1155517d",
        "signature": "0x9fe04fb1386e5298a467cc829be5d03bc8ca0744f7bc3bc535caf7c0795d4b226433ff99cdd7620b0b54b65ee87bbe580f6ac9275f5fdc6e473a175d95cf246b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x94bD4722E64786b3Becc30919F77562F00074cce": {
        "max": 1,
        "address": "0x94bD4722E64786b3Becc30919F77562F00074cce",
        "signature": "0xfbb3b2610464fdfab5db7dd948ca193e31050333bfc4693eb13a7da578f980ed4f19a0604e2aad159784cceca3fbe7425ddb2af52f86fe235c81a7fee0792ad61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x14Ea562ff035b1814189f1A31FA6717Fdb1F5921": {
        "max": 1,
        "address": "0x14Ea562ff035b1814189f1A31FA6717Fdb1F5921",
        "signature": "0x911eefd840e9770c909af1ca89eeb5a66114458e3be56e7592ae8b55d72cece11f1a6945145772a5df2ff9ea676028bc02091977e6f6526dff0e3aed022fd7d21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x910915b4EF4B48737b786E5f279124ba2D088f4A": {
        "max": 1,
        "address": "0x910915b4EF4B48737b786E5f279124ba2D088f4A",
        "signature": "0x632c3405ec89df6f351c72e88f8e4ffae83f4d07dc24ee375dd05a4affff4c893094dee8eac11713efded71f5381c644ea35e0652239d07bcda891570dc920df1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2DadB58a95fE4A79583a086bcd928379d21A5ddd": {
        "max": 1,
        "address": "0x2DadB58a95fE4A79583a086bcd928379d21A5ddd",
        "signature": "0x40c46a4e661faa53a1cdbe7d9aa782b9bd3e920c6c0a07db3010b2220eee8ba450a848eead5d04d3bbcb1ba1db26e1f7bed339a6cb909d743947d6deb11603721b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5aff73AD2520898Aa93F42a2A601F9a2f33078C1": {
        "max": 1,
        "address": "0x5aff73AD2520898Aa93F42a2A601F9a2f33078C1",
        "signature": "0x87dba47d675599b88c2896ec482c477211a30e53937d3e8e0fc86a6ae19edb972b46e810c9782136d98a2adc67f8c7e1906bd4038692ee0e17973f9cc69b514b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x69c0E38b72b7ea3dAA6F8C519e7b6e5F6754FD74": {
        "max": 1,
        "address": "0x69c0E38b72b7ea3dAA6F8C519e7b6e5F6754FD74",
        "signature": "0x3b8314afdde2075350583ce15247b8fdc6040575e5b09e228352cceb453f501b1ddb67841f6b8d82044c95eb454033ac1c511396a477f3e970d1ba647e9f21e21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAb95a0Fb53df696A492A93f30FA126bDE2493043": {
        "max": 1,
        "address": "0xAb95a0Fb53df696A492A93f30FA126bDE2493043",
        "signature": "0x7822156886d1ceb862c273188f0ea6523bf479e56be3b3779fd7cdcb0c78ea5b15251bc344be5b67e729ceb45850381bc6fa889fbdc5c0af2020e591341e35e11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5F93BB4Db36875C18F1A81984D22f7e9dD8a3898": {
        "max": 1,
        "address": "0x5F93BB4Db36875C18F1A81984D22f7e9dD8a3898",
        "signature": "0xc02ca3d50d786b90898fbef10dc21ffd78d98d0f711cebec859304bdd438050508b5983ab6d142282c067bb95894f51256ad4d6f71655b10a1ef29cb54b3240d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd69ae6Dd8eE3AE6d430570e5a79496eC98313E69": {
        "max": 1,
        "address": "0xd69ae6Dd8eE3AE6d430570e5a79496eC98313E69",
        "signature": "0x533239b9ab0dab046082684d5e46861f5f346d0b6f568f40f45908e6b88251ef667c7c65a65211a1f978a5bdd163fcdb1d2c2557c62cb3b79d8925e4b74e2b8f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x176B113D6DAf61869444148A7DD2f222EfEF2097": {
        "max": 1,
        "address": "0x176B113D6DAf61869444148A7DD2f222EfEF2097",
        "signature": "0xd8c771cf1c56e474d17587400e42cf8094313b168b28a6610f6ac1413d7cbf154242f806e92fefcac51e29bb1e8707da117cda9e14bbc02c9e6d3ecc3921683a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x136a4201199B1E75eCDF9aBEC5E41Eb84183406b": {
        "max": 1,
        "address": "0x136a4201199B1E75eCDF9aBEC5E41Eb84183406b",
        "signature": "0xfdd55d684b5607935f352bf27b43a83f13df13cdc0c43909e832686d64820d42433e7d777766a4968cb46931831780fa5940688caed7d9622234f6c11419d8171c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x94064533b245aCEa8c76612763E5bD222b233716": {
        "max": 1,
        "address": "0x94064533b245aCEa8c76612763E5bD222b233716",
        "signature": "0xa75463200742d71a1e7b07890589b2a68e04314bb73406e2fbc2e7d1d78cbc7e67897e71b1bfe8082d6249085b3ff12c5b7d36aaf6348d02323a31a3757940b41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc336239F76d07530677F4C7fA1299D966C361166": {
        "max": 1,
        "address": "0xc336239F76d07530677F4C7fA1299D966C361166",
        "signature": "0x74d748d2429323af29b73367dc03af67c416bf8face183f9944aa746f3d5da026062a47fc626a3698751b933781e12ec3c2cf7e61a266be4c32b4138bc6835251c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa41E160BE4327Ec0E286348A12fb127Ca6Ea0029": {
        "max": 1,
        "address": "0xa41E160BE4327Ec0E286348A12fb127Ca6Ea0029",
        "signature": "0x7f9c107e0679b14efd1961f5834e7d0e260c0bce21afcc1a369e2fb341b062553a3b954712b633ddafba97b253355118c759ac2f6637542e885a38427242f5281b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEbEd0BC2d689b179512efdf65E7bF16D264d7aEE": {
        "max": 1,
        "address": "0xEbEd0BC2d689b179512efdf65E7bF16D264d7aEE",
        "signature": "0xebe04a110b280f04a18e93cd8ce4f3fd7134c339703600d8db8c9a4680e456e61930c4a382e4c7eda163809b9aee0f11cc852b0b2a779f9140f181f1031146581c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdb10453283D1F1Aa7FCa16039fdC3B068b75ea38": {
        "max": 1,
        "address": "0xdb10453283D1F1Aa7FCa16039fdC3B068b75ea38",
        "signature": "0x0c962ea63c776a7c360a0a3ab7be5afabbafb88e6003c36c9ddf22f3619c0e2007507bbea1a45c0176de9d95715153da7a51f47ba7f2312334654a030400a3341c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBb6f4CFC66356b64bB6b05Ac5e36b1FF76471054": {
        "max": 1,
        "address": "0xBb6f4CFC66356b64bB6b05Ac5e36b1FF76471054",
        "signature": "0x751649c97b600d2516f3de84158edbe6067e8ac926305f2414392b9f62adeaaf322bee6b5258476882bc279b9e6248a112ceff4d1c3f995c067e7d01a0a6b6431b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x783964b031c215298672C3EF4503fbaaF51aaafC": {
        "max": 1,
        "address": "0x783964b031c215298672C3EF4503fbaaF51aaafC",
        "signature": "0x61739ba4a9246b7ad859ea4844e8ea4b95119ee44850dedf1bf766c45949aa9a10c127df6bbe26faf7b88c95887d1c9814651d96bb26490ed1330e0a619278131c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb8a9E697757b3767047e27c9B1cDAA2A2eF0C0D8": {
        "max": 1,
        "address": "0xb8a9E697757b3767047e27c9B1cDAA2A2eF0C0D8",
        "signature": "0xfced9b97f4e0db8b5c91208334f8fd392f65ceb3dc0981935d80a81ff6892ea468803e38ba7b4e315c35a324ad798af7ee983708707e8a37af8e9c5a095d61071b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9bC5536b6EDD37fCF15f5E44e0a56C68397f5CEf": {
        "max": 1,
        "address": "0x9bC5536b6EDD37fCF15f5E44e0a56C68397f5CEf",
        "signature": "0x58c28569b19cc40ad602ca192062b1dfa05aba7b9c41ee2f90368f55bbf828d96c192376c94746206af5bc547eb5921f1e7f6c5eab2965924ad72dcab525b8cd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x57fBe4e3E478a1e27E0FD6242f757453138e28ab": {
        "max": 1,
        "address": "0x57fBe4e3E478a1e27E0FD6242f757453138e28ab",
        "signature": "0x727945d6289f1afaa948f8f2c92e2f708822a9a98e69b6ed4700e2c9dcb954152206d15f2dda1888efe9d471e6576064d4792e1851321e72a12d985ba033f8fe1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaa419d1331d6932ba23188C40eBF039aba931D96": {
        "max": 1,
        "address": "0xaa419d1331d6932ba23188C40eBF039aba931D96",
        "signature": "0x7d372c81c3ed32336b0ce112da03f09b80afc923281da7bb3dc5d223eabc22ba3740c36f1a5b757dbf4fe19b7c9e7eed45e5c3ecf15ccb99f56435441f2cbca21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE09Ed115ff72De999B8748D8F3914EeE88Fe14C5": {
        "max": 1,
        "address": "0xE09Ed115ff72De999B8748D8F3914EeE88Fe14C5",
        "signature": "0x57d3f7296a9388d902c10e33baaf2eae623ef2ac1f7994b8e174385032710766451e3759c0f2ea20eb9ebd35fe6e341be2e9a0abe8c0aaea40a0f327e20da3671c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3E4f7448f272dAa612235d348340D06C4988DA18": {
        "max": 1,
        "address": "0x3E4f7448f272dAa612235d348340D06C4988DA18",
        "signature": "0x872ed6e6996dd2265d15eccf3f0e7c6a7a7b46686bd071a5ddb41da4cfa6b89e106d8e38a977bfc748a21ce69df4176aa9d0e34e7f7ff7ba7ca7c78e57e1e67a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1b0b416Ab0540bA7CA143DA93DD5BBD766ee1103": {
        "max": 1,
        "address": "0x1b0b416Ab0540bA7CA143DA93DD5BBD766ee1103",
        "signature": "0x278866f52171e51d4c489161662ca51ab98b952d9e839fe654b782e2f95e5a7d68ed9c1500ee9a13795e752cda2db6f26393bcbbddaa3e5f7a47dc9fbf7e3b6d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x63d68173F546B07bCfF6b2d36D747cAb122dee4D": {
        "max": 1,
        "address": "0x63d68173F546B07bCfF6b2d36D747cAb122dee4D",
        "signature": "0xe0cda85175fa87114c35a8d4b9840cfa21bb1091e37b912bb42427d87ca0822a048d290dc2d55583aebd917ae67c42ece37844b6925e0287fb3024c4d56df5b91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x971a2E20Dd06bE464AB6211FcDA7aA28a616520b": {
        "max": 1,
        "address": "0x971a2E20Dd06bE464AB6211FcDA7aA28a616520b",
        "signature": "0xae8ab1877c386efc63d0b35be238d4befa4cd7b783361950145237c9f47602270f1fde621eba13c253d3b7b927019dadb7bc3663d8646d6aca35251b66d1ef3d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x25A8DECC507fb692831D75017Fc98E7933e5592D": {
        "max": 1,
        "address": "0x25A8DECC507fb692831D75017Fc98E7933e5592D",
        "signature": "0x5c2b150f321ac574d5af84efc6c77b00e972bc309156247112530cdb52259ab16ee1957bcd18de488d4cb7dfc3a0b23c3e2878bc54b7e088323dd71fae09b24a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x120dEB222124899a1351C994662669ed798EA614": {
        "max": 1,
        "address": "0x120dEB222124899a1351C994662669ed798EA614",
        "signature": "0xdc46fc386c72fa14bdce9273c40c2832cd37603846fbbe8bb268f76ecd536a334ef6a05aeaad9c8b457f376e7f83134c791a3604de0aef3e44ba70a41711d46c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xCEE098ad89eA73583262A5143D67D9e2F94121bc": {
        "max": 1,
        "address": "0xCEE098ad89eA73583262A5143D67D9e2F94121bc",
        "signature": "0x9a42a976dad5882a9025ed37aeeedc494dfb8605747cd11cba5e4f32528a43205b2d054184b1478146ec313476507ae13e98223543d12db3b7b7a1a534dbbc5a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x730a2B599580492ee02CBB9CfD4C0424c67377D9": {
        "max": 1,
        "address": "0x730a2B599580492ee02CBB9CfD4C0424c67377D9",
        "signature": "0x6917eedfaa89afaae3e34d423f60a5029c13cd6c2be4c50eda2f9bda79ef0d7c2136a04bd49fa468f26b1878ff264639df1a7bbb2ebbc55136edd7a55f4152b81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcEa0Ee639b96BD0110f60fDd50A66EAbA9c5CC1c": {
        "max": 1,
        "address": "0xcEa0Ee639b96BD0110f60fDd50A66EAbA9c5CC1c",
        "signature": "0x23bf0f71203dcf349e2c01cc8495f2628493ad6a56d0a36bc4e113e3423a059e7ad14c62ea8012cae4fb9084ff117bb966c541e1a755bdfc770580ed201119e41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF833448EEA1d019dac0aa3675EA9dc723F1f49F8": {
        "max": 1,
        "address": "0xF833448EEA1d019dac0aa3675EA9dc723F1f49F8",
        "signature": "0xe3478922d565c4da46b9e166a87dbf5c4b41fcea84afc889083d66b6b4e2926a3afd357b9423e73e7aebd413c12d93a4c39289d0c0994223b5d25e242805aa661b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe6069d4A8a9a7b32539DC8C5D3A557dDa6F3a832": {
        "max": 1,
        "address": "0xe6069d4A8a9a7b32539DC8C5D3A557dDa6F3a832",
        "signature": "0x691ed706c317343295d72dac3ebe323eea7aed7300b66f45e2a0e5ab136289c47bda882bd4582faa4adbe146d162f72d680f8d914d76c056136dc1e7f839007f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x106b1d3E1dAF0b6Da0D4c86C23900980bFc92Ce7": {
        "max": 1,
        "address": "0x106b1d3E1dAF0b6Da0D4c86C23900980bFc92Ce7",
        "signature": "0xe313196874168b169dcbe559641f116d50060a0aa5519b62bf696bc7fe56270342e7f22c900593b5107f964104a419c951f041d7e3fe90cc744275192e9c4a9b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9F12842ad1F0B5d39C31515Ec9Baa96CD3857236": {
        "max": 1,
        "address": "0x9F12842ad1F0B5d39C31515Ec9Baa96CD3857236",
        "signature": "0x7f6b74a0bb848025ce48cc5f4e4c894b576709d239475244b22a6c0a85db28d05fa82371bec3c41d29dbb5ac43d7f0234ba2c773d540fd0871e4209cab6e75061c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x40a0fD29243c0fFF7Dd8dbc17823373266b6302C": {
        "max": 1,
        "address": "0x40a0fD29243c0fFF7Dd8dbc17823373266b6302C",
        "signature": "0x01495c56b70b1958d3820b5bb816153307efb8d5f5399082440eea8e85fcd28821970aad0f6bf4e775746fbb85de51a78f4c0d7b126e5acf78b29a47be1f5dea1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA8b047460EFB9B8F99dbfc085981A261730d7065": {
        "max": 1,
        "address": "0xA8b047460EFB9B8F99dbfc085981A261730d7065",
        "signature": "0xf9bf56305e8684e98f756d5d8feb961783f67d36172bc26139474d068814de1b0b5b609518462c7a307d9e0377c423d5265c86e1414221968436413ce08e5d071b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE35A4110749dd479b500472E5f95B5812E04dCb2": {
        "max": 1,
        "address": "0xE35A4110749dd479b500472E5f95B5812E04dCb2",
        "signature": "0xf299ef1f085b0b2dcdbb02c60cee40592ee59244b977ce49eff9a80686d2de72568c3bbca57b63757158d147a6a97d06cb6ba88a9fb402b726f131355528a6261c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x038dB6bdE9581754039A3e87482bbf790d7053CA": {
        "max": 1,
        "address": "0x038dB6bdE9581754039A3e87482bbf790d7053CA",
        "signature": "0x8f9439fd24b74d7139028089517d77080e909cbac86650664225a1ceb28f800c257490ed21829c63fc231240eb0d4f4ac4b8791aa44364133d3dbff41e12217b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDd17c00Ca57a836B521744196F5a8286BB061073": {
        "max": 1,
        "address": "0xDd17c00Ca57a836B521744196F5a8286BB061073",
        "signature": "0x84aa6558c209b47cd7a35a4b86073e4ab3bfbae3aa9573f3441ec6d73e62112d6f16ff05a1b76968d338c3627340781b251f7a0dd4087d5342d556dc306340311c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4197Ba6e9dAfC3C8bac60E58BBc2E4F0cDE6566e": {
        "max": 1,
        "address": "0x4197Ba6e9dAfC3C8bac60E58BBc2E4F0cDE6566e",
        "signature": "0xcc3ad6589feefe3a23d4f0a10825c3e080647a72d2cec2609b3b182c6bad38ef0059738de2572549b6517a32a884e986148070104d62b5e7e98787c6d9797af21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb549cF0D11ba35c32c52fc15f090B56fFe9f214A": {
        "max": 1,
        "address": "0xb549cF0D11ba35c32c52fc15f090B56fFe9f214A",
        "signature": "0xe91ef40bc620b911e28f651f64ae6922766d54713dce45bd0a79b9631410ecfe2d70e48b068104cd5afc0e078e67823458280e04b75510f30e4e5a5cb06445bd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF83ab9262EC834e9Da9b79e08FE443F90c2C5fB1": {
        "max": 1,
        "address": "0xF83ab9262EC834e9Da9b79e08FE443F90c2C5fB1",
        "signature": "0x56af378c53abd2cf453fb38cb22965803b00036d5b3f9d32437f49c909a0467d26ff680fa773d112889061fee7c808a0e9df0ff2748cdb75e3a55cd445facadb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8b7dF4e6762b8EFBEfd38B1d3166D1CF83a46B99": {
        "max": 1,
        "address": "0x8b7dF4e6762b8EFBEfd38B1d3166D1CF83a46B99",
        "signature": "0x9ef0438637f7602c7ef271aac90b8c2de3b17a3e581ed79b4250a8c49c2586dc5118933984c9ccf8749477a315dd930b93273d68e8c9df631735d85414e842671b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBFe2F34Bc286E9e5EaC80F4BF968F91690BCDd5F": {
        "max": 1,
        "address": "0xBFe2F34Bc286E9e5EaC80F4BF968F91690BCDd5F",
        "signature": "0x0aaff4bc792460095c414567747adf172b9ac2c2f958e6c39866887e7b83c1b5256cbfe713b87b45a8318f947af713a257d928c15f507c442afd95510c0caeb51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE9F0e4dc7459F2Ef89C75a8b70F952F9211C52FE": {
        "max": 1,
        "address": "0xE9F0e4dc7459F2Ef89C75a8b70F952F9211C52FE",
        "signature": "0xf3652df1707c5a9c1f5b3e4d8469ec5054fde0e8014b9efcccc9f031e56e1ce9504a9fcfdb45bc7480dabbe2be279edd4d69ae7dcb91ab21d328c67ae394ceb71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8c1F0009A378f392987Eee9fD49dD0E86704b55C": {
        "max": 1,
        "address": "0x8c1F0009A378f392987Eee9fD49dD0E86704b55C",
        "signature": "0x2c94ac820d0e329cf4224c4f9960159b3b94341cc45ecdcb85f1a52b3fc40b96541feb3b8cd2b7258db0235227a747c28c0cb7f0d48c110b24747b0e4ff9f8dd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x07E995dA69985141FA8bb28264eFB27BD353719B": {
        "max": 1,
        "address": "0x07E995dA69985141FA8bb28264eFB27BD353719B",
        "signature": "0xade757cfd181fa546667f74bf79d6e2652fb60b9b5da8370ed6701120ca2e2f43f4a89451c2ed7962e382083389a30332b0fc197ae48f5c8ea6b451b68571c931b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD985A8e6AAD678c99384f13b707c400B9Cc95dD4": {
        "max": 1,
        "address": "0xD985A8e6AAD678c99384f13b707c400B9Cc95dD4",
        "signature": "0x9166c2691eb7627411f5c2a1f6b1f4cfec649f7e8e153cbed5b2747363bccf431ffa461c76d8dc03021a64c4199bee06940413c27594fce281225ae6cf37b3621c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa00b9dc08f46e0E68f48CD52C86CCf2B08EC0D77": {
        "max": 1,
        "address": "0xa00b9dc08f46e0E68f48CD52C86CCf2B08EC0D77",
        "signature": "0x469735fe0449228f7dff28fff5250bae21d5897072ddf7abef75b6cca58d7bbb4af9ef1c1853a9c5062538b9463e9a69abb6d3721ff160d0e0a42276e4ddf5071b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9dD81336766bF69add9006Fd84Bc30B9F5EceB38": {
        "max": 1,
        "address": "0x9dD81336766bF69add9006Fd84Bc30B9F5EceB38",
        "signature": "0x1374066181c2e6c092cd4649cd45dcb9a87e4c38fb39d17b5915de8463862c44013fd462f7f53fce59cc688b54c80cf1b19c1390f0e137458c8066e5ac5dfe971c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x62a2bF7BeDeFD35e9153aE5655b1Dc853D51A960": {
        "max": 1,
        "address": "0x62a2bF7BeDeFD35e9153aE5655b1Dc853D51A960",
        "signature": "0x650111f7fd9161a0c12c288be582f6722c95ddf7ae3f86fad8c6c21a302ff60160053efcaf636fee9afb1a728714914af635d8af7dc8c777b548a3b4196eddf61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x330Bce303d27Df0eb1b856Da3464278DB1DB1aC5": {
        "max": 1,
        "address": "0x330Bce303d27Df0eb1b856Da3464278DB1DB1aC5",
        "signature": "0x538564b939467366e927544f9370cb5d8811d1b679830a05489a4a0319c6326d33a6a05bddebab7f7d4d6193ca26abff507300328b7748abbf084f5ff4688ac51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x88652A4dEA091464BF4f8EC200CbD27c5b73890f": {
        "max": 1,
        "address": "0x88652A4dEA091464BF4f8EC200CbD27c5b73890f",
        "signature": "0x52d4fdc99d0505bf78b2a2b6627b3faca21c792922f749fe798cbb8b30d6eca300a335490618e7e509f5acef852d8de6739a42d5c2882c54adf604a7b85ef3c71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x78272D01168dcBCCd0F610DF27141883f1bCE7f0": {
        "max": 1,
        "address": "0x78272D01168dcBCCd0F610DF27141883f1bCE7f0",
        "signature": "0x26d277515fa409741c19bfd818214bb93fb8bc34cd5cb222cd326d0b49e40ceb22966644e89dba4224be0ec7f2fb35a495f18d8d096211d21fe8733f9da88e6d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x023Cd4062b82Db1EEcC91Fe077Ec944d4b89384a": {
        "max": 1,
        "address": "0x023Cd4062b82Db1EEcC91Fe077Ec944d4b89384a",
        "signature": "0xaf437a41ed618264881d7a93be65060a3ea447df462512367424fb4ea8fbd6b07c682fd94177502c778d3a6cc39021327b69687757e058fac80fd7e23f0c012b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x93B3B4D88EcaB2dd9C038e145a47aF08A2cE0ACf": {
        "max": 1,
        "address": "0x93B3B4D88EcaB2dd9C038e145a47aF08A2cE0ACf",
        "signature": "0x77ae0afebc4aef3d2b8982118ca6c9d493a95614f8cf95a15ae45add7f1b661732aa33c44b49f27098af1d539dd728b8740c3638dfb35fc6e6dbd078253937741b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6B5e85DACC1f14137d74A22C3d2af711f2Deed8D": {
        "max": 1,
        "address": "0x6B5e85DACC1f14137d74A22C3d2af711f2Deed8D",
        "signature": "0xea75219fdd87966da51b5041c247b534cc8148514256659162c17c3adf1f875e23deed5f6f38eb2401916381cfbaa8968e9a0b566a6340b2d82a42d0001ab8cd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa53B43a9BDAAFC650b75d9C5FaC53B1c1E96B890": {
        "max": 1,
        "address": "0xa53B43a9BDAAFC650b75d9C5FaC53B1c1E96B890",
        "signature": "0x35f044b1f781de828fa175c029712d5aa02b1eab6c9923b05520fa560f51f517625d88a15357f6489588c85f6eb02075019f197d3413eda25e914375cad930311c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x78B5F58cBDbC6fb9648e5C3f430296d18Dec906B": {
        "max": 1,
        "address": "0x78B5F58cBDbC6fb9648e5C3f430296d18Dec906B",
        "signature": "0xc51a12b7f2b83349ab8726ea8a5edaa66b295c95b2caa0af781724a926ad09c50f344c19804bad4b158b5f03f8cc08c329fb985fe1bc3f3190ab859c42547c921c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9B8A5FcB9a949a75Ed9B80a87C57df1D0B5cDcbd": {
        "max": 1,
        "address": "0x9B8A5FcB9a949a75Ed9B80a87C57df1D0B5cDcbd",
        "signature": "0xa80cc6170c18d30e796fc2d17568c7c9276d47e7be09b97bbd6b6ec747968f694a6f1f8d5aa282f9cf7599ce7bc6cc0fa2bdc268197602f787f32fa47f98f8971c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8Ab3056507380D39607eA9A0122148d60d708f6c": {
        "max": 1,
        "address": "0x8Ab3056507380D39607eA9A0122148d60d708f6c",
        "signature": "0x9123bdb3e4e35d491e9a1af8d623299b8eae43ade50c87e9f131be308c1fdda8317d13f236b785c3ee9016055c6a22af4eb54eeb00b54d0f3dc9fbba32684bf91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb319d12998ff12E30fD10A64913486cAee97A6ee": {
        "max": 1,
        "address": "0xb319d12998ff12E30fD10A64913486cAee97A6ee",
        "signature": "0x5ec56968f1f714af7ef80e52be0eb8e4e6b2d5d769cf5c85db525646c1769433689a4a1cee3e417817e36e9189e77c54b0cf45ca3db6d6766249980bea3b1fa61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4B714D0c5518871E5Ea3A4B63Dc2c38046c3718A": {
        "max": 1,
        "address": "0x4B714D0c5518871E5Ea3A4B63Dc2c38046c3718A",
        "signature": "0x1344d6f811a2de22763ecc762def96032dae351a985a9a195c34e810ffa687d54800df2edf07af0a9732af2dc72fba2d18c8e84fd34c16bc71a76065b1611d791b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6eC6099EC7F5F6AB89e9b544A4961b69d1e8F4D2": {
        "max": 1,
        "address": "0x6eC6099EC7F5F6AB89e9b544A4961b69d1e8F4D2",
        "signature": "0x619f06ab64d80bf3e77108ac75d1944c5a13b318818578d91be283a27dc593d930deac757272e533891d37ee027538e1c213c923048c336c53a97c43654610181b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa22f582E83b0f5aFb027Bc255C09F8564f56916e": {
        "max": 1,
        "address": "0xa22f582E83b0f5aFb027Bc255C09F8564f56916e",
        "signature": "0x46e3a754bc56d5993155561892b21ec4aed1ac027f996f42962b36e30f894ea71657bcaa0c38f7e9211765be7bfa90cbe3deef8c8bb389c2be5aaad1221da7711c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3b7061EBD73394F1d3F267C6087D4bed71a61Ec1": {
        "max": 1,
        "address": "0x3b7061EBD73394F1d3F267C6087D4bed71a61Ec1",
        "signature": "0x70cb5880799382ff3dadcc3f87614fa299a551872c8dfb84743cd572979e21b5487c765b43f1e7b532f8b4e8ca9df628d73592b7d6eeefabe884a6fa93fd541f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6EbBE71a30A9AEC065DE30F4789C7e98f0843cD2": {
        "max": 1,
        "address": "0x6EbBE71a30A9AEC065DE30F4789C7e98f0843cD2",
        "signature": "0x6022eeda4549e50b297ea671be9be18efbed058bdee6e1a78a797272129146701551d626aa19287ad3dbc71b0c0bfb5ac23cd7855adddd9cae7e00ee522174381b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc452F25D51acc057EBfA7A51c280AAE2EaAd1AA9": {
        "max": 1,
        "address": "0xc452F25D51acc057EBfA7A51c280AAE2EaAd1AA9",
        "signature": "0xd6c5934e64e4342e38e4e4e8a89ff0119a19c0eb35b6640f8b6fa8a989aa8c2c12550f5e9af59cbfd37f231e42c002c0798d721500364dc910cacdf6858074601c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1cbEAbf2E1E81491CD9cf0993d5eb4ea338a8c74": {
        "max": 1,
        "address": "0x1cbEAbf2E1E81491CD9cf0993d5eb4ea338a8c74",
        "signature": "0x9aaa6ba35488872032a4da9f988e248ba9c86fbf69a832ff4a118761760ed1f71986fed2fe42913916a98cb4b161541caacf7608c8d0569825f8891b920201821c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xee4be8291aE48514C6109008b640ece0ef8d3eC8": {
        "max": 1,
        "address": "0xee4be8291aE48514C6109008b640ece0ef8d3eC8",
        "signature": "0x42b027245d5e6ec296d20236fba38cbc7ff1310893e345c04a1b1286ab43dd751d1511b16fd16eadcae0a75a31bd421c2e1567a5546d26af246d7be5564e40171b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x75BFE009e0D9b0AfA6c38d0DE623eFd42fAdCd6a": {
        "max": 1,
        "address": "0x75BFE009e0D9b0AfA6c38d0DE623eFd42fAdCd6a",
        "signature": "0x2531ae4d0df80b78389058f12b193e214845e7453d777afef5296428b6f91acc1835068a3f8c6e0d179371cbf38addbed14d63386c08d5fc569c63b3f1225b6f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE6C8d6be04A9C4e78062Dc3781Dd9d4e2B286FF2": {
        "max": 1,
        "address": "0xE6C8d6be04A9C4e78062Dc3781Dd9d4e2B286FF2",
        "signature": "0x7d3452033ace4e3bbb14e9dff643ca086c73ce5cf5369b5f88d47a4eabf61fc56c385ddec6e50e6df5051978430287eb6ebce721f99d7fafda76a9a2f4e3c2551b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0AEa8e067b44C6448cB9b67bD227c9729569c1a8": {
        "max": 1,
        "address": "0x0AEa8e067b44C6448cB9b67bD227c9729569c1a8",
        "signature": "0x9153da3830c47781841ab9701b62bcf4f507ef8b666c1e3549f2f20f93c244ea5e7393b5e5ffafc52b51368adef093f2b63e6559565f59c56790e21c4c6cca251b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7E77f148e69b06079fe7f608ded23B34164Ff818": {
        "max": 1,
        "address": "0x7E77f148e69b06079fe7f608ded23B34164Ff818",
        "signature": "0xf812b37443758374454514cb1e0044ef950f0295c7453aed6319a4ade2d4061b2fdc921b83f501cc6e793a1af30ffcd76bcda5e3033e4bf0d5769da77618d6b41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5176b66F3D0645dC45a93Bd8D50433B79681964c": {
        "max": 1,
        "address": "0x5176b66F3D0645dC45a93Bd8D50433B79681964c",
        "signature": "0x1344219cf952a000cf7bdb51d04e21c4e0944c021f3ebb55f2c4bb6e60dbf1c17cfb4ab12b78ace556e6113015ada6aab3239c1a8598f43c842c4a522e8e5b8d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA7e179423f2fCE8368d1de0588b65a5fdeC0c454": {
        "max": 1,
        "address": "0xA7e179423f2fCE8368d1de0588b65a5fdeC0c454",
        "signature": "0x7d87ac895c64474d5dea56bdd11b8c673ee9c9121340b5c981f86da1763d294871f7b8b8d6f01f1db24c6bc9181e3c00a2f0d4c889b396169ac60476857f95861c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x274B9806D5F268496442ad7D69b50B60C1724Ef2": {
        "max": 1,
        "address": "0x274B9806D5F268496442ad7D69b50B60C1724Ef2",
        "signature": "0x72516aaebc41ee7c302d410a424948463edcb01f2fe72bee5c07573f01077fab49a946b8ab7dabd8392c34f7bd055b46083c3af34b46e3f6f54036bf3feaebf31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x430d55C83B666C2Ce0EECF0A1C5A9A0A03336feB": {
        "max": 1,
        "address": "0x430d55C83B666C2Ce0EECF0A1C5A9A0A03336feB",
        "signature": "0x6b042ecc6ec8578b291cda949c78619571b720b211a6ca41e1e5fa937b0ea02d2fc412bdeab59ac694b6e7079c23edb778db8fdc1810f8baaf67dd76534e81311b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC8abd6eFdbA9E1a27741DC27E891100f2F2Aba3e": {
        "max": 1,
        "address": "0xC8abd6eFdbA9E1a27741DC27E891100f2F2Aba3e",
        "signature": "0xf8b660946cceda91de134d51c68e1900fea8463fa85464de7e1ed9aa3d6854be69343ac0c56b46101141aa95e696cf9a822ac872a42b1688665e7c87b53df84a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFd3770dCb19f001e7fADE64eA774f575bf8a9275": {
        "max": 1,
        "address": "0xFd3770dCb19f001e7fADE64eA774f575bf8a9275",
        "signature": "0xaa16fcf4bfcb42f015490325d15159c8257fcb2b8321a0c0a360c6226608541f1a2cc67c58e9908be641e41966ce7ca5cedd406e1caaf890edc0fbb97ba25e0e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfA20683F64d470E2B7D8c2CbC0E0Ab8813C03f17": {
        "max": 1,
        "address": "0xfA20683F64d470E2B7D8c2CbC0E0Ab8813C03f17",
        "signature": "0x0c552c5008bdfa5314ba4cc8a8e997c18eab7a8cc0d4a3afec752f9cc693ae695fa7657e267c623cbeaa903665cf2474deb4b630a28b9af3805e091be2c698651c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf530aeE78CB9D5b9585aB3DA307bcF70C8a93196": {
        "max": 1,
        "address": "0xf530aeE78CB9D5b9585aB3DA307bcF70C8a93196",
        "signature": "0x6415a42901a34528c8616733b88d34f9a5d2096d3bbc0a1f0357f66dd74dd8e364dfc3b8dc41d0d18059d4c94a272ac989e4ca48e9b8ea8ffdfacb5bf036feb11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcE75E19905bE136248a570c89c87DfEb8f45f600": {
        "max": 1,
        "address": "0xcE75E19905bE136248a570c89c87DfEb8f45f600",
        "signature": "0x0e880c44d8a8683f8cae7f0422e3abe9091f6a07198f23fb2c49bec5d399db82501e9fea6ff9357c7eebdfa211dd316c99afde4bfa8b031805038a78f07bee9b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3BaD3dbBf5A1108173795Bd22C8544027d4aD1d4": {
        "max": 1,
        "address": "0x3BaD3dbBf5A1108173795Bd22C8544027d4aD1d4",
        "signature": "0x1aac2a8632a37e524c5be13d4c844b1bbd2445ed9907bb1538c17f4becf36b1046024243c723f233d5560e9f785143fcbcabda463ab8d426564072c12abc577d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0aBbDA1DC11D99cA868878ca050Da6036e216577": {
        "max": 1,
        "address": "0x0aBbDA1DC11D99cA868878ca050Da6036e216577",
        "signature": "0xf3ca8ca026e978e9b36f0350971b4a39832163813e85f63727e688df6df09f8929d13f3284117659447809182f354e858d9ff5f7403b6b45c26c033461baa04a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4E2c77447A61E2A7Ec190DAb9D40A92C25458de8": {
        "max": 1,
        "address": "0x4E2c77447A61E2A7Ec190DAb9D40A92C25458de8",
        "signature": "0xee3b8f9b0bed5674a3b48fb8e993dd73d84d67d01f0e97cabea2bc43586442d54faaf660047f3e5f755547fe4328ab84afd8328b59bc4186802826f26038527b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8Ac5B1DC1873994F376276cE008f8Adfb2DBDc5b": {
        "max": 1,
        "address": "0x8Ac5B1DC1873994F376276cE008f8Adfb2DBDc5b",
        "signature": "0x97144954e65554a6184ef770691a14b674b214e6c1fe4c5dfc7dde2ed07b0f606803bdd4b9f0115af8d1b764faafc78c7b06f260739dd1935eb9cb866834bca61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7070879d3195399944dF4bb9b356AAf8DC7A782F": {
        "max": 1,
        "address": "0x7070879d3195399944dF4bb9b356AAf8DC7A782F",
        "signature": "0xe096ac0142776a339074100aec08ce0b232ce01536736162f18c377091152dad18c30751d9ba381d6bb070a82afa19190313a95fbb7f3c8fae36897aee6862a81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0369Af271cF7C7F108A14F0857Af99Ea2F7F30A7": {
        "max": 1,
        "address": "0x0369Af271cF7C7F108A14F0857Af99Ea2F7F30A7",
        "signature": "0x06652082d79446ac8721a5684bf4e091498f410ae2404662039b4654617775ab7d2ac548225c7414a2c14585b4175e82a13f425f70e06040a005ede10c5201b41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x003b5671B06682A9745F9d29c3a603D3cf3C8724": {
        "max": 1,
        "address": "0x003b5671B06682A9745F9d29c3a603D3cf3C8724",
        "signature": "0x8a94251787571eb345208837bc4d101ef5edb44cce5735ca3bfcdda76fb4825063d79fe3eb18f835eed19a18f9bc76259c17fe2b27e734a6cdcb005096e69fff1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9C37F704Aa087282854A677b05B930147Dd74F86": {
        "max": 1,
        "address": "0x9C37F704Aa087282854A677b05B930147Dd74F86",
        "signature": "0xa019e67a0b07593673117a5d6f0734bd211a223d9cdfbcbe4f8a3caa78941554472daaf549a59e185deab00852e1e1a91c3e18d0f10ef825fe395cde69d4bd8b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2B4821EB4D90Ebe6AD8aC1BF69E8b16faCc0B5A1": {
        "max": 1,
        "address": "0x2B4821EB4D90Ebe6AD8aC1BF69E8b16faCc0B5A1",
        "signature": "0xacfdf6167efda4831d4ac863e84b05480f4c2011972770dc77d943748ab21d545a3169e816a444d2457083e08604d8014a37a69b5325592d5dae38f7cfadb5e61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x43074973DD0Cf530eA7fA7beAb41487Da12ae68F": {
        "max": 1,
        "address": "0x43074973DD0Cf530eA7fA7beAb41487Da12ae68F",
        "signature": "0x6bcf384ec37d5e02c8366323c580f7f5197c09d8864181e01771f6385154c80152b62f106acca9c1d65809a975c59d23d6860b6d34ea4aa95afadcda06e28d111b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9B856857370B9720aa56eD371c0d8730e753D1cC": {
        "max": 1,
        "address": "0x9B856857370B9720aa56eD371c0d8730e753D1cC",
        "signature": "0xa80cf36348d225cd118e7e53e0f7f817dcf7e8bc98921295d9b73895e1ae1dee52517957cfdce2b9cff84733a7f114363338a7bf2606caf76e95067983b9ef5a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE3d6AD7515dF6879c03F85a3bfF987cb43f79Aee": {
        "max": 1,
        "address": "0xE3d6AD7515dF6879c03F85a3bfF987cb43f79Aee",
        "signature": "0x44f34513ab7a47420628590daa2d5fae4282520cb496a92e325480fae238fefc53f2a8f30b1666295d43d4e56e6046b0f6a5887d6822bb2fd7171b92afea30381c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x785717EdC0C4a0975Ab58Be32e2391ede794af7f": {
        "max": 1,
        "address": "0x785717EdC0C4a0975Ab58Be32e2391ede794af7f",
        "signature": "0xbf4b44fb7c1ebcd89dccdc66ec8a84405bae4756128d938b1e41340492cabcfb6bf0aae67526739b25f84c372c1c6aa211391bdf82eed622e50aba2233b915321c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1F8cEF0CDdE793F1CCcc54214b00295d37781654": {
        "max": 1,
        "address": "0x1F8cEF0CDdE793F1CCcc54214b00295d37781654",
        "signature": "0xf20cd1d09100bb83f609b7cf6a5f3a78b09a625d72c760111eced6cbd65ab36c0c03f06fcbb79ddd86dae787c88372e3d5e32b294af521e5ce7c6acb314c21081c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x41B9609989428545326A211ff99e652A9A9B6774": {
        "max": 1,
        "address": "0x41B9609989428545326A211ff99e652A9A9B6774",
        "signature": "0x47dbcc33231d8cc469ff85a0422bc54e19643b9ed3c18d38603ca058e71fa554048042b3a91557a2b2d934c16abd45977c011eb03885a214aa3950d5df65c50c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xddf068ECA134aBa4B90D7865b2Ff82Dd4e741968": {
        "max": 1,
        "address": "0xddf068ECA134aBa4B90D7865b2Ff82Dd4e741968",
        "signature": "0x6c80e6888744b5c37ad10707107b0abd6a01b43cab01dd885895a79cee8a90bc08ec85b41facf08b428e44c79a75c8b77ffb5ef54b77174b65ed6f0053a1b8461c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB7AD0E1801cfbE946e80EB0ADaAf6966Be6862B4": {
        "max": 1,
        "address": "0xB7AD0E1801cfbE946e80EB0ADaAf6966Be6862B4",
        "signature": "0x109b4184db4c49dc68fb01ed69527aa9f8cde3445aa4555eaa19430a06da727506a819971700829dc8a46fee6dd81728f466d7d95a11007a9b2bb8cc38175c1a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf37bca35AbEff7A3507e23b5aaC549B89dcB2F64": {
        "max": 1,
        "address": "0xf37bca35AbEff7A3507e23b5aaC549B89dcB2F64",
        "signature": "0xc6efa1a07555589929df24c7896a9ace8f3d7337aec8924545804c8d98f198af65203ef95416ea7002046d8e7b7a5980283680b206bf782c67ba0338e1ee236d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA3C22c0eBfd23052A6d26E3C15f21969790a64c6": {
        "max": 1,
        "address": "0xA3C22c0eBfd23052A6d26E3C15f21969790a64c6",
        "signature": "0xffb4df205a10ff9825dce5af3500c6b6710c44a48df532f5ed214d98e3d187f45b670aee382a49737f7f80ccf6b991991459db8a47d00b2b0d0a8840a15328db1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6974bE1D8FcBE52335ba0326066C8f80c68839a0": {
        "max": 1,
        "address": "0x6974bE1D8FcBE52335ba0326066C8f80c68839a0",
        "signature": "0x62dca9edd904c1a6235a4a94ba056bbe3034bbca0a189204857c60bcd29149f66fa8dc528fde7c26142d60277b6ae355a0b0b9a94abfa6b8a57f026f162acd641c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x26bA913a4A49ebdb23a748Ae9513Bc75F01f0e5f": {
        "max": 1,
        "address": "0x26bA913a4A49ebdb23a748Ae9513Bc75F01f0e5f",
        "signature": "0xe590ea20aaae80e5fa15f476f2e4f30ab637b0f6f3964944e46286b87daf02ee42ac28c957d98f537bc5bdcabe9ed70c0569a8b8acceb6c9c48f2cba109b0d1c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF36bac9f25BbF11DA99f57B89dF2dB45ae8c7Cdd": {
        "max": 1,
        "address": "0xF36bac9f25BbF11DA99f57B89dF2dB45ae8c7Cdd",
        "signature": "0x8d00c270e34098d8e46b5b364dafe0de9b8574ffef272e47ad0dd17ce52857c4124a2b57ef9584399e55e2cdaa0b818d07636fb27ff12370d005d551877e62341b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC2402ddb90E98A4E2fE8f485EC1E6c5B85AEb41D": {
        "max": 1,
        "address": "0xC2402ddb90E98A4E2fE8f485EC1E6c5B85AEb41D",
        "signature": "0x59d7b63781aa2179a9b9cb1d020494d726bd209d223ea42f9b1993808b5f0fb01efa85b152779c1c1914117c7a8cfdad9f5843f3602dc2e2d34638e85e84ff251c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa18b92b20B6Bfea220b74E75c3b17b76e1E145dc": {
        "max": 1,
        "address": "0xa18b92b20B6Bfea220b74E75c3b17b76e1E145dc",
        "signature": "0x4277e1900d5ae64658b0c7e8c9db3d8472d76127f19902de70ddc605905a9fec70a084ce11f9c633e4b755072802b8944d6a0541d6202b77b3cc109c139b83c01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5d0CAD88Bc13Dc6A1C7E6e41cC59DeA267aC6E9B": {
        "max": 1,
        "address": "0x5d0CAD88Bc13Dc6A1C7E6e41cC59DeA267aC6E9B",
        "signature": "0x6945eaa021bd4e0259403ae21e958967a23579efba24c77b196d0c30260f0afe584986e6fae829d81df2b9723746cd76b9ebc932818b5b7cb5156e293231fa271b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x455279B69cE420a5486C6C0072471B4af6721cFc": {
        "max": 1,
        "address": "0x455279B69cE420a5486C6C0072471B4af6721cFc",
        "signature": "0x9916b218a81ef8198c6edc22e43712106716431691ca982ffcf93a6f7613f03d1575b02b6c6ae31ebe34c57aceca09a469c8772ffa86733f62d00c05a14b35991c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5E50cC50f3091Ee014a1B08a0DC98F5882a78645": {
        "max": 1,
        "address": "0x5E50cC50f3091Ee014a1B08a0DC98F5882a78645",
        "signature": "0x3bdc433e8a65b25229121c86197effa30cdb91268018a94d5b6b50495505ddfb1eff7fb60283695265d6ba149717c9f6120f32b2a167cc786e7786498ef552b21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9b2FeBaAB42071C125268347cF0f1B4E3AFF325A": {
        "max": 1,
        "address": "0x9b2FeBaAB42071C125268347cF0f1B4E3AFF325A",
        "signature": "0x5740d9882ca2cbbb55c317e5b8b85c2c3627927624218a318bd31bc6e221854b53ebf4f37321f0bd742be03d0d91c684490cf34bdc5fa64c0a1911646c2293651b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd328B7B6DB379418E2cd58193F68448AC3551A65": {
        "max": 1,
        "address": "0xd328B7B6DB379418E2cd58193F68448AC3551A65",
        "signature": "0x864c378756cec8e422a62fa7fb251ec5c1c6a7088618276211ae0fe2d8563760473f886adc942d4ca1071d79675d4d34b7e318476ca1685f5b53ef398afcf19d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD32C2dDFC1C14109Fb91978f7893bEB640A42086": {
        "max": 1,
        "address": "0xD32C2dDFC1C14109Fb91978f7893bEB640A42086",
        "signature": "0xe32a5bb547b2e1c29ccbf5f549d66f13c804bd084e3d7f5a6313f3219faa1c27139a3bc6e9488c8af30c68eeb2c8252678e65b96089fc63fcbea4a7d1381e9a71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x08f1dAfBe1ae73C42917F645FD5e5f933848E5B0": {
        "max": 1,
        "address": "0x08f1dAfBe1ae73C42917F645FD5e5f933848E5B0",
        "signature": "0x3306644d0562e562b99f3bcd36ce92ea204a96dd57f6030e14df2d99cae9fd5e2dd78bf28c0ff86fe90dbe9826e74277162a8ad9b7b8ecf9919a69e90a7305821c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd0b8f03f66698310255534587fE79d8334f19724": {
        "max": 1,
        "address": "0xd0b8f03f66698310255534587fE79d8334f19724",
        "signature": "0xfd42fca96a93994818e1d7b1ed18abf70c9f67399a9efc60e1b3cdb1f1e36c785cecf26c2dbc682722c085d4dd6860c7aed786fd618d3e1a6292338b4772eb1e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb15CB3975f62fCf9DfF2C0d71F6BEe345dac7A59": {
        "max": 1,
        "address": "0xb15CB3975f62fCf9DfF2C0d71F6BEe345dac7A59",
        "signature": "0x02f96b03752ad9b0f67f19cac109bf319bb78b6d4c2eab686bb4f879f2bc46082c2f5b7c8fe6941f19631a26a7f383377ab3f3496c8406d794e410dff814e72a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x81602e3eb12EEcbE9de4CF39d904d14e7308A1E6": {
        "max": 1,
        "address": "0x81602e3eb12EEcbE9de4CF39d904d14e7308A1E6",
        "signature": "0x16db490ea8331b6b9b488e5ece1a2cf97212ed23ef489e092969cf6b4af6f7b3166d973d2d49d41f67c9be23c6165fe2dd69a7a236921fa12a84cc71ae6deba21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x82d100791489a98De809e5D53a4D13ec6AE3F47E": {
        "max": 1,
        "address": "0x82d100791489a98De809e5D53a4D13ec6AE3F47E",
        "signature": "0x14c834afda491828e339059a3c6b80316f6e183c30da93c25f2713c087e17114746ab059a5ecb98cded3cf9c2ed80e6ebbf99bd9f8555be1413e724b543faed71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF54Ac0d04B67B7d19C24Da7a4a27D95B3D8a939f": {
        "max": 1,
        "address": "0xF54Ac0d04B67B7d19C24Da7a4a27D95B3D8a939f",
        "signature": "0x9b52e9de34ea8f600c84d35a0ae324d57787ddc7c0e3c64820ade2ac748b376a027673c8963f4798b8733af85476388a3c7be479ff4855de3dfd99dcb1325ee91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEb9735c3AAbF046f04D0eA77c81d72a68372daBD": {
        "max": 1,
        "address": "0xEb9735c3AAbF046f04D0eA77c81d72a68372daBD",
        "signature": "0xcb3cff042b6778434b6debeae410ce71121c62c7e3bb467b4ba7d4bf151152c9709c34309e504a19e6ee3cfa039139c9a912653916321fb589adf991d1c89ce11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf831d176562d3A4FbE442eB46eDe1A9a4ab533c7": {
        "max": 1,
        "address": "0xf831d176562d3A4FbE442eB46eDe1A9a4ab533c7",
        "signature": "0xe274e4a82fa42f98992cf68193901a50246f6e1346f74eff3c0665797350964f3d865cc2f0e36e27dcf5e7f68b17a7e92966fb889cc9fb65862207f48f6214cc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x36219686a2c9a2cEFCc866FB3038583e5568Ca25": {
        "max": 1,
        "address": "0x36219686a2c9a2cEFCc866FB3038583e5568Ca25",
        "signature": "0x5e6f6a352a27c7fc6a1c5efb82fe7ceb610c58f59370b45d3058e5e48431b1fd4cd4d5572bd543fc473e8c8dfda4957cd81c4ec078acde6d83f70e974390b7a91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xcAA5872C7b8fe08c4cBC9bF8f70c446B3Db23D61": {
        "max": 1,
        "address": "0xcAA5872C7b8fe08c4cBC9bF8f70c446B3Db23D61",
        "signature": "0x96012735383848941531bdb1ec2a4dcbb6c410ae235fca8946b38647a4ed90ec783b1c92f300bc3edd874975b3e4282dd631b250d8ba89df683e7be841982cbc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB0Ee8BC623a9D04EA960387aa51D26452eF10cFb": {
        "max": 1,
        "address": "0xB0Ee8BC623a9D04EA960387aa51D26452eF10cFb",
        "signature": "0x347dd9573e6885555fd625ff5fe2668ff5391b13f41185d6ff78ca6104610ded3588b0d7b5860bffd65ade650278c37aa75e7e1d8af0f947f6e383570cbcc0491b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x82E005ee54c7a36b4d21c6636cC8266F1dE740ea": {
        "max": 1,
        "address": "0x82E005ee54c7a36b4d21c6636cC8266F1dE740ea",
        "signature": "0x70ca7126cd1086c8d4734dc64d2b4683f4303319b9e28b7e093c92ab130c28552bd72068179d0e81b590fc823fc6c819ccac374763b2eb2c895283fef390aa031c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x58cbf1dCF70d2E8FD5B8A0fe3C9fdAE030E01824": {
        "max": 1,
        "address": "0x58cbf1dCF70d2E8FD5B8A0fe3C9fdAE030E01824",
        "signature": "0xfe8e7d828e6c3bcc603ccb15445507123959ecc42fbdfd41c23afe64a66df0107aff5e1683789cf691353136c32e8d2b809e0557a4e175495cf9bff10ed14c961c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8eBD6FF639bbf0cADfA451b08D01511DEF957d04": {
        "max": 1,
        "address": "0x8eBD6FF639bbf0cADfA451b08D01511DEF957d04",
        "signature": "0x7fe6d3cca6531a86ae1ebbb5f7a6d4978cdd3ef89110e6434a6e913b00c09a2126d140f9f6ee56f09c273ac5fa2e3a3e082f084e0b6d8b3efb7e5950d37e7f131c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x164c21650cde5f62d219Cf4E4b955fcdac79E350": {
        "max": 1,
        "address": "0x164c21650cde5f62d219Cf4E4b955fcdac79E350",
        "signature": "0x4a83a0e107ef423942fb8dc1f5581bd968d45967a84b30b17fdaf968dfbcc3f43e6ab95662e14e7e50313f5ae9dfaa2e0a765b122f92b26276e5a95c936e111f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2D3aaBaD8a8923a94F7cD9D0FEEd6819780649E8": {
        "max": 1,
        "address": "0x2D3aaBaD8a8923a94F7cD9D0FEEd6819780649E8",
        "signature": "0xcc6007008656b8a6ad670b7d3bc98a122e3b2f89e83071c50a78fdba04a3b3322d0ae3aa7eb6f626b8f0a0db8cb42b8368cefc1b2e8e185b018a01cf1c37d4a11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7e97e648B6576187f1A4a03b194CBFD4eE76F543": {
        "max": 1,
        "address": "0x7e97e648B6576187f1A4a03b194CBFD4eE76F543",
        "signature": "0xe18af6953cd0b3b19d2c898a9c19483a382eb2ed4d763895258ec56ceb8142af553f0d58494f27cdac012a210c9b24dbb24e1f3467f979f9daa6c47a5a322c1d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc1a9F25E5157C9B526e1A0D87a1912C96881163A": {
        "max": 1,
        "address": "0xc1a9F25E5157C9B526e1A0D87a1912C96881163A",
        "signature": "0xf794c08314b89c417c7a1845b666321d2d16bd40caf411e54bdcebcc06e379c0289c69b3a692565f2aa1c91fd81d3677d6cf72fc38c73d52399048a66f6ee8331b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfbCd56AB1a384e2A42f871E229fc60c92491532b": {
        "max": 1,
        "address": "0xfbCd56AB1a384e2A42f871E229fc60c92491532b",
        "signature": "0xf50661cbe9547af294c77c2ce99e0bd990cfb6a4330fb9b30a357e5dab5222052d1d4dc6ad73c1432d31aeb0be1ea224fe39eefb30f52c995f6c92aab43f47741c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x352A0101e9A732f8C7AFa34B0df13eE31aB90C48": {
        "max": 1,
        "address": "0x352A0101e9A732f8C7AFa34B0df13eE31aB90C48",
        "signature": "0x1e2c5594b159a600f88244c0e433ed3576487d6c7657a80b0f6aa3077dabbbab52a98aa848fcf070e5cd80f693d5d937ab5abb61e1c69e4d0f4e0e201d7895f11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFCAF9409D660fe5AD0446Bd1B9A1BF7421584e36": {
        "max": 1,
        "address": "0xFCAF9409D660fe5AD0446Bd1B9A1BF7421584e36",
        "signature": "0xafddbc575dbed635b57be70859404739950b76db23667bc94e34a704811508ea5af987f227f3807016b4cb747b398dfe538cdf5dc9010bd7eabdc67e80688b571c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7b28B67D7F4FE24EeA6Ff6a38DD2d289D5956A78": {
        "max": 1,
        "address": "0x7b28B67D7F4FE24EeA6Ff6a38DD2d289D5956A78",
        "signature": "0x76d7199bb1b06e93b0b0267955a463f2181d110e0c6ba131daac9c58962c154f18a090dc7190bdecb2cec08887b2330044fb93413f124d9d836caa6836c68eb21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2FB01ab8aABa9dc548CE489B8D88201f320F6608": {
        "max": 1,
        "address": "0x2FB01ab8aABa9dc548CE489B8D88201f320F6608",
        "signature": "0x3beb2dd4deb96bb36a101a635af1202bc1067feedbb67d0cac5bc23efc36754e46408dded1cfde27582be4f50fa64e507fac1472a5c9bdf79cffb68d3ed1c5081b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x80E940fb0f5bF5F7cC75D04bf153024E646250a3": {
        "max": 1,
        "address": "0x80E940fb0f5bF5F7cC75D04bf153024E646250a3",
        "signature": "0x193759812db7cb37b391a1a9699558f6b94fe17b261e254563b98281712901f022b94813b03e309c1e90c2b8cc91bc0149b7ed576a566d9b0fad04ae131e33d01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4e9d35db0B0269B7b891122229B7EF0A7eE66257": {
        "max": 1,
        "address": "0x4e9d35db0B0269B7b891122229B7EF0A7eE66257",
        "signature": "0xb84a313382d945d01d6d3330391b3f14f8f4c361112c89fa4ef67b8c91b5b5e459e00bb35f1d3df2626e044cf03cad4bdfe741d1a59285ff68c54d06f574656d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x71866Ffb7E700E0832aD51f230B75668305493ff": {
        "max": 1,
        "address": "0x71866Ffb7E700E0832aD51f230B75668305493ff",
        "signature": "0x8243a175ef1d4b0d360560444508be136fc90cbeb1ad780b0c566ac2b5e32801641dc95fabd75ed0ac5faed9c4c72f1172e12957663c21f4c9dc4c286da143461b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x85331f6B6661D4F94e5eFFF97f9D6aa23a022097": {
        "max": 1,
        "address": "0x85331f6B6661D4F94e5eFFF97f9D6aa23a022097",
        "signature": "0xb63597588b6b9e64c85b76e49a5b5f8724ac37d454b53fd9188f2bcde48628ff31404f7f040014e37dcc02871c2bfb24e560dc51fb5887d11837befeecf2387d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x605f644264f7c335FA3c5e2166426Ac8c9fe43aE": {
        "max": 1,
        "address": "0x605f644264f7c335FA3c5e2166426Ac8c9fe43aE",
        "signature": "0xc7f25f612def8d6a98d962f06cdaf23966bfdcccad4ab8acb75f4691f04fdc7a55b263723b399404dabeb7b49d69d8537f119dc3464645475546ed9eae3919141b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4c207A8D31D521b5167E9c109AEEEE9F67Fd7851": {
        "max": 1,
        "address": "0x4c207A8D31D521b5167E9c109AEEEE9F67Fd7851",
        "signature": "0x2d24250d09ad9f3b8a5e385100ae93b0a34d2e1732af9b714106d8f92a4843f668c64aa5a7741c60cf8a1a4f159696cea98f690db2b415a25135b62c8304894a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC9ED609fF81853950B0605282870bAC975863042": {
        "max": 1,
        "address": "0xC9ED609fF81853950B0605282870bAC975863042",
        "signature": "0x160716f35ad3c5e907d17b4071a96e031b661a515e1a6a4b3f99589fffd60ee940b12a16aaa3b0b69ac46600acea44f8071d75bbdfda4d1ae21a86e3ef9fd6021c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x25d7bB247d12c7EdAd26B221B24661Dc6566ACcB": {
        "max": 1,
        "address": "0x25d7bB247d12c7EdAd26B221B24661Dc6566ACcB",
        "signature": "0xa874b821f2836ebf87e36cf4561cac2393813ad3daae93784414c471fcea59421289085d14efc734e4e9b895456b185dd6013d18889d89a00e3fd316d47fc6221c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA5b7c36024147F030F9e2fd5104D016eF837dAC3": {
        "max": 1,
        "address": "0xA5b7c36024147F030F9e2fd5104D016eF837dAC3",
        "signature": "0xe585e8f2755ac754c40aa4618264248cbb626b902f0fbcbbf7096edf7a528d852242f4721c31ee19b320c204a36068178de11b91a7a04bec234ad98954cdec721b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5d436809429ecf0A0754Da5ba91F9f4d0d062bFb": {
        "max": 1,
        "address": "0x5d436809429ecf0A0754Da5ba91F9f4d0d062bFb",
        "signature": "0xc43e3231ca18f90b06e267ee1cce4f1584a93bad37cb4fdfa1804ef0d474cb5a66fbeaadafe4c982c9b215d6a2df70df46f8fd9084b7d38f85603d608c3ace071b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2FDBB9E3CF161C40c53F58C6991Bb87Ce35D7068": {
        "max": 1,
        "address": "0x2FDBB9E3CF161C40c53F58C6991Bb87Ce35D7068",
        "signature": "0x88b8ef139ad69e07d8a72ac5f23e55c791069aefcc72c9ad11c25c2388b7930a2bb179c91c48e980a599d02c1ee7dd8d27662bfca3fcd81d19dfed9a4fe442cc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x37A5C75d4A970Ee22B6d89fBBc83757915734602": {
        "max": 1,
        "address": "0x37A5C75d4A970Ee22B6d89fBBc83757915734602",
        "signature": "0xcfac205877067c1952da4603c21500e25e0de4ca7b96c25f6f6faa77180d26ab7ad8045762cfbea0559145d0eee1a4d104621799e5a8a3f5cdb7bbf333d356951b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x08115296321682422b35129A0BC877ACe9AeB3Aa": {
        "max": 1,
        "address": "0x08115296321682422b35129A0BC877ACe9AeB3Aa",
        "signature": "0x3757cd6b85a4e077ef83b5e9952eec6ba8ccfff81f7ab033cbb9b54c5563bce5709718aa209fb5b674737a6c1cb7e60e2fcc5ab2f70302aedab44d046db0402d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe268F03eBe44B04768c5E5eb0F7CbdB12F121368": {
        "max": 1,
        "address": "0xe268F03eBe44B04768c5E5eb0F7CbdB12F121368",
        "signature": "0xc34512763fb3fd8618ed22e1051731d91d437d7fffcda3235badd0f6d14d068c04e2fd72f115ccf52b58746f207beebf8001ade5e4dbc8cecaa51b58a678125c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x93a15b67602fd64017B37a76D98400d010eE1bc6": {
        "max": 1,
        "address": "0x93a15b67602fd64017B37a76D98400d010eE1bc6",
        "signature": "0x1a4c59eddbba0637cac3f57e2dc0168c9a3483288b3095a14251bb14084523b94226655cb4a61ce7415dff94c6086747edb46a9f2fd0fca6e9aedd11556d458f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA3fDe2146FC90e1C7DF03c9E63aB156528c62508": {
        "max": 1,
        "address": "0xA3fDe2146FC90e1C7DF03c9E63aB156528c62508",
        "signature": "0xf40175394b71ba548dd82de7a69909ecf669e58c426d56a4e6a23fc9386bb9ae36479cfc54add8c8242059e9767963ef84f83e19410bc9e8097e4f07fbb339bf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe2c09c1359E0B48ABB78E067c8709b8cEe730570": {
        "max": 1,
        "address": "0xe2c09c1359E0B48ABB78E067c8709b8cEe730570",
        "signature": "0x56696dbecda6b3ea35c08cc0a423a9d2864e4365928f16c16f5580a1b0d7cd7b18d62e5b0cd1d17cdf1b6d0796f90a79972073d4fb145bd4f2f87fd78841be971b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x294A15794419D687f7B090866919b155deD1dd97": {
        "max": 1,
        "address": "0x294A15794419D687f7B090866919b155deD1dd97",
        "signature": "0xd3f38d9e89f52315b75e50053bc898e3087d393262a3ceee3c31859b28169e9b005ce9a532a66b9431a2ce3a302535e5ed7e4e4db322a38fbcaeeaca733c497b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD02030ab08F685131A897B3225f9588F45Dbfd2C": {
        "max": 1,
        "address": "0xD02030ab08F685131A897B3225f9588F45Dbfd2C",
        "signature": "0x82a935a0760fde8c0e1368e13a8efe6fdcc54cde7bce283d30ff43ff9f1633553ec200f46709b5b2435695510796f3599e3f212e3ad94fd680d05a6ef800da541c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeFAE08c393Cb4Ef3DD694198c1cf249957A24782": {
        "max": 1,
        "address": "0xeFAE08c393Cb4Ef3DD694198c1cf249957A24782",
        "signature": "0x04472e70606b61abb8af6f20bab76e5cb4c850cd46b3edd166f4ea7fa998a0a703c3e681ae67b6de12cd23cded2c294ed931a0ce367e352abc2a98858a8baac31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x488d3819cEa6020aE2d9c019bD8d539F68974D40": {
        "max": 1,
        "address": "0x488d3819cEa6020aE2d9c019bD8d539F68974D40",
        "signature": "0xf983c90bf2044d32dd9183c63a081e02f24f98d066ef97857db8b53f65472e9144c7bd8535b971878857063d5390f72c7e22035e46a8fef2994d1a9f20a8ae1e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb6767610EF4aC21D35A5aD063B1d6bAEA0C80226": {
        "max": 1,
        "address": "0xb6767610EF4aC21D35A5aD063B1d6bAEA0C80226",
        "signature": "0xa982624922d1eb03a781af815aa99c0f5523e0baf25894e2b8ecfbca4c8e896a31837214ac466b4b54730235344ad56bbaa84086b8481e05ced794ea18df46e11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaAe7400DD8A973bCC1cc788dE0232849f85E7034": {
        "max": 1,
        "address": "0xaAe7400DD8A973bCC1cc788dE0232849f85E7034",
        "signature": "0x5d790c03b47986a27ba1d97c0cdaf4cf94f030cfd7bc13c29c599a085fa31580297275cbca66f35ee3efe13fc6c6b3a4aeba4c923d6c763ce15e5dce62a108421c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE13e428ca28Ca7b381bd8A7F6a75Fab94280814c": {
        "max": 1,
        "address": "0xE13e428ca28Ca7b381bd8A7F6a75Fab94280814c",
        "signature": "0xfa49f171e03afdb3a39dd20a37886a082fe07c633a486148788b103eaff03e8248435116c596411b72e52d4a6ecdcae426b8ac3e548ae3f8d3842000bb5f14f91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x323Fd3264f0545cA48A6eD544dc215D7e5aCa309": {
        "max": 1,
        "address": "0x323Fd3264f0545cA48A6eD544dc215D7e5aCa309",
        "signature": "0xe9344d6a0d639269cf29b35bd894d5399138fa7e8711618b2699c3d32a5210ef146c9e05bb2681cc857d13e89c6588ea5b29a8f2d31aa8a3e88f93ec57b2a2ed1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x259442Ccc79b8902bc3796f43F5fF8423463eF0A": {
        "max": 1,
        "address": "0x259442Ccc79b8902bc3796f43F5fF8423463eF0A",
        "signature": "0x7cf8fa2733cee3b1b4fee436705edbfebc3a904ac0b3f498976c1c6eb0c24bdb7ec885fa304474bddd9c28a5e524551d70ec2aa29f0c57d255d948d1a266c1031c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1Edf858F3d66f8D8B595E52AF3FFB993F0cAd086": {
        "max": 1,
        "address": "0x1Edf858F3d66f8D8B595E52AF3FFB993F0cAd086",
        "signature": "0x8879b2b020fd89fba13ea949085c4d80951c0bea3bf441b2ad25c5576cc9152674cd9c44195d6b99d036c8290f992e962a015c72ff994b397d620c99c72e16a91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2D7BFbA6e49c9cd451C44d27775725fc56F3B044": {
        "max": 1,
        "address": "0x2D7BFbA6e49c9cd451C44d27775725fc56F3B044",
        "signature": "0xb7a5725dcd03fa1f1d9131ef0887aead33e434eb6e92f28f325324f4bf3f0fb638cc146ed2aa981672c2851d70fd92d95bca66a9f596158335aeedddb9ddfae21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x09b2741feD63B7Fcc5E577cDc9d0cf0D85127A32": {
        "max": 1,
        "address": "0x09b2741feD63B7Fcc5E577cDc9d0cf0D85127A32",
        "signature": "0x5cd86ecbf7edb28fa4617561a0d1bc4ae4999948460c840bbc3b71c23f77e3a46cb85dc66b0fd0f3b927fd86d73ba21dbd1a02b7ebd40a741651853e6e8359a61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC929b6bD739a8564BcB35e978Afe4ffF5b6c3cEF": {
        "max": 1,
        "address": "0xC929b6bD739a8564BcB35e978Afe4ffF5b6c3cEF",
        "signature": "0x110373b85893e24dc53f3f63aed02997c26de6942b899c479a7ffb9a52992d05177bf4c840ac4b0f226bf7880d06ca6309907cc7b37ef9309a370909ffa5de301c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5bB1396E3EC5E31E12DC7846c4c94eea25083f35": {
        "max": 1,
        "address": "0x5bB1396E3EC5E31E12DC7846c4c94eea25083f35",
        "signature": "0xa7b71cb6c36defd0f512400ab42ded9a38021919460b3e8217802239b64e28785dd2378ef9624c4a3a1bf066b4d2e29057f065cb9ae295875f2130d5bc177b091b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3e8f4639E926f36f7309836F6D018a9ea59B345e": {
        "max": 1,
        "address": "0x3e8f4639E926f36f7309836F6D018a9ea59B345e",
        "signature": "0x691018a154b26804a188303889158f941e059666bed42680fc8b8371d6e41bff4ddc6f2a43efe2f2b95d626da5311cba8147b7ab469a53ebd82f23ca3d84159b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x09ff88685141C185B139dFaACeDB67520727DE65": {
        "max": 1,
        "address": "0x09ff88685141C185B139dFaACeDB67520727DE65",
        "signature": "0x39de6410c7ed2b1de51a35a7e9a6c6263fc05932f50283d3b96f58529d7874d361445be87d25eb98f355608a47fa28c294c1fcea88e05b8a009416c2530a7ee71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf796CC0f0734a4326c523e5bb8C6f6D5d73AA9e8": {
        "max": 1,
        "address": "0xf796CC0f0734a4326c523e5bb8C6f6D5d73AA9e8",
        "signature": "0x9700004ef0d8cb0892d5723583b5b2fededb06f5e7031ba8cabd8b300fe74e675c9999bd018354e4ce257986548e570076e6c089127b1756af176feaaa5558851b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1E444Ff1B2Ba8181f1F13933ab588517480dD61D": {
        "max": 1,
        "address": "0x1E444Ff1B2Ba8181f1F13933ab588517480dD61D",
        "signature": "0x39c03123bc987dd58c08c09f81fcca8512b58ad51977b4e5910feaa114fff5282aa0ac96836b7fed978659ba04f5e6f8d0d94a1a262405a4c7fd6fc745b153021c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x06D7dB176bc47684a643D45fef75188de5A6b4Fa": {
        "max": 1,
        "address": "0x06D7dB176bc47684a643D45fef75188de5A6b4Fa",
        "signature": "0xed3d17620c23c25af558d2dfff87f34d5c22c844b90b400127bffed363a956011ffb9d97c32fe1b66753ceea6b799d2c65cd732ec587f0cc6482c6d83726e9121c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb0Aa29a487beA02151A729d2D13CcC5dc0dF6D04": {
        "max": 1,
        "address": "0xb0Aa29a487beA02151A729d2D13CcC5dc0dF6D04",
        "signature": "0x57e8d7619ddf0d4ae75db4230b75d4961d0fdc90c7dfb1677b793e4f94adfede70fc674e257e8fb4b572a02f385775a00ee8495a890914cbf04253128ca2135c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe2e901ddA609FB1628A017C550e0562761B2a196": {
        "max": 1,
        "address": "0xe2e901ddA609FB1628A017C550e0562761B2a196",
        "signature": "0x2ee7d65be2e81e98da4518ab3527f4481eb6a3759d42ea3a7e41107bc657e21b5a41b4b0efd679d1695cdf2c37c4e97630c33fbeb50842b638a97e5c92f961a51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x84c78179277FBE5edb51C01170B572Cc4e2998FD": {
        "max": 1,
        "address": "0x84c78179277FBE5edb51C01170B572Cc4e2998FD",
        "signature": "0xf95178a26ecef43878b02a5c239fe892b628f980221a7c43597e74d66674dafe55da52018b94f7de194840baa718c6593bb026d3596cbc0833f36f63664bb62a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x063696F9EC833B6bEb18EF13Ed5d6AC48b2516d3": {
        "max": 1,
        "address": "0x063696F9EC833B6bEb18EF13Ed5d6AC48b2516d3",
        "signature": "0x7ecaf9f529813037719165ce0ded41d156cbc9e8a22d131ec3df17eeaba3651f5c30330d73efe4fc302f1e2d0d4d0958e7b6e2a81f009ac65f3a3b915a943f791b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x65db74678F1C2E1d4986f4009F76547a4bd6bc64": {
        "max": 1,
        "address": "0x65db74678F1C2E1d4986f4009F76547a4bd6bc64",
        "signature": "0xc880961380e0cf0f2160170e35354608636a501887b1c4a8165bfc8236d478267fc31def0e228fa5971ee47257efead94e10f2c786a6e5fdec443765c0a0e6881c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x91484Bc672bB3a9da12c5C766112170952cE73C9": {
        "max": 1,
        "address": "0x91484Bc672bB3a9da12c5C766112170952cE73C9",
        "signature": "0xa10ba2d5a2bc96be1cecf42b25abf757e08d1063a6f8a884e42176390a072c7c442b7be42ec50c41743532bfacad08a6d2ec3bf1cc2e86e848afce035e2dfad31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAF15825F5E617EE4860582b15e697322E8Bc3A05": {
        "max": 1,
        "address": "0xAF15825F5E617EE4860582b15e697322E8Bc3A05",
        "signature": "0x9a6e2a1488c4205d06a8f1d57f4edc30e8e345310a9be5ae254720d0da1555712a15a76e3d648d4100272d53226a0a6acc891c8a0155462cd9103a1e6e65cfb31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB1AEf567c632C5E3A8c409d65fA2878D05B585fb": {
        "max": 1,
        "address": "0xB1AEf567c632C5E3A8c409d65fA2878D05B585fb",
        "signature": "0xd914ec712591935e8936b80654b66a711811648df74e016ea82fd117467a735e6bba1f98595f08a285fbdc6da06c6ed4bee11b3c2f83781b74bc89a3b50b957b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7B098eFe20DCA4DB693E438FBA05211f7e40f688": {
        "max": 1,
        "address": "0x7B098eFe20DCA4DB693E438FBA05211f7e40f688",
        "signature": "0xb161d0aa692afe64ddd7105fb5c54d8641ba827b3aa535919026aa04c58924b7141f427ba5d41e9988f814b302bf5d27edda5a937a76f6e95cce582df9d1d1fd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x048593e773C7D4668dd4D4bE4deDF3Ab593Adc25": {
        "max": 1,
        "address": "0x048593e773C7D4668dd4D4bE4deDF3Ab593Adc25",
        "signature": "0xed38ffe40d062ece1c49248d032cbf96e14789ac1337f26e30835d429a399545323ceb06baa1403f0867e95a88e8c2c13c49210e2404169058d7b10271c6507a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x076d883d39A33560EefaCB14D3bC1893Bd87D67C": {
        "max": 1,
        "address": "0x076d883d39A33560EefaCB14D3bC1893Bd87D67C",
        "signature": "0x2b7610c65829965220ca1a762831d5653ff09be952aac7d0ee8c6de6bd407ab83ec8905a9ad3a27f16bdb244e9db0b95a5d07d7bac944076d85ded02c2b0b1ad1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9aEa54fE40899BDB2005F94cf81fc1C505e521E0": {
        "max": 1,
        "address": "0x9aEa54fE40899BDB2005F94cf81fc1C505e521E0",
        "signature": "0xdb36fbc6a3d9e47985cd454bfc0c8de8cf5caee0612b8fd0787d5cfe48ae2e8c2dbef0822fcd58a9ea37a61cc1000016263f38dea0ec2c248f157598520318581c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF17e8045CF4C339eC3821977A4D5A3A9AB675f53": {
        "max": 1,
        "address": "0xF17e8045CF4C339eC3821977A4D5A3A9AB675f53",
        "signature": "0x58788f700738638c0ac8c39df18a46ef55d87386abe4aa74e5d7c72cb888006944658b761a99698ba1438860b6205d65210dca8bacc1a3f644d296540ad89f5e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE7B4e983c1B0701699722E87778800Ef4831dD15": {
        "max": 1,
        "address": "0xE7B4e983c1B0701699722E87778800Ef4831dD15",
        "signature": "0xa1a874b4b31ce152e638ad803cff20acba7036db4acdaac7fcdc6efc376519cc6b64078baf664fb4d48f1f78eaab84b9a24b9ea1e8be6cfeb81285f5b5bfbaed1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4E5Fe339b5FCf0f29679d424934fadD708219a5C": {
        "max": 1,
        "address": "0x4E5Fe339b5FCf0f29679d424934fadD708219a5C",
        "signature": "0x61940b744019b584d3e70c0d781357adf10c185f5f824883e31cad7b4fbf24a311175bc8b5df986cc47745b91ad14dbcf94f034c0d9bac4dd89735c4c8e4de561c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaE4abc4a59Ddca14cFDa57601991D41DFbc5755C": {
        "max": 1,
        "address": "0xaE4abc4a59Ddca14cFDa57601991D41DFbc5755C",
        "signature": "0x8a43a44d0a2859cf82c75a02bc9b585c3f5d45acfe2c48d56a65c5e97bd4b28a7af808ce34e5f7d12b1d0ff5d6c811e12aefd25628d353706071d6b83670c2441b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE4a08a3A431270bFE1cBf2D7F47Ff25f4cB7F333": {
        "max": 1,
        "address": "0xE4a08a3A431270bFE1cBf2D7F47Ff25f4cB7F333",
        "signature": "0x6f94717f31890e9df3be3e29775f5da5fd95ca73ef4dc20c43a65b0d26f79b957c3ddc24c63b38044184112d8a61ded2c0f91e70ff6f2a408ce98f127fdb639e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x210367F6FbB316fc24674AB5c36666eDAC3e6187": {
        "max": 1,
        "address": "0x210367F6FbB316fc24674AB5c36666eDAC3e6187",
        "signature": "0xdf42e4d2ac30d699a0c7931f8e455cbee0422d82771ace731ac918f4d6210a1d6437f6050f2ffe1cc21f2118657ef3ec0dceb00269d6d56c428cff75bf1451d01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x43e153DfC3431a748d66587DF3aeB25b67b5fAAd": {
        "max": 1,
        "address": "0x43e153DfC3431a748d66587DF3aeB25b67b5fAAd",
        "signature": "0xe22b5870562699ea69e881663ee5b8427be293a3f8bfb788d891984762756af77289d8ac79e70d8509fec470513fbff5a20b258cb96b617b1fd0986526cb848e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1469b25984200bbCD19EE6f1C8a6Ee065822E619": {
        "max": 1,
        "address": "0x1469b25984200bbCD19EE6f1C8a6Ee065822E619",
        "signature": "0x4f34af9373d0905120c042c4dd4b5b2c36e27c2865ebd76d0bcc650ec89ee39a1b80e1318e291eb04c2beb8d201eab7323b1c4b1e2d3d9411b0e33419b15c4fa1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe3746875FCC8D7164ce3EE268d872efD016c16D7": {
        "max": 1,
        "address": "0xe3746875FCC8D7164ce3EE268d872efD016c16D7",
        "signature": "0xd5f1c69128c8540f52a1cff8bd68b6edd218a4aa9135dcc6b35c622492586a60590de97601fb62cf9e059c087570fa0efc05ca07c6907008bcadc5dc009502841b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD12EF7C6A70659BB97f5669cafe22a1eF575112A": {
        "max": 1,
        "address": "0xD12EF7C6A70659BB97f5669cafe22a1eF575112A",
        "signature": "0x1e0ac950cf779c3b177e27ff6078b5f0054c7edb53a8245c8724f39b2a8eb7d81f7ed313732a61817456cabceaaf28d677fade7093ab1f041af1f631412405361b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x678190b9c3A9e83595A8Fa1df2da7D9325b73d83": {
        "max": 1,
        "address": "0x678190b9c3A9e83595A8Fa1df2da7D9325b73d83",
        "signature": "0x135be7025220ad7c9ed3a9fa986f25795ae9c346993a478aa0189610e3b38b0b32795aae4eed6000ed9284adb38e0bb2fb912c869b850fb165e77933a2a8238f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf35eDDB79cAdff47B8596eC812bACe7fe6d56604": {
        "max": 1,
        "address": "0xf35eDDB79cAdff47B8596eC812bACe7fe6d56604",
        "signature": "0x4119bb78cd2191bffad05f2430754c44caafc8febb6f4e491055e23338220db02c3ce950573bac1022c1af77d2edb56f013c71c19b50a51b05e75a811e604f531c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfD28926903C1354DAaa333cb31B41104210Eb0e3": {
        "max": 1,
        "address": "0xfD28926903C1354DAaa333cb31B41104210Eb0e3",
        "signature": "0x480bb2a582d3646c390321c8d1b8a98bbe99687ba880b7137584957b094d47ef66366f5f9b810ea5d13de6063b2c03e5b7b3cbd4bd7e7817df2240e889f7a2ed1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5d19F457f3427f512b8E19911Babf8738FDC8a05": {
        "max": 1,
        "address": "0x5d19F457f3427f512b8E19911Babf8738FDC8a05",
        "signature": "0x3a0b13cdfaa204d9f753ae3a10a639cee1ea82756254a024c748d6f4ff5a65e9224880083074b13fc3ac5d14c6054c4e2940a08b57783eafc06317133eb4b79e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa046540eF97E23aB13e2bfF1e218b5CFbad33a66": {
        "max": 1,
        "address": "0xa046540eF97E23aB13e2bfF1e218b5CFbad33a66",
        "signature": "0xf74fed10a7ba569667ac278ac4b8bfd3731490eff0a018f018bda98c69d60eed13d79a68cb7a74b81e4337362bee08e7027042af20eadccf444d6144db7011d21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xec6C8C9D582e2dc7eEa3a45B2D7899429eE64C61": {
        "max": 1,
        "address": "0xec6C8C9D582e2dc7eEa3a45B2D7899429eE64C61",
        "signature": "0x31592072bbe042396ddd561c066ca329a289c890b4d8764f3750d974f144b37a330e476d8f7bc884d59ec7d2fd341c1d38a72f3fdaacb6a163a096f5c06dfa241b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4E68C897F3Ae937bEB0e7ed7e156730D4E56d914": {
        "max": 1,
        "address": "0x4E68C897F3Ae937bEB0e7ed7e156730D4E56d914",
        "signature": "0x7ecc4b84554c379f07b8060d6ce6c2b7f11b5916075659fd160a23fc371c336451ee0e9890e851b61d5a7e25c7548571b4b757133b1f6d83f36388eb399926411c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc4BCeB4dD0Eb13Cb058398F0108118953dB8f93a": {
        "max": 1,
        "address": "0xc4BCeB4dD0Eb13Cb058398F0108118953dB8f93a",
        "signature": "0x07d0c7bddd8c91f743303d9a1974bc031e042a0829b889c236479949bde52b857a629690e84261d8de3a6fe9e80a2d3a3e66a0d685518641aef20849927566b71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0CDD65d3e6e80dA2e5A11F7C1cEdaCE730372D7E": {
        "max": 1,
        "address": "0x0CDD65d3e6e80dA2e5A11F7C1cEdaCE730372D7E",
        "signature": "0x07789b929ed7cc2ac036d062f9f827a32602ed3244e426bb4563575d7b0eeda1459cf2906ae48f203e281d1885eea96e5aa0c2bef00eaf2c2c5e86ec61b3e0811b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4cc151087CdbFD03316AF9AFe83DA42f65989Fb3": {
        "max": 1,
        "address": "0x4cc151087CdbFD03316AF9AFe83DA42f65989Fb3",
        "signature": "0xef89656657add795a932117487178d087a830fc84d7eee61f63ecb9b787b8fe404b14b9077500c46f79f077211462cab8407793b38009be8aaf21a2980cc533e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x83082Acd5aB84aa97B058aCA08D3F475fA2448Dd": {
        "max": 1,
        "address": "0x83082Acd5aB84aa97B058aCA08D3F475fA2448Dd",
        "signature": "0x5d052dec259598430ea605b096d0b9f0b931cd38c8aa3be41ba5cd4705d332f34f9a75dc391e086d1ad37f75b0836cddcaab6773d63f1af41a0e436fd54366591b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa4d64966a79c2E732fCF6Bd02f74e96030dFE673": {
        "max": 1,
        "address": "0xa4d64966a79c2E732fCF6Bd02f74e96030dFE673",
        "signature": "0x0f9cac58ab4c43cb39e9d22df47aee5b0bf30619d233882962e20a54e5457b16156666cbbd1fa6764508eb1105622f40e33d043b1aca321499fc5055ffa7db431b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1D7d2aA3282d5f70598Aac7A3972a092D7743ef0": {
        "max": 1,
        "address": "0x1D7d2aA3282d5f70598Aac7A3972a092D7743ef0",
        "signature": "0x5a073ab4b84f804980b76bb52c78f2d070767a57924000aca689b2ecf163b65f1bd8a5161cee57b09e8670c3ac6ad39f917aa53d981ecd0a8ac6c1744e53593f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0676d673A2a0A13fe37A3EC7812A8cCC571cA07B": {
        "max": 1,
        "address": "0x0676d673A2a0A13fe37A3EC7812A8cCC571cA07B",
        "signature": "0xe1e93bbc6e7c4af79cdb9d6f4ab6eb5e2336b77c5489631281a9ff91dbc1fc395cc7260468813825073042b14c61bdd4976042269c0924a107a9faea2509a9711b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0707FfAD57ec8A15a53e0211711eB1A3d5A6cC06": {
        "max": 1,
        "address": "0x0707FfAD57ec8A15a53e0211711eB1A3d5A6cC06",
        "signature": "0x3e3d0579698b5dcf6c62c6e18f25835af20991e40de008baad359225d0d3a8b12530d72cdb3fe4af2a379e4748fcbf713a9082b31bd984addfbe6ad74842af521b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x08E8d4A308c410F42AbfC31C6195a3B2C3138Cd6": {
        "max": 1,
        "address": "0x08E8d4A308c410F42AbfC31C6195a3B2C3138Cd6",
        "signature": "0xb524186b87b44f4b41e364bef55b305d213783a4dfa7678f9c4734bb57e185ea04d5eea1fec91acbce9eb8822655f8d996fbe148bb89583360abc0bca5d41c151c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x10C25fD86DC785F4a66c8541c6C5EeaaEc373669": {
        "max": 1,
        "address": "0x10C25fD86DC785F4a66c8541c6C5EeaaEc373669",
        "signature": "0x8c21befda8b738bdd92de26254e8b75e1968ade412910ffa36e1a8a23f6408b001ebcbe0e2c6ec265688b3fd80645b982f0300a3478853afe44052f80a301aba1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2e96C35cBFD7C7694C6157C491920FC7693afbB6": {
        "max": 1,
        "address": "0x2e96C35cBFD7C7694C6157C491920FC7693afbB6",
        "signature": "0x3f6a12b4136fc2f85637daa91842cf1494a17ebc97597e5d7e868cdc2e5046cb286ef59b5ec5b3c13ddd10397c4a84db8e987c6ccfc100f55f25ab542377e1f01b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x43AE07e1C9d90970Db4ab628CA7e7940d3c4769B": {
        "max": 1,
        "address": "0x43AE07e1C9d90970Db4ab628CA7e7940d3c4769B",
        "signature": "0xacb3e8d216d9a81e171eac783d71160291eba0e9debf3f0fa776eb1597f41a2e0474d2b2ee950a3574b2b2725db404a1d1115c2070ec71a2d7a09484bb8ca91f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6C90141A55cDE5faB162cd4d3Cba202f806dA335": {
        "max": 1,
        "address": "0x6C90141A55cDE5faB162cd4d3Cba202f806dA335",
        "signature": "0x6c6d8c3e7ebfc838dc226d1d907b65c84b1a1814c0b215cb1f528ad843d81374299ea45a8b72c18651e3e95153a7b2ca06c405b103c28e8e52c74fc637d6b2a31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x778c4938d2fdacD4679aa3c7029fCae60599248C": {
        "max": 1,
        "address": "0x778c4938d2fdacD4679aa3c7029fCae60599248C",
        "signature": "0x69cd3d6e6be034552c329ae0ceaf00f636a56293a1ed8cd7b865af10a9be20345dbb92c51672a4622339c1c31d92827a92c65f727b6fcb98c399dab73475ef541b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x890E9b41Acf346847A826d22d971a0928aEAEf59": {
        "max": 1,
        "address": "0x890E9b41Acf346847A826d22d971a0928aEAEf59",
        "signature": "0x9d3ef70fd6753df3d68fe5f3cd07f94d3e787bc4024bc77b6b7537a53375228f371e1c1fd17067a8894ad96f5b4dc905c269fabd35ab6188aa292ff7be9638cf1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9838757cf8D9B49a03a00347A9c99d0F8922d5fA": {
        "max": 1,
        "address": "0x9838757cf8D9B49a03a00347A9c99d0F8922d5fA",
        "signature": "0xc7bcf06c7384fb0494000e7704bb9187db75f13bf1ce6d8e870ec0310f6d793d1179b6ef992128865d86f9283e74161c2047788b558c5b4b2be7716f3d3773901b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa35bd9fd4A1B3Cb2b1C7D7788a580D913E3feFB9": {
        "max": 1,
        "address": "0xa35bd9fd4A1B3Cb2b1C7D7788a580D913E3feFB9",
        "signature": "0x4d6af3be447145e16d86f1e18b2757f423bbb30c730c37d4a929999bb864e06210438ef43105a6e9e7bbc5ddb0b6f559cd5e70770a822815621a9d2d774124451b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe44591CdB2a3d74227cC30b420265eF6b587E770": {
        "max": 1,
        "address": "0xe44591CdB2a3d74227cC30b420265eF6b587E770",
        "signature": "0xecddcd8545322286a27cfaec0763a88a1c71156b964db995ae5cfde2a471321b6c1dfa5d2830b01f79e52e5a54396e7de7e7b824bcd9fea177a56cf9e5739ef61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe585f95b44905dfC8a8640C5B1933ba5d9BeCC75": {
        "max": 1,
        "address": "0xe585f95b44905dfC8a8640C5B1933ba5d9BeCC75",
        "signature": "0xb0f9193dbef1324515f39d76e17167e3561f17fd91ed4342bf402d3a003709361c1f8bb1d876e668e1769930be9a6998cb3f7ff3a19aa95c31ca7cfbb7cb33b91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe9178DeAAA47d6E42CF5011908C8F0Cf738D612C": {
        "max": 1,
        "address": "0xe9178DeAAA47d6E42CF5011908C8F0Cf738D612C",
        "signature": "0xd7a12f2e410bcc9982ff64255936ff43beab432e8d67fa7e7dd666412c71eac4661e635094ef6464b8e3af6c91ec10262d4e140bf2e52300da5ec4c949efb7b61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF2F3CF7Aa50070Dc55e3b4471E7136aD194945df": {
        "max": 1,
        "address": "0xF2F3CF7Aa50070Dc55e3b4471E7136aD194945df",
        "signature": "0xbf39383444a564301a9440f339d91120021b6c9c3565da63b7c23bfe162a279d7ac0d258bd96a458971be19670da4ef72567caecfcfca61a720b7f7a2d859d8a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA34D6cE0e9801562E55C90A3D0C7a1f8B68287Ff": {
        "max": 1,
        "address": "0xA34D6cE0e9801562E55C90A3D0C7a1f8B68287Ff",
        "signature": "0x5d779f3b8f7a2905bea653908dc62a83855d788ace7417379cc9a13757d6812c2dfe41f3b71fefda2431637d3f03a1605b9ce6e29f088bf23476f007a4c4eb061b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5Ea0FCDCaF7C866d727c5fBdea4A9bBED8A5a725": {
        "max": 1,
        "address": "0x5Ea0FCDCaF7C866d727c5fBdea4A9bBED8A5a725",
        "signature": "0x4caea7610bb1232adf105d32dca0b7e4cc776f225844c218176aff68512a81b116e5f4006e9c5adb3877fd46315ad75934e6d42c078a5aa77dded050ba99c8ce1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB8B7Cf5b91Ed7B36dd20dF1A64c6524f38312eeF": {
        "max": 1,
        "address": "0xB8B7Cf5b91Ed7B36dd20dF1A64c6524f38312eeF",
        "signature": "0x3dd77aa88a2038a67dcff99261846fa99349e46784539a1e3817af0226f2eadf01d1818ced60c8ac0e21da7e86d8ee28e8f1175db618687f5377a47c14d163e51c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x050C1C3D2E802dD6208Acee28806CC65f35F9327": {
        "max": 1,
        "address": "0x050C1C3D2E802dD6208Acee28806CC65f35F9327",
        "signature": "0x2e136868eb0ab7c3a4cb3597ee6cf8f7d0c4f2cf587d755d2a2661701a820d926d395a842958387ced31aaed369d8291bef935ac1581c405899e38fadb227ab91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x65e63a3BfABC916c22e7230Ae1b15CE4e787534C": {
        "max": 1,
        "address": "0x65e63a3BfABC916c22e7230Ae1b15CE4e787534C",
        "signature": "0x11a2cf7f967a4459067b521fd194bd6eb25199a1a143df5ac3abf5c5c9bde11371ec8181fff0c97f3456576d244411a064abc4149bd7d0c4c57630553ea96d261b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB5fdFbbdDC872D08D0203Cd6D69d5cE67Eb4c761": {
        "max": 1,
        "address": "0xB5fdFbbdDC872D08D0203Cd6D69d5cE67Eb4c761",
        "signature": "0x602e95514f4694a9e6884907bc77ec8c7fed3df3f31e58c2ed826895bfbf555027d037743f59df45448d4b200e02ffe69b42779467b92376b10822db9eabf77c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8f505b39a533cE343321341e8CA7102E6b9571e3": {
        "max": 1,
        "address": "0x8f505b39a533cE343321341e8CA7102E6b9571e3",
        "signature": "0xded5e6d977ee525510ede2d7ef02619f122e09be49e333f4f215d91208fc82567219ac504f003add987186dd19cb57019524998aa787d992b7d5dde6ed71693a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x813ACA533fD21Aa060417188787be1407BB8d3E3": {
        "max": 1,
        "address": "0x813ACA533fD21Aa060417188787be1407BB8d3E3",
        "signature": "0x30b95aabba702c17c104aa1e1e9c58b8d897940039488a7e68a01a74c4bef84636124bfe23169eb51aeb1a092ac17a8bf517b38f89076c1c35a62e04f49a75ec1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6c85DB956eCf0e4b7c138D03D62d6280A15b855a": {
        "max": 1,
        "address": "0x6c85DB956eCf0e4b7c138D03D62d6280A15b855a",
        "signature": "0x452bf70d7b204b17fa81f7e4c82912357e039f57f518efc994ab5d6f0f553fa816848677b0a8fdd9bac59d44ff15a387492810aa948a861d2fbab999be20497b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x33229aCBF633E9B3d9aE245d37a824934c30131d": {
        "max": 1,
        "address": "0x33229aCBF633E9B3d9aE245d37a824934c30131d",
        "signature": "0xb6da66f9ffabb583dd6fc7e277aae6e15231ef8646fbc2522414daa236357cd608b1c2fe420c856d504e999d031eaa24c371bff36ff1e027da9e8808cb0003c11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4A13e4308CEf76092ebf3763a10790cdB88F6fAd": {
        "max": 1,
        "address": "0x4A13e4308CEf76092ebf3763a10790cdB88F6fAd",
        "signature": "0x138657d7ff6640baf2cf9853910fbfda8042008da51b8bb935532070bb6ccde43261ee5fee3d4b99ae9d4d8ef1ba3594c7e76418a1ad1768bae9ce4b1f006f511c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe3B999185a4b2498D466b0461a2f6C52a9112446": {
        "max": 1,
        "address": "0xe3B999185a4b2498D466b0461a2f6C52a9112446",
        "signature": "0x4d90b6766270d6920c6c0dadd0f05777dea3cdd6df15658c5cf11c88784d1c3f6e879e2c6acaa4423fcc1f56478ed1c447c3375d4eefe411d34cbe84c3256d301b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfDAA4596891C591e2D20e1A97788ba0706cDd39E": {
        "max": 1,
        "address": "0xfDAA4596891C591e2D20e1A97788ba0706cDd39E",
        "signature": "0x86108cdefd6279dd0f2f526c79e9989dee50dc45f5035c3c96e7f0e4d368da8b6e8de28428e97ee01935ed2e4eb2b8ea66637a2e8aafacef177aafe608b13a321b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBB3a0704450a5980Bc547aE5CD6190Aaf5B12FBc": {
        "max": 1,
        "address": "0xBB3a0704450a5980Bc547aE5CD6190Aaf5B12FBc",
        "signature": "0xf0de1c79512c7296159e93007b8e133624a6a9e95e048992b30e6855fb4fba97577dec4381b46d9f55a5190476199483bcfadd02c4833a40f26d6403273f89461b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xc5fC762B4CFEceb489E659c9b2044bc45f1a5b56": {
        "max": 1,
        "address": "0xc5fC762B4CFEceb489E659c9b2044bc45f1a5b56",
        "signature": "0xa40eac24ccd969d42066b780db6c07dad1b443d3a83fffab0e9114b49148ffb1219c05f389998774f1ef7cfa3a9248a42d0e7268ba2e2af88d494d41a7ca51ae1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7662899fB4eD36ff1cD66ED62E9aB3DA3A158AB0": {
        "max": 1,
        "address": "0x7662899fB4eD36ff1cD66ED62E9aB3DA3A158AB0",
        "signature": "0xff672377ebab649f805714ad6cbed0457936d268b565af3fdc97c5311a95f034512cf02759aea2f3f55c59024c82f18a22106ea6184864d76c61238f57b8bdf71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2BcD66F96bB1A862aB4fe0cBD52799CeC7E9Fd1C": {
        "max": 1,
        "address": "0x2BcD66F96bB1A862aB4fe0cBD52799CeC7E9Fd1C",
        "signature": "0x2dbe3f35bdec4b476341914b785b8730058da258cbbcec638551f7bda5deb8ec3b95390e1feeff714badf1cdbb62d50f80b1f3cc27fb4c36ea8f324cd5a1717c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x769dada94cEeb1B3399150AD3da9EF9C7697B0C9": {
        "max": 1,
        "address": "0x769dada94cEeb1B3399150AD3da9EF9C7697B0C9",
        "signature": "0x97eef1b9d2d448af2299d4eb53a5e11a944251a5b34d190311ab0d58706777642ab6766f06b002faa7d27889b7ffb0cb647bd073f056490c52ef01a02991ae911b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7DD421494491371395336D028FD99758DDB4E131": {
        "max": 1,
        "address": "0x7DD421494491371395336D028FD99758DDB4E131",
        "signature": "0x21e5f88d984962634e68558dd91a6e070c3211cd89c220aeaaf9555291dda7663deb41cee996c04a21eaf5668241a6fdaf7d1d85edf97e96646ad27484e395701b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE15E19AB2260BF48E53c46752a3EcD55dBd7a3D4": {
        "max": 1,
        "address": "0xE15E19AB2260BF48E53c46752a3EcD55dBd7a3D4",
        "signature": "0xc64b2c5bf51755d3cba0d8e36381aec07731cc99d6e36109354d24db96c8b4a5039859edd4e8712a0d4776471073511c8c05e6217d139275521c6204edaf83591c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x01b8Fefb074d461ea100F952839d3B40eA2C921C": {
        "max": 1,
        "address": "0x01b8Fefb074d461ea100F952839d3B40eA2C921C",
        "signature": "0xc3d450d63197744311c0eb28e89bfc8eb540c4b3a312bc9885f36a779ddb95da5d1c0fbd3a16077970776852de8260ef75e7f9869535165fc6a0baa6be7170c41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1a0DD3e87d3F581cdFACaA1Cd94877A4cEe8C610": {
        "max": 1,
        "address": "0x1a0DD3e87d3F581cdFACaA1Cd94877A4cEe8C610",
        "signature": "0x7deebd942c62e161160606f56f5252fa546d3a24712dc6f21a9c57747a8bc930204aa95f6f86de4e4bf3684193438e9e2867ac3df95c7265c19cfd0236e519b91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5831a8FdF2a7875a786B066C5306092BeEb365F4": {
        "max": 1,
        "address": "0x5831a8FdF2a7875a786B066C5306092BeEb365F4",
        "signature": "0xd9f14f5417e47aa89b768d73bc802e791e49e3bbc436fdc85a2cfc86af1cc3310ebac98cad1774066717b8def52578b9bbbbbf6b0e0c9678039a7c0aee08d74a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x28D85024cF957c44832f44A4d3570c5dcD9C1474": {
        "max": 1,
        "address": "0x28D85024cF957c44832f44A4d3570c5dcD9C1474",
        "signature": "0x6fbd5839e7ff4f0d7dafb1c4a0467d9fe561b3d09da93000168dc6a1e03eb51e7afd008bdcadf6816b2a10aedebc04c28fc8a33ffdf5df244e0f72586f74d6981c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB38960d106759f1C26dcb7d61F4f9a5dE3664Eb5": {
        "max": 1,
        "address": "0xB38960d106759f1C26dcb7d61F4f9a5dE3664Eb5",
        "signature": "0xe0e28438bb7a6d0f06ae3976a7537caf1423ad70ddb3a96ed5fd406538737e521ad6869d27150cd9d250a3ac8764eb83d74faf923584e84759038933da8bb6871c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD3De017f4f256ECe1f82111FbDAdb46Bd9A5868a": {
        "max": 1,
        "address": "0xD3De017f4f256ECe1f82111FbDAdb46Bd9A5868a",
        "signature": "0x615d83a36b4b1706006154c1b0ed2783c1de4f2c5b1b0f5e4a9b9f5ba485278a6a84d1129f74529e58c3185655917531152b4d2af26a014a3e11f711d32a013d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x14243e5d57e2299E6931ABb4936854c41d960B5E": {
        "max": 1,
        "address": "0x14243e5d57e2299E6931ABb4936854c41d960B5E",
        "signature": "0x1b8e22be5df2ae5b6b76457d09a83b2d49d160088af15635f2b95fdcbfe4c239198bcf5cc2d5820a0330e79f294d69ba8c9fffd683a86db0f33199dab41137191b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe6F62C94b89A8678E25580e933deEeA415Cd5972": {
        "max": 1,
        "address": "0xe6F62C94b89A8678E25580e933deEeA415Cd5972",
        "signature": "0x139df7362874cca49c8a3c425fac87fe6d37e54929c47d08e2fab915d190973854ea164d7217b9a0b348c8f916032ad0429280a120321138680213bed88415a11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xED4E7189d0De3C006582BAF61D592eB9AF9f0fE1": {
        "max": 1,
        "address": "0xED4E7189d0De3C006582BAF61D592eB9AF9f0fE1",
        "signature": "0x13f816804c5c72e54b0f96ff0bcf61e7b559cee7b447b6b8fb49ffc658ea2548028f25c3193d2d62bbbcaa005a332007a04c1c4cbda4216b58c88878868fb8801c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC5Ae2824beE8b2F6927A4627A8eB5AAAF6576052": {
        "max": 1,
        "address": "0xC5Ae2824beE8b2F6927A4627A8eB5AAAF6576052",
        "signature": "0x5e60b32dee0ab7a42bcd5cc6711d4afad80b829b208a42cba4b82d799045238846eafaed85ac6f56d4181a754e61e11083a01ac8f16af8f315ac56460b6ef8201c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0aE198b51c2D519F91C9740FbD7a1C98532668e4": {
        "max": 1,
        "address": "0x0aE198b51c2D519F91C9740FbD7a1C98532668e4",
        "signature": "0x009eaaacfd2530b841b2202b4b4e62832d4c1be5dfc80532c0b92654c30aa2427f0aba197ec8d1aa4e1b98992b7170ecfbde670150defb187e581f62282f815a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x54a1b77C8F4f31EE81982310362b08F49ce4EF03": {
        "max": 1,
        "address": "0x54a1b77C8F4f31EE81982310362b08F49ce4EF03",
        "signature": "0xa4c6cf869a49a757f1888712c2834ca919b4842ecd8adb2f37dbdafd579f1f7c37d2fc740e543d9679646a57fe537983c1622705ff754f34694306d88e5618561c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF757ea546911b43C9Cf7217113e71Dc5E824dB9A": {
        "max": 1,
        "address": "0xF757ea546911b43C9Cf7217113e71Dc5E824dB9A",
        "signature": "0x28775bcaffc655f5f4bb5d1c54292fca6636674c7c77afa38c05a6fc55b9fd793e6393ce93626df8726fc9400b648d448b5a9857c856e40f66526251f8ca46911c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf87894e5983578588aE39323Fd8aE5DB705E7a7a": {
        "max": 1,
        "address": "0xf87894e5983578588aE39323Fd8aE5DB705E7a7a",
        "signature": "0xcc8a34282e914e93cd06901f4b7efe661cdbd6622ab44a35876917ecf2b32b9a5cd91fae5f12fc012048f3aff3c697192317b9ad4328bf9bf89d164135a651621b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8941Bfb3BAC9FeEe20B048f990332224FefB0F4b": {
        "max": 1,
        "address": "0x8941Bfb3BAC9FeEe20B048f990332224FefB0F4b",
        "signature": "0xbe7403e68fbebb7b7135c435f5caa4609173757f1dd1a5e9beaa0815fc36daf0725d65610367bca2bf77d1911871e7fe95badf86e1701e1f30e77e07cdd032ad1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC149ef1f64aF52dDCF16e5D4292750371F7f120F": {
        "max": 1,
        "address": "0xC149ef1f64aF52dDCF16e5D4292750371F7f120F",
        "signature": "0x3ca32ff3d1f2142155f0d2ce0ffe6e4d1c0feaaabfa120f9f3e93df837f4587926992e3f42e14dde882e9982bcff9c99216cb1a30ecd08928ba117e242cb73861c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE09137c7e389813e466241bD04525375E13a21A4": {
        "max": 1,
        "address": "0xE09137c7e389813e466241bD04525375E13a21A4",
        "signature": "0x5033036cb292fd6542f96356ceda7077fe16a15c640e6770b0a3e4a39029360854116242428c92deb2757164b9d8a03a98e690dcfb74d196f89d8ca0d69023931c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2ef0A1D9146A10FCB63faCA000622Fe2B229A7BF": {
        "max": 1,
        "address": "0x2ef0A1D9146A10FCB63faCA000622Fe2B229A7BF",
        "signature": "0xb912dcf01c3e93936aedc4d5915b7c9dba5354cc0c9d693b1c972042fe7df77c371d62dfae4d55630ce5adf7f4a81f98525507172fe85a54c4cc1772a79663e61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2b66B74C29C290286BdB8a6eCAD5C77936832223": {
        "max": 1,
        "address": "0x2b66B74C29C290286BdB8a6eCAD5C77936832223",
        "signature": "0xffbaea677552a3ddffee4237e5a768c54623c5f6deee033ae12ee11791554d67419c695ee21ddee5f1c342437c7c414d7ba3beb1348606511f0398d3332e75f71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2ed926BB162d391ea4c6d323Db6c808077592D48": {
        "max": 1,
        "address": "0x2ed926BB162d391ea4c6d323Db6c808077592D48",
        "signature": "0xa1e57d37a4ecb2afbf08b832bfbb62a3029dfc271043f28c204e6649b0f5549a1e23304a1e1260c1106d64654d92a24272663e4432dd069422b9f5b9ff5130741c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD5E481cbbc8ec434c8F38Fa1dB4Be1cEa890ED3D": {
        "max": 1,
        "address": "0xD5E481cbbc8ec434c8F38Fa1dB4Be1cEa890ED3D",
        "signature": "0x49d7e2b2deda758a6fbff76d4bc04a8625658e5e5d2e6a063ce5bbf090d0db687fab81b6bad38985c67fad9b4bf326b6283c156fe74fac7b49bc82ee3848f6a11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x48dE7Bfecc6bbC23D1B17fEE88E12d9C55e66Ad8": {
        "max": 1,
        "address": "0x48dE7Bfecc6bbC23D1B17fEE88E12d9C55e66Ad8",
        "signature": "0xb04a9338a500ac08035b4f6f36dc539c6917915fdde2efcb55acf6a1be0bdce04d7d9e3594287411e5bf36af709df1a4ae25a62465207721abb8bb09ea0438d41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8B33945a0395b21800fCBde943AB4Bd7D6fD7561": {
        "max": 1,
        "address": "0x8B33945a0395b21800fCBde943AB4Bd7D6fD7561",
        "signature": "0x2e10361de56be9bb8ffd1853eda50ec3f99d59f98a863c9156a6f6606d3d61b2047668a1296e6e9e24dc3984375174198c6fffb3dd09f24c9c44a3698df29e791c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEDc5668505eC640086Fbcbc9B043CcDea4E67622": {
        "max": 1,
        "address": "0xEDc5668505eC640086Fbcbc9B043CcDea4E67622",
        "signature": "0xb2e19a4214db9bfbd1cd6303892d4b4b94756c08195b382c43cba34487b49a93133326712e8699f5fc844b2d8a02fe1d6f549cc546689265df11736860af64f41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x50287efFc106EE5c163B635c996D98e3FB777615": {
        "max": 1,
        "address": "0x50287efFc106EE5c163B635c996D98e3FB777615",
        "signature": "0x119c3f1c8a99a426c709d548dd13dded34680b5e24e5b0a7da533684d763a9c8120e1adc7fb09804572c68fa597a77bf0b1a3cecb0818a81539ef41c82c2e7561b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7721c1f9AF2f13b2B076A13eff009C512ED4B0b1": {
        "max": 1,
        "address": "0x7721c1f9AF2f13b2B076A13eff009C512ED4B0b1",
        "signature": "0x277b84d8102b061b63a47ad0ef80584c3d7c3237991350b5364b25592c3ae4985b22ed15f298d3c0906e2e2dbeaa32ab5ebf73c04c0e7b3e14d44122e8f441481c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBa1be45D00702E999F5F0c1203FB7D73520122aE": {
        "max": 1,
        "address": "0xBa1be45D00702E999F5F0c1203FB7D73520122aE",
        "signature": "0x55c543be5b3222abbcdf5b227f733b054bb764450220c172a0eb56f47c74db0e428134d13ad1c3cdb69bd47717e56f85cd14e80c53c7bbd428cbd979aeb821011b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4B0b5a2E104B96e39b8Cbe85b06517e687C5De1C": {
        "max": 1,
        "address": "0x4B0b5a2E104B96e39b8Cbe85b06517e687C5De1C",
        "signature": "0x1ec0f7d60d9f68fbf22083b85ab33ec1ca196daa66658748f9055e263c87d2c951241f7f7b6b7ef7c23cf1487d4f1a60bbfcaf5fcf06767574197ac6086a7c5f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x54Ecb1Cf8DeF76775E898115Bda0f194f20552cA": {
        "max": 1,
        "address": "0x54Ecb1Cf8DeF76775E898115Bda0f194f20552cA",
        "signature": "0x371d8d7a07402492745c59a0f60c4f5fed27bb87f4c53505a8485f4ffcc6d1cd31a7d85f140d7ca2047354c3545d6c49c81f530f8b8fadc03bd57602628805e31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBC704E1C1D9f1189Bd18c096b0A52709601219c7": {
        "max": 1,
        "address": "0xBC704E1C1D9f1189Bd18c096b0A52709601219c7",
        "signature": "0x1bee5c9a78b686ef0343181d420ff95def1c7420e481417bee59e5a87c63288f6b98b94a6e5c413f234320a85e7567824362298cb7733d6afbc883836fcd33af1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x44Fb1c21109193bdd56c46e7b5b9a3D0ACF90BB0": {
        "max": 1,
        "address": "0x44Fb1c21109193bdd56c46e7b5b9a3D0ACF90BB0",
        "signature": "0x51b7501ee5da0fc9125bf1d9de4cbfc6b65f05af24d928a9a82fd0fd9a0daa212f00d6ae66bb12a372b29f7f845aaf183afd27f26f57e878104e979221c6398c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD0DF4E55644dfB9d7736fb098Ef07e06AF8F63A6": {
        "max": 1,
        "address": "0xD0DF4E55644dfB9d7736fb098Ef07e06AF8F63A6",
        "signature": "0x5489b76a4eeb80e32fd4d0d7d2a9cfb1d29c71c1abbbf97fe47b850093a43dad461131106277b3fe26ae53afb221c17e3c2376fec9412018fd1a7ab2620ac77e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x535B7885e6BA74b62Bd00377430740a25527486E": {
        "max": 1,
        "address": "0x535B7885e6BA74b62Bd00377430740a25527486E",
        "signature": "0x46526efc38db8189fd739d08058c1b27f7dfbc4fe33774689e7df3aafec0d6482e773faa5e599ebca26a004383a2a40530ab7ebb6ea6d9208e0454f9aedc62f61b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x83987205db02645EEd10A6A21c93745B91c9e920": {
        "max": 1,
        "address": "0x83987205db02645EEd10A6A21c93745B91c9e920",
        "signature": "0x5ba4540d18e0dfb531821a9a7d3d75987152e3e99dfdda0582d5e78c00d28d884674a7de9c28036db74b63945de138faaac3f85da5c57e43cfa86ab6c3b00cca1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2E5F975a1b1439B8806f34E2cAA6729D97fBF434": {
        "max": 1,
        "address": "0x2E5F975a1b1439B8806f34E2cAA6729D97fBF434",
        "signature": "0x954a39cbe04ed3c05fde5a62e2c3f8e633e92ad858cdfb88424eb517a3a82b9a4efca1726c5797ac25c6d2dae251adab4354eb26a47d9f5473f89ac95d65ec3c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC8712a2E41dbDd4c5fEF747cCB2f00aDD20f3B73": {
        "max": 1,
        "address": "0xC8712a2E41dbDd4c5fEF747cCB2f00aDD20f3B73",
        "signature": "0x291ad3e218730dd97a3b1ac6e4c7ed9ea24582e8f161520783388f94e0f9351d1b10bc19e5284450c43b0b043422130d810e5893c7a9a3f9e40eb55fbedad4871b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAB21E12A4310102ECd81F506F4192d8AB6dEac91": {
        "max": 1,
        "address": "0xAB21E12A4310102ECd81F506F4192d8AB6dEac91",
        "signature": "0xa24c7cef98b4d3607ae25d7ba148231c2b8a3296664309365e7515b8015dc3267af93ebbe3e54f66ef6d6778dbabf8331c917b6ed92c31426a48b068df66932a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE7fae237A1C0F459D5624d6aED3b8315ba88607f": {
        "max": 1,
        "address": "0xE7fae237A1C0F459D5624d6aED3b8315ba88607f",
        "signature": "0x5137b0a439c71e7e6231fd1695ce64866e0ae225e8a43f62c89d1e2945f49ccd76d89e00299ccfe7e0741f501f9571e69e62f4f6fb24e6a2949133670d1a331f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF029DaE8a21324EE13D22bAdb4C1c769559ba7B0": {
        "max": 1,
        "address": "0xF029DaE8a21324EE13D22bAdb4C1c769559ba7B0",
        "signature": "0xccf312ef03dd534c299074b1bd5da623b3fd27bc1b100f21b62074d4744bb64e439e1cfb22a63a335c0d026d5c8af59742dcd87af03d92eac6e8a5b8a3f6fbaa1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAD25680333d3fBb8562F6Ae7bdaeD657e9794727": {
        "max": 1,
        "address": "0xAD25680333d3fBb8562F6Ae7bdaeD657e9794727",
        "signature": "0x78d6079dfd823f021cdbe424cb9d64de759f2acb2631944881ee40de9f7cce607bc98d47e20bb596b4289296c4fd8ae0d2dc501026aae9f3cdd02b220c1cf5911b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x41433FE1fdb4Cff668f01E4cfF6464036ad35254": {
        "max": 1,
        "address": "0x41433FE1fdb4Cff668f01E4cfF6464036ad35254",
        "signature": "0x26e9a67ebb1e4b2a3f4da301c0797e6b67aab64e3957692d2adfea52096acba874de50a06a90b52164ded4cec2d721968231d85f9427907599d9dd8afdcc124b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9f5444798EC6a5C743777482f8fC4D7E4e0A4556": {
        "max": 1,
        "address": "0x9f5444798EC6a5C743777482f8fC4D7E4e0A4556",
        "signature": "0x3f9c557e55efa0cdd52ef7168a9d2a8f9657d685d2b19a43e5790db372a9b46a45968d8f1fa4af0a6f055ea60cfc42eb33f3cac4be5a9c8b8720550d0297ce2a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8C3FE279D536E45bF9f1DC17920EE9c5A2DdD018": {
        "max": 1,
        "address": "0x8C3FE279D536E45bF9f1DC17920EE9c5A2DdD018",
        "signature": "0x59f5130560b6029b2b80c946c73a545c83b1cdf93acff38d15c5ca828c999acd49b13da3998ad840e901bcd986cb150a6b942dbc6cb26d2a96248a559244f1101c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbFbDF0Db5659483f79D34993966E2621583FF961": {
        "max": 1,
        "address": "0xbFbDF0Db5659483f79D34993966E2621583FF961",
        "signature": "0x4c688da9120e5fc0cc365d8d42025ed51de9ba95b2623568a1e972c6e51b9b4c6e9144ef46feac125fc008aa9697f4dedb127fd39dc4adbb7c6018815db4bf011b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x745cB8757f74840A579D70b366F7869f99E7F4C1": {
        "max": 1,
        "address": "0x745cB8757f74840A579D70b366F7869f99E7F4C1",
        "signature": "0xa23cebeb3fb2252ac716e206b82d12cc42dcc710d6fbbb59f3e542eba45df56f3d64dde77a8f223f35914a6d843c156351231a09a250f95d281b379fd5f5cea11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa5dE2574BFC6fE642bC6B0e5aa7bF920845Cdce0": {
        "max": 1,
        "address": "0xa5dE2574BFC6fE642bC6B0e5aa7bF920845Cdce0",
        "signature": "0xb2302c4d9a5f3fe6401cd14f119f3aaeef9da8483cf6c161b73544dc75bd00615cd911085d8fddb14552c8df86836a488556fb47ad248f5b9712e6ede441cadd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1De1a50769C3C0489f0C419ce1beB7c35F7f0476": {
        "max": 1,
        "address": "0x1De1a50769C3C0489f0C419ce1beB7c35F7f0476",
        "signature": "0xa0a79108792771ef410e623fa35222de3d1c61c5f1451fa50611166f305134f47f4c4459511b2ca84c38ebb818108df5bc637061f409dab784e1720d8b40ef8d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7A567935cD9F508988D47E566FE1793635d79248": {
        "max": 1,
        "address": "0x7A567935cD9F508988D47E566FE1793635d79248",
        "signature": "0xfe46361461249421cea94a260e861c48161ad73333c4269e9e12d184b2fa9c2f7fac2569f04d60e965fc1ed2187f70ee34850bc392a5fa3cd23408d078e8c5401b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaD4A0Da40941B1454730C4E1FEAFEDaDfF7f6256": {
        "max": 1,
        "address": "0xaD4A0Da40941B1454730C4E1FEAFEDaDfF7f6256",
        "signature": "0x785217a11924dc28b1fb7a51661add355326bdf7f3de18002229342c771a30e24f9e127740ac34b878f23a52ef7a4a4d65c501c8761dd5c4a63563cfc8ac2cbc1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe404F7D0A8b598CC1aDc794B8B3A89A9FE7a295A": {
        "max": 1,
        "address": "0xe404F7D0A8b598CC1aDc794B8B3A89A9FE7a295A",
        "signature": "0xb5997964d9246e9697c76c0885c243d2eba8bc5c529d3fe63bd3491289591f6a39502e541d73e8895ec53006d477a86a69b09ebba32b6cf521a7989847984cea1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x67D2B4CeD6242198f24472F9cdfa17419ad995fE": {
        "max": 1,
        "address": "0x67D2B4CeD6242198f24472F9cdfa17419ad995fE",
        "signature": "0xb170d7dda36a0fdfe832f41385ac1e5293440c9c81f9b537c292791570cc6f747c1277d29f984979493575075b829cb204c5067417bb3a8cd173947b7f05b7531c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x42e52c835c81368074e70483B1dF925bBa13C6B3": {
        "max": 1,
        "address": "0x42e52c835c81368074e70483B1dF925bBa13C6B3",
        "signature": "0xcee84947bed263c6d388a76af4392f9a1208952d3909ce484d56579efe5959de5347209c72c55a5b58755f94073ead8d31efc81450a1379c27d5652aa1f0f2861b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1882477Bd21F43f5f61Fa58B9A5283a27356F57E": {
        "max": 1,
        "address": "0x1882477Bd21F43f5f61Fa58B9A5283a27356F57E",
        "signature": "0xa885d78901fdd29edc6cacc285eb6c0e3d60724c087919a27539237f144776ab7cfe0ae726732202ede175ad1770ff4b6b34ae49c3678ace5ce8447f3c3cf83a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xaDE58479294C9A9CEc09887093E7eB39aeaC6572": {
        "max": 1,
        "address": "0xaDE58479294C9A9CEc09887093E7eB39aeaC6572",
        "signature": "0xa24c501364e9695d291918b7e58e7c9244a9306aa41f401459b1aa4a9184a6245655f020e51ab2cc34a9d2e88f810b4799c298402064b9500d48bcc4abe824321c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDC0415683CD1a664048b73A521fE725911b3Db38": {
        "max": 1,
        "address": "0xDC0415683CD1a664048b73A521fE725911b3Db38",
        "signature": "0xb874bb34dba784614ad8e1e5cde1152bd883ffa1469a6a0f05f9381d3c96bfff52803e91e3b179c15f7f669cd4f4da14b21bfcf4987a465d7f4ab2a6fffe494b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x079bF165DfB7BcF38C21592B1574cD7A7C8D735c": {
        "max": 1,
        "address": "0x079bF165DfB7BcF38C21592B1574cD7A7C8D735c",
        "signature": "0xc28e794721e58c03e0c9468ec47942e8c7a076e59df6209c03f5af3b3d008e09535a351d878dbc8829ed3df52f121694ddbefc628a42f7e2acd67f30d252182e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x78B93eb839aa3C500527de686170aaA465FcAA4D": {
        "max": 1,
        "address": "0x78B93eb839aa3C500527de686170aaA465FcAA4D",
        "signature": "0xd20cb1b1cf412d9c53135512bdae88a6e553acafb19b6ae616ae039354c03af3011c76130745e24b346f8c0baabea3b3b6e9d32c5b80c1150cd42bcebf2c0bc41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x90fF6E37e4bB8E91784881275D76998fd33aA111": {
        "max": 1,
        "address": "0x90fF6E37e4bB8E91784881275D76998fd33aA111",
        "signature": "0x1703d308c0a2c801903dff343458e1651ed58fbf32d64cf98c7016fb527697666ce4b891d5277b4f7faff631ba71cd2f3c497b8c68756172cd57fe7ca9af932e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1C2B2C05d5786403C38904F3da8670a53267Cbfa": {
        "max": 1,
        "address": "0x1C2B2C05d5786403C38904F3da8670a53267Cbfa",
        "signature": "0xc3fa7420e2b1e39313699deae48b6ca2d53ecb352bf7f35efbd5acf271e69c995656f43e5f69a4c71bd1f223d8123fa9a7d267a75e86f8468405428381d5b25b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE5e76b4507E08f8795B191a8887b74B45Ef8D87D": {
        "max": 1,
        "address": "0xE5e76b4507E08f8795B191a8887b74B45Ef8D87D",
        "signature": "0x6b6dd3a350142749d03cc7913df7ce69f904ae183e3d0eda69ae46a3523ca4c1386d1525d485db8c2eac52a07c08ea4ce05a2764689a682a58250e83c7f246f31b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x36D6336D48132D85acef8A6E30473C72D9e01e5F": {
        "max": 1,
        "address": "0x36D6336D48132D85acef8A6E30473C72D9e01e5F",
        "signature": "0xd37cb714237274ad076d22fa2a6c91c20c4a9bfa918259be2f2b7e3a62debb5e3925b5a6fb97ab7d0a3dfa3de0468e2152d993ed48a0f67223cbda1baa08bf3a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA088e5b7897C4d43a1f6BC0A0d6B470b9e275913": {
        "max": 1,
        "address": "0xA088e5b7897C4d43a1f6BC0A0d6B470b9e275913",
        "signature": "0xc66bd1d54031c434db2430002dd4c0626dcc12160c255a43c01b9c09a0c320ab2cb18e0335c396093c28d43485097aac46dd7b9f246647f544cc2ef20807519b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEd1E31618a8C0B7E1a924a4d333C064fF13102ab": {
        "max": 1,
        "address": "0xEd1E31618a8C0B7E1a924a4d333C064fF13102ab",
        "signature": "0x4b717b1d14c92023107a8f25a6517acaab045ecf4b7dcada39543fa3a11a83122ac176caa684255b68102bb6b2b301968d3fd026ee11541d0e4525798fe6dee71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2aDD9ea67a2544e6A98FbC69Be8C057D4D1715a8": {
        "max": 1,
        "address": "0x2aDD9ea67a2544e6A98FbC69Be8C057D4D1715a8",
        "signature": "0x2aa9cd30202690ebf0855ac42239dbd07f38f48332b6973177d50907765dfa04198843a24a25c034acca40d64e84317b30df55d66bab715078e9a4da9d5267891b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3782Bc4312A328eA2BAC5815E30c4B5bb38e199e": {
        "max": 1,
        "address": "0x3782Bc4312A328eA2BAC5815E30c4B5bb38e199e",
        "signature": "0x63bc2c989288c15fc79747eefcfec395dbf16308ff3ba53ab05b330d9b2de56660fd8092e0dc42851a33741871b7adbaaff7428057813563a68498b713d3d97b1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x08475C826B7fF94B5887123269ED71c5E4E399B1": {
        "max": 1,
        "address": "0x08475C826B7fF94B5887123269ED71c5E4E399B1",
        "signature": "0x59211f117278dcd6834cfcd743361aef3b23c6267fb91325d850aa5790670d9a7e3e2758802b7d60f25e6e3b7f0ce60cb2d26e5c2880efc44a0ec119ed6954171b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA03c3d24c1F193df11E3E68A8E824a55eaf5046B": {
        "max": 1,
        "address": "0xA03c3d24c1F193df11E3E68A8E824a55eaf5046B",
        "signature": "0x32fb7245845c31d64c6e4dc958872dff08d6874ca969b818b03104aa80bbd822729d30d93c5a175cbe56f3ba8db7c1b5853313ae8b6f73da5411103a1c01bd5f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6784660BB47a51B85181dc51154355FdCFD70bC2": {
        "max": 1,
        "address": "0x6784660BB47a51B85181dc51154355FdCFD70bC2",
        "signature": "0x9a9479d1921d9ea5242d2e0b825b872fb51fcbbe3538e50ec9cf4ec805a193e04f0c0408f1f6c1c382899e38df9e816289a16f596c9686c0e17e3457361c8a731b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF809795B42Aa1Ea7170010Ae8471fAbCA900a4ED": {
        "max": 1,
        "address": "0xF809795B42Aa1Ea7170010Ae8471fAbCA900a4ED",
        "signature": "0xe2f4d38c27fdcc17bfc43ac4dbf83d867a94138ef69eba1408a9b159f381b34e72a7ef0cdfe8194d0c9e731d12a16a8b5a21c71e50ccd1388f3e3a401e687df31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfcC8996F1Aa16Ae0E8D209427df3E6763A5aBAA0": {
        "max": 1,
        "address": "0xfcC8996F1Aa16Ae0E8D209427df3E6763A5aBAA0",
        "signature": "0xbade95d9380cf44c87c414b6fe3501ef6553728f7e5e078dbb9bdcaa4cd9c9cb25903590f0d9a4b59ac1565a3dee8621245ce4c6092e8a449c19f6f5b072ed551c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd8BC8191648f2605d670fF876D786E4eE6aEBC56": {
        "max": 1,
        "address": "0xd8BC8191648f2605d670fF876D786E4eE6aEBC56",
        "signature": "0x7cf3010a9bb09c49b719d864d52265f41c3345165dfe34a1bef3d97bb66c67f84d2442fa0907feee97999e245019103ce3ae21ab6188d42c11a925960fa2a38f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeC8affBeb77065B4a2a65fd586cf08c5E5Fe7B39": {
        "max": 1,
        "address": "0xeC8affBeb77065B4a2a65fd586cf08c5E5Fe7B39",
        "signature": "0x245892881c599af8df0bf42af78a78a0d128c4f6f19fecddf91899e67325032c0b4cc0e21bdcc78e875bdb9c126940a4ac66e7a5956067838beb188b36f62c8c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x126Df08C0Bab851be8223337e8367749d31B41F3": {
        "max": 1,
        "address": "0x126Df08C0Bab851be8223337e8367749d31B41F3",
        "signature": "0x3fe94a0ad88967581c586201098a607444e44ca859bedcad021599d73fb5f7a45f9a061a239450053ae31504ab7c9c1bbd9c6c1610be2319924a9e4ee7a1b75d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8F02b346Cc93922d176Df250d3a8248c29C34E30": {
        "max": 1,
        "address": "0x8F02b346Cc93922d176Df250d3a8248c29C34E30",
        "signature": "0x15d42a1190e9ef9c3ca6ab7b31cccccd7327d1252f30b55297cc60e210e3eaf85a678898bd36b5be07a906e35c305fe0c78a756bd9ededdfe474eb8c941aa5ce1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x242b9be3DB7B2249B1b08FA6AA6136DFAA82FD09": {
        "max": 1,
        "address": "0x242b9be3DB7B2249B1b08FA6AA6136DFAA82FD09",
        "signature": "0x6e734a657b7d2c870e353149c2a9e340b60d0e056af46e161031fd3ae99de43a6786ae1e58fa1283c53bf12490f980253807cc3f0ea7d02c73335d28f80de96d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x694336e1fE01850D8f1B2998AfDdcC8089986ac4": {
        "max": 1,
        "address": "0x694336e1fE01850D8f1B2998AfDdcC8089986ac4",
        "signature": "0xff43c7abae303eff1033315f9a8ef1b6d72f794a99beb3679a698b20d74e7b22502090498b8731cc8476403cc38d4daf71d4fecfc6d4111f909289fb70c0daf41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xE022E13Cf3D483c247A12D27D38709Bb596ba471": {
        "max": 1,
        "address": "0xE022E13Cf3D483c247A12D27D38709Bb596ba471",
        "signature": "0x64a460c1d08530c03baaa4c46750c24d205df1fa69eca2eba8040567ebd3e8ae4cc909203c428eb02335a9f75c154ce410dc5c0bd265f8ee9f2db71668fb1ac81c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x679994a1f82be819c98b65059a7e67C196B13487": {
        "max": 1,
        "address": "0x679994a1f82be819c98b65059a7e67C196B13487",
        "signature": "0x0351e2528bd72e165c3e2308d0c13e76d3e6d25e88da2dca594dd26b0c3696334291cd88d52cab41241084b55f30e5569987f25f85474c938521c8c686f8f5351b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA0fB00A310b3D4997307DE571d5b486D8B9307Ec": {
        "max": 1,
        "address": "0xA0fB00A310b3D4997307DE571d5b486D8B9307Ec",
        "signature": "0xe0741418089d59cf51b58e4dd9f069abfae155048683915441ed5a715417bdd445555ed1994f613bde099cc988115b5f30ca735a648c62715154773c9a22ec8d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC535522d9a23617626d38df6C3774f2da7d53943": {
        "max": 1,
        "address": "0xC535522d9a23617626d38df6C3774f2da7d53943",
        "signature": "0x9a1d7212774a5cc32d486abefa3aea4df1f831b43c45d1f0d0546c8e0c6630f822da53d4f40eb1078435400be819fff4ad0c89bdf3fbbb0f02e794c1e302d8721c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEfCB78f883514323c80C65FA7bDc9d7224327867": {
        "max": 1,
        "address": "0xEfCB78f883514323c80C65FA7bDc9d7224327867",
        "signature": "0xb2c7b45fad65a92fcda0a509db901a492584e60e30fdca83cdeedf4f99291d7f15c4e2a0c3a2f1eb6ad1488bb61e363fba3ed887eebe8afdf34d84649b5fbfb41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3A0c26Ab64f4230d8a2485F5F5475d57BbF3cb27": {
        "max": 1,
        "address": "0x3A0c26Ab64f4230d8a2485F5F5475d57BbF3cb27",
        "signature": "0xb3e77cc867298de3775dffe45c34ab88dd8fa84891afaee4ca30ccb40193996d1d3948bfcd5b726af4732dd5a58ea72d3bdfb79e06bc04b636e5507b9cd33c8e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x25FdDA4d128Fa2640A6De744E55fF73B9251cD65": {
        "max": 1,
        "address": "0x25FdDA4d128Fa2640A6De744E55fF73B9251cD65",
        "signature": "0x277e06f9173b50abc47550e38c5d1463a2ff978574fcf95d4095181cf07b72b453e5c8fd9a54df7ad35ad21941f0c89013bce134f27b356c157f908693b853a11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x65c3EAE720c9095E423e478166Fa0e46dbB37d01": {
        "max": 1,
        "address": "0x65c3EAE720c9095E423e478166Fa0e46dbB37d01",
        "signature": "0xe252e1015de015791b64455bed74adf82a2404bb5597fecb0ef4824e75d8b8af12afe03c6f8cd00f369f89ebf70d1d74d6b0c0eb117cba8a908497645052f2cb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x21C9A6758778D2b42EaD8b07e9B974502c588888": {
        "max": 1,
        "address": "0x21C9A6758778D2b42EaD8b07e9B974502c588888",
        "signature": "0x8dac55c509dc743cc5ed77a7d73804cc16b64ce30b30aacdf30f4d50da6299b7732734dc01378a0aa44b63081a63f876603c8b3ebc6e1400a6280a5cb5aa83cc1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xFedE3Eb4debE099f3467867F07fAf394a80243Ee": {
        "max": 1,
        "address": "0xFedE3Eb4debE099f3467867F07fAf394a80243Ee",
        "signature": "0x2b1569959571ee1a467e2760a2ada54556c4b782a19c76a2304b7e4def514e442c949145c5b7debe8e38ef016b286272e32769be8604a833824fc2fc334d9ee31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x052dEe14da49B73552B9A78128a7aB41dfE70f2f": {
        "max": 1,
        "address": "0x052dEe14da49B73552B9A78128a7aB41dfE70f2f",
        "signature": "0x9a4b374a2e61cc74c0cc6f8cc3dea2d627d7170e88e7d8870c7afedc54e9fdcc20ee6faed37c161fdab84443ec327a823d2593a3064f45e4095874ba56e471ad1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xd7166BA136b327f42F58244996b766DD1A73Da5a": {
        "max": 1,
        "address": "0xd7166BA136b327f42F58244996b766DD1A73Da5a",
        "signature": "0xa90c3c9cdd037ed4844185bc23b7e36e3be64f60e9cc4c41335d941b3688adda6145fad59ac8a5498f6e08ee1a81fe2ab9686a232947a85e1de493ad81f54dc11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6aEA87fc607cA9EF5e6E9C9e3cDF008d77050926": {
        "max": 1,
        "address": "0x6aEA87fc607cA9EF5e6E9C9e3cDF008d77050926",
        "signature": "0x80ecf81982097f3636270346abb5448fbeed418a15c13bf1b039959e3706650c04ce0f4f1ed98d0f8e6be587444baf640c01b5cdaa694372c5283b08a5c97edb1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe61252912c463f9A6e083751948C918d37243b30": {
        "max": 1,
        "address": "0xe61252912c463f9A6e083751948C918d37243b30",
        "signature": "0xbded615093351c3d6bffe77ebe6b0e51c2e1cf852225962c368e04bf4f244bad1048dd2b6f9e4a43cfce50d0f461fa9630ef1d97fa7814bc29f71542f53adb4d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xF4460453cB044336DC2B2A765B1193D6B804F70E": {
        "max": 1,
        "address": "0xF4460453cB044336DC2B2A765B1193D6B804F70E",
        "signature": "0xfd589c1495d8ecbc72a8074ec8050dca003b4b24b8a07ee72ae1e4900e6cfc792c1eda4edfacf9195ef311e4a7cb71aa0a00561a6b92d6e591cf63cdc09c653a1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5e6fFF2F946af0fB0D48E9314194376D71730adE": {
        "max": 1,
        "address": "0x5e6fFF2F946af0fB0D48E9314194376D71730adE",
        "signature": "0x1b69d6705e71bcf208e6e05c8bdcc08100a83abf7db4beb0cb4d3ca276ff27fd1ba2e64fccb6f73dc8c04ca4f40c3598e924d7a455e67705e868f655454648cd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4E72d52aD1C80A35159E3227cb5B31c5811f3214": {
        "max": 1,
        "address": "0x4E72d52aD1C80A35159E3227cb5B31c5811f3214",
        "signature": "0xef004e9f7d3858be39d78c804bb57b94970354451ab2c7215a5e534a394b2415577684ef99ff713363ac3f0a42da4c1d0b486a984e2cd2dc8175bba56543be041c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x309Cfe6fe2042064448eC84Dc2B432D5cf764b7b": {
        "max": 1,
        "address": "0x309Cfe6fe2042064448eC84Dc2B432D5cf764b7b",
        "signature": "0x99faabffbe8a493bb313657649819610a088baa52f08a52f1a72cc28d0a8ddf42c170f3e410e7a727d416148fee99e445d380a0309e56cc282010a811cbd0ca91b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7E6B3bd0421E207c0ee479483327F65750C2957C": {
        "max": 1,
        "address": "0x7E6B3bd0421E207c0ee479483327F65750C2957C",
        "signature": "0xceab87dbc1d2bad47f0e3f05055ebb9f6a78070e07791bee656cc0a7a897775105b907bf1a8619817c8120dc29cfb0c9cad48a104ff690338818180d642fe62c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6f4EbC63E5862353FC51502c2265D46EE720a87C": {
        "max": 1,
        "address": "0x6f4EbC63E5862353FC51502c2265D46EE720a87C",
        "signature": "0x91c1da802d2f8796844a4449ebf5410f4448f5afe4d3234203c56beb9091509d58bbcc83737a503a11166c2283c58fa354cae556d9a4030a7176dc457e64e2d81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x345F80f2A77a15c40E5a0E04De8060D2aEA37040": {
        "max": 1,
        "address": "0x345F80f2A77a15c40E5a0E04De8060D2aEA37040",
        "signature": "0xf6eaffb21fb8af05fe258bae239cd0dda9108ccc6af6c1c072f81fa8e2210a1113b1895fa604cbf51f205d8fa65e0e851962806e38324eea08cd1125a8e4c04a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0cA0258eD53f83a783EA7626F9DDb2B7Da8547C9": {
        "max": 1,
        "address": "0x0cA0258eD53f83a783EA7626F9DDb2B7Da8547C9",
        "signature": "0x81b226f98deb11c8618dd0e05be58327e8f1975a1da8af6897250741fc9d19c46827c00ed8f6fb9f105afa840e6dd8885f4143c38b80673f56a7aa5d48348b8f1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x912981441028ddEC29736Ec86ECf4785B007eEfE": {
        "max": 1,
        "address": "0x912981441028ddEC29736Ec86ECf4785B007eEfE",
        "signature": "0x8e325bb12ad614b2876e3d9de928ecbd449dd32fae852b30c41aceef72ee385c0b3b210192c1a6d327d04df0a0cff413f20700f77c920ebd3ede77f038a186331c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa6E562B7aC88b33CFc186d2146E826f43eE76BAc": {
        "max": 1,
        "address": "0xa6E562B7aC88b33CFc186d2146E826f43eE76BAc",
        "signature": "0xe9b4b5980bd1c2f5cb17dfe6c21f4e62d9d9c03c7c3b20fa58731672908910aa321dbab4d6f3f19c7b0e888c52448f75d4a5414c4f998a9ffc1948ad6e4a807e1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8c97B171738aA8fdbD0bb73a440Be2424F840635": {
        "max": 1,
        "address": "0x8c97B171738aA8fdbD0bb73a440Be2424F840635",
        "signature": "0x8d6a1a39fbcbc937560a7df574380c3b21dd2949a00c661534d5ae5608d729aa4f8b29254aa5c6a0857af2dcb99ae6547cdb661662c703f765c8b8b247d626ad1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe0b934154c07bc53B7A59684143a77dB98d73eAf": {
        "max": 1,
        "address": "0xe0b934154c07bc53B7A59684143a77dB98d73eAf",
        "signature": "0x25c3214e3ed8e34ce9d5e5682b3ebed220416613b6e5cd64c4c2cd629b19842d2358f4cfaef79f8da59c0bd4887d34e20b3bcf16d70c8be278a74a464caaa31a1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA51AC38c5924b20Cc543F7e5e21ea692b328B0B8": {
        "max": 1,
        "address": "0xA51AC38c5924b20Cc543F7e5e21ea692b328B0B8",
        "signature": "0xd0397d4727ec1420a4c893de85fcfd15aefc5db183358165b8e59a8a85fbe1c86b70f2943829740599c9b566e3e749d31e9590198a5a5af000754fc8338405b81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6867B647D4AB5DdF0743c3AffBb8F1367c8809ED": {
        "max": 1,
        "address": "0x6867B647D4AB5DdF0743c3AffBb8F1367c8809ED",
        "signature": "0xda8bcab9e9ffea4e52de4b19422166bc40775e94c4d503dab343a59d26e0a35170694dd90beab07ead4fd8cd02546f3f20191007526ce1679c753af05bbbf5181c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xEc0B68626F7f4B4ac4c9caE6deF857949bE12A2D": {
        "max": 1,
        "address": "0xEc0B68626F7f4B4ac4c9caE6deF857949bE12A2D",
        "signature": "0x5361eb40b05f77785a98efbf1b30f567678a565daa7f74f5213227830b8d22ff092d2d2199f6c1d755d81d9270baaf2c6d1e2694f57e6f8e5ad9b766c8f5bb771b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x16a3b7444F760DdFE53D39CEa162c7AE5243d159": {
        "max": 1,
        "address": "0x16a3b7444F760DdFE53D39CEa162c7AE5243d159",
        "signature": "0x2e530f5de34865936763939504719d6daba624d12f94e4868577cf57e8a817322f68120360db43abdace574fa638ed1c41a8beb7e96c71022134a1a6541ef1221c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBf5940c5CD1a91373eFA1f083fF310b8b9a18BF1": {
        "max": 1,
        "address": "0xBf5940c5CD1a91373eFA1f083fF310b8b9a18BF1",
        "signature": "0xda863bf27195bc9bd2ce2693656595f6370fd98fdb0f48baeaf2b9c1739be3552ff7faaf46cee25beee812e6ac5d511e40b0db03b3dc7fc8a026a8302469e2da1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5939F99BA0F0c8e538c1604AddaDC6Ce4Da5Aa1d": {
        "max": 1,
        "address": "0x5939F99BA0F0c8e538c1604AddaDC6Ce4Da5Aa1d",
        "signature": "0xab3a13d2df70c22b18bc8aea783b5a9e994ee28d2fd839e2a46e6bd7e73be17107bf9d09bd4474db787bcc0f157ff5fec3a2d78834f484b2912d36c49edd1a851c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5290F195c2d89F121406e0d9F8cFf19cE078BA97": {
        "max": 1,
        "address": "0x5290F195c2d89F121406e0d9F8cFf19cE078BA97",
        "signature": "0x89f964fa0a37974b0cff00a52e03068cc017ee987e923bab57ca5873bc4d9775471add5cfdea19a1af5de22aadf1aa9b1183f841d5b56b9b5160b35a80aa55a31c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBcb65e75e19a96F2d17441EB49A34D593Cff51d4": {
        "max": 2,
        "address": "0xBcb65e75e19a96F2d17441EB49A34D593Cff51d4",
        "signature": "0xe524671c8005032844827eb495c9634dbaab287a5314116a0be29af755dad47f5751280d785b74d7e0c9b38e3af7d3c886a91e8d3f688b9a99c26f9dcd8a9fc61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2fA000c0E99e3b6454BEd942d8eCCD26cFf6D5CC": {
        "max": 1,
        "address": "0x2fA000c0E99e3b6454BEd942d8eCCD26cFf6D5CC",
        "signature": "0xff75a472b17b5a2f26dcb5ce980f2e7fe267b3fe97bd05e472c2a1a20d7c4d3e05ac61ed8758dcbc95d4b7b17b21729349cbd76e219fe7b5fa549583fa038f491c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x677DFB4433692F5d45Aa3eD53231775366FFa6FF": {
        "max": 1,
        "address": "0x677DFB4433692F5d45Aa3eD53231775366FFa6FF",
        "signature": "0xe1ad7be6868e1477f0d4ee1d2cececded3e8d17cb9785bb38daa8584edf01f247341fa64d28234afccc339c97e740b32dc5df1da4c38b40e1b549aba3b0f6e8b1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1EE56bD2C437414449FF0b6a926fBF359AC22113": {
        "max": 1,
        "address": "0x1EE56bD2C437414449FF0b6a926fBF359AC22113",
        "signature": "0x2ad25b237b7c8e000cfd7a467d7440e4fade88f812949f6f04575e6886bd6dc979b69074012fa73960fb12109145a954ab4f150527381f409c30025a104e11d71b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8d43dF22045B83c2434dDEC005c22A17e8aDbdBe": {
        "max": 1,
        "address": "0x8d43dF22045B83c2434dDEC005c22A17e8aDbdBe",
        "signature": "0xdcef3cdbec1897ddc862018075350f02abd83ff26a2bf1d8a9e8f2baaecac7d14e7edad156a319c7e3f2f38837463e48f97116c27435aa07ceedb6452e629b471c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x81d4dE84a05f05098114dc1194251f5AB2B30A4E": {
        "max": 1,
        "address": "0x81d4dE84a05f05098114dc1194251f5AB2B30A4E",
        "signature": "0x21e8896e4162dc035bbaca18337fad77526e2a727346b3697854e862933f5cd56b5a88fcf9e5c33610a75e472f65683e8bee2a03120cdd59a80b07789334990d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA303fF90c27679dD4aFB03FA323C4DC6AF2a9072": {
        "max": 1,
        "address": "0xA303fF90c27679dD4aFB03FA323C4DC6AF2a9072",
        "signature": "0xe3a2149498f00b140722709e0eaf329c7120a851f7a454dace57f905d472e8ac1119b5e8c34c54cdefc56d443d4998e8ddda877cc707908ce2db25c0a09ea8091b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x081812a2AA2A3F29Aa9FC3c2B4B475f33a824458": {
        "max": 1,
        "address": "0x081812a2AA2A3F29Aa9FC3c2B4B475f33a824458",
        "signature": "0x6e9665049b9747dfb62a2769a2940fe1125f9e887fdd6fddf0cb0c66de4881d55f4679408f6966b57597444501e2ce85b51333dccd8e86acbf1ea52ab600c9011b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x384d3074A4aEc2C783C0Fea1b81198b250b0eE6B": {
        "max": 1,
        "address": "0x384d3074A4aEc2C783C0Fea1b81198b250b0eE6B",
        "signature": "0x8f7ca1f8fc9bd0db8fea95b6865b47497c7c719fd2672311f831f715a494324738ef30b941ed3f2e5cf4dfe0f862f1a2604b1e4431352faa2de54b8bc00658ea1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC46A525602E29eF17E5f51385D91AE257E659c35": {
        "max": 1,
        "address": "0xC46A525602E29eF17E5f51385D91AE257E659c35",
        "signature": "0x792e73e8feb18d83742752d396a7560872f895b02e0d199b406664c1d01e611735ba55600a7ff3760e1d981056be1d23b6372ea9a099188d76d7dd8ed3eff6b11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8517128Cf1437123Cb711b4974bB35C12D5fdDd0": {
        "max": 1,
        "address": "0x8517128Cf1437123Cb711b4974bB35C12D5fdDd0",
        "signature": "0xa3c6e8d52b3899f15e64d161a723f84fdeac9a5f352a09757721e46e1dcbe0ff12d60bec94b4d705909f89492ebae2c3dcd38f3cebf66c85690c38d3d6d0ac251c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD252D4a5aF6894Ec52e13E373C52D06e9D5D44ED": {
        "max": 1,
        "address": "0xD252D4a5aF6894Ec52e13E373C52D06e9D5D44ED",
        "signature": "0xcc96d97b0741155b5a839550b3e738204205ed43950c6bae6185d71aa96a32b44e9488bbcc41c33d936e2754a0dabc7b2b1d0255c5356be35d10ee8cf1ddfcb51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x171Fd53d7B47De375a46a8652916Df6D3c145D24": {
        "max": 1,
        "address": "0x171Fd53d7B47De375a46a8652916Df6D3c145D24",
        "signature": "0x4dd5186abb3a15e1f03092bbf086eaa50483e914a05fa0207c0569f9aa407c38317e99fd4c9a3732294d8c4f03fcb4dca26488ae325b346507340918172d239c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9251eaeC9B56Aac38Cd5c6D5fA0b64A222d73D91": {
        "max": 1,
        "address": "0x9251eaeC9B56Aac38Cd5c6D5fA0b64A222d73D91",
        "signature": "0x0d0e7de9d515bcb53e698a0570ebbb8d08e7974591148cc45a2e8e8e80ed247025ad1785a0ca88a9c01929cd41ef0c18a0dee7ab27feb2c593461fa3209c3f771b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0F065845F74A4093Eef36F0e742F82c5a23Af886": {
        "max": 1,
        "address": "0x0F065845F74A4093Eef36F0e742F82c5a23Af886",
        "signature": "0xdf9310de10bbe0b663578ae14ce8d10baae0c200788cfd2f4bb81980f864c34f4e87d71034584375de13cac9429c9599f65260399897f4e681643374df7a0eca1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x4b8aaD5d489A34a9df375E3f337e2E5955e4010b": {
        "max": 1,
        "address": "0x4b8aaD5d489A34a9df375E3f337e2E5955e4010b",
        "signature": "0x2f85cf56035e911111387004dafa62def694db0e6c12f2ae5a9f671f0a78ae7537f5725ecf4f13598c5d6aeb3a28d7d1b4c8e59a62cd73c9592fbec80a87da101b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2981073dC9F2932579797e701eaEf813752a5AfB": {
        "max": 1,
        "address": "0x2981073dC9F2932579797e701eaEf813752a5AfB",
        "signature": "0x29624a06a4a98d4e63132700d8a6f0758688b9a76f30e31715474353332f2ac456e7e65c80623e5cda9ced5f0014b532a7e140fa771fd9e185c32892c39351901c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x939F0381e1Cc9654acc05df5623854EC34bB975d": {
        "max": 1,
        "address": "0x939F0381e1Cc9654acc05df5623854EC34bB975d",
        "signature": "0x3c91868a84935e3a18a263e31cfbfc674605635502060ceddc52dcec0033fe450227fdd4139c4012296738af84f80553b27a229c9b9acb8b26e87ef9044527741c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x41ABEF42cB6A2F6a135Ee52016eBfc5727A6D50f": {
        "max": 1,
        "address": "0x41ABEF42cB6A2F6a135Ee52016eBfc5727A6D50f",
        "signature": "0x1918a9aafe8b53a01d4f08ba114ba57a83c9cf72eeae03d5b4d53e6174395bd63317a577f20c94e37b8347af86c09cf259a8f35bdc7a8c0ef3392ef198f340111b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBfd6D252CD2bC075865283f8A26d5A41c252119B": {
        "max": 1,
        "address": "0xBfd6D252CD2bC075865283f8A26d5A41c252119B",
        "signature": "0xd5f6e94de4501906258ebcc67f6dd48d99aa4b2839de0d50cc20f680307175025aa6e3aad6b882f15de90d4651497adf607cd9f9bca23df7bf5fe14829fb4f211c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x84DC4343F50Ac947954bb1C4aCa7145d92B9C642": {
        "max": 1,
        "address": "0x84DC4343F50Ac947954bb1C4aCa7145d92B9C642",
        "signature": "0xf2318a5b478e649a0e2c7cd5ae3afdd3be73ee6bca2e8078d9d0dad8cfa075707cddc0861d8ac64630a9546fe836896c302eace20194a161b9a9bbc44366c3f91c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8520201Ec6Ab08AA35270efDCF28b51a826bcd97": {
        "max": 1,
        "address": "0x8520201Ec6Ab08AA35270efDCF28b51a826bcd97",
        "signature": "0xf8cd5e8a34a1ee1259cbe0522967b6854a28b234ebf6b4a2f2ff0c8f465541193a3bdf7f0c121694408366b7bd939cb75b52d4b456ce109e8fc4ca5cde2c08f41b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2D97a5A8Da17227b9b393ca9Be92bfa1a46dF179": {
        "max": 1,
        "address": "0x2D97a5A8Da17227b9b393ca9Be92bfa1a46dF179",
        "signature": "0x8b5e54265497ffa5d1abf08e69385208c784030bb16d56c394e61c0faf6be9d27540b7b577ac87012b40184cdccc38ce5133f2c24bb0b995fa3548f3060f415e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0a7de4ABc82688389C29DCaB6E8Db5FE51B56429": {
        "max": 1,
        "address": "0x0a7de4ABc82688389C29DCaB6E8Db5FE51B56429",
        "signature": "0x975f2b19b959095e89515c3bae42cdfdcffaf1213c73f015010074eff05b40e75eb98cb7e255cd94e3140c6c67a402736c61efd0bb4b4ce68ad7d9e1b72b86661b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x5f4F284a9f4f4B2cecDb8C53Cc01F433caf71fe7": {
        "max": 1,
        "address": "0x5f4F284a9f4f4B2cecDb8C53Cc01F433caf71fe7",
        "signature": "0xe5d12c0cd60f1dfd993d254f1f43924968fb7fae19b785e83b763d544ec6031e6ee3ca99ae377b755f68c0a588b46d5074a4fc49f42fcd17f15118cc8838f4ef1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD83836784FAb639708CB0E9183082eC68D91d1b0": {
        "max": 1,
        "address": "0xD83836784FAb639708CB0E9183082eC68D91d1b0",
        "signature": "0xb3859d563c94b6fbda9d341e18c16a4814b37a1cfccc00075c49f2a6337cdcb969416116e4e7bcf31562213c5a7269c12df6455b319e8a528c42ed49b49e70471b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA30B6212E131D6E9AA8BaE98B9F711c393cA81FA": {
        "max": 1,
        "address": "0xA30B6212E131D6E9AA8BaE98B9F711c393cA81FA",
        "signature": "0x9d773126eb22003f5bf3255aeaa8bf162bc542f58fab9ebbb1aea47bfbcd69f763cf659969d334a9e3443059fb545c30a36023f715bb699886acb71d97dfe5ae1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7eC047756A1847DD8e8FdBEc5363ea2e31010331": {
        "max": 1,
        "address": "0x7eC047756A1847DD8e8FdBEc5363ea2e31010331",
        "signature": "0x74d39e41647475316a3040b555668cb07c19d92b980cf0abf3a0a17be00bffa708e3a43cb88dd9d5fe14eef59c74cb527838f6ce1ebfef86c03242f45ad40b3c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x77b14F509c1DE672aa6d3260C9F6244E7252F332": {
        "max": 1,
        "address": "0x77b14F509c1DE672aa6d3260C9F6244E7252F332",
        "signature": "0xd725f81d34523181dd7822e8f3ea6c1d7dcb850a5392da9b06fd748d9669dce060e8650b3f0098a58f4fea77892145be9ce7749436640a75f7a83d26b8df1dc51b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x70bD6339a06d2F8a3A76666c47979c6b3264a578": {
        "max": 1,
        "address": "0x70bD6339a06d2F8a3A76666c47979c6b3264a578",
        "signature": "0x7add8d2a15cd89aa547c88af549a0f36417345425240717a9f84236ce074952c52f35106ab39300e7ccfea139f4c2e4b3b18c8fd33ccdde2724968c4d28e14de1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1cbaAFea429Dd0FCbe77e6320b47D0444D3Ec97f": {
        "max": 1,
        "address": "0x1cbaAFea429Dd0FCbe77e6320b47D0444D3Ec97f",
        "signature": "0x2092e026539f8b600fd1928b09606b549d081c2038138bfab8938e07e03196d759f9383a100dd3fa9f09882115535feb4035e1aae208eeeb73b482385c30d0711b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xe7301bB5a55D9db75d67682F74603381fc039F5e": {
        "max": 1,
        "address": "0xe7301bB5a55D9db75d67682F74603381fc039F5e",
        "signature": "0x45b1ba14f3a1083b9818b39661544be3b7b375aa4ba241808669e517042885bc2e5de4f57dfa44fef43de5b4df465ba13db911d0b2fc0d2230454233333428e11b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xbBD0b827256061dd8e9Fbf8A2eBDBe690D96885d": {
        "max": 1,
        "address": "0xbBD0b827256061dd8e9Fbf8A2eBDBe690D96885d",
        "signature": "0x9035a81b6b0714f67c42c78997049f5f448113418440af281b105c0d0b5b956f0c38b8abdfdca4267b241dab98c6b9c212ce6db8a27b6085af0e71659341e53d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x1927b8b8A266d8F9E645256a2Be89fE6F6c1bf12": {
        "max": 1,
        "address": "0x1927b8b8A266d8F9E645256a2Be89fE6F6c1bf12",
        "signature": "0xf140a4b8ea973bf7f8bf0e8d5bf94493187fadbd157fef2487e206f8fb27e77b481a2aec8ad2e5eac2d0ebfa70d80b538424a8a7f527b9d6fa034798a89c48121c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x8C003534eCd51F3Ce4f124080370bBF8c1e6216C": {
        "max": 2,
        "address": "0x8C003534eCd51F3Ce4f124080370bBF8c1e6216C",
        "signature": "0x70d381895e756a071e61885cfee27b2de72647b0c34603ec251973bc537e209576999ccbdf316505c2bc344da7e7d42fdde9a9a21ec26ac4c51f2222d657f3bd1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xfa2236A34042bc622C44d031328b465973c3d140": {
        "max": 2,
        "address": "0xfa2236A34042bc622C44d031328b465973c3d140",
        "signature": "0x13f64fb58d8a247c31f5b27d5687ad562ca7eada07ddedd1638b1c96909553a173a5d876a60251a3cab874029b61e0734d3fc8a275b0edf468f452b387a3616c1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x84553ad958a3ee5AB45d3ea1D10CcB7e72B3FDA0": {
        "max": 2,
        "address": "0x84553ad958a3ee5AB45d3ea1D10CcB7e72B3FDA0",
        "signature": "0xa7183e8377e561549e782584216afc96d7efa206f2f5aa5df02cbcb4b3d245a312c6ae243544602eb7fded16a85a50ea5536e5e51e7b7c4d896b1d35dbced2fb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x92375C57ADbb9732CFe50DE3a4df9ecdD87E6cfd": {
        "max": 2,
        "address": "0x92375C57ADbb9732CFe50DE3a4df9ecdD87E6cfd",
        "signature": "0x6162e4b242bdcbb201fec9dbb902231a1136e1384abb25ca7fe1decb0e3d427c4b20a5de347f2cb7e25198181a55c8098c3b477c0fd342f3f4f1e92fdac42e011b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x03301d0e138B4Be7e5770Dc191f4Da4cFB01163C": {
        "max": 2,
        "address": "0x03301d0e138B4Be7e5770Dc191f4Da4cFB01163C",
        "signature": "0xacfe7af687e0e8faf49dfdf924136cba330c26af34cf5711c0ea8a93d8997bb44483c711bad8f8b94e9ac431dfd4f166fa1c50d89143f2b965e1dd6ef5d181c71c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x36AC3244e20bCe70303169398ebA60C45Fb3aA9c": {
        "max": 1,
        "address": "0x36AC3244e20bCe70303169398ebA60C45Fb3aA9c",
        "signature": "0x1cba329ce38f4f768fbd0310f2b0a26c50cf9207ff938ff027cc4119d50d7f180c64e16504b4a511fe04c9dd1977296e08d8070bc693ae5584ffc8d1e3b1d8571b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf62a70302bE84882dA09B035565130cc145f761c": {
        "max": 1,
        "address": "0xf62a70302bE84882dA09B035565130cc145f761c",
        "signature": "0x73667f862fe24d4e4675ffe782ceb65c9b01689f31a9778a15d9f5e89c0bb73a6eb50b14f5302eef8fda27deed780ecfc9a98772c9d198eff7af515580c53f881b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x593bA4cb9d669567edDc7832FA5bFF62F1bea02F": {
        "max": 1,
        "address": "0x593bA4cb9d669567edDc7832FA5bFF62F1bea02F",
        "signature": "0xb581794654f1551c93dbc69d6eb02440219b6c53ccbfff34414b14bc05d2a170392f1729a869a805b87a4a0025c237b93c19cf3625143601a344cfc653f963371b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7f6C11033C958A32Ca0CF9d29e6767102A386d07": {
        "max": 1,
        "address": "0x7f6C11033C958A32Ca0CF9d29e6767102A386d07",
        "signature": "0x496d9243a9691fa940cacefdc996d72c89129e52224297b2219f8dd1a857266905ffe0562c1cc7b1138976059e3eef703d80fc9e6b4f44cca782775bdc7780ea1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xf99428B433fE96353A013f6550A111d41f660221": {
        "max": 1,
        "address": "0xf99428B433fE96353A013f6550A111d41f660221",
        "signature": "0xd283513fddf37390f6515ec69d49f6d0c5d9bb13b9c506334be73a6c4e409aa61ecf1e63dd7c6cbf30844b088d63fa0268a2161828622f5fee849e928f7137151c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3882e6270EBc7AB5b51113fd007C5a6C8f8D7732": {
        "max": 1,
        "address": "0x3882e6270EBc7AB5b51113fd007C5a6C8f8D7732",
        "signature": "0x85ce62f88ab6080d87b953d81ea0cd14745749a8fd19988b997a2c78d4fdb4d22e52754a018241ce048efb21a1112f6cca26f5471f1273797e313687f1422b361c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC60D18d515C4C44aC5b5207c5fF759cCa510915f": {
        "max": 1,
        "address": "0xC60D18d515C4C44aC5b5207c5fF759cCa510915f",
        "signature": "0x848892af5429b2185e274936baebd644ba63964850dce0f24c13024fd819ac12605934dcaf7e8df4a708aeb4979b972d1e7d52340e90a10b166973774950bf811b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xeBcaBdA0EA2856A7544fC1452305ff99cd04496b": {
        "max": 3,
        "address": "0xeBcaBdA0EA2856A7544fC1452305ff99cd04496b",
        "signature": "0x9ffacd61cfd20bdb1ffd6292a5d5fb5da003f108a7b94257dcf4bea8e8aacff460d9e415ab8ecc91999d335741ccaaad99eac1cceb7ed418450f35dfa42b9e421c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x07eC0f92b52586769c634bA5F60c7100d5A10662": {
        "max": 1,
        "address": "0x07eC0f92b52586769c634bA5F60c7100d5A10662",
        "signature": "0x71aa69e75b925dc83554721fb6097664bbf7916aca0913416615e586681b6c96102097d47644f723e85ffefdfea6514d59ca1c1da690dc112d64883b909bf74d1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAe11A7f0fC3EE6E635914D160C89Cc78497151D8": {
        "max": 1,
        "address": "0xAe11A7f0fC3EE6E635914D160C89Cc78497151D8",
        "signature": "0xbb0ffee37eb2df3b5b50641f01d079ec0c7031cf5360b8613dfb2189afde7e9906bfdbdceb409a6b058ba399783b9d0ce7ec2937c589654f7e0ffb7c28da5df81b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xAE3c71cDd04f8e0956Df6ca4C235BfE658816c2B": {
        "max": 1,
        "address": "0xAE3c71cDd04f8e0956Df6ca4C235BfE658816c2B",
        "signature": "0x05aa818a45f1466bd490507cf3b377fea5f5a9c02a8b650466b9566589c0c01b340884ea7aeea87c52103d70577335dd5c8d9d8dc4e1c5a76130f95c2e220bf11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xa04aCcd846960D30a5A2020461a9b78F7b6dACe1": {
        "max": 1,
        "address": "0xa04aCcd846960D30a5A2020461a9b78F7b6dACe1",
        "signature": "0x5693423db364bf55077a6812ff0a7c88a7e5f14173be6c2b0745f6af7420049b30da71a8ffdc64976ecea2d09a431ab39b3c496f0027e7fc3ff3db1c4d1a0dcd1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBeCA0DBD83ad67E6610c893D51371546354340a7": {
        "max": 1,
        "address": "0xBeCA0DBD83ad67E6610c893D51371546354340a7",
        "signature": "0x064534cce336d0116bcaf7f7357a7ff97378332353589429df8d8517ad30140212abb888793d59659e1a1ce384ebfa9b65736cd161ad96d37bcb4f1847fff4511c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB61895220192bfE1AA14522128A0bcf9387FaCe2": {
        "max": 2,
        "address": "0xB61895220192bfE1AA14522128A0bcf9387FaCe2",
        "signature": "0x0a3da4c63b7cf7233b9a2e31aee5d4874cf3d9d24fe992acbeec239b609468a86d75dc31d32ab111957253a30def47b523a41edf59ebfd330da6033645fb50121c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x0b33392EC23f14e3fE419b10a15e7f80d4494F7b": {
        "max": 1,
        "address": "0x0b33392EC23f14e3fE419b10a15e7f80d4494F7b",
        "signature": "0x2b7feb2839ca8cef466fbee1532d5394257422178db6017600a37eee8f8b8857605e95916c5b9545163c0429b9cf7a1741545b7cd744a28a27f04e9642a3448c1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xC91e6CaB88ecAb84ef8552fe011A3E7bbD710780": {
        "max": 1,
        "address": "0xC91e6CaB88ecAb84ef8552fe011A3E7bbD710780",
        "signature": "0x75567ad2eca084bea0cefcff6e240fa53fdec36a4531c915a61a54be05c371de084bf883ffc97ff2c615ea35f99a92bd6a6a6cea71d00f763a449138ad4806091b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x33beC9A7fc674C1aeBf887E51D97c1aE1bA0976C": {
        "max": 1,
        "address": "0x33beC9A7fc674C1aeBf887E51D97c1aE1bA0976C",
        "signature": "0x6d897c56ba4f99a202c2a3627edc1cc22d2b5b1a8b4660d5230d03a478a259cc44b55cf8df505d43511f86cc69b71563a18aefca588d6978dbb9a2b20db539c21c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x388a75E30474351a3BB807f2A21F9dcd278EE1aA": {
        "max": 1,
        "address": "0x388a75E30474351a3BB807f2A21F9dcd278EE1aA",
        "signature": "0x3eae4103048b7011e2a623cf3f7ea1771e85a71f2979782ade07fe80422f866028ee28f2adf2d12b3450551fb1c98be497c38195b5b9afa2b8aae9364477cd6d1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xBD76bb693fe0848659989705dA511F7F31dC624B": {
        "max": 1,
        "address": "0xBD76bb693fe0848659989705dA511F7F31dC624B",
        "signature": "0xdfa14f3d2384a628c8ab27b9929cbbf8f471c98b7a5babdeae6d33369c0aa3bf78345e0a541987791c88cad45560ba234b688a4cefef0baff75d359b1de4b9c41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x61219D19B588F008A9D58DeA7ce0221C0D862E4C": {
        "max": 1,
        "address": "0x61219D19B588F008A9D58DeA7ce0221C0D862E4C",
        "signature": "0x5f6c17c96ed98e4adfcb4bc31b4735d50bfbe1942008c657d788d40d6c3535f54b2042a7cc6a4de3aa298ea156720192d9eb4bafd48da53393c7e64e37774f781c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6edaf620c758595135Cf2F8Ca1a52733fAA44212": {
        "max": 1,
        "address": "0x6edaf620c758595135Cf2F8Ca1a52733fAA44212",
        "signature": "0xf61b628f838c2b8a493c8ac8e210ec150e61d5274f2b338fa75f5b62a109a20058792df21a22be1d00290784386229a28d65dd682872386bd0acf568a3f325791c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x2A38161D0EF5a954f6763606f353a353eda464e7": {
        "max": 1,
        "address": "0x2A38161D0EF5a954f6763606f353a353eda464e7",
        "signature": "0x136c8a19f67511946e4a9e8e1784100046d8145d77dc200fc1f37641900a914106c406e7d6ac44c043372a784b90a839b5f01685d2a22c647ab12d2b3f9e5f041b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x86E5918f837C92f40140D687e68B6558fF6033a2": {
        "max": 1,
        "address": "0x86E5918f837C92f40140D687e68B6558fF6033a2",
        "signature": "0xbbef8008607e5228798d9f2ccb32cb742e166bc0f4105182e2f692d92c0f6ad96d74b5acde76966253c7e9b3628cd1cfd12f3348ab4be8e1ca1ffd2c113105681b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xD95F0670F50054e4db67112a341e7Fe520a81DCc": {
        "max": 1,
        "address": "0xD95F0670F50054e4db67112a341e7Fe520a81DCc",
        "signature": "0xfc0edfa6cab9ee6fad82ff5498a871474534a04764041ddfab21e90ef7da9a132e7e31c2a7438d7d9f9c38e80b5840aff2ba1f53d7535b40d9cfe0db59e165d61c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x19FF9b5086Cf6Cf4B85a5c359A7329032011600D": {
        "max": 1,
        "address": "0x19FF9b5086Cf6Cf4B85a5c359A7329032011600D",
        "signature": "0x6f33ea4ce073532c7e66a087697dc32bfcb3e8c853e8694488026443a3465e1000238146df99cfd8b51c3686515f799f4ea0a818795e0fd02330bdcde2dd8e681b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xDA11c7475da3D28bc7F041851E640FF88d00E9b5": {
        "max": 1,
        "address": "0xDA11c7475da3D28bc7F041851E640FF88d00E9b5",
        "signature": "0xf9d69d1ecbdf0adbec2e01329c437747ff6fc72d1f6f13f2b90b146037169a5b381383794cb6039da2db717e4fb240841a6ed7fcb966ba9847358dce691dd0571b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6dd7446A6d3Aa57097608d26A30E7681C9c0BD52": {
        "max": 1,
        "address": "0x6dd7446A6d3Aa57097608d26A30E7681C9c0BD52",
        "signature": "0x5c3435861a7fa61dc581f2c5fb26c02ebd818521d37c509c8f9d0467b2b423497b7b4d11948e5c102fe1c93a95b2829dbd6bcf17250de6183ffd850bdd8eddde1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x10c9470a1b0cf1D356a9F5decF100d2A208480ED": {
        "max": 1,
        "address": "0x10c9470a1b0cf1D356a9F5decF100d2A208480ED",
        "signature": "0x58afda0c26e07cab1f85f217d2c57793964754f7a1cd2e10a38f1a013fb715072f498ecb97320174f1cff0b387bc3406c3aad31c756f916e1eb84e69d0c0e5a11c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x25dFE94F20d26b14b76A564C660F939282Ad5720": {
        "max": 1,
        "address": "0x25dFE94F20d26b14b76A564C660F939282Ad5720",
        "signature": "0xb2696945fdb26303477e55c40f1ed2dbe66c0156cdf964e935e271d9d142d96d13f47e23ae91e630f36c9f0eed6c5a51777f480602331d2309d0293b7bcb7e431c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x3BEA3631571079e90486930BDF896Cc6e99451ED": {
        "max": 1,
        "address": "0x3BEA3631571079e90486930BDF896Cc6e99451ED",
        "signature": "0x2e7982450e9503687ca758d12908bd1ad571d4ac1ae1e5e148c1392bdde067c20391a3e7b641d0d25862c0d17dfd9ab98d49475cde60a4f1b0d2d76c03c0f1221c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x82042293b45F87aA8703B4bA66Caf7b6146f73AE": {
        "max": 1,
        "address": "0x82042293b45F87aA8703B4bA66Caf7b6146f73AE",
        "signature": "0xc66db2b9102bfe61cf819d93ded4e57fe2d5006ba4ea962563a42c43b420ec7c2bfab3c4126b8b1a81cdfc0e49d2914a7a7151bfb9c5b2b8b6d65a50e6bafbe21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xA178d10B50a4dD8336aA4A61965419E32Eb113A6": {
        "max": 1,
        "address": "0xA178d10B50a4dD8336aA4A61965419E32Eb113A6",
        "signature": "0xa9d198343de3e47ce189a7772be98142a0c999d6bc3119e973080c0a99c27c215f81d0f82e4fa977be3f5cb8e37010e1d4ec70cc3a806fddd79470d5981a12341b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x13C77dd1D6cf10F0A1214676B3762B957E0db94F": {
        "max": 1,
        "address": "0x13C77dd1D6cf10F0A1214676B3762B957E0db94F",
        "signature": "0xc2814008e761971b639457678a25f2e4f5e768ce1e7476c1507e08da498312cf7297e820a73bf912eed4f4afbcd8ee8b45974686c2d3fe82df9f022e3ba033d41c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6bd42c0F991e95CC747367D3f0Fa471134c059cE": {
        "max": 1,
        "address": "0x6bd42c0F991e95CC747367D3f0Fa471134c059cE",
        "signature": "0x3f8d101e3d624ee85d4b9ecddba774367c1233a7c2b0a946e39aaec497504edf03ad4391c2a8cfac9b2ab2777203b9ee5e051f00ad5e0bbdd9214e4b784a64181c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x6f88f66e04Ee27Ec62f9C678FC3ce61268A8dad6": {
        "max": 1,
        "address": "0x6f88f66e04Ee27Ec62f9C678FC3ce61268A8dad6",
        "signature": "0xd7e28a68d9191e00c30d7922d16da72eacdeb125f7a34595bb1ace02316dab59571db4fabefccda73dc53483171f253d248fcdb7f36c7ddbc208edbe147756fb1c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xb85eF4488f9066141202Eb51210e4E1D9Df02F53": {
        "max": 1,
        "address": "0xb85eF4488f9066141202Eb51210e4E1D9Df02F53",
        "signature": "0x3c9d879bb118bf8a3ae709ae5501e4a74c222ac070595991ce717388dc9d0b386b23d6d97f824b9a26356af154301267ac3789cbed22abf5e4051e2d24b7ed1e1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x7342c7B4930EeAFe4FB6163838eEa4d3F318d04d": {
        "max": 1,
        "address": "0x7342c7B4930EeAFe4FB6163838eEa4d3F318d04d",
        "signature": "0x8ecc0790a561194fae63e28711e6afd74aec4f0dbd4c274cc6cd97b7eb0c9d8f5001f4222f45fa0c025d68226f6189b7c6c53d8d9c072e95efb240099989b6081c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x44A6cD21bBD267428b281b74cC260f85d2934436": {
        "max": 1,
        "address": "0x44A6cD21bBD267428b281b74cC260f85d2934436",
        "signature": "0x3c06a1918ef188bac667b15be17897640c431865ddaab5d70a303d8fc5ff436477a091a97d13e6ed63f334c3a1a2afa8623b68d67036322d03d6cb85c9c6ff971c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x186E4f417Aa013871F44B718F72EdA100bC4734D": {
        "max": 1,
        "address": "0x186E4f417Aa013871F44B718F72EdA100bC4734D",
        "signature": "0x4fdbc17ae0822c42b9bfcaab118b2794739bd66e8c1809a50cac90c968720ada011201a4f2d4f65eb8c77da2a3ae931c274424cc9759bc33b83633d04a7a12b21b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xB7d30bA4f6dDec4C6EBF6e6FFDAD89fF9AbA0Cd9": {
        "max": 1,
        "address": "0xB7d30bA4f6dDec4C6EBF6e6FFDAD89fF9AbA0Cd9",
        "signature": "0x40dc616b1b1e90804d19fa5e2783a4da1c6b44231e5582bc950bae2d06a4b56469a7e589d793f26ac6c3b52fe06e4ce2c30ac6d9e6eca396b24b30e48d52ec7f1b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0xdDd1918AC0D873eb02feD2ac24251da75d983Fed": {
        "max": 1,
        "address": "0xdDd1918AC0D873eb02feD2ac24251da75d983Fed",
        "signature": "0x0dbbaea1fd89e626b4c86eec1ee79c968d5c8048fc57053f9862e5f4bbdf78644a070a3c71c552c442f016072aaef083a6a825e3de86591e31c68ff1b50e86a01c",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    },
    "0x9Ec8ebc9Fb0Cf26613d39A8947035a2D401B52f5": {
        "max": 3,
        "address": "0x9Ec8ebc9Fb0Cf26613d39A8947035a2D401B52f5",
        "signature": "0xcc81afd7ae74d438c570259e906065aac48bdbc7e31f28e0a0eae95cc62da00330404c2b729e8b3a492db209ea4c8f8b05b896a690ad048e6f43b4f860b85c701b",
        "signer": "0xC95DBB35c338F05e35EF45B014b2EaB8cfbea78B"
    }
}